<template>
<div>
  <!-- BREADCRUMB -->
  <div class="margin-sticky-fix"></div>
  <div class="breadcrumb bg-dark-gray">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="flex-wrap d-flex">
            <div class="d-flex mr-4 pointer">
              <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-users.homepage') }}</span>
            </div>
            <div class="d-flex mr-4">
              <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
            </div>
            <div class="d-flex">
              <span class="text-16 text-white pt-3 pb-3">{{ $t('admin-menu.templates') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- //BREADCRUMB -->
  <div id="content-2">
    <div class="container">
      <div class="row mt-5">
        <div class="col-12">
          <h2 class="text-18 text-dark-gray semi-bold mb-3"> {{ $t('admin-menu.templates') }}</h2>
        </div>
        <section id="article-list">
          <div class="row" v-if="!loading && templates.length > 0">
            <div class="col-12" v-for="template in templates" :key="template.id">
              <!-- Template -->
              <div class="article-box bg-light-gray-primary mb-3">
                <div class="row">
                  <div class="article-header col-lg-1" v-if="$store.state.user.role == 'Admin'">
                    <div class="flex-wrap d-flex h-100">
                      <div class="d-flex flex-column article-editor mr-3 pointer">
                        <img src="assets/img/icons/pen.svg" class="img-fluid mb-3" alt="" @click.prevent="$router.push({name: 'EditTemplate', params: {id: template.id}})">
                      </div>
                    </div>
                  </div>
                  <div v-bind:class="{ 'col-lg-11': $store.state.user.role == 'Admin', 'col-12': '' }">
                    <h1 class="text-22 text-white semi-bold text-truncate">{{template.name}}</h1>
                    <div class="mt-3">
                      <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6">
                          <span class="text-white">en:</span>
                          <h2 class="text-14 medium text-white" v-html="$store.getters.sanitizeSummernoteText((JSON.parse(template.content)).en)"></h2>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                          <span class="text-white">hu:</span>
                          <h2 class="text-14 medium text-white" v-html="$store.getters.sanitizeSummernoteText((JSON.parse(template.content)).hu)"></h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- //Template -->
            </div>
          </div>
        </section>
      </div>
    </div>
    </div>
  <div v-if="loading" class="row">
    <div class="col-12">
      <Loading height="20vh" />
    </div>
  </div>
</div>
</template>

<script>
import Loading from '../components/Loading.vue'
export default {
  components: {Loading},
  name: "Templates",
  mounted() {
    this.axios.get('/templates')
        .then((resp) => {
          this.templates = resp.data.templates
        })
        .catch(e => console.log(e?.response))
        .finally(() => this.loading = false)
  },
  data() {
    return {
      loading: true,
      templates: [],
      submitting: false
    }
  },
}
</script>

<style scoped>

</style>
