<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-courses.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{$t('posts.unapproved')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section id="content-2">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-xl-6 order-2 order-xl-1">
                        <section id="timeline">
                            <feed :showUnapprovedOnly="true" />
                        </section>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import Feed from "../components/Feed.vue"

export default {
    components: { Feed },
    name: 'UnapprovedPosts',
}
</script>