import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import CourseCatalog from "../views/CourseCatalog.vue";
import MyCourses from "../views/MyCourses.vue";
import Glossary from "../views/Glossary.vue";
import FastShare from "../views/FastShare.vue";
import AssignmentsToReturn from "../views/AssignmentsToReturn.vue";
import Reporting from "../views/Reporting.vue";
import MyTeam from "../views/MyTeam.vue";
import Course from "../views/Course.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Register2 from "../views/Register2.vue";
import VerifyEmail from "../views/VerifyEmail.vue";
import Chat from "../views/Chat.vue";
import Notifications from "../views/Notifications.vue";
import Settings from "../views/Settings.vue";
import ActivityHistory from "../views/ActivityHistory.vue";
import Overview from "../views/Overview.vue";
import Uploads from "../views/Uploads.vue";
import Meeting from "../views/Meeting.vue";
import GlossaryArticle from "../views/GlossaryArticle.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";
import ViewCourse from "../views/ViewCourse.vue";
import CreateCourse from "../views/CreateCourse.vue";
import ManageCourses from "../views/ManageCourses.vue";
import CourseMaterials from "../views/CourseMaterials.vue";
import EditCourse from "../views/EditCourse.vue";
import TestAssembler from "../views/TestAssembler.vue";
import TestQuestionAssembler from "../views/TestQuestionAssembler.vue";
import EditTest from "../views/EditTest.vue";
import ManageTests from "../views/ManageTests.vue";
import ActiveTestAttempt from "../views/ActiveTestAttempt.vue";
import CorrectTestAttempt from "../views/CorrectTestAttempt.vue";
import ViewTestAttempt from "../views/ViewTestAttempt.vue";
import ManageUsers from "../views/ManageUsers.vue";
import CreateGlossaryArticle from "../views/CreateGlossaryArticle.vue";
import EditGlossaryArticle from "../views/EditGlossaryArticle.vue";
import CourseReport from "../views/CourseReport.vue";
import CourseReports from "../views/CourseReports.vue";
import UnapprovedGlossary from "../views/UnapprovedGlossary.vue";
import Cart from "../views/Cart.vue";
import AfterPayment from "../views/AfterPayment.vue";
import Feedback from "../views/Feedback.vue";
import FeedbackList from "../views/FeedbackList.vue";
import ResolvedFeedbackList from "../views/ResolvedFeedbackList.vue";
import CourseMetadataReports from "../views/CourseMetadataReports.vue";
import CourseFeedbackList from "../views/CourseFeedbackList.vue";
import EditCourseFeedback from "../views/EditCourseFeedback.vue";
import CreateUserCourseFeedback from "../views/CreateUserCourseFeedback";
import UserCourseFeedback from "../views/UserCourseFeedback";
import CourseFeedbackReports from "../views/CourseFeedbackReports";
import CourseQuizReports from "../views/CourseQuizReports";
import CourseQuizReport from "../views/CourseQuizReport";
import QuizReport from "../views/QuizReport";
import SearchReports from "../views/SearchReports";
import BarAssociationReports from "../views/BarAssociationReports";
import CourseBarAssociationReport from "../views/CourseBarAssociationReport";
import CheckoutUserData from "../views/CheckoutUserData.vue";
import CheckoutPaymentMethod from "../views/CheckoutPaymentMethod.vue";
import CheckoutSummary from "../views/CheckoutSummary.vue";
import AfterExam from "../views/AfterExam.vue";
import MyOrders from "../views/MyOrders.vue";
import Order from "../views/Order.vue";
import ViewPost from "../views/ViewPost.vue";
import PersonalAnalytics from "../views/PersonalAnalytics.vue";
import ManageNews from "../views/ManageNews.vue";
import CreateNews from "../views/CreateNews.vue";
import EditNews from "../views/EditNews.vue";
import ManageCoupons from "../views/ManageCoupons";
import ManageCodes from "../views/ManageCodes";
import CreateFarContract from "../views/CreateFarContract";
import Faq from "../views/Faq";
import UserManual from "../views/UserManual";
import ExchangeRates from "../views/ExchangeRates";
import TemporaryCertificates from "../views/TemporaryCertificates";
import CourseGroupList from "../views/CourseGroupList";
import EditCourseGroup from "../views/EditCourseGroup";
import CreateCourseGroup from "../views/CreateCourseGroup";
import CourseCatalogGrouped from "../views/CourseCatalogGrouped";
import EditMembership from "../views/EditMembership";
import UnapprovedPosts from "../views/UnapprovedPosts";
import CustomPageList from "../views/CustomPageList";
import CreateCustomPage from "../views/CreateCustomPage";
import CustomPage from "../views/CustomPage";
import EditCustomPage from "../views/EditCustomPage";
import MyCertificates from "@/views/MyCertificates";
import Templates from "@/views/Templates";
import EditTemplate from "@/views/EditTemplate";
import CouponStatistics from "@/views/CouponStatistics";
import ManageCertificates from "@/views/ManageCertificates";
import SettingsEnhanced from "@/views/SettingsEnhanced";
import GeneralSettings from "../components/settings/GeneralSettings";
import Membership from "../components/settings/Membership";
import Qualification from "../components/settings/Qualification";
import BillingDate from "../components/settings/BillingData";
import OtherInformation from "../components/settings/OtherInformation";
import SecurityTab from "../components/settings/SecurityTab";
import Badges from "../components/settings/Badges";
import Invoices from "../components/settings/Invoices";
import TeamMembers from "@/views/TeamMembers";
import TeamMember from "@/views/TeamMember";
import InvoicePreviewPage from "@/views/InvoicePreviewPage";
import ProfessionalHistory from "../components/settings/ProfessionalHistory";
import Questionnaires from "../views/Questionnaires.vue";
import Questionnaire from "../views/Questionnaire";
import BarionWallet from "../components/settings/BarionWallet";
import BarionWallets from "../components/UserData/BarionWallets";
import LocalizationSettings from "../components/settings/LocalizationSetting";
import Confirmation from "../views/Confirmation";
import ManageOrders from "../views/ManageOrders";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { doesntRequireMembership: true },
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: Feedback,
    meta: { doesntRequireMembership: true },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/checkout/data",
    name: "CheckoutUserData",
    component: CheckoutUserData,
    meta: { doesntRequireMembership: true },
  },
  {
    path: "/orders",
    name: "MyOrders",
    component: MyOrders,
    meta: { doesntRequireMembership: true },
  },
  {
    path: "/my-certificates",
    name: "MyCertificates",
    component: MyCertificates,
    meta: { doesntRequireMembership: true },
  },
  {
    path: "/orders/:order",
    name: "Order",
    component: Order,
    meta: { doesntRequireMembership: true },
  },
  {
    path: "/checkout/payment-method",
    name: "CheckoutPaymentMethod",
    component: CheckoutPaymentMethod,
    meta: { doesntRequireMembership: true },
  },
  {
    path: "/checkout/summary",
    name: "CheckoutSummary",
    component: CheckoutSummary,
    meta: { doesntRequireMembership: true },
  },
  {
    path: "/checkout/invoice-preview",
    name: "InvoicePreview",
    component: InvoicePreviewPage,
    meta: { doesntRequireMembership: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { doesntRequireMembership: true },
  },
  {
    path: "/frequently-asked-questions",
    name: "Faq",
    component: Faq,
    meta: { doesntRequireMembership: true },
  },
  {
    path: '/user-manual',
    name: 'UserManual',
    component: UserManual,
    meta: {doesntRequireMembership: true}
  },
  {
    path: '/analytics/:user?',
    name: 'PersonalAnalytics',
    component: PersonalAnalytics,
    meta: {doesntRequireMembership: true}
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    props: true,
    component: ForgotPassword,
    meta: {doesntRequireMembership: true}
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    props: true,
    meta: {doesntRequireMembership: true}
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {doesntRequireMembership: true}
  },
  {
    path: '/resend-confirmation',
    name: 'Confirmation',
    component: Confirmation,
    meta: {doesntRequireMembership: true}
  },
  {
    path: '/finish-registration',
    name: 'Register2',
    props: true,
    component: Register2
  },
  {
    path: '/test-attempt/active',
    name: 'ActiveTestAttempt',
    component: ActiveTestAttempt
  },
  {
    path: '/test-attempt/:testAttempt/corrected',
    name: 'ViewTestAttempt',
    component: ViewTestAttempt,
    props: true
  },
  {
    path: '/meeting/:id',
    name: 'Meeting',
    component: Meeting,
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: VerifyEmail,
    meta: {doesntRequireMembership: true}
  },
  {
    path: "/course-catalog",
    name: "CourseCatalog",
    component: CourseCatalog,
    meta: { doesntRequireMembership: true },
  },
  {
    path: '/course-catalog/:group',
    name: 'CourseCatalogGrouped',
    component: CourseCatalogGrouped,
    meta: {doesntRequireMembership: true}
  },
  {
      path: '/course/:id',
      name: 'Course',
      component: Course,
    meta: {doesntRequireMembership: true}
  },
  {
    path: '/course/:id/view',
    name: 'ViewCourse',
    component: ViewCourse
  },
  {
    path: '/my-courses',
    name: 'MyCourses',
    component: MyCourses
  },
  {
    path: '/questionnaires',
    name: 'Questionnaires',
    component: Questionnaires
  },
  {
    path: '/questionnaire/:id/attempt/:attempt',
    name: 'Questionnaire',
    component: Questionnaire
  },
  {
    path: "/far-contract/:course/create",
    name: "CreateFarContract",
    component: CreateFarContract,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    meta: { doesntRequireMembership: true },
  },
  {
    path: "/old-settings",
    name: "OldSettings",
    component: Settings,
  },
  {
    path: "/settings",
    name: "Settings",
    redirect: { name: "General" },
    component: SettingsEnhanced,
    meta: { doesntRequireMembership: true },
    children: [
      {
        path: "general",
        name: "General",
        component: GeneralSettings,
      },
      {
        path: "membership",
        name: "Membership",
        component: Membership,
      },
      {
        path: "invoices",
        name: "Invoices",
        component: Invoices,
      },
      {
        path: "qualification",
        name: "Qualification",
        component: Qualification,
      },
      {
        path: "professional-history",
        name: "ProfessionalHistory",
        component: ProfessionalHistory,
      },
      {
        path: "billing-data",
        name: "Billing",
        component: BillingDate,
      },
      {
        path: "other-information",
        name: "OtherInfo",
        component: OtherInformation,
      },
      {
        path: "badges",
        name: "Badges",
        component: Badges,
      },
      {
        path: "barion-wallets",
        name: "BarionWallets",
        component: BarionWallet,
      },
      {
        path: "localizations",
        name: "Localizations",
        component: LocalizationSettings,
      },
    ],
  },
  {
    path: '/activity-history/:id?',
    name: 'ActivityHistory',
    component: ActivityHistory,
  },
  {
    path: '/test/:test/attempt/:attempt/finished',
    name: 'AfterExam',
    props: true,
    component: AfterExam
  },
  {
    path: '/chat',
    name: 'Chat',
    props: true,
    component: Chat,
    meta: {doesntRequireMembership: true}
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    meta: {doesntRequireMembership: true}
  },
  {
    path: '/after-payment/:paymentRequestId?',
    name: 'AfterPayment',
    component: AfterPayment,
    meta: {doesntRequireMembership: true}
  },
  {
    path: '/uploads',
    name: 'Uploads',
    component: Uploads,
  },
  //Member User routes
  {
    path: '/share',
    name: 'FastShare',
    component: FastShare,
    meta: { doesntRequireMembership: true }
  },
  {
    path: '/post',
    name: 'ViewPost',
    component: ViewPost,
    props: true,
    meta: { doesntRequireMembership: true }
  },
  {
    path: '/glossary',
    name: 'Glossary',
    component: Glossary,
    meta: { doesntRequireMembership: true }
  },
  {
    path: '/glossary/:id',
    name: 'GlossaryArticle',
    component: GlossaryArticle,
    meta: { doesntRequireMembership: true }
  },
  //Admin routes
  {
    path: '/posts/unapproved',
    name: 'UnapprovedPosts',
    component: UnapprovedPosts,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/coupons/manage',
    name: 'ManageCoupons',
    component: ManageCoupons,
    meta: { requiresAdminAccess: false }
  },
  {
    path: '/code/manage',
    name: 'ManageCodes',
    component: ManageCodes,
    meta: { requiresAdminAccess: false }
  },
  {
    path: '/coupons/statistics',
    name: 'CouponStatistics',
    component: CouponStatistics,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/exchange-rates',
    name: 'ExchangeRates',
    component: ExchangeRates,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/templates',
    name: 'Templates',
    component: Templates,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/templates/:id',
    name: 'EditTemplate',
    component: EditTemplate,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/news/manage',
    name: 'ManageNews',
    component: ManageNews,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/orders/manage',
    name: 'ManageOrders',
    component: ManageOrders,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/news/create',
    name: 'CreateNews',
    component: CreateNews,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/news/:id/edit',
    name: 'EditNews',
    props: true,
    component: EditNews,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/feedback/list',
    name: 'FeedbackList',
    component: FeedbackList,
  },
  {
    path: '/feedback/resolved',
    name: 'ResolvedFeedbackList',
    component: ResolvedFeedbackList,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/course/:course/feedback/:feedback',
    name: 'UserCourseFeedback',
    component: UserCourseFeedback,
    meta: { requiresMasterAccess: true }
  },
  {
    path: '/course/:course/feedback/list',
    name: 'CourseFeedbackList',
    component: CourseFeedbackList,
    meta: { requiresMasterAccess: true }
  },
  {
    path: '/course/:course/feedback/edit',
    name: 'EditCourseFeedback',
    component: EditCourseFeedback,
    meta: { requiresMasterAccess: true }
  },
  {
    path: '/temporary-certificates',
    name: 'TemporaryCertificates',
    component: TemporaryCertificates,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/certificates',
    name: 'Certificates',
    component: ManageCertificates,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/glossary/unapproved',
    name: 'UnapprovedGlossary',
    component: UnapprovedGlossary,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/users/manage',
    name: 'ManageUsers',
    component: ManageUsers,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/users/manage',
    name: 'ManageUsers',
    component: ManageUsers,
    meta: { requiresMasterAccess: true }
  },
  {
    path: '/tests/manage',
    name: 'ManageTests',
    component: ManageTests,
    meta: { requiresMasterAccess: true }
  },
  {
    path: '/tests/create',
    name: 'TestAssembler',
    component: TestAssembler,
    meta: { requiresMasterAccess: true }
  },
  {
    path: '/tests/:test/edit',
    name: 'EditTest',
    component: EditTest,
    meta: { requiresMasterAccess: true }
  },
  {
    path: '/tests/:test/questions',
    name: 'TestQuestionAssembler',
    component: TestQuestionAssembler,
    meta: { requiresMasterAccess: true }
  },
/*  {
    path: '/assignments-to-return',
    name: 'AssignmentsToReturn',
    component: AssignmentsToReturn,
    meta: { requiresAdminAccess: true }
  },*/
  {
    path: '/reporting',
    name: 'Reporting',
    component: Reporting,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/reporting/search-results',
    name: 'SearchReports',
    component: SearchReports,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/reporting/bar-association',
    name: 'BarAssociationReports',
    component: BarAssociationReports,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/reporting/bar-association/:course',
    name: 'CourseBarAssociationReport',
    component: CourseBarAssociationReport,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/courses/create',
    name: 'CreateCourse',
    component: CreateCourse,
    meta: { requiresMasterAccess: true }
  },
  {
    path: '/courses/manage',
    name: 'ManageCourses',
    component: ManageCourses,
    meta: { requiresMasterAccess: true }
  },
/*  {
    path: '/my-team',
    name: 'TeamMembers',
    component: TeamMembers,
    meta: { requiresMasterAccess: true }
  },*/
  {
    path: '/my-team/:member',
    name: 'TeamMember',
    component: TeamMember,
    meta: { requiresMasterAccess: true }
  },
  {
    path: '/courses/:course/edit',
    name: 'EditCourse',
    component: EditCourse,
    meta: { requiresMasterAccess: true }
  },
  {
    path: '/courses/metadata',
    name: 'CourseMetadataReports',
    component: CourseMetadataReports,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/courses/quiz/reports',
    name: 'CourseQuizReports',
    component: CourseQuizReports,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/courses/:course/quiz/reports',
    name: 'CourseQuizReport',
    component: CourseQuizReport,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/courses/:course/quiz/:test/reports',
    name: 'QuizReport',
    component: QuizReport,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/courses/feedback/reports',
    name: 'CourseFeedbackReports',
    component: CourseFeedbackReports,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/courses/reports',
    name: 'CourseReports',
    component: CourseReports,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/courses/:course/report',
    name: 'CourseReport',
    component: CourseReport,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/courses/:course/materials',
    name: 'CourseMaterials',
    component: CourseMaterials,
    meta: { requiresMasterAccess: true }
  },
  {
    path: '/glossary/:id/edit',
    name: 'EditGlossaryArticle',
    component: EditGlossaryArticle,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/course-groups',
    name: 'CourseGroupList',
    component: CourseGroupList,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/course-groups/:group/edit',
    name: 'EditCourseGroup',
    component: EditCourseGroup,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/course-groups/create',
    name: 'CreateCourseGroup',
    component: CreateCourseGroup,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/memberships/:membership/edit',
    name: 'EditMembership',
    component: EditMembership,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/custom-pages',
    name: 'CustomPageList',
    component: CustomPageList,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/custom-pages/:customPage/edit',
    name: 'EditCustomPage',
    component: EditCustomPage,
    meta: { requiresAdminAccess: true }
  },
  {
    path: '/custom-pages/create',
    name: 'CreateCustomPage',
    component: CreateCustomPage,
    meta: { requiresAdminAccess: true }
  },
  //Master routes
  {
    path: '/test-attempts/:testAttempt/correct',
    name: 'CorrectTestAttempt',
    component: CorrectTestAttempt,
    meta: { requiresMasterAccess: true }
  },
/*  {
    path: "/my-team",
    name: "MyTeam",
    component: MyTeam,
    meta: { requiresMasterAccess: true },
  },*/
  {
    path: '/overview/:id',
    name: 'Overview',
    component: Overview,
    meta: { requiresMasterAccess: true }
  },
  {
    path: '/glossary/create',
    name: 'CreateGlossaryArticle',
    component: CreateGlossaryArticle,
    meta: { requiresMasterAccess: true }
  },

  {
    path: "/:pathMatch(.*)*",
    name: "CustomPage",
    component: CustomPage,
    meta: {doesntRequireMembership: true}
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
