<template>
    <canvas id="courseStartCredit"></canvas>
</template>
<script>
export default {
    name: 'CourseStartCreditChart',
    props: {
        data: Array,
        isBar: {
            default: false 
        }
    },
    mounted() {
        const ctx = $('#courseStartCredit')
        this.chart = new Chart(ctx, {
            type: 'bar',
                data: 
                {   
                    labels: this.data[0].map(d => d.x),
                    datasets: 
                    [   
                        
                        { 
                            data: this.data[0].map(d => d.y),
                            borderWidth: 2,
                            backgroundColor: 'rgb(23, 111, 183, 0.8)',
                            borderColor: 'rgb(23, 111, 183, 1)',
                            label: this.$t('chart.started-courses'),
                        },
                        { 
                            data: this.data[1].map(d => d.y),
                            borderWidth: 2,
                            borderColor: 'rgb(40, 167, 69, 1)',
                            backgroundColor: 'rgb(40, 167, 69, 1)',
                            label: this.$t('chart.earned-credits'),
                            fill: false,
                            type: this.isBar ? 'bar' : 'line',
                            lineTension: 0, 
                            options: {
                                cubicInterpolationMode: 'monotone',
                                lineTension: 0, 
                            }
                        },
                    ]
                },
                options:
                {  
                    scales: 
                    {
                        xAxes: 
                        [{
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    },
                }  
        });
    },
    data() {
        return {
            chart: null
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale
        }
    },
    watch: {
        locale() {
            if(this.chart) {
                this.chart.data.datasets[0].label = this.$t('chart.started-courses')
                this.chart.data.datasets[1].label = this.$t('chart.earned-credits')
                this.chart.update()
            }
        },
        data() {
            if(this.chart) {
                this.chart.data.labels = this.data[0].map(d => d.x)
                this.chart.data.datasets.forEach((dataset,index) => {
                    this.chart.data.datasets[index].data = this.data[index].map(d => d.y)
                })
                this.chart.update()
            }
        }
    }
}
</script>