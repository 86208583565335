<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('reporting.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('reporting.statistic') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->
        <div id="content-2">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <section id="analytics-list" class="mt-5">
                            <div class="row">
                                <!-- analytics -->
                                <div class="col-lg-3 col-md-4 col-md-6 col-12 mb-3">
                                    <div class="analytics-card filter-shadow">
                                        <div class="analytics-header bg-light-gray-primary">
                                            <div class="flex-wrap d-flex align-items-center text-center">
                                                <div class="d-flex align-items-center ml-auto mr-auto">
                                                    <h3 class="text-18 text-white">{{ $t('reporting.course-statistic') }}</h3>
                                                </div>
                                            </div>
                                            <div class="flex-wrap d-flex align-items-center text-center mt-2">
                                                <div class="d-flex align-items-center ml-auto mr-auto">
                                                    <h3 class="text-12 text-white regular">{{ $t('reporting.success') }}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="analytics-bottom text-center">
                                            <button class="btn btn-theme" @click.prevent="$router.push({name: 'CourseReports'})">{{$t('reporting.login')}}</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- //analytics -->
                                <!-- analytics -->
                                <div class="col-lg-3 col-md-4 col-md-6 col-12 mb-3">
                                    <div class="analytics-card filter-shadow">
                                        <div class="analytics-header bg-light-gray-primary">
                                            <div class="flex-wrap d-flex align-items-center text-center">
                                                <div class="d-flex align-items-center ml-auto mr-auto">
                                                    <h3 class="text-18 text-white">{{ $t('reporting.meta') }}</h3>
                                                </div>
                                            </div>
                                            <div class="flex-wrap d-flex align-items-center text-center mt-2">
                                                <div class="d-flex align-items-center ml-auto mr-auto">
                                                    <h3 class="text-12 text-white regular">{{ $t('reporting.anal-functions') }}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="analytics-bottom text-center">
                                            <button class="btn btn-theme" @click="$router.push({name: 'CourseMetadataReports'})">{{$t('reporting.login')}}</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- //analytics -->
                                <!-- analytics -->
                                <div class="col-lg-3 col-md-4 col-md-6 col-12 mb-3">
                                    <div class="analytics-card filter-shadow">
                                        <div class="analytics-header bg-light-gray-primary">
                                            <div class="flex-wrap d-flex align-items-center text-center">
                                                <div class="d-flex align-items-center ml-auto mr-auto">
                                                    <h3 class="text-16 text-white">{{ $t('reporting.report') }}</h3>
                                                </div>
                                            </div>
                                            <div class="flex-wrap d-flex align-items-center text-center mt-2">
                                                <div class="d-flex align-items-center ml-auto mr-auto">
                                                    <h3 class="text-12 text-white regular">{{ $t('reporting.report-functions') }}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="analytics-bottom text-center">
                                            <button class="btn btn-theme"  @click="$router.push({name: 'BarAssociationReports'})">{{$t('reporting.login')}}</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- //analytics -->
                                <!-- analytics -->
                                <div class="col-lg-3 col-md-4 col-md-6 col-12 mb-3">
                                    <div class="analytics-card filter-shadow">
                                        <div class="analytics-header bg-light-gray-primary">
                                            <div class="flex-wrap d-flex align-items-center text-center">
                                                <div class="d-flex align-items-center ml-auto mr-auto ">
                                                    <h3 class="text-18 text-white">{{ $t('reporting.rates') }}</h3>
                                                </div>
                                            </div>
                                            <div class="flex-wrap d-flex align-items-center text-center mt-2">
                                                <div class="d-flex align-items-center ml-auto mr-auto">
                                                    <h3 class="text-12 text-white regular">{{ $t('reporting.rate-results') }}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="analytics-bottom text-center">
                                            <button class="btn btn-theme"  @click="$router.push({name: 'CourseFeedbackReports'})">{{$t('reporting.login')}}</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- //analytics -->
                                <!-- analytics -->
                                <div class="col-lg-3 col-md-4 col-md-6 col-12 mb-3">
                                    <div class="analytics-card filter-shadow">
                                        <div class="analytics-header bg-light-gray-primary">
                                            <div class="flex-wrap d-flex align-items-center text-center">
                                                <div class="d-flex align-items-center ml-auto mr-auto ">
                                                    <h3 class="text-18 text-white">{{ $t('reporting.quiz') }}</h3>
                                                </div>
                                            </div>
                                            <div class="flex-wrap d-flex align-items-center text-center mt-2">
                                                <div class="d-flex align-items-center ml-auto mr-auto">
                                                    <h3 class="text-12 text-white regular">{{ $t('reporting.quiz-results') }}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="analytics-bottom text-center">
                                            <button class="btn btn-theme" @click="$router.push({name: 'CourseQuizReports'})">{{$t('reporting.login')}}</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- //analytics -->
                                <!-- analytics -->
                                <div class="col-lg-3 col-md-4 col-md-6 col-12 mb-3">
                                    <div class="analytics-card filter-shadow">
                                        <div class="analytics-header bg-light-gray-primary">
                                            <div class="flex-wrap d-flex align-items-center text-center">
                                                <div class="d-flex align-items-center ml-auto mr-auto ">
                                                    <h3 class="text-18 text-white">{{ $t('reporting.search-results') }}</h3>
                                                </div>
                                            </div>
                                            <div class="flex-wrap d-flex align-items-center text-center mt-2">
                                                <div class="d-flex align-items-center ml-auto mr-auto">
                                                    <h3 class="text-12 text-white regular">{{ $t('reporting.search') }}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="analytics-bottom text-center">
                                            <button class="btn btn-theme" @click="$router.push({name: 'SearchReports'})">{{$t('reporting.login')}}</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- //analytics -->
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
   name: 'Reporting'
}
</script>
<style scoped>
    .analytics-header {
        padding: 15px 20px !important;
        border-radius: 8px;
        min-height: 100px;
    }
    .analytics-bottom {
        padding: 15px 10px;
    }
</style>
