<template>
  <div id="localizations-settings">
    <h1>{{$t('settings.localizations')}}</h1>

    <div class="mt-5">
      <div class="form-group">
        <label>{{ $t("settings.language") }}</label>

        <div class="profile-drop-item profile-drop-item-lang">
                  <span :class="$store.state.locale == 'hu' ? 'active' : ''"
                        @click.prevent="changeLocale('hu')">HU</span>
          <span :class="$store.state.locale == 'en' ? 'active' : ''"
                @click.prevent="changeLocale('en')">EN</span>
        </div>

      </div>
      <div class="form-group">
        <label>{{ $t("settings.currency") }}</label>
        <div class="profile-drop-item profile-drop-item-lang">
                                <span v-for="currency in $store.state.currencies"
                                      :key="currency.currency"
                                      :class="$store.state.currency == currency.currency ? 'active' : ''"
                                      @click.prevent="changeCurrency(currency.currency)"
                                >
                                    {{ currency.currency }}
                                </span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Icon from "../Icon";
export default {
  components: {
    Icon,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    changeLocale(lang) {
      const currentLocale = this.$store.state.user.locale
      this.$i18n.locale = lang
      this.$store.commit('changeLocale', lang)
      if (currentLocale != lang)
        this.axios.post('/locale', {locale: lang})
            .then(() => {
              this.$store.state.user.locale = lang
            })
            .catch(e => {
              consle.log(e)
            })
    },
    changeCurrency(currency) {
      this.$store.commit('setCurrency', currency)
    },
  }
}
</script>

<style scoped>

#localizations-settings {
  padding: 50px 25px;
  /*position: fixed;*/
}
h1 {
  font-size: 20px;
  font-weight: 500;
  color: #333e48;
}

form .form-group label {
  color: #333e48;
  font-size: 14px;
  font-weight: 500;
}


.profile-drop-item-lang > span {

}

@media (min-width: 992px) {

.profile-drop-item {
    display: block;
    padding: 3px 15px;
    text-align: left !important;
  }

  .profile-drop-item-lang {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .profile-drop-item-lang span {
    background-color: transparent;
    color: var(--red);
    padding: 3px 10px;
    border-radius: 8px;
    margin-right: 10px;
    border: 2px solid var(--red);
    font-size: 14px;
  }

  .profile-drop-item-lang span.active {
    font-weight: bold;
    background-color: var(--red);
    color: var(--white);
  }
}



</style>
