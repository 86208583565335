<template>
    <div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#" @click.prevent="$router.push({name: 'Home'})">{{ $t('assignment-to-return.homepage') }}</a></li>
                <li class="breadcrumb-item"><a href="#">{{ $t('assignment-to-return.admin') }}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('assignment-to-return.for-fixing') }}</li>
            </ol>
        </nav>

        <AdminMenu/>

        <section v-if="!loading && assignmentsToReturn" id="list-table" class="table-responsive" style="height: 100% !important;">
            <table class="table table-striped">
                <thead class="thead-blue" style="background-color: var(--black)">
                    <tr>
                        <th scope="col">{{ $t('assignment-to-return.for-fixing') }}</th>
                        <th scope="col">{{ $t('assignment-to-return.user') }}</th>
                        <th scope="col">{{ $t('assignment-to-return.loading') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="testAttempt in assignmentsToReturn" :key="testAttempt.id">
                        <td @click.prevent="$router.push({name: 'CorrectTestAttempt', params: {testAttempt: testAttempt.id}})">
                            <span class="left-play">
                                <a href="#">
                                    <img src="assets/img/icons/blue-play.png" alt="">
                                </a>
                            </span>
                            <a href="#">{{testAttempt.test.name}}</a>
                        </td>
                        <td>{{testAttempt.user.name}} </td>
                        <td>{{$store.getters.parseDateTime(testAttempt.finished_at)}}</td>
                    </tr>
                </tbody>
            </table>

        </section>
        <Loading v-else height="40vh" />
    </div>
</template>

<script>
import AdminMenu from '../components/AdminMenu.vue'
import Loading from '../components/Loading.vue'
export default {
    name: "AssignmentsToReturn",
    components: {
        AdminMenu,
        Loading
    },
    mounted() {
        this.loading = true
        this.axios.get('/test-attempts/uncorrected')
            .then(resp => {
                this.assignmentsToReturn = resp.data
            })
            .finally(() => this.loading = false)
    },
    data() {
        return {
            loading: false,
            assignmentsToReturn: null
        }
    }

}
</script>
