<template>
  <div
    class="modal fade"
    id="recommendedCoursesModal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("edit-course.feedback-manage") }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <loading v-if="loading" height="100%" />
          <div v-else>
            <h6>
              <strong>{{ $t("course-modal.title") }}</strong>
            </h6>

            <div
              class="d-flex justify-content-start align-items-center mb-4 mt-2"
            >
              <!-- <select class="form-control mr-3" v-model="selected">
                <option :value="null">{{
                  recommendable.length == 0
                    ? $t("course-modal.list1")
                    : $t("course-modal.list2")
                }}</option>
                <option
                  v-for="course in recommendable"
                  :value="course.id"
                  :key="course.id"
                  >{{ course.title }}</option
                >
              </select> -->
              <Select2
                v-model="selected"
                class="w-100"
                :options="recommendable"
                :settings="{
                  sortResults: recommendable.sort((a, b) =>
                    a.text.localeCompare(b.text)
                  ),
                }"
              />

              <button
                class="btn btn-secondary d-flex ms-2"
                :disabled="submitting || selected == null"
                @click.prevent="addToRecommended(selected)"
              >
                <i class="fas fa-plus" style="width: 22px; height: 22px;"></i>
              </button>
            </div>

            <h6>
              <strong>{{ $t("course.suggested-courses") }}</strong>
            </h6>
            <div class="row">
              <div
                class="col-12 mt-2"
                v-for="course in recommended"
                :key="course.id"
              >
                <p
                  v-if="course.is_inactive"
                  style="font-size: 14px; color: var(--orange)"
                >
                  <strong>{{ $t("course-modal.warning") }}:</strong>
                  {{ $t("course-modal.w-2") }}
                </p>
                <p
                  v-if="!course.active"
                  style="font-size: 14px; color: var(--orange)"
                >
                  <strong>{{ $t("course-modal.warning") }}:</strong>
                  {{ $t("course-modal.w-3") }}
                </p>
                <div class="d-flex align-items-center">
                  <img
                    :src="
                      `${
                        course.cover_image
                          ? $store.state.storageURL +
                            course.cover_image.path +
                            '?token=' +
                            $store.state.token
                          : 'assets/img/template.jpg'
                      }`
                    "
                    style="border-radius: 50%; width: 60px; height: 60px"
                  />
                  <div class="ml-3 mr-2">{{ course.title }}</div>
                  <div
                    style="margin-left: auto; font-size: 22px;"
                    class="mr-3"
                    :style="{ color: submitting ? 'grey' : 'red' }"
                  >
                    <a
                      href="#"
                      :disabled="submitting"
                      @click.prevent="removeFromRecommended(course.id)"
                      ><i class="fas fa-times"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div v-if="recommended.length == 0" class="col-12">
                {{ $t("dla-user-home.no-rec") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-danger"
            data-dismiss="modal"
          >
            {{ $t("common.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "./Loading.vue";
import Select2 from "vue3-select2-component";

export default {
  components: {
    Loading,
    Select2,
  },
  name: "RecommendedCoursesModal",
  props: {
    course: {
      type: Object,
      required: false,
      default: null,
    },
  },
  created() {
    this.getData();
  },
  data() {
    return {
      loading: true,
      recommendable: [],
      recommended: [],
      submitting: false,
      selected: null,
      url: this.course ? `/${this.course.id}` : "",
      myValue: "",
      myOptions: ["op1", "op2", "op3"], // or [{id: key, text: value}, {id: key, text: value}]
    };
  },
  methods: {
    async getData() {
      try {
        const response = (
          await this.axios.get("/courses/recommendable" + this.url)
        ).data.courses;
        for (let item of response) {
          this.recommendable.push({
            id: item.id,
            text: item.title,
          });
        }
        this.recommended = (
          await this.axios.get("/courses/recommended/admin" + this.url)
        ).data.courses;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    addToRecommended(id) {
      const course = this.recommendable.find((c) => c.id == id);
      if (course && !this.submitting) {
        this.submitting = true;
        if (this.course) {
          this.axios
            .post("/courses/" + this.course.id + "/recommended/" + course.id)
            .then((resp) => {
              this.recommendable.splice(
                this.recommendable.findIndex((c) => c.id == course.id),
                1
              );
              this.recommended.push(course);
              this.selected = null;
            })
            .catch((e) => console.log(e))
            .finally(() => (this.submitting = false));
        } else {
          this.axios
            .post("/courses/" + course.id + "/recommended", {
              recommended: true,
            })
            .then((resp) => {
              this.recommendable.splice(
                this.recommendable.findIndex((c) => c.id == course.id),
                1
              );
              this.recommended.push(course);
              this.selected = null;
            })
            .catch((e) => console.log(e))
            .finally(() => (this.submitting = false));
        }
      }
    },
    removeFromRecommended(id) {
      const course = this.recommended.find((c) => c.id == id);
      if (course && !this.submitting) {
        this.submitting = true;
        if (this.course) {
          this.axios
            .delete("/courses/" + this.course.id + "/recommended/" + course.id)
            .then((resp) => {
              this.recommended.splice(
                this.recommended.findIndex((c) => c.id == course.id),
                1
              );
              this.recommendable.push(course);
            })
            .catch((e) => console.log(e))
            .finally(() => (this.submitting = false));
        } else {
          this.axios
            .post("/courses/" + course.id + "/recommended", {
              recommended: false,
            })
            .then((resp) => {
              this.recommended.splice(
                this.recommended.findIndex((c) => c.id == course.id),
                1
              );
              this.recommendable.push(course);
            })
            .catch((e) => console.log(e))
            .finally(() => (this.submitting = false));
        }
      }
    },
    sortData(data) {
      return data.sort((a, b) => a.text.localeCompare(b.text));
    },
  },
};
</script>

<style scoped>
::v-deep .select2-container {
  width: inherit !important;
}
</style>
