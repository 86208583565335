<template>
    <div 
        class="btn-circular" 
        :class="`${disabled ? 'disabled' : ''} overrideClasses`" 
        :style="`height: ${height}; width: ${width}; ${overrideStyles}`"
    >
        <slot>
        </slot>
    </div>
</template>
<script>
export default {
    name: 'CircleButton',
    props: {
        disabled: {
            type: Boolean, 
            optional: true,
            default: false
        },
        width: {
            type: String,
            optional: false
        },
        height: {
            type: String,
            optional: false
        },
        overrideClasses: {
            type: String,
            optional: true,
            default: null
        },
        overrideStyles: {
            type: String,
            optional: true,
            default: ''
        }
    }
}
</script>
<style scoped>
    .btn-circular {
        border-radius: 50%; 
        color: white; 
        background-color: var(--red); 
        display: flex;
        flex-shrink: 0;
        justify-content: center; 
        align-items: center;
        cursor: pointer;
    }
    .disabled {
        cursor: not-allowed;
        background-color: var(--gray);
    }
</style>