<template>
    <div class="modal fade" tabindex="-1" role="dialog" id="createUserQualificationModal" ref="createUserQualificationModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{$t('user-qualifications.add-new')}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form id="createUserQualificationForm" @submit.prevent="submit" class="modal-body">
                <div class="col-12">
                    <label class="required">{{$t('user-qualifications.name')}}</label>
                    <input class="form-control" type="text" :placeholder="$t('user-qualifications.name-placeholder')" v-model="qualification.name" required/>
                </div>
                <div class="col-12 mt-2">
                    <label class="required">{{$t('user-qualifications.field')}}</label>
                    <input class="form-control" type="text" :placeholder="$t('user-qualifications.field-placeholder')" v-model="qualification.field" required/>
                </div>
                <div class="col-12 mt-2 form-group">
                    <label class="required">{{$t('user-qualifications.type')}}</label>
<!--                    <select class="form-control" v-model="qualification.qualification_id" required>
                        <option :value="null" disabled>{{$t('settings.choose-list')}}</option>
                        <option v-for="qual in qualifications" :key="qual.id" :value="qual.id">{{$t(`qualifications.${qual.name}`)}}</option>
                    </select>-->
                  <Select2 v-model="qualification.qualification_id" :options="qualifications" :settings="{width:'100%'}" />
                </div>
                <div class="col-12 mt-2">
                    <label class="required">{{$t('user-qualifications.obtained-at')}}</label>
                    <datetime :placeholder="$t('user-qualifications.obtained-at-placeholder')"
                        :zone="DateTime.local().zoneName" 
                        :valueZone="DateTime.local().zoneName" 
                        v-model="qualification.obtained_at" 
                        required 
                        class="form-control" 
                    />
                </div>
                <div class="col-12 mt-2">
                    <label>{{$t('user-qualifications.upload-attachments')}}</label>
                    <generic-file-uploader 
                        @uploadFinished="addAttachment"
                        @canSend="submitting = $event"
                        containerClasses="row"
                        :hideBarAfterUpload="true"
                        documentContainerClasses="col-6 mb-1 pl-1"
                        imageContainerClasses="col-6 mb-1 pr-1"
                    >
                        <template v-slot:document-btn>
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex" style="flex-direction: column; align-items: center; justify-content: center;">
                                        <i class="fas fa-file-alt font-28 mb-1" />
                                        <span>{{$t('chat-conversation.doc')}}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-slot:image-btn>
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex" style="flex-direction: column; align-items: center; justify-content: center;">
                                        <i class="fas fa-file-image font-28 mb-1" />
                                        <span>{{$t('test-question-assembler.image')}}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </generic-file-uploader>
                </div>
                <div class="col-12">
                    <label class="mb-0">{{$t('user-qualifications.attachments')}}</label>
                    <div v-if="qualification.attachments.length > 0" style="font-size: 80%" class="mb-2"><i>{{$t('user-qualifications.click-to-view')}}</i></div>
                    <div v-for="attachment in qualification.attachments" :key="attachment.id" class="d-flex">
                        <a v-if="attachment.type === 'image'" :href="$store.getters.getStorageURL(attachment.path)" data-fancybox="createUserQualification" :data-caption="attachment.name">
                            <i class="far fa-image"/> {{attachment.name}}
                        </a>
                        <a v-else-if="attachment.type === 'document'" 
                            :href="$store.getters.getStorageURL(attachment.path)"
                            target="_blank"
                            @click="downloadAttachment(attachment, $event)">
                            <i class="fas fa-file-alt"/> {{attachment.name}}
                        </a>
                        <div v-if="!submitting" @click="removeAttachment(attachment)" class="ml-2" style="cursor: pointer;">
                            <i class="fas fa-trash-alt text-danger" /> 
                        </div>
                        <div v-else class="ml-2" style="cursor: not-allowed;">
                            <i class="fas fa-trash-alt text-secondary" /> 
                        </div>
                    </div>
                    <p v-if="qualification.attachments.length === 0">{{$t('user-qualifications.no-attachments')}}</p>
                </div>
            </form>
            <div class="modal-footer">
                <button class="btn btn-primary" :disabled="submitting" form="createUserQualificationForm">{{$t('user-qualifications.add')}}</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('user-qualifications.cancel')}}</button>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import { DateTime } from 'luxon'
import GenericFileUploader from '../../GenericFileUploader.vue'
import Datetime from '../../DateTimePicker/Datetime.vue'
import Select2 from "vue3-select2-component";
export default {
    components: { GenericFileUploader, Datetime, Select2 },
    name: 'CreateUserQualificationModal',
    emits: ['created'],
    created() {
        this.axios.get('/qualifications')
            .then(resp => {
                this.qualifications = resp.data.map(qual => {
                    return {
                        id: qual.id,
                        text: this.$t('qualifications.'+qual.name)
                    }
                })
            })
    },
    data() {
        return {
            DateTime: DateTime,
            email: null,
            qualifications: [],
            qualification: {
                name: null,
                field: null,
                qualification_id: null,
                obtained_at: null,
                attachments: []
            },
            submitting: false,
        }
    },
    methods: {
        addAttachment(file) {
            const attachment = {
                id: file.uuid,
                type: file.type,
                name: file.file.name,
                path: `/download/${file.type}/${file.uuid}`
            }
            this.qualification.attachments.push(attachment)
        },
        removeAttachment(attachment) {
            const idx = this.qualification.attachments.findIndex(a => a.id == attachment.id)
            if(idx !== -1) {
                this.submitting = true
                let config = {
                    method: 'POST',
                    data: null,
                    url: `/upload/${attachment.type}/${attachment.id}/abort`,
                    baseURL: process.env.VUE_APP_STORAGE_URL,
                };
                this.axios(config)
                    .then(() => this.qualification.attachments.splice(idx, 1))
                    .catch(e => console.log(e))
                    .finally(() => this.submitting = false)
            }
        },
        downloadAttachment(attachment, event) {
            if(!this.$store.isNative) return;
            event.preventDefault()
            this.downloading = true;
            this.axios.get(this.$store.getters.getStorageURL(attachment.path), {responseType: 'blob'})
                .then(resp => {
                    const name = this.element.course_multimedia.name+'.pdf'
                    this.$store.commit('downloadFileMobile', {name, mime: 'application/pdf', data: resp.data})
                })
                .catch(e => console.log(e.response))
                .finally(() => this.downloading = false)
        },
        submit() {
            this.submitting = true
            this.axios.post('/user-qualifications', this.qualification)
                .then(resp => {
                    this.$emit('created', resp.data.user_qualification)
                    $(this.$refs.createUserQualificationModal).modal('hide')
                    this.qualification = {
                        name: null,
                        field: null,
                        qualification_id: null,
                        obtained_at: null,
                        attachments: []
                    }
                })
                .catch(e => console.log(e))
                .finally(() => {
                    this.submitting = false
                })
        }
    }
}
</script>
<style scoped>
.card {
    box-shadow: 0px 0px 0px grey;
    transition:  box-shadow .1s ease-out;
    cursor: pointer;
}
.card:hover {
    text-shadow:0px 0px 1px rgb(207, 206, 206) !important;
    box-shadow: 1px 1px 5px rgb(207, 206, 206) !important;
    transition:  box-shadow .1s ease-in;
}

</style>