<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('course-catalog.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Feedback'})">{{ $t('feedback.name') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('feedback.resolved') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->
        <div class="container mt-5">
            <button type="button" v-if="$store.state.user.role == 'Admin'" class="btn btn-outline-primary mb-2" @click="$router.push({name: 'FeedbackList'})">{{$t('feedback.unresolved')}}</button>
            <div class="row mt-4" v-if="!loading">
                <div v-for="feedback in feedbackList.data" :key="feedback.id" class="col-12 mb-4 mb-md-0 col-md-6">
                    <div class="card" style="border: 1px solid var(--black)">
                        <div class="card-header" style="background-color:  var(--black) !important;">
                            <div class="d-flex align-items-center" style="color: white !important; cursor: pointer; width: fit-content;" @click.prevent="$router.push({name: 'Overview', params: {id: feedback.user.id}})">
                                <img v-if="feedback.user.avatar != null" style="width: 50px !important; height: 50px !important; border-radius: 50%" class="img-fluid" :src="$store.state.storageURL+feedback.user.avatar.path+'?token='+$store.state.token">
                                <Avatar v-else :username="feedback.user.name" :size="50" />
                                <div class="ml-2">
                                    <strong>{{feedback.user.name}}</strong>
                                    <br>
                                    <span>{{$store.getters.parseDateTime(feedback.created_at)}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <h5 class="card-title mb-4" style="font-weight: bold">{{$t('feedback.'+feedback.feedback_type.name)}}</h5>
                            <p class="card-text">{{feedback.feedback}}</p>
                        </div>
                        <div class="card-footer">
                            <label><strong>{{$t('feedback.resolution-text')}}</strong></label>
                            <textarea rows="2" disabled :value="feedback.resolution_text ?? 'No resolution text provided'" class="form-control"></textarea>
                            <div class="mt-1"><strong>{{$t('feedback.resolved-at')}} {{$store.getters.parseDateTime(feedback.resolved_at)}}</strong></div>
                            <button :disabled="submitting" @click.prevent="resolve(feedback)" class="btn btn-secondary mt-3">{{$t('feedback.unresolve')}}</button>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6" v-if="feedbackList.data.length == 0">
                    {{$t('feedback.no-resolved')}}
                </div>
            </div>
            <Loading v-else height="70vh" />
            <div class="row mt-4" v-if="feedbackList.data && feedbackList.data.length > 0">
                <regular-paginator :paginator="feedbackList" @loadPage="loadFeedback" classes="w-100 mb-2" />
            </div>
        </div>
    </div>
</template>
<script>
import Loading from '@/components/Loading.vue';
import Avatar from '@/components/Avatar.vue';
import RegularPaginator from '@/components/RegularPaginator.vue';
export default {
    name: 'ResolvedFeedbackList',
    components: {
        Loading,
        Avatar,
        RegularPaginator
    },
    mounted() {
        this.axios.get('/feedback/resolved')
            .then(resp => this.feedbackList = resp.data)
            .finally(() => this.loading = false)
    },
    data() {
        return {
            feedbackList: [],
            loading: true,
            submitting: false,
        }
    },
    methods: {
        loadFeedback(page) {
            this.loading = true
            this.axios.get('/feedback/resolved', {params: {page: page}})
                .then(resp => this.feedbackList = resp.data)
                .finally(() => {
                    this.loading = false
                })
        },
        resolve(feedback) {
            this.submitting = true
            this.axios.post('/feedback/'+feedback.id+'/unresolve')
                .then(resp => {
                    const idx = this.feedbackList.data.findIndex(f => f.id == feedback.id)
                    if(idx != -1) this.feedbackList.data.splice(idx, 1)
                })
                .finally(() => this.submitting = false)
        }
    }
}
</script>