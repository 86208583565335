<template>
  <button
    v-if="click"
    :disabled="disabled"
    type="submit"
    @click.prevent="click"
    class="secondary-button"
    :class="{ 'flex-row-reverse': rightIcon }"
  >
    <Icon v-show="icon" :name="icon" :is-disabled="disabled" />
    <span>{{ text }}</span>
  </button>
  <button
    v-else
    :disabled="disabled"
    type="submit"
    class="secondary-button"
    :class="{ 'flex-row-reverse': rightIcon }"
  >
    <Icon v-show="icon" :name="icon" :is-disabled="disabled" />
    <span>{{ text }}</span>
  </button>
</template>
<script>
import Icon from "../Icon";

export default {
  name: "TransparentButton",
  components: { Icon },
  props: {
    disabled: {},
    text: {
      type: String,
      default: "Submit",
    },
    icon: {
      type: String,
    },
    click: {},
    rightIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
button.secondary-button {
  background-color: transparent;
  border-radius: 5px;
  border: 1.5px solid #d62b33;
  display: flex;
  padding: 5px 20px;
  height: 38px;
  gap: 10px;
  align-items: center;
}

button.secondary-button span {
  color: #d62b33;
  font-size: 18px;
  font-weight: 600;
}

:deep(button.secondary-button .icon svg path) {
  fill: #d62b33 !important;
  color: #d62b33 !important;
}

button.secondary-button:hover {
  background-color: #d62b33;
  border: 1.5px solid #d62b33;
}

button.secondary-button:hover span {
  color: #fff;
}

::v-deep button.secondary-button:hover .icon svg path {
  fill: #fff !important;
}

button.secondary-button:disabled {
  cursor: not-allowed;
  border: 1.5px solid #9f999a;
  background-color: transparent;
}
button.secondary-button:disabled span {
  color: #9f999a;
}

:deep(button.secondary-button:disabled .icon svg path) {
  color: #9f999a;
  fill: #9f999a !important;
}
</style>
