<template>
    <div v-if="!loading" id="carousel-top" ref="carousel" class="carousel slide">
        <div class="carousel-inner">
            <div class="carousel-item active"
            :style="{backgroundImage: `url('${membership.cover_image ? $store.getters.getStorageURL(membership.cover_image.path) : '/assets/img/template.jpg'}')`}"
            style="linear-gradient(0deg,transparent, transparent, #333E48 100%);">
                <div class="carousel-caption d-flex align-items-center justify-content-center">
                  <div class="container">
                    <div class="row">
                      <div class="col-12">
                        <h5 style="cursor: pointer;" @click.prevent="buyMembership">
                          <div class="d-flex align-items-center justify-content-center">
                            <div>{{$t('membership.buy')}}</div>
                            <div v-if="submitting" class="ml-1 spinner-border text-primary spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12" style="display: flex;justify-content: center;">
                        <PrimaryButton  :text="$t('membership.add-cart')" :disabled="submitting" :click="buyMembership" />
                      </div>
                    </div>
                  </div>

                </div>
            </div>
        </div>
    </div>
    <div v-else class="ssc-square" style="border-radius: 5px;"></div>
</template>
<script>
import PrimaryButton from "./settings/PrimaryButton.vue";

export default {
    name: 'BuyMembership',
  components: {PrimaryButton},
    mounted() {
        this.axios.get('/memberships/1')
            .then(resp => {
                this.membership = resp.data.membership
            })
            .finally(() => this.loading = false)
    },
    data() {
        return {
            loading: true,
            membership: null,
            carousel: this.$refs.carousel,
            submitting: false
        }
    },
    methods: {
        buyMembership() {
            if(this.submitting) return;
            if(this.$store.state.user.cart_items.some(c => c.cartable_type == 'App\\Models\\Membership' && c.cartable_id == this.membership.id)) {
                this.$router.push({name: 'Cart'})
                return;
            }
            this.submitting = true
            this.axios.post(`/memberships/${this.membership.id}/cart`)
                .then(resp => {
                    const cartItem = resp.data.cart_item
                    this.$store.state.user.cart_items.push(cartItem)
                    this.$router.push({name: 'Cart'})
                })
                .catch(e => console.log(e))
                .finally(() => this.submitting = false)
        }
    },
    updated() {
        if(this.$refs.carousel) {
            $(this.$refs.carousel).carousel({
                interval: 10000,
                ride: 'carousel'
            })
        }
    },
    beforeUnmount() {
        if(this.$refs.carousel) {
            $(this.$refs.carousel).carousel('dispose')
        }
    },
}
</script>
<style scoped>
#carousel-top, .ssc-square  {
    margin-bottom: 25px;
}
@media (max-width: 992px) {
    #carousel-top, .ssc-square  {
        padding-top: 0px !important;
        margin-top: -40px !important;
        margin-left: -40px;
        margin-right: -40px;
    }
}
@media (max-width: 706px) {
    #carousel-top, .ssc-square {
        padding-top: 0px !important;
        margin-left: -15px;
        margin-right: -15px;
        margin-top: -15px !important;
    }
}

.ssc-square {
    display: block;
    background-color: rgba(0,0,0,.17);
    width: 100%;
    height: 600px;
    margin: 0px !important;
}
.ssc-square, .ssc-head-line, .ssc-line, .ssc-circle {
    position: relative;
    overflow: hidden;
}
.ssc-square:after, .ssc-head-line:after, .ssc-line:after, .ssc-circle:after {
    content: "";
    -webkit-animation: ssc-loading 0.7s infinite;
    animation: ssc-loading 0.7s infinite;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    z-index: 1;
    background: -webkit-gradient(linear,left top,right top,from(transparent),color-stop(rgba(255,255,255,0.3)),to(transparent));
    background: linear-gradient(90deg,transparent,rgba(255,255,255,0.3),transparent);
}
@keyframes ssc-loading {
	from {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%)
	}
	to {
		-webkit-transform: translateX(100%);
		transform: translateX(100%)
	}
}

</style>
