<template>
  <div v-if="$store.state.user">
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('course-catalog.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('cart.cart') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->

    <section id="content-2">
      <div class="container">
        <div id="pay-list" v-if="!loading">
          <div v-if="$store.state.user.cart_items.length > 0" class="row">
            <div id="cart-items" class="col-lg-8 col-12 d-flex flex-column">
              <div v-for="item in $store.state.user.cart_items" :key="item.id" class="cart-item-container">
                <div v-if="!orderInProgress">
                  <div v-if="item.cartable_type !== 'App\\Models\\Membership'" class="remove-cart-container"
                       @click.prevent="removeFromCart(item)" :class="submitting ? 'disabled' : ''">
                    <i class="fas fa-times"/>
                  </div>
                </div>
                <div class="cart-item">
                  <div class="cart-item-image">
                    <img :src="item.cartable.cover_image
                      ? $store.getters.getStorageURL(
                        item.cartable.cover_image.path
                      )
                      : '/assets/img/demo/training.png'
                      "/>
                  </div>
                  <div class="cart-item-content pb-2 pt-2">
                    <div>
                      <div style="font-weight: 600; color: white; font-size: 18px">
                        {{ item.cartable.title }}
                      </div>
                      <div style="font-weight: 400; color: white; font-size: 14px">
                        {{
                          item.cartable.course_type &&
                          item.cartable_type != "App\\Models\\AttemptPackage"
                              ? $t(
                                  `course-types.${item.cartable.course_type.name}`
                              )
                              : !item.cartable.course_type &&
                              item.cartable_type ==
                              "App\\Models\\AttemptPackage"
                                  ? $t("cart.package-fee")
                                  : "Membership fee"
                        }}
                      </div>
                    </div>
                    <div v-if="item.cartable_type === 'App\\Models\\Course'">
                      <div style="font-weight: 400; color: white; font-size: 11px">
                        {{ $t("course.begin") }}:
                        <strong>
                          {{
                            item.cartable.start_date
                                ? $store.getters.parseDateTime(
                                    item.cartable.start_date
                                )
                                : $t("course.no")
                          }}
                        </strong>
                      </div>
                      <div style="font-weight: 400; color: white; font-size: 11px">
                        {{ $t("course.end") }}:
                        <strong>
                          {{
                            item.cartable.end_date
                                ? $store.getters.parseDateTime(
                                    item.cartable.end_date
                                )
                                : $t("course.no")
                          }}
                        </strong>
                      </div>
                    </div>
                    <div v-if="item.cartable_type === 'App\\Models\\AttemptPackage'
                        ">
                      <div style="font-weight: 400; color: white; font-size: 11px">
                        {{ $t("cart.attempt-count") }}:
                        <strong>
                          {{ item.cartable.attempt_count }}
                        </strong>
                      </div>
                      <div style="font-weight: 400; color: white; font-size: 11px">
                        {{ $t("cart.attempt-price") }}:
                        <strong>
                          {{ item.cartable.net_price }}
                        </strong>
                      </div>
                    </div>
                    <div>
                      <div v-if="item.cartable.net_price" style="font-weight: 600; color: white; font-size: 20px">
                        {{ huFormat(item.cartable.net_price) }}
                      </div>
                      <div style="font-weight: 400; color: white; font-size: 11px">
                        <span>{{ huFormat(item.cartable.net_price) }} +{{
                            $t("cart.vat")
                          }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-12 order-summary-container">
              <div class="order-summary">
                <div class="d-flex justify-content-between">
                  <div style="font-weight: 600; color: #757575; font-size: 20px">
                    {{ $t("cart.netto-total") }}
                  </div>
                  <div style="font-weight: 600; font-size: 20px">
                    {{ huFormat(netTotal) }}
                  </div>
                </div>
                <div style="border: 1px dashed #dadada" class="mt-2 mb-2"></div>
                <div v-if="orderInProgress" class="mb-2">
                  <p>{{ $t("cart.msg") }}</p>
                  <p v-if="orderInProgress.checkout_url">
                    {{ $t("cart.click") }}
                    <a :href="orderInProgress.checkout_url" target="_blank"
                       style="text-decoration: underline; color: var(--black)">
                      {{ $t("cart.here") }}</a>
                    {{ $t("cart.continue") }}
                  </p>
                  <p v-if="orderInProgress.checkout_url">
                    {{ $t("cart.click") }}
                    <span @click="cancelOrder(orderInProgress.id) " target="_blank"
                       style="text-decoration: underline; color: var(--black);cursor:pointer">
                      {{ $t("cart.here") }}</span>
                    {{ $t("cart.cancel") }}
                  </p>
                  <p v-else>
                    {{ $t("cart.click") }}
                    <a @click.prevent="
                      $router.push({
                        name: 'AfterPayment',
                        params: {
                          paymentRequestId:
                            orderInProgress.payment_request_id,
                        },
                        query: { paymentId: orderInProgress.payment_id },
                      })
                      " target="_blank" style="text-decoration: underline; color: var(--black)">
                      {{ $t("cart.here") }}</a>
                    {{ $t("cart.continue") }}
                  </p>
                </div>
                <div class="d-flex justify-content-center">
                  <router-link :to="{ name: 'CheckoutUserData' }" class="btn btn-theme d-flex align-items-center"
                               :disabled="submitting || orderInProgress"
                               style="color: white; font-weight: 600; font-size: 16px">
                    <div class="mr-3">{{ $t("cart.pay") }}</div>
                    <div>
                      <i class="fas fa-arrow-right"/>
                    </div>
                  </router-link>
                  <!-- <button type="button" class="btn btn-theme d-flex align-items-center"
                    :disabled="submitting || orderInProgress" @click="$router.push({ name: 'CheckoutUserData' })"
                    style="color: white; font-weight: 600; font-size: 16px">
                    <div class="mr-3">{{ $t("cart.pay") }}</div>
                    <div>
                      <i class="fas fa-arrow-right" />
                    </div>
                  </button> -->
                </div>
              </div>
            </div>

            <div id="empty-cart-section" class="d-flex justify-content-center" style="
              margin-top: 12vh;
              flex-direction: column;
              align-items: center;
            " v-if="$store.state.user.cart_items.length === 0">
              <h3 style="
                width: 100%;
                text-align: center;
                margin-bottom: 20px;
                margin-top: 50px;
              ">
                {{ $t("cart.empty") }}
              </h3>
              <button class="btn btn-theme mt-3" @click.prevent="$router.push({ name: 'MyOrders' })"
                      style="width: fit-content">
                {{ $t("cart.my-orders") }}
              </button>
              <div></div>
            </div>
          </div>
        </div></div>
    </section>
  </div>
</template>
<script>
import Loading from '@/components/Loading.vue'

export default {
  name: 'Cart',
  components: {
    Loading
  },
  mounted() {
    this.loading = true
    this.axios.get('/user')
        .then(resp => {
          this.$store.commit('setUser', resp.data);
        })
        .finally(() => this.loading = false)
  },
  computed: {
    netTotal() {
      if (this.$store.state.user.cart_items.length > 0) {
        return this.$store.state.user.cart_items.reduce((acc, item) => acc + parseFloat(item.cartable.net_price), 0)
      }
      return 0
    },
    vatSum() {
      if (this.$store.state.user.cart_items.length > 0) {
        return this.$store.state.user.cart_items.reduce((acc, item) => acc + (item.cartable.gross_price - item.cartable.net_price), 0)
      }
      return 0
    },
    discount() {
      if (this.$store.state.checkout && this.$store.state.checkout.coupon_code) {
        return this.$store.state.checkout.coupon_code.percentage_based ? this.netTotal * (this.$store.state.checkout.coupon_code.discount / 100) : this.$store.state.checkout.coupon_code.discount
      }
      return 0
    },
    grossTotal() {
      return this.netTotal + this.vatSum - this.discount
    },
    orderInProgress() {
      return this.$store.state.user.orders.find(o => o.status == 'Prepared' || o.status == 'Started' || o.status == 'InProgress');
    }
  },
  data() {
    return {
      submitting: false,
      loading: false,
    }
  },
  methods: {
    removeFromCart(item) {
      this.submitting = true
      const url = item.cartable_type == 'App\\Models\\Course' ? `/courses/${item.cartable.id}/cart` : (item.cartable_type == "App\\Models\\AttemptPackage" ? `/attempt-package/${item.cartable.id}/remove-from-cart` : `/memberships/${item.cartable.id}/cart`)
      this.axios.delete(url)
          .then(resp => this.$store.state.user.cart_items = resp.data.cartItems)
          .catch(e => console.log(e))
          .finally(() => this.submitting = false)
    },
    huFormat(sum) {
      return this.$store.getters.formatPrice(sum)
    },
    cancelOrder(id) {
      this.axios.post('/payment/cancel',{order_id:id})
          .then(resp => location.reload())
          .catch(e => console.log(e))
    },
    sum() {
      let sum = 0;
      this.$store.state.user.cart_items.forEach(item => {
        sum += item.cartable.gross_price
      })
      if (this.$store.state.checkout && this.$store.state.checkout.coupon_code) {
        sum -= this.$store.state.checkout.coupon_code.percentage_based ? sum * (this.$store.state.checkout.coupon_code.discount / 100) : this.$store.state.checkout.coupon_code.discount
      }
      return sum >= 0 ? sum : 0
    },
  }
}
</script>
<style>
.order-summary-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end
}

.order-summary {
  margin-bottom: 1.6rem;
}

.cart-item-container {
  width: 100%;
  background-color: #768692;
  filter: drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.1)) drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.2));
  border-radius: 5px;
  padding: 10px;
  position: relative;
  margin-bottom: 1.6rem;
}

.cart-item-image {
  border-radius: 5px;
  margin-right: 20px;
}

.cart-item-image img {
  width: 275px;
  height: 170px;
  border-radius: 5px;
  object-fit: cover;
}

.cart-item-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.remove-cart-container {
  position: absolute;
  font-size: 15px;
  padding: 5px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--red);
  right: 8px;
  top: 8px;
  color: white;
  cursor: pointer;
}

.remove-cart-container.disabled {
  cursor: not-allowed;
  opacity: 50%;
}

.cart-item {
  display: flex;
}

@media (max-width: 576px) {
  .cart-item {
    flex-direction: column;
  }

  .cart-item-image, .cart-item-image img {
    width: 100%;
    margin-right: 0px;
  }
}
</style>
