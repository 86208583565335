<template>
  <div class="modal fade" id="multimediaListModal" ref="multimediaListModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Attach multimedia - {{ uploadType }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="search" class="mr-1">Search:</label>
            <input type="text" name="search" id="search" placeholder="ex. EMS" v-model="search" @change="onSearch" />
          </div>
          <RegularPaginator :paginator="{current_page: current_page, last_page: last_page}" @loadPage="getMultiMedia"/>
          <span>Pages: {{ last_page }}</span>
          <ul class="list-group">
            <li v-for="media in multimedia" :key="media.id" class="list-group-item pointer" @click="()=>attach(media)">
              <div v-if="media.type==='image'">
                <img :src="$store.state.storageURL+media.path+'?token='+$store.state.token" :alt="media.name"
                     style="max-height: 60px"/>
                <p>{{ media.name }}</p>
              </div>
              <div v-else-if="media.type==='video'">
                <video :id="'cm'+media.id"
                       :ref="'cm'+media.id" controls
                       style="max-width: 300px"
                       controlsList="nodownload">
                  <source :src="$store.state.storageURL+media.path+'?token='+$store.state.token">
                </video>
                <p>{{ media.name }}</p>
              </div>
              <div v-else-if="media.type==='audio'">
                <audio :id="'cm'+media.id" :ref="'cm'+media.id"
                       controls controlsList="nodownload">
                  <source
                      :src="$store.state.storageURL+media.path+'?token='+$store.state.token">
                </audio>
                <p>{{ media.name }}</p>
              </div>
              <div v-else class="d-flex align-items-center" style="align-items: center;">
                <p class="font-16"
                    style="font-size: 20px !important; display: flex; align-items: center; margin-bottom: 0px;">
                  <i class="fas fa-file-alt mr-2 font-25"
                     style="font-size: 25px !important; width: 25px !important; color: var(--red);"></i>
                  <a style="color: var(--black);"
                     :href="$store.state.storageURL+media.path+'?token='+$store.state.token"
                     target="_blank">{{ media.name }}</a>
                </p>
              </div>
            </li>
          </ul>
          <span>Pages: {{ last_page }}</span>
          <RegularPaginator :paginator="{current_page: current_page, last_page: last_page}" @loadPage="getMultiMedia"/>
        </div>
        <div class="modal-footer">
          <TransparentButton data-dismiss="modal" :click="()=>confirm" :text="$t('common.attach')"/>
          <PrimaryButton data-dismiss="modal" :click="()=>confirm" :text="$t('common.upload')"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PrimaryButton from "./settings/PrimaryButton.vue"
import TransparentButton from "./settings/TransparentButton.vue"
import RegularPaginator from "./RegularPaginator.vue";

export default {
  name: 'MultiMediaList',
  components: {RegularPaginator, PrimaryButton, TransparentButton},
  emits: ["attach"],
  props: {
    confirm: {},
    uploadType: {
      type: String
    }
  },
  data() {
    return {
      storageURL: process.env.VUE_APP_STORAGE_URL,
      multimedia: Object,
      triggerWatcher: false,
      search: "",
      page: 1,
      current_page: 1,
      last_page: 1,
      token: this.axios.defaults.headers.common.Authorization.split(" ")[1],
    };
  },
  methods: {
    attach: function (media) {
      $("#multimediaListModal").modal("hide");
      this.$emit("attach", media);
    },
    onSearch(){
      this.getMultiMedia(1)
    },
    getMultiMedia(page = 1) {
      console.log("getMultiMedia", page);
      this.current_page = page;
      this.axios.get('/multimedia?type=' + this.uploadType + "&page=" + page+ "&search=" + this.search).then(resp => {
        this.multimedia = resp.data.multimedia.data;
        this.current_page = resp.data.multimedia.current_page;
        this.last_page = resp.data.multimedia.last_page;
      })
    }
  },
  watch: {
    "uploadType": function (newVal, oldVal) {
      console.log("uploadType changed", newVal, oldVal);
      this.page = 1;
      this.current_page = 1;
      this.last_page = 1;
      this.search = "";
      this.multimedia = [];
      this.getMultiMedia();
    }
  },
}
</script>
<style scoped>

</style>
