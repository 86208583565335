<template>
  <!-- BREADCRUMB -->
  <div class="margin-sticky-fix"></div>
  <div class="breadcrumb bg-dark-gray">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="flex-wrap d-flex">
            <div class="d-flex mr-4 pointer">
              <span class="text-16 text-white pt-3 pb-3"
                    @click.prevent="$router.push({name: 'Home'})">{{ $t('questionnaires.homepage') }}</span>
            </div>
            <div class="d-flex mr-4">
              <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
            </div>
            <div class="d-flex pointer">
              <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Questionnaires'})">{{ $t('questionnaires.questionnaires') }}</span>
            </div>
            <div class="d-flex mr-4">
              <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
            </div>
            <div class="d-flex">
              <span class="text-16 text-white pt-3 pb-3">{{testAttempt && !loading ? testAttempt.test.name:$t('questionnaires.questionnaire') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- //BREADCRUMB -->
  <div id="content-2">
    <div class="container">
      <div class="row">
        <div class="col-10">
          <div v-if="testAttempt && !loading">
            <h1>{{ testAttempt.test.name }}</h1>
            <fillable-test-element :test="testAttempt.test" :attempts="[testAttempt]" @pullback="$emit('pullback')"
                                   @loadCourse="$emit('loadCourse')"/>
          </div>
          <loading v-else height="70vh"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActiveTestAttempt from "./ActiveTestAttempt";
import FillableTestElement from "../components/CourseMaterialListElements/FillableTestElement";
import Loading from "../components/Loading";

export default {
  name: "Questionnaire",
  components: {
    ActiveTestAttempt,
    FillableTestElement,
    Loading
  },
  mounted() {
    this.axios.get('/tests/' + this.$route.params.id + '/attempts/' + this.$route.params.attempt).then(resp => {
      console.log("resp", resp.data);
      this.testAttempt = resp.data.testAttempt;
      this.loading = false;
    }).finally(() => {
      this.loading = false
    });
  },
  data() {
    return {
      loading: true,
      testAttempt: null,
      afterAttemptData: {
        show: false,
        test: null,
        attempt: null,
      },
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>