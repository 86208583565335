<template>
<div>
    <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="flex-wrap d-flex">
                        <div class="d-flex mr-4 pointer">
                            <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('edit-test.homepage') }}</span>
                        </div>
                        <div class="d-flex mr-4">
                            <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                        </div>
                        <div class="d-flex mr-4" v-if="!loading && customPage">
                            <span class="text-16 text-white pt-3 pb-3">{{ customPage.title[$store.state.locale] ? customPage.title[$store.state.locale] : customPage.title['en'] }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- //BREADCRUMB -->
    <div class="container mt-3">
        <div class="row" v-if="!loading">
            <div v-if="customPage" class="col-12" v-html="customPage.content[$store.state.locale] ? customPage.content[$store.state.locale] : customPage.content['en']"></div>
            <div v-else style="font-size: 35px;">404</div>
        </div>
        <loading height="50vh" v-else />
    </div>
</div>
</template>
<script>
import Loading from '../components/Loading.vue'
export default {
    name: 'CustomPage',
    components: {Loading},
    created() {
        const path = this.$route.path
        this.customPage = this.$store.state.customPages.find(c => c.link == process.env.VUE_APP_FRONTEND_URL + path) ?? {id: path.substr(1)}
    },
    computed: {
        stateLoading() {
            return this.$store.state.customPagesLoading
        },
        loading() {
            return this.pageLoading
        }
    },
    watch: {
        stateLoading(val) {
            if(val == false) {
                const path = this.$route.path
                this.customPage = this.$store.state.customPages.find(c => c.link == process.env.VUE_APP_FRONTEND_URL + path)
            }
        },
        customPage(val, prev) {
            if(val && val?.id !== prev?.id) {
                this.axios.get('/custom-pages/'+val.id)
                    .then(resp => {
                        this.customPage = resp.data.custom_page
                    })
                    .catch(e => {console.log(e); this.customPage = null})
                    .finally(() => this.pageLoading = false)
            }
        }
    },
    data() {
        return {
            customPage: null,
            pageLoading: true,
        }
    }
}
</script>