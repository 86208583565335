<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({ name: 'Home' })">{{
                  $t('course-catalog.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('course-catalog.course-list') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <section id="content-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="row mb-5 mt-3 justify-content-center">
              <div class="col-md-6 col-12">
                <label class="text-16 text-dark-gray semi-bold mb-0">{{ $t('chat.search') }}</label>
                <form @submit.prevent="search">
                  <input type="text" :disabled="loading" name="string" class="form-control" />
                </form>
              </div>
            </div>
            <section id="trainings" v-if="!loading">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('common.course-groups') }}</h2>
                </div>
                <div v-for="courseGroup in courseGroups" :key="courseGroup.id" class="col-lg-3 col-md-4 col-sm-6 col-12"
                  style="cursor: pointer;"
                  @click="$router.push({ name: 'CourseCatalogGrouped', params: { group: courseGroup.id } })">
                  <course-group-card :courseGroup="courseGroup" />
                </div>
                <div v-if="courseGroups.length === 0" class="col-12 text-title-gray weight-500 text-16 mb-4">
                  {{ searchString ? $t('course-catalog.search-empty-group') : $t('course-catalog.empty-group') }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-12">
                  <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('common.workshops') }}</h2>
                </div>
                <div v-for="workshop in workshops" :key="workshop.id" class="col-lg-3 col-md-4 col-sm-6 col-12"
                  style="cursor: pointer;" @click="$router.push({ name: 'Course', params: { id: workshop.id } })">
                  <course-card :course="workshop" :showBadges="true" />
                </div>
                <div v-if="courseGroups.length === 0" class="col-12 text-title-gray weight-500 text-16 mb-4">
                  {{ searchString ? $t('course-catalog.search-empty-group') : $t('course-catalog.empty-group') }}
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('course-catalog.available-courses') }}</h2>
                </div>
                <div v-for="course in courses" :key="course.id" class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4"
                  style="cursor: pointer;" @click="$router.push({ name: 'Course', params: { id: course.id } })">
                  <course-card :course="course" :showBadges="true" />
                </div>
                <div v-if="courses.length === 0" class="col-12 mt-2 text-left">
                  <h3 class="text-title-gray weight-500 text-16"> {{
                    searchString ? $t('course-catalog.search-empty-course') : $t('course-catalog.empty-course')
                  }} </h3>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('course.coming-soon') }}</h2>
                </div>
                <div v-for="course in comingSoon" :key="course.id" class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4"
                  style="cursor: pointer;" @click="$router.push({ name: 'Course', params: { id: course.id } })">
                  <course-card :course="course" :showBadges="true" />
                </div>
                <div v-if="comingSoon.length === 0" class="col-12 mt-2 text-left">
                  <h3 class="text-title-gray weight-500 text-16"> {{
                    searchString ? $t('course-catalog.search-empty-course') : $t('course-catalog.empty-course')
                  }} </h3>
                </div>
              </div>
            </section>
            <Loading v-else height="70vh" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import CourseCard from '@/components/CourseCard'
import CourseGroupCard from '../components/CourseGroupCard.vue'

export default {
  name: 'CourseCatalog',
  components: {
    Loading,
    CourseCard,
    CourseGroupCard
  },
  mounted() {
    this.getCourses()
  },
  data() {
    return {
      loading: true,
      courses: Array(),
      comingSoon: Array(),
      searchString: '',
      courseGroups: [],
      workshops: [],
      token: this.axios.defaults.headers.common.Authorization.split(' ')[1],
      storageURL: process.env.VUE_APP_STORAGE_URL,
    }
  },
  computed: {
    coursesAndGroups() {
      return [...this.courses.filter(c => c.course_group_id === null), ...this.courseGroups]
    },
    user() {
      return this.$store.state.user
    },
    nonMember() {
      return this.user && (this.user.role == 'Member User' || this.user.role == 'User') && this.user.hasActiveMembership == false
    }
  },
  methods: {
    search(e) {
      const elements = new FormData(e.currentTarget)
      const string = elements.get("string")
      if (string !== this.searchString) {
        this.searchString = string;
        this.getCourses()
      }
    },
    getCourses() {
      this.loading = true;
      this.axios.get('/courses', { params: { search: this.searchString } })
        .then(resp => {
          this.courses = this.orderCoursesByPrerequisites(resp.data.courses);
          this.courseGroups = resp.data.course_groups.filter((group) => group.parent_id === null);
          this.comingSoon = resp.data.coming_soon;
          this.workshops = resp.data.workshops;

        })
        .finally(() => this.loading = false)
    },
    isCourse(object) {
      return object.hasOwnProperty('course_group_id');
    },
    setFav(index) {
      this.axios.post(`/courses/${this.courses[index].id}/toggle-favorite`)
      if (this.isFav(this.courses[index].id)) {
        const idx = this.$store.state.user.favorite_courses.indexOf(course => course.id === id);
        this.$store.state.user.favorite_courses.splice(idx, 1)
      } else
        this.$store.state.user.favorite_courses.push(this.courses[index])
    },
    orderCoursesByPrerequisites(courses) {
      let orderedCourses = [];
      let coursesWithoutPrerequisites = courses.filter(course => course.prerequisites.length === 0);
      let coursesWithPrerequisites = courses.filter(course => course.prerequisites.length > 0);
      while (coursesWithoutPrerequisites.length > 0) {
        let course = coursesWithoutPrerequisites.pop();
        orderedCourses.push(course);
        coursesWithPrerequisites = coursesWithPrerequisites.filter(c => !c.prerequisites.includes(course.id));
        coursesWithoutPrerequisites = coursesWithoutPrerequisites.concat(coursesWithPrerequisites.filter(c => c.prerequisites.length === 0));
        coursesWithPrerequisites = coursesWithPrerequisites.filter(c => c.prerequisites.length > 0);
      }
      orderedCourses.push(...coursesWithPrerequisites);
      return orderedCourses;
    },
    isFav(id) {
      return this.$store.state.user.favorite_courses.find(course => course.id === id)
    }
  }
}
</script>
<style scoped>
.card-title {
  margin-bottom: 0px !important;
  padding-top: 20px !important;
  text-align: center !important;
}

.card-body {
  background: none !important;
}

.dark-bg-line {
  background-color: var(--red-90);
}
</style>
