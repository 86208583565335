<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-tests.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('team-members.team-members') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div id="content-2">
      <div class="container">
        <div class="row mt-5">
          <div class="col-12">
            <h2 class="text-18 text-dark-gray semi-bold mb-3"> {{ $t('team-members.team-members') }}</h2>
          </div>
          <section v-for="course in courses" :key="course.id" id="list-table" class="table-responsive">
            <!-- TABLE #1 -->
            <h3 class="text-16 text-dark-gray semi-bold mb-3">
              {{ course.title }}
            </h3>
            <div class="table-responsive">
              <!-- NO DATA -->
              <table class="table table-borderless" v-if="course.users.length == 0">
                <thead>
                <tr class="table-head bg-light-gray-primary text-left">
                  <th scope="col">{{ $t('team-members.team-members') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr class="text-center">
                  <td class="text-18 text-light-black semib-old">{{ $t('no-data.team-members') }}</td>
                </tr>
                </tbody>
              </table>
              <!-- //NO DATA -->
              <table class="table" v-if="course.users.length > 0">
                <tbody>
                <tr class="table-head bg-light-gray-primary text-center">
                  <th style="width: 25% !important;" class="text-left">{{ $t('team-members.tname') }}</th>
                </tr>
                <tr v-for="member in course.users" :key="member.id" class="text-center">
                  <td class="text-left"
                      @click.prevent="$router.push({name: 'TeamMember', params: {member: member.id}})">
                    <div class="d-flex flex-wrap align-items-center">
                      <div class="d-flex align-items-center">
                        <i class="fas fa-info-circle"></i>
                        <div class="d-flex">
                          <a href="#" class="text-14">{{ member.name }}</a>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!-- //TABLE #1 -->
          </section>
          <loading height="50vh" width="100%" v-if="loading"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading'

export default {
  name: "TeamMembers",
  components: {
    Loading
  },
  mounted() {
    this.loading = true;
    this.axios.get('/team-members')
         .then(resp => this.courses = resp.data.courses)
         .finally(() => this.loading = false);
  },
  data() {
    return {
      loading: false,
      courses: [],
      submitting: false
    }
  },
}
</script>

<style scoped>
#list-table,
#new-quiz {
  margin-top: 30px;
}

.table-responsive .table-responsive {
  background-color: var(--white);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 30px;
}

.table-head {
  color: var(--white) !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.table-head th {
  font-weight: 400;
  font-size: 16px;
}

.fa-info-circle {
  color: rgba(34, 34, 34, 0.7) !important;
  margin-right: 15px;
}

a:hover {
  text-decoration: none !important;
  color: var(--red);
}

table {
  margin-bottom: 0px !important;
}

table,
table tr,
table th,
table td {
  vertical-align: middle;
}

.btn-outline-primary {
  transition: 0.2s all;
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus {
  background-color: var(--red);
  border-color: var(--red);
  transition: 0.2s all;
  color: var(--white);
}
</style>
