<template>
<div class="modal fade" id="newTestGroupModal" ref="newTestGroupModal" tabindex="-1"  aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header text-white" style="background-color: var(--red) !important;">
            <h5 class="modal-title">{{$t('test-groups.new-group')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-12">
                <label>{{$t('test-groups.name')}}</label>
                <input type="text" v-model="newTestGroup.name" required class="form-control">
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">{{$t('common.cancel')}}</button>
            <button type="button" class="btn btn-secondary" :disabled="submitting" @click.prevent="createNewTestGroup">{{$t('common.save')}}</button>
        </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: 'NewTestGroupModal',
    emits: ['newTestGroupCreated'],
    props:{
        test: Object
    },
    data() {
        return {
            submitting: false,
            newTestGroup: {
                name: null
            },
        }
    },
    methods: {
        createNewTestGroup() {
            this.submitting = true
            this.axios.post(`/tests/${this.test.id}/test-groups`, this.newTestGroup)
                .then(resp => {
                    this.newTestGroup.name = null
                    if(this.test.test_groups.length == 0) {
                        this.test.test_questions.forEach(q => {
                            q.test_group_id = resp.data.testGroup.id
                            q.edit.test_group_id = resp.data.testGroup.id
                        })
                    }
                    this.$emit('newTestGroupCreated', resp.data.testGroup)
                })
                .catch(e => console.log(e, e.response))
                .finally(() => {
                    this.submitting = false
                    $(this.$refs.newTestGroupModal).modal('hide')
                })
        }
    }
}
</script>