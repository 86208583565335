<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-courses.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'ManageCourses'})">{{
                    $t('manage-courses.managing-course')
                  }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('create-course.create-course') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->

    <section id="settings">
      <div class="container">
        <!-- <div class="row">
            <div class="col-12 mt-2">
                <h2>
                    {{ $t('create-course.create-course') }}
                </h2>
            </div>
        </div> -->
        <prerequisite-courses-modal :course="data"/>
        <form @submit.prevent="submit">
          <div class="row">
            <div class="col-lg-6 col-12 mt-2">
              <div class="row">
                <div class="col-12 mt-2">
                  <p>{{ $t('create-course.cover-img') }} </p>
                  <div v-if="!coverImage && !coverImageUploading" @click="$refs.coverImageUpload.click()"
                       class="col-md-7 col-lg-12 col-xl-9 col-12 d-flex justify-content-center align-items-center"
                       style="border: 2px solid #e0e0e0; height: 300px; flex-direction: column; border-radius: 5px; cursor: pointer; background: white;"
                  >
                    <i class="far fa-image" style="font-size: 100px; color: #e0e0e0"></i>
                    <span style="color: grey">{{ $t('create-course.upload-cover-img') }}</span>
                  </div>
                  <div v-else-if="coverImage && !coverImageUploading" @click="$refs.coverImageUpload.click()"
                       class="col-md-7 col-lg-12 col-xl-9 col-12 d-flex justify-content-center align-items-center"
                       style="background: white;border: 2px solid #e0e0e0; height: 300px; flex-direction: column; border-radius: 5px; cursor: pointer; background-position: center; background-repeat: no-repeat; background-size: cover;"
                       :style="{backgroundImage: `url(${$store.state.storageURL+coverImage.path}?token=${$store.state.token})`}"
                  >
                  </div>
                  <div v-else
                       class="col-md-7 col-lg-12 col-xl-9 col-12 d-flex justify-content-center align-items-center"
                       style="border: 2px solid #e0e0e0; height: 300px;background: white;">
                    <Loading height="100%"/>
                  </div>
                  <ImageCropper v-if="uploadedAvatarURL" @urlChanged="createUpload" :type="uploadType"
                                :src="uploadedAvatarURL" :aspectRatio="null"/>
                  <input ref="coverImageUpload" type="file" accept="image/jpeg, image/jpg, image/png" @change="readURL"
                         style="display: none;">
                  <button v-if="coverImage" class="btn btn-outline-danger mt-2" style="width: 128px"
                          @click.stop="removeCoverImage" :disabled="coverImageUploading">{{
                      $t('create-course.delete')
                    }}
                  </button>
                </div>
                <div class="col-12 mt-2">
                  <label>{{ $t('create-course.type') }} </label>
<!--                  <select class="form-control" v-model="data.course_type_id" required>
                    <option v-for="courseType in courseTypes" :value="courseType.id" :key="courseType.id">
                      {{ courseType.name }}
                    </option>
                  </select>-->
                  <Select2 v-model="data.course_type_id" :options="courseTypes" :settings="{width:'100%'}" />
                </div>
                <div class="col-12 mt-2">
                  <label>{{ $t('create-course.cert-type') }} </label>
                  <Select2 v-model="data.certificate_type" :options="certificateTypes" :settings="{width:'100%', placeholder:$t('create-course.no-cert-type'), allowClear:true}" />
                </div>
                <div class="col-12 mt-2">
                  <TemporaryCertificateInput :certificate_types="certificateTypes" v-model:temporary_certificate="data.temporary_certificate" v-model:temporary_certificate_type="data.temporary_certificate_type" v-model:temporary_certificate_validity="data.temporary_certificate_validity"/>
                </div>
                <div class="col-12 mt-2">
                  <label>{{ $t('create-course.course-group') }} </label>
                  <Select2 v-model="data.course_group_id" :options="courseGroups" :settings="{width:'100%', placeholder:$t('create-course.no-course-group'), allowClear:true}" />
                </div>
                <div class="col-12 mt-2">
                  <label>{{ $t('create-course.name') }}</label>
                  <div v-if="errors['title']">
                    <span v-for="(error, idx) in errors['title']" style="color: red;" :key="idx">{{ error }}</span>
                  </div>
                  <input @input="errors['title'] = null" type="text" v-model="data.title" class="form-control"
                         :style="{border: errors['title'] ? '1px solid red' : '1px solid #ced4da'}" required>
                </div>
                <div class="col-12 mt-2">
                  <label>{{ $t('create-course.meeting_link') }}</label>
                  <div v-if="errors['meeting_link']">
                    <span v-for="(error, idx) in errors['meeting_link']" style="color: red;" :key="idx">{{ error }}</span>
                  </div>
                  <input @input="errors['meeting_link'] = null" type="text" v-model="data.meeting_link" class="form-control"
                         :style="{border: errors['meeting_link'] ? '1px solid red' : '1px solid #ced4da'}" >
                </div>
                <div class="col-12 mt-2">
                  <label>{{ $t('create-course.speakers') }}</label>
                  <button type="button" class="form-control btn btn-secondary" data-toggle="modal"
                          data-target="#presentersModal">{{ $t('create-course.add-speakers') }}
                  </button>
                </div>
                <div class="col-12 mt-2">
                  <label>{{ $t('create-course.about-speakers') }}: </label>
                  <div v-if="errors['description']">
                    <span v-for="(error, idx) in errors['description']" style="color: red;"
                          :key="idx">{{ error }}</span>
                  </div>
                  <textarea @input="errors['contents'] = null" rows="5" v-model="data.description" class="form-control"
                            :style="{border: errors['description'] ? '1px solid red' : '1px solid #ced4da'}"
                            required></textarea>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-12 mt-lg-0 pl-lg-3 mt-4 pl-0">
              <div class="col-12 mt-2">
                <label>{{ $t('create-course.course-description') }} </label>
                <div v-if="errors['contents']">
                  <span v-for="(error, idx) in errors['contents']" style="color: red;" :key="idx">{{ error }}</span>
                </div>
                <textarea rows="5" @input="errors['contents'] = null" v-model="data.contents" class="form-control"
                          :style="{border: errors['contents'] ? '1px solid red' : '1px solid #ced4da'}"
                          required></textarea>
                <span class="limiter" :class="limitExceeded && 'limit_exceeded'">{{charactersLeft}}</span>
              </div>
              <div class="col-12 mt-2">
                <label>{{ $t("edit-course.badge") }}
                  <div class="d-flex align-items-center mt-1">
                    <i class="fas fa-info-circle mr-1 text-info font-15" style="font-size: 15px;"></i>
                    <span class="font-12" style="font-size: 12px">{{ $t('edit-course.badge-text') }}</span>
                  </div>
                </label>                <div class="justify-content-start badge-wrapper">
                  <div class='badge-container text-center border-1'>
                    <input type="radio" name="selected_badge" v-model="data.badge_id" id="badge_none" class="d-none imgbgchk" value="null">
                    <label for="badge_none">
                      <div style="width: 50px; height: 50px; display: table-cell; vertical-align: middle" :style="data.badge_id === 'null' || data.badge_id === null ? 'border: 3px solid #d62b33' : 'border: none' ">None</div>
                      <div class="tick_container">
                        <div class="tick"></div>
                      </div>
                    </label>
                  </div>
                  <div v-for="badge in badges" :key="badge.id" class='badge-container text-center border-1'>
                    <input type="radio" name="selected_badge" v-model="data.badge_id" :id="'badge'+ badge.id" class="d-none imgbgchk" :value="badge.id">
                    <label :for="'badge'+ badge.id">
                      <img :src="$store.state.storageURL+badge.multimedia.path+`?token=${$store.state.token}`" :alt="badge.title" style="width: 50px">
                      <div class="tick_container">
                        <div class="tick"><i class="fa fa-check"></i></div>
                      </div>
                    </label>
                  </div>
                  <div v-if="!badgeUploading" class='badge-container text-center border-1' @click="$refs.badgeUpload.click()">
                    <label>
                      <img style="width: 50px; height: 50px; border:none">
                      <div class="tick_container-add">
                        <div class="tick-add"><i class="fa fa-plus"></i></div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <input ref="badgeUpload" type="file" accept="image/jpeg, image/jpg, image/png" @change="uploadBadgeImage"
                     style="display: none;">
              <div class="col-12 mt-2">
                <label>{{ $t("edit-course.prerequisites") }}</label>
                <button class="btn btn-secondary mt-2 w-100" type="button" data-toggle="modal"
                        data-target="#prerequisiteCoursesModal">{{ $t("create-course.add-prerequisites") }}
                </button>
              </div>
              <div class="col-12 mt-2">
                <label>{{ $t('create-course.location') }} </label>
                <Select2 v-model="data.location_id" :options="locations" :settings="{width:'100%', placeholder:$t('create-course.online'), allowClear:true}" />
                <button type="button" class="btn btn-outline-primary mt-2 d-flex align-items-center" data-toggle="modal"
                        data-target="#newLocationModal">
                  <i class="fas fa-map-marker-alt mr-2"></i>
                  <span>{{ $t('create-course.add-new-location') }}</span>
                </button>
              </div>
              <div class="col-12 mt-2">
                <AvailableInCountries :countries="countries" v-model:available-in="data.available_in" />
              </div>
              <div class="col-12 mt-4">
                <label for="start">{{ $t('create-course.begin') }}<br>
                  <div class="d-flex align-items-center mt-1">
                    <i class="fas fa-info-circle mr-1 text-success font-15" style="font-size: 15px;"></i>
                    <span class="font-12" style="font-size: 12px">{{ $t('create-course.text1') }}</span>
                  </div>
                </label>
                <datetime type="datetime" v-model="data.start_date" :max-datetime="data.end_date"
                          input-class="form-control"/>
              </div>
              <div class="col-12 mt-2">
                <label for="start">{{ $t('create-course.end') }} <br>
                  <div class="d-flex align-items-center mt-1">
                    <i class="fas fa-info-circle mr-1 text-succes font-15" style="font-size: 15px;"></i>
                    <span class="font-12" style="font-size: 12px">{{ $t('create-course.text2') }}</span>
                  </div>
                </label>
                <datetime type="datetime" :min-datetime="data.start_date" v-model="data.end_date"
                          input-class="form-control"/>
              </div>
              <div class="col-12 mt-2">
                <label>{{ $t('create-course.barion-wallet') }}</label>
                <div>
                  <i class="fas fa-info-circle"/>{{ $t('create-course.wallet-info') }}
                </div>
                <Select2 v-model="data.barion_wallet_id" :options="barionWallets" :settings="{width:'100%', placeholder:'XBody system', allowClear:true}" />

              </div>
              <div class="col-12 mt-2">
                <label>{{ $t('create-course.price') }} </label>
                <div v-if="errors['base_price']">
                  <span v-for="(error, idx) in errors['base_price']" style="color: red;" :key="idx">{{ error }}</span>
                </div>
                <input @input="errors['base_price'] = null" type="number" min="0" v-model="data.base_price"
                       class="form-control"
                       :style="{border: errors['base_price'] ? '1px solid red' : '1px solid #ced4da'}" required>
              </div>
              <div class="col-12 mt-2">
                <label>{{ $t('create-course.currency') }} </label>
                <div v-if="errors['currency']">
                  <span v-for="(error, idx) in errors['currency']" style="color: red;" :key="idx">{{ error }}</span>
                </div>
                <Select2 v-model="data.currency" :options="currencies" :settings="{width:'100%', placeholder:'currency', allowClear:false}" />
              </div>
              <div class="col-12 mt-2">
                <input  v-model="data.coupon_compatible" true-value='1' false-value='0' id="coupon-compatible"
                        type="checkbox"
                        :style="{border:'1px solid #ced4da',marginRight:'10px'}" >
                <label class="" for="coupon-compatible">{{ $t('create-course.coupon-compatible') }} </label>
              </div>
              <div class="col-12 mt-2">
                <input  v-model="data.code_required" true-value='1' false-value='0' id="code_required"
                        type="checkbox"
                        :style="{border:'1px solid #ced4da',marginRight:'10px'}" >
                <label class="" for="code_required">{{ $t('create-course.code_required') }} </label>
              </div>
              <div class="col-12">
                <button type="submit" :disabled="loading" class="btn btn-secondary w-100 mt-5">
                  {{ $t('create-course.create-course') }}
                </button>
              </div>
            </div>
          </div>
        </form>

        <!-- Presenters modal -->
        <div class="modal fade" id="presentersModal" tabindex="-1" role="dialog"
             aria-labelledby="newpresentersModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="newpresentersModalLabel">{{ $t('create-course.add-speakers') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-12">
                    <label>{{ $t('create-course.no-user') }}</label>
                    <textarea class="form-control w-100" type="text" v-model="data.external_presenters"></textarea>
                  </div>
                  <div class="col-12 mt-3">
                    <span>{{ $t('create-course.registered') }}: </span>
                    <div class="mt-2 d-flex align-items-center" v-for="user in data.presenters" :key="user.id">
                      <div class="d-inline-flex align-items-center">
                        <img v-if="user.avatar"
                             :src="$store.state.storageURL+user.avatar.path+'?token='+$store.state.token"
                             style="border-radius: 50%; height: 50px" class="ml-0" alt="">
                        <Avatar v-else class="team-image ml-0" :username="user.name" :size="50"/>
                        <h6 class="name ml-2 mt-0 pl-0" style="font-weight: bold">
                          {{ user.name }} <br/>
                          <span class="email mb-1">{{ user.email }}</span>
                        </h6>
                      </div>
                      <span class="ml-4 mb-1" style="cursor: pointer" @click.prevent="removeFromPresenters(user)">
                                        <i class="fas fa-times font-24" style="color: red; font-size: 24px"></i>
                                    </span>
                    </div>
                    <div class="mt-3 ml-2" v-if="data.presenters.length < 1">
                      <strong>{{ $t('create-course.no-speakers') }}</strong>
                    </div>
                  </div>
                  <label class="col-12 mt-5">{{ $t('create-course.new-speakers') }}</label>
                  <form class="col-md-8 mt-0 col-12 d-flex alig-items-center" @submit.prevent="startPresenterSearch">
                    <input type="text" class="form-control" :placeholder="$t('edit-course.username')"
                           v-model="presenterSearchStr">
                  </form>
                  <div class="col-md-4 mt-0 col-12 mb-4">
                    <button :disabled="searching" type="button" class="btn btn-secondary"
                            @click="startPresenterSearch()">{{ $t('create-course.search') }}
                    </button>
                  </div>
                  <div v-if="!searching" class="col-12 mt-2 mb-3">
                    <div
                        v-for="user in presenterResults.filter(u => data.presenters.findIndex(user => user.id == u.id) === -1)"
                        :key="user.id" class="mt-3">
                      <div class="d-flex align-items-center" style="cursor: pointer;"
                           @click.prevent="addToPresenters(user)" data-dismiss="modal">
                        <img v-if="user.avatar"
                             :src="$store.state.storageURL+user.avatar.path+'?token='+$store.state.token"
                             style="border-radius: 50%; height: 50px" class="ml-0" alt="">
                        <Avatar v-else class="team-image ml-0" :username="user.name" :size="50"/>
                        <h6 class="name ml-2 mt-0 pl-0" style="font-weight: bold">
                          {{ user.name }} <br/>
                          <span class="email mb-1">{{ user.email }}</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div v-else class="col-12">
                    <Loading height="30vh"/>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
              </div>
            </div>
          </div>
        </div>


        <!-- New location modal -->
        <div class="modal fade" ref="newLocationModal" id="newLocationModal" tabindex="-1" role="dialog"
             aria-labelledby="newLocationModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="newLocationModalLabel">{{ $t('create-course.new-location') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="row">
                    <div class="col-12 mt-2">
                      <label>{{ $t('create-course.country') }}</label>
                      <div v-if="errors['country']">
                        <span v-for="(error, idx) in errors['country']" style="color: red;"
                              :key="idx">{{ error }}</span>
                      </div>
<!--                      <select v-model="locationData.country_id" class="form-control">
                        <option v-for="country in countries" :value="country.id" :key="country.id">{{ country.name }}
                        </option>
                      </select>-->
                      <Select2 v-model="locationData.country_id" :options="countries" :settings="{width:'100%'}" />
                    </div>
                    <div class="col-12 mt-2">
                      <label>{{ $t('create-course.zip-code') }}</label>
                      <div v-if="errors['zip_code']">
                        <span v-for="(error, idx) in errors['zip_code']" style="color: red;"
                              :key="idx">{{ error }}</span>
                      </div>
                      <input type="text" v-model="locationData.zip_code" class="form-control"
                             :style="{border: errors['zip_code'] ? '1px solid red' : '1px solid #ced4da'}" required>
                    </div>
                    <div class="col-12 mt-2">
                      <label>{{ $t('create-course.city') }}</label>
                      <div v-if="errors['locality']">
                        <span v-for="(error, idx) in errors['locality']" style="color: red;"
                              :key="idx">{{ error }}</span>
                      </div>
                      <input type="text" v-model="locationData.locality" class="form-control"
                             :style="{border: errors['locality'] ? '1px solid red' : '1px solid #ced4da'}" required>
                    </div>
                    <div class="col-12 mt-2">
                      <label>{{ $t('create-course.street-name') }}</label>
                      <div v-if="errors['public_space']">
                        <span v-for="(error, idx) in errors['public_space']" style="color: red;"
                              :key="idx">{{ error }}</span>
                      </div>
                      <input type="text" v-model="locationData.public_space" class="form-control"
                             :style="{border: errors['public_space'] ? '1px solid red' : '1px solid #ced4da'}" required>
                    </div>
                    <div class="col-12 mt-2">
                      <label>{{ $t('create-course.street-type') }}</label>
                      <div v-if="errors['public_space_type']">
                        <span v-for="(error, idx) in errors['public_space_type']" style="color: red;"
                              :key="idx">{{ error }}</span>
                      </div>
                      <input type="text" v-model="locationData.public_space_type" class="form-control"
                             :style="{border: errors['public_space_type'] ? '1px solid red' : '1px solid #ced4da'}"
                             required>
                    </div>
                    <div class="col-12 mt-2">
                      <label>{{ $t('create-course.other') }}</label>
                      <div v-if="errors['other']">
                        <span v-for="(error, idx) in errors['other']" style="color: red;" :key="idx">{{ error }}</span>
                      </div>
                      <input type="text" :placeholder=" $t('course-materials.placeholder')" v-model="locationData.other"
                             class="form-control"
                             :style="{border: errors['other'] ? '1px solid red' : '1px solid #ced4da'}">
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{
                    $t('create-course.close')
                  }}
                </button>
                <button type="button" @click="createLocation" :disabled="locationLoading" class="btn btn-primary">
                  {{ $t('create-course.create') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Loading from '@/components/Loading'
import Datetime from '@/components/DateTimePicker/Datetime.vue'
import ImageCropper from '@/components/ImageCropper'
import Avatar from '@/components/Avatar.vue'
import PrerequisiteCoursesModal from '../components/PrerequisiteCoursesModal.vue'
import Select2 from "vue3-select2-component";
import AvailableInCountries from "./AvailableInCountries.vue";
import TemporaryCertificateInput from "./TemporaryCertificateInput.vue";

export default {
  name: 'CreateCourse',
  components: {
    TemporaryCertificateInput,
    AvailableInCountries,
    Loading,
    Datetime,
    ImageCropper,
    Avatar,
    PrerequisiteCoursesModal,
    Select2
  },
  mounted() {
    this.axios.get('/barion-wallets')
        .then(resp => this.barionWallets = resp.data.barion_wallets.map(wallet => ({
          id: wallet.id,
          text: wallet.email
        })));
    this.axios.get('/course-types')
        .then(resp => this.courseTypes = resp.data.map(type => ({
          id: type.id,
          text: type.name
        })));
    this.axios.get('/courses/locations')
        .then(resp => {
          this.locations = resp.data.map(location => ({
            id: location.id,
            text: `${location.zip_code}, ${location.locality}, ${location.public_space} ${location.public_space_type}, ${location.other}, ${location.country.name}`
          }));
        });
    this.axios.get('/countries')
        .then(resp => this.countries = resp.data.map(country => ({
          id: country.id,
          text: country.name
        })))
        this.axios.get('/currencies')
        .then(resp => this.currencies = resp.data.map(currency => ({
          id: currency.currency,
          text: currency.currency
        })))
    this.getBadges();
    this.axios.get('/vat-rates')
        .then(resp => this.vatRates = this.vatRates.concat(resp.data))
    this.axios.get('/certificate-types')
        .then(resp => this.certificateTypes = resp.data.map(type => ({
          id: type.type,
          text: type.type
        })))
    this.axios.get('/course-groups')
        .then(resp => {
          this.courseGroups = [];
          resp.data.course_groups.forEach(courseGroup => {
            //add course group children also to the list
            this.courseGroups.push({id: courseGroup.id, text: courseGroup.title});
            if (courseGroup.children.length > 0) {
              courseGroup.children.forEach(child => {
                this.courseGroups.push({id: child.id, text: " - "+child.title});
              });
            }
          });});
  },
  data() {
    return {
      coverImageUploading: false,
      coverImage: null,
      badgeUploading: false,
      limitExceeded:false,
      badgeImage: null,
      uploadedAvatarURL: null,
      uploadType: null,
      courseTypes: [],
      locations: [],
      badges: [],
      currencies: [],
      countries: [],
      vatRates: [],
      certificateTypes: [],
      presenterResults: [],
      courseGroups: [],
      barionWallets: [],
      presenterSearchStr: null,
      searching: false,
      locationData: {
        country_id: 141
      },
      loading: false,
      locationLoading: false,
      data: {
        title: null,
        presenters: [],
        available_in: [],
        external_presenters: null,
        description: "",
        contents:"",
        temporary_certificate:false,
        temporary_certificate_type: null,
        temporary_certificate_validity: null,
        course_type_id: null,
        location_id: null,
        meeting_link: null,
        start_date: null,
        end_date: null,
        base_price: 0,
        dla_price: 0,
        active: 1,
        badge_id: null,
        far_type: null,
        certificate_type: null,
        prerequisites: [],
        coupon_compatible:1,
        code_required:0,
        course_group_id: null,
        barion_wallet_id: null,
        currency:'EUR'
      },
      errors: Object(),
      regexErrors: {
        bar_association_number: null,
        tax_id: null,
        taj_id: null,
        educational_id: null
      }

    }
  },
  methods: {
    removeFromPresenters(user) {
      const idx = this.data.presenters.findIndex(p => p.id == user.id)
      if (idx !== -1)
        this.data.presenters.splice(idx, 1)
    },
    addToPresenters(user) {
      this.data.presenters.push(user);
    },
    startPresenterSearch() {
      this.searching = true
      let params = {
        search: this.presenterSearchStr
      }
      this.axios.get('/users/presenters/', {params})
          .then(resp => {
            this.presenterResults = resp.data.users
          })
          .finally(() => this.searching = false)
    },
    createUpload(url) {
      if (url) {
        let file = this.dataURLtoFile(url)
        let formData = new FormData;
        formData.set('image', file, file.name);
        this.coverImageUploading = true
        console.log(formData)
        let config = {
          method: 'POST',
          data: formData,
          url: `/upload/image`,
          baseURL: process.env.VUE_APP_STORAGE_URL,
          headers: {
            'Content-Type': 'application/octet-stream'
          },
        };
        this.axios(config)
            .then(resp => {
              config.url += '/' + resp.data.name
              config.url += '/finish';
              config.data = null;
              this.axios(config)
                  .then(resp => {
                    this.coverImage = resp.data.multimedia;
                  })
                  .finally(() => this.coverImageUploading = false)
            })
            .catch((e) => this.coverImageUploading = false)
      }

    },
    readURL(input) {
      input = input.target
      if (input.files && input.files[0]) {
        this.uploadType = input.files[0].type
        var reader = new FileReader()
        reader.onload = (e) => {
          this.uploadedAvatarURL = e.target.result
        }

        reader.readAsDataURL(input.files[0]) // convert to base64 string
      }
    },
    getBadges() {
      this.axios.get('/badges')
          .then(resp => this.badges = this.badges.concat(resp.data.badges));
    },
    uploadBadgeImage(event){
      //Upload image from input
      let file = event.target.files[0];
      let formData = new FormData();
      formData.set('image', file, file.name);
      this.badgeUploading = true;
      let config = {
        method: 'POST',
        data: formData,
        url: `/upload/image`,
        baseURL: process.env.VUE_APP_STORAGE_URL,
        headers: {
          'Content-Type': 'application/octet-stream'
        },
      };
      this.axios(config)
          .then(resp => {
            config.url += '/' + resp.data.name
            config.url += '/finish';
            config.data = null;
            this.axios(config)
                .then(resp => {
                  console.log(resp.data);
                  this.badgeImage = resp.data.multimedia;
                  this.createBadge(resp.data.multimedia);
                })
          })
          .catch((e) => this.badgeUploading = false)
    },
    createBadge(multimedia) {
      let data = {
        title: multimedia.name,
        description: multimedia.name,
        multimedia_id: multimedia.id,
      }
      this.axios.post('/badges', data)
          .then(resp => {
            this.badges.push(resp.data.badge)
          })
          .catch(e => console.log(e))
      .finally(() => this.badgeUploading = false);
    },
    dataURLtoFile(dataurl, filename) {

      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], 'coverimg', {type: mime});
    },
    removeCoverImage() {
      this.axios.post(process.env.VUE_APP_STORAGE_URL + '/upload/image/' + this.coverImage.id + '/abort')
      this.coverImage = null
      this.$refs.coverImageUpload.value = null;
    },
    removeEmptyOrNull(obj) {
      Object.keys(obj).forEach(k => {
        if (obj[k] === null || obj[k] === '')
          delete obj[k]
      })
      return obj;
    },
    submit() {
      this.loading = true;
      (this.data.badge_id==="null") && (this.data.badge_id=null);
      this.data.cover_image_id = this.coverImage ? this.coverImage.id : null
      if (!this.data.temporary_certificate){
        delete this.data.temporary_certificate_type;
        delete this.data.temporary_certificate_validity;
      }
      this.axios.post('/courses', this.removeEmptyOrNull(this.data))
          .then(resp => {
            this.$router.push({name: 'CourseMaterials', params: {course: resp.data.course.id}})
          })
          .catch(e => this.errors = e.response.data.errors)
          .finally(() => this.loading = false)
    },
    createLocation() {
      this.locationLoading = true;
      this.axios.post('/locations', this.locationData)
          .then(resp => {
            this.locations.push(resp.data.location)
            this.data.location_id = resp.data.location.id
            this.locationData = {
              country_id: 141
            }
            $(this.$refs.newLocationModal).modal('hide')
          })
          .catch(e => {
            Object.assign(this.errors, e.response.data.errors)
          })
          .finally(() => this.locationLoading = false)
    },
  },
  computed: {
    charactersLeft() {
      let char = this.data.contents.length,
          limit = 3000;
      this.limitExceeded = limit<char;
      return (limit - char) + " / " + limit;
    }
  },
  watch:{
    "data.certificate_type":{
      handler: function (val) {
        console.log("data.certificate_type",val);
        if (this.data.temporary_certificate){
          this.data.temporary_certificate_type = val;
        }
    }
  }
  }
}
</script>
<style scoped>

.badge-wrapper {
  border: 1px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}

.badge-container {
  flex-basis: 0;
  margin: 8px;
  max-width: 100%;
}


.badge-container img {
  cursor: pointer;
  transition: transform 1s;
  object-fit: cover;
}

.badge-container label {
  overflow: hidden;
  position: relative;
}
.imgbgchk:checked + label > .tick_container {
  opacity: 1;
}

/*         aNIMATION */
.imgbgchk:checked + label > img {
  transform: scale(1.1);
  opacity: 0.3;
}

.tick_container {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  cursor: pointer;
  text-align: center;
}
.tick_container-add {
  background-color: var(--red);
  transition: .5s ease;
  opacity: 1;
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  cursor: pointer;
  text-align: center;
}

.tick {
  color: var(--red);
  font-size: 16px;
  height: 20px;
  width: 20px;
  border-radius: 100%;
}
.tick-add {
  color: var(--white);
  font-size: 16px;
  height: 20px;
  width: 20px;
  border-radius: 100%;
}
.limiter{
  font-size: 12px;
}
.limit_exceeded{
  color: red;
}
</style>
