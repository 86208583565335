<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-tests.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('manage-tests.managing-tests') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div id="content-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <section id="new-quiz">
              <div class="row">
                <div class="col-12">
                  <div class="flex-wrap d-flex">
                    <div class="d-flex align-items-center pointer mr-auto"
                         @click.prevent="$router.push({name: 'TestAssembler'})">
                      <div class="new-button-50 d-flex align-items-center justify-content-center">
                        <img src="assets/img/icons/plus.svg" class="img-fluid" alt="">
                      </div>
                      <div class="d-flex flex-column ml-2">
                        <h6 class="text-16 text-dark-gray">{{ $t('manage-tests.create-new') }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div class="col-lg-9">
            <div class="row">
              <div class="col-12">
                <section v-if="!loading && tests" section id="list-table" class="table-responsive">
                  <!-- TABLE #1 -->
                  <h2 v-if="tests.length > 0" class="text-18 text-dark-gray semi-bold mb-3">
                    {{ $t('manage-tests.tests') }}
                  </h2>
                  <div class="table-responsive">
                    <!-- NO DATA -->
                    <table class="table table-borderless" v-if="tests.length === 0">
                      <thead>
                      <tr class="table-head bg-light-gray-primary text-left">
                        <th scope="col">{{ $t('manage-tests.for-fixing') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr class="text-center">
                        <td class="text-18 text-light-black semib-old">{{ $t('no-data.quiz') }}</td>
                      </tr>
                      </tbody>
                    </table>
                    <!-- //NO DATA -->
                    <table class="table" v-if="tests.length > 0">
                      <tbody>
                      <tr class="table-head bg-light-gray-primary text-center">
                        <th :class="'text-left pointer'+' '+(currentSort==='name'?'sorted':'')" @click="sort('name')" style="width: 25% !important;">{{ $t('manage-tests.tname') }}</th>
                        <th :class="'pointer'+' '+(currentSort==='start_date'?'sorted':'')" @click="sort('start_date')" style="width: 20% !important;">{{ $t('manage-tests.tbegin') }}</th>
                        <th :class="'pointer'+' '+(currentSort==='end_date'?'sorted':'')" @click="sort('end_date')" style="width: 20% !important;">{{ $t('manage-tests.tend') }}</th>
                        <th :class="'pointer'+' '+(currentSort==='hidden'?'sorted':'')" @click="sort('hidden')" style="width: 13% !important;">{{ $t('manage-tests.visibility') }}</th>
                        <th :class="'pointer'+' '+(currentSort==='exam'?'sorted':'')" @click="sort('exam')" style="width: 20% !important;">{{ $t('manage-tests.type') }}</th>
                      </tr>
                      <tr v-for="test in sortedTests" :key="test.id" class="text-center">
                        <td class="text-left"
                            @click.prevent="$router.push({name: 'EditTest', params: {test: test.id}})">
                          <div class="d-flex flex-wrap align-items-center">
                            <div class="d-flex align-items-center">
                              <i class="fas fa-pen"></i>
                              <div class="d-flex">
                                <a href="#" class="text-14">{{ test.name }}</a>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="text-14">
                          {{ test.start_date ? $store.getters.parseDateTime(test.start_date) : '-' }}
                        </td>
                        <td class="text-14"><span
                            class="left">{{ test.end_date ? $store.getters.parseDateTime(test.end_date) : '-' }}</span>
                        </td>
                        <td class="text-14" :style="{color: test.hidden ? 'red' : 'green'}">
                          {{ test.hidden ? $t('manage-courses.hidden') : $t('manage-courses.public') }}
                        </td>
                        <td class="text-14" :style="{color: test.exam ? 'var(--red)' : ''}">
                          {{(test.fillable)?($t('edit-test.fillable')):( test.exam ? $t('manage-tests.exam') : $t('manage-tests.regular')) }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- //TABLE #1 -->
                  <!-- TABLE #2 -->
                  <h2 v-if="assignmentsToReturn.length > 0" class="text-18 text-dark-gray semi-bold mb-3">
                    {{ $t('manage-tests.for-fixing') }}
                  </h2>
                  <div class="table-responsive">
                    <!-- NO DATA -->
                    <table class="table table-borderless" v-if="assignmentsToReturn.length == 0">
                      <thead>
                      <tr class="table-head bg-light-gray-primary text-left">
                        <th scope="col">{{ $t('manage-tests.for-fixing') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr class="text-center">
                        <td class="text-18 text-light-black semib-old">{{ $t('no-data.quiz-1') }}</td>
                      </tr>
                      </tbody>
                    </table>
                    <!-- //NO DATA -->
                    <table class="table table-striped" v-if="assignmentsToReturn.length > 0">
                      <thead>
                      <tr class="table-head bg-light-gray-primary text-center">
                        <th scope="col" :class="'text-left pointer '+(currentSort1==='name'?'sorted':'')" @click="sort1('name')" style="width: 25% !important;">{{ $t('manage-tests.test') }}</th>
                        <th scope="col"  style="width: 25% !important;">{{ $t('manage-tests.user') }}</th>
                        <th scope="col"  style="width: 25% !important;">{{ $t('manage-tests.date') }}</th>
                        <th scope="col" :class="'pointer '+(currentSort1==='exam'?'sorted':'')" @click="sort1('exam')" style="width: 25% !important;">{{ $t('manage-tests.type') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="testAttempt in sortedTests1" :key="testAttempt.id">
                        <td @click.prevent="$router.push({name: 'CorrectTestAttempt', params: {testAttempt: testAttempt.id}})">
                                                        <span class="left-play">
                                                            <a href="#">
                                                                <img src="assets/img/icons/blue-play.png" alt="">
                                                            </a>
                                                        </span>
                          <a href="#">{{ testAttempt.test.name }}</a>
                        </td>
                        <td>{{ testAttempt.user.name }}</td>
                        <td>{{ $store.getters.parseDateTime(testAttempt.finished_at) }}</td>
                        <td :style="{color: testAttempt.test.exam ? 'var(--red)' : ''}">
                          {{ testAttempt.test.exam ? $t('manage-tests.exam') : $t('manage-tests.regular') }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- //TABLE #2 -->
                  <!-- //TABLE #3 -->
                  <h2 v-if="autocorrectedTestAttempts.length > 0" class="text-18 text-dark-gray semi-bold mb-3">
                    {{ $t('manage-tests.auto') }}
                  </h2>
                  <div class="table-responsive">
                    <!-- NO DATA -->
                    <table class="table table-borderless" v-if="autocorrectedTestAttempts.length == 0">
                      <thead>
                      <tr class="table-head bg-light-gray-primary text-left">
                        <th scope="col">{{ $t('manage-tests.auto') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr class="text-center">
                        <td class="text-18 text-light-black semib-old">{{ $t('no-data.quiz-2') }}</td>
                      </tr>
                      </tbody>
                    </table>
                    <!-- //NO DATA -->
                    <table class="table" v-if="autocorrectedTestAttempts.length > 0">
                      <thead>
                      <tr class="table-head bg-light-gray-primary text-center">
                        <th style="width: 25% !important;" :class="'text-left pointer'+' '+(currentSort2==='name'?'sorted':'')" @click="sort2('name')"  scope="col">{{$t('manage-tests.test') }}
                        </th>
                        <th style="width: 25% !important;" scope="col">{{ $t('manage-tests.user') }}</th>
                        <th style="width: 25% !important;" scope="col">{{ $t('manage-tests.date') }}</th>
                        <th style="width: 25% !important;" :class="'pointer'+' '+(currentSort2==='exam'?'sorted':'')" @click="sort2('exam')" scole="col">{{ $t('manage-tests.type') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="testAttempt in sortedTests2" :key="testAttempt.id" class="text-center">
                        <td class="text-left"
                            @click.prevent="$router.push({name: 'CorrectTestAttempt', params: {testAttempt: testAttempt.id}})">
                                                        <span class="left-play">
                                                            <a href="#">
                                                                <img src="assets/img/icons/blue-play.png" alt="">
                                                            </a>
                                                        </span>
                          <a href="#">{{ testAttempt.test.name }}</a>
                        </td>
                        <td>{{ testAttempt.user.name }}</td>
                        <td>{{ $store.getters.parseDateTime(testAttempt.finished_at) }}</td>
                        <td :style="{color: testAttempt.test.exam ? 'var(--red)' : ''}">
                          {{(testAttempt.test.fillable)?($t('edit-test.fillable')):( testAttempt.test.exam ? $t('manage-tests.exam') : $t('manage-tests.regular')) }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- //TABLE #3 -->
                </section>
                <loading v-else/>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Loading from '@/components/Loading'
import AdminMenu from '@/components/AdminMenu'

export default {
  name: 'TestManagement',
  components: {
    Loading,
    AdminMenu
  },
  mounted() {
    this.loading = true;
    this.axios.get('/tests')
        .then(resp => this.tests = resp.data.tests)
        .finally(() => this.loading = false)
    this.axios.get('/test-attempts/uncorrected')
        .then((resp) => this.assignmentsToReturn = resp.data)
    this.axios.get('/test-attempts/autocorrected')
        .then((resp) => this.autocorrectedTestAttempts = resp.data)
  },
  methods: {
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
    sort1: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort1) {
        this.currentSort1Dir = this.currentSort1Dir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort1 = s;
    },
    sort2: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort2) {
        this.currentSort2Dir = this.currentSort2Dir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort2 = s;
    },
  },
  computed: {
    sortedTests: function () {
      return this.tests.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    sortedTests1: function () {
      return this.assignmentsToReturn.sort((a, b) => {
        let modifier = 1;
        if (this.currentSort1Dir === 'desc') modifier = -1;
        if (a.test[this.currentSort1] < b.test[this.currentSort1]) return -1 * modifier;
        if (a.test[this.currentSort1] > b.test[this.currentSort1]) return 1 * modifier;
        return 0;
      });
    },
    sortedTests2: function () {
      return this.autocorrectedTestAttempts.sort((a, b) => {
        let modifier = 1;
        if (this.currentSort2Dir === 'desc') modifier = -1;
        if (a.test[this.currentSort2] < b.test[this.currentSort2]) return -1 * modifier;
        if (a.test[this.currentSort2] > b.test[this.currentSort2]) return 1 * modifier;
        return 0;
      });
    }
  },
  data() {
    return {
      tests: [],
      loading: false,
      assignmentsToReturn: [],
      autocorrectedTestAttempts: [],
      currentSort: 'name',
      currentSortDir: 'asc',
      currentSort1: 'name',
      currentSort1Dir: 'asc',
      currentSort2: 'name',
      currentSort2Dir: 'asc',
    }
  }

}
</script>
<style scoped>
#list-table,
#new-quiz {
  margin-top: 30px;
}

.table-responsive .table-responsive {
  background-color: var(--white);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 30px;
}

.table-head {
  color: var(--white) !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.table-head th {
  font-weight: 400;
  font-size: 16px;
}

.fa-pen {
  color: rgba(34, 34, 34, 0.7) !important;
  margin-right: 15px;
}

a:hover {
  text-decoration: none !important;
  color: var(--red);
}

table {
  margin-bottom: 0px !important;
}

table,
table tr,
table th,
table td {
  vertical-align: middle;
}

.btn-outline-primary {
  transition: 0.2s all;
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus {
  background-color: var(--red);
  border-color: var(--red);
  transition: 0.2s all;
  color: var(--white);
}
.sorted {
  font-weight: bold!important;
}
</style>
