<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({ name: 'Home' })">{{
                  $t("course-catalog.homepage") }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white">
                  <img src="@/assets/img/icons/bc-seperator.svg" class="h-100" alt="" />
                </span>
              </div>
              <div class="d-flex pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({ name: 'Cart' })">{{
                  $t("cart.cart") }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white">
                  <img src="@/assets/img/icons/bc-seperator.svg" class="h-100" alt="" />
                </span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{
                  $t("checkout-payment.billing-data")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div id="content-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <section id="checkout-user" class="custom-input">

              <div class="row" v-if="!loadingData">
                <a  class="col-12 mb-3" target="_blank" :href="xbodyIdUrl"><button class="btn btn-theme" >
                      Manage your Billing information in XBody ID
                    </button></a>
                <div class="col-12">
                  <h2 class="text-18 text-dark-gray semi-bold mb-3">
                    {{ $t("checkout-user.data-title") }}
                  </h2>
                  <div class="row mb-3">
                    <div class="col-12" style="display: inline-flex; align-items: center">
                      <label class="form-check-label pr-5">{{
                  $t("checkout-user.purchase-as")
                }}</label>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="billingData.is_company"
                          disabled type="radio" name="isCompany"
                          id="company" :value="true" />
                        <label class="form-check-label" for="company"> Company </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="billingData.is_company"
                          disabled type="radio" name="isCompany"
                          id="private" :value="false" />
                        <label class="form-check-label" for="private"> Private </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <!--            Name and Phone        -->
                    <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div class="row">
                        <div class="col-12">
                          <label>{{ $t("settings.name") }}</label>
                          <div v-if="billingErrors['name']">
                            <span v-for="(error, idx) in billingErrors['name']" style="color: red" :key="idx">
                              {{ error }}
                            </span>
                          </div>
                          <input :placeholder="$t('settings.bill-address')" type="text" v-model="billingData.name"
                            class="form-control" :style="{
                  border: billingErrors['name']
                    ? '1px solid red'
                    : '1px solid #ced4da',
                }" disabled />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <label>{{ $t("settings.phone") }}</label>
                          <div v-if="billingErrors['phone_number']">
                            <span v-for="(error, idx) in billingErrors['phone_number']" style="color: red" :key="idx">
                              {{ error }}
                            </span>
                          </div>
                          <input :placeholder="$t('settings.phone')" type="text" inputmode="tel"
                            v-model="billingData.phone_number" class="form-control" :style="{
                  border: billingErrors['phone_number']
                    ? '1px solid red'
                    : '1px solid #ced4da',
                }" disabled />
                        </div>
                      </div>
                    </div>
                    <!--            Address fields column        -->
                    <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div class="row">
                        <div class="col-12">
                          <label>{{ $t("settings.country") }}</label>
                          <div v-if="billingErrors['country']">
                            <span v-for="(error, idx) in billingErrors['country']" style="color: red" :key="idx">
                              {{ error }}
                            </span>
                          </div>
                          <select v-model="billingData.country_id" class="form-control" disabled>
                            <option v-for="country in countries" :value="country.id" :key="country.id">
                              {{ country.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <label>{{ $t("settings.zip-code") }}</label>
                          <div v-if="billingErrors['zip_code']">
                            <span v-for="(error, idx) in billingErrors['zip_code']" style="color: red" :key="idx">
                              {{ error }}
                            </span>
                          </div>
                          <input :placeholder="$t('common.zip-code')" type="text" v-model="billingData.zip_code"
                            class="form-control" :style="{
                  border: billingErrors['zip_code']
                    ? '1px solid red'
                    : '1px solid #ced4da',
                }" disabled />
                        </div>
                        <div class="col-8">
                          <label>{{ $t("settings.city") }}</label>
                          <div v-if="billingErrors['locality']">
                            <span v-for="(error, idx) in billingErrors['locality']" style="color: red" :key="idx">
                              {{ error }}
                            </span>
                          </div>
                          <input :placeholder="$t('common.city')" type="text" v-model="billingData.locality"
                            class="form-control" :style="{
                  border: billingErrors['locality']
                    ? '1px solid red'
                    : '1px solid #ced4da',
                }" disabled />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <label>{{ $t("settings.street-name") }}</label>
                          <div v-if="billingErrors['public_space']">
                            <span v-for="(error, idx) in billingErrors['public_space']" style="color: red" :key="idx">
                              {{ error }}
                            </span>
                          </div>
                          <input :placeholder="$t('common.street-name')" type="text" v-model="billingData.public_space"
                            class="form-control" :style="{
                  border: billingErrors['public_space']
                    ? '1px solid red'
                    : '1px solid #ced4da',
                }" disabled />
                        </div>
                        <!-- <div class="col-4">
                          <label>{{ $t("settings.street-type") }}</label>
                          <div v-if="billingErrors['public_space_type']">
                            <span v-for="(error, idx) in billingErrors['public_space_type']" style="color: red"
                              :key="idx">
                              {{ error }}
                            </span>
                          </div>
                          <input :placeholder="$t('settings.street')" type="text"
                            v-model="billingData.public_space_type" class="form-control" :style="{
                  border: billingErrors['public_space_type']
                    ? '1px solid red'
                    : '1px solid #ced4da',
                }" disabled />
                        </div> -->
                        <div class="col-4">
                          <label>Street number</label>
                          <div v-if="billingErrors['other']">
                            <span v-for="(error, idx) in billingErrors['other']" style="color: red" :key="idx">
                              {{ error }}
                            </span>
                          </div>
                          <input type="text" :placeholder="$t('course-materials.placeholder')"
                            v-model="billingData.other" class="form-control" :style="{
                  border: billingErrors['other']
                    ? '1px solid red'
                    : '1px solid #ced4da',
                }" disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr v-if="billingData.is_company" />
                  <div class="row">
                    <div v-if="billingData.is_company" class="col-12 col-md-6 mt-2">
                      <label>{{ $t("settings.company-name") }}</label>
                      <div v-if="billingErrors['company_name']">
                        <span v-for="(error, idx) in billingErrors['company_name']" style="color: red" :key="idx">
                          {{ error }}
                        </span>
                      </div>
                      <input :placeholder="$t('settings.company-name')" type="text" v-model="billingData.company_name"
                        class="form-control" :style="{
                  border: billingErrors['company_name']
                    ? '1px solid red'
                    : '1px solid #ced4da',
                }" disabled required />
                    </div>
                    <div class="col-12 col-md-6 mt-2 d-flex align-items-center" v-if="(billingData.is_company && isEU)">

                      <input class="form-check-input pr-2" v-model="hasEUVat" :disabled="billingData.sap_code"
                        type="checkbox" name="hasEUVat" id="hasEUVat" />
                      <label class="form-check-label">{{ $t("settings.has-eu-vat") }}</label>
                    </div>
                    <div v-if="(billingData.is_company && !hasEUVat)" class="col-12 col-md-6 mt-2">
                      <label>{{ $t("settings.company-tax") }}</label>
                      <div v-if="billingErrors['company_tax_id']">
                        <span v-for="(error, idx) in billingErrors['company_tax_id']" style="color: red" :key="idx">
                          {{ error }}
                        </span>
                      </div>
                      <div class="input-group">
                        <input :placeholder="$t('settings.company-tax')" type="text"
                          v-model="billingData.company_tax_id" class="form-control" id="company_tax_id" :style="{
                  border: billingErrors['company_tax_id']
                    ? '1px solid red'
                    : '1px solid #ced4da',
                }" disabled required />
                      </div>
                    </div>
                    <div v-if="(billingData.is_company && hasEUVat)" class="col-12 col-md-6 mt-2">
                      <label>{{ $t("settings.company-eu-tax") }}</label>
                      <div v-if="billingErrors['company_eu_tax_id']">
                        <span v-for="(error, idx) in billingErrors['company_eu_tax_id']" style="color: red" :key="idx">
                          {{ error }}
                        </span>
                      </div>
                      <div v-if="VATValidity">
                        <span v-if="VATValidity.valid" style="color: green">{{
                  VATValidity.message
                }}</span>
                        <span v-else style="color: red">{{ VATValidity.message }}</span>
                      </div>
                      <div class="d-flex">
                        <img src="@/assets/img/icons/eu.svg" style="height: 35px; padding: 2px" alt="" />
                        <input :placeholder="$t('settings.company-eu-tax')" v-model="billingData.company_eu_tax_id"
                          type="text" class="form-control" :style="{
                  border:
                    billingErrors['company_eu_tax_id'] ||
                      VATValidity?.valid === 0
                      ? '1px solid red'
                      : '1px solid #ced4da',
                }" disabled required />
                      </div>
                    </div>
                    <div class="col-12 mt-3 ml-auto">
                      <!-- <button v-if="!canSkip" class="btn btn-theme continue ml-auto float-right" :disabled="loading"
                        @click.prevent="setBillingData">
                        {{ $t("checkout-user.next") }}
                        <img src="assets/img/icons/right-arrow.svg" class="img-fluid ml-2" alt="" />
                      </button> -->
                      <router-link v-if="canContinue" :to="{ name: 'CheckoutSummary' }"
                        class="btn btn-theme continue ml-auto float-right">
                        <div class="mr-3"> {{ $t("checkout-user.next") }}
                          <img src="assets/img/icons/right-arrow.svg" class="img-fluid ml-2" alt="" />
                        </div>
                      </router-link>
                      <a  v-else class="btn btn-theme continue ml-auto float-right" target="_blank" :href="xbodyIdUrl">
                      Fill your billing information to continue</a>
                    </div>
                  </div>
                </div>
              </div>
              <Loading v-else style="height: 200px;" />
            </section>
          </div>
        </div>
      </div>
    </div>
    <!-- <ConfirmationModal id="hasEUVatModal" title="Do you have a EU VAT number?" text="You can use EU VAT number."
      @hasEUConsent="hasEUConsent" /> -->
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  name: "CheckoutUserData",
  components: {
    Loading: defineAsyncComponent(() => import("@/components/Loading.vue")),
  },
  async mounted() {
    this.loadingData = true;
    if (this.$store.state.user.cart_items.length === 0) {
      this.$router.push({ name: "Cart" });
    }

    await this.axios
      .get("/countries")
      .then((resp) => (this.countries = this.countries.concat(resp.data)));

    await this.$store.commit("setCheckout");

    if (this.$store.state.checkout?.billingData) {

      this.billingData = this.$store.state.checkout.billingData;
      this.SAPCODE = this.$store.state.checkout.billingData.sap_code;
      //this.noSAP = this.$store.state.checkout.billingData.sap_code ? true : false;
      this.hasEUVat = this.billingData.company_eu_tax_id && this.billingData.company_eu_tax_id.length > 0;
      if(this.billingData.name){
        this.canContinue = true;
      }
      
    } else {
      this.canContinue = false;
    }

    // if (this.SAPCODE) {
    //   this.canContinue = true;
    //   this.disableSAP = true;
    //   this.canSkip = true;
    // }
    this.loadingData = false;
  },
  data() {
    return {
      xbodyIdUrl:process.env.VUE_APP_XBODY_URL,
      SAPCODE: null,
     // noSAP: false,
      canContinue: false,
      canSkip: false,
      disableSAP: false,
      billingData: {
        name: null,
        phone_number: null,
        country_id: null,
        zip_code: null,
        locality: null,
        address: null,
        public_space: null,
        public_space_type: null,
        other: null,
        company_name: null,
        company_tax_id: null,
        company_eu_tax_id: null,
        sap_code: null,
        is_company: 0,
      },
      billingErrors: Object(),
      VATValidity: null,
      hasEUVat: false,
      countries: [],
      loading: false,
      loadingData: false,
      wrongSA: false,
      isEU: false,
    };
  },
  methods: {
    showInfo() {
      let image = document.getElementById('info-img')

      if (image.classList.contains('d-none')) {
        image.classList.remove('d-none');
      } else {
        image.classList.add('d-none');
      }
    },

    async fetchSAP() {
      this.wrongSAP = false;
      if (!this.SAPCODE) {
        this.billingData = {
          name: null,
          phone_number: null,
          country_id: null,
          zip_code: null,
          locality: null,
          address: null,
          public_space: null,
          public_space_type: null,
          other: null,
          company_name: null,
          company_tax_id: null,
          company_eu_tax_id: null,
          sap_code: null,
          is_company: 0,
        }
        this.canContinue = false;
      }
      this.loadingData = true;
      await this.axios
        .get(
          "/billing-information/fetchSapCustomer/" + this.SAPCODE
        )
        .then((resp) => {
          if (resp.data.code == 200) {
            let billingInfo = resp.data.body.data.billing_info;
            let isCompany = resp.data.body.data.status_info.user_is_company;
            this.billingData = {
              name: resp.data.body.data.name,
              phone_number: resp.data.body.data.phone_number,
              country_id: this.countries.filter(country => billingInfo.adresses[0].country == country.code)[0].id,
              zip_code: billingInfo.adresses[0].zip_code,
              locality: billingInfo.adresses[0].city,
              address: billingInfo.adresses[0].street_name,
              public_space: billingInfo.adresses[0].street_name,
              public_space_type: billingInfo.adresses[0].street_type ? (billingInfo.adresses[0].street_type == '' ? '-' : billingInfo.adresses[0].street_type) : '-',
              other: billingInfo.adresses[0].street_number,
              company_name: resp.data.body.data.name,
              company_tax_id: resp.data.body.data.tax_info.non_eu_tax_number,
              company_eu_tax_id: resp.data.body.data.tax_info.eu_tax_number,
              sap_code: this.SAPCODE,
              is_company: isCompany,
            }
            if (resp.data.body.data.tax_info.hungary_tax_number) {
              this.billingData.company_tax_id = resp.data.body.data.tax_info.hungary_tax_number;
            }
            if (this.billingData.company_eu_tax_id != '' && this.billingData.company_eu_tax_id) {
              //   this.billingData.company_tax_id = this.billingData.company_eu_tax_id;
              this.hasEUVat = true
              this.isEU = true
            }
            this.canContinue = true;
          } else {
            this.wrongSAP = true;
          }

        })
        .catch(
          (e) => {
            console.log(e)
          })
        .finally(() => (this.loadingData = false));
    },
    setBillingData() {
      this.loading = true;
      if(this.canContinue ){
        this.$router.push({ name: "CheckoutSummary" });
      }else{
        alert('please configure your billing information');
      }
      // if (!this.billingData.is_company) {
      //   this.billingData.has_eu_vat = false;
      //   this.billingData.company_name = null;
      //   this.billingData.company_tax_id = null;
      //   this.billingData.company_eu_tax_id = null;
      //   this.axios
      //     .post(
      //       "/billing-information",
      //       this.$store.getters.removeEmptyOrNull(this.billingData)
      //     )
      //     .then((resp) => {
      //       this.$store.state.user = resp.data.user;
      //       //this.$router.push({name: 'CheckoutPaymentMethod'})
      //       this.$router.push({ name: "CheckoutSummary" });
      //     })
      //     .catch(
      //       (e) =>
      //       (this.billingErrors = e.response.data.errors
      //         ? e.response.data.errors
      //         : Object())
      //     )
      //     .finally(() => (this.loading = false));
      // } else {
      //   //checking if country is Hungary(HU)
      //   this.billingData.has_eu_vat = this.hasEUVat;
      //   if (this.hasEUVat) {
      //     this.billingData.company_tax_id = null;
      //     this.axios
      //       .post(
      //         "/check-vat-number",
      //         this.$store.getters.removeEmptyOrNull(this.billingData)
      //       )
      //       .then((resp) => {
      //         this.VATValidity = resp.data;
      //         this.axios
      //           .post(
      //             "/billing-information",
      //             this.$store.getters.removeEmptyOrNull(this.billingData)
      //           )
      //           .then((resp) => {
      //             this.$store.state.user = resp.data.user;
      //             this.$router.push({ name: "CheckoutSummary" });
      //           })
      //           .catch(
      //             (e) =>
      //             (this.billingErrors = e.response.data.errors
      //               ? e.response.data.errors
      //               : Object())
      //           )
      //           .finally(() => {
      //             this.loading = false;
      //             console.log("finally, billing info saved");
      //           });
      //       })
      //       .catch((e) => (this.billingErrors = e.response.data.errors))
      //       .finally(() => {
      //         console.log("finally, vat number check");
      //       });
      //   } else {
      //     this.billingData.company_eu_tax_id = null;
      //     this.axios
      //       .post(
      //         "/billing-information",
      //         this.$store.getters.removeEmptyOrNull(this.billingData)
      //       )
      //       .then((resp) => {
      //         this.$store.state.user = resp.data.user;
      //         //this.$router.push({name: 'CheckoutPaymentMethod'})
      //         this.$router.push({ name: "CheckoutSummary" });
      //       })
      //       .catch(
      //         (e) =>
      //         (this.billingErrors = e.response.data.errors
      //           ? e.response.data.errors
      //           : Object())
      //       )
      //       .finally(() => (this.loading = false));
      //   }
      // }
    },
    checkVATNumber() {
      this.axios
        .post(
          "/check-vat-number",
          this.$store.getters.removeEmptyOrNull(this.billingData)
        )
        .then((resp) => {
          this.VATValidity = resp.data;
        })
        .catch((e) => (this.billingErrors = e.response.data.errors))
        .finally(() => (this.loading = false));
    },
    hasEUConsent(val) {
      this.hasEUVat = val;
    },
  },
  watch: {
    "billingData.country_id": function (val) {
      //check if country is eu using country id in countries
      let isItEU = this.countries.find((c) => c.id === val && c.eu === 1);
      //toggle has EU VAT modal using ref
      if (isItEU && this.billingData.is_company) {
        this.isEU = true;
      } else {
        this.isEU = false;
      }
    },
    // "billingData.is_company": function (val) {
    //   //check if country is eu using country id in countries
    //   let isEU = this.countries.find(
    //     (c) => c.id === this.billingData.country_id && c.eu === 1
    //   );
    //   //toggle has EU VAT modal using ref
    //   if (isEU && val && this.billingData.country_id !== 141) {
    //     $("#hasEUVatModal").modal("show");
    //   } else {
    //     this.hasEUVat = false;
    //     // console.log('yess1')

    //   }
    // },
  },
};
</script>

<style scoped>
#checkout-user {
  margin-top: 30px;
}

.continue img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(189deg) brightness(105%) contrast(101%);
  height: 15px !important;
  margin-bottom: 0px;
}

/*Style for radio tag*/
input[type="radio"] {
  /* Radio */

  box-sizing: border-box;

  width: 20px;
  height: 20px;
  box-shadow: 0 3px 5px rgba(46, 46, 66, 0.08);
  border-radius: 100px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

input[type="radio"]:checked {
  /* Radio */

  box-sizing: border-box;

  width: 20px;
  height: 20px;

  /* Accent */
  accent-color: #d62b33;
  /* Dark grey */
  box-shadow: 0 3px 5px rgba(46, 46, 66, 0.08);
  border-radius: 100px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.info-icon {
  z-index: 2;
  padding: 8px 14px;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 50%;
  border: rgb(92, 92, 92) 1px solid;
}
</style>
