<template>
  <div class="row h-100">
    <div class="col-xl-4 left-side-image h-100">
      <img class="img-fluid small-logo" src="assets/img/xbody-dark.png" alt="">
    </div>
    <div class="col-xl-8 right-side-content">
      <div class="container h-100">
        <div class="row h-75 justify-content-center align-items-center bg-dark-white">
          <form class="col-xl-5 col-lg-8 col-md-8 col-sm-8 col-10 text-center" @submit.prevent="login">
            <img src="/assets/xbody-asset-1.svg" width="200" />
            <h2 class="mb-3 mt-4">{{ $t('login.login') }}</h2>
            <div class="mb-3">
              <label for="inputEmail" class="form-label float-left">{{ $t('login.email') }}</label>
              <input type="email"
                     id="inputEmail"
                     class="form-control login-input"
                     autocomplete="email"
                     :placeholder="$t('course-bar-association-report.user-mail')"
                     required
                     v-model="email"
                     autofocus/>
            </div>
            <div class="mb-3">
              <div class="flex-wrap d-flex">
                <div class="d-flex">
                  <label for="inputPassword" class="form-label">{{ $t('login.password') }}</label>
                </div>
                <div class="d-flex ml-auto">
                  <a href="#" @click.prevent="$router.push({name: 'ForgotPassword'})">{{
                      $t('login.forgot-password')
                    }}</a>
                </div>
              </div>
              <input type="password"
                     id="inputPassword"
                     autocomplete="current-password"
                     class="form-control"
                     :placeholder="$t('common.password')"
                     required
                     v-model="password"/>
            </div>
            <button type="submit" class="btn btn-normal w-100" :disabled="loading">{{ $t('login.login') }}</button>
            <h5 class="mt-4 text-13" @click.prevent="redirectToRegistration">{{ $t('login.no-account') }} <a
                href="#">{{ $t('login.registry') }}</a></h5>
            <h5 class="mt-2 text-13" @click.prevent="redirectToConfirmation">{{ $t('login.resend-confirmation') }} <a
                href="#">{{ $t('login.send') }}</a></h5>
          </form>
        </div>
        <div class="row">
          <div class="clearfix mx-auto">
            <div class="lang-selector">
              <span :class="$store.state.locale == 'hu' ? 'active' : ''" @click.prevent="changeLocale('hu')">HU</span>
              <span :class="$store.state.locale == 'en' ? 'active' : ''" @click.prevent="changeLocale('en')">EN</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "Login",
  data() {
    return {
      email: null,
      password: null,
      loading: false,
    };
  },
  mounted() {
    if(!localStorage.getItem('locale')){
      localStorage.setItem('locale','en')
    }
  },
  methods: {
    login() {
      this.loading = true;
      this.axios
          .post("/login", {email: this.email, password: this.password})
          .then((resp) => {
            this.$store.commit("setUser", resp.data.user);
            this.$store.commit("setCurrency", 'EUR');
            localStorage.setItem("accessToken", resp.data.token);
            this.$router.push({name: "Home"});
            this.axios.defaults.headers.common = {
              Authorization: `Bearer ${resp.data.token}`,
              Accept: "Application/json",
            };
            this.axios.defaults.headers.common['X-Socket-Id'] = window.Echo.socketId();

            window.Echo.options.auth.headers.Authorization = `Bearer ${resp.data.token}`
            this.$store.state.token = resp.data.token
            this.$store.dispatch('setupWebsocketListeners')
            this.$store.dispatch('getCustomPages')
          })
          .catch((err) => console.log(err))
          .finally(() => (this.loading = false));
    },
    redirectToConfirmation() {
      this.$router.push({name: 'Confirmation'});
    },
    redirectToRegistration() {
      this.$router.push({name: 'Register'});
    },
    async changeLocale(lang) {
      this.$i18n.locale = lang
      this.$store.commit('changeLocale', lang)
    }
  },
};
</script>
<style scoped>
.left-side-image {
  background-image: url('../assets/img/login-image.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 70px 70px !important;
}

.small-logo {
  width: 150px;
}

label small {
  color: var(--gray);
}

h6 {
  font-weight: 400;
}

.lang-selector {
  margin-top: 0;
  margin-bottom: 7px;
}

.lang-selector span {
  background-color: transparent;
  cursor: pointer;
  color: var(--red);
  padding: 3px 10px;
  border-radius: 5px;
  margin-right: 10px;
  border: 2px solid var(--red);
  font-size: 14px;
}

.lang-selector span.active {
  font-weight: bold;
  background-color: var(--red);
  color: var(--white);
}

.container {
  max-width: 100% !important;
}

@media (min-width: 1200px) {
  .right-side-content {
    padding-left: 0 !important;
  }
}

@media (max-width: 1199px) {
  .left-side-image {
    height: 100px !important;
    padding: 37px 50px !important;
  }

  .right-side-content {
    max-height: calc(100% - 100px);
  }

  .remove-text {
    display: none;
  }

  .small-logo {
    width: 100px;
  }
}

.btn-normal {
  padding: 10px 10px;
  border-radius: 5px !important;
}

input {
  border-color: var(--gray) !important;
  box-shadow: none !important;
  outline: none !important;
  color: var(--black);
}

input:focus {
  border-color: var(--red) !important;
}

label,
.d-flex a:active,
.d-flex a:visited,
.d-flex a:link {
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
}

a:active,
a:visited,
a:link {
  color: var(--black);
  text-decoration: none;
  font-weight: 400;
}

a:hover {
  color: var(--red);
}

@media (max-width: 991px) {
  label,
  .d-flex a:active,
  .d-flex a:visited,
  .d-flex a:link {
    font-size: 13px !important;
  }
}
</style>
