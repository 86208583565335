<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('reporting.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Reporting'})">{{ $t('reporting.statistic') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'CourseQuizReports', params: {course: $route.params.course}})">{{ $t('reporting.quiz') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'CourseQuizReport', params: {course: $route.params.course}})">{{course ? course.title : $t('common.course')}}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{test ? test.name : 'Teszt'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->
        <!-- RÉGI FRONT -->
        <div id="content-2">
            <div class="container">
                <div class="row" v-if="!loading && test">
                    <div class="col-md-6 col-12 mb-4">
                        <h3 class="text-18 text-dark-gray semi-bold mb-3">{{test.name}}</h3>
                        <div class="chart-box bg-white filter-shadow custom-input">
                            <course-test-percentage-chart :id="$route.params.test" :data="test.testAttempts" />
                        </div>
                    </div>
                    <div class="col-md-6 col-0"></div>
                    <div class="col-md-6 col-12 mb-4" v-for="question in test.test_questions.filter(q => q.question_type != 'Free text')">
                        <h2 class="text-18 text-dark-gray semi-bold mb-3">{{question.question}}</h2>
                        <div class="chart-box bg-white filter-shadow custom-input">
                            <course-test-question-chart :id="question.id" :data="question.test_question_answers" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CourseTestPercentageChart from '../components/Charts/CourseTestPercentageChart.vue'
import CourseTestQuestionChart from '../components/Charts/CourseTestQuestionChart.vue'
import Loading from '../components/Loading.vue'
export default {
    name: 'QuizReport',
    components: {
        CourseTestPercentageChart,
        Loading,
        CourseTestQuestionChart
    },
    mounted() {
        this.axios.get(`/reports/${this.$route.params.test}/test/${this.$route.params.course}`)
            .then(resp => {
                this.test = resp.data.test
                this.course = resp.data.course
            })
            .finally(() => this.loading = false)
    },
    data() {
        return {
            loading: true,
            test: null,
            course: null
        }
    },
    methods: {
        generateFakeData() {
            let data = []
            for(let i = 0; i < 100 ; i++) {
                data.push(Math.floor(Math.random() * 101))
            }
            return data
        }
    }

}
</script>
<style scoped>
    .chart-box {
        padding: 15px;
        border-radius: 8px;
    }
</style>