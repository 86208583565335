<template>
    <nav aria-label="..." :class="classes">
        <ul class="pagination justify-content-center">
            <li class="page-item arrow" :class="{disabled: paginator.current_page == 1}" >
                <a class="page-link" href="#" tabindex="-1" aria-disabled="true" @click.prevent="$emit('loadPage', Number(paginator.current_page)-1)"><i class="fas fa-arrow-left" :class="{disabled: paginator.current_page == 1}"></i></a>
            </li>
            <li class="last-page"><strong>{{ paginator.last_page }} / </strong></li>
            <li class="page-item" aria-current="page">
                <a class="page-link">{{ paginator.current_page }}</a>
            </li>
            <li class="page-item arrow" :class="{disabled: paginator.current_page == paginator.last_page}">
                <a class="page-link" href="#" @click.prevent="$emit('loadPage', Number(paginator.current_page)+1)"><i class="fas fa-arrow-right" :class="{disabled: paginator.current_page == paginator.last_page}" ></i></a>
            </li>
        </ul>
        <!-- <div> <strong>{{ paginator.last_page }} / {{ paginator.current_page }}</strong></div> -->
    </nav>
</template>
<script>
export default {
    name: 'RegularPaginator',
    props: {
        paginator: Object,
        classes: {
            optional: true
        }
    },
    emits: ['loadPage'],
    computed: {
        pages() {
            let arr = Array()
            for(let i = 1; i <= this.paginator.last_page; i++) {
                arr.push(i)
            }
            return arr
        }
    }
}
</script>

<style scoped>
.last-page{
    display: flex;
    align-items: center;
    margin-right: 5px;

}
    .pagination .page-item {
        width: 30px !important;
        box-shadow: none !important;
        outline: none !important;
    }
    .pagination .page-item:not(.arrow) {
        margin-right: 7px;
    }
    .page-item.arrow:last-child {
        margin-left: -13px;
    }
    .pagination .page-item .page-link {
        background-color: var(--white) !important;
        border-color: var(--red) !important;
        width: 30px !important;
        color: var(--red) !important;
        border-radius: 50%;
        font-size: 13px;
        padding-top: 6px !important;
        height: 30px !important;
    }
    .pagination .page-item.active .page-link {
        background-color: var(--red) !important;
        border-color: var(--red) !important;
        width: 30px !important;
        border-radius: 50%;
        font-size: 13px;
        padding-top: 6px !important;
        height: 30px !important;
        color: var(--white) !important;
    }
    .pagination .page-item.arrow .page-link {
        background: none !important;
        color: var(--black) !important;
        border: none !important;
    }


</style>
