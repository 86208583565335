<template>
        <div class="w-100 d-flex justify-content-center align-items-center gap-2">
            <span>Items per page</span>
            <select class="border-0" v-model="currentPerPage" >
            <option v-for="perPage in PerPages" :key="perPage" :value="perPage">{{ perPage }}</option>
        </select>
        <span>Page </span>
            <a class="rounded-circle page-link" href="#"  v-if="currentPage!=1" @click.prevent="currentPage--"><i class="fas fa-arrow-left"></i></a>
            <select class="border-0" v-model="currentPage">
            <option v-for="page in pages" 
            :key="page" 
            :value="page"
            >{{ page }}</option>
             </select>
        <a class=" rounded-circle page-link" href="#" v-if="currentPage!=paginator.last_page" @click.prevent="currentPage++"><i class="fas fa-arrow-right" ></i></a>
        </div>
</template>
<script>
import { watch } from 'vue'
export default {
    name: 'CustomPaginator',
    props: {
        paginator: Object,
        classes: {
            optional: true
        }
    },
    data() {
        return {
            PerPages: [
                10,
                20,
                30,
                50,
            ],
            currentPage:1,
            currentPerPage:10,
        }
    },
    emits: ['loadPage'],
    computed: {
        pages() {
            let arr = Array()
            for(let i = 1; i <= this.paginator.last_page; i++) {
                arr.push(i)
            }
            return arr
        }
    },
    watch: {
        currentPerPage: function(newPerPage) {
            this.$emit('loadPage', 1, newPerPage);
        },
        currentPage: function(newPage) {
            this.$emit('loadPage', newPage, this.currentPerPage);
         }
    }
}
</script>

