<template>
  <div>
    <!-- Toggle temporary certificate-->
    <div class="mt-2">
      <label>{{ $t('create-course.temporary_certificate') }}</label>
      <div class="custom-control custom-switch">
        <!--suppress HtmlUnknownAttribute -->
        <input type="checkbox" class="custom-control-input" id="temporary_certificate" :value="temporary_certificate" @update:modelValue="$emit('update:temporary_certificate', $event)" :true-value="true" :false-value="false" />
        <label class="custom-control-label" for="temporary_certificate">{{ $t('create-course.temporary_certificate_toggle') }}</label>
      </div>
    </div>
    <div v-show="temporary_certificate" class="mt-2">
      <label>{{ $t('create-course.temporary_certificate_type') }}</label>
      <Select2 :value="temporary_certificate_type" @update:modelValue="$emit('update:temporary_certificate_type', $event)" :options="certificate_types" :settings="{width:'100%', allowClear:false}" />
      <div class="d-flex align-items-center mt-1">
        <i class="fas fa-info-circle mr-1 text-info font-15" style="font-size: 15px;"></i>
        <span class="font-12" style="font-size: 12px">{{ $t('create-course.temporary_certificate_desc') }}</span>
      </div>
    </div>
    <div v-show="temporary_certificate" class="mt-2">
      <label>{{ $t('create-course.temporary_certificate_validity') }}</label>
      <input type="number" class="form-control" min="1" max="6" :value="temporary_certificate_validity" @update:modelValue="$emit('update:temporary_certificate_validity', $event)" />
      <div class="d-flex align-items-center mt-1">
        <i class="fas fa-info-circle mr-1 text-info font-15" style="font-size: 15px;"></i>
        <span class="font-12" style="font-size: 12px">{{ $t('create-course.temporary_certificate_validity_desc') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Select2 from "vue3-select2-component";

export default {
  name: 'TemporaryCertificateInput',
  components: {Select2},
  props: {
    certificate_types: {
      type: Array,
      default: []
    },
    temporary_certificate_type: {
      type: String,
      default: null
    },
    temporary_certificate_validity: {
      type: Number,
      default: null
    },
    temporary_certificate:{
      type: Boolean,
      default: false
    }
  },
  emits: ['update:temporary_certificate','update:temporary_certificate_type','update:temporary_certificate_validity'],
  watch: {
    temporary_certificate_type: {
      handler: function (val) {
        this.$emit('update:temporary_certificate_type', val)
      },
      deep: true
    },
    temporary_certificate_validity: {
      handler: function (val) {
        this.$emit('update:temporary_certificate_validity', val)
      },
      deep: true
    },
    temporary_certificate: {
      handler: function (val) {
        this.$emit('update:temporary_certificate', val)
        if (!val) {
          console.log('clear temporary_certificate_type and temporary_certificate_validity');
          this.$emit('update:temporary_certificate_type', null)
          this.$emit('update:temporary_certificate_validity', null)
        }
      },
      deep: true
    }
  }
}
</script>
<style scoped>
</style>
