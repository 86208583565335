<template>
    <form @submit.prevent="setOtherInformation">
        <slot>
            <div class="col-12 mt-2">
                <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('settings.other-information') }} </h3>
            </div>
        </slot>
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <h2 class="text-14 mt-2 text-light-black regular">
                        <span>{{$t('other-information.spoken')}}</span>
                        <span class="ml-2 pointer" @click.prevent="addLanguage">
                            <i class="fas fa-plus" />
                        </span>
                    </h2>
                </div>
                <div class="col-6">
                    <label for=""><small>{{$t('other-information.lang')}}</small> </label>
                </div>
                <div class="col-6">
                    <label for=""><small>{{$t('other-information.level')}}</small></label>
                </div>
                <div v-for="(lang, idx) in otherInformationData.spoken_languages" :key="idx" class="col-12 row pr-0">
                    <div class="col-md-6 d-flex align-items-center mb-1">
                        <div class="mr-1" style="width: 15px;">{{idx + 1}}.</div>
                        <input class="form-control" type="text" v-model="lang.language">
                    </div>
                    <div class="col-md-6 pr-0" style="display: inline-flex; gap: 0.25rem;">
                        <input class="form-control" type="text" v-model="lang.level">
                        <div @click.prevent="removeLanguage(idx)" style="align-self: center; color: red; cursor: pointer;">
                            <i class="fas fa-trash-alt" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--        <div class="col-12 mt-2">
            <label>{{$t('other-information.trans-need')}}</label>
            <select v-model="otherInformationData.translations_needed" class="form-control">
                <option :value="false">{{$t('common.no')}}</option>
                <option :value="true">{{$t('common.yes')}}</option>
            </select>
        </div>-->
        <div class="col-12 mt-2">
            <label>{{$t('other-information.edu-date')}}</label>
            <datetime class="form-control" placeholder="Please select a date" v-model="otherInformationData.date_of_education" />
        </div>
        <div class="col-12">
            <div class="flex-wrap d-flex">
                <div class="d-flex ms-auto">
                    <button type="submit" :disabled="loading" class="btn btn-theme mt-3 mb-3 "><img src="assets/img/icons/save.svg" class="img-fluid mr-2" alt="">{{ $t('settings.save-invoice') }}</button>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import Datetime from '@/components/DateTimePicker/Datetime'
export default {
    name: 'otherInformationData',
    components: {
        Datetime,
    },
    props: {
        errors: {
            type: Object,
            optional: true,
            default: null
        },
        withSave: {
            type: Boolean,
            optional: true,
            default: true
        }
    },
    created() {
        if(this.otherInformationData.spoken_languages === null) {
            const obj = {language: null, level: null}
            const arr = Array()
            arr.push(JSON.parse(JSON.stringify(obj)))
            arr.push(JSON.parse(JSON.stringify(obj)))
            arr.push(JSON.parse(JSON.stringify(obj)))
            this.otherInformationData.spoken_languages = arr
        }
    },
    data() {
        return {
            loading: false,
            localErrors: {},
            otherInformationData: this.$store.state.user && this.$store.state.user.other_information ? this.$store.state.user.other_information : {spoken_languages: null, translations_needed: false}
        }
    },
    computed: {
        otherInformationErrors() {
            return this.errors ?? this.localErrors
        }
    },
    methods: {
        removeLanguage(idx) {
            this.otherInformationData.spoken_languages.splice(idx, 1);
        },
        addLanguage() {
            this.otherInformationData.spoken_languages.push({language: null, level: null})
        },
        setOtherInformation() {
            this.loading = true
            this.axios.post('/other-information', this.$store.getters.removeEmptyOrNull(this.otherInformationData))
                .then(resp => {
                    this.$store.state.user = resp.data.user
                })
                .catch(e => this.localErrors = e.response.data.errors)
                .finally(() => this.loading = false)
        },
    }
}
</script>
