<template>
<div>
  <!-- BREADCRUMB -->
  <div class="margin-sticky-fix"></div>
  <div class="breadcrumb bg-dark-gray">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="flex-wrap d-flex">
            <div class="d-flex mr-4 pointer">
              <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('questionnaires.homepage') }}</span>
            </div>
            <div class="d-flex mr-4">
              <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
            </div>
            <div class="d-flex">
              <span class="text-16 text-white pt-3 pb-3">{{ $t('questionnaires.questionnaires') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- //BREADCRUMB -->
  <div id="content-2">
    <div class="container" v-if="!loading">
      <div class="row">
        <div class="col-12" >
          <section id="questionnaire-list">
            <h2 v-if="questionnaires.length > 0" class="text-18 text-dark-gray semi-bold mb-3">
              {{ $t('questionnaires.questionnaires') }}
            </h2>
            <div class="table-responsive">
              <table class="table table-striped" v-if="questionnaires.length > 0">
                <thead>
                <tr class="table-head bg-light-gray-primary text-center text-white">
                  <th scope="col" :class="'text-left pointer'+' '+(currentSort==='name'?'sorted':'')" @click="sort('name')">{{ $t('questionnaires.name') }}</th>
                  <th scope="col" :class="'pointer'+' '+(currentSort==='question_pool_size'?'sorted':'')" @click="sort('question_pool_size')">{{ $t('questionnaires.question-count') }}</th>
                  <th scope="col" :class="'pointer'+' '+(currentSort==='length'?'sorted':'')" @click="sort('length')">{{ $t('questionnaires.duration') }}</th>
                  <th scope="col" width="25%">&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="questionnaire in sortedQuestionnaires" :key="questionnaire.id">
                  <td>{{questionnaire.name}}</td>
                  <td class="text-center">{{questionnaire.question_pool_size}} </td>
                  <td class="text-center">{{parseDuration(questionnaire.length)}}</td>
                  <td class="text-center" :style="{color: questionnaire.exam ? 'var(--red)' : ''}">
                   <PrimaryButton :key="questionnaire.id" v-if="questionnaire.test_attempts.length===0" :disabled="loading" :text="$t('questionnaires.start-attempt')"  @click="openModal('AYSModal'+questionnaire.id)"/>
                    <PrimaryButton :key="questionnaire.id" v-if="questionnaire.test_attempts.length>0" :disabled="loading" :text="$t('questionnaires.continue')"  @click="openModal('AYSModal'+questionnaire.id)"/>
                  </td>
                  <!-- ARE YOU SURE? Modal -->
                  <div class="modal fade" :id="'AYSModal'+questionnaire.id" tabindex="-1"
                       aria-labelledby="AYSModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="AYSModalLabel">{{ $t('questionnaires.ays') }}</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <p class="col-12 text-center">{{$t("questionnaires.ays-start-attempt")}}</p>
                          </div>
                          <div class="row" v-if="questionnaire.test_attempts.length>0">
                            <div class="col-12">
                              <ul class="list-group list-group-flush">
                                <li v-for="test_attempt in questionnaire.test_attempts" class="list-group-item">
                                  <router-link :to="{name: 'Questionnaire', params: {id: questionnaire.id, attempt: test_attempt.id}}">{{$t("questionnaires.ays-test-attempt")}} {{test_attempt.id}}</router-link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button v-if="questionnaire.test_attempts.length===0" type="button" class="btn btn-primary" data-dismiss="modal">{{$t('questionnaires.cancel')}}</button>
                          <button v-if="questionnaire.test_attempts.length===0" type="button" @click.prevent="start_attempt(questionnaire.id)"  class="btn btn-secondary" data-dismiss="modal">{{$t('questionnaires.start-attempt')}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- //ARE YOU SURE? Modal -->
                </tr>
                </tbody>
              </table>
            </div>
            <!-- NO DATA -->
            <div v-if="!questionnaires || questionnaires.length===0">
              <div class="text-18 text-dark-gray semi-bold text-center">{{$t('no-data.questionnaire')}}</div>
            </div>
            <!-- //NO DATA -->
          </section>
        </div>
      </div>
    </div>
    <loading v-else height="70vh"/>
  </div>
</div>
</template>

<script>
import Loading from "../components/Loading";
import PrimaryButton from "../components/settings/PrimaryButton.vue";

export default {
  name: 'Questionnaires',
  components: {
    PrimaryButton,
    Loading
  },
  data() {
    return {
      loading:true,
      questionnaires: [],
      currentSort:'name',
      currentSortDir:'asc',
    }
  },
  mounted() {
    this.axios.get('/tests/fillable').then(resp => {
      this.questionnaires = resp.data.tests;
      this.loading = false;
    })
  },
  methods: {
    start_attempt(id) {
      this.loading = true
      this.axios.post( '/tests/' + id + '/attempts')
          .then((resp) => {
            console.log("resp",resp.data);
            this.$store.state.user.activeTestAttempt = resp.data.testAttempt;
            this.$router.push({name: 'Questionnaire', params: {id: id, attempt: resp.data.testAttempt.id}});
            this.loading = false;
          })
          .finally(() => this.loading = false)
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
    parseDuration(duration) {
      let all = duration*60;
      const hours = Math.floor(all / 3600)
      const minutes = Math.floor((all % 3600) / 60)
      return `${hours !== 0 ? hours + " " + this.$t('time-unit.hour') + " " : ''}${minutes !== 0 ? minutes + " " + this.$t('time-unit.minute') : ''}`
    },
    openModal(id){
      $("#"+id).modal("show");
    }
},
  computed: {
    sortedQuestionnaires: function () {
      return this.questionnaires.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    }
  }
}
</script>

<style scoped>
.sorted{
  font-weight: normal;
}
</style>
