<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-orders.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('manage-orders.manage-orders') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div id="content-2">
      <div class="container">
        <div class="row">
          <div class="col-12 mt-5 mb-3">
            <h2 class="text-18 text-dark-gray semi-bold">{{ $t('manage-orders.manage-orders') }}</h2>
          </div>
          <div v-if="!loading && orders" class="col-12 mb-4">
            <div class="new-table filter-shadow">
              <table class="table borderless-table">
                <thead class="bg-light-gray-primary">
                <tr>
                  <th scope="col" :class="'pointer'+' '+(currentSort4==='user'?'sorted':'')" @click="sort4('user')">
                    {{ $t('manage-orders.user') }}
                  </th>
                  <th scope="col" :class="'pointer'+' '+(currentSort4==='status'?'sorted':'')" @click="sort4('status')">
                    {{ $t('manage-orders.status') }}
                  </th>
                  <th scope="col" :class="'pointer'+' '+(currentSort4==='historyTotal'?'sorted':'')"
                      @click="sort4('historyTotal')">{{ $t('manage-orders.total') }}
                  </th>
                  <th scope="col" :class="'pointer'+' '+(currentSort4==='currency'?'sorted':'')"
                      @click="sort4('currency')">{{ $t('manage-orders.currency') }}
                  </th>
                  <th scope="col" :class="'pointer'+' '+(currentSort4==='exchange_rate'?'sorted':'')"
                      @click="sort4('exchange_rate')">{{ $t('manage-orders.rate') }}
                  </th>
                  <th scope="col" :class="'pointer'+' '+(currentSort4==='coupon'?'sorted':'')" @click="sort4('coupon')">
                    {{ $t('manage-orders.coupon') }}
                  </th>
                  <th scope="col" :class="'pointer'+' '+(currentSort4==='invoice_id'?'sorted':'')" @click="sort4('invoice_id')">
                    {{ $t('manage-orders.invoice') }}
                  </th>
                  <th scope="col" :class="'pointer'+' '+(currentSort4==='updated_at'?'sorted':'')"
                      @click="sort4('updated_at')">{{ $t('manage-orders.updated') }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="order in ordersHistory" :key="order.id">
                  <td>{{ order.user }}</td>
                  <td>{{ order.status }}</td>
                  <td>{{ order.historyTotal }}</td>
                  <td>{{ order.currency }}</td>
                  <td>{{ order.exchange_rate }}</td>
                  <td>{{ order.coupon?.code }}</td>
                  <td v-if="order.invoice_id">
                    <button class="btn btn-outline-primary" @click="downloadInvoice(order.invoice_id)">{{ order.invoice_id}}</button>
                  </td>
                  <td v-else>
                    <button :disabled="order.status!=='Succeeded' || creating" class="btn btn-outline-primary" @click="createInvoice(order.id)">{{ $t('manage-orders.create-invoice') }}</button>
                  </td>
                  <td>{{ order.updated_at }}</td>
                </tr>
                <tr v-if="ordersHistory.length === 0">
                  <td colspan="3" class="text-center text-light-black semi-bold pt-4 pb-4">{{
                      $t('no-data.order')
                    }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <Loading v-else width="100%"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../components/Loading";

export default {
  name: "ManageOrders",
  components: {
    Loading
  },
  data() {
    return {
      loading: true,
      creating:false,
      orders: [],
      currentSort4:'title',
      currentSort4Dir:'asc',
    }
  },
  mounted() {
    this.loading = true;
    this.axios.get('/orders')
        .then(resp => this.orders = resp.data.orders)
        .finally(() => this.loading = false);
  },
  methods:{
    createInvoice(id) {
      console.log("create invoice", id);
      this.creating = true;
      this.axios.post('/order/' + id + '/invoice')
          .then(resp => {
            console.log(resp);
            let index = this.orders.findIndex((order)=>(order.id===id));
            this.orders[index] = resp.data.order;
          }).catch(err =>{console.log("err", err);})
          .finally(() => this.creating = false);
    },
    downloadInvoice(dockEntry) {
      this.downloading = true;
      this.axios.get('/invoice/' + dockEntry + '/doc-entry')
          .then(resp => {
            window.open(resp.data.invoice.preview_pdf, '_blank');
            this.downloading = false;
          })
          .finally(() => this.downloading = false);
    },
    sort4: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort4) {
        this.currentSort4Dir = this.currentSort4Dir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort4 = s;
    },
  },
  computed: {
    ordersHistory: function () {
      let result =  (this.orders && this.orders.length)? this.orders.map((order) => {
        return {
          id: order.id,
          user: order.user.name,
          status: order.status,
          historyTotal: order.items.reduce((el, a)=>(el+a.net_price), 0),
          currency: order.currency,
          exchange_rate: order.exchange_rate,
          coupon: order.coupon,
          invoice_id: order.invoice_id,
          updated_at: this.$store.getters.parseDateTime(order.updated_at)
        }
      }) : [];

      return result.sort((a, b) => {
        let modifier = 1;
        if (this.currentSort4Dir === 'desc') modifier = -1;
        if (a[this.currentSort4] < b[this.currentSort4]) return -1 * modifier;
        if (a[this.currentSort4] > b[this.currentSort4]) return 1 * modifier;
        return 0;
      });
    }
  }
}
</script>

<style scoped>

</style>
