<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('my-courses.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{$t('membership.edit-membership')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->
        <div id="content-2" v-if="!loading && membership">
            <div class="container">
                <form @submit.prevent="submit">
                    <div class="row">
                        <div class="col-lg-6">
                            <section id="media-input" class="custom-input">
                                <div class="d-flex flex-wrap mb-2">
                                    <div class="d-flex mr-auto">
                                        <h3 class="text-18 text-light-black medium">{{ $t('edit-course.cover-img') }}</h3>
                                    </div>
                                    <div class="d-flex" v-if="membership.cover_image">
                                        <img @click.stop="removeCoverImage" :disabled="membership.imageUploading" src="assets/img/icons/trash-red.svg" class="pointer" alt="">
                                    </div>
                                </div>
                                <div v-if="!membership.cover_image && !membership.imageUploading" @click="$refs.coverImageUpload.click()" class="cover-upload filter-shadow mb-3 d-flex align-items-center justify-content-center pointer">
                                    <img src="assets/img/icons/add-photo.svg" class="upload-photo" alt="">
                                </div>
                                <div v-else-if="membership.cover_image && !membership.imageUploading" @click="$refs.coverImageUpload.click()" class="cover-upload filter-shadow" :style="{backgroundImage: `url(${$store.state.storageURL+membership.cover_image.path}?token=${$store.state.token})`}"></div>
                                <div v-else class="col-12 d-flex justify-content-center align-items-center" style="border: 2px solid #e0e0e0; height: 300px;">
                                    <Loading height="100%" />
                                </div>
                                <ImageCropper v-if="uploadedAvatarURL" @urlChanged="createUpload" :src="uploadedAvatarURL" :type="uploadType" :aspectRatio="null" />
                                <input ref="coverImageUpload" type="file" accept="image/jpeg, image/jpg, image/png" @change="readURL" style="display: none;">
                            </section>
                        </div>
                        <div class="col-lg-6">
                            <section id="media-input" class="custom-input">
                                <div class="form-group mb-3">
                                    <label for="" class="text-16 text-dark-warm-gray">{{ $t('edit-course.name') }}</label>
                                    <div v-if="errors['title']">
                                        <span class="text-13 semi-bold" v-for="(error, idx) in errors['title']" style="color: red;" :key="idx">{{error}}</span>
                                    </div>
                                    <input @input="errors['title'] = null" type="text" v-model="membership.title" class="form-control" :style="{border: errors['title'] ? '1px solid red' : '1px solid #ced4da'}" required>
                                </div>
                                <div class="form-group mb-3">
                                    <label for="" class="text-16 text-dark-warm-gray">{{$t('membership.membership-price')}}</label>
                                    <div v-if="errors['net_price']">
                                        <span class="text-13 semi-bold" v-for="(error, idx) in errors['net_price']" style="color: red;" :key="idx">{{error}}</span>
                                    </div>
                                    <input  @input="errors['net_price'] = null" type="number" min="1" v-model="membership.net_price" class="form-control" :style="{border: errors['net_price'] ? '1px solid red' : '1px solid #ced4da'}" required>
                                </div>
                                <div class="form-group">
                                    <span class="text-14 regular text-black">
                                        {{ $t('membership.duration') }}: {{duration}}
                                    </span>
                                </div>
                                <div class="space-helper"></div>
                                <!-- <button type="submit" class="btn btn-theme-outline float-right mt-3 mb-3 ml-3"><img src="assets/img/icons/trash-red.svg" class="img-fluid mr-1" style="height: 23px;margin-top: -4px;" alt="">Delete</button> -->
                                <button type="submit" :disabled="loading" class="btn btn-theme float-right mt-3 mb-3 "><img src="assets/img/icons/save.svg" class="img-fluid mr-2" alt="">{{ $t('edit-course.save-changes') }}</button>
                            </section>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>
<script>
import Loading from '@/components/Loading'
import ImageCropper from '@/components/ImageCropper.vue'
import Avatar from '@/components/Avatar.vue'
import { Duration } from 'luxon'

export default {
    name: 'EditCourse',
    components: {
        Loading,
        ImageCropper,
        Avatar,
    },
    mounted() {
        this.loading = true
        this.axios.get('/vat-rates')
            .then(resp => this.vatRates = this.vatRates.concat(resp.data))
        this.axios.get('/memberships/'+this.$route.params.membership)
            .then(resp => {
                this.membership = resp.data.membership
            })
            .finally(() => this.loading = false)

    },
    computed: {
        duration() {
            if(!this.membership) return;
            const duration = Duration.fromISO(this.membership.duration).normalize();
            return `${duration.years > 0 ? `${duration.years} year` : ''} ${duration.months > 0 ? `${duration.months} month` : ''} ${duration.day > 0 ? `${duration.day} day` : ''}`

        }
    },
    data() {
        return {
            submitting: false,
            loading: false,
            uploadedAvatarURL: null,
            uploadType: null,
            loading: false,
            membership: null,
            vatRates: [],
            errors: Object(),
            deleting: false
        }
    },
    methods: {
        createUpload(url) {
            if(url) {
                let file = this.dataURLtoFile(url)
                let formData = new FormData;
                formData.set('image', file, file.name);
                this.membership.imageUploading = true
                console.log(formData)
                let config = {
                    method: 'POST',
                    data: formData,
                    url: `/upload/image`,
                    baseURL: process.env.VUE_APP_STORAGE_URL,
                    headers: {
                        'Content-Type': 'application/octet-stream'
                    },
                };
                this.axios(config)
                    .then(async (resp) => {
                        config.url += '/' + resp.data.name
                        config.url += '/finish';
                        config.data = null;
                        resp = await this.axios(config)
                        this.membership.cover_image = resp.data.multimedia;
                        this.membership.cover_image_id = resp.data.multimedia.id
                        await this.axios.patch('/courses/'+this.membership.id, {image_id: this.membership.cover_image_id})

                    })
                    .catch((e) => {})
                    .finally(() => this.membership.imageUploading = false)
            }

        },
        readURL(input) {
            input = input.target
            if (input.files && input.files[0]) {
                this.uploadType = input.files[0].type
                var reader = new FileReader()
                reader.onload = (e) => {
                    this.uploadedAvatarURL = e.target.result
                }

                reader.readAsDataURL(input.files[0]) // convert to base64 string
            }
        },
        dataURLtoFile(dataurl, filename) {

            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], `${this.membership.id}_image`, {type:mime});
        },
        removeCoverImage() {
            this.axios.post(process.env.VUE_APP_STORAGE_URL+'/upload/image/'+this.membership.cover_image.id+'/abort')
            this.membership.cover_image = null
            this.membership.cover_image_id = null
            this.$refs.coverImageUpload.value = null
        },
        removeEmptyOrNull(obj) {
            Object.keys(obj).forEach(k => {
                if((obj[k] === null || obj[k] === '') && k !== 'location_id')
                    delete obj[k]
            })
            return obj;
        },
        submit() {
            this.loading = true
            this.axios.patch('/memberships/'+this.membership.id, this.membership)
                .then(resp => {
                    this.uploadedAvatarURL = null
                    this.$router.push({name: 'membershipList'})
                })
                .catch(e => this.errors = e.response.data.errors)
                .finally(() => this.loading = false)
        },
    }
}
</script>
