<template>
<div>
    <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="flex-wrap d-flex">
                        <div class="d-flex mr-4 pointer">
                            <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('edit-test.homepage') }}</span>
                        </div>
                        <div class="d-flex mr-4">
                            <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                        </div>
                        <div class="d-flex mr-4 pointer">
                            <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'CustomPageList'})">{{ $t('custom-pages.custom-pages') }}</span>
                        </div>
                        <div class="d-flex mr-4">
                            <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                        </div>
                        <div class="d-flex mr-4">
                            <span class="text-16 text-white pt-3 pb-3">{{$t('custom-pages.create-custom-page')}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- //BREADCRUMB -->
    
    <!-- Custom page group modal -->
    <create-custom-page-group-modal />

    <!-- Edit custom page group modal -->
    <edit-custom-page-groups-modal />
    <div class="container mt-3">
        <div class="row">
            <div class="col-12 mt-3">
                <label class="text-title-gray weight-600 text-16">{{$t('custom-pages.link')}}</label>
                <div>
                    <i class="fas fa-info-circle text-primary" />
                    {{$t('custom-pages.link-info')}}
                </div>
                <p v-if="httpError" style="color: red">{{ $t('manage-news.url-lore') }}</p>
                <input :style="httpError ? 'border: 1px solid red;' : ''" 
                    type="text" 
                    v-debounce:400ms="validLink" 
                    class="form-control" 
                    v-model="page.link" 
                    :placeholder="$t('manage-news.url-lore-2')"
                    required
                >
            </div>
            <div class="col-12 mt-3">
                <label class="text-title-gray weight-600 text-16">{{$t('custom-pages.group')}}</label>
                <select class="form-control" v-model="page.group_id" required>
                    <option disabled :value="null">{{$t('course-materials.choose-list-create')}}</option>
                    <option 
                        v-for="customPageGroup in $store.state.customPageGroups" 
                        :key="customPageGroup.id" 
                        :value="customPageGroup.id"
                    >
                        {{customPageGroup.title[$store.state.locale] ? customPageGroup.title[$store.state.locale] : customPageGroup.title['en']}}
                    </option>
                </select>
                <div class="d-flex justify-content-between mt-2">
                    <button class="btn btn-secondary" data-toggle="modal" data-target="#createCustomPageGroupModal">{{$t('custom-page-groups.create-new')}}</button>
                    <button class="btn btn-primary" data-toggle="modal" data-target="#editCustomPageGroupsModal">{{$t('custom-page-groups.edit')}}</button>
                </div>
            </div>
            <div class="col-12 mt-3">
                <label class="text-title-gray weight-600 text-16">{{$t('custom-pages.order')}}</label>
                <input class="form-control" type="number" style="width: 80px;" v-model="page.order" required />
            </div>
            <div class="col-12 mt-3">
                <div class="d-flex align-items-center mb-2 text-title-gray weight-600 text-16">
                    <img src="assets/img/lang/en.png" style="height: 20px;">
                    <label class="mb-0 ml-1">{{ $t('custom-pages.title') }}</label>
                </div>
                <input class="form-control" v-model="page.title.en" required />
            </div>
            <div class="col-12 mt-3">
                <div class="d-flex align-items-center mb-2 text-title-gray weight-600 text-16">
                    <img src="assets/img/lang/hu.png" style="height: 20px;">
                    <label class="mb-0 ml-1">{{ $t('custom-pages.title') }}</label>
                </div>
                <input class="form-control" v-model="page.title.hu" required />
            </div>
            <div class="col-12 mt-3" id="text-assembler">
                <div>
                    <i class="fas fa-info-circle text-primary" />
                    {{$t('custom-pages.only-link-info')}}
                </div>
                <label class="c-inp mt-2"> 
                    <span class="text-title-gray text-18 weight-600">{{ $t('custom-pages.only-link') }}</span>
                    <input type="checkbox" v-model="onlyLink">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="col-12 mt-3" v-show="!onlyLink">
                <div class="d-flex align-items-center mb-2 text-title-gray text-18 weight-600">
                    <img src="assets/img/lang/en.png" style="height: 20px;">
                    <label class="mb-0 ml-1">{{ $t('custom-pages.content') }}</label>
                </div>
                <textarea ref="content-en" />
            </div>
            <div class="col-12 mt-3" v-show="!onlyLink">
                <div class="d-flex align-items-center mb-2 text-title-gray weight-600 text-16">
                    <img src="assets/img/lang/hu.png" style="height: 20px;">
                    <label class="mb-0 ml-1">{{ $t('custom-pages.content') }}</label>
                </div>
                <textarea ref="content-hu" />
            </div>
            <div class="col-12 mt-4">
                <button class="btn btn-secondary" @click.prevent="submit" :disabled="submitting">{{$t('common.create')}}</button>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import CreateCustomPageGroupModal from '../components/CreateCustomPageGroupModal.vue'
import EditCustomPageGroupsModal from '../components/EditCustomPageGroupsModal.vue'
export default {
    name: 'CreateCustomPage',
    components: {
        CreateCustomPageGroupModal,
        EditCustomPageGroupsModal,
    },
    mounted() {
        $(this.$refs['content-en']).summernote({
            height: 400,
            callbacks: {
                onChange: (contents, $editable) => {
                    this.page.content.en = contents
                }
            }
        })
       $(this.$refs['content-hu']).summernote({
            height: 400,
            callbacks: {
                onChange: (contents, $editable) => {
                    this.page.content.hu = contents
                }
            }
        })
    },
    data() {
        return {
            submitting: false,
            httpError: false,
            onlyLink: false,
            page: {
                link: process.env.VUE_APP_FRONTEND_URL + '/',
                title: {
                    hu: null,
                    en: null
                },
                content: {
                    hu: null,
                    en: null
                },
                order: null,
                group_id: null,
            }
        }
    },
    methods: {
        validLink() {
            if(this.page.link == '' || this.page.link.match(/^http(s?):\/\//)) {
                this.httpError = false
            }
            else {
                this.httpError = true
            }
        },
        submit() {
            if(this.submitting) return;
            this.submitting = true
            if(this.onlyLink) {
                this.page.content.hu = null
                this.page.content.en = null
            }
            this.axios.post('/custom-pages', this.page)
                .then(resp => {
                    this.$router.push({name: 'CustomPageList'})
                })
                .catch(e => console.log(e))
                .finally(() => this.submitting = false)
        }
    }
}
</script>
<style scoped>
/* CUSTOM CHECKBOX */

.c-inp {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.c-inp input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border: 2px solid #333E48;
}

.c-inp input:checked ~ .checkmark {
    background-color: #333E48;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.c-inp input:checked ~ .checkmark:after {
    display: block;
}

.c-inp .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.c-inp span {
    white-space: normal;
    word-wrap: break-word;
}
</style>