<template>
    <canvas :id="'courseFeedbackQuestion'+id"></canvas>
</template>
<script>
export default {
    name: 'courseFeedbackQuestionChart',
    props: {
        data: Object,
        id: Number
    },
    mounted() {
        console.log(this.data)
        Chart.defaults.scale.gridLines.display = false;
        const ctx = $('#courseFeedbackQuestion'+this.id)
        this.chart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: this.data.map(d => d.key),
                datasets: [
                    {
                        data: this.data.map(d => d.value),
                        backgroundColor: 'rgb(23, 111, 183, 0.8)',
                        borderColor: 'rgb(23, 111, 183, 1)',
                    }
                ]
            },
            options: {
                legend: {
                    display: false,
                    labels: {
                        fontColor: 'rgb(255, 99, 132)'
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            min: 0,
                            stepSize: 1
                        }
                    }]
                }
                
            }
        });
    },
    data() {
        return {
            chart: null
        }
    }
}
</script>