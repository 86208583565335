<template>
    <div v-if="answer.multimedia.type == 'video' || answer.multimedia.type == 'image' " 
        style="color: var(--black); cursor:pointer; width: 100%; height: 100%" 
        :data-fancybox="`newQuestion`" 
        :href="answer.multimedia.type != 'video' ? $store.getters.getStorageURL(answer.multimedia.path) : `#question-${answer.id}-${answer.multimedia.id}`" target="_blank"
        :data-caption="answer.multimedia.name"
    >
        <div v-if="answer.multimedia.type == 'video'" class="test-attempt-video-container">
            <video class="test-attempt-video" nocontrols>
                <source :src="$store.getters.getStorageURL(answer.multimedia.path)">
            </video>
            <video :id="`question-${answer.id}-${answer.multimedia.id}`" style="display: none;" controls controlsList="nodownload">
                <source :src="$store.getters.getStorageURL(answer.multimedia.path)">
            </video>
            <i class="far fa-play-circle playbtn" style="text-shadow: 10 10 10px #000"></i>
        </div>
        <div v-if="answer.multimedia.type == 'image'" class="test-attempt-image-container">
            <img :src="$store.getters.getStorageURL(answer.multimedia.path)" class="test-attempt-image" style="max-height: 200px !important;">
        </div>
    </div>
</template>
<script>
export default {
    name: 'TestQuestionAnswerMultimedia',
    props: {
        answer: Object
    }
}
</script>