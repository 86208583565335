<template>
<div>
    <div class="row line mt-3" style="display: flex;margin-left: 0px !important;margin-right: 0px !important;padding-left: 32px !important;padding-right: 0px !important;">
        <!-- WRITE COMMENT INPUT -->
        <div v-if="user.hasActiveMembership == true && user.role!='User'" class="flex-wrap d-flex w-100" data-toggle="modal" :data-target="'#newCommentModal-'+post.id">
            <div class="d-flex w-100">
                <div class="helper-box mr-2"></div>
                <div class="d-flex w-100 write-comment align-items-center pointer">
                    <div class="d-flex mr-auto">
                        <h6 class="text-light-gray text-12 medium">{{$t('post.new-comment')}}</h6>
                    </div>
                    <div class="d-flex">
                        <img src="assets/img/icons/camera.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
        <!-- //WRITE COMMENT INPUT -->
    </div>
            <!-- NEW COMMENT MODAL -->
            <div class="modal fade" :id="'newCommentModal-'+post.id" tabindex="-1" role="dialog" :aria-labelledby="'newCommentModal-'+post.idLabel" :ref="'newCommentModal-'+post.id"
                aria-hidden="true" style="backdrop-filter: none !important;">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header  text-center">
                            <h5 class="post-title w-100" :id="'newCommentModal-'+post.idLabel">{{$t('post.comment')}}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body w-100">
                            <div class="row">
                                <div class="col-12 modal-message">
                                    <div class="form-group">
                                        <textarea :placeholder="$t('post.comment-text')" class="form-control"
                                            :id="'newCommentModal-'+post.idTextarea"
                                            style="width: 100%"
                                            v-model="comment"
                                            :disabled="loading"></textarea>
                                            <div class="text-right"><sub>{{comment ? comment.length : 0}} / 2000</sub></div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button type="button" :disabled="loading" class="btn btn-image-upload w-100 mb-0" @click="$refs.imageUpload.click()">
                                        <input type="file" accept="image/png, image/jpeg, image/jpg, image/gif" id="uploadImage" ref="imageUpload" @change="createUpload('image')" style="display: none;">
                                        <div class="d-flex align-items-center pointer mr-auto">
                                            <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                <img src="assets/img/icons/white-camera.svg" class="img-fluid ml-1" alt="">
                                            </div>
                                            <div class="d-flex flex-column ml-2">
                                                <h6 class="text-16 text-dark-gray">{{ $t('feed.attach-pic') }}</h6>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                <div v-if="files.length > 0" class="col-12">
                                    <h5 class="mb-2 mt-4">{{ $t('feed.uploads') }}</h5>
                                    <div class="row">
                                        <div v-for="(file, index) in files" :key="file.id" class="col-12 mb-3">
                                            <Upload v-if="file.shown" :file="file.file" :type="file.type" :id="file.id" @uploadFinished="finishUpload" @uploadCancelled="cancelUpload"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" :disabled="!canSend" @click="sendComment" class="btn btn-theme w-100">{{ $t('post-comment-box.send') }}</button>
                        </div>
                    </div>
                </div>
            </div>
</div>
</template>
<script>
import Avatar from '@/components/Avatar'
import Upload from '@/components/Upload'
export default {
    name: 'PostCommentBox',
    components: {
        Avatar,
        Upload
    },
    props: {
        post: Object
    },
    data() {
        return {
            files: [],
            loading: false,
            comment: '',
        }
    },
    computed: {
        canSend() {
            return !this.files.find(file => file.uuid === null) && !this.loading
        },
      user() {
        return this.$store.state.user
      }
    },
    methods: {
        createUpload(type) {
            if(event.target.files.item(0)) {
                this.loading = true
                this.files.push({file: event.target.files.item(0), type: type, shown: true, uuid: null, id: this.files.length})
                event.target.value = null
            }
        },
        finishUpload(id, multimedia) {
            this.files.find(file => file.id === id).uuid = multimedia.id
            this.loading = false
        },
        cancelUpload(id, uuid) {
            this.loading = false
            this.files.splice(this.files.indexOf(this.files.find(file => file.id === id)), 1)
        },
        sendComment() {
            let formData = {}
            this.loading = true
            const multimedia = this.files.filter(file => file.uuid !== null).map(file => file.uuid)
            if(this.comment)
                formData.comment = this.comment
            if(multimedia.length > 0)
                formData.multimedia = multimedia
            this.loading = true;
            this.axios.post(`/posts/${this.post.id}/comments`, formData)
            .then(resp => {
                this.post.comments.push(resp.data.comment)
                this.comment = ''
                this.files = []
                $(this.$refs['newCommentModal-'+this.post.id]).modal('hide')
            }).catch(e => console.log(e.response))
            .finally(() => this.loading = false)
        },
     }
}
</script>
<style scoped>
    /* WRITE COMMENT INPUT */
    .write-comment {
        border: 1px solid #B5ADA6;
        border-radius: 5px;
        padding: 10px;
    }
</style>
