<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-courses.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('certificates.certificates') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->

    <div class="container mt-4">
      <section v-if="!loading && certificates" id="list-table" class="table-responsive" style="height: 100% !important;">
        <div class="d-flex flex-wrap align-items-center justify-content-between">
          <div class="d-flex">
            <h3 class="mb-3 mt-3 text-22 text-title-gray weight-700">{{ $t('certificates.certificates') }} </h3>
          </div>
        </div>
        <div class="new-table filter-shadow" style="height: 100% !important;">
          <table class="table borderless-table">
            <thead class="bg-light-gray-primary">
            <tr>
              <th :class="'pointer'+' '+(currentSort==='ownerName'?'sorted':'')" @click="sort('ownerName')">{{ $t('certificates.certificate-owner') }}</th>
              <th :class="'pointer'+' '+(currentSort==='courseName'?'sorted':'')" @click="sort('courseName')">{{ $t('certificates.certificate-course') }}</th>
              <th :class="'pointer'+' '+(currentSort==='created_at'?'sorted':'')" @click="sort('created_at')">{{ $t('certificates.certificate-date') }}</th>
              <th>{{ $t('certificates.certificate') }}</th>
              <th>{{ $t('certificates.certificate-card') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="cert in sortedCertificates" :key="cert.id">
              <td>{{cert.ownerName ?? 'Not specified'}} </td>
              <td>{{cert.courseName}}</td>
              <td>{{$store.getters.parseDateTime(cert.created_at)}}</td>
              <td>
                <div class="d-flex align-items-center">
                  <button type="button" :disabled="loading" class="btn-outline-danger btn mb-2" @click.prevent="downloadCertificate(cert.course, cert.user)">
                    <div class="d-flex align-items-center">
                      <i class="fas fa-arrow-circle-down mr-1"></i>
                      <span>{{$t('activity-history.download-activity-diary2')}}</span>
                    </div>
                  </button>
                  <div v-if="loading" class="text-danger spinner-border ml-2 mb-2" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <button type="button" :disabled="loading" class="btn-outline-danger btn mb-2" @click.prevent="downloadCertificateCard(cert.course, cert.user)">
                    <div class="d-flex align-items-center">
                      <i class="fas fa-arrow-circle-down mr-1"></i>
                      <span>{{$t('activity-history.download-activity-diary2')}}</span>
                    </div>
                  </button>
                  <div v-if="loading" class="text-danger spinner-border ml-2 mb-2" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="certificates.length === 0">
              <td colspan="5" class="text-center">
                {{ $t('certificates.no-certificates') }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </section>
      <div v-else>
        <Loading height="80vh" />
      </div>
    </div>
  </div>
</template>
<script>
import {DateTime} from 'luxon'
import Loading from '@/components/Loading'
import {Capacitor} from "@capacitor/core";
export default {
  name: 'CourseManagement',
  components: {
    Loading,
  },
  mounted() {
    this.loading = true;
    this.axios.get('/certificates')
        .then(resp => this.certificates = resp.data.certificates)
        .finally(() => this.loading = false)
  },
  data() {
    return {
      certificates: null,
      loading: false,
      DateTime: DateTime,
      currentSort:'id',
      currentSortDir:'asc',
    }
  },
  methods:{
    downloadCertificate(course, user) {
      this.loading = true;
      this.axios.get('/courses/'+course.id+'/certificate?user='+user.id, {responseType: 'blob'})
          .then(resp => {
            const name = course.title+'_cert.pdf'
            if(Capacitor.isNative) {
              this.$store.commit('downloadFileMobile', {name, mime: 'application/pdf', data: resp.data})
            }
            else {
              const url = window.URL.createObjectURL(new Blob([resp.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', name);
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(e => console.log(e.response))
          .finally(() => this.loading = false)
    },
    downloadCertificateCard(course, user) {
      this.loading = true;
      this.axios.get('/courses/'+course.id+'/certificate-card?user='+user.id, {responseType: 'blob'})
          .then(resp => {
            const name = course.title+'_card.pdf'
            if(Capacitor.isNative) {
              this.$store.commit('downloadFileMobile', {name, mime: 'application/pdf', data: resp.data})
            }
            else {
              const url = window.URL.createObjectURL(new Blob([resp.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', name);
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(e => console.log(e.response))
          .finally(() => this.loading = false)
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
  },
  computed: {
    sortedCertificates: function () {
      let certificates = this.certificates.map((cert)=>{
        cert.card_path = undefined;
        return {
          id:cert.id,
          ownerName:cert.user.name,
          courseName:cert.course.title,
          created_at:cert.created_at,
          user:cert.user,
          course:cert.course,
          path:cert.path,
          cartPath:cert.card_path
        };
      });
      return certificates.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    }
  }
}
</script>
<style scoped>
.btn-outline-primary:hover {
  background-color: var(--red) !important;
  border-color: var(--red) !important;
}
.sorted{
  font-weight: normal;
}
</style>
