<template>
<div>
    <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="flex-wrap d-flex">
                        <div class="d-flex mr-4 pointer">
                            <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-news.homepage') }}</span>
                        </div>
                        <div class="d-flex mr-4">
                            <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                        </div>
                        <div class="d-flex mr-4 pointer">
                            <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'ManageNews'})">{{ $t('manage-news.manage-news') }}</span>
                        </div>
                        <div class="d-flex mr-4">
                            <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                        </div>
                        <div class="d-flex">
                            <span class="text-16 text-white pt-3 pb-3">{{ $t('manage-news.edit-news') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- //BREADCRUMB -->
    <div id="content-2">
        <div class="container">
            <div class="row" v-if="!loading">
                <div class="col-lg-6">
                    <section id="media-input" class="custom-input">
                        <div class="d-flex flex-wrap mb-2">
                            <dib class="d-flex mr-auto">
                                <h3 class="text-18 text-light-black medium">{{ $t('create-course.cover-img') }}</h3>
                            </dib>
                            <dib class="d-flex" v-if="multimedia && false" @click.stop="removemultimedia" :disabled="multimediaUploading">
                                <img src="assets/img/icons/trash-red.svg" class="pointer" alt="">
                            </dib>
                        </div>
                        <div v-if="!news.multimedia && !multimediaUploading" @click="$refs.multimediaUpload.click()" class="cover-upload filter-shadow mb-3 d-flex align-items-center justify-content-center pointer"> 
                            <img src="assets/img/icons/add-photo.svg" class="upload-photo" alt="">
                        </div>
                        
                        <div v-else-if="news.multimedia && !multimediaUploading" @click="$refs.multimediaUpload.click()" class="cover-upload filter-shadow" :style="{backgroundImage: `url(${$store.state.storageURL+news.multimedia.path}?token=${$store.state.token})`}"></div>
                        <div v-else class="col-12 d-flex justify-content-center align-items-center" style="border: 2px solid #e0e0e0; height: 300px;">
                            <Loading height="100%" />
                        </div>
                        <ImageCropper v-if="uploadedAvatarURL" @urlChanged="createUpload" :src="uploadedAvatarURL" :type="uploadedType" :aspectRatio="null" />
                        <input ref="multimediaUpload" type="file" accept="image/jpeg, image/jpg, image/png" @change="readURL" style="display: none;">
                    </section>
                </div>
                <div class="col-lg-6">
                    <section id="media-input" class="custom-input">
                        <form action="">
                            <div class="form-group mb-3">
                                <label for="" class="text-16 text-dark-warm-gray">{{ $t('manage-news.add-text') }} <img src="assets/img/lang/hu.png" style="height: 20px;"></label>
                                <input type="text" class="form-control" v-model="news.content.hu">
                            </div>
                            <div class="form-group mb-3">
                                <label for="" class="text-16 text-dark-warm-gray">{{ $t('manage-news.add-text') }} <img src="assets/img/lang/en.png" style="height: 20px;"></label>
                                <input type="text" class="form-control" v-model="news.content.en">
                            </div>
                            <div class="form-group mb-3">
                                <label for="" class="text-16 text-dark-warm-gray">{{ $t('manage-news.url-lore') }}</label>
                            <p class="text-13 semi-bold" v-if="httpError" style="color: red">{{ $t('manage-news.url-lore') }}</p>
                                <input :style="httpError ? 'border: 1px solid red;' : ''" type="text" v-debounce:400ms="validLink" class="form-control" v-model="news.link" :placeholder="$t('manage-news.url-lore-2')">
                            </div>
                            <div class="space-helper"></div>
                            <!-- <button type="submit" class="btn btn-theme-outline float-right mt-3 mb-3 ml-3"><img src="assets/img/icons/trash-red.svg" class="img-fluid mr-1" style="height: 23px;margin-top: -4px;" alt="">Delete</button> -->
                            <button @click.prevent="submit" :disabled="submitting || !news.multimedia_id || !String(news.content).trim()" type="submit" class="btn btn-theme float-right mt-3 mb-3 "><img src="assets/img/icons/save.svg" class="img-fluid mr-2" alt="">{{ $t('manage-news.save') }}</button>
                        </form>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import ImageCropper from '@/components/ImageCropper'
import Loading from '@/components/Loading'

export default {
    name: 'EditNews',
    components: {
        Loading,
        ImageCropper,
    },
    props: {
        newsProp: String
    },
    mounted() {
        if(!this.newsProp) {
            this.loading = true
            this.axios.get('/news/'+this.$route.params.id)
                .then(resp => {
                    this.news = resp.data
                })
                .catch(e => console.log(e))
                .finally(() => this.loading = false)
        }
        else {
            this.news = JSON.parse(this.newsProp)
            this.loading = false
        }
    },
    data() {
        return {
            multimediaUploading: false,
            uploadedAvatarURL: null,
            submitting: false,
            loading: true,
            httpError: false,
            news: null
        }
    },
    methods: {
        validLink() {
            if(this.news.link == '' || !this.news || this.news.link.match(/^http(s?):\/\//)) {
                this.httpError = false
            }
            else {
                this.httpError = true
            }
        },
        createUpload(url) {
            if(url) {
                let file = this.dataURLtoFile(url)
                let formData = new FormData;
                formData.set('image', file, file.name);
                this.multimediaUploading = true
                let config = {
                    method: 'POST',
                    data: formData,
                    url: `/upload/image`,
                    baseURL: process.env.VUE_APP_STORAGE_URL,
                    headers: {
                        'Content-Type': 'application/octet-stream'
                    },
                };
                this.axios(config)
                    .then(resp => {  
                        config.url += '/' + resp.data.name
                        config.url += '/finish';
                        config.data = null;
                        this.axios(config)
                        .then(resp => {
                            this.news.multimedia = resp.data.multimedia;
                            this.news.multimedia_id = resp.data.multimedia.id;
                        })
                        .finally(() => this.multimediaUploading = false)
                    })
                    .catch((e) => this.multimediaUploading = false)
            }

        },
        readURL(input) {
            input = input.target
            if (input.files && input.files[0]) {
                this.uploadedType = input.files[0].type
                var reader = new FileReader()
                reader.onload = (e) => {
                    this.uploadedAvatarURL = e.target.result
                }
                
                reader.readAsDataURL(input.files[0]) // convert to base64 string
            }
        },
        dataURLtoFile(dataurl, filename) {
 
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), 
                n = bstr.length, 
                u8arr = new Uint8Array(n);
                
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], 'coverimg', {type: 'image/jpeg'});
        },
        removemultimedia() {
            this.axios.post(process.env.VUE_APP_STORAGE_URL+'/upload/image/'+this.multimedia.id+'/abort')
            this.news.multimedia = null
            this.news.multimedia_id = null
            this.$refs.multimediaUpload.value=null;
        },
        submit() {
            if(this.news.multimedia_id) {
                this.submitting = true
                this.axios.patch('/news/'+this.news.id, this.news)
                    .then(resp => {
                        this.$router.push({name: 'ManageNews'})
                    })
                    .catch(e => console.log(e))
                    .finally(() => this.submitting = false)
            }
        }
    }
}
</script>
<style scoped>
    #media-input {
        margin-top: 30px;
    }
    .space-helper {
        margin-top: 87px;
    }
</style>