<template>
  <div v-if="!loading && $store.state.user && group">
    <div v-if="group.name != null" class="d-flex align-items-center phone-p-top"
         style="background: #AABAC6; height: 70px;">
      <Avatar :size="40" :username="group.name"/>
      <h2 class="ml-2" style="font-size: 18px; font-weight: 600; color: white;">{{ group.name }}</h2>
      <span v-if="group.creator_id == $store.state.user.id" class="ml-4 mb-1"
            style="color: var(--red); cursor: pointer;" data-toggle="modal" data-target="#editGroupModal">
            <i class="fas fa-edit" style="font-size: 30px"></i>
        </span>
      <span class="ml-4 mb-1" style="color: var(--red); cursor: pointer;" data-toggle="modal"
            data-target="#userListModal">
            <i class="fas fa-address-book" style="font-size: 30px"></i>
        </span>
    </div>
    <div v-else class="d-flex align-items-center phone-p-top p-3" style="background: #AABAC6; height: 70px;">
      <img v-if="group.users.find(u => u.id != $store.state.user.id).avatar"
           :src="group.users.find(u => u.id != $store.state.user.id).avatar.path+'?token='+$store.state.token"
           style="height: 40px; border-radius: 50%">
      <Avatar v-else :size="40" :username="group.users.find(u => u.id != $store.state.user.id).name"/>
      <div class="ml-2 class d-flex flex-column">
        <div style="font-size: 18px; font-weight: 600; color: white;">
          {{ group.users.find(u => u.id != $store.state.user.id).name }}
        </div>
        <!--            <div style="font-size: 12px;font-weight: 400; color: white; line-height: 14px;">{{group.users.find(u => u.id != $store.state.user.id).email}}</div>-->
      </div>
    </div>
    <div v-if="$store.state.wsStatus?.current == 'failed' || $store.state.wsStatus?.current == 'disconnected'"
         class="w-100 mt-1 text-center text-white"
         style="height: 25px; background-color: var(--danger); border-radius: 3px">
      {{ $t("chat-conversation.disconnected") }}
    </div>
    <div v-else-if="$store.state.wsStatus?.current == 'connecting'" class="w-100 mt-1 text-center"
         style="height: 25px; background-color: var(--warning); border-radius: 3px">
      {{ $t("chat-conversation.connecting") }}
    </div>
    <div class="messages-box p-3" ref="messageFrame" id="messageframe" style="overflow-y: auto;">
      <div class="d-flex justify-content-center">
        <infinite-scroll v-if="messageFrame" @shouldLoad="loadMessages" :bottomScroll="false"
                         :shouldLoad="group.paginator.current_page < group.paginator.last_page"
                         :element="messageFrame"/>
      </div>
      <div
          v-for="(message,idx) in group.chat_group_messages" :key="message.id"
          :class="message.id === group.chat_group_messages[group.chat_group_messages.length - 1].id ? 'last' : ''"
          style="display: flex; gap: 5px; margin-bottom: 4px;"
          :style="isOwn(message) ? 'flex-direction: row-reverse;' : ''"
      >
        <div v-if="!isOwn(message) && (idx == 0 || isOwn(group.chat_group_messages[idx-1]))" class="mt-3">
          <img v-if="getSender(message).avatar" class="inlineIcon"
               :src="getSender(message).avatar.path+'?token='+$store.state.token">
          <Avatar v-else :size="40" :username="getSender(message).name"/>
          <img v-if="getSender(message).hasActiveMembership && getSender(message).badge" class="inverted-user-badge" :src="$store.state.storageURL+getSender(message)?.badge?.multimedia.path+'?token='+$store.state.token">
        </div>
        <div v-else-if="isOwn(message) && (idx == 0 || !isOwn(group.chat_group_messages[idx-1]))" class="mt-3">
          <div v-if="$store.state.user.avatar" class="avatar-wrapper">
            <img class="inlineIcon"
                 :src="$store.state.user.avatar.path+'?token='+$store.state.token">
            <img v-if="$store.state.user.hasActiveMembership && $store.state.user.badge" class="user-badge" :src="$store.state.storageURL+$store.state.user.badge.multimedia.path+'?token='+$store.state.token">
          </div>
          <div v-else class="avatar-wrapper">
            <Avatar  :size="40" :username="$store.state.user.name"/>
            <img v-if="$store.state.user.badge" class="user-badge" :src="$store.state.storageURL+$store.state.user.badge.multimedia.path+'?token='+$store.state.token">
          </div>
        </div>
        <div v-else style="height: 40px; width: 40px;"></div>
        <div class="d-flex flex-column msg">
          <div
              v-if="(!isOwn(message) && (idx == 0 || isOwn(group.chat_group_messages[idx-1]))) || (isOwn(message) && (idx == 0 || !isOwn(group.chat_group_messages[idx-1])))"
              class="mt-3"
              style="color: #444444; font-weight: 600; font-size: 13px;"
              :style="isOwn(message) ? 'align-self: flex-end' : ''"
          >
            {{ isOwn(message) ? $store.state.user.name : getSender(message).name }}
          </div>
          <div class="d-flex" style="gap: 0.5rem;">
            <a href="#" class="delete-message" @click.prevent="deleteMessage(message)" v-if="isOwn(message)"
               style="display: inline-flex; align-items: center;">
              <i class="fas fa-trash-alt" style="color: var(--red) !important;"></i>
            </a>
            <div class="p-2"
                 style="align-items: center; border-radius: 5px; background: #E9E9E9; font-weight: 500; font-size: 18px; width: fit-content;"
                 :style="isOwn(message) ? 'align-self: flex-end' : ''">
              <div class="d-flex" style="flex-direction: column">
                <div v-if="message.message"
                     v-html="$store.getters.getHTMLSafeText(message.message)"
                     :style="isOwn(message) ? 'align-self: flex-end' : ''"
                >
                </div>
                <small class="font-weight-light text-muted">{{ formatDate(message.created_at) }}</small>
                <div v-if="message.multimedia && message.multimedia.length > 0"
                     style="flex-direction: row-reverse; max-width: 99%">
                  <div class="mb-2"
                       :style="{float: isOwn(message) ? 'right' : 'left', flexDirection: isOwn(message) ? 'row-reverse' : 'row'}">
                    <div v-for="multimedia in message.multimedia.filter((mm) => mm.type == 'document')"
                         :key="multimedia.id" class="ml-2 mt-2">
                                        <span>
                                            <a :href="$store.state.storageURL+multimedia.path+'?token='+$store.state.token"
                                               target="_blank">
                                                <i class="far fa-file-alt mr-1" alt="#"
                                                   style="font-size: 20px !important; color: black"></i>
                                                <span style="color: var(--black)">{{ multimedia.name }}</span>
                                            </a>
                                        </span>
                    </div>
                    <div v-for="multimedia in message.multimedia.filter((mm) => mm.type == 'audio')"
                         :key="multimedia.id" class="mt-4">
                      <p class="ml-3 mb-0">{{ multimedia.name }}</p>
                      <audio controls>
                        <source :src="$store.state.storageURL+multimedia.path+'?token='+$store.state.token">
                      </audio>
                    </div>
                  </div>
                  <div class="row mb-3 w-100 mr-0"
                       :style="{
                                    float: isOwn(message) ? 'right' : 'left', flexDirection: isOwn(message) ? 'row-reverse' : 'row',
                                    paddingLeft: isOwn(message) && !message.message ? '' : '',
                                    paddingRight: isOwn(message) && !message.message ? '' : ''
                                }"
                  >
                    <div
                        v-for="multimedia in message.multimedia.filter((mm) => mm.type == 'video' || mm.type == 'image')"
                        :key="multimedia.id" class="col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-1 pr-0 mr-1">
                      <div v-if="multimedia.type == 'video' || multimedia.type == 'image' "
                           style="color: var(--black); cursor:pointer; background-color: grey; width: 100%; height: 100%"
                           data-fancybox="fancy"
                           :href="multimedia.type != 'video' ? $store.state.storageURL+multimedia.path+'?token='+$store.state.token : '#'+multimedia.id"
                           target="_blank"
                           :data-caption="multimedia.name"
                      >
                        <div v-if="multimedia.type == 'video'" style="width: 100%; height: 100%">
                          <video @loadedmetadata="onLoad"
                                 style="width: 100%; height: 100%; pointer-events: none; border-radius: 5px" nocontrols>
                            <source :src="$store.state.storageURL+multimedia.path+'?token='+$store.state.token">
                          </video>
                          <video :id="multimedia.id" style="display: none;" controls controlsList="nodownload">
                            <source :src="$store.state.storageURL+multimedia.path+'?token='+$store.state.token">
                          </video>
                          <i class="far fa-play-circle playbtn"></i>
                        </div>
                        <div v-if="multimedia.type == 'image'" style="width: 100%; height: 100%;">
                          <img @load="onLoad"
                               :src="$store.state.storageURL+multimedia.path+'?token='+$store.state.token"
                               style="width: 100%; height: 100%; object-fit: cover; border-radius: 5px">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <span :style="isOwn(message) ? 'align-self: flex-end' : ''" class="d-flex timestamp">{{$store.getters.parseDateTime(message.created_at)}}</span>-->
        </div>
      </div>
    </div>
    <div class="w-100 p-2 d-flex flex-column align-items-center" style="background: #AABAC640">
      <div class="d-flex align-items-center w-100">
        <div class="bot p-0" style="background: white; flex-grow: 1">
          <div class="input-group" style="border: 1px solid #B5ADA6; border-radius: 5px;">
            <textarea :disabled="!canSend" @keydown="handleChatInput" class="form-control"
                      style="height: 100% !important; margin-right: 0px !important;padding-right: 0px !important; border: none;"
                      :placeholder="$t('chat-conversation.type')" rows="1" v-model="message"></textarea>
            <div class="input-group-append">
              <button :disabled="!canSend" type="button" class="btn " data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                <i class="fas fa-paperclip"></i>
              </button>
              <div class="dropdown-menu">
                <button class="dropdown-item" type="button" @click="$refs.videoUpload.click()">
                  <i class="fas fa-file-video"></i>
                  <span class="ml-2">{{ $t('chat-conversation.vid') }}</span>
                  <input type="file" accept="video/mp4, video/webm, video/avi" id="uploadVideo" ref="videoUpload"
                         @change="createUpload('video')" style="display: none;">
                </button>
                <button class="dropdown-item" type="button" @click="$refs.imageUpload.click()">
                  <i class="fas fa-image"></i>
                  <span class="ml-2">{{ $t('chat-conversation.pic') }}</span>
                  <input type="file" accept="image/png, image/jpeg, image/jpg, image/gif" id="uploadImage"
                         ref="imageUpload" @change="createUpload('image')" style="display: none;">
                </button>
                <button class="dropdown-item" type="button" @click="$refs.audioUpload.click()">
                  <i class="fas fa-file-audio"></i>
                  <span class="ml-2">{{ $t('chat-conversation.audio') }}</span>
                  <input type="file" accept="audio/mp3, audio/mpeg, audio/wav" id="uploadAudio" ref="audioUpload"
                         @change="createUpload('audio')" style="display: none;">
                </button>
                <button class="dropdown-item" type="button" @click="$refs.documentUpload.click()">
                  <i class="fas fa-file-alt"></i>
                  <span class="ml-2">{{ $t('chat-conversation.doc') }}</span>
                  <input type="file" accept=".doc, .docx, .pdf, .xls, .xlsx, .ods, .ppt, .pptx, .txt"
                         id="uploadDocument" ref="documentUpload" @change="createUpload('document')"
                         style="display: none;">
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
              type="button"
              class="btn d-flex justify-content-center align-items-center"
              :disabled="!canSend || files.some(f => f.uuid == null) || (!message && files.length < 1)"
              @click="sendMessage"
          >
            <div class="d-flex align-items-center justify-content-center"
                 style="width: 50px; height: 50px; border-radius: 50%; background: var(--red); color: white;">
              <i class="fas fa-paper-plane" style="font-size: 22px;"/>
            </div>
          </button>
        </div>
      </div>
      <div v-for="(file, index) in files" :key="file.id" class="pl-1 mt-3" style="margin-right: auto;">
        <Upload v-if="file.shown" :file="file.file" :type="file.type" :id="file.id" @uploadFinished="finishUpload"
                @uploadCancelled="cancelUpload"/>
      </div>
    </div>
    <div v-if="group.name != null && group.creator_id == $store.state.user.id" class="modal fade" id="editGroupModal"
         tabindex="-1" role="dialog" aria-labelledby="editGroupModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editGroupModalLabel">{{ $t('chat-conversation.edit-group') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <form @submit.prevent="editGroupChat" class="w-100">
                <div class="col-12">
                  <label>{{ $t('chat-conversation.group-name') }}</label>
                  <input class="form-control w-100" type="text" v-model="groupChatNameEdited" required>
                </div>
                <div class="col-12 mt-4">
                  <button class="btn btn-primary w-100" :disabled="submitting" @click="editGroup">
                    {{ $t('chat-conversation.save') }}
                  </button>
                </div>
              </form>
              <label class="col-12 mt-5">{{ $t('chat-conversation.add-users') }}</label>
              <form class="col-md-8 mt-0 col-12 d-flex alig-items-center" @submit.prevent="startSearchAddToGroup">
                <input type="text" class="form-control" placeholder="Felhasználó neve" v-model="search">
              </form>
              <div class="col-md-4 mt-0 col-12 mb-4">
                <button :disabled="searching" type="button" class="btn btn-primary" @click="startSearchAddToGroup()">
                  {{ $t('chat-conversation.search') }}
                </button>
              </div>
              <div v-if="!searching" v-for="user in addableResults" :key="user.id" class="col-12 mt-2 mb-3">
                <div class="d-flex align-items-center">
                  <img v-if="user.avatar" :src="user.avatar.path+'?token='+$store.state.token"
                       style="border-radius: 50%; height: 50px" class="ml-0" alt="">
                  <Avatar v-else class="team-image ml-0" :username="user.name" :size="50"/>
                  <h6 class="name ml-2 mt-0 pl-0" style="font-weight: bold">
                    {{ user.name }} <br/>
                    <!--                                    <span class="email mb-1">{{user.email}}</span>-->
                  </h6>
                  <button class="btn btn-outline-primary" style="margin-left: auto;" :disabled="submitting"
                          @click="addUser(user)">Hozzáadás
                  </button>
                </div>
              </div>
              <div v-else class="col-12">
                <Loading height="30vh"/>
              </div>
            </div>
          </div>
          <div class="modal-footer">
          </div>
        </div>
      </div>
    </div>
    <div v-if="group.name != null" class="modal fade" id="userListModal" tabindex="-1" role="dialog"
         aria-labelledby="userListModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="userListModalLabel">{{ $t('chat-conversation.users') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div v-for="user in group.users" :key="user.id" class="col-12 mt-2 mb-3">
                <div class="d-flex align-items-center">
                  <img v-if="user.avatar" :src="user.avatar.path+'?token='+$store.state.token"
                       style="border-radius: 50%; height: 50px" class="ml-0" alt="">
                  <Avatar v-else class="team-image ml-0" :username="user.name" :size="50"/>
                  <h6 class="name ml-2 mt-0 pl-0" style="font-weight: bold">
                    {{ user.name }} <br/>
                    <!--                                    <span class="email mb-1">{{user.email}}</span>-->
                  </h6>
                  <button v-if="$store.state.user.id == group.creator_id" class="btn btn-outline-danger mr-1"
                          style="margin-left: auto;" :disabled="submitting" @click="removeUser(user)">
                    {{ $t('chat-conversation.remove') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
          </div>
        </div>
      </div>
    </div>
  </div>
  <Loading v-else height="70vh"/>
</template>
<script>
import {DateTime} from 'luxon';
import Avatar from '@/components/Avatar.vue'
import Loading from '@/components/Loading.vue'
import Upload from '@/components/Upload.vue'
import InfiniteScroll from './InfiniteScroll.vue';

export default {
  name: 'ChatConversation',
  components: {
    Avatar,
    Loading,
    Upload,
    InfiniteScroll
  },
  props: {
    chat: Object
  },
  mounted() {
    if (this.$refs.messageFrame) {
      this.messageFrame = this.$refs.messageFrame
      this.$refs.messageFrame.style.height = window.innerHeight - (460-80) + "px";
      this.$refs.messageFrame.scrollTop = this.$refs.messageFrame.scrollHeight;
    }
    this.groupChatNameEdited = this.group.name
  },
  beforeUpdate() {
    if (this.$refs.messageFrame) {
      const prevScrollHeight = this.$refs.messageFrame.scrollHeight
      const isLastVisible = this.isElementInViewport($('.bubbleWrapper.last').first())
      this.$nextTick(() => {
        if (this.$refs.messageFrame && prevScrollHeight < this.$refs.messageFrame.scrollHeight && isLastVisible) {
          this.onLoad()
          this.viewConversation()
        }
      })
    }
  },
  data() {
    return {
      loading: false,
      formatter: DateTime.DATETIME_MED,
      message: null,
      canSend: true,
      searching: false,
      submitting: false,
      search: null,
      groupChatNameEdited: null,
      addableResults: [],
      messageFrame: null,
      files: []
    }
  },
  computed: {
    group() {
      if (this.$store.state.user) {
        const group = this.$store.state.user.chat_groups.find(g => g.id == this.chat.id);
        //If group is null, destroy the component
        //console.log("group", group);
        return group
      }
      return null
    },
  },
  methods: {
    loadMessages(state) {
      this.axios.get('/conversation/' + this.group.id + '/messages', {params: {page: this.group.paginator.current_page + 1}})
          .then(resp => {
            this.group.paginator = resp.data.paginator
            this.group.chat_group_messages.unshift(...resp.data.messages)
            let currScrollHeight = this.messageFrame.scrollHeight
            this.$nextTick(() => {
              this.messageFrame.scrollTop = this.messageFrame.scrollHeight - currScrollHeight
            })
          })
          .finally(() => state.finishLoad())
    },
    createUpload(type) {
      if (event.target.files.item(0))
        this.files.push({file: event.target.files.item(0), type: type, shown: true, uuid: null, id: this.files.length})
    },
    finishUpload(id, multimedia) {
      this.files.find(file => file.id === id).uuid = multimedia.id
    },
    cancelUpload(id, uuid) {
      this.files.splice(this.files.indexOf(this.files.find(file => file.id === id)), 1)
    },
    isOwn(message) {
      return message.sender_id == this.$store.state.user.id
    },
    getSender(message) {
      //console.log("getSender",  this.group.historical_users);
      return this.group.historical_users.find(u => u.id == message.sender_id)
    },
    handleChatInput(e) {
      if (!e.shiftKey && e.keyCode == 13 && ((this.message != null && this.message.trim() != '') || this.files.length > 0)) {
        e.preventDefault()
        if (!this.files.some(f => f.uuid == null))
          this.sendMessage()
      }
    },
    sendMessage() {
      if (this.canSend) {
        let formData = {};
        const multimedia = this.files.filter(file => file.uuid !== null).map(file => file.uuid)
        if (this.message) {
          formData.message = this.message
        }
        if (multimedia.length > 0) {
          formData.multimedia = multimedia
        }
        this.message = null
        this.files = []
        this.axios.post('/conversation/' + this.group.id, formData)
            .then(resp => {
              this.group.chat_group_messages.push(resp.data.chatMessage)
              this.onLoad()
            })
      }
    },
    deleteMessage(message) {
      if (!message.deleting) {
        const idx = this.group.chat_group_messages.findIndex(m => m.id == message.id)
        this.group.chat_group_messages[idx].deleting = true
        this.axios.delete(`/conversation/${this.group.id}/message/${message.id}`)
            .then(resp => {
              this.group.chat_group_messages.splice(idx, 1)
            })
            .catch(e => {
              this.group.chat_group_messages[idx].deleting = false
            })
      }
    },
    addUser(user) {
      this.submitting = true
      this.axios.post(`/conversation/group/${this.group.id}/user/${user.id}`)
          .then(resp => {
            this.group.users.push(user)
            this.addableResults.splice(this.addableResults.findIndex(u => u.id == user.id), 1)
          })
          .finally(() => this.submitting = false)
    },
    removeUser(user) {
      this.submitting = true
      this.axios.delete(`/conversation/group/${this.group.id}/user/${user.id}`)
          .then(resp => {
            this.group.users.splice(this.group.users.findIndex(u => u.id == user.id), 1)
            this.search = null
            this.addableResults = []
          })
          .finally(() => this.submitting = false)
    },
    editGroup() {
      this.submitting = true
      this.axios.patch(`/conversation/group/${this.group.id}`, {name: this.groupChatNameEdited})
          .then(resp => {
            this.group.name = this.groupChatNameEdited
          })
          .finally(() => this.submitting = false)
    },
    startSearchAddToGroup() {
      this.searching = true
      let params = {
        search: this.search
      }
      this.axios.get('/conversation/group/' + this.group.id + '/addable', {params})
          .then(resp => {
            this.addableResults = resp.data
          })
          .finally(() => this.searching = false)
    },
    onLoad() {
      this.$nextTick(() => {
        if (this.$refs.messageFrame) {
          this.$refs.messageFrame.scrollTop = this.$refs.messageFrame.scrollHeight;
        }
      })
    },
    isElementInViewport(el) {
      if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
      }
      if (!el) {
        return false
      }
      var rect = el.getBoundingClientRect();

      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
          rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
      );
    },
    viewConversation() {
      const chat = this.group
      this.axios.post('/conversation/' + chat.id + '/read-messages')
          .then(() => {
            chat.chat_group_messages.forEach(msg => {
              const idx = msg.chat_group_message_receivers.findIndex(r => r.receiver_id == this.$store.state.user.id && r.read_at == null)
              if (idx !== -1)
                msg.chat_group_message_receivers[idx].read_at = new Date()
            })
          })
    },
    formatDate(date) {
      //console.log("daata", date);
      let d = new DateTime.fromISO(date);
      return d.toRelative();
      //return "jii";
    }
  },
}
</script>
<style scoped>
.inlineIcon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.user-badge{
  top: -50px;
  left: 19px;
  width: 24px;
  position: relative;
}
.inverted-user-badge{
  top: -25px;
  left: -5px;
  width: 24px;
  position: relative;
}
.playbtn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 70px;
  color: white
}

.playbtn:hover {
  color: rgb(196, 196, 196);
}

.message::v-deep(a) {
  text-decoration: underline;
}

.bubbleWrapper span.own,
.bubbleWrapper span.other {
  display: none !important;
  padding: 0px !important;
  margin-bottom: auto !important;
  margin-top: auto !important;
}

.own-name,
.other-name {
  font-size: 15px !important;
}

.ownBubble,
.otherBubble {
  margin-bottom: 0px !important;
  word-break: break-word;
}

.bubbleWrapper:hover span.own,
.bubbleWrapper:hover span.other,
.bubbleWrapper:hover .other-name,
.bubbleWrapper:hover .own-name {
  display: initial !important;
}

.bubbleWrapper span a {
  color: var(--cgray) !important;
}

@media (max-width: 1199px) {
  .phone-p-top {
    margin-top: 20px;
  }
}

#chat .input-group.text-send-area, #chat .input-group.text-send-area * {
  background-color: var(--cstmgray) !important;
  border-color: var(--cstmgray) !important;
  outline: none !important;
  box-shadow: none !important;
}

#chat .input-group.text-send-area textarea {
  margin-right: 0px !important;
  padding-right: 0px !important;
  overflow-y: auto !important;
}

#chat .input-group.text-send-area .btn {
  margin-left: 0px !important;
  height: auto !important;
  box-shadow: none !important;
  outline: none !important;
  filter: none !important;
}

.msg:hover::v-deep(.timestamp) {
  display: block !important;
}

.msg:hover::v-deep(.delete-message) {
  visibility: visible !important;
}

.delete-message {
  visibility: hidden !important;
}

.timestamp {
  display: none !important;
}

</style>
