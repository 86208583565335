<template>
    <div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a @click.prevent="$router.push({name: 'Home'})" href="#">{{ $t('settings.homepage') }}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('footer.faq') }}</li>
            </ol>
        </nav>
        <h3 class="mb-5 mt-2">{{$t('footer.faq')}}</h3>
        <div v-for="(question, idx) in questions" :key="idx" class="mt-4">
            <h3 :key="idx" class="question" data-toggle="collapse" :href="'#collapse-'+idx" role="button">{{question.question}}&nbsp&nbsp</h3>
            <div class="collapse" :id="'collapse-'+idx">{{question.answer}}</div>
        </div>    
    </div>
</template>
<script>
export default {
    name: 'Faq',
    computed: {
        questions() {
            return [
                {
                    question: this.$t('faq.q1'),
                    answer: this.$t('faq.a1')
                },
                {
                    question: this.$t('faq.q2'),
                    answer: this.$t('faq.a2')
                },
                {
                    question: this.$t('faq.q3'),
                    answer: this.$t('faq.a3')
                },
                {
                    question: this.$t('faq.q4'),
                    answer: this.$t('faq.a4')
                },
                {
                    question: this.$t('faq.q5'),
                    answer: this.$t('faq.a5')
                },
                {
                    question: this.$t('faq.q6'),
                    answer: this.$t('faq.a6')
                },
                {
                    question: this.$t('faq.q7'),
                    answer: this.$t('faq.a7')
                }
            ]
        }
    }
}
</script>
<style scoped>
 .question {
    color: var(--lblue);
    position: relative;
    width: fit-content;
    padding-right: 5px;
    cursor: pointer;
 }

.question[aria-expanded="true"]::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    content: "\f106";
}

.question::after {
  content: "\f107";
  color: var(--blue);
  font-size: 25px;
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  vertical-align: middle;
}
</style>