<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Glossary'})">{{ $t('create-glossary-article.knowledge-base') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('create-glossary-article.new-entry') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->
        <div id="content-2">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <section id="media-buttons">
                            <div class="row">
                                <div class="col-lg-6 mb-3">
                                    <div class="flex-wrap d-flex" @click="$refs.imageUpload.click()">
                                        <input type="file" accept="image/png, image/jpeg, image/jpg, image/gif" id="uploadImage" ref="imageUpload" @change="createUpload('image')" style="display: none;">
                                        <div class="d-flex align-items-center pointer mr-auto">
                                            <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                <img src="assets/img/icons/white-camera.svg" class="img-fluid ml-1" alt="">
                                            </div>
                                            <div class="d-flex flex-column ml-2">
                                                <h6 class="text-16 text-dark-gray">{{$t('course-materials.add-picture')}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-3">
                                    <div class="flex-wrap d-flex" @click="$refs.videoUpload.click()">
                                        <input type="file" accept="video/mp4, video/webm, video/avi" id="uploadVideo" ref="videoUpload" @change="createUpload('video')" style="display: none;">
                                        <div class="d-flex align-items-center pointer mr-auto">
                                            <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                <img src="assets/img/icons/videocam.svg" class="img-fluid ml-1" alt="">
                                            </div>
                                            <div class="d-flex flex-column ml-2">
                                                <h6 class="text-16 text-dark-gray">{{$t('course-materials.add-video')}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-3">
                                    <div class="flex-wrap d-flex" @click="$refs.audioUpload.click()">
                                        <input type="file" accept="audio/mp3, audio/mpeg, audio/wav" id="uploadAudio" ref="audioUpload" @change="createUpload('audio')" style="display: none;">
                                        <div class="d-flex align-items-center pointer mr-auto">
                                            <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                <img src="assets/img/icons/microphone.svg" class="img-fluid" alt="">
                                            </div>
                                            <div class="d-flex flex-column ml-2">
                                                <h6 class="text-16 text-dark-gray">{{$t('course-materials.add-voice')}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-3">
                                    <div class="flex-wrap d-flex" @click="$refs.documentUpload.click()">
                                        <input type="file" accept=".doc, .docx, .pdf, .xls, .xlsx, .ods, .ppt, .pptx, .txt" id="uploadDocument" ref="documentUpload" @change="createUpload('document')" style="display: none;">
                                        <div class="d-flex align-items-center pointer mr-auto">
                                            <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                <img src="assets/img/icons/file.svg" class="img-fluid ml-1" alt="">
                                            </div>
                                            <div class="d-flex flex-column ml-2">
                                                <h6 class="text-16 text-dark-gray">{{$t('course-materials.add-document')}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id="media-input" class="custom-input">
                            <form @submit.prevent="submit">
                                <div class="form-group mb-4">
                                    <label for="" class="text-18 text-dark-warm-gray">{{ $t('create-glossary-article.title') }}</label>
                                    <input type="text" class="form-control" placeholder="" v-model="data.title">
                                </div>
                                <div class="form-group mb-4">
                                    <label for="exampleFormControlTextarea1" class="text-18 text-dark-warm-gray">{{ $t('create-glossary-article.content') }}</label>
                                    <textarea ref="summernote" class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="data.content"></textarea>
                                </div>
                                <div class="form-group">
                                    <label for="" class="text-18 text-dark-warm-gray">{{ $t('create-glossary-article.labels') }}</label>
                                    <input class="form-control"   v-model="data.labels" />  
                                </div>
                                <div class="d-flex">
                                    <span class="font-18" style="font-size: 18px" v-if="$store.state.user.role !== 'Admin'">
                                        <i class="fas fa-info-circle mr-2" style="color: var(--black)"></i>
                                        <span class="mb-3" style="font-size: 0.9em !important;">{{ $t('create-glossary-article.admin-msg') }}</span>
                                    </span>
                                </div>
                                <button :disabled="loading" type="submit" class="btn btn-theme float-right mt-3 mb-3">{{ $t('create-glossary-article.create') }}</button>
                            </form>
                        </section>
                    </div>
                    <div class="col-lg-5">
                        <section id="buttons">
                            <div class="row">
                                <div class="col-12">
                                    <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('create-glossary-article.uploads') }}</h2>
                                </div>
                                <!-- <div class="col-12">
                                    <h6 class="text-13 text-dark-gray semi-bold">No data placeholder</h6>
                                </div> -->
                                <div class="col-12">
                                    <div class="row">
                                        <div v-for="(file, index) in files" :key="file.id" class="col-12 mb-3">
                                            <Upload v-if="file.shown" :file="file.file" :type="file.type" :id="file.id" @uploadFinished="finishUpload" @uploadCancelled="cancelUpload"/>
                                        </div>
                                        <div v-if="files.length == 0" class="mb-2 col-12">
                                            <h6 class="text-13 text-dark-gray semi-bold">{{ $t('fast-share.no-uploads') }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Upload from '@/components/Upload'
export default {
    name: 'CreateGlossaryArticle',
    components: {
        Upload
    },
    mounted() {
        $(this.$refs.summernote).summernote({
            height: 300,
            toolbar: [
                ['style', ['style.h1', 'style.h2', 'style.h3','style.h4', 'style.h5', 'style.h6']],
                ['style', ['bold', 'italic', 'underline',]],
                ['insert', ['link']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['view', ['fullscreen', 'help']],
            ],
            callbacks: {
                onChange: (contents, $editable) => {
                    this.data.content = contents
                }
            }
        })
    },
    data() {
        return {
            data: {
                title: null,
                content: null,
                labels: null
            },
            loading: false,
            files: []
        }
    },
    methods: {
        createUpload(type) {
            if(event.target.files.item(0))
                this.files.push({file: event.target.files.item(0), type: type, shown: true, uuid: null, id: this.files.length})
        },
        finishUpload(id, multimedia) {
            this.files.find(file => file.id === id).uuid = multimedia.id
        },
        cancelUpload(id, uuid) {
            this.files.splice(this.files.indexOf(this.files.find(file => file.id === id)), 1)
        },
        submit() {
            this.loading = true
            let formData = this.data;
            const multimedia = this.files.filter(file => file.uuid !== null).map(file => file.uuid)
            if(multimedia.length > 0)
                formData.multimedia = multimedia

            this.axios.post('/glossary-articles', formData)
                .then(resp => {
                    this.$router.push({name: 'Glossary'})
                })
                .catch(e => console.log(e.response))
                .finally(() => this.loading = false)
        }
    }
}
</script>
<style>
    #media-buttons,
    #buttons,
    #media-input {
        margin-top: 30px;
    }
</style>
