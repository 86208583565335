<template>
    <!-- Modal New Answer-->
    <div v-if="question.questionType !== 'Free text' || question.test_question_answers.length < 1" class="modal fade" :id="'newAnswer'+question.id+'Modal'" tabindex="-1" role="dialog" :aria-labelledby="'newAnswer'+question.id+'ModalLabel'" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header question-modal-header" style="background-color: var(--red) !important;">
                <h5 class="modal-title text-white" :id="'newAnswer'+question.id+'ModalLabel'">{{ $t('test-question-assembler.add-new-answer') }}</h5>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <form @submit.prevent="createQuestionAnswer(question)">
                    <div class="modal-body nono">
                        <div class="row">
                            <div class="col-12" v-if="question.question_type != 'Subgrouped'">
                                <div class="col-12">
                                    {{$t('test-question-assembler.attach-multimedia')}}
                                </div>
                                <generic-file-uploader 
                                    v-if="!question.newAnswer.multimedia_id"
                                    @uploadFinished="attachMultimediaToNewAnswer(question, $event)"
                                    containerClasses="col-12 row"
                                    videoContainerClasses="col-6 mb-1 p-0 pr-1"
                                    imageContainerClasses="col-6 mb-1 p-0 pr-1"
                                >
                                    <template v-slot:video-btn>
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="d-flex" style="flex-direction: column; align-items: center; justify-content: center;">
                                                    <i class="fas fa-file-video font-28 mb-1" />
                                                    <span>{{$t('test-question-assembler.video')}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-slot:image-btn>
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="d-flex" style="flex-direction: column; align-items: center; justify-content: center;">
                                                    <i class="fas fa-file-image font-28 mb-1" />
                                                    <span>{{$t('test-question-assembler.image')}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </generic-file-uploader>
                                <div class="col-12 mb-3" v-else>
                                    <div>
                                    <div v-if="question.newAnswer.multimedia_type == 'video' || question.newAnswer.multimedia_type == 'image' " style="color: var(--black); cursor:pointer; width: 100%; height: 100%" 
                                        :data-fancybox="`question.newAnswer`" 
                                        :href="question.newAnswer.multimedia_type != 'video' ? $store.getters.getStorageURL(`/download/${question.newAnswer.multimedia_type}/${question.newAnswer.multimedia_id}`) : '#question.newAnswer-'+question.newAnswer.multimedia_id" target="_blank"
                                        data-caption="Multimedia"
                                    >
                                        <div v-if="question.newAnswer.multimedia_type == 'video'" style="max-width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: var(--lgray); border-radius: 5px; position: relative;">
                                            <video style="max-width: 100%; max-height: 100%; object-fit: cover; border-radius: 5px; pointer-events: none;" nocontrols>
                                                <source :src="$store.getters.getStorageURL(`/download/${question.newAnswer.multimedia_type}/${question.newAnswer.multimedia_id}`)">
                                            </video>
                                            <video :id="'question.newAnswer-'+question.newAnswer.multimedia_id" style="display: none;" controls controlsList="nodownload">
                                                <source :src="$store.getters.getStorageURL(`/download/${question.newAnswer.multimedia_type}/${question.newAnswer.multimedia_id}`)">
                                            </video>
                                            <i class="far fa-play-circle playbtn"></i>
                                        </div>
                                        <div v-if="question.newAnswer.multimedia_type == 'image'" style="max-width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: var(--lgray); border-radius: 5px;">
                                            <img :src="$store.getters.getStorageURL(`/download/${question.newAnswer.multimedia_type}/${question.newAnswer.multimedia_id}`)" style="max-width: 100%; max-height: 100%; object-fit: cover; border-radius: 5px">
                                        </div>
                                    </div>
                                    <button class="w-100 btn btn-danger mt-2" @click.prevent="deleteNewAnswerMultimedia(question)">{{$t('test-question-assembler.delete-multimedia')}}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="exampleFormControlTextarea1">{{ $t('test-question-assembler.answer') }}</label>
                                    <textarea v-model="question.newAnswer.answer" class="form-control"  rows="3" required></textarea>
                                </div>
                            </div>
                            <div class="col-12" v-if="question.question_type == 'Multiple choice' || (question.question_type !== 'Free text' && question.question_type !== 'Subgrouped' && question.test_question_answers.filter((qa) => qa.correct == true).length < 1)">
                                <label class="container2">{{ $t('test-question-assembler.correct-answer') }}
                                    <input v-model="question.newAnswer.correct" type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-12" v-else-if="question.question_type == 'Single choice'">
                                <i class="fas fa-info-circle mr-2 font-15" style="color: var(--black); font-size: 15px;"></i>
                                <span>{{ $t('test-question-assembler.choose1') }} <b style="color: red">{{ $t('test-question-assembler.wrong') }}</b> {{ $t('test-question-assembler.choose2') }}</span>
                            </div>
                            <div class="col-12" v-else-if="question.question_type == 'Free text'">
                                <i class="fas fa-info-circle mr-2 font-15" style="color: var(--black); font-size: 15px;"></i>
                                <span>{{ $t('test-question-assembler.write1') }} <b style="color: green">{{ $t('test-question-assembler.good') }}</b> {{ $t('test-question-assembler.choose2') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" :disabled="question.newAnswer.loading" class="btn btn-secondary">{{ $t('test-question-assembler.add') }}</button>
                        <button type="button" class="btn btn-primary" data-dismiss="modal">{{ $t('test-question-assembler.cancel') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import GenericFileUploader from '../GenericFileUploader.vue'
export default {
    name: 'NewAnswerModal',
    components: {
        GenericFileUploader
    },
    props: {
        question: Object
    },
    methods: {
        deleteNewAnswerMultimedia(question) {
            const url = this.$store.getters.getStorageURL(`/upload/${question.newAnswer.multimedia_type}/${question.newAnswer.multimedia_id}/abort`)
            question.newAnswer.multimedia_id = null
            question.newAnswer.multimedia_type = null
            this.axios.post(url)
                .then(resp => {})
                .catch(e => console.log(e.response))
        },
        attachMultimediaToNewAnswer(question, file) {
            question.newAnswer.multimedia_id = file.uuid
            question.newAnswer.multimedia_type = file.type
        },
        createQuestionAnswer(question) {
            question.newAnswer.loading = true
            this.axios.post('/questions/'+question.id+'/answers', this.$store.getters.removeEmptyOrNull(question.newAnswer))
                .then(resp => {
                    resp.data.answer.edit = JSON.parse(JSON.stringify(resp.data.answer))
                    question.test_question_answers.push(resp.data.answer)
                    question.newAnswer = {answer: null, correct: question.questionType == 'Free text'}
                    $('#newAnswer'+question.id+'Modal').modal('hide')
                })
                .finally(() => question.newAnswer.loading = false)
        },

    }
}
</script>
<style scoped>
.close {
    color: white !important;
    opacity: 1 !important;
    font-weight: 500;
}
.card {
    box-shadow: 0px 0px 0px grey;
    transition:  box-shadow .1s ease-out;
    cursor: pointer;
}
.card:hover {
    text-shadow:0px 0px 1px rgb(207, 206, 206) !important;
    box-shadow: 1px 1px 5px rgb(207, 206, 206) !important;
    transition:  box-shadow .1s ease-in;
}
.playbtn {
    position: absolute; 
    left: 50%; top: 50%; 
    transform: translate(-50%, -50%); 
    font-size: 70px; 
    color:white
}
.remove {
    position: absolute;
    top: 0;
    right: 0;
    color: red;
    font-size: 25px;
    transform: translate(-100%, 0%); 
}
</style>