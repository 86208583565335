<template>
  <div v-if="user">
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray" v-if="$store.state.user.role == 'Admin'">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-courses.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'ManageUsers'})">{{ $t('overview.manage-users') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ user ? user.name : $t('overview.look-in') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <!-- BREADCRUMB -->
    <div class="breadcrumb bg-dark-gray" v-if="$store.state.user.role == 'Master'">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-courses.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'MyTeam'})">{{ $t('overview.my-team') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ user ? user.name : $t('overview.look-in') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- //BREADCRUMB -->
    <div id="content-2">
      <div class="container d-none">

        <h2 class="mb-3"> {{ $t('overview.for-fixing2') }} </h2>
        <section id="quizff-list-table" class="overview-table-responsive">
          <div class="overview-table-responsive">
            <table class="table table-striped">
              <tbody>
              <tr>
                <th :class="'pointer'+' '+(currentSort==='name'?'sorted':'')" @click="sort('name')"
                    style="width: 25% !important">{{ $t('overview.tname') }}
                </th>
                <th :class="'pointer'+' '+(currentSort==='started_at'?'sorted':'')" @click="sort('started_at')"
                    style="width: 25% !important">{{ $t('overview.tbegin') }}
                </th>
                <th :class="'pointer'+' '+(currentSort==='finished_at'?'sorted':'')" @click="sort('finished_at')"
                    style="width: 50% !important">{{ $t('overview.tend') }}
                </th>
              </tr>
              <tr v-for="testAttempt in sortedTestAttempts.filter(ta => {return new Date(Date.parse(ta.finished_at)) < new Date() && !ta.corrected})"
                  :key="testAttempt.id">
                <td @click.prevent="$router.push({name: 'CorrectTestAttempt', params: {testAttempt: testAttempt.id}})"
                    style="width: 25% !important">
                                <span class="left-play">
                                    <a href="#">
                                        <img src="assets/img/icons/blue-play.png" alt="">
                                    </a>
                                </span>
                  <a href="#">{{ testAttempt.name }}</a>
                </td>
                <td style="width: 25% !important">{{ $store.getters.parseDateTime(testAttempt.started_at) }}</td>
                <td style="width: 50% !important"><span
                    class="left">{{ $store.getters.parseDateTime(testAttempt.finished_at) }}</span>
                </td>
              </tr>
              <tr v-if="user.test_attempts.length == 0">
                <td colspan="3" class="text-center">
                  {{ $t('no-data.quiz') }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </section>
        <h2 class="mb-3 mt-5"><i class="fas fa-book mr-2" style="color: var(--black);"></i>
          {{ $t('my-courses.owned-courses') }} </h2>
        <section id="owned-list-table" class="overview-table-responsive">
          <div class="overview-table-responsive">
            <table class="table table-striped">
              <tbody>
              <tr>
                <th :class="'pointer'+' '+(currentSort1==='title'?'sorted':'')" @click="sort1('title')"
                    style="width: 25% !important">{{ $t('overview.cname') }}
                </th>
                <th :class="'pointer'+' '+(currentSort1==='started_at'?'sorted':'')" @click="sort1('started_at')"
                    style="width: 25% !important">{{ $t('overview.tbegin') }}
                </th>
                <th :class="'pointer'+' '+(currentSort1==='progress'?'sorted':'')" @click="sort1('progress')"
                    style="width: 25% !important">{{ $t('overview.state') }}
                </th>
                <th v-if="$store.state.user.role === 'Admin'" style="width: 25% !important">{{ $t('common.delete') }}</th>
              </tr>
              <tr v-for="course in sortedOwnedCourses" :key="course.id" style="width: 25% !important">
                <td @click.prevent="$router.push({name: 'Course', params: {id: course.id}})">
                                <span class="left-play">
                                    <a href="#">
                                        <img src="assets/img/icons/blue-play.png" alt="">
                                    </a>
                                </span>
                  <a href="#">{{ course.title }}</a>
                </td>
                <td style="width: 25% !important">{{ course.started_at }}</td>
                <td style="width: 25% !important">{{ course.progress }}%</td>
                <td v-if="$store.state.user.role === 'Admin'">
                  <button class="btn btn-danger" data-toggle="modal" :data-target="`#delete-user-course-modal-${course.id}`"
                          :disabled="course.progress === 100">{{ $t('common.delete') }}
                  </button>
                </td>

                <div v-if="$store.state.user.role === 'Admin'" class="modal fade"
                     :id="`delete-user-course-modal-${course.id}`" tabindex="-1" aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title">{{ $t('overview.delete-user-course') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body"
                           v-html="$t('overview.delete-user-course-text', [$store.getters.getHTMLSafeText(course.title)])">
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('common.cancel') }}
                        </button>
                        <button type="button" class="btn btn-danger" :disabled="deleting"
                                @click.prevent="deleteUserCourse(course)">{{ $t('common.delete') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </tr>
              <tr v-if="user.courses == 0">
                <td colspan="4" class="text-center">
                  {{ $t('no-data.courses') }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </section>
        <h2 class="mb-3 mt-5"> {{ $t('overview.ongoing-courses') }} </h2>
        <section id="ongiong-list-table" class="overview-table-responsive">
          <div class="overview-table-responsive">
            <table class="table table-striped">
              <tbody>
              <tr>
                <th :class="'pointer'+' '+(currentSort2==='title'?'sorted':'')" @click="sort2('title')"
                    style="width: 25% !important">{{ $t('overview.cname') }}
                </th>
                <th :class="'pointer'+' '+(currentSort2==='started_at'?'sorted':'')" @click="sort2('started_at')"
                    style="width: 25% !important">{{ $t('overview.tbegin') }}
                </th>
                <th :class="'pointer'+' '+(currentSort2==='progress'?'sorted':'')" @click="sort2('progress')"
                    style="width: 50% !important">{{ $t('overview.state') }}
                </th>
              </tr>
              <tr v-for="course in sortedCoursesInProgress" :key="course.id" style="width: 25% !important">
                <td @click.prevent="$router.push({name: 'Course', params: {id: course.id}})">
                                <span class="left-play">
                                    <a href="#">
                                        <img src="assets/img/icons/blue-play.png" alt="">
                                    </a>
                                </span>
                  <a href="#">{{ course.title }}</a>
                </td>
                <td style="width: 25% !important">{{ course.started_at }}</td>
                <td style="width: 50% !important">{{ course.progress }}%</td>

              </tr>
              <tr v-if="sortedCoursesInProgress.length == 0">
                <td colspan="4" class="text-center">
                  {{ $t('no-data.courses') }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <section id="profile-overview">
              <!-- ACTIONS -->
              <div class="flex-wrap d-flex">
                <div class="d-flex align-items-center mr-auto">
                  <div class="image mr-2" style="width: 50px">
                    <img v-if="user.avatar" :src="user.avatar.path+'?token='+$store.state.token"
                         width="40" style="float: right;border-radius: 50%;" class="img-fluid avatar-rounded"
                         :alt="user.name">
                    <Avatar v-else :username="user.name" :rounded="true" style="float: right;" class="avatar"/>
                  </div>
                  <div class="d-flex flex-column">
                    <h2 class="text-18 text-light-black semi-bold">{{ user.name }}</h2>
                    <h3 class="text-16 text-light-black medium">{{ user.email }}</h3>
                    <h6 class="text-12 text-light-black medium">{{ user.country_of_interest?.name }}</h6>
                  </div>
                </div>
                <div class="d-flex mr-3 ms-auto"
                     @click="$router.push({name: 'ActivityHistory', params: {user: JSON.stringify(user), id: user.id}})">
                  <button class="btn btn-theme mb-2"><img src="assets/img/icons/arrows.svg" alt="">
                    {{ $t('overview.watch') }}
                  </button>
                </div>
                <div class="d-flex mr-3">
                  <button class="btn btn-theme mb-2" data-toggle="modal" data-target="#UserSettings"><img
                      src="assets/img/icons/cog.svg" alt=""> {{ $t("view-course.details") }}
                  </button>
                </div>
                <div class="d-flex">
                  <button class="btn btn-theme mb-2" data-toggle="modal" data-target="#LoginAs">{{ $t("overview.login-as") }}</button>
                </div>

                <!-- Confirm login as modal -->
                <ConfirmationModal
                    id="LoginAs"
                    title="Are you sure?"
                    text="You are about to login as this user. This will log you out and log you in as the selected user."
                    @hasEUConsent="loginAs"
                />

                <!-- USER SETTINGS MODAL -->
                <div class="modal fade" id="UserSettings" tabindex="-1" aria-labelledby="UserSettingsLabel"
                     aria-hidden="true">
                  <div class="modal-dialog modal-dialog-scrollable">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="UserSettingsLabel">User Settings</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <form action="">
                          <div class="col-12 mb-3" v-if="$store.state.user.role == 'Admin'">
                            <label for="masters">{{ $t('overview.master') }}</label>
                            <!--                            <select id="masters" class="form-control" v-model="user.master_id">
                                                          <option :value="null">{{ $t('overview.no-master') }}</option>
                                                          <option v-for="master in masters.filter(m => m.id != user.id)" :key="master.id"
                                                                  :value="master.id">{{ master.name }} ({{ master.email }})
                                                          </option>
                                                        </select>-->
                            <Select2 v-model="user.master_id" :options="masters" :settings="{width:'100%'}"/>
                            <div class="d-flex">
                              <button :disabled="submitting" @click="changeTeam()" type="button"
                                      class="btn btn-theme-outline mt-4 ms-auto">{{ $t('overview.change') }}
                              </button>
                            </div>
                          </div>
                          <div class="col-12 mb-3" v-if="$store.state.user.role == 'Master'">
                            <label for="master-name">{{ $t('overview.master') }}</label>
                            <span id="master-name" class="ml-2 text-14 semi-bold">{{ user.master.name }}</span>
                          </div>
                          <div class="col-12 mb-3" v-if="$store.state.user.role == 'Admin'">
                            <label for="role-admin">{{ $t('overview.authority') }}</label>
                            <select id="role-admin" class="form-control" v-model="user.role">
                              <option v-for="role in roles" :key="role.role">{{ role.role }}</option>
                            </select>
                            <div class="d-flex">
                              <button :disabled="submitting" @click="changeRole()" type="button"
                                      class="btn btn-theme-outline mt-4 ms-auto">{{ $t('overview.change') }}
                              </button>
                            </div>
                          </div>
                          <div class="col-12 mb-3" v-if="$store.state.user.role == 'Admin'">
                            <label for="country-of-interest">{{ $t('overview.country-of-interest') }}</label>
                            <select2 v-model="user.country_of_interest.id" :options="countries"
                                     :settings="{width:'100%'}"/>
                            <div class="d-flex">
                              <button :disabled="submitting" @click="changeCountryOfInterest()" type="button"
                                      class="btn btn-theme-outline mt-4 ms-auto">{{ $t('overview.change') }}
                              </button>
                            </div>
                          </div>                   
                          <div class="col-12 mb-3" v-if="$store.state.user.role=='Admin'">
                            <label for="resend-email-verification" class="label">{{$t('overview.resend-email-verification')}}</label>
                            <div class="d-flex">
                              <button :disabled="submitting" @click="resendEmailVerification" id="resend-email-verification" type="button" class="btn btn-theme-outline mt-2 ms-auto form-group">{{$t('overview.send')}}</button>
                            </div>
                          </div>
                          <div class="col-12 mb-3" v-if="$store.state.user.role=='Admin'">
                            <label for="manual-email-verification" class="label">{{$t('overview.manual-email-verification')}}</label>
                            <div class="d-flex">
                              <button :disabled="submitting" @click="manualEmailVerification" id="manual-email-verification" type="button" class="btn btn-theme-outline mt-2 ms-auto form-group">{{$t('overview.send')}}</button>
                            </div>
                          </div>
                          <div class="col-12 mb-3" v-if="$store.state.user.role == 'Master'">
                            <label for="role-master">{{ $t('overview.authority') }}</label>
                            <span id="role-master" class="ml-2 text-14 semi-bold">{{ user.role }}</span>
                          </div>
                          <div class="col-12 mb-3">
                            <label for="deleted">{{ $t('delete-modal.deleted') }}</label>
                            <h5 class="text-light-black text-14" :class="user.deleted ? 'text-danger' : 'text-success'">
                              {{ user.deleted ? $t('common.yes') : $t('common.no') }}</h5>
                            <div class="d-flex">
                              <button id="deleted" v-if="!user.deleted && $store.state.user.role == 'Admin'"
                                      type="button" class="btn btn-theme-outline mt-4 ms-auto" data-toggle="modal"
                                      data-target="#delete-profile-modal">{{ $t('delete-modal.title-admin') }}
                              </button>
                            </div>
                            <delete-profile-modal @deleted="user.deleted = true" :user="user"/>
                          </div>
                          <div class="mb-3">
                            <admin-user-qualification-list :userId="user.id"/>
                          </div>
                          <div class="col-12 mb-3" v-if="$store.state.user.role=='Admin'">
                              <label for="resend-email-verification" class="label">SAP CODE</label>
                              <div class="d-flex" v-if="SAP_CODE">
                                <input type="text" disabled v-model="SAP_CODE">
                                <!-- <button :disabled="submitting" @click="changeSAP" id="change_sap" type="button" class="btn btn-theme-outline mt-2 ms-auto form-group">{{$t('overview.change')}}</button> -->
                              </div>
                               <p v-else>{{$t('overview.user_dont_have_billing_information')}}</p>
                            </div>
                           
                        </form>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-theme" data-dismiss="modal">{{ $t('common.cancel') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- //USER SETTINGS MODAL -->
              </div>
              <!-- RESPONSIVE TABLE -->
              <div class="row">
                <div class="col-12 mt-5 mb-3">
                  <h2 class="text-18 text-dark-gray semi-bold">{{ $t('overview.for-fixing2') }}</h2>
                </div>
                <div class="col-12 mb-4">
                  <div class="new-table filter-shadow">
                    <table class="table borderless-table">
                      <thead class="bg-light-gray-primary">
                      <tr>
                        <th scope="col" :class="'pointer'+' '+(currentSort3==='name'?'sorted':'')"
                            @click="sort3('name')">{{ $t('overview.tname') }}
                        </th>
                        <th scope="col" :class="'pointer'+' '+(currentSort3==='started_at'?'sorted':'')"
                            @click="sort3('started_at')">{{ $t('overview.tbegin') }}
                        </th>
                        <th scope="col" :class="'pointer'+' '+(currentSort3==='finished_at'?'sorted':'')"
                            @click="sort3('finished_at')">{{ $t('overview.tend') }}
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="testAttempt in sortedCorrectedTestAttempts"
                          :key="testAttempt.id">
                        <td @click.prevent="$router.push({name: 'CorrectTestAttempt', params: {testAttempt: testAttempt.id}})">
                          {{ testAttempt.name }}
                        </td>
                        <td>{{ testAttempt.started_at }}</td>
                        <td>{{ testAttempt.finished_at }}</td>
                      </tr>
                      <tr v-if="sortedCorrectedTestAttempts.length == 0">
                        <td colspan="3" class="text-center text-light-black semi-bold pt-4 pb-4">{{
                            $t('no-data.quiz')
                          }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- //RESPONSIVE TABLE -->
              <!-- COURSES IN PROGRESS -->
              <section id="training-progress" class="mb-3 mt-2">
                <div class="row">
                  <div class="col-12">
                    <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('overview.ongoing-courses') }}</h2>
                  </div>

                  <!-- TRAINING CARD -->
                  <div class="col-12 col-sm-6 col-lg-3" v-for="course in user.coursesInProgress" :key="course.id"
                       @click="$router.push({name: 'Course', params: {id: course.id}})"
                  >
                    <course-card :course="course" :showBadges="true"/>
                  </div>

                  <!-- //TRAINING CARD -->
                  <div class="col-12 mb-3" v-if="user.coursesInProgress.length == 0">
                    <h6 class="text-13 text-dark-gray semi-bold">{{ $t('no-data.courses') }}</h6>
                  </div>
                </div>
              </section>
              <!-- //COURSES IN PROGRESS -->
              <!-- COMPLETED COURSES -->
              <section id="training-completed">
                <div class="row mb-5">
                  <div class="col-12">
                    <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('my-courses.ended-courses') }}</h2>
                  </div>
                  <!-- TRAINING CARD -->
                  <div class="col-12 col-sm-6 col-lg-3" v-for="course in user.coursesFinished" :key="course.id" @click="$router.push({name: 'Course', params: {id: course.id}})">
                    <course-card :course="course" :showBadges="true"/>
                  </div>
                  <!-- //TRAINING CARD -->
                  <div class="col-12" v-if="user.coursesFinished.length == 0">
                    <h6 class="text-13 text-dark-gray semi-bold">{{ $t('no-data.courses') }}</h6>
                  </div>
                </div>
              </section>
              <!-- //COMPLETED COURSES -->
              <!-- ORDER HISTORY TABLE -->
              <div class="row">
                <div class="col-12 mt-5 mb-3">
                  <h2 class="text-18 text-dark-gray semi-bold">{{ $t('overview.order-history') }}</h2>
                </div>
                <div class="col-12 mb-4">
                  <div class="new-table filter-shadow">
                    <table class="table borderless-table">
                      <thead class="bg-light-gray-primary">
                      <tr>
                        <th scope="col" :class="'pointer'+' '+(currentSort4==='title'?'sorted':'')"
                            @click="sort4('title')">{{ $t('overview.order-history-items') }}
                        </th>
                        <th scope="col" :class="'pointer'+' '+(currentSort4==='status'?'sorted':'')"
                            @click="sort4('status')">{{ $t('overview.order-status') }}
                        </th>
                        <th scope="col" :class="'pointer'+' '+(currentSort4==='historyTotal'?'sorted':'')"
                            @click="sort4('historyTotal')">{{ $t('overview.order-history-sum') }}
                        </th>
                        <th scope="col" :class="'pointer'+' '+(currentSort4==='currency'?'sorted':'')"
                            @click="sort4('currency')">{{ $t('overview.order-history-currency') }}
                        </th>
                        <th scope="col" :class="'pointer'+' '+(currentSort4==='exchange_rate'?'sorted':'')"
                            @click="sort4('exchange_rate')">{{ $t('overview.order-history-rate') }}
                        </th>
                        <th scope="col" :class="'pointer'+' '+(currentSort4==='coupon'?'sorted':'')"
                            @click="sort4('coupon')">{{ $t('overview.order-history-coupon') }}
                        </th>
                        <th scope="col" :class="'pointer'+' '+(currentSort4==='updated_at'?'sorted':'')"
                            @click="sort4('updated_at')">{{ $t('overview.order-updated_at') }}
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="order in sortedOrderHistory" :key="order.id">
                        <td>{{ order.title }}</td>
                        <td>{{ order.status }}</td>
                        <td>{{ order.historyTotal }}</td>
                        <td>{{ order.currency }}</td>
                        <td>{{ order.exchange_rate }}</td>
                        <td>{{ order.coupon }}</td>
                        <td>{{ order.updated_at }}</td>
                      </tr>
                      <tr v-if="sortedOrderHistory == 0">
                        <td colspan="3" class="text-center text-light-black semi-bold pt-4 pb-4">{{
                            $t('no-data.order')
                          }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- //RORDER HISTORY TABLE -->
              <!-- CERTIFICATES TABLE -->
              <div class="row">
                <div class="col-12 mt-5 mb-3">
                  <h2 class="text-18 text-dark-gray semi-bold">{{ $t('certificates.certificates') }}</h2>
                </div>
                <div class="col-12 mb-4">
                  <div class="new-table filter-shadow">
                    <table class="table borderless-table">
                      <thead class="bg-light-gray-primary">
                      <tr>
                        <th scope="col" :class="'pointer'+' '+(currentSort5==='id'?'sorted':'')" @click="sort5('id')">
                          {{ $t('my-certificates.id') }}
                        </th>
                        <th scope="col" :class="'pointer'+' '+(currentSort5==='title'?'sorted':'')"
                            @click="sort5('title')">{{ $t('my-certificates.course') }}
                        </th>
                        <th scope="col" :class="'pointer'+' '+(currentSort5==='created_at'?'sorted':'')"
                            @click="sort5('created_at')">{{ $t('my-certificates.date') }}
                        </th>
                        <th scope="col">{{ $t('my-certificates.certificate') }}</th>
                        <th scope="col">{{ $t('my-certificates.certificate-card') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="certificate in sortedCertificates" :key="certificate.id">
                        <td>#{{ certificate.id }}</td>
                        <td><a style="cursor: pointer;"
                               @click.prevent="$router.push({name: 'Course', params: {id: certificate.course_id}})">{{
                            certificate.title
                          }}</a>
                        </td>
                        <td>{{ $store.getters.parseDateTime(certificate.created_at) }}</td>
                        <td>
                          <button
                              type="button"
                              v-if="certificate && certificate.path != null"
                              :disabled="certificateLoading"
                              class="mt-2 mb-2 btn btn-theme text-center text-white semi-bold"
                              @click.prevent="downloadCertificate(certificate.course)"
                          >
                            <div class="d-flex align-items-center">
                              <i class="fas fa-award mr-1"></i>
                              <span>{{ $t('my-certificates.download') }}</span>
                            </div>
                          </button>
                        </td>
                        <td>
                          <button
                              type="button"
                              :disabled="certificateCardLoading"
                              v-if="certificate && certificate.card_path != null"
                              class="mt-2 mb-2 btn btn-theme text-center text-white semi-bold"
                              @click.prevent="downloadCertificateCard(certificate.course)"
                          >
                            <div class="d-flex align-items-center">
                              <i class="fas fa-id-card mr-1"></i>
                              <span>{{ $t('my-certificates.download-card') }}</span>
                            </div>
                          </button>
                        </td>
                      </tr>
                      <tr v-if="sortedCertificates.length == 0">
                        <td colspan="3" class="text-center text-light-black semi-bold pt-4 pb-4">{{
                            $t('certificates.no-certificates')
                          }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- //CERTIFICATES TABLE -->
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
  <loading v-else height="60vh"/>
</template>
<script>
import Loading from '../components/Loading.vue';
import DeleteProfileModal from '../components/DeleteProfileModal.vue';
import AdminUserQualificationList from '../components/UserData/UserQualifications/AdminUserQualificationList.vue';
import {Capacitor} from "@capacitor/core";
import Select2 from "vue3-select2-component";
import Avatar from "../components/Avatar.vue";
import CourseCard from "../components/CourseCard.vue";
import ConfirmationModal from "../components/ConfirmationModal.vue";

export default {
  components: {ConfirmationModal, CourseCard, Avatar, Loading, DeleteProfileModal, AdminUserQualificationList, Select2},
  name: 'Overview',
  mounted() {
    this.loading = true
    this.axios.get('/users/' + this.$route.params.id)
        .then(resp => {
          this.user = resp.data.user
          this.SAP_CODE = resp.data.user.billing_information?.sap_code;
          this.barAssociationData = {
            bar_association_id: this.user.bar_association_id,
            bar_association_number: this.user.bar_association_number,
            bar_association_name: this.user.bar_association_name,
            wants_credits: this.user.wants_credits,
          }
        })
        .finally(() => this.loading = false)
    this.axios.get('/users?role=Master')
        .then(resp => {
          this.masters = resp.data.users.data.filter(u => u.role == 'Master').map((u) => {
            return {id: u.id, text: `${u.name} ${u.email}`};
          });
        })
    this.axios.get('/roles')
        .then(resp => this.roles = resp.data)
    this.axios.get('/bar-associations')
        .then(resp => this.barAssociations = this.barAssociations.concat(resp.data))

    //get countries
    this.axios.get('/countries').then(resp => {
      this.countries = resp.data.map((country) => ({id: country.id, text: country.name}));
    })
  },
  data() {
    return {
      user: null,
      SAP_CODE:null,
      loading: false,
      certificateLoading: false,
      certificateCardLoading: false,
      countries: [],
      barAssociations: [],
      masters: [],
      roles: [],
      barAssociationData: {
        bar_association_id: null,
        bar_association_number: null,
        bar_association_name: null,
        wants_credits: 0,
      },
      errors: Object(),
      regexErrors: {
        bar_association_number: null,
      },
      submitting: false,
      deleting: false,
      currentSort: 'name',
      currentSortDir: 'asc',
      currentSort1: 'title',
      currentSort1Dir: 'asc',
      currentSort2: 'title',
      currentSort2Dir: 'asc',
      currentSort3: 'name',
      currentSort3Dir: 'asc',
      currentSort4: 'title',
      currentSort4Dir: 'asc',
      currentSort5: 'id',
      currentSort5Dir: 'asc',
    }
  },
  methods: {
    validateBarAssociationNumber() {
      if (/^[0-9]{8}$/.test(this.barAssociationData.bar_association_number)) {
        this.regexErrors['bar_association_number'] = null
      } else {
        this.regexErrors['bar_association_number'] = 'Nem megfelelő formátum'
      }
    },
    loginAs(login){
      console.log('login as', login);
      if (!login) return;

      this.submitting = true;
      this.loading = true;
      this.axios.post('/users/'+this.$route.params.id+'/login').then((resp)=>{
        this.$store.commit("setUser", resp.data.user);
        this.$store.commit("setCurrency", 'EUR');
        localStorage.setItem("accessToken", resp.data.token);
        this.$router.push({name: "Home"});
        this.axios.defaults.headers.common = {
          Authorization: `Bearer ${resp.data.token}`,
          Accept: "Application/json",
        };
        this.axios.defaults.headers.common['X-Socket-Id'] = window.Echo.socketId();

        window.Echo.options.auth.headers.Authorization = `Bearer ${resp.data.token}`
        this.$store.state.token = resp.data.token
        this.$store.dispatch('setupWebsocketListeners')
        this.$store.dispatch('getCustomPages')
      }).finally(() => {
        this.submitting = false;
        this.loading = false;
      });

    },
    changeRole() {
      this.submitting = true
      this.axios.post('/users/' + this.$route.params.id + '/role', {role: this.user.role})
          .finally(() => this.submitting = false)
    },
    changeSAP() {
      this.submitting = true
      this.axios.post('/users/' + this.$route.params.id + '/sap_code', {code: this.SAP_CODE})
          .finally(() => this.submitting = false)
    },
    changeTeam() {
      this.submitting = true
      this.axios.post('/users/' + this.$route.params.id + '/team', {master_id: this.user.master_id})
          .finally(() => this.submitting = false)
    },
    resendEmailVerification(){
      this.submitting = true
      this.axios.post('/users/' + this.$route.params.id + '/resend-email-verification')
          .finally(() => this.submitting = false)
    },
    manualEmailVerification(){
      this.submitting = true
      this.axios.post('/users/' + this.$route.params.id + '/manual-email-verification')
          .finally(() => this.submitting = false)
    },
    changeCountryOfInterest() {
      this.loading = true;
      this.submitting = true;
      this.axios.patch('/users/' + this.$route.params.id, {country_of_interest: this.user.country_of_interest.id})
          .finally(() => {
            this.submitting = false;
            this.loading = false;
          })
    },
    getStartDate(course) {
      let start = 0
      this.user.course_material_progress.filter(cmp => cmp.course_material.course_id == course.id).forEach(cmp => {
        if (new Date(Date.parse(cmp.created_at)) > start) {
          start = new Date(Date.parse(cmp.created_at))
        }
      })
      //console.log(start)
      return start == 0 ? 'Nem elkezdett' : this.$store.getters.parseDateTime(start.toISOString())
    },
    getProgress(course) {
      let progress = 0;
      this.user.course_material_progress.filter(cm => cm.course_material.course_id == course.id).forEach((cm) => {
        progress += cm.progress
      })
      return course.duration ? Math.floor(progress / (course.duration / 100)) : 0
    },
    updateBarAssociationData() {
      this.submitting = true
      this.axios.post('/bar-association-data', this.barAssociationData, {params: {user: this.user.id}})
          .then(resp => {
            Object.assign(this.user, resp.data.barAssociationData)
          })
          .catch(e => this.errors = e.response.data.errors)
          .finally(() => this.submitting = false)
    },
    deleteUserCourse(course) {
      this.deleting = true;
      this.axios.delete(`/users/${this.user.id}/courses/${course.id}`)
          .then(() => {
            let idx = this.user.courses.findIndex(c => c.id === course.id)
            if (idx !== -1) {
              this.user.courses.splice(idx, 1)
            }
            idx = this.user.coursesInProgress.findIndex(c => c.id === course.id)
            if (idx !== -1) {
              this.user.coursesInProgress.splice(idx, 1)
            }
            $(`delete-user-course-modal-${course.id}`).modal('hide')
            $('.modal-backdrop.fade.show').remove()
            $('body').removeClass('modal-open')
          })
          .catch(e => console.log(e))
          .finally(() => this.deleting = false)
    },
    downloadCertificate(course) {
      this.certificateLoading = true;
      this.axios.get('/courses/' + course.id + '/certificate?user=' + this.$route.params.id, {responseType: 'blob'})
          .then(resp => {
            const name = course.title + '_cert.pdf'
            if (Capacitor.isNative) {
              this.$store.commit('downloadFileMobile', {name, mime: 'application/pdf', data: resp.data})
            } else {
              const url = window.URL.createObjectURL(new Blob([resp.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', name);
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(e => console.log(e.response))
          .finally(() => this.certificateLoading = false)
    },
    downloadCertificateCard(course) {
      this.certificateCardLoading = true;
      this.axios.get('/courses/' + course.id + '/certificate-card?user=' + this.$route.params.id, {responseType: 'blob'})
          .then(resp => {
            const name = course.title + '_card.pdf'
            if (Capacitor.isNative) {
              this.$store.commit('downloadFileMobile', {name, mime: 'application/pdf', data: resp.data})
            } else {
              const url = window.URL.createObjectURL(new Blob([resp.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', name);
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(e => console.log(e.response))
          .finally(() => this.certificateCardLoading = false)
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
    sort1: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort1) {
        this.currentSort1Dir = this.currentSort1Dir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort1 = s;
    },
    sort2: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort2) {
        this.currentSort2Dir = this.currentSort2Dir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort2 = s;
    },
    sort3: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort3) {
        this.currentSort3Dir = this.currentSort3Dir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort3 = s;
    },
    sort4: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort4) {
        this.currentSort4Dir = this.currentSort4Dir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort4 = s;
    },
    sort5: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort5) {
        this.currentSort5Dir = this.currentSort5Dir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort5 = s;
    }
  },
  computed: {

    sortedTestAttempts: function () {
      let testAttempts = this.user && this.user.test_attempts &&
      this.user.test_attempts.length && this.user.test_attempts.length > 0 ?
          this.user.test_attempts.map((testAttempt) => {
            return {
              id: testAttempt.id,
              name: testAttempt.test.name,
              started_at: testAttempt.started_at,
              finished_at: testAttempt.finished_at
            }
          }) : [];
      return testAttempts.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    sortedOwnedCourses: function () {
      let ownedCourses = this.user && this.user.courses &&
      this.user.courses.length && this.user.courses.length > 0 ?
          this.user.courses.map((course) => {
            return {
              id: course.id,
              title: course.title,
              started_at: this.getStartDate(course),
              progress: this.getProgress(course),
            }
          }) : [];
      return ownedCourses.sort((a, b) => {
        let modifier = 1;
        if (this.currentSort1Dir === 'desc') modifier = -1;
        if (a[this.currentSort1] < b[this.currentSort1]) return -1 * modifier;
        if (a[this.currentSort1] > b[this.currentSort1]) return 1 * modifier;
        return 0;
      });
    },
    sortedCoursesInProgress: function () {
      let coursesInProgress = this.user && this.user.coursesInProgress &&
      this.user.coursesInProgress.length && this.user.coursesInProgress.length > 0 ?
          this.user.coursesInProgress.map((course) => {
            return {
              id: course.id,
              title: course.title,
              started_at: this.getStartDate(course),
              progress: this.getProgress(course),
            }
          }) : [];
      return coursesInProgress.sort((a, b) => {
        let modifier = 1;
        if (this.currentSort2Dir === 'desc') modifier = -1;
        if (a[this.currentSort2] < b[this.currentSort2]) return -1 * modifier;
        if (a[this.currentSort2] > b[this.currentSort2]) return 1 * modifier;
        return 0;
      });
    },
    sortedCorrectedTestAttempts: function () {
      let correctedTestAttempts = this.user.test_attempts && this.user.test_attempts.length &&
      this.user.test_attempts.length > 0 && this.user.test_attempts.filter(ta => {
        return new Date(Date.parse(ta.finished_at)) < new Date() && !ta.corrected
      }) ?
          this.user.test_attempts.filter(ta => {
            return new Date(Date.parse(ta.finished_at)) < new Date() && !ta.corrected
          }).map((testAttempt) => {
            return {
              id: testAttempt.id,
              name: testAttempt.test.name,
              started_at: this.$store.getters.parseDateTime(testAttempt.started_at),
              finished_at: this.$store.getters.parseDateTime(testAttempt.finished_at),
            }
          }) : [];
      return correctedTestAttempts.sort((a, b) => {
        let modifier = 1;
        if (this.currentSort3Dir === 'desc') modifier = -1;
        if (a[this.currentSort3] < b[this.currentSort3]) return -1 * modifier;
        if (a[this.currentSort3] > b[this.currentSort3]) return 1 * modifier;
        return 0;
      });
    },
    sortedOrderHistory: function () {
      let orderHistory = this.user.orders && this.user.orders.length &&
      this.user.orders.length > 0 ?
          this.user.orders.map((order) => {
            return {
              id: order.id,
              title: order.items.map(x => x.title).join(", "),
              status: order.status,
              historyTotal: order.items.reduce((x, y) => (x + y.gross_price), 0),
              currency: order.currency,
              exchange_rate: order.exchange_rate,
              coupon: order.coupon ? order.coupon.code : "-",
              updated_at: this.$store.getters.parseDateTime(order.updated_at),
            }
          }) : [];
      return orderHistory.sort((a, b) => {
        let modifier = 1;
        if (this.currentSort4Dir === 'desc') modifier = -1;
        if (a[this.currentSort4] < b[this.currentSort4]) return -1 * modifier;
        if (a[this.currentSort4] > b[this.currentSort4]) return 1 * modifier;
        return 0;
      });
    },
    sortedCertificates: function () {
      let certificates = this.user.certificates && this.user.certificates &&
      this.user.certificates.length > 0 ?
          this.user.certificates.map((certificate) => {
            return {
              id: certificate.id,
              title: certificate.course.title,
              created_at: certificate.created_at,
              path: certificate.path,
              course_id: certificate.course_id,
              course: certificate.course,
              card_path: certificate.card_path
            }
          }) : [];
      return certificates.sort((a, b) => {
        let modifier = 1;
        if (this.currentSort5Dir === 'desc') modifier = -1;
        if (a[this.currentSort5] < b[this.currentSort5]) return -1 * modifier;
        if (a[this.currentSort5] > b[this.currentSort5]) return 1 * modifier;
        return 0;
      });
    }
  }
}
</script>
<style scoped>
#profile-overview {
  margin-top: 30px;
}

.user-info {
  border-radius: 5px;
  padding: 15px;
}

.training-card-progress {
  max-width: 95% !important;
}

.sorted {
  font-weight: normal;
}
</style>
