<template>
<div class="modal" ref="changePasswordModal" tabindex="-1" role="dialog" id="changePasswordModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{$t('settings.change-pw')}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="submit" class="row" id="changePasswordForm">
            <div class="col-12">
                <label>{{$t('settings.curr-pw')}}</label>
                <input class="form-control" type="password" v-model="data.current_password"> 
            </div>
            <div class="col-12 mt-3">
                <label>{{$t('reset-password.u-pw')}}</label>
                <input class="form-control" type="password"  @input="setPassword">
                <ul v-if="data.new_password != null" class="text-left mt-2 pl-4">
                    <li :class="validation.length ? 'text-success' : 'text-danger'">{{$t('register.valid-1')}}</li>
                    <li :class="validation.lowerCase ? 'text-success' : 'text-danger'">{{$t('register.valid-2')}}</li>
                    <li :class="validation.upperCase ? 'text-success' : 'text-danger'">{{$t('register.valid-3')}}</li>
                    <li :class="validation.number ? 'text-success' : 'text-danger'" >{{$t('register.valid-4')}}</li>
                    <li :class="validation.special ? 'text-success' : 'text-danger'">{{$t('register.valid-5')}}</li>   
                </ul>
            </div>
            <div class="col-12 mt-3">
                <label>{{$t('settings.new-pw-again')}}</label>
                <input class="form-control" type="password" v-model="data.new_password_confirm"> 
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('common.cancel')}}</button>
        <button type="submit" form="changePasswordForm" :disabled="submitting || pwErrors" class="btn btn-primary">{{$t('overview.change')}}</button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
    name: 'ChangePasswordModal',
    data() {
        return {
            data: {
                current_password: null,
                new_password: null,
                new_password_confirm: null
            },
            validation: {
                length: false,
                lowerCase: false,
                upperCase: false,
                number: false,
                special: false
            },
            submitting: false
        }
    },
    computed: {
        pwErrors() {
            for(const prop in this.validation) {
                if(this.validation[prop] === false) return true
            }
            return false
        }
    },
    methods: {
        setPassword(pw) {
            this.data.new_password = pw.target.value
            this.validation.length = this.data.new_password.length >= 8
            this.validation.lowerCase = new RegExp('[a-z]').test(this.data.new_password)
            this.validation.upperCase = new RegExp('[A-Z]').test(this.data.new_password)
            this.validation.number = new RegExp('[0-9]').test(this.data.new_password)
            this.validation.special = new RegExp('[^A-Za-z0-9]').test(this.data.new_password)
        },
        submit() {
            this.submitting = true
            this.axios.post('/password', this.data)
                .then(() => {
                    this.data = {
                        current_password: null,
                        new_password: null,
                        new_password_confirm: null
                    }
                    $(this.$refs['changePasswordModal']).modal('hide');
                })
                .catch()
                .finally(() => this.submitting = false)
        }
    }
}
</script>