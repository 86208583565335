<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-coupons.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('manage-coupons.manage-coupons') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div id="content-2">
      <div class="container">
        <div class="row">
          <div class="col mt-4">
            <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('manage-coupons.manage-coupons') }}</h2>
          </div>
          <div v-if="$store.state.user.role != 'Master'" class="col mt-4 align-content-end" style="display: flex; justify-content: flex-end;">
            <button type="button" @click.prevent="$router.push({name: 'CouponStatistics'})"
                    class="btn btn-theme text-center text-white semi-bold">{{ $t('manage-coupons.coupon-statistics') }}
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <div class="row">
              <div class="col-12">
                <section id="new-coupon">
                  <div class="row">
                    <div class="col-12">
                      <div class="flex-wrap d-flex">
                        <div class="d-flex align-items-center pointer mr-auto" data-toggle="modal"
                             data-target="#newCouponModal">
                          <div class="new-button-50 d-flex align-items-center justify-content-center">
                            <img src="assets/img/icons/plus.svg" class="img-fluid" alt="">
                          </div>
                          <div class="d-flex flex-column ml-2">
                            <h6 class="text-16 text-dark-gray">{{ $t('manage-coupons.new-coupon') }}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="col-12 mt-3">
                <label class="text-16 text-dark-gray mb-0">{{ $t('manage-coupons.usage-type') }}:</label>
                <div class="form-check">
                  <input class="form-check-input" @change="filterCoupons($event)" type="radio" :value="null"
                         name="type" checked id="all">
                  <label class="form-check-label" for="flexRadioDefault1">
                    {{ $t('manage-coupons.all') }}
                  </label>
                </div>
                <div v-for="(type) in usageTypes" :key="type">
                  <div v-if="coupons.some(c=>c.usage_type===type.type)" class="form-check">
                    <input class="form-check-input" @change="filterCoupons($event)" type="radio" name="type"
                           :value="type.type" id="flexRadioDefault1">
                    <label class="form-check-label" for="flexRadioDefault1">
                      {{ type.type }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3">
                <label class="text-16 text-dark-gray mb-0">{{ $t('manage-coupons.validity') }}:</label>
                <div class="form-check">
                  <input class="form-check-input" @change="filterCouponsValidity($event)" type="radio" :value="null"
                         name="validity" checked id="all_valid">
                  <label class="form-check-label" for="flexRadioDefault1">
                    {{ $t('manage-coupons.all') }}
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" @change="filterCouponsValidity($event)" type="radio" :value="true"
                         name="validity" id="valid">
                  <label class="form-check-label" for="flexRadioDefault1">
                    {{ $t('manage-coupons.valid') }}
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" @change="filterCouponsValidity($event)" type="radio" :value="false"
                         name="validity" id="invalid">
                  <label class="form-check-label" for="flexRadioDefault1">
                    {{ $t('manage-coupons.invalid') }}
                  </label>
                </div>
              </div>
              <div v-if='couponType=="Once"' class="col-12 mt-3">
                <label class="text-16 text-dark-gray mb-0">{{ $t('manage-coupons.usage') }}:</label>
                <div class="form-check">
                  <input class="form-check-input" @change="filterCouponsUsage($event)" type="radio" :value="null"
                         name="usage" checked id="all_usage">
                  <label class="form-check-label" for="flexRadioDefault1">
                    {{ $t('manage-coupons.all') }}
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" @change="filterCouponsUsage($event)" type="radio" :value="true"
                         name="usage"  id="used">
                  <label class="form-check-label" for="flexRadioDefault1">
                    {{ $t('manage-coupons.used') }}
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" @change="filterCouponsUsage($event)" type="radio" :value="false"
                         name="usage"  id="unused">
                  <label class="form-check-label" for="flexRadioDefault1">
                    {{ $t('manage-coupons.unused') }}
                  </label>
                </div>
              </div>
            </div>
            <create-coupon-modal @couponCreated="onCouponCreated" :usageTypes="usageTypes" :courses="courses"/>
          </div>
          <div class="col-lg-9">
            <section id="coupon-list">
              <div class="row" v-if="!loading">
                <!-- <div class="col-12">
                    <h6 class="text-13 text-dark-gray semi-bold">No data placeholder</h6>
                </div> -->
                <!-- COUPON CARD -->
                <div v-for="coupon in coupons.filter(c=>filter(c))" :key="coupon.id"
                     class="col-12 col-xl-4 col-sm-6 col-lg-6 col-md-4 mb-3">
                  <div class="coupon-box bg-light-gray-primary">
                    <div class="row">
                      <div class="col-lg-9">
                        <div class="coupon-code text-center">
                          <h4 class="text-white text-18 semi-bold mb-3">{{ $t('manage-coupons.sale') }}</h4>
                          <h2 class="text-white text-36 semi-bold mb-3">
                            {{ coupon.discount }}{{ coupon.percentage_based ? '%' : ' EUR' }}</h2>
                          <h4 class="text-white text-18 regular mb-1">{{ $t('manage-coupons.code') }}:</h4>
                          <div class="code-box">
                            <h3 class="text-white text-20 semi-bold">{{ coupon.code }}</h3>
                          </div>
                          <h5 v-if="coupon.membership" class="text-white text-12 mb-3">{{$t('manage-coupons.membership') }}</h5>
                          <h5 v-else class="text-white text-12 mb-3">{{ coupon.course ? coupon.course.title : $t('manage-coupons.all-courses') }}</h5>
                          <h5 class="text-white text-12 mb-3">{{ $t('usage-types.' + coupon.usage_type) }}</h5>
                          <h5 class="text-white text-12 mb-3" :class="!coupon.is_valid?'text-danger':''">
                            {{
                              coupon.valid_until ? $store.getters.parseDateTime(coupon.valid_until) : $t('manage-coupons.unlimited')
                            }}</h5>
                          <h5 class="text-white text-12 mb-3">{{ coupon.creator?.name }}</h5>
                        </div>
                      </div>
                      <div class="col-lg-3 coupon-action pl-0 d-flex align-items-center">
                        <div class="flex-wrap d-flex align-items-center justify-content-center">
                          <img data-toggle="modal" :data-target="'#deleteCoupon-'+coupon.id"
                               src="assets/img/icons/trash-red.svg" class="trash pointer" alt="">
                        </div>
                        <generic-delete-modal
                            :id="'deleteCoupon-'+coupon.id"
                            text="Biztosan törölni szeretné a következő kupont: "
                            :item="coupon.code"
                            :boldItem="true"
                            @itemDeleted="onCouponDeleted"
                            :url="`/coupons/${coupon.id}`"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <h6 v-if="coupons.length === 0" class="text-16 text-dark-gray">{{ $t('manage-coupons.empty') }}</h6>
                <!-- //COUPON CARD -->
              </div>
              <loading height="30vh" v-else/>
            </section>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import CreateCouponModal from '../components/CreateCouponModal.vue'
import EditCouponModal from '../components/EditCouponModal.vue'
import GenericDeleteModal from '../components/GenericDeleteModal.vue'
import Loading from '../components/Loading.vue'

export default {
  components: {CreateCouponModal, GenericDeleteModal, EditCouponModal, Loading},
  name: 'ManageCoupons',
  mounted() {
    this.axios.get('/coupons')
        .then(resp => {
          this.coupons = resp.data.coupons
          this.courses = resp.data.courses
          this.usageTypes = resp.data.usage_types
        })
        .catch(e => console.log(e))
        .finally(() => this.loading = false)
  },
  data() {
    return {
      coupons: [],
      courses: [],
      usageTypes: [],
      loading: true,
      couponType: '',
      couponValidity: '',
      couponUsage: ''
    }
  },
  methods: {
    onCouponEdited(coupon) {
      this.coupons[this.coupons.findIndex(c => c.id === coupon.id)] = coupon
    },
    onCouponCreated(coupon) {
      this.coupons.push(coupon)
    },
    filter(c) {
      //console.log(c, this.couponType, this.couponValidity, this.couponUsage);
      if (!this.couponType && !this.couponValidity) {
        return true;
      } else if (!this.couponType && this.couponValidity) {
        if (this.couponValidity == 'true') {
          return c.is_valid === true;
        } else if (this.couponValidity == 'false') {
          return c.is_valid === false;
        }
      }else if (this.couponType && !this.couponValidity){
        if (this.couponType === c.usage_type) {
          if (this.couponType == 'Once'){
            if(!this.couponUsage){
              return true;
            }if (this.couponUsage ==='true'){
              return c.is_used === true;
            }else if (this.couponUsage ==='false'){
              return c.is_used === false;
            }
          }else {
            return true;
          }
        }
      }else{
        if (this.couponType === c.usage_type && this.couponValidity == 'true') {
          return c.is_valid === true;
        } else if (this.couponType === c.usage_type && this.couponValidity == 'false') {
          return c.is_valid === false;
        }
      }
    },
    filterCoupons(event) {
      this.couponType = event.target.value;
    },
    filterCouponsValidity(event) {
      this.couponValidity = event.target.value;
    },
    filterCouponsUsage(event) {
      this.couponUsage = event.target.value;
    },
    onCouponDeleted(couponId) {
      const id = Number(couponId.split('-')[1])
      this.coupons.splice(this.coupons.findIndex(c => c.id === id), 1)
    }
  }
}
</script>

<style scoped>
#new-coupon,
#coupon-list {
  margin-top: 30px;
}

.coupon-box {
  padding: 10px;
  border-radius: 5px;
}

.coupon-code {
  border: 2px dashed var(--white);
  padding: 10px;
}

.text-20 {
  word-wrap: break-word;
}

.code-box {
  min-height: 46px;
  overflow: hidden;
}

.coupon-action img {
  height: 32px;
}

.trash {
  filter: invert(100%) sepia(10%) saturate(7410%) hue-rotate(294deg) brightness(122%) contrast(104%);
}

.action-spacer {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 20px;
  margin-top: 20px;
  height: 2px;
  width: 90%;
}

@media (max-width: 991px) {
  .flex-wrap {
    width: 100%;
  }

  .action-spacer {
    display: none;
  }

  .pen, .trash {
    margin-top: 10px;
  }

  .pen {
    margin-right: 30px;
  }

  .pl-0 {
    padding-left: 15px !important;
  }
}
</style>
