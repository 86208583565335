<template>
<div>
    <div>
      <!-- select from presenters -->
      <select v-model="selectedPresenter" class="form-control mb-1">
        <option v-for="(presenter, index) in presenters" :value="presenter.id" :key="presenter.id" :selected="index==0">{{presenter.name}}</option>
      </select>
        <textarea v-model="question" class="form-control mb-1" :placeholder="$t('expert.placeholder')"></textarea>
      <TransparentButton :click="askTheExpert" :disabled="submitting || !question || !question.trim()" :text="$t('view-course.send')"/>
    </div>
</div>
</template>
<script>
import TransparentButton from "./settings/TransparentButton.vue";

export default {
  components: {TransparentButton},
    props: {
        creatorId: Number,
        presenters: Array,
    },
    data() {
        return {
            question: null,
            selectedPresenter: this.presenters && this.presenters.length > 0 ? this.presenters[0].id : this.creatorId,
            submitting: false,
        }
    },
    methods: {
        askTheExpert() {
            if(this.question && this.question.trim()) {
                this.submitting = true
                const group = this.$store.state.user.chat_groups.find(g => g.name == null && g.users.length == 2 && g.users.some(u => u.id == this.selectedPresenter));
                console.log("selected",this.selectedPresenter);
                if(!group) {
                    this.axios.post('/conversation/user/'+this.selectedPresenter, {message: this.question})
                        .then(resp => {
                            this.$store.state.user.chat_groups.push(resp.data.group)
                            this.$store.commit('subscribeToGroup', resp.data.group)
                            this.$router.push({name: 'Chat', params: {conversation: Number(resp.data.group.id)}})
                        })
                        .catch((e) => console.log(e))
                        .finally(() => this.submitting = false)
                }
                else {
                    this.axios.post('/conversation/'+group.id, {message: this.question})
                        .then(resp => {
                            group.chat_group_messages.push(resp.data.chatMessage)
                            this.$router.push({name: 'Chat', params: {conversation: Number(group.id)}})
                        })
                        .finally(() => this.submitting = false)
                }
            }
        },
     }
}
</script>
