<template>
    <div class="modal fade" tabindex="-1" role="dialog" id="ndaModal">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title text-center" style="flex-grow: 1">{{$t('nda.title')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" ref="ndaModal">
            <p>{{$t('nda.preface')}}</p>
            <p>{{$t('nda.line1')}}</p>
            <p>{{$t('nda.line2')}}</p>
            <p>{{$t('nda.line3')}}</p>
            <p>{{$t('nda.line4')}}</p>
            <p>{{$t('nda.line5')}}</p>
            <p>{{$t('nda.line6')}}</p>
            <p>{{$t('nda.line7')}}</p>
            <p>{{$t('nda.line8')}}</p>
            <p>{{$t('nda.line9')}}</p>

        </div>
        <div class="modal-footer">
          <PrimaryButton :disabled="!canAccept" :click="accept" :text="$t('nda.accept')"/>
        </div>
        </div>
    </div>
    </div>
</template>
<script>
import PrimaryButton from "./settings/PrimaryButton.vue";

export default {
    name: 'NdaModal',
  components: {PrimaryButton},
    emits: ['accepted'],
    mounted() {
        this.element = this.$refs.ndaModal
        this.jqueryElement = $(this.element)
        this.element.addEventListener("scroll", this.scroll)
    },
    beforeUnmount() {
        this.element.removeEventListener("scroll", this.scroll)
    },
    data() {
        return {
            canAccept: false,
            element: null,
            jqueryElement: null
        }
    },
    methods: {
        accept() {
            this.$emit('accepted')
            $('#ndaModal').modal('hide')
        },
        scroll () {
            if (this.isScrollThresholdReached()) {
                this.canAccept = true
            }
        },
        getDocHeight() {
            return Math.max(this.element.scrollHeight, this.element.offsetHeight, this.element.clientHeight)
        },
        isScrollThresholdReached() {
            return this.jqueryElement.scrollTop() + this.jqueryElement.innerHeight() >= this.getDocHeight() - 40
        }
    },
}
</script>
