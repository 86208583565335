<template>
    <div>

        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-courses.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'ManageTests'})">{{ $t('manage-tests.managing-tests') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('correct-test-attempt.try-test') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->

        <div class="container" style="max-width: 700px;">
            <div class="row" v-if="!loading && attempt">
                <h1 v-if="attempt.test_group" class="col-12 text-center mt-3">
                    {{attempt.test_group.name}}
                </h1>
                <div class="col-12 mt-5" v-for="(currentQuestion, idx) in attempt.questions" :key="currentQuestion.id">
                    <div class="col-12 mb-4 p-0">
                        <h3><strong>{{idx+1}}. {{currentQuestion.question}}</strong></h3>
                        <div v-if="currentQuestion.multimedia">
                            <div v-if="currentQuestion.multimedia.type == 'video' || currentQuestion.multimedia.type == 'image' " style="color: var(--black); cursor:pointer; width: 100%; height: 100%" 
                                :data-fancybox="`newQuestion`" 
                                :href="currentQuestion.multimedia.type != 'video' ? $store.getters.getStorageURL(currentQuestion.multimedia.path) : `#question-${currentQuestion.id}-${currentQuestion.multimedia.id}`" target="_blank"
                                :data-caption="currentQuestion.multimedia.name"
                            >
                                <div v-if="currentQuestion.multimedia.type == 'video'" class="test-attempt-video-container">
                                    <video class="test-attempt-video" nocontrols>
                                        <source :src="$store.getters.getStorageURL(currentQuestion.multimedia.path)">
                                    </video>
                                    <video :id="`question-${currentQuestion.id}-${currentQuestion.multimedia.id}`" style="display: none;" controls controlsList="nodownload">
                                        <source :src="$store.getters.getStorageURL(currentQuestion.multimedia.path)">
                                    </video>
                                    <i class="far fa-play-circle playbtn" style="text-shadow: 10 10 10px #000"></i>
                                </div>
                                <div v-if="currentQuestion.multimedia.type == 'image'" class="test-attempt-image-container">
                                    <img :src="$store.getters.getStorageURL(currentQuestion.multimedia.path)" class="test-attempt-image">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2 p-0">
                        <form @submit.prevent="submit">
                            <div v-if="currentQuestion.question_type == 'Single choice'">
                                <div v-for="answer in currentQuestion.answers" :key="answer.id" class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <input disabled type="radio" :checked="currentQuestion.userAnswers.some(ua => ua.answer == answer.answer)" :name="currentQuestion.id" :aria-label="$t('correct-test-attempt.radio')">
                                        </div>
                                    </div>
                                    <div type="text" class="form-control" style="height: fit-content !important;" :aria-label="$t('correct-test-attempt.text-input')">
                                        <div :class="answer.multimedia ? 'mb-2' : ''">
                                            <span>{{answer.answer}}</span>
                                            <span v-if="currentQuestion.userAnswers.some(ua => ua.answer == answer.answer) && answer.correct">
                                                <i class="fas fa-check ml-2" style="color: green; font-size: 20px;"></i>
                                            </span>
                                            <span v-else-if="currentQuestion.userAnswers.some(ua => ua.answer == answer.answer) && !answer.correct">
                                                <i class="fas fa-times ml-2" style="color: red; font-size: 20px;"></i>
                                            </span>
                                            <span v-else-if="!currentQuestion.userAnswers.some(ua => ua.answer == answer.answer) && answer.correct">
                                                <i class="fas fa-check ml-2" style="color: green; font-size: 20px;"></i>
                                            </span>
                                        </div>
                                        <div v-if="answer.multimedia">
                                            <div v-if="answer.multimedia.type == 'video' || answer.multimedia.type == 'image' " style="color: var(--black); cursor:pointer; width: 100%; height: 100%" 
                                                :data-fancybox="`newQuestion`" 
                                                :href="answer.multimedia.type != 'video' ? $store.getters.getStorageURL(answer.multimedia.path) : `#question-${answer.id}-${answer.multimedia.id}`" target="_blank"
                                                :data-caption="answer.multimedia.name"
                                            >
                                                <div v-if="answer.multimedia.type == 'video'" class="test-attempt-video-container">
                                                    <video class="test-attempt-video" nocontrols>
                                                        <source :src="$store.getters.getStorageURL(answer.multimedia.path)">
                                                    </video>
                                                    <video :id="`question-${answer.id}-${answer.multimedia.id}`" style="display: none;" controls controlsList="nodownload">
                                                        <source :src="$store.getters.getStorageURL(answer.multimedia.path)">
                                                    </video>
                                                    <i class="far fa-play-circle playbtn" style="text-shadow: 10 10 10px #000"></i>
                                                </div>
                                                <div v-if="answer.multimedia.type == 'image'" class="test-attempt-image-container">
                                                    <img :src="$store.getters.getStorageURL(answer.multimedia.path)" class="test-attempt-image">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="currentQuestion.question_type == 'Subgrouped'">
                                <div v-for="subQuestion in currentQuestion.sub_questions" :key="subQuestion.id" class="mb-2">
                                    <div type="text" class="form-control d-flex flex-column" style="height: fit-content !important;" aria-label="Text input with radio button">
                                        <div :class="subQuestion.multimedia ? 'mb-2' : ''">{{subQuestion.question}}</div>
                                        <div v-if="subQuestion.multimedia">
                                            <div v-if="subQuestion.multimedia.type == 'video' || subQuestion.multimedia.type == 'image' " style="color: var(--black); cursor:pointer; width: 100%; height: 100%" 
                                                :data-fancybox="`newQuestion`" 
                                                :href="subQuestion.multimedia.type != 'video' ? $store.getters.getStorageURL(subQuestion.multimedia.path) : `#subQuestion-${subQuestion.id}-${subQuestion.multimedia.id}`" target="_blank"
                                                :data-caption="subQuestion.multimedia.name"
                                            >
                                                <div v-if="subQuestion.multimedia.type == 'video'" class="test-attempt-video-container">
                                                    <video class="test-attempt-video" nocontrols>
                                                        <source :src="$store.getters.getStorageURL(subQuestion.multimedia.path)">
                                                    </video>
                                                    <video :id="`subQuestion-${subQuestion.id}-${subQuestion.multimedia.id}`" style="display: none;" controls controlsList="nodownload">
                                                        <source :src="$store.getters.getStorageURL(subQuestion.multimedia.path)">
                                                    </video>
                                                    <i class="far fa-play-circle playbtn" style="text-shadow: 10 10 10px #000"></i>
                                                </div>
                                                <div v-if="subQuestion.multimedia.type == 'image'" class="test-attempt-image-container">
                                                    <img :src="$store.getters.getStorageURL(subQuestion.multimedia.path)" class="test-attempt-image">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-control mt-2 disabled">
                                            <div class="d-flex align-items-center" v-if="currentQuestion.userAnswers.find(ua => ua.sub_question_id === subQuestion.id)">
                                                <div>{{currentQuestion.userAnswers.find(ua => ua.sub_question_id === subQuestion.id).answer}}</div>
                                                <div v-if="currentQuestion.userAnswers.find(ua => ua.sub_question_id === subQuestion.id).answer === currentQuestion.answers.find(a => a.id === subQuestion.correct_answer_id).answer">
                                                    <i class="fas fa-check ml-2" style="color: green; font-size: 20px;"></i>
                                                </div>
                                                <div v-else>
                                                    <i class="fas fa-times ml-2" style="color: red; font-size: 20px;"></i>
                                                </div>
                                            </div>
                                            <div v-else>{{$t('correct-test-attempt.no-answer')}} <i class="fas fa-times ml-2" style="color: red; font-size: 20px;"></i></div>
                                        </div>
                                        <div class="mt-2" style="font-size: 90%" v-if="!currentQuestion.userAnswers.find(ua => ua.sub_question_id === subQuestion.id) || currentQuestion.userAnswers.find(ua => ua.sub_question_id === subQuestion.id).answer !== currentQuestion.answers.find(a => a.id === subQuestion.correct_answer_id).answer">
                                            {{$t('common.correct-answer')}}: <strong>{{currentQuestion.answers.find(a => a.id === subQuestion.correct_answer_id).answer}}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="currentQuestion.question_type == 'Multiple choice'">
                                <div v-for="answer in currentQuestion.answers" :key="answer.id" class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <label></label>
                                            <input type="checkbox" class="checkbox" disabled :checked="currentQuestion.userAnswers.some(ua => ua.answer == answer.answer)" :aria-label="$t('correct-test-attempt.radio')">
                                        </div>
                                    </div>
                                    <div type="text" class="form-control" style="height: fit-content !important;" :aria-label="$t('correct-test-attempt.text-input')">
                                        <div :class="answer.multimedia ? 'mb-2' : ''">
                                            <span>{{answer.answer}}</span>
                                            <span v-if="currentQuestion.userAnswers.some(ua => ua.answer == answer.answer) && answer.correct">
                                                <i class="fas fa-check ml-2" style="color: green; font-size: 20px;"></i>
                                            </span>
                                            <span v-else-if="currentQuestion.userAnswers.some(ua => ua.answer == answer.answer) && !answer.correct">
                                                <i class="fas fa-times ml-2" style="color: red; font-size: 20px;"></i>
                                            </span>
                                            <span v-else-if="!currentQuestion.userAnswers.some(ua => ua.answer == answer.answer) && answer.correct">
                                                <i class="fas fa-check ml-2" style="color: green; font-size: 20px;"></i>
                                            </span>
                                        </div>
                                        <div v-if="answer.multimedia">
                                            <div v-if="answer.multimedia.type == 'video' || answer.multimedia.type == 'image' " style="color: var(--black); cursor:pointer; width: 100%; height: 100%" 
                                                :data-fancybox="`newQuestion`" 
                                                :href="answer.multimedia.type != 'video' ? $store.getters.getStorageURL(answer.multimedia.path) : `#question-${answer.id}-${answer.multimedia.id}`" target="_blank"
                                                :data-caption="answer.multimedia.name"
                                            >
                                                <div v-if="answer.multimedia.type == 'video'" class="test-attempt-video-container">
                                                    <video class="test-attempt-video" nocontrols>
                                                        <source :src="$store.getters.getStorageURL(answer.multimedia.path)">
                                                    </video>
                                                    <video :id="`question-${answer.id}-${answer.multimedia.id}`" style="display: none;" controls controlsList="nodownload">
                                                        <source :src="$store.getters.getStorageURL(answer.multimedia.path)">
                                                    </video>
                                                    <i class="far fa-play-circle playbtn" style="text-shadow: 10 10 10px #000"></i>
                                                </div>
                                                <div v-if="answer.multimedia.type == 'image'" class="test-attempt-image-container">
                                                    <img :src="$store.getters.getStorageURL(answer.multimedia.path)" class="test-attempt-image">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="currentQuestion.question_type == 'Free text'">
                                <h5 class="mt-4">{{ $t('correct-test-attempt.correct-answ') }}</h5>
                                <textarea disabled class="form-control mb-3" :value="currentQuestion.answers.length > 0 ? currentQuestion.answers[0].answer : $t('correct-test-attempt.no-correct')" :aria-label="$t('correct-test-attempt.txt-area')"></textarea>
                                <h5>{{ $t('correct-test-attempt.given-answ') }}</h5>
                                <textarea disabled class="form-control" :value="currentQuestion.userAnswers.length > 0 ? currentQuestion.userAnswers[0].answer : null" :aria-label="$t('correct-test-attempt.txt-area')"></textarea>
                            </div>
                            <h5 class="mt-3" v-if="currentQuestion.question_type !== 'Free text'">{{ $t('correct-test-attempt.score') }} {{calculatePoints(currentQuestion)}}/{{currentQuestion.max_points}}</h5>
                            <div v-else>
                                <h5 v-if="currentQuestion.userAnswers[0]" class="mt-3">{{ $t('correct-test-attempt.score') }}<input type="number" :style="{width: currentQuestion.max_points.toString().length*25+15+'px'}" v-model="currentQuestion.givenPoints">/{{currentQuestion.max_points}}</h5>
                                <h5 v-else class="mt-3">{{ $t('correct-test-attempt.score') }} 0/{{currentQuestion.max_points}}</h5>
                                <button v-if="currentQuestion.userAnswers[0]" class="btn btn-secondary mt-1 mb-4" type="button" :disabled="submitLoading" @click="assignPoints(currentQuestion)">{{ $t('correct-test-attempt.edit-score') }}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-5 mb-5">
                    <h4><strong>{{ $t('correct-test-attempt.test-name') }}</strong></h4>
                    <span class="ml-2">{{attempt.test.name}}</span>
                    <h4 class="mt-3"><strong>{{ $t('correct-test-attempt.user') }}</strong></h4>
                    <span class="ml-2">{{attempt.user.name}}</span>
                    <h4 class="mt-3"><strong>{{ $t('view-test-attempt.group') }}</strong></h4>
                    <span class="ml-2">{{attempt.test_group ? attempt.test_group.name : $t('common.none')}}</span>
                    <h4 class="mt-3"><strong>{{ $t('correct-test-attempt.max-time') }}</strong></h4>
                    <span class="ml-2">{{attempt.test.length}} {{ $t('correct-test-attempt.minute') }}</span>
                    <h4 class="mt-3"><strong>{{ $t('correct-test-attempt.begin') }}</strong></h4>
                    <span class="ml-2">{{$store.getters.parseDateTime(attempt.started_at)}}</span>
                    <h4 class="mt-3"><strong>{{ $t('correct-test-attempt.end') }}</strong></h4>
                    <span class="ml-2">{{$store.getters.parseDateTime(attempt.finished_at)}}</span>
                    <h4 class="mt-3"><strong>{{ $t('correct-test-attempt.score') }}</strong></h4>
                    <span class="ml-2">{{calculateAllPoints()}} / {{allPoints}}</span>
                    <h4 class="mt-3"><strong>{{ $t('correct-test-attempt.min-percent') }}</strong></h4>
                    <span class="ml-2">{{attempt.test.min_pass_percentage}}%</span>
                    <h4 class="mt-3"><strong>{{ $t('correct-test-attempt.percent') }}</strong></h4>
                    <span class="ml-2" :style="{color: (calculateAllPoints() / allPoints * 100) >= attempt.test.min_pass_percentage ? 'green' : 'red'}">{{(Math.floor(calculateAllPoints() / allPoints * 100))}}%</span>
                    <br>
                    <button v-if="!attempt.corrected" type="submit" class="btn btn-secondary mt-3" :disabled="submitLoading" @click="submit">{{ $t('correct-test-attempt.end-fixing') }}</button>
                </div>
            </div>
            <loading v-else />
        </div>
    </div>
</template>
<script>
import Loading from '@/components/Loading'
export default {
    name: 'CorrectTestAttempt',
    components: {
        Loading
    },
    mounted() {
        this.loading = true
        this.axios.get('/test-attempts/'+this.$route.params.testAttempt+'/correct')
            .then(resp => {
                this.attempt = resp.data.testAttempt
                this.attempt.questions.forEach(question => {
                    if(question.question_type == 'Free text') {
                        question.givenPoints = question.userAnswers.length > 0 ? (Number(question.userAnswers[0].points) ?? 0) : 0
                    }
                })
            })
            .catch(e => {
                this.$router.push({name: 'Home'})
            })
            .finally(() => this.loading = false)
    },
    data() {
        return {
            attempt: null,
            loading: false,
            submitLoading: false
        }
    },
    computed: {
        allPoints() {
            let sum = 0;
            this.attempt.questions.forEach((q) => {
                sum += q.max_points
            })

            return sum
        }
    },
    methods: {
        calculatePoints(question) {
            return this.attempt.points.find(a => a.test_question_id == question.id)?.points
        },
        calculateAllPoints() {
            let sum = 0;
            this.attempt.questions.forEach(q => {
                sum += this.calculatePoints(q)
            })
            return sum;
        },
        assignPoints(currentQuestion) {
            this.submitLoading = true
            this.axios.post('test-attempts/'+this.attempt.id+'/answer/'+currentQuestion.userAnswers[0].id+'/points', {points: currentQuestion.givenPoints})
                .then(resp => currentQuestion.userAnswers[0] = resp.data.answer)
                .finally(() => this.submitLoading = false)
        },
        submit() {
            this.submitLoading = true
            this.axios.post('test-attempts/'+this.attempt.id+'/correct')
                .then(resp => this.$router.push({name: 'AssignmentsToReturn'}))
                .finally(() => this.submitLoading = false)
        }
    }
}
</script>
<style scoped>
.playbtn {
    position: absolute; 
    left: 50%; top: 50%; 
    transform: translate(-50%, -50%); 
    font-size: 70px; 
    color:white;
}
.playbtn:hover {
    color: grey;
}
.disabled {
    background-color: #e9ecef;
    cursor:not-allowed;
}
</style>