<template>
    <div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a @click.prevent="$router.push({name: 'Home'})" href="#">{{ $t('notifications.homepage') }}</a></li>
                <li class="breadcrumb-item" aria-current="page"><a @click.prevent="$router.push({name: 'Notifications'})" href="#">{{ $t('notifications.notifications') }}</a></li>
                <li class="breadcrumb-item active"><a>{{ $t('view-post.post') }}</a></li>
            </ol>
        </nav>
        <section id="timeline" v-if="postObj">
            <div class="shadow def-p col-xl-6 mt-5">
                <Post :postProp="postObj" @postDeleted="$router.push({name: 'Notifications'})" />
            </div>
        </section>
        <Loading v-else />
    </div>
</template>
<script>
import Post from '@/components/Post'
import Loading from '@/components/Loading'
export default {
    name: 'ViewPost',
    props: {
        post: String
    },
    components: {
        Post,
        Loading
    },
    mounted() {
        this.loading = true
        this.axios.get('/posts/'+this.post)
            .then(resp => {
                this.postObj = resp.data.post
            })
            .catch(e => console.log(e))
            .finally(this.loading = false)
    },
    data() {
        return {
            postObj: null,
            loading: true
        }
    }
}
</script>
