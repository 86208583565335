<template>
    <div>
        <div class="col-12 mt-2">
            <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('settings.qualifications') }} </h3>
        </div>
        <div v-if="userQualifications !== null" class="col-12 mt-3">
            <div v-for="userQualification in userQualifications" :key="userQualification.id" class="d-flex">
                <a class="text-primary" style="cursor: pointer" data-toggle="modal" :data-target="`#viewUserQualificationModal-${userQualification.id}`">{{userQualification.name}} <i class="fas fa-eye"></i></a>
                <view-user-qualification-modal :qualification="userQualification" />
            </div>
            <div v-if="userQualifications.length === 0">
                {{$t('user-qualifications.no-quals-admin')}}
            </div>
        </div>
        <div class="col-12 mt-2 justify-content-center w-100" v-else>
            <loading height="100%" />
        </div>
    </div>
</template>
<script>
import Loading from '../../Loading.vue';
import ViewUserQualificationModal from './ViewUserQualificationModal.vue';
export default {
    components: { 
        Loading, 
        ViewUserQualificationModal,
    },
    props: {
        userId: Number
    },
    name: 'UserQualificationList',
    created() {
        this.axios.get('/users/'+this.userId+'/qualifications')
            .then(resp => this.userQualifications = resp.data.user_qualifications)
            .catch(e => console.log(e))
            .finally(() => this.loading = false)
    },
    data() {
        return {
            userQualifications: null,
            loading: true
        }
    }
}
</script>