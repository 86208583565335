<template>
    <div class="d-flex align-items-center">
        <button type="button" :disabled="certificateLoading" class="btn btn-outline-primary mb-2" @click.prevent="downloadCertificate">
            <div class="d-flex align-items-center">
                <i class="fas fa-award mr-1"></i>
                <span>{{$t('course.download')}}</span>
            </div>
        </button>
        <div v-if="certificateLoading && spinner" class="spinner-border text-primary ml-2 mb-2" role="status">
            <span class="sr-only">{{$t('create-far.loading')}}</span>
        </div>
    </div>
</template>
<script>
import { Capacitor } from '@capacitor/core';
export default {
    name: 'FarContractDownload',
    props: {
        user: {
            type: Number,
            optional: true,
            default: null
        },
        course: Number,
        spinner: {
            type: Boolean,
            optional: true,
            default: true
        }
    },
    data() {
        return {
            certificateLoading: false
        }
    },
    methods: {
        downloadCertificate() {
            this.certificateLoading = true;
            let params = {}
            if(this.user) params.user = this.user
            this.axios.get('/courses/'+this.course+'/certificate', {responseType: 'blob', params})
                .then(resp => {
                    if(Capacitor.isNative) {
                        this.$store.commit('downloadFileMobile', {name: resp.headers['x-file-name'], mime: 'application/pdf', data: resp.data})
                    }
                    else {
                        const url = window.URL.createObjectURL(new Blob([resp.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', resp.headers['x-file-name']);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
                .catch(e => console.log(e.response))
                .finally(() => this.certificateLoading = false)
        },
    }
}
</script>
