<template>
  <div id="professional_history">
    <div class="row align-items-end">
      <h1 class="ps-2 col">{{ $t("settings.professional-history") }}</h1>
      <img
        class="col"
        src="@/assets/img/settings/professional-history.png"
        alt=""
      />
      <div class="col" />
    </div>
    <form class="mt-5" @submit.prevent="setProfessionalHistory">
      <!--      <h2 class="mt-5 mb-5">Illetékes Kamara adatoka</h2>-->
      <div class="form-group">
        <label>{{ $t("settings.job-sector") }}</label>
        <Select2
          v-model="professionalHistoryData.job_sector"
          :options="jobSectors"
        />
      </div>
      <div class="form-group">
        <label>{{ $t("settings.skills-and-qualifications") }}</label>
        <div
          v-if="
            professionalHistoryErrors &&
            professionalHistoryErrors['skills_and_qualifications']
          "
        >
          <span
            v-for="(error, idx) in professionalHistoryErrors[
              'skills_and_qualifications'
            ]"
            style="color: red"
            :key="idx"
            >{{ error }}</span
          >
        </div>
        <input
          type="text"
          :placeholder="$t('settings.sqq')"
          class="form-control"
          v-model="professionalHistoryData.skills_and_qualifications"
        />
      </div>
      <div class="form-group">
        <label>{{ $t("settings.work-experience") }}</label>
        <div
          v-if="
            professionalHistoryErrors &&
            professionalHistoryErrors['work_experience']
          "
        >
          <span
            v-for="(error, idx) in professionalHistoryErrors['work_experience']"
            style="color: red"
            :key="idx"
            >{{ error }}</span
          >
        </div>
        <input
          type="text"
          :placeholder="$t('settings.wq')"
          v-model="professionalHistoryData.work_experience"
          class="form-control"
          :style="{
            border:
              professionalHistoryErrors &&
              professionalHistoryErrors['work_experience']
                ? '1px solid red'
                : '1px solid #ced4da',
          }"
          required
        />
      </div>
      <div class="form-group">
        <label>{{ $t("settings.studio") }}</label>
        <input
          type="text"
          class="form-control"
          :placeholder="$t('settings.studio')"
          v-model="professionalHistoryData.studio"
          :style="{
            border:
              professionalHistoryErrors && professionalHistoryErrors['studio']
                ? '1px solid red'
                : '1px solid #ced4da',
          }"
          required
        />
      </div>
      <div class="form-group">
        <label>{{ $t("settings.current-position") }}</label>
        <input
          type="text"
          v-model="professionalHistoryData.current_position"
          class="form-control"
          :style="{
            border:
              professionalHistoryErrors &&
              professionalHistoryErrors['current_position']
                ? '1px solid red'
                : '1px solid #ced4da',
          }"
          required
        />
      </div>
      <div class="form-group">
        <label>{{ $t("settings.current-ems-certification") }}</label>
        <input
          type="text"
          v-model="professionalHistoryData.current_ems_certification"
          class="form-control"
          :style="{
            border:
              professionalHistoryErrors &&
              professionalHistoryErrors['current_ems_certification']
                ? '1px solid red'
                : '1px solid #ced4da',
          }"
        />
      </div>
      <div class="form-group">
        <label>{{ $t("settings.device") }}</label>
        <div
          v-if="
            professionalHistoryErrors && professionalHistoryErrors['device']
          "
        >
          <span
            v-for="(error, idx) in professionalHistoryErrors['device']"
            style="color: red"
            :key="idx"
            >{{ error }}</span
          >
        </div>
        <Select2 v-model="professionalHistoryData.device" :options="devices" />
      </div>
      <div class="d-flex mt-5 justify-content-end">
        <PrimaryButton
          :disabled="loading"
          icon="save"
          :click="setProfessionalHistory"
          right-icon
          :text="$t('settings.save')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import PrimaryButton from "./PrimaryButton.vue";

export default {
  components: {
    PrimaryButton,
    Select2,
  },
  props: {
    errors: {
      type: Object,
      optional: true,
      default: null,
    },
    withSave: {
      type: Boolean,
      optional: true,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      localErrors: {},
      jobSectors: [
        { id: "Business", text: this.$t("job-sectors.Business") },
        { id: "Beauty", text: this.$t("job-sectors.Beauty") },
        { id: "Fitness", text: this.$t("job-sectors.Fitness") },
        { id: "Medical", text: this.$t("job-sectors.Medical") },
        {
          id: "Professional sports",
          text: this.$t("job-sectors.Professional sports"),
        },
        { id: "Physiotherapy", text: this.$t("job-sectors.Physiotherapy") },
      ],
      professionalHistoryData: { job_sector: null, device: null },
      devices: ["Newave USA", "Newave", "Newave Med", "Actiwave"],
    };
  },
  created() {
    this.axios
      .get("/professional-history")
      .then((resp) => {
        resp.data.professional_history &&
          (this.professionalHistoryData = resp.data.professional_history);
      })
      .catch((e) => console.log(e))
      .finally(() => (this.loading = false));
  },
  computed: {
    professionalHistoryErrors() {
      return this.errors ?? this.localErrors;
    },
  },
  methods: {
    setProfessionalHistory() {
      this.loading = true;
      this.axios
        .post(
          "/professional-history",
          this.$store.getters.removeEmptyOrNull(this.professionalHistoryData)
        )
        .then((resp) => {
          this.professionalHistoryData = resp.data.professional_history;
          this.loading = false;
        })
        .catch((e) => (this.localErrors = e.response.data.errors))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
#professional_history {
  padding: 40px 25px;
}

h1,
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #333e48;
}

/* START BUTTONS */

button.red-button span {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-right: 15px;
}

::v-deep button.red-button .icon svg path {
  fill: #fff !important;
}

/* END BUTTONS */

/* START FORM */

form .form-group label {
  color: #333e48;
  font-size: 14px;
  font-weight: 500;
}
.form-group
  div
  span.select2.select2-container.select2-container--default.select2-container--below.select2-container--focus {
  min-width: 100% !important;
  max-width: 100% !important;
}
form .form-group .form-control {
  border: 1px solid #928c88;
}

#professional_history .row img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
</style>
