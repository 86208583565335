<template>
<div>
        <section v-if="user.hasActiveMembership == true && user.role!='User'" id="feed-actions">
            <div class="row">
                <div class="col-12">
                    <div class="flex-wrap d-flex">
                        <div class="d-flex align-items-center pointer mr-auto"  v-if="!showUnapprovedOnly"  data-toggle="modal" data-target="#newPostModal">
                            <div class="new-button-60 d-flex align-items-center justify-content-center">
                                <img src="assets/img/icons/plus.svg" class="img-fluid" alt="">
                            </div>
                            <div class="d-flex flex-column ml-2">
                                <h6 class="text-18">{{ $t('feed.new-post') }}</h6>
                            </div>
                        </div>

                        <!-- NEW POST Modal -->
                        <div v-if="user.hasActiveMembership == true && user.role!='User'" class="modal fade" id="newPostModal" tabindex="-1" role="dialog" aria-labelledby="newPostModalLabel" ref="newPostModal"
                            aria-hidden="true">
                            <div class="modal-dialog custom-input" role="document">
                                <div class="modal-content">
                                    <div class="modal-header text-left">
                                        <h5 class="post-title w-100" id="newPostModalLabel">{{ $t('feed.new-entry') }}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body w-100">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="flex-wrap d-flex">
                                                    <div class="d-flex mr-auto minus-2 align-items-center">
                                                        <img v-if="$store.state.user.avatar" :src="$store.state.user.avatar.path+'?token='+$store.state.token" style="width: 56px;" class="avatar-rounded white-border mr-2" alt="#">
                                                        <Avatar v-else :username="$store.state.user.name" :size="56" class="avatar-rounded white-border mr-2" />
                                                        <div class="d-flex flex-column mt-1">
                                                            <h4 class="text-light-black text-21 bold mb-1">{{$store.state.user.name}}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 modal-message mt-4">
                                                <div class="form-group">
                                                    <textarea :placeholder="$t('feed.new-post-content')" class="form-control"
                                                        id="newPostModalTextarea"
                                                        style="width: 100%"
                                                        v-model="content"
                                                        :disabled="sending"></textarea>
                                                        <div class="text-right"><sub>{{content ? content.length : 0}} / 2000</sub></div>
                                                </div>
                                            </div>
                                          <div class="col-12">
                                              <div class="form-check">
                                                <input type="checkbox"  v-model="lang" true-value="all" false-value="" :disabled="sending" class="form-check-input" id="all_lang">
                                                <label class="form-check-label" for="all_lang">{{$t('feed.all-lang')}}</label>
                                              </div>
                                          </div>
                                            <div v-if="showPollCreate" class="col-12 mb-4">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <label>{{ $t('feed.vote-name') }}</label>
                                                        <input v-model="poll.name" type="text" class="form-control w-100 mb-3">
                                                    </div>
                                                    <div class="col-12">
                                                        <label>{{ $t('feed.question') }}</label>
                                                        <input v-model="poll.question" type="text" class="form-control w-100 mb-3">
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label>{{ $t('feed.vote-type') }}</label>
                                                        <select v-model="poll.poll_type_id" class="form-control w-100 mb-3">
                                                            <option v-for="type in pollTypes" :value="type.id" :key="type.id">{{$t(`question-type.${type.type}`)}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label>{{ $t('feed.anonym') }}</label>
                                                        <select v-model="poll.anonym" class="form-control w-100 mb-3">
                                                            <option :value="1">{{ $t('feed.yes') }}</option>
                                                            <option :value="0">{{ $t('feed.no') }}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-12">
                                                        <div v-if="poll.poll_type_id != 3" class="w-100" v-for="(answer, index) in poll.poll_answers" :key="index">
                                                            <label>{{ $t('feed.option') }} #{{index+1}}</label>
                                                            <div class="remove d-flex align-items-center mb-3">
                                                                <input type="text" v-model="poll.poll_answers[index].answer" class="form-control w-100 mr-2">
                                                                <span @click="poll.poll_answers.splice(index, 1)" style="cursor: pointer"><i class="fas fa-minus-circle font-25" style="color: var(--red);"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                              <TransparentButton v-show="poll.poll_type_id !== 3" :click="()=>poll.poll_answers.push({answer: null})" :text="$t('feed.new-option')" />
                                            </div>
                                            <div class="col-12">
                                                <section id="media-buttons">
                                                    <div class="row">
                                                        <div class="col-lg-6 mb-3" :disabled="sending" @click="$refs.imageUpload.click()">
                                                            <div class="flex-wrap d-flex">
                                                                <input type="file" accept="image/png, image/jpeg, image/jpg, image/gif" id="uploadImage" ref="imageUpload" @change="createUpload('image')" style="display: none;">
                                                                <div class="d-flex align-items-center pointer mr-auto">
                                                                    <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                                        <img src="assets/img/icons/white-camera.svg" class="img-fluid ml-1" alt="">
                                                                    </div>
                                                                    <div class="d-flex flex-column ml-2">
                                                                        <h6 class="text-16 text-dark-gray">{{ $t('feed.attach-pic') }}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 mb-3" :disabled="sending" @click="$refs.videoUpload.click()">
                                                            <div class="flex-wrap d-flex">
                                                                <input type="file" id="uploadVideo" accept="video/mp4, video/webm, video/avi" ref="videoUpload" @change="createUpload('video')" style="display: none;">
                                                                <div class="d-flex align-items-center pointer mr-auto">
                                                                    <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                                        <img src="assets/img/icons/videocam.svg" width="25" class="img-fluid ml-1" alt="">
                                                                    </div>
                                                                    <div class="d-flex flex-column ml-2">
                                                                        <h6 class="text-16 text-dark-gray">{{ $t('feed.attach-vid') }}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 mb-3" :disabled="sending" @click="$refs.audioUpload.click()">
                                                            <div class="flex-wrap d-flex">
                                                                <input type="file" id="uploadAudio" accept="audio/mp3, audio/mpeg, audio/wav" ref="audioUpload" @change="createUpload('audio')" style="display: none;">
                                                                <div class="d-flex align-items-center pointer mr-auto">
                                                                    <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                                        <img src="assets/img/icons/microphone.svg" class="img-fluid" alt="">
                                                                    </div>
                                                                    <div class="d-flex flex-column ml-2">
                                                                        <h6 class="text-16 text-dark-gray">{{ $t('feed.attach-sound') }}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 mb-3" :disabled="sending" @click="$refs.documentUpload.click()">
                                                            <div class="flex-wrap d-flex">
                                                                <input type="file" id="uploadDocument" accept=".doc, .docx, .pdf, .xls, .xlsx, .ods, .ppt, .pptx, .txt"  ref="documentUpload" @change="createUpload('document')" style="display: none;">
                                                                <div class="d-flex align-items-center pointer mr-auto">
                                                                    <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                                        <img src="assets/img/icons/file.svg" class="img-fluid ml-1" alt="">
                                                                    </div>
                                                                    <div class="d-flex flex-column ml-2">
                                                                        <h6 class="text-16 text-dark-gray">{{ $t('feed.attach-doc') }}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                              <TransparentButton v-if="!showPollCreate" :disabled="sending" :click="()=>(showPollCreate = true)" :text="$t('feed.add-voting')"/>
                                              <PrimaryButton v-if="showPollCreate" :disabled="sending" :click="()=>(showPollCreate = false)" :text="$t('feed.cancel-voting')"/>
                                            </div>
                                            <div v-if="files.length > 0" class="col-12">
                                                <h5 class="mb-2 mt-4">{{ $t('feed.uploads') }}</h5>
                                                <div class="row">
                                                    <div v-for="(file) in files" :key="file.id" class="col-12 mb-3">
                                                        <Upload v-if="file.shown" :file="file.file" :type="file.type" :id="file.id" @uploadFinished="finishUpload" @uploadCancelled="cancelUpload"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                      <PrimaryButton :disabled="!canSend" :click="submit" :text="$t('feed.share')"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex align-items-center pointer">
                            <div class="d-flex flex-column white-bg pr-4 filter-dropdown">
                                <select v-model="filter" class="form-control ">
                                    <option :value="null" class="form-control">{{ $t('feed.all') }}</option>
                                    <option :value="1" class="form-control">{{ $t('feed.today') }}</option>
                                    <option :value="2" class="form-control">{{ $t('feed.yesterday') }}</option>
                                    <option :value="3" class="form-control">{{ $t('feed.month') }}</option>
                                    <option :value="4" class="form-control">{{ $t('feed.year') }}</option>
                                </select>
                            </div>
                            <div class="d-flex flex-column">
                                <div class="new-button-50 d-flex align-items-center justify-content-center">
                                    <img src="assets/img/icons/filter.svg" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section v-else >
          <div class="row">
            <div class="col-12">
              <h2 class="text-18 text-dark-gray semi-bold mb-3">{{$t('feed.timeline')}}</h2>
            </div>
          </div>
        </section>

        <div v-if="posts.length > 0 && !loading">
            <div v-for="post in posts" :key="post.id" class="m-top">
                <div v-if="!post.deleted" >
                    <Post @postDeleted="post.deleted = true" :postProp="post" />
                </div>
            </div>
            <div class="mt-2 d-flex justify-content-center">
                <infinite-scroll @shouldLoad="loadPosts" :shouldLoad="paginator.current_page != paginator.last_page" />
            </div>
        </div>
        <div v-else-if="loading">
            <Loading height="50vh" />
        </div>
        <div v-else class="col-12 mt-4">
            <h6 class="text-13 text-dark-gray semi-bold">{{ $t('feed.no-posts') }}</h6>
        </div>
    </div>
</template>
<style scoped>
    .vue-avatar--wrapper {
        z-index: 2;
    }
</style>
<script>
import Post from '@/components/Post'
import Loading from '@/components/Loading'
import Avatar from '@/components/Avatar'
import Upload from '@/components/Upload'
import InfiniteScroll from './InfiniteScroll.vue'
import TransparentButton from "./settings/TransparentButton.vue";
import PrimaryButton from "./settings/PrimaryButton.vue";
export default {
    name: 'Feed',
    components: {
      PrimaryButton,
      TransparentButton,
        Post,
        Loading,
        Avatar,
        Upload,
        InfiniteScroll
    },
    props: {
        courseId: {
            type: Number,
            default: null
        },
        showUnapprovedOnly: {
            type: Boolean,
            default: false
        }
    },
    mounted() {

        this.getPosts();
        this.axios.get('/poll-types').then(resp => {
            this.pollTypes = resp.data
        });
        //console.log(this.$store.state.locale);
        this.setLang(this.$store.state.locale);
    },
     data() {
        return {
            posts: [],
            filter: null,
            paginator: null,
            files: [],
            content: null,
            loading: true,
            lang:null,
            sending: false,
            showPollCreate: false,
            pollTypes: [],
            poll: {
                name: null,
                anonym: 0,
                question: null,
                poll_type_id: 1,
                poll_answers: [{answer: null}]
            }
        }
    },
    watch: {
        files() {
            return this.files
        },
        filter() {
            this.getPosts()
        }
    },
    computed: {
        queryParams() {
            return {
                filter: this.filter,
                showUnapprovedOnly: this.showUnapprovedOnly,
                courseId: this.courseId
            };
        },
        canSend() {
            return !this.files.find(file => file.uuid === null) && !this.sending
        },
      user() {
        return this.$store.state.user
      }
    },
    methods: {
        getPosts() {
            this.loading = true
            this.axios.get('/posts', {params: this.queryParams})
                .then(resp => {
                    this.loading = false
                    this.posts = resp.data.posts
                    this.paginator = resp.data.paginator
                })
                .catch(e => console.log(e))
                .finally(() => this.loading = false)
        },
      setLang(lang) {
          this.locale = lang;
      },
        createUpload(type) {
            if(event.target.files.item(0))
                this.files.push({file: event.target.files.item(0), type: type, shown: true, uuid: null, id: this.files.length})
        },
        finishUpload(id, multimedia) {
            this.files.find(file => file.id === id).uuid = multimedia.id
        },
        cancelUpload(id, uuid) {
            this.files.splice(this.files.indexOf(this.files.find(file => file.id === id)), 1)
        },
        loadPosts(state) {
            this.axios.get('/posts', {params: {page: this.paginator.current_page + 1}})
                .then(resp => {
                    this.posts.push(...resp.data.posts)
                    this.paginator = resp.data.paginator
                })
                .finally(() => state.finishLoad())
        },
        submit() {
            if(!this.sending) {
                let formData = {}
                const multimedia = this.files.filter(file => file.uuid !== null).map(file => file.uuid)
                if(this.content)
                    formData.content = this.content
                if(multimedia.length > 0)
                    formData.multimedia = multimedia
                if(this.showPollCreate === true) {
                    formData.poll = JSON.parse(JSON.stringify(this.poll))
                    formData.poll.poll_answers = formData.poll.poll_answers.map(a => a.answer)
                }
                if(this.courseId) {
                    formData.course_id = this.courseId
                }
                if(this.lang) {
                    formData.lang = this.lang
                }else {
                    formData.lang = this.$store.state.locale
                }

                console.log(formData)
                this.sending = true;

                this.axios.post('/posts', formData)
                    .then(resp => {
                        this.files = []
                        this.content = null
                        if(this.$store.state.user?.role === 'Admin') {
                            this.posts.unshift(resp.data.post)
                        }
                        $(this.$refs.newPostModal).modal('hide')
                    })
                    .catch(e => console.log(e.response))
                    .finally(() => this.sending = false)
            }
        }
    }
}
</script>
<style scoped>
    .feed-list {
        margin-top: 30px;
    }
    .new-button-50 img {
        height: 25px;
    }
    .white-bg {
        background-color: white;
        padding: 7px 15px;
        border-radius: 5px;
        margin-right: -10px;
    }
    .pointer h6 {
        font-weight: 600;
        color: rgba(84, 84, 84, 0.75);
    }
    .feed-list .feed-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 15px 15px 0px 15px;
    }
    .feed-list .feed-container {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 25px;
    }
    .minus-2 {
        margin-bottom: -12px;
    }
    .filter-dropdown select {
        border: none !important;
        box-shadow: none !important;
        height: 31px !important;
        font-size: 14px;
        width: 120%;
    }
    .filter-dropdown {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        margin-right: -30px !important;
    }
</style>
