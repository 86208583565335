<template>
  <div id="qualifications">
    <div class="d-flex align-items-center justify-content-center">
      <img src="@/assets/img/settings/qualification.png" alt="" />
    </div>
    <div class="d-flex align-items-center justify-content-between my-4">
      <h1 class="">{{ $t("user-qualifications.qualifications") }}</h1>
      <div
        class="add-button-section"
        data-toggle="modal"
        data-target="#createUserQualificationModal"
      >
        <p class="m-0">{{ $t("user-qualifications.add-new") }}</p>
        <IconButton icon="add" class="position-static" />
      </div>
    </div>

    <table>
      <thead>
        <tr>
          <th>
            <span>{{ $t("user-qualifications.name") }}</span>
          </th>
          <th>
            <span>{{ $t("user-qualifications.field") }}</span>
          </th>
          <th>
            <span>{{ $t("user-qualifications.qualification") }}</span>
          </th>
          <th>
            <span>{{ $t("settings.action") }}</span>
          </th>
        </tr>
      </thead>
      <tbody v-for="(userQualification, idx) in userQualifications" :key="idx">
        <tr>
          <td
            style="cursor: pointer"
            data-toggle="modal"
            :data-target="`#viewUserQualificationModal-${userQualification.id}`"
          >
            <span>{{ userQualification.name }}</span>
          </td>
          <td
            style="cursor: pointer"
            data-toggle="modal"
            :data-target="`#viewUserQualificationModal-${userQualification.id}`"
          >
            <span>{{ userQualification.field }}</span>
          </td>
          <td
            style="cursor: pointer"
            data-toggle="modal"
            :data-target="`#viewUserQualificationModal-${userQualification.id}`"
          >
            <span>
              {{ $t("qualifications." + userQualification.qualification.name) }}
            </span>
          </td>
          <td>
            <TransparentButton
              data-toggle="modal"
              :data-target="`#delete-qualification-modal-${userQualification.id}`"
              icon="delete-red"
              :text="$t('user-qualifications.delete')"
            />
          </td>
          <view-user-qualification-modal :qualification="userQualification" />
          <edit-user-qualifications-modal
            :userQualification="userQualification"
            @updated="updateQualification"
          />
          <generic-delete-modal
            :id="'delete-qualification-modal-' + userQualification.id"
            :boldItem="true"
            :item="userQualification.name"
            :itemDeletedReturnValue="userQualification.id"
            text="Are you sure you want to delete the following qualification: "
            title="Delete qualification"
            :url="'/user-qualifications/' + userQualification.id"
            @itemDeleted="removeQualification"
          />
        </tr>
      </tbody>
    </table>

    <div class="table-with-inputs mt-5">
      <h1
        v-if="userQualifications.length === 0"
        class="text-13 text-dark-gray semi-bold"
      >
        {{ $t("user-qualifications.no-quals") }}
      </h1>
    </div>
    <create-user-qualification-modal @created="addQualification" />
  </div>
</template>

<script>
import CreateUserQualificationModal from "../UserData/UserQualifications/CreateUserQualificationModal";
import GenericDeleteModal from "../GenericDeleteModal";
import ViewUserQualificationModal from "../UserData/UserQualifications/ViewUserQualificationModal";
import EditUserQualificationsModal from "../UserData/UserQualifications/EditUserQualificationsModal";
import IconButton from "./IconButton.vue";
import TransparentButton from "@/components/settings/TransparentButton.vue";

export default {
  components: {
    TransparentButton,
    IconButton,
    CreateUserQualificationModal,
    GenericDeleteModal,
    ViewUserQualificationModal,
    EditUserQualificationsModal,
  },
  data() {
    return {
      loading: false,
      userQualifications:
        this.$store.state.user && this.$store.state.user.user_qualifications
          ? this.$store.state.user.user_qualifications
          : [],
    };
  },
  created() {
    this.axios
      .get("/user-qualifications")
      .then((resp) => (this.userQualifications = resp.data.user_qualifications))
      .catch((e) => console.log(e))
      .finally(() => (this.loading = false));
  },
  methods: {
    addQualification(qualification) {
      this.userQualifications.push(qualification);
    },
    updateQualification(qualification) {
      const idx = this.userQualifications.findIndex(
        (q) => q.id === qualification.id
      );
      if (idx !== -1) {
        this.userQualifications[idx] = qualification;
      }
    },
    removeQualification(qualificationId) {
      this.userQualifications = this.userQualifications.filter(
        (q) => q.id !== qualificationId
      );
    },
  },
};
</script>

<style scoped>
#qualifications {
  padding: 40px 25px;
}

h1,
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #333e48;
}

/* START FORM */

form .form-group label {
  color: #333e48;
  font-size: 14px;
  font-weight: 500;
}

form .form-group .form-control {
  border: 1px solid #928c88;
  border-radius: 5px;
}
.table-with-inputs .head > div {
  background-color: #cecece;
  border: 1px solid #333e48;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-with-inputs .head > div span {
  font-weight: 500;
  font-size: 20px;
  color: #333e48;
}

.table-with-inputs .table-input {
  border: 1px solid #cecece;
  background-color: #fff;
  padding: 15px;
}

.table-with-inputs .remove-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-with-inputs .table-input .form-control {
  border: none;
  color: #333e48;
  font-size: 14px;
  font-weight: 500;
}

.table-with-inputs .table-input .rounded-red {
  margin-top: 0;
  margin-right: 0;
}

.add-button-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-button-section p {
  font-size: 18px;
  font-weight: 500;
  color: #837e7f;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

thead {
  background-color: #cecece;
  border-radius: 5px;
}

th {
  font-weight: 500;
  font-size: 15px;
  color: #333e48;
  padding: 10px;
  border: solid 1px #333e48;
  border-right: none;
  text-align: center;
}

th:first-of-type {
  border-top-left-radius: 5px;
}

th:last-of-type {
  border-top-right-radius: 5px;
  border-right: solid 1px #333e48;
}

tbody tr {
  border: 1px solid #cecece;
  background-color: #fff;
}

tbody tr:first-of-type {
  border-top: none;
}

tbody tr td {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #928c88;
  border: solid 1px #cecece;
  border-right: none;
  text-align: center;
}

tbody tr td .secondary-button {
  margin: 0 auto;
}

tbody tr td:last-of-type {
  border-right: solid 1px #cecece;
}
</style>
