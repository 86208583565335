<template>
    <div v-if="!loading" class="mt-3">
        <h3>{{ $t('create-far.adult') }}</h3>
        <p>{{ $t('create-far.adult-lore') }}</p>
        <p>{{ $t('create-far.adult-data') }}</p>
          <form @submit.prevent="setFarContractData" class="w-100 row">
            <div class="col-6 pr-2 mt-2">
                <label>{{ $t('create-far.lastname') }} </label>
                <div v-if="errors['last_name']">
                    <span v-for="(error, idx) in errors['last_name']" style="color: red;" :key="idx">{{error}}</span>
                </div> 
                <input :placeholder="$t('settings.curr-last')" class="form-control" v-model="farContractData.last_name" required :style="{border: errors['last_name'] ? '1px solid red' : '1px solid #ced4da'}">
            </div>
            <div class="col-6 pl-1 mt-2">
                <label>{{ $t('create-far.firstname') }} </label>
                <div v-if="errors['first_name']">
                    <span v-for="(error, idx) in errors['first_name']" style="color: red;" :key="idx">{{error}}</span>
                </div> 
                <input type="text" :placeholder="$t('settings.curr-first')" class="form-control" v-model="farContractData.first_name" required :style="{border: errors['first_name'] ? '1px solid red' : '1px solid #ced4da'}">
            </div>
            <div class="col-12 mt-2">
                <label>{{ $t('settings.birth-name') }} </label>
                <div v-if="errors['birth_name']">
                    <span v-for="(error, idx) in errors['birth_name']" style="color: red;" :key="idx">{{error}}</span>
                </div>    
                <input type="text" :placeholder="$t('settings.birth-name')" class="form-control" v-model="farContractData.birth_name" required :style="{border: errors['birth_name'] ? '1px solid red' : '1px solid #ced4da'}">
            </div>
            <div class="col-12 mt-2">
                <label>{{ $t('settings.mother-name') }} </label>
                <div v-if="errors['mothers_name']">
                    <span v-for="(error, idx) in errors['mothers_name']" style="color: red;" :key="idx">{{error}}</span>
                </div> 
                <input :placeholder="$t('settings.mother-name')" type="text" class="form-control" v-model="farContractData.mothers_name" required :style="{border: errors['mothers_name'] ? '1px solid red' : '1px solid #ced4da'}">
            </div>
                
            <div class="col-6 pr-2 mt-2">
                <label>{{ $t('settings.birth-place') }} </label>
                <div v-if="errors['birth_place']">
                    <span v-for="(error, idx) in errors['birth_place']" style="color: red;" :key="idx">{{error}}</span>
                </div>    
                <input :placeholder="$t('settings.birth-place')" type="text" class="form-control" v-model="farContractData.birth_place" required :style="{border: errors['birth_place'] ? '1px solid red' : '1px solid #ced4da'}">
            </div>
            <div class="col-6 pl-1 mt-2">
                <label>{{ $t('settings.birth-date') }} </label>
                <div v-if="errors['birth_date']">
                    <span v-for="(error, idx) in errors['birth_date']" style="color: red;" :key="idx">{{error}}</span>
                </div> 
                <datetime :placeholder="$t('settings.birth-date')" :zone="DateTime.local().zoneName" :valueZone="DateTime.local().zoneName" v-model="farContractData.birth_date" required class="form-control" :style="{border: errors['birth_date'] ? '1px solid red' : '1px solid #ced4da'}" />
            </div>
            <div class="col-12 mt-2">
                <label>{{ $t('settings.email') }} </label>
                <div v-if="errors['email']">
                    <span v-for="(error, idx) in errors['email']" style="color: red;" :key="idx">{{error}}</span>
                </div>    
                <input :placeholder="$t('settings.adult-address')" type="email" class="form-control" v-model="farContractData.email" required :style="{border: errors['email'] ? '1px solid red' : '1px solid #ced4da'}">
            </div>
            <div class="col-12 mt-2">
                <label>{{ $t('settings.educated') }}</label>
                <div v-if="errors['qualification_id']">
                    <span v-for="(error, idx) in errors['qualification_id']" style="color: red;" :key="idx">{{error}}</span>
                </div> 
                <select class="form-control" v-model="farContractData.qualification_id" :style="{border: errors['qualification_id'] ? '1px solid red' : '1px solid #ced4da'}">
                    <option disabled :value="null">{{$t('settings.choose-list')}}</option>
                    <option v-for="qualification in qualifications" :value="qualification.id" :key="qualification.id">{{$t(`qualifications.${qualification.name}`)}}</option>
                </select>
            </div>
            <div class="col-12 mt-2">
                <label>{{ $t('settings.om') }}</label>
                <div v-if="errors['educational_id']">
                    <span v-for="(error, idx) in errors['educational_id']" style="color: red;" :key="idx">{{error}}</span>
                </div>
                <div>
                    <span v-if="regexErrors['educational_id']" style="color: red;">{{regexErrors['educational_id']}}</span>
                </div>
                <input type="number" :placeholder="$t('create-far.empty')" class="form-control" v-model="farContractData.educational_id" :style="{border: errors['educational_id'] || regexErrors['educational_id'] ? '1px solid red' : '1px solid #ced4da'}">
            </div>
            <div class="col-12 mt-3">
                <div style="font-size: 18px; flex-basis: 100%" class="font-18 pb-2 d-flex align-items-center">
                    <input type="checkbox" :true-value="true" :false-value="false" style="width: 16px; height: 16px;" v-model="checkedData">
                    <span class="ml-2">{{ $t('create-far.valid') }}</span>
                </div>
                <button type="submit" :disabled="submitting || !checkedData" class="btn btn-save">{{ $t('settings.save') }}</button>
            </div>
        </form>
        <!-- Modal -->
        <div class="modal fade" ref="contractPdfModal" id="contractPdfModal" data-backdrop="static" tabindex="-1" aria-labelledby="contractPdfModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="contractPdfModalLabel">{{ $t('create-far.contract') }}</h5>
            </div>
            <div class="modal-body" style="height: 65vh" ref="modalBody">
                <div v-if="submitting && !contractPdfData" class="d-flex align-items-center justify-content-center" style="height: 100%">
                    <div class="mr-2">{{ $t('create-far.contract-generate') }}</div>
                    <div class="spinner-border text-primary spinner-border-sm" role="status">
                        <span class="sr-only">{{ $t('create-far.loading') }}</span>
                    </div>
                </div>
                <div v-else style="height: 100%">
                    <object v-if="contractPdfData && defaultViewer" :data="contractPdfData" type="application/pdf" height="100%" width="100%" name="Szerzodes">
                            <!-- If object is not supported, use embed -->
                            <embed
                                v-if="contractPdfData"
                                :src="contractPdfData"
                                type="application/pdf"
                                frameBorder="0"
                                scrolling="auto"
                                height="100%"
                                width="100%"
                            >
                    </object>
                    <div style="height: 100%" v-else>
                        <div class="w-90">
                            <button :disabled="rendering" class="btn btn-primary" @click.prevent="pdfScale += 0.2">Nagyítás</button>
                            <button :disabled="rendering" class="btn btn-primary ml-2" @click.prevent="pdfScale -= 0.2">Kicsinyítés</button>
                        </div>
                        <div class="mb-2 mt-2 w-100" style="height: calc(100% - 38px); overflow-y: scroll" id="the-canvas">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div :class="!defaultViewer ? 'mt-5' : ''" style="font-size: 18px; flex-basis: 100%" class="font-18 pb-2 d-flex align-items-center">
                    <input type="checkbox" :true-value="true" :false-value="false" style="width: 16px; height: 16px;" v-model="acceptedContract">
                    <span class="ml-2">{{ $t('create-far.contract-check') }}</span>
                </div>
                <button type="button" class="btn btn-secondary" :disabled="submitting" data-dismiss="modal">{{ $t('create-far.cancel') }}</button>
                <button type="button" class="btn btn-primary" @click.prevent="create" :disabled="!acceptedContract || submitting">
                    <span v-if="!submitting">{{ $t('create-far.signature') }}</span>
                    <div v-else class="d-flex align-items-center justify-content-center" style="height: 100%">
                        <div class="mr-2">{{ $t('create-far.wait') }}</div>
                        <div class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">{{ $t('create-far.loading') }}</span>
                        </div>
                    </div>
                </button>
            </div>
            </div>
        </div>
        </div>
    </div>
    <loading v-else height="80vh" />
</template>
<script>
import { Capacitor } from '@capacitor/core';
import * as pdf from 'pdfjs-dist'
import worker from 'pdfjs-dist/build/pdf.worker.entry'
import Loading from '../components/Loading.vue'
import Datetime from  '../components/DateTimePicker/Datetime'
import { DateTime } from 'luxon'
export default {
    name: 'CreateFarContract',
    components: {Loading, Datetime},
    mounted() {
        this.defaultViewer = !(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || Capacitor.isNative);
        this.pdfScale = 1
        this.axios.get('/qualifications')
            .then(resp => this.qualifications = this.qualifications.concat(resp.data))

        this.axios.get('/far-contracts/'+this.$route.params.course+'/create')
            .catch(e => this.$router.push({name: 'Course', params: {id: this.$route.params.course}}))
            .finally(() => this.loading = false)
    },
    data() {
        return {
            loading: true,
            DateTime: DateTime,
            farContractData: this.$store.state.user && this.$store.state.user.far_contract_data ? {
                birth_name: this.$store.state.user.far_contract_data.birth_name,
                birth_place: this.$store.state.user.far_contract_data.birth_place,
                mothers_name: this.$store.state.user.far_contract_data.mothers_name,
                birth_date: this.$store.state.user.far_contract_data.birth_date,
                first_name: this.$store.state.user.far_contract_data.first_name,
                last_name: this.$store.state.user.far_contract_data.last_name,
                qualification_id: this.$store.state.user.far_contract_data.qualification_id,
                educational_id: this.$store.state.user.far_contract_data.educational_id,
                email: this.$store.state.user.far_contract_data.email
            } : { qualification_id: null},
            qualifications: [],
            errors: Object(),
            regexErrors: Object(),
            contractPdfData: null,
            submitting: false,
            checkedData: false,
            acceptedContract: false,
            defaultViewer: true,
            pdfScale: 0.5,
            rendering: false
        }
    },
    watch: {
        contractPdfData(val) {
            if(val && !this.defaultViewer) this.drawPDFonCanvas(val)
        },
        pdfScale() {
            if(this.contractPdfData && !this.defaultViewer)
                this.drawPDFonCanvas(this.contractPdfData)
        }
    },
    methods: {
        setFarContractData() {
            this.submitting = true
            this.contractPdfData = null
            this.acceptedContract = false
            this.farContractData.birth_date = DateTime.fromISO(this.farContractData.birth_date).toFormat('yyyy-MM-dd')
            this.axios.post('/far-contract-data', this.farContractData)
                .then(async (resp) => {
                    this.$store.state.user = resp.data.user
                    $(this.$refs.contractPdfModal).modal('show')
                    const pdf = await this.axios.get('/far-contracts/'+this.$route.params.course+'/preview', {responseType: 'blob'})
                    let fileReader = new FileReader()
                    fileReader.readAsDataURL(pdf.data)
                    fileReader.onload = (e) => {
                        this.submitting = false
                        this.contractPdfData = e.target.result
                    }
                })
                .catch(e => {
                    console.log(e)
                    this.errors = e.response.data.errors
                })
                .finally(() => this.submitting = false)
        },
        create() {
            this.submitting = true
            this.axios.post('/far-contracts/'+this.$route.params.course)
                .then(resp => {
                    $(this.$refs.contractPdfModal).modal('hide')
                    this.$router.push({name: 'ViewCourse', params: {id: this.$route.params.course}})
                })
                .finally(() => this.submitting = false)
        },
        downloadPDF() {
            this.pdfLoading = true;
            this.axios.get('/far-contracts/'+this.$route.params.course+'/preview', {responseType: 'blob'})
                .then(resp => {
                    const url = window.URL.createObjectURL(new Blob([resp.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'activity-log.pdf');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(e => console.log(e.response))
                .finally(() => this.pdfLoading = false)
        },
        convertDataURIToBinary(dataURI) {
            var base64Index = dataURI.indexOf(';base64,') + ';base64,'.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));

            for(var i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        },
        drawPDFonCanvas(pdfData) {
            this.rendering = true
            const mb = this.$refs.modalBody.getBoundingClientRect()
            console.log(mb.innerWidth)
            pdfData = this.convertDataURIToBinary(pdfData)
            // Loaded via <script> tag, create shortcut to access PDF.js exports.
            var pdfjsLib = pdf

            // Using DocumentInitParameters object to load binary data.
            pdfjsLib.GlobalWorkerOptions.workerSrc = worker
            var loadingTask = pdfjsLib.getDocument({data: pdfData});
            loadingTask.promise.then((pdf) => {
                const scale = this.pdfScale
                const viewer = document.getElementById('the-canvas');
                
                // Fetch the first page
                for(let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                    let canvas = document.getElementById('page-'+pageNumber) ? document.getElementById('page-'+pageNumber) : document.createElement("canvas");    
                    canvas.className = 'pdf-page-canvas';
                    canvas.id ="page-"+pageNumber         
                    viewer.appendChild(canvas); 
                    pdf.getPage(pageNumber).then((page) => {
                        
                        var viewport = page.getViewport({scale: scale});

                        // Prepare canvas using PDF page dimensions
                        //var canvas = document.getElementById('the-canvas');
                        //canvas.height = mb.height
                        //canvas.width = mb.width
                        var context = canvas.getContext('2d');
                        canvas.width = viewport.width - 15;
                        canvas.height = viewport.height;

                        // Render PDF page into canvas context
                        var renderContext = {
                        canvasContext: context,
                        viewport: viewport
                        };
                        var renderTask = page.render(renderContext);
                        renderTask.promise.then( () =>{
                            this.rendering = false
                        });
                    });
                }
            });
        }
    }
    
}
</script>
