<template>
  <div>
    <section id="content-2">
      <Carousel/>
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <section id="timeline">
              <Feed/>
            </section>
          </div>
          <div class="col-lg-6">
            <section id="knowledge-share">
              <div class="row mb-4">
                <div class="col-12">
                  <div class="flex-wrap d-flex">
                    <div class="d-flex align-items-center pointer mr-auto" @click="$router.push({name: 'FastShare'})">
                      <div class="new-button-60 d-flex align-items-center justify-content-center">
                        <img src="assets/img/icons/plus.svg" class="img-fluid" alt="">
                      </div>
                      <div class="d-flex flex-column ml-2">
                        <h6 class="text-18">{{ $t('dla-user-home.share-knowledge') }}</h6>
                      </div>
                    </div>
                    <div class="d-flex align-items-center pointer ms-auto"
                         v-on:click.prevent="$router.push({name: 'ManageUsers'})">
                      <div class="d-flex flex-column mr-2">
                        <h6 class="text-18">{{ $t('master-home.manage-team') }}</h6>
                      </div>
                      <div class="new-button-60 d-flex align-items-center justify-content-center">
                        <i style="color: white;font-size: 21px;" class="fas fa-user-friends"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="team-list">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('master-home.members') }}</h2>
                </div>
                <div class="col-12" id="members">
                  <!-- MEMBER CARD -->
                  <div class="member-little-card bg-light-gray-secondary filter-shadow mb-3"
                       v-for="teamMember in $store.state.user.subordinates" :key="teamMember.id" @click="$router.push({name: 'Overview', params: {id: teamMember.id}})">
                    <div class="flex-wrap d-flex ">
                      <div class="d-flex mr-auto align-items-center align-items-cente">
                        <img src="assets/img/avatar.png" width="43px" class="avatar-rounded white-border mr-2" alt="">
                        <div class="d-flex flex-column mt-1">
                          <h4 class="text-white text-16 bold mb-1">{{ teamMember.name }}</h4>
                        </div>
                      </div>
                      <div class="d-flex ">
                        <img src="assets/img/icons/more-info.svg" class="pointer mt-2" alt="">
                      </div>
                    </div>
                  </div>
                  <!-- MEMBER CARD -->
                </div>
                <div class="col-12 mb-3" v-if="$store.state.user.subordinates.length == 0">
                  <h6 class="text-13 text-dark-gray semi-bold">{{ $t('master-home.no-team') }}</h6>
                </div>
              </div>
            </section>
            <section id="trainings">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('master-home.suggested-courses') }}</h2>
                </div>
                <div v-for="course in recommendedCourses" :key="course.id"
                     class="col-md-6 mb-4 col-12"
                     style="cursor: pointer;"
                     @click="$router.push({name: 'Course', params: {id: course.id}})"
                >
                  <course-card :course="course"/>
                </div>
                <div v-if="recommendedCourses.length == 0" class="col-md-6 mb-4 col-12">
                  <h6 class="text-13 text-dark-gray semi-bold">{{ $t('admin-home.no-recommended') }}</h6>
                </div>
              </div>
              <!--      New Courses       -->
              <div class="row">
                <div class="col-12">
                  <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('master-home.new-courses') }}</h2>
                </div>
                <div v-for="course in newCourses" :key="course.id"
                     class="col-md-6 mb-4 col-12"
                     style="cursor: pointer;"
                     @click="$router.push({name: 'Course', params: {id: course.id}})"
                >
                  <course-card :course="course"/>
                </div>
                <div v-if="newCourses.length == 0" class=" mb-4 ml-4 col-12">
                  <h6 class="text-13 text-dark-gray semi-bold">{{ $t('admin-home.no-recommended') }}</h6>
                </div>
              </div>
            </section>
            <section id="articles">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('master-home.new-articles') }}</h2>
                </div>
                <!-- <div class="col-12">
                    <h6 class="text-13 text-dark-gray semi-bold">No data placeholder</h6>
                </div> -->
                <div v-for="article in glossaryArticles" :key="article.id"
                     class="col-md-6 mb-4 col-12"
                     style="cursor: pointer;"
                >
                  <div class="article-card filter-shadow no-image pointer"
                       @click.prevent="$router.push({name: 'GlossaryArticle', params: {id: article.id}})">
                    <div class="article-card-bottom bg-light-gray-primary">
                      <h5 class="text-18 text-white semi-bold mb-1">{{ article.title }}</h5>
                      <h4 class="text-14 regular text-white thin line-clamp"
                          v-html="$store.getters.getHTMLSafeText(article.content)"></h4>
                      <!-- BADGE FRAME -->
                      <div class="flex-wrap d-flex mt-1">
                        <!-- BADGE -->
                        <div class="d-flex mr-2 mt-2" v-for="label in article.labels" :key="label.id">
                          <span class="article-badge"><img src="assets/img/icons/badge.svg" class="mr-1 img-fluid"
                                                           alt="">{{ label.name }}</span>
                        </div>
                        <!-- //BADGE -->
                      </div>
                      <!-- //BADGE FRAME -->
                    </div>
                  </div>
                </div>
                <div v-if="recommendedCourses.length == 0" class="col-md-6 mb-4 col-12">
                  <h6 class="text-13 text-dark-gray semi-bold">{{ $t('no-data.analytics') }}</h6>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Feed from '@/components/Feed'
import CourseCard from './CourseCard.vue'

export default {
  name: 'MasterHome',
  components: {
    Feed,
    CourseCard
  },
  mounted() {
    this.axios.get('/glossary-articles')
        .then(resp => {
          this.glossaryArticles = resp.data.glossaryArticles.slice(0, 4)
        });
    this.axios.get('/courses/new')
        .then(resp => {
          this.newCourses = resp.data.courses
        });
    this.axios.get('/courses/recommended')
        .then(resp => {
          this.recommendedCourses = resp.data.courses
        })
  },
  data() {
    return {
      glossaryArticles: [],
      recommendedCourses: [],
      newCourses: [],
      teamMembers: Array(9).fill({
        id: Math.floor(Math.random() * 100) + 1,
        name: 'John Doe'
      })
    }
  }
}
</script>
<style scoped>
.label {
  background-color: var(--yellow);
  padding: 4px;
  color: white;
  border-radius: 4px;
  font-size: 15px;
}

.line-clamp::v-deep(h2) {
  font-size: 20px !important;
}

.glossary-title {
  font-size: 22px !important;
  color: var(--black) !important;
  font-weight: bold;
}

@supports (-webkit-line-clamp: 6) {
  .line-clamp {
    font-size: 16px;
    -webkit-line-clamp: 6;
    padding-bottom: 2px;
    -webkit-box-pack: end;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .line-clamp::v-deep(p) {
    margin: 0px !important;
    line-height: 19.2px;
  }
}

.card-title {
  margin-bottom: 0px !important;
  padding-top: 20px !important;
  text-align: center !important;
}

.card-body {
  background: none !important;
}

.dark-bg-line {
  background-color: var(--red-90);
}

.new-glossary {
  border: none !important;
  padding-left: 0px !important;
  margin-left: 0px !important;

}

.yellow-left {
  border-left: 3px solid var(--yellow) !important;
  border-radius: 0px;
}

@media (max-width: 845px) {
  .left-new-btn {
    margin-bottom: 20px;
  }

  .right-new-btn {
    margin-bottom: 0px !important;
  }
}

@media (max-width: 575px) {
  .rsp-text {
    text-align: center !important;
    padding-top: 3px !important;
  }

  .rsp-text * {
    font-size: 17px !important;
  }

  .cstm-dpn {
    display: none;
  }
}

/* MOBILE FONT SIZE UNDER 426px */
@media (min-width: 426px) {
  .font-20 {
    font-size: 20px !important;
  }

  .font-60 {
    font-size: 60px !important;
  }

  .font-18 {
    font-size: 18px !important;
  }
}

@media (max-width: 425px) {
  .font-60 {
    font-size: 42px !important;
  }

  .font-20,
  .line-clamp::v-deep(h2) {
    font-size: 14px !important;
  }

  .glossary-title {
    font-size: 15px !important;
  }

  .font-18 {
    font-size: 12px !important;
  }

  .line-clamp {
    font-size: 13px !important;
  }

  .label {
    font-size: 13px !important;
  }

  .rsp-text.right-side {
    margin-top: 3px !important;
  }
}

@media (min-width: 426px) {
  .home-page-sep {
    display: none !important;
  }
}

@media (max-width: 425px) {
  .mt-5 {
    margin-top: 0px !important;
  }

  #trainings h1 {
    margin-top: 10px !important;
  }

  .home-page-sep {
    border: none !important;
    border-bottom: 2px solid var(--wlgray) !important;
  }
}
</style>
