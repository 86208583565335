<template>
    <div class="feed-list">
            <!-- Post.vue -->
            <div class="feed-item filter-shadow mb-5" style="border-radius: 5px">
                <div class="feed-header bg-light-gray-secondary ">
                    <div class="flex-wrap d-flex">
                        <div class="d-flex mr-auto minus-2">
                            <img v-if="post.user.avatar" :src="post.user.avatar.path+'?token='+$store.state.token" style="width: 56px;" class="avatar-rounded white-border mr-2" alt="#">
                            <Avatar v-else :username="post.user.name" :size="56" class="avatar-rounded white-border mr-2" />
                            <div class="d-flex flex-column mt-1">
                                <h4 class="text-white text-16 bold mb-1">{{post.user.name}}</h4>
                                <h5 class="text-white text-10 medium">
                                    <span class="text-white text-10 medium date">{{$store.getters.parseDateTime(post.created_at)}}</span>
                                    <span class="ml-0" v-if="post.updated_at != post.created_at">, {{ $t('post.edited') }} <span class="text-white text-8 medium">{{$store.getters.parseDateTime(post.updated_at)}}</span></span>
                                </h5>
                            </div>
                        </div>
                        <div class="d-flex dropleft" v-if="post.user.id === $store.state.user.id || $store.state.user.role === 'Admin'">
                            <span  type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src="assets/img/icons/3-dot.svg" class="mb-2 pointer" alt="">
                            </span>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <span class="dropdown-item" style="cursor: pointer; display: flex; align-items: center;" data-toggle="modal" :data-target="'#newAttachmentModal-'+post.id"><i class="fas fa-plus" style="font-size: 13px"></i><span class="ml-1">{{ $t('post.add-attachment') }}</span></span>
                                <span class="dropdown-item" style="cursor: pointer; display: flex; align-items: center;" @click="editingPost = true"><i class="fas fa-pen" style="font-size: 13px"></i><span class="ml-1">{{ $t('post.edit') }}</span></span>
                                <span class="dropdown-item" style="cursor: pointer; display: flex; align-items: center;" data-toggle="modal" :data-target="'#postDeleteModal-'+post.id"><i class="fas fa-trash-alt" style="font-size: 13px" ></i><span class="ml-1">{{ $t('post.delete') }}</span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="feed-container bg-white custom-input">
                    <textarea ref="editPostTextarea" v-show="editingPost" v-model="post.content" oninput='this.style.height = "";this.style.height = this.scrollHeight+5 + "px"' onclick='this.style.height = "";this.style.height = this.scrollHeight+5 + "px"' class="mt-4" style="width: 100%"></textarea>
                    <h3 class="text-black text-13 text-justify" v-show="!editingPost" style="word-break: break-word;" v-html="$store.getters.getHTMLSafeText(post.content)"></h3>
                    <div v-if="editingPost" class="d-flex flex-wrap mt-3">
                        <span class="d-flex me-auto" @click="confirmPostEdit">
                            <button class="btn btn-theme text-12">
                                {{ $t('post.save-changes') }}
                            </button>
                        </span>
                        <span class="d-flex" @click="cancelPostEdit">
                            <button class="btn btn-theme-outline text-12">
                                {{ $t('post.cancel') }}
                            </button>
                        </span>
                    </div>
                    <div v-if="post.poll" :class="post.content ? 'mt-3' : 'mt-0'">
                        {{post.poll.name}} <br v-if="post.poll.name">
                        <h6 :class="post.poll.name ? 'mt-3' : 'mt-0'" :style="post.poll.name ? '' : 'margin-top: 0px !important;'"><strong>{{post.poll.question}}</strong></h6>
                        <div v-if="post.poll.poll_type.type == 'Multiple choice'">
                            <div v-for="(answer, index) in post.poll.poll_answers" :key="answer.id" class="d-flex align-items-center row no-gutters mb-2">
                                <input
                                    v-model="userAnswers[index].selected"
                                    :disabled="post.poll.user_votes.length > 0 || sendingVote"
                                    :checked="post.poll.user_votes.some(v => v.poll_answer_id == answer.id)"
                                    type="checkbox"
                                    style="height: 18px; width: 20px;"
                                >
                                <span class="ml-2 col-md-9 col-10 d-inline-flex align-items-center" style="height: 40px; background-color: var(--wlgray); border-radius: 5px;"><span class="ml-2">{{answer.answer}}</span></span>
                                <span v-if="post.poll.user_votes.length > 0" class="ml-2 col-11 col-md-2">{{post.poll.poll_user_answers.filter(v => v.poll_answer_id == answer.id).length}} {{ $t('post.votes') }}</span>
                            </div>
                            <button @click="submitVote" :disabled="sendingVote || !userAnswers.some(a => a.selected != false)" v-if="post.poll.user_votes.length < 1" class="mt-2 mb-4 btn btn-success">{{ $t('post.vote') }}</button>
                        </div>
                        <div v-else-if="post.poll.poll_type.type == 'Single choice'">
                            <div v-for="(answer, index) in post.poll.poll_answers" :key="answer.id" class="d-flex align-items-center row no-gutters mb-2">
                                <input
                                    :name="'poll'+post.poll.id"
                                    v-model="userAnswers[0].poll_answer_id"
                                    :value="answer.id"
                                    :disabled="post.poll.user_votes.length > 0 || sendingVote"
                                    :checked="post.poll.user_votes.some(v => v.poll_answer_id == answer.id)"
                                    type="radio"
                                    style="height: 18px; width: 20px;"
                                >
                                <span class="ml-2 col-md-9 col-10 d-inline-flex align-items-center" style="height: 40px; background-color: var(--wlgray); border-radius: 5px;"><span class="ml-2">{{answer.answer}}</span></span>
                                <span v-if="post.poll.user_votes.length > 0" class="ml-2 col-11 col-md-2">{{post.poll.poll_user_answers.filter(v => v.poll_answer_id == answer.id).length}} {{ $t('post.votes') }}</span>
                            </div>
                            <button @click="submitVote" :disabled="sendingVote || userAnswers[0].poll_answer_id == null" v-if="post.poll.user_votes.length < 1" class="mt-2 mb-4 btn btn-success">{{ $t('post.vote') }}</button>
                        </div>
                        <div v-else>
                            <textarea v-model="userAnswers[0].answer" v-if="!post.poll.user_votes.some(v => v.answer != null)" :disabled="sendingVote" class="form-control"></textarea>
                            <textarea v-else disabled class="form-control" :value="post.poll.user_votes.find(v => v.answer != null).answer"></textarea>
                            <button @click="submitVote" :disabled="userAnswers[0].answer == null || userAnswers[0].answer.trim() == '' || sendingVote" v-if="post.poll.user_votes.length < 1" class="mt-2 mb-4 btn btn-success">{{ $t('post.vote') }}</button>
                        </div>
                    </div>
                    <div v-if="post.multimedia && post.multimedia.length > 0" class="mt-3">
                        <div class="mb-2">
                            <div v-for="multimedia in post.multimedia.filter((mm) => mm.type == 'document')" :key="multimedia.id" class="ml-2 mt-2">
                                <span style="display: flex; align-items: center">
                                    <a :href="storageURL+multimedia.path+'?token='+token" target="_blank">
                                        <i class="far fa-file-alt mr-1" alt="#" style="font-size: 20px !important;"></i>
                                        <span style="color: var(--black)">{{multimedia.name}}</span>
                                    </a>
                                    <span v-if="editingPost" @click.prevent="setMultimediaToDelete(multimedia)" data-toggle="modal" :data-target="'#multimediaDeleteModal-'+post.id"><i class="fas fa-times remove-document ml-2"></i></span>
                                </span>
                            </div>
                            <div v-for="multimedia in post.multimedia.filter((mm) => mm.type == 'audio')" :key="multimedia.id" class="mt-4">
                                <p class="ml-3 mb-0">{{multimedia.name}}</p>
                                <div style="display: flex; align-items: center">
                                    <audio controls>
                                        <source :src="storageURL+multimedia.path+'?token='+token">
                                    </audio>
                                    <span v-if="editingPost" @click.prevent="setMultimediaToDelete(multimedia)" style="display: flex; align-items: center" data-toggle="modal" :data-target="'#multimediaDeleteModal-'+post.id"><i class="fas fa-times remove-audio ml-2"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div v-for="multimedia in post.multimedia.filter((mm) => mm.type == 'video' || mm.type == 'image')" :key="multimedia.id" class="col-sm-6 col-md-4 col-lg-4 col-xl-6 mt-4" style="height: 300px;">
                                <div v-if="multimedia.type == 'video' || multimedia.type == 'image' " style="color: var(--black); cursor:pointer; width: 100%; height: 100%"
                                    :data-fancybox="`post-multimedia-${post.id}`"
                                    :href="multimedia.type != 'video' ? storageURL+multimedia.path+'?token='+token : '#'+multimedia.id" target="_blank"
                                    :data-caption="multimedia.name"
                                >
                                    <div v-if="multimedia.type == 'video'" style="max-width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: var(--lgray); border-radius: 5px;">
                                        <video style="max-width: 100%; max-height: 100%; object-fit: cover; position: relative; border-radius: 5px; pointer-events: none;" nocontrols>
                                            <source :src="storageURL+multimedia.path+'?token='+token">
                                        </video>
                                        <video :id="multimedia.id" style="display: none;" controls controlsList="nodownload">
                                            <source :src="storageURL+multimedia.path+'?token='+token">
                                        </video>
                                        <i class="far fa-play-circle playbtn"></i>
                                        <span v-if="editingPost" @click.prevent="setMultimediaToDelete(multimedia)" data-toggle="modal" :data-target="'#multimediaDeleteModal-'+post.id"><i class="fas fa-times remove"></i></span>
                                    </div>
                                    <div v-if="multimedia.type == 'image'" style="max-width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: var(--lgray); border-radius: 5px;">
                                        <img :src="storageURL+multimedia.path+'?token='+token" style="max-width: 100%; max-height: 100%; object-fit: cover; position: relative; border-radius: 5px">
                                        <span v-if="editingPost" @click.prevent="setMultimediaToDelete(multimedia)" data-toggle="modal" :data-target="'#multimediaDeleteModal-'+post.id"><i class="fas fa-times remove"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- PostComment.vue -->
                    <hr class="black-line" />
                    <div  class="comment-box">
                        <div class="flex-wrap d-flex mb-3">
                            <div class="d-flex">
                                <h6 class="text-light-gray text-10 medium">{{$tc('post.comments',post.comments.length, {count: post.comments.length})}}</h6>
                            </div>
                        </div>
                        <div v-for="comment in post.comments" :key="comment.id" class="col-12 comment-line">
                            <PostComment v-if="!comment.deleted" @commentDeleted="comment.deleted = true" :commentProp="comment" />
                        </div>

                        <div class="col-12">
                            <PostCommentBox v-if="post.approved" :post="post" />
                            <div class="row line" v-else style="display: flex;margin-left: 0px !important;margin-right: 0px !important;padding-left: 26px !important;padding-right: 0px !important;">>
                                <div class="text-center" @click.prevent="approve" style="display: flex; align-items: center; flex-grow: 1; border-radius: 10px !important; background-color: var(--success); cursor: pointer;margin-left: -25px;z-index: 1;" data-toggle="modal" :data-target="'#newCommentModal-'+post.id">
                                    <span class="w-100 pt-2 pb-2" style="color: white">{{$t('post.approve')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //PostComment.vue -->
                </div>
            </div>
            <!-- //Post.vue -->


         <div v-if="user.hasActiveMembership == true && user.role!='User'" class="modal fade" ref="postDeleteModal" :id="'postDeleteModal-'+post.id" tabindex="-1" aria-labelledby="postDeleteModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="postDeleteModalLabel">{{ $t('post.sure') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    {{ $t('post.delete-question') }}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-theme" data-dismiss="modal">{{ $t('post.cancel') }}</button>
                    <button type="button" class="btn btn-theme-outline" data-dismiss="modal" @click="deletePost">{{ $t('post.delete') }}</button>
                </div>
                </div>
            </div>
        </div>
        <div v-if="user.hasActiveMembership == true && user.role!='User'" class="modal fade" ref="multimediaDeleteModal" :id="'multimediaDeleteModal-'+post.id" tabindex="-1" aria-labelledby="multimediaDeleteModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="multimediaDeleteModalLabel">{{ $t('post.sure') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    {{ $t('post.question1') }} {{multimediaToDeleteName}} {{ $t('post.question2') }}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-theme" data-dismiss="modal">{{ $t('post.cancel') }}</button>
                    <button type="button" class="btn btn-theme-outline" data-dismiss="modal" @click="deleteMultimedia">{{ $t('post.delete') }}</button>
                </div>
                </div>
            </div>
        </div>
        <div v-if="user.hasActiveMembership == true && user.role!='User'" class="modal fade" ref="newAttachmentModal" :id="'newAttachmentModal-'+post.id" tabindex="-1" role="dialog" aria-labelledby="newAttachmentModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header  text-center">
                        <h5 class="post-title w-100" id="newAttachmentModalLabel">{{ $t('post.new-attachment') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body w-100">
                        <div class="row">
                            <div class="col-12">
                                <button type="button" class="btn btn-theme-outline w-100 mb-2" @click="$refs.videoUpload.click()">
                                    <input type="file" accept="video/mp4, video/webm, video/avi" id="uploadVideo" ref="videoUpload" @change="createUpload('video')" style="display: none;">
                                    {{ $t('post.attach-vid') }}
                                </button>
                                <button type="button" class="btn btn-theme-outline w-100 mb-2" @click="$refs.imageUpload.click()">
                                    <input type="file"  accept="image/png, image/jpeg, image/jpg, image/gif" id="uploadImage" ref="imageUpload" @change="createUpload('image')" style="display: none;">
                                    {{ $t('post.attach-pic') }}
                                </button>
                                <button type="button" class="btn btn-theme-outline w-100 mb-2" @click="$refs.audioUpload.click()">
                                    <input type="file" accept="audio/mp3, audio/mpeg, audio/wav" id="uploadAudio" ref="audioUpload" @change="createUpload('audio')" style="display: none;">
                                    {{ $t('post.attach-sound') }}
                                </button>
                                <button type="button" class="btn btn-theme-outline w-100" @click="$refs.documentUpload.click()">
                                    <input type="file" accept=".doc, .docx, .pdf, .xls, .xlsx, .ods, .ppt, .pptx, .txt" id="uploadDocument" ref="documentUpload" @change="createUpload('document')" style="display: none;">
                                    {{ $t('post.attach-doc') }}
                                </button>
                            </div>
                            <div v-if="files.length > 0" class="col-12">
                                <h5 class="mb-2 mt-4">{{ $t('post.uploads') }}</h5>
                                <div class="row">
                                    <div v-for="(file, index) in files" :key="file.id" class="col-12 mb-3">
                                        <Upload v-if="file.shown" :file="file.file" :type="file.type" :id="file.id" @uploadFinished="finishUpload" @uploadCancelled="cancelUpload"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" :disabled="!canSend" @click="attachFiles" class="btn btn-theme w-100">{{ $t('post.save') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { DateTime } from 'luxon';
import Avatar from '@/components/Avatar'
import Upload from '@/components/Upload'
import PostComment from '@/components/PostComment'
import PostCommentBox from '@/components/PostCommentBox'

export default {
    name: 'Post',
    components: {
        Avatar,
        Upload,
        PostComment,
        PostCommentBox
    },
    props: {
        postProp: Object,

    },
    created() {
        this.post = this.postProp
        if(this.post.poll) {

            if(this.post.poll.poll_type.type == 'Multiple choice') {
                this.post.poll.poll_answers.forEach(p => {
                    let vote = {
                        poll_answer_id: p.id,
                        selected: false
                    }
                    if(this.post.poll.user_votes.find(v => v.poll_answer_id == p.id)) {
                        vote.selected = true
                    }
                    this.userAnswers.push(vote)
                })
            }
            else if(this.post.poll.poll_type.type == 'Single choice') {
                this.userAnswers.push({poll_answer_id: null})
            }
            else
                this.userAnswers.push({answer: null})
        }
    },
    data() {
        return {
            token: this.axios.defaults.headers.common.Authorization.split(' ')[1],
            storageURL: process.env.VUE_APP_STORAGE_URL,
            comment: '',
            post: null,
            files: [],
            loading: false,
            editingPost: false,
            multimediaToDelete: null,
            originalContent: this.postProp.content,
            userAnswers: [],
            sendingVote: false,
        }
    },
    watch: {
        files() {
            return this.files
        },
        multimediaToDelete() {
            return this.multimediaToDelete
        }
    },
    computed: {
        canSend() {
            return !this.files.find(file => file.uuid === null) && !this.loading
        },
        commentNumber() {
            return this.post.comments.filter((comment) => {
                return !comment.deleted
            }).length
        },
        multimediaToDeleteName() {
            return this.multimediaToDelete ? this.multimediaToDelete.name : ''
        },
      user() {
        return this.$store.state.user
      }
    },
    methods: {
        submitVote() {
            this.sendingVote = true
            let formData = {}
            if(this.post.poll && this.post.poll.poll_type.type == 'Multiple choice') {
                formData.poll_answers = this.userAnswers.filter(a => a.selected != false)
            }
            else if(this.post.poll && this.post.poll.poll_type.type == 'Single choice') {
                formData.poll_answer_id =this.userAnswers[0].poll_answer_id
            }
            else {
                formData.answer =this.userAnswers[0].answer
            }

            this.axios.post('poll/'+this.post.poll.id+'/vote', formData)
                .then(resp => this.post.poll = resp.data.poll)
                .finally(() => this.sendingVote = false)
        },
        createUpload(type) {
            if(event.target.files.item(0)) {
                this.loading = true
                this.files.push({file: event.target.files.item(0), type: type, shown: true, uuid: null, id: this.files.length})
                event.target.value = null
            }
        },
        finishUpload(id, multimedia) {
            console.log(multimedia)
            this.files.find(file => file.id === id).uuid = multimedia.id
            this.files.find(file => file.id === id).path = multimedia.path
            this.files.find(file => file.id === id).name = multimedia.name
            this.loading = false
        },
        cancelUpload(id, uuid) {
            this.loading = false
            this.files.splice(this.files.indexOf(this.files.find(file => file.id === id)), 1)
        },
        sendComment() {
            let formData = {}
            this.loading = true
            const multimedia = this.files.filter(file => file.uuid !== null).map(file => file.uuid)
            if(this.comment)
                formData.comment = this.comment
            if(multimedia.length > 0)
                formData.multimedia = multimedia
            this.loading = true;
            this.axios.post(`/posts/${this.post.id}/comments`, formData)
            .then(resp => {
                this.post.comments.push(resp.data.comment)
                this.comment = ''
                this.files = []
            }).catch(e => console.log(e.response))
            .finally(() => this.loading = false)
        },
        parseDate(date) {
            return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)
        },
        cancelPostEdit() {
            if(this.editingPost) {
                this.post.content = this.originalContent
                this.editingPost = false
            }
        },
        confirmPostEdit() {
            if(this.editingPost) {
                this.editingPost = false
                if(this.originalContent != this.post.content) {
                    let formData = {}
                    const multimedia = this.files.filter(file => file.uuid !== null).map(file => file.uuid)
                    formData.content = this.post.content
                    if(multimedia.length > 0)
                        formData.multimedia = multimedia
                    this.loading = true;
                    this.axios.patch(`/posts/${this.post.id}/`, formData)
                    .then(resp => {
                        this.post = resp.data.post
                        this.originalContent = resp.data.post.content
                        this.files = []
                    }).catch(e => console.log(e))
                    .finally(() => this.loading = false)
                }
            }
        },
        deletePost() {
            this.axios.delete(`/posts/${this.post.id}/`).then(resp => this.$emit('postDeleted', this.post)).catch(e => console.log(e.response))
        },
        attachFiles() {
            this.loading = true
            const multimedia = this.files.filter(file => file.uuid !== null).map(file => file.uuid)
            this.axios.post(`posts/${this.post.id}/multimedia`, {multimedia: multimedia})
                .then(resp => {
                    this.files.forEach((file,index) => {
                        this.files[index].id = file.uuid
                    })
                    this.post.multimedia = this.post.multimedia.concat(this.files.filter(file => file.uuid !== null))
                    $(this.$refs.newAttachmentModal).modal('hide')
                    this.files = []
                })
                .catch(e => console.log(e))
                .finally(() => this.loading = false)
            },
        setMultimediaToDelete(multimedia) {
            console.log(multimedia)
            this.multimediaToDelete = multimedia
        },
        deleteMultimedia() {
            this.loading = true
            this.axios.post(`${this.storageURL}/upload/${this.multimediaToDelete.type}/${this.multimediaToDelete.id}/abort`)
            .then(resp => {
                this.post.multimedia = this.post.multimedia.filter(mm => { return mm.id !== this.multimediaToDelete.id})
            })
            .catch(e => console.log(e.response))
            .finally(() => this.loading = false)
        },
        approve() {
            this.loading = true
            this.axios.post(`/posts/${this.post.id}/approve`)
                .then(resp => this.$emit('postDeleted', this.post))
                .catch(e => console.log(e))
                .finally(() => this.loading = false)
        }
    }
}
</script>
<style scoped>
    .feed-list {
        margin-top: 30px;
    }
    .new-button-50 img {
        height: 25px;
    }
    .white-bg {
        background-color: white;
        padding: 7px 15px;
        border-radius: 5px;
        margin-right: -10px;
    }
    .pointer h6 {
        font-weight: 600;
        color: rgba(84, 84, 84, 0.75);
    }
    .feed-list .feed-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 15px 15px 0px 15px;
    }
    .feed-list .feed-container {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 25px;
    }
    .minus-2 {
        margin-bottom: -12px;
    }
    .filter-dropdown select {
        border: none !important;
        box-shadow: none !important;
        height: 31px !important;
        font-size: 14px;
        width: 120%;
    }
    .filter-dropdown {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        margin-right: -30px !important;
    }
    /* USER COMMENT CSS */
    .user-comment {
        padding: 5px 10px 20px 10px;
        border-radius: 5px;
    }
    .helper-box {
        width: 35px;
    }
    /* WRITE COMMENT INPUT */
    .write-comment {
        border: 1px solid #B5ADA6;
        border-radius: 5px;
        padding: 10px;
    }
</style>
