<template>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">{{ $t('route-nav.course-list') }}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ $t('route-nav.homepage') }}</li>
        </ol>
    </nav>
</template>

<script>
export default {
    name: "RouteNav"
}
</script>
