<template>
  <div>
    <nav id="navbar" class="navbar navbar-expand-lg fixed-top bg-dark-gray text-white">
      <div class="container-fluid" ref="navigation-container">
        <button class="btn btn-menu-toggler collapsed" type="button" data-toggle="collapse"
          data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <i style="color: white !important;font-size: 25px;" class="fas fa-bars"></i>
          <i style="color: white !important;font-size: 25px;" class="fas fa-times"></i>
        </button>
        <!-- PC NAVBAR -->
        <div class="row w-100 ml-0 pc-navbar-top justify-content-between">
          <div class="logo-col" @click.prevent="$router.push({ name: 'Home' })">
            <img src="../assets/xbody-academy-logo-vertical-logo-white.svg" class="img-fluid top-logo"
              style="height: 30px !important;cursor: pointer;" alt="">
          </div>
          <div class="menu-col">
            <div class="flex-wrap d-flex d-flex justify-content-center w-100 top-nav-dropdown">
              <div class="nav-item" :class="$route.name === 'Home' ? 'active' : ''">
                <a href="#" @click.prevent="$router.push({ name: 'Home' })" class="nav-link">{{ $t('new-navbar.home')
                }}</a>
              </div>
              <div class="nav-item" :class="$route.name === 'CourseCatalog' ? 'active' : ''">
                <a href="#" @click.prevent="$router.push({ name: 'CourseCatalog' })" class="nav-link">{{
                  $t('new-navbar.all-courses-2') }}</a>
              </div>
              <div class="nav-item" :class="$route.name === 'MyCourses' ? 'active' : ''" v-if="!nonMember">
                <a href="#" @click.prevent="$router.push({ name: 'MyCourses' })" class="nav-link">{{
                  $t('new-navbar.my-courses') }}</a>
              </div>
              <div class="nav-item" :class="$route.name === 'Questionnaires' ? 'active' : ''" v-if="!nonMember">
                <a href="#" @click.prevent="$router.push({ name: 'Questionnaires' })" class="nav-link">{{
                  $t('new-navbar.questionnaires') }}</a>
              </div>
              <div class="nav-item" :class="$route.name === 'Glossary' ? 'active' : ''" v-if="!nonMember">
                <a href="#" @click.prevent="$router.push({ name: 'Glossary' })" class="nav-link">{{
                  $t('new-navbar.articles') }}</a>
              </div>
              <div v-if="$store.state.user.role == 'Admin'" class="nav-item dropdown ">
                <a class="nav-link" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  {{ $t('new-navbar.management') }}
                  <svg xmlns="http://www.w3.org/2000/svg" class="chewron-down" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd" />
                  </svg>
                </a>
                <div class="dropdown-menu w-100" aria-labelledby="navbarDropdown2">
                  <div class="admin-menu-container" style="max-width: 1200px; margin-left: auto; margin-right: auto;">
                    <a v-for="item in adminMenuItems" :key="item.routeName"
                      class="pc-drop-item d-flex align-items-center pointer"
                      @click.prevent="$router.push({ name: item.routeName, params: item.params })"
                      :class="$route.name === item.routeName ? 'active' : ''">
                      <img class="icon mr-1" style="color: var(--red)" :src="`../assets/img/icons/${item.iconPath}`" />
                      <div>{{ $t(item.text) }}</div>
                    </a>
                  </div>
                </div>
              </div>
              <div v-else-if="$store.state.user.role == 'Master'" class="nav-item dropdown ">
                <a class="nav-link" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  {{ $t('new-navbar.management') }}
                </a>
                <div class="dropdown-menu w-100" aria-labelledby="navbarDropdown2">
                  <div class="flex-wrap d-flex justify-content-center">
                    <a class="pc-drop-item" @click.prevent="$router.push({ name: 'ManageCourses' })">{{
                      $t('admin-menu.managing-course') }}</a>
                    <a class="pc-drop-item" @click.prevent="$router.push({ name: 'ManageTests' })">{{
                      $t('admin-menu.managing-test') }}</a>
                    <a class="pc-drop-item" @click.prevent="$router.push({ name: 'ManageUsers' })">{{
                      $t('admin-menu.team-members') }}</a>
                      <a class="pc-drop-item" @click.prevent="$router.push({ name: 'ManageCodes' })">{{
                      $t('manage-codes.manage-codes') }}</a>
                      <a class="pc-drop-item" @click.prevent="$router.push({ name: 'ManageCoupons' })">{{
                        $t('admin-menu.manage-coupons') }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right-col d-flex justify-content-end align-items-center">
            <a href="#" :badge="$store.getters.unreadNotificationsCount" class="custom-badge pc-right-items me-4"
              @click.prevent="$router.push({ name: 'Notifications' })">
              <img class="icon" src="../assets/img/icons/notifications_active_24px.svg" alt="">
            </a>
            <a href="#" :badge="$store.state.user && $store.state.user.cart_items?.length"
              class="custom-badge pc-right-items me-4" @click.prevent="$router.push({ name: 'Cart' })">
              <img class="icon" src="../assets/img/icons/shopping_cart_24px.svg" alt="">
            </a>
            <a href="#" :badge="$store.getters.unreadMessagesCount" class="custom-badge pc-right-items me-4"
              @click.prevent="$router.push({ name: 'Chat' })">
              <img class="icon icon-bubble" src="../assets/img/icons/message.svg" alt="">
            </a>
            <a href="#" class="profile-dropdown" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <img v-if="user.avatar" :src="user.avatar.path + '?token=' + $store.state.token" width="40"
                style="float: right;border-radius: 50%;" class="img-fluid avatar" alt="LOGO">
              <Avatar v-else :username="user.name" :size="40" :rounded="true" style="float: right;" class="avatar" />
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a href="#" class="profile-drop-item" @click.prevent="$router.push({ name: 'MyOrders' })">
                  {{ $t('new-navbar.orders') }}
                </a>
                <a href="#" class="profile-drop-item" @click.prevent="$router.push({ name: 'MyCertificates' })">
                  {{ $t('new-navbar.certificates') }}
                </a>
                <a href="#" class="profile-drop-item" @click.prevent="$router.push({ name: 'Settings' })">
                  {{ $t('new-navbar.settings') }}
                </a>
                <!--                <div class="profile-drop-item profile-drop-item-lang">
                  <span :class="$store.state.locale == 'hu' ? 'active' : ''"
                        @click.prevent="changeLocale('hu')">HU</span>
                  <span :class="$store.state.locale == 'en' ? 'active' : ''"
                        @click.prevent="changeLocale('en')">EN</span>
                </div>
                <div class="profile-drop-item profile-drop-item-lang">
                                <span v-for="currency in $store.state.currencies"
                                      :key="currency.currency"
                                      :class="$store.state.currency == currency.currency ? 'active' : ''"
                                      @click.prevent="changeCurrency(currency.currency)"
                                >
                                    {{ currency.currency }}
                                </span>
                </div>-->
                <a href="#" class="profile-drop-item" @click="logout()">
                  {{ $t('new-navbar.logout') }}
                </a>
              </div>
            </a>
          </div>
        </div>
        <!-- PHONE NAVBAR -->
        <div class="collapse navbar-collapse mobile-top-navbar" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <li class="nav-item mt-1 mb-2">
              <img v-if="user.avatar" :src="user.avatar.path + '?token=' + $store.state.token" width="25"
                class="img-fluid avatar" style="float: left;margin-right: 10px;border-radius: 50%;" alt="LOGO">
              <Avatar v-else :username="user.name" :size="25" :rounded="true" style="float: left;margin-right: 10px;"
                class="avatar" />
              {{ $store.state.user && $store.state.user.name }}
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="$route.name === 'Settings' ? 'active' : ''" href="#"
                @click.prevent="$router.push({ name: 'Settings' })">
                {{ $t('new-navbar.settings-profile') }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="$route.name === 'MyCertificates' ? 'active' : ''" href="#"
                @click.prevent="$router.push({ name: 'MyCertificates' })">
                {{ $t('new-navbar.certificates') }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="$route.name === 'Home' ? 'active' : ''"
                @click.prevent="$router.push({ name: 'Home' })" href="#">{{ $t('new-navbar.home') }}</a>
            </li>
            <li class="nav-item dropdown" v-if="!nonMember">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ $t('new-navbar.all-courses') }}
              </a>
              <div class="dropdown-menu mb-2" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" @click.prevent="$router.push({ name: 'CourseCatalog' })" href="#">{{
                  $t('new-navbar.all-courses-2') }}</a>
                <a class="dropdown-item" @click.prevent="$router.push({ name: 'MyCourses' })" href="#">{{
                  $t('new-navbar.my-courses') }}</a>
              </div>
            </li>
            <li class="nav-item" v-if="user.role != 'User' && !nonMember">
              <a class="nav-link" :class="$route.name === 'Glossary' ? 'active' : ''"
                @click.prevent="$router.push({ name: 'Glossary' })" href="#">{{ $t('new-navbar.articles') }}</a>
            </li>
            <li v-if="$store.state.user.role == 'Admin'" class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ $t('new-navbar.management') }}
              </a>
              <div class="dropdown-menu mb-2" aria-labelledby="navbarDropdownMenuLink">
                <a v-for="item in adminMenuItems" :key="item.routeName"
                  class="dropdown-item d-flex align-items-center pointer"
                  @click.prevent="$router.push({ name: item.routeName, params: item.params })"
                  :class="$route.name === item.routeName ? 'active' : ''">
                  <img class="icon mr-1" style="color: var(--red)" :src="`../assets/img/icons/${item.iconPath}`" />
                  <div>{{ $t(item.text) }}</div>
                </a>
              </div>
            </li>
            <li v-else-if="$store.state.user.role == 'Master'" class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ $t('new-navbar.management') }}
              </a>
              <div class="dropdown-menu mb-2" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" :class="$route.name === 'ManageTests' ? 'active' : ''"
                  @click.prevent="$router.push({ name: 'ManageTests' })" >{{
                    $t('admin-menu.managing-test')
                  }}</a>
                <a class="dropdown-item" :class="$route.name === 'ManageUsers' ? 'active' : ''"
                  @click.prevent="$router.push({ name: 'ManageUsers' })" >{{
                    $t('admin-menu.managing-user')
                  }}</a>
                  <a class="dropdown-item" :class="$route.name === 'ManageCodes' ? 'active' : ''"
                  @click.prevent="$router.push({ name: 'ManageCodes' })" >{{
                    $t('manage-codes.manage-codes')
                  }}</a>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">
                <div class="profile-drop-item-lang-mobile">
                  <span :class="$store.state.locale == 'hu' ? 'active' : ''" @click.prevent="changeLocale('hu')">HU</span>
                  <span :class="$store.state.locale == 'en' ? 'active' : ''" @click.prevent="changeLocale('en')">EN</span>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">
                <div class="profile-drop-item-lang-mobile">
                  <span v-for="currency in $store.state.currencies" :key="currency.currency"
                    :class="$store.state.currency == currency.currency ? 'active' : ''"
                    @click.prevent="changeCurrency(currency.currency)">
                    {{ currency.currency }}
                  </span>
                </div>
              </a>
            </li>
            <li class="nav-item" @click="logout()">
              <a class="nav-link" href="#"><i class="fas fa-sign-out-alt"></i> {{ $t('new-navbar.logout') }}</a>
            </li>
          </ul>
        </div>
        <!-- PHONE BOTTOM NAVIGATION -->
        <div class="mobile-bottom-nav">
          <div class="flex-box d-flex justify-content-center mt-2">
            <div class="d-flex ms-auto me-auto">
              <a :badge="$store.getters.unreadNotificationsCount" href="#" class="custom-badge-bottom"
                @click.prevent="$router.push({ name: 'Notifications' })">
                <img class="icon" src="../assets/img/icons/notifications_active_24px.svg" alt="">
              </a>
            </div>
            <div class="d-flex ms-auto me-auto">
              <a :badge="$store.state.user && $store.state.user.cart_items.length" href="#" class="custom-badge-bottom"
                @click.prevent="$router.push({ name: 'Cart' })">
                <img class="icon" src="../assets/img/icons/shopping_cart_24px.svg" alt="">
              </a>
            </div>
            <div class="d-flex ms-auto me-auto">
              <a href="#" :badge="$store.getters.unreadMessagesCount" @click.prevent="$router.push({ name: 'Chat' })">
                <img class="icon icon-bubble" src="../assets/img/icons/message.svg" alt="">
              </a>
            </div>
          </div>
        </div>
        <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul v-for="item in navItems" :key="item.routeName" class="nav navbar-nav first">
                <li v-if="item.routeName !== 'Glossary' || $store.state.user.role !== 'User'" v-bind:class="{ active: isActive(item.routeName) }" @click="handleClick" class="nav-item">
                     <router-link :to="{name: item.routeName}" class="nav-link" href="#">{{$t(`navbar.${item.routeName}`)}}</router-link>
                </li>
            </ul>
        </div> -->
      </div>
    </nav>
    <buy-membership v-if="nonMember && shouldShowCarousel" />
    <carousel v-else-if="shouldShowCarousel" />
  </div>
</template>

<script>
import Carousel from './Carousel.vue'
import Avatar from '@/components/Avatar'
import BuyMembership from './BuyMembership.vue'

export default {
  name: "Navbar",
  components: {
    Carousel,
    Avatar,
    BuyMembership,
  },
  data() {
    return {
      adminMenuItems: [
        {
          iconPath: 'XBODY Manage news.svg',
          routeName: 'ManageNews',
          text: 'admin-menu.manage-news'
        },
        {
          iconPath: 'XBODY Manage coupons.svg',
          routeName: 'ManageCoupons',
          text: 'admin-menu.manage-coupons'
        },
        {
          iconPath: 'XBODY Course management.svg',
          routeName: 'ManageCourses',
          text: 'admin-menu.managing-course'
        },
        {
          iconPath: 'XBODY Course groups.svg',
          routeName: 'CourseGroupList',
          text: 'admin-menu.course-group-list'
        },
        {
          iconPath: 'XBODY Analytics.svg',
          routeName: 'Reporting',
          text: 'admin-menu.stats'
        },
        {
          iconPath: 'XBODY Quiz management.svg',
          routeName: 'ManageTests',
          text: 'admin-menu.managing-test'
        },
        {
          iconPath: 'XBODY User management.svg',
          routeName: 'ManageUsers',
          text: 'admin-menu.managing-user'
        },
        {
          iconPath: 'XBODY Create knowledge base article.svg',
          routeName: 'CreateGlossaryArticle',
          text: 'admin-menu.knowledge-new'
        },
        {
          iconPath: 'XBODY Edit shared knowledge.svg',
          routeName: 'UnapprovedGlossary',
          text: 'admin-menu.knowledge-edit'
        },
        {
          iconPath: 'XBODY Unapproved posts.svg',
          routeName: 'UnapprovedPosts',
          text: 'admin-menu.unapproved-posts'
        },
        {
          iconPath: 'XBODY Feedback list.svg',
          routeName: 'FeedbackList',
          text: 'feedback.list'
        },
        {
          iconPath: 'XBODY Exchange rates.svg',
          routeName: 'ExchangeRates',
          text: 'admin-menu.exchange-rates'
        },
        {
          iconPath: 'XBODY Temporary certificates.svg',
          routeName: 'TemporaryCertificates',
          text: 'admin-menu.temporary-certificates'
        },
        {
          iconPath: 'XBODY Yearly membership settings.svg',
          routeName: 'EditMembership',
          params: { membership: 1 },
          text: 'admin-menu.yearly-membership'
        },
        {
          iconPath: 'XBODY Customers page.svg',
          routeName: 'CustomPageList',
          params: { membership: 1 },
          text: 'admin-menu.custom-pages'
        },
        {
          iconPath: 'XBODY Manage news.svg',
          routeName: 'Templates',
          params: { membership: 1 },
          text: 'admin-menu.templates'
        },
        {
          iconPath: 'XBODY Temporary certificates.svg',
          routeName: 'Certificates',
          params: { membership: 1 },
          text: 'admin-menu.certificates'
        },
        {
          iconPath: 'XBODY Order management.svg',
          routeName: 'ManageOrders',
          params: { membership: 1 },
          text: 'admin-menu.orders'
        },
        {
          iconPath: 'XBODY Manage coupons.svg',
          routeName: 'ManageCodes',
          params: { membership: 1 },
          text: 'manage-codes.manage-codes'
        },
      ],
      navItems: [
        {
          routeName: 'Home',
          text: 'Kezdőlap'
        },
        {
          routeName: 'MyCourses',
          text: 'Saját képzések'
        },
        {
          routeName: 'CourseCatalog',
          text: 'Képzéstár'
        },
        {
          routeName: 'Glossary',
          text: 'Tudásbázis'
        },
      ]
    }
  },
  computed: {
    shouldShowCarousel() {
      return Array('Home').some(r => r == this.$route.name)
      // return false
    },
    user() {
      return this.$store.state.user
    },
    nonMember() {
      return this.user && (this.user.role == 'Member User' || this.user.role == 'User') && this.user.hasActiveMembership == false
    }
  },
  methods: {
    isActive(name) {
      return name === this.$route.name
    },
    handleClick() {
      $('#navbarSupportedContent').collapse('hide');
      if (window.innerWidth < 992) {
        this.$refs['navigation-container'].scrollIntoView(true);
      }
    },
    logout() {
      this.axios.post('/logout')
      window.Echo.leave('user.' + this.user.id)
      // this.$store.commit('setUser', null)
      // this.$store.commit('setCheckout', null);
      localStorage.clear()
      location.reload();
      // this.$router.push({name: 'Login'})
    },
    changeLocale(lang) {
      const currentLocale = this.$store.state.user.locale
      this.$i18n.locale = lang
      this.$store.commit('changeLocale', lang)
      if (currentLocale != lang)
        this.axios.post('/locale', { locale: lang })
          .then(() => {
            this.$store.state.user.locale = lang
          })
          .catch(e => {
            consle.log(e)
          })
    },
    changeCurrency(currency) {
      this.$store.commit('setCurrency', currency)
    }
  }
}

</script>
<style scoped>
.admin-menu-container {
  display: grid;
  gap: 20px 15px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.mobile-item {}

/* USAGE: <span class="icon icon-heart" badge="5"></span> */
.custom-badge[badge]:after {
  border-radius: 50%;
  color: var(--white);
  content: attr(badge);
  font-size: 10px;
  margin-top: -20px;
  padding: 0px;
  height: 18px;
  padding-top: 2px;
  min-width: 18px;
  background-color: var(--red);
  margin-left: 15px !important;
  position: absolute;
  text-align: center;
}

[badge]:after {
  border-radius: 50%;
  color: var(--black);
  content: attr(badge);
  font-size: 10px;
  margin-top: 12px;
  padding: 0px;
  margin-left: -15.5px !important;
  position: absolute;
  text-align: center;
}

[badge^="-"]:after,
[badge="0"]:after,
[badge=""]:after {
  display: none;
}

@media (max-width: 991px) {
  .pc-navbar-top {
    display: none !important;
  }

  .navbar {
    background-color: transparent;
    padding: 0px !important;
  }
}

@media (min-width: 992px) {

  .mobile-navbar-top,
  .btn-menu-toggler,
  .mobile-bottom-nav,
  .navbar-expand-lg .navbar-collapse.mobile-top-navbar {
    display: none !important;
  }

  .logo-col {
    max-width: 14rem !important;
  }
}

.chewron-down {
  width: 20px;
  height: 20px;
}

.nav-link {
  color: white;
}

.navbar {
  align-items: center;
  height: 76px !important;

}

.custom-badge {
  display: flex;
  align-items: center;
}

.icon {
  width: 28px;
  height: 28px;
}

.icon-bubble {
  width: 26px;
  height: 23px;
}

.avatar {
  height: 36px;
  width: 36px;
}

.active {
  font-weight: 700;
}

.profile-drop-item-lang>span {
  color: var(--white) !important;
}

@media (min-width: 992px) {
  .navbar .profile-dropdown .dropdown-menu {
    left: auto !important;
    position: fixed;
    top: 54px;
    right: 40px;
    width: auto !important;
    min-width: auto !important;
    background-color: #333E48 !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }

  .navbar .profile-dropdown .profile-drop-item {
    display: block;
    padding: 3px 15px;
    text-align: left !important;
  }

  .dropdown-menu.show {
    background-color: #3f464d !important;
    position: fixed;
    top: 74px;
    color: var(--white);
    border: none !important;
    border: none !important;
    border-radius: 0 !important;
  }

  .pc-drop-item:hover,
  .pc-drop-item.active,
  .dropdown-item.active {
    background-color: #333E48 !important
  }

  .pc-drop-item {
    color: white;
    text-decoration: none !important;
    padding: 5px 15px;
    margin: 5px;
    border-radius: 8px;
  }

  .profile-dropdown a {
    color: var(--white) !important;
    text-decoration: none !important;
  }

  .profile-drop-item-lang {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .profile-drop-item-lang span {
    background-color: transparent;
    color: var(--red);
    padding: 3px 10px;
    border-radius: 8px;
    margin-right: 10px;
    border: 2px solid var(--red);
    font-size: 14px;
  }

  .profile-drop-item-lang span.active {
    font-weight: bold;
    background-color: var(--red);
    color: var(--white);
  }
}

@media (min-width: 1101px) and (max-width: 1350px) {
  .nav-link {
    font-size: 14px !important;
    padding-left: 7px;
    padding-right: 7px;
  }
}

@media(min-width: 992px) and (max-width: 1080px) {
  .logo-col {
    max-width: 9rem !important;
  }
}

@media (min-width: 992px) and (max-width: 1100px) {
  .nav-link {
    font-size: 14px !important;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.mobile-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
  color: #fff !important;
  background-color: var(--black)
}

.btn-menu-toggler {
  outline: none !important;
  box-shadow: none !important;
  z-index: 202 !important;
}

.btn-menu-toggler .fa-bars {
  display: none;
  transition: 0.3s all;
}

.btn-menu-toggler.collapsed .fa-times {
  display: none;
  transition: 0.3s all;
}

.btn-menu-toggler.collapsed .fa-bars {
  display: block;
  transition: 0.3s all;
}

@media (max-width: 991px) {
  .navbar {
    border-radius: 50%;
    width: 48px !important;
    height: 48px !important;
  }

  .btn-menu-toggler {
    background-color: var(--black);
    border-radius: 50%;
    width: 48px !important;
    height: 48px !important;
    margin-top: 0px;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    transition: 0.3s all;
  }

  .btn-menu-toggler.collapsed {
    border-radius: 50% !important;
    margin-left: 0px !important;
    transition: 0.3s all;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .mobile-top-navbar {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--black);
    margin-top: 25px;
    padding-top: 20px;
    border-top-right-radius: 10px;
    text-align: left;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    min-width: 190px;
    max-height: 85vh !important;
    overflow-y: auto;
    border-bottom-right-radius: 10px;
    z-index: 200 !important;
  }

  .profile-drop-item-lang-mobile span {
    background-color: white;
    color: var(--red);
    padding: 5px 10px;
    border-radius: 8px;
    margin-right: 10px;
    border: 2px solid var(--red);
  }

  .profile-drop-item-lang-mobile span.active {
    font-weight: bold;
    background-color: var(--red);
    color: var(--white);
  }

  [badge]:after {
    border-radius: 50%;
    color: var(--white);
    content: attr(badge);
    font-size: 10px;
    margin-top: -5px;
    padding: 0px;
    height: 18px;
    padding-top: 2px;
    min-width: 18px;
    background-color: var(--red) !important;
    margin-left: -12px !important;
    position: absolute;
    text-align: center;
  }
}
</style>
