<template>
  <div class="container mt-4">
    <div v-if="!loading && order">
      <div v-if="order.status == 'Succeeded'" class="pt-5">
        <div class="w-full pt-5">
          <div class="mx-auto mt-4" style="height: 31px; width: 44px;">
            <svg
                width="44"
                height="31"
                viewBox="0 0 44 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M2 15.3176L15.3176 28.6353L42 2"
                  stroke="#00BD2A"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <p class="text-center mt-3">{{ $t("after-payment.thanks") }}</p>
        <p class="text-center mt-3">{{ $t("after-payment.success") }}</p>
        <div class="d-flex text-center align-items-center" v-if="order.invoice_id!==null">
          <button
              type="button"
              :disabled="pdfLoading"
              class="btn btn-outline-danger m-auto mb-2"
              @click.prevent="downloadPDF"
          >
            <div class="d-flex align-items-center">
              <i class="fas fa-arrow-circle-down mr-1"></i>
              <span>{{ $t("after-payment.download") }}</span>
            </div>
          </button>
          <div
              v-if="pdfLoading"
              class="spinner-border text-danger ml-2 mb-2 m-auto"
              role="status"
          >
            <span class="sr-only">{{ $t("after-payment.loading") }}</span>
          </div>
        </div>
        <div v-else-if="err_message">
          <p class="text-center text-red">{{err_message}}</p>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <button v-if="order?.items?.some(obj=>obj.orderable_type=='App\\Models\\Membership')" type="button" :disabled="loading" class="btn btn-theme mb-2" @click.prevent="$router.push({ name: 'CourseCatalog' })">{{ $t("course-catalog.course-list") }}</button>
          <button v-else type="button" :disabled="loading" class="btn btn-theme mb-2" @click.prevent="$router.push({ name: 'MyCourses' })">{{ $t("my-courses.my-courses") }}</button>
        </div>
      </div>
      <div v-else-if="order.status == 'Canceled'">
        {{ $t("after-payment.error1") }}
      </div>
      <div v-else-if="order.status == 'Expired'">
        {{ $t("after-payment.error2") }}
      </div>
      <div v-else class="w-full pt-5">{{ $t("after-payment.status") }} {{ order.status }}</div>
    </div>
    <Loading v-else-if="loading" height="70vh" />
    <div v-else>
      <div class="w-full pt-5">
        <div class="mx-auto mt-4" style="height: 31px; width: 44px;">
          <svg
              width="44"
              height="31"
              viewBox="0 0 44 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M2 15.3176L15.3176 28.6353L42 2"
                stroke="#00BD2A"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <p class="text-center">{{ $t("after-payment.thanks") }}</p>
      <p class="text-center">{{ $t("after-payment.success") }}</p>
      <button
          class="btn btn-primary"
          @click.prevent="$router.push({ name: 'Home' })"
      >
        {{ $t("after-payment.back") }}
      </button>
    </div>
  </div>
</template>
<script>
import { Capacitor } from "@capacitor/core";
import Loading from "@/components/Loading";

export default {
  name: "AfterPayment",
  components: {
    Loading,
  },
  mounted() {
    if (this.$route.query.paymentId) {
      this.loading = true;
      this.axios
        .post("/payment/status", { paymentId: this.$route.query.paymentId, paymentRequestId:this.$route.params.paymentRequestId })
        .then((resp) => {
          this.order = resp.data.order;
          this.err_message = resp.data.err_message;
          this.$store.state.user = resp.data.user;
        })
        .finally(() => (this.loading = false));
    }
  },
  data() {
    return {
      loading: false,
      order: null,
      err_message:null,
      pdfLoading: false,
    };
  },
  methods: {
    downloadPDF() {
      this.pdfLoading = true;
      this.axios.get('/invoice/'+this.order.invoice_id+'/doc-entry')
          .then(resp => {
            window.open(resp.data.invoice.preview_pdf, '_blank');
            this.pdfLoading=false;
          })
          .finally(() => this.pdfLoading = false);
    },
  },
};
</script>
<style></style>
