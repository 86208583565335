<template>
    <canvas :id="'courseTestQuestion'+id"></canvas>
</template>
<script>
export default {
    name: 'courseTestQuestionChart',
    props: {
        data: Object,
        id: Number
    },
    mounted() {
        console.log(this.data)
        let backgroundColors = []
        let borderColors = []
        this.data.forEach(d => {
            backgroundColors.push(d.correct ? 'rgb(40, 167, 69, 0.8)' : 'rgb(220, 53, 69, 0.8)')
            borderColors.push(d.correct ? 'rgb(40, 167, 69, 1)' : 'rgb(220, 53, 69, 1)')
        })
        //Chart.defaults.scale.gridLines.display = false;
        const ctx = $('#courseTestQuestion'+this.id)
        this.chart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: this.data.map(d => d.answer),
                datasets: [
                    {
                        data: this.data.map(d => d.count),
                        backgroundColor: backgroundColors,
                        borderColor: borderColors,
                    }
                ]
            },
            options: {
                legend: {
                    display: false,
                    labels: {
                        fontColor: 'rgb(255, 99, 132)'
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1
                        }
                    }]
                }
            }
        });
    },
    data() {
        return {
            chart: null
        }
    }
}
</script>