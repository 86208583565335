<template>
    <canvas id="courseFinishCredit"></canvas>
</template>
<script>
export default {
    name: 'CourseFinishCreditChart',
    props: {
        data: Array,
        unit: String,
        isBar: {
            default: false 
        }
    },
    mounted() {
        const ctx = $('#courseFinishCredit')
        this.chart = new Chart(ctx, {
            type: this.isBar? 'bar' : 'line' ,
                data: 
                {   
                    labels: this.data[0].map(d => d.x),
                    datasets: 
                    [
                        {
                            data: this.data[0].map(d => d.y),
                            borderWidth: 2,
                            borderColor: ' #05213E',
                            backgroundColor: ' #05213E',
                            label: this.lab1,
                            fill: false,
                            lineTension: 0, 
                            options: {
                                cubicInterpolationMode: 'monotone',
                                lineTension: 0, 
                            }
                        },
                        {

                            data: this.data[1].map(d => d.y),
                            borderWidth: 2,
                            borderColor: "var(--red)",
                            backgroundColor: "var(--red)",
                            label: this.lab2,
                            fill: false,
                            type: this.isBar? 'bar' : 'line',
                            lineTension: 0, 
                            options: {
                                cubicInterpolationMode: 'monotone',
                                lineTension: 0, 
                            }
                        },
                    ]
                },
                options:
                {  
                    scales: 
                    {
                        xAxes: 
                        [
                            {
                                ticks: {
                                    autoSkip: false,
                                }
                            },
                        ],
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    },
                }  
        });
    },
    data() {
        return {
            chart: null
        }
    },
    computed: {
        lab1() {
            return this.$t('chart.finished-courses')
        },
        lab2() {
            return this.$t('chart.earned-credits')
        },
    },
    watch: {
        lab1() {
            if(this.chart) {
                this.chart.data.datasets[0].label = this.lab1
                this.chart.data.datasets[1].label = this.lab2
                this.chart.update()
            }
        },
        data() {
            if(this.chart) {
                this.chart.data.labels = this.data[0].map(d => d.x)
                this.chart.data.datasets.forEach((dataset,index) => {
                    this.chart.data.datasets[index].data = this.data[index].map(d => d.y)
                })
                this.chart.update()
            }
        }
    }
}
</script>