<template>
  <div>
    <section id="content-2">
      <Carousel />
      <div class="container">
        <div class="row">
<!--
          <div class="col-lg-6">
            <section id="timeline">
              <Feed />
            </section>
          </div>
-->
          <div class="col-lg-12">
            <section id="trainings">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('master-home.suggested-courses') }}</h2>
                </div>
                <div v-for="course in recommendedCourses" :key="course.id"
                     class="col-md-4 mb-4 col-12"
                     style="cursor: pointer;"
                     @click="$router.push({name: 'Course', params: {id: course.id}})"
                >
                  <course-card :course="course" />
                </div>
                <div v-if="recommendedCourses.length == 0" class="col-md-6 mb-4 col-12">
                  <h6 class="text-13 text-dark-gray semi-bold">{{ $t('admin-home.no-recommended') }}</h6>
                </div>
              </div>
              <!--      New Courses       -->
              <div v-if="newCourses.length != 0" class="row">
                <div class="col-12">
                  <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('master-home.new-courses') }}</h2>
                </div>
                <div v-for="course in newCourses" :key="course.id"
                     class="col-md-3 mb-3 col-12"
                     style="cursor: pointer;"
                     @click="$router.push({name: 'Course', params: {id: course.id}})"
                >
                  <course-card :course="course" />
                </div>
                <div v-if="newCourses.length == 0" class=" mb-4 ml-4 col-12">
                  <h6 class="text-13 text-dark-gray semi-bold">{{ $t('admin-home.no-recommended') }}</h6>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CourseCard from "@/components/CourseCard";
import Feed from "@/components/Feed";

export default {
  name: 'NonMemberHome',
  components: {CourseCard, Feed},
  mounted() {
    this.axios.get('/courses/recommended')
        .then(resp => {
          this.recommendedCourses = resp.data.courses
        });
    this.axios.get('/memberships/1')
        .then(resp => {
          this.membership = resp.data.membership
        })
        .finally(() => this.loading = false);
    this.axios.get('/glossary-articles')
        .then(resp => {
          this.glossaryArticles = resp.data.glossaryArticles.slice(0,4)
        });
    this.axios.get('/courses/new')
        .then(resp => {
          this.newCourses = resp.data.courses
        });
  },
  data() {
    return {
      recommendedCourses: [],
      newCourses: [],
      membership: null,
      glossaryArticles: []
    }
  },
  methods: {
    buyMembership() {
      if(this.submitting) return;
      if(this.$store.state.user.cart_items.some(c => c.cartable_type == 'App\\Models\\Membership' && c.cartable_id == this.membership.id)) {
        this.$router.push({name: 'Cart'})
        return;
      }
      this.submitting = true
      this.axios.post(`/memberships/${this.membership.id}/cart`)
          .then(resp => {
            const cartItem = resp.data.cart_item
            this.$store.state.user.cart_items.push(cartItem)
            this.$router.push({name: 'Cart'})
          })
          .catch(e => console.log(e))
          .finally(() => this.submitting = false)
    }
  }
}
</script>
