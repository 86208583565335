<template>
<div>
    <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="flex-wrap d-flex">
                        <div class="d-flex mr-4 pointer">
                            <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('reporting.homepage') }}</span>
                        </div>
                        <div class="d-flex mr-4">
                            <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                        </div>
                        <div class="d-flex mr-4 pointer">
                            <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Reporting'})">{{ $t('reporting.statistic') }}</span>
                        </div>
                        <div class="d-flex mr-4">
                            <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                        </div>
                        <div class="d-flex mr-4 pointer">
                            <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'CourseReports'})">{{ $t('reporting.course-statistics') }}</span>
                        </div>
                        <div class="d-flex mr-4">
                            <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                        </div>
                        <div class="d-flex">
                            <span class="text-16 text-white pt-3 pb-3">{{ $t('reporting.course-statistic') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- //BREADCRUMB -->
    <div id="content-2">
        <div class="container">
            <div class="row" v-show="!loading">
                <div class="col-12 mt-2 text-left" v-if="course"><h3 class="text-22 text-dark-gray semi-bold mb-4">{{course.title}}</h3></div>
                <div class="col-md-6 col-12 mb-4">
                    <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('course-report.users') }}</h3>
                    <div class="chart-box bg-white filter-shadow custom-input">
                    <canvas id="courseCompletedByTime"></canvas>
                    </div>
                </div>
                <div class="col-md-6 col-12 mb-4">
                    <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('course-report.role') }}</h3>
                    <div class="chart-box bg-white filter-shadow custom-input">
                    <canvas height="150" id="courseCompletedByRole"></canvas>
                    </div>
                </div>
                <div class="col-md-6 col-12 mb-4">
                    <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('course-report.unfinished') }}</h3>
                    <div class="chart-box bg-white filter-shadow custom-input">
                    <canvas height="150" id="courseUnfinishedMaterials"></canvas>
                    </div>
                </div>
                <div class="col-md-6 col-12 mb-4">
                    <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('course-report.view-counts') }}</h3>
                    <div class="chart-box bg-white filter-shadow custom-input">
                    <canvas id="courseMaterialViewCounts"></canvas>
                    </div>
                </div>
                <div class="col-md-6 col-12 mb-4" v-if="course">
                    <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('course-report.netto-time') }}</h3>
                    <div class="chart-box bg-white filter-shadow custom-input">
                    <net-time-chart :data="course.usersFinished.map(u => u.net_time)" />
                     </div>
                </div>
                <div class="col-md-6 col-12 mb-4" v-if="course">
                    <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('course-report.gross-time') }}</h3>
                    <div class="chart-box bg-white filter-shadow custom-input">
                    <gross-time-chart :data="course.usersFinished" />
                    </div>
                </div>
                <div class="col-md-6 col-12 mb-4" v-if="course">
                    <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('course-report.browser') }}</h3>
                    <div class="chart-box bg-white filter-shadow custom-input">
                    <browser-chart :data="course.usersFinished.map(u => u.devices).flat()" />
                    </div>
                </div>
                <div class="col-md-6 col-12 mb-4" v-if="course">
                    <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('course-report.device') }}</h3>
                    <div class="chart-box bg-white filter-shadow custom-input">
                    <device-chart :data="course.usersFinished.map(u => u.devices).flat()" />
                    </div>
                </div>
                <div class="col-md-6 col-12 mb-4" v-if="course">
                    <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('course-report.op-system') }}</h3>
                    <div class="chart-box bg-white filter-shadow custom-input">
                    <os-chart :data="course.usersFinished.map(u => u.devices).flat()" />
                    </div>
                </div>
                <div v-if="course" class="col-12 col-md-6" v-for="(video, idx) in course.videoMaterials">
                    <h3 class="text-18 text-dark-gray semi-bold mb-3"><strong>{{video.course_multimedia.name}}</strong> {{ $t('course-report.stopped') }}</h3>
                    <canvas :id="'courseMaterialViewCounts'+idx"></canvas>
                </div>
            </div>
        </div>
    </div>
    <loading height="40vh" v-show="loading" />
</div>
</template>
<script>
import { DateTime } from 'luxon';
import randomColor from 'randomcolor'
import Loading from '../components/Loading.vue';
import NetTimeChart from '../components/Charts/NetTimeChart.vue';
import GrossTimeChart from '../components/Charts/GrossTimeChart.vue';
import BrowserChart from '../components/Charts/BrowserChart.vue';
import DeviceChart from '../components/Charts/DeviceChart.vue';
import OsChart from '../components/Charts/OsChart.vue';

export default {
    components: { Loading, NetTimeChart, GrossTimeChart, BrowserChart, DeviceChart, OsChart },
    name: 'CourseReport',
    mounted() {
        this.axios.get('/courses/'+this.$route.params.course+'/report')
            .then(resp =>
            {
                this.course = resp.data
                this.getCourseFinishedByRole()
                this.getCourseFinishedByTime()
                this.getCourseUnfinishedMaterials()
                this.getMaterialViewCounts()
                this.$nextTick(() => {
                    this.course.videoMaterials.forEach((vm,idx) => {
                        this.getVideoMaterialAbandons(idx)
                    })
                })
            })
            .finally(() => this.loading = false)
    },
    data() {
        return {
            course: null,
            loading: true,
            materialViewCountChart: null,
            courseFinishedByTimeChart: null,
            materialAbandonsChart: null
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale
        }
    },
    watch: {
        locale() {
            if(this.materialViewCountChart) {
                this.materialViewCountChart.data.datasets[0].label = this.$t('course-report.script-label-1')
                //this.chart.data.datasets[1].label = this.$t('chart.finished-courses')
                //this.chart.data.datasets[2].label = this.$t('chart.ratio')
                this.materialViewCountChart.update()
            }
            if(this.courseFinishedByTimeChart) {
                this.courseFinishedByTimeChart.data.datasets[0].label = this.$t('course-report.script-label-2')
                //this.chart.data.datasets[1].label = this.$t('chart.finished-courses')
                //this.chart.data.datasets[2].label = this.$t('chart.ratio')
                this.courseFinishedByTimeChart.update()
            }
            if(this.materialAbandonsChart) {
                this.materialAbandonsChart.data.datasets[0].label = this.$t('course-report.script-label-1')
                //this.chart.data.datasets[1].label = this.$t('chart.finished-courses')
                //this.chart.data.datasets[2].label = this.$t('chart.ratio')
                this.materialAbandonsChart.update()
            }
        }
    },
    methods: {
        getVideoMaterialAbandons(idx) {
            let colorArr = randomColor({count: this.course.course_materials.length})
            let colorBorderArr = colorArr.map(c => this.convertHexToRgba(c, 100))
            colorArr = colorArr.map(c => this.convertHexToRgba(c, 20))
            const userCount = this.course.users.length
            let data = this.course.videoMaterials[idx].course_material_progress.map(cmp => cmp.progress)
            let labels = [];
            for(let i = 1; i < 11; i++) {
                let length = this.course.videoMaterials[idx].course_multimedia.length
                labels.push(Math.round((length / 10) * i))
            }
            data.forEach((p, i) => {
                let closest = Math.abs(p - labels[0])
                let closestIdx = 0
                labels.forEach((l, j) => {
                    const dist = Math.abs(p - l)
                    if(dist < closest) {
                        closest = dist
                        closestIdx = j
                    }
                })
                data[i] = labels[closestIdx]
            })
            console.log(data)
            let ctx = $('#courseMaterialViewCounts'+idx)
            this.materialAbandonsChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    datasets: [{
                        data: data,
                        label: this.$t('course-report.script-label-1'),
                        backgroundColor: colorArr,
                        borderColor: colorBorderArr,
                        borderWidth: 1
                        }],

                    // These labels appear in the legend and in the tooltips when hovering different arcs
                    labels: labels
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                stepSize: 1
                            }
                        }]
                    }
                }
            });
        },
        getCourseUnfinishedMaterials(){
            let colorArr = randomColor({count: this.course.course_materials.length})
            let colorBorderArr = colorArr.map(c => this.convertHexToRgba(c, 100))
            colorArr = colorArr.map(c => this.convertHexToRgba(c, 20))
            const userCount = this.course.users.length
            const data = this.course.abandoned.map(cm => userCount - (cm.course_material_progress.length - cm.courseMaterialProgressAbandoned.length))
            let ctx = $('#courseUnfinishedMaterials')
            let chart = new Chart(ctx, {
                type: 'pie',
                data: {
                    datasets: [{
                        data: data,
                        backgroundColor: colorArr,
                        borderColor: colorBorderArr,
                        borderWidth: 1
                        }],

                    // These labels appear in the legend and in the tooltips when hovering different arcs
                    labels: this.course.course_materials.map(cm => this.getName(cm))
                }
            });

        },
        getCourseFinishedByTime() {
            let courseCompletedByDate = Object()
            this.course.usersFinished.forEach(u => {
                const finishedAt =  `${DateTime.fromISO(u.finished_at, {locale: 'hu'}).monthShort} ${DateTime.fromISO(u.finished_at, {locale: 'hu'}).day}`
                courseCompletedByDate[finishedAt] = courseCompletedByDate[finishedAt] ? courseCompletedByDate[finishedAt] + 1 : 1
            })
            let colorArr = randomColor({count: Object.keys(courseCompletedByDate).length})
            let colorBorderArr = colorArr.map(c => this.convertHexToRgba(c, 100))
            colorArr = colorArr.map(c => this.convertHexToRgba(c, 20))
            let ctx = $('#courseCompletedByTime')
            this.courseFinishedByTimeChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: Object.keys(courseCompletedByDate),
                    datasets: [{
                        label: this.$t('course-report.script-label-2'),
                        data: Object.values(courseCompletedByDate),
                        backgroundColor: colorArr,
                        borderColor: colorBorderArr,
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                stepSize: 1
                            }
                        }]
                    }
                }
            });
        },
        getCourseFinishedByRole() {
            let courseCompletedByRole = {
                'Member User': 0,
                'Master': 0,
                'User': 0,
                'Admin': 0
            }
            this.course.usersFinished.forEach(u => {
                courseCompletedByRole[u.role]++
            })
            let ctx = $('#courseCompletedByRole');
            let chart = new Chart(ctx, {
                type: 'pie',
                data: {
                    datasets: [{
                        data: [courseCompletedByRole['Member User'], courseCompletedByRole['Master'], courseCompletedByRole['User'], courseCompletedByRole['Admin']],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                        ],
                        borderWidth: 1
                        }],

                    // These labels appear in the legend and in the tooltips when hovering different arcs
                    labels: [
                        'XBody User',
                        'Master',
                        'User',
                        'Admin'
                    ]
                }
            });
        },
        getMaterialViewCounts() {
            let colorArr = randomColor({count: this.course.course_materials.length})
            let colorBorderArr = colorArr.map(c => this.convertHexToRgba(c, 100))
            colorArr = colorArr.map(c => this.convertHexToRgba(c, 20))
            const userCount = this.course.users.length
            const data = this.course.abandoned.map(cm => cm.course_material_progress.length - cm.courseMaterialProgressAbandoned.length)
            let ctx = $('#courseMaterialViewCounts')
            this.materialViewCountChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    datasets: [{
                        data: data,
                        label: this.$t('course-report.script-label-1'),
                        backgroundColor: colorArr,
                        borderColor: colorBorderArr,
                        borderWidth: 1
                        }],

                    // These labels appear in the legend and in the tooltips when hovering different arcs
                    labels: this.course.course_materials.map(cm => this.getName(cm))
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                stepSize: 1
                            }
                        }]
                    }
                }
            });
        },
        getUsersFinishedBrowsers() {
            let chart = new this.Chart(ctx, {
                type: 'pie',
                data: {
                    datasets: [{
                        data: [courseCompletedByRole['Member User'], courseCompletedByRole['Master'], courseCompletedByRole['User'], courseCompletedByRole['Admin']],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                        ],
                        borderWidth: 1
                        }],

                    // These labels appear in the legend and in the tooltips when hovering different arcs
                    labels: [
                        'Chrome',
                        'Safari',
                        'Firefox',
                        'Opera',
                        'Edge'
                    ]
                }
            });
        },
        getName(courseMaterial) {
            console.log(courseMaterial)
            if(courseMaterial.course_test) {
                return courseMaterial.course_test.test.name
            }
            else if(courseMaterial.course_event) {
                return courseMaterial.course_event.name
            }
            else {
                return courseMaterial.course_multimedia.name
            }
        },
        convertHexToRgba(hexCode, opacity) {
            console.log(hexCode)
            let hex = hexCode.replace('#', '');

            if (hex.length === 3) {
                hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
            }

            const r = parseInt(hex.substring(0, 2), 16);
            const g = parseInt(hex.substring(2, 4), 16);
            const b = parseInt(hex.substring(4, 6), 16);

            return `rgba(${r},${g},${b},${opacity / 100})`;
        }
    }
}
</script>
<style scoped>
    .chart-box {
        padding: 15px;
        border-radius: 8px;
    }
</style>
