<template>
    <div v-if="loading" class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</template>
<script>
export default {
    name: 'InfiniteScroll',
    props: {
        shouldLoad: Boolean,
        bottomScroll: {
            optional: true,
            type: Boolean,
            default: true,
        },
        element: {
            optional: true,
            default: window
        }
    },
    emits: ['shouldLoad'],
    data() {
        return {
            loading: false,
            jqueryElement: null,
            stateChanger: {
                finishLoad: () => {
                    this.loading = false
                },
                dontLoadMore: () => {
                    this.shouldLoad = false
                }
            }
        }
    },
    mounted() {
        this.jqueryElement = $(this.element)
        this.element.addEventListener("scroll", this.scroll)
    },
    beforeUnmount() {
        this.element.removeEventListener("scroll", this.scroll)
    },
    methods: {
        scroll () {
            const scollThresholdReached = this.isScrollThresholdReached()

            if (scollThresholdReached && this.shouldLoad && !this.loading) {
                this.loading = true
                this.$emit('shouldLoad', this.stateChanger)
            }
        },
        getDocHeight() {
            if(this.element === window) {
                var D = document;
                return Math.max(
                    D.body.scrollHeight, D.documentElement.scrollHeight,
                    D.body.offsetHeight, D.documentElement.offsetHeight,
                    D.body.clientHeight, D.documentElement.clientHeight
                );
            }
            else {
                return Math.max(this.element.scrollHeight, this.element.offsetHeight, this.element.clientHeight)
            }
        },
        isScrollThresholdReached() {
            if(this.bottomScroll)
                return this.jqueryElement.scrollTop() + this.jqueryElement.innerHeight() === this.getDocHeight()
            else {
                return this.jqueryElement.scrollTop() == 0
            }
        }
    },
}
</script>