<template>
    <NonMemberHome v-if="user && (user.role == 'Member User' || user.role == 'User') && user.hasActiveMembership == false" />
    <DLAUserHome v-else-if="user && user.role == 'Member User'" />
    <AdminHome v-else-if="user && user.role == 'Admin'" />
    <MasterHome v-else-if="user && user.role == 'Master'" />
    <UserHome v-else-if="user && user.role == 'User'" />
</template>
<script>
import DLAUserHome from '@/components/DLAUserHome.vue'
import AdminHome from '@/components/AdminHome.vue'
import MasterHome from '@/components/MasterHome.vue'
import UserHome from '@/components/UserHome.vue'
import NonMemberHome from '@/components/NonMemberHome.vue'

export default {
    name: "Home",
    components: {
        DLAUserHome,
        AdminHome,
        MasterHome,
        UserHome,
        NonMemberHome,
    },
    computed: {
      user() {
          return this.$store.state.user
      }
  }
}
</script>
