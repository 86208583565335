<template>
    <footer :class="$store.state.isNative ? 'footer' : ''" class="bg-dark-gray d-flex flex-column" id="footer">
        <div class="row d-flex flex-column flex-xl-row text-white footer-1 align-self-center align-self-xl-stretch">
            <div class="col-lg-4 flex-grow-1 d-flex flex-column branding text-white align-items-start">
                <img src="../assets/img/EMS.min.png" style="width: 169px; height: 49.2px; background-color: white"
                    class="logo" alt="">
                <!--                <p class="branding-text mt-1 mb-3 text-14 mt-2">{{$t('footer.know-more')}}</p>
                <button class="text-white bg-red px-4 py-2 border-0 rounded" @click.prevent="$router.push({name: 'Feedback'})">{{$t('footer.contact-us')}}</button>-->
            </div>
            <div class="col-lg-8 d-flex flex-column gap-4 pt-2 flex-md-row align-self-start text-gray text-14 mt-xl-0">
                <div v-for="customPageGroup in $store.state.customPageGroups" :key="customPageGroup.id"
                    class="d-flex flex-column align-items-start link-column" style="flex-basis: 100%">
                    <h3 class="fs-5 fw-normal text-white text-20 mb-1">
                        {{ customPageGroup.title[$store.state.locale] ? customPageGroup.title[$store.state.locale] :
                            customPageGroup.title['en'] }}
                    </h3>
                    <a v-for="customPage in $store.state.customPages.filter(p => p.group_id === customPageGroup.id)"
                        :key="customPage.id" class="" :href="customPage.link" @click.prevent="processLink(customPage.link)">
                        {{ customPage.title[$store.state.locale] ? customPage.title[$store.state.locale] :
                            customPage.title['en'] }}
                    </a>
                </div>
            </div>
            <div class="col-12 row mt-2">
                <div class="col-md-6 pb-2 ">
                    <img class=" d-block" style="max-width: 350px;" src="../assets/img/barion-card-strip-intl.svg"
                        title="barion" alt="">
                </div>

                <p class="col-md-6 text-end text-13  text-gray ">&copy; Copyright by XBody, designed by <strong
                        class="text-white"><a href="https://weborigo.com" target="_blank">WebOrigo</a></strong>.</p>

            </div>
        </div>
        <hr class="text-white hr" />

    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            scrollbarVisible: false,
            resizeObserver: null
        }
    },
    mounted() {
        this.resizeObserver = new ResizeObserver(entries => {
            this.scrollbarVisible = $(document).height() - $('#footer').height() > $(window).height()
        })
        this.$nextTick(() => {
            this.resizeObserver.observe($('.content-padding').first()[0])
        })
    },
    methods: {
        beforeDestroy() {
            this.resizeObserver.unobserve($('.content-padding').first()[0])
        },
        scrollTop() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
        processLink(link) {
            if (link.includes(process.env.VUE_APP_FRONTEND_URL)) {
                link = link.split(process.env.VUE_APP_FRONTEND_URL)[1]
                this.$router.push({ path: link })
            }
            else {
                window.open(link, '_blank');
            }
        }
    }
}
</script>
<style scoped>
footer {
    margin-top: 30px;
}

.logo {
    height: auto;
    width: 171px;
}

.branding-text {
    width: 216px;
}

.link-column>* {
    margin-bottom: 0.75rem;
}

.footer-1 {
    margin-top: 32px;
    margin-bottom: 12px;
    margin-left: 96px;
    margin-right: 96px;
}

.hr {
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 0;
    margin-bottom: 0;
}

.copyright {
    margin-right: 41px;
    margin-top: 14px;
    margin-bottom: 14px;
}

footer a:hover {
    cursor: pointer;
    text-decoration: none !important;
    color: var(--red) !important;
}
</style>
