<template>
    <div class="modal fade" :id="'attendanceSheetModal'+event.id" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title">{{ $t('attendance-sheet.title') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body pb-0">
                <div class="row" v-if="!loading">
                    <div class="col-12 mt-2 mb-1">
                        <div class="mb-4 font-18">
                            <div>{{$t('attendance-sheet.all-users')}}  <strong>{{users.length}}</strong></div>
                            <div>{{$t('attendance-sheet.attended')}} <strong class="text-success">{{users.filter(u => u.present).length}}</strong></div>
                            <div>{{$t('attendance-sheet.did-not-attend')}} <strong class="text-danger">{{users.filter(u => !u.present).length}}</strong></div>
                        </div>
                        <div v-if="!canSubmit" class="mb-2">
                            <i class="fas fa-info-circle text-warning"></i> {{$t('attendance-sheet.after-event-end', {eventEnd: $store.getters.parseDateTime(eventEnd.toISO())})}}
                        </div>
                        <div class="d-flex" style="justify-content: space-between">
                            <div class="font-17">{{$t('attendance-sheet.user')}}</div>
                            <div class="font-17">{{$t('attendance-sheet.attended')}}</div>
                        </div>
                        <hr class="mt-2 mb-2">
                        <div v-for="user in users" :key="user.id">
                            <div class="d-flex align-items-center" style="justify-content: space-between">
                                <div class="d-flex align-items-center">
                                    <img v-if="user.avatar" :src="user.avatar.path+'?token='+$store.state.token" style="border-radius: 50%; height: 50px" class="ml-0" alt="">
                                    <avatar v-else class="team-image ml-0" :username="user.name" :size="50"/>
                                    <h6 class="name ml-2 mt-0 pl-0" style="font-weight: bold" :class="user.present ? 'text-success' : 'text-danger'">
                                        {{user.name}} <br />
                                        <span class="email mb-1">{{user.email}}</span>
                                    </h6>
                                </div>
                                <div class="mr-4" style="height: 20px;">
                                    <input style="width: 20px; height: 20px;" v-model="user.present" :disabled="originalUsers.find(u => u.id === user.id).present || !canSubmit" type="checkbox">
                                </div>
                            </div>
                            <hr class="mt-2 mb-2">
                        </div>
                    </div>
                </div>
                <loading height="100%" v-else />
            </div>
            <div class="modal-footer pt-0">
                <div class="d-flex align-items-center mb-3">
                    <div class="ml-2"> <input style="width: 14px; !important; height: 14px;" v-model="accepted" :disabled="submitting || !canSubmit" type="checkbox"> {{$t('attendance-sheet.warn')}} <strong>{{$t('attendance-sheet.not-possible')}}</strong>.</div>
                </div>
              <PrimaryButton :click="submit" :disabled="submitting || !accepted || !canSubmit" :text="$t('common.save-changes')" />
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import {DateTime} from 'luxon'
import Avatar from './Avatar.vue'
import Loading from './Loading.vue'
import PrimaryButton from "./settings/PrimaryButton.vue";
export default {
  components: {PrimaryButton, Avatar, Loading },
    name: 'AttendanceSheetModal',
    props: {
        event: Object,
        course: Object
    },
    mounted() {
        this.axios.get(`/courses/${this.course.id}/events/${this.event.id}/users`)
            .then(resp => {
                this.users = resp.data.users
                this.originalUsers = JSON.parse(JSON.stringify(this.users))
            })
            .catch(e => console.log(e))
            .finally(() => this.loading = false)
    },
    data() {
        return {
            loading: true,
            accepted: false,
            submitting: false,
            users: [],
            originalUsers: []
        }
    },
    computed: {
        eventEnd() {
            return DateTime.fromISO(this.event.start_date).plus({minutes: this.event.length})
        },
        canSubmit() {
            return this.eventEnd < DateTime.local()
        }
    },
    methods: {
        submit() {
            this.submitting = true
            this.axios.post(`/courses/${this.course.id}/events/${this.event.id}/users`, {usersPresent: this.users.filter(u => u.present).map(u => u.id)})
                .then(resp => {
                    this.originalUsers = JSON.parse(JSON.stringify(this.users))
                    this.accepted = false
                })
                .catch(e => console.log(e))
                .finally(() => this.submitting = false)
        }
    }
}
</script>
<style scoped>
    /* MOBILE FONT SIZE UNDER 426px */
    @media (min-width: 426px) {
        .font-17 {
            font-size: 17px !important;
        }
        .font-18 {
            font-size: 18px !important;
        }
    }

    @media (max-width: 425px) {
        .font-17 {
            font-size: 12px !important;
        }
        .font-18 {
            font-size: 13px !important;
        }
    }
</style>
