<template>
    <image-element v-if="type == 'image'" :element="element" @progressMade="makeProgress" />
    <test-element v-else-if="type == 'test'" :element="element" :attempts="$parent.course.test_attempts.filter(ta => ta.test_id == element.course_test.test_id)" @pullback="$emit('pullback')" @loadCourse="$emit('loadCourse')"/>
    <document-element v-else-if="type == 'document'" @progressMade="makeProgress" :element="element" />
    <audio-video-element v-else-if="type == 'video' || type == 'audio'" :element="element" @progressMade="makeProgress" />
    <event-element v-else-if="type == 'event'" :element="element" />
</template>
<script>
import AudioVideoElement from './AudioVideoElement.vue'
import DocumentElement from './DocumentElement.vue'
import EventElement from './EventElement.vue'
import ImageElement from './ImageElement.vue'
import TestElement from './TestElement.vue'
export default {
    components: { ImageElement, TestElement, DocumentElement, AudioVideoElement, EventElement },
    props: {element: Object},
    emits: ['progressMade', 'pullback', 'loadCourse'],
    name: 'CourseMaterialListElement',
    created() {
        this.type = this.getType(this.element)
    },
    data() {
        return {
            type: null
        }
    },
    methods: {
        getType(material) {
            if(material.course_multimedia) {
                return material.course_multimedia.multimedia.type
            }
            else if(material.course_event) {
                return 'event'
            }
            else {
                return 'test'
            }
        },
        makeProgress(element, unlockNext = false) {
            this.$emit('progressMade', element, unlockNext)
        }
    }
}
</script>
