<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-users.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('admin-menu.exchange-rates') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->
        <div id="content-2">
            <div class="container">
                <div class="row mt-5">
                    <div class="col-12">
                        <h2 class="text-18 text-dark-gray semi-bold mb-3"><i class="fas fa-euro-sign mr-2"></i> {{ $t('admin-menu.exchange-rates') }}</h2>
                    </div>
                    <div class="col-12">
                        <section id="exchange-list" class="mt-2">
                            <div class="row">
                                <!-- EXCHANGE OPTION -->
                                <div v-for="rate in exchangeRates" :key="rate.id" class="col-lg-3 col-md-4 col-md-6 col-12 mb-3">
                                    <div class="exchange-card filter-shadow">
                                        <div class="exchange-header bg-light-gray-primary">
                                            <div class="flex-wrap d-flex align-items-center">
                                                <div class="d-flex align-items-center ml-auto">
                                                    <h3 class="text-16 text-white">1 {{rate.primary_currency}} =</h3>
                                                </div>
                                                <div class="d-flex custom-input align-items-center">
                                                    <div class="form-group">
                                                        <input type="text" v-model="rate.exchange_rate">
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mr-auto ml-2">
                                                    <h3 class="text-16 text-white">{{rate.secondary_currency}}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="exchange-bottom text-center">
                                            <button class="btn btn-theme" @click.prevent="updateRate(rate)" :disabled="submitting">{{$t('common.save')}}</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- //EXCHANGE OPTION -->
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loading from '../components/Loading.vue'
export default {
    components: { Loading },
    name: 'ExchangeRates',
    mounted() {
        this.axios.get('/exchange-rates')
            .then((resp) => {
                this.exchangeRates = resp.data.exchangeRates
            })
            .catch(e => console.log(e?.response))
            .finally(() => this.loading = false)
    },
    data() {
        return {
            loading: true,
            exchangeRates: [],
            submitting: false
        }
    },
    methods: {
        updateRate(rate) {
            this.submitting = true
            this.axios.patch('/exchange-rates', {currency: rate.secondary_currency, rate: rate.exchange_rate})
                .catch(e => console.log(e?.response))
                .finally(() => this.submitting = false)
        }
    }
}
</script>
<style scoped>
    .exchange-header {
        padding: 10px 0px;
        border-radius: 8px;
    }
    .exchange-bottom {
        padding: 15px 10px;
    }
    .exchange-header input {
        background-color: #aabac6 !important;
        border-color: #aabac6 !important;
        height: 24px;
        width: 78px !important;
        font-size: 16px !important;
        margin-left: 10px;
    }
    .exchange-header .custom-input {
        margin-top: 15px;
    }
    .btn-outline-primary {
        transition: 0.2s all;
        margin-bottom: 0px !important;
    }

    .btn-outline-primary:hover,
    .btn-outline-primary:active,
    .btn-outline-primary:focus {
        background-color: var(--red);
        border-color: var(--red);
        transition: 0.2s all;
        color: var(--white);
    }
</style>