<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('edit-test.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'ManageTests'})">{{ $t('edit-test.managing-tests') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('edit-test.edit') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div class="content-2">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div id="text-assembler" class="custom-input" v-if="!loading && test">
              <form @submit.prevent="submit">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="name">{{ $t('edit-test.name') }}</label>
                          <input :disabled="finalized" type="text" v-model="test.name" class="form-control" id="name"
                                 required>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="period">{{ $t('edit-test.duration') }}</label>
                          <input :disabled="finalized" type="number" v-model="test.length" min="0" class="form-control"
                                 id="period" required>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label class="mb-0 mt-3" for="try-number">{{ $t('edit-test.size') }}</label><br>
                          <sub><img src="assets/img/icons/info-circle.svg" class="mb-1" alt="">
                            {{ $t('edit-test.max-size') }} {{ test.test_questions.length }}</sub>
                          <input type="number" @input="changePoolSize" :value="test.question_pool_size" min="0"
                                 :max="test.test_questions.length" class="mt-1 form-control" id="try-number" required>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="try-number">{{ $t('edit-test.max-attempts') }}</label>
                          <input type="number" v-model="test.max_attempts" min="1" class="form-control" id="try-number"
                                 required>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="min-points">{{ $t('edit-test.min-percent') }}</label>
                          <input :disabled="finalized" type="number" min="0" v-model="test.min_pass_percentage"
                                 class="form-control" id="min-points" required>
                        </div>
                      </div>
                      <div class="col-12 mb-3">
                        <div class="flex-wrap d-flex">
                          <div class="d-flex align-items-center pointer mr-auto"
                               @click="$router.push({name: 'TestQuestionAssembler', params: {test: test.id}})">
                            <div class="new-button-40 d-flex align-items-center justify-content-center">
                              <img src="assets/img/icons/pen.svg" alt="">
                            </div>
                            <div class="d-flex flex-column ml-2">
                              <h6 class="text-16 text-dark-gray">{{ $t('edit-test.edit-questions-answers') }}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-3">
                        <label>{{ $t('edit-test.delete') }}</label>
                        <div v-if="test.test_attempts && test.test_attempts.length>0" class="d-flex align-items-center mt-1 mb-0">
                          <i class="fas fa-info-circle mr-1 text-danger"></i>
                          <div>{{ $t('edit-test.delete-info') }}</div>
                        </div>
                        <button type="button" :disabled="loading || (test.test_attempts && test.test_attempts.length>0)" data-toggle="modal"
                                data-target="#deleteTestModal"
                                class="btn btn-danger w-100 mt-2">{{ $t('edit-test.delete') }}
                        </button>
                      </div>
                      <div class="col-12 mb-3">
                        <button type="button" :disabled="loading" @click.prevent="duplicate"
                                class="btn btn-secondary w-100 mt-2">{{ $t('edit-test.duplicate') }}
                        </button>
                      </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="start">{{ $t('edit-test.start') }}</label>
                        <datetime type="datetime" :max-datetime="test.end_date" v-model="test.start_date"
                                  input-class="form-control"/>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label for="end">{{ $t('edit-test.end') }}</label>
                        <datetime type="datetime" :min-datetime="test.start_date" v-model="test.end_date"
                                  input-class="form-control"/>
                      </div>
                    </div>
                    <div class="col-12 mb-3">
                      <label class="mb-0 mt-3">{{ $t('edit-course.visibility') }} </label><br>
                      <sub><img src="assets/img/icons/info-circle.svg" class="mb-1" alt=""> Please set a question bank
                        size greater than 0 before publishing</sub>
                      <div v-if="test.question_pool_size < 1" class="d-flex align-items-center mb-1">
                        <i class="fas fa-info-circle text-info font-18" style="font-size: 18px"></i>
                        <div class="ml-2">{{ $t('test-question-assembler.before') }}</div>
                      </div>
                      <select class="mt-1 form-control" :disabled="finalized || test.question_pool_size < 1"
                              :class="!test.hidden ? 'text-success' : 'text-danger'" v-model="test.hidden" required>
                        <option class="text-success" :value="0">{{ $t('edit-course.public') }}</option>
                        <option class="text-danger" :value="1">{{ $t('edit-course.hidden') }}</option>
                      </select>
                    </div>
                    <div class="col-12">
                      <label class="c-inp"> <span>{{ $t('edit-test.shuffle-q') }}</span>
                        <input type="checkbox" v-model="test.shuffle_questions" true-value="1" false-value="0">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="col-12">
                      <label class="c-inp"> <span>{{ $t('edit-test.shuffle-a') }}</span>
                        <input type="checkbox" v-model="test.shuffle_answers" true-value="1" false-value="0">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="col-12">
                      <label class="c-inp"> <span>{{ $t('edit-test.fillable') }}</span>
                        <input type="checkbox" v-model="test.fillable" true-value="1" false-value="0">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div v-if="test.attempt_package" class="col-12">
                      <label class="c-inp"> <span>{{ $t('test-assembler.add-attempt-package') }}</span>
                        <input type="checkbox" :value="test.attempt_package" disabled checked>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div v-if="test.attempt_package" class="col-12">
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <label for="ap-attempt">{{ $t('test-assembler.ap-attempt') }}</label>
                            <input type="number" min="1" v-model="test.attempt_package.attempt_count"
                                   class="form-control" :disabled="finalized" id="ap-attempt" required>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <label for="ap-price">{{ $t('test-assembler.ap-price') }}</label>
                            <input type="number" min="0" v-model="test.attempt_package.net_price" class="form-control"
                                   id="ap-price" :disabled="finalized" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <div v-if="finalized" class="d-flex align-items-center mb-1">
                        <i class="fas fa-info-circle text-danger"></i>
                        <div class="ml-2">
                          {{ $t('edit-test.finalize-warning', {course: test.courses.find(c => c.finalized).title}) }}
                        </div>
                      </div>
                      <button type="submit" :disabled="loading" class="btn btn-theme float-right button-bottom"><img
                          src="assets/img/icons/save.svg" class="img-fluid mr-2" alt="">
                        {{ $t('edit-test.save-changes') }}
                      </button>
                    </div>
                  </div>
                </div>
            </div>
            </form>
          </div>
          <loading v-else/>
        </div>
      </div>
    </div>
  </div>
  <!-- Delete course modal -->
  <div class="modal fade" id="deleteTestModal" ref="deleteTestModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('edit-test.delete') }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <span>{{ $t('edit-test.delete-warning') }}</span>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('common.cancel') }}</button>
          <button type="button" class="btn btn-danger" :disabled="deleting" @click.prevent="deleteTest">
            {{ $t('common.delete') }}
          </button>
        </div>
      </div>
    </div>
  </div>

  </div>
</template>
<script>
import Loading from '@/components/Loading.vue'
import Datetime from '@/components/DateTimePicker/Datetime.vue'
import TestDuplicator from '../components/TestDuplicator.vue'

export default {
  name: 'TestAssembler',
  components: {
    Loading,
    Datetime,
    TestDuplicator
  },
  created() {
    this.loading = true
    this.axios.get('/test-point-formulas')
        .then(resp => this.pointingFormulas = resp.data)
        .catch(e => console.log(e))
    this.axios.get('/tests/' + this.$route.params.test)
        .then(resp => {
          this.test = resp.data.test
          console.log(resp.data.test);
          this.initialHidden = resp.data.test.hidden
        })
        .finally(() => {
          this.loading = false
          this.$nextTick(() => {
            $('#popover').popover()
          })
        })
  },
  data() {
    return {
      test: null,
      pointingFormulas: [],
      loading: false,
      deleting: false,
      initialHidden: 0
    }
  },
  computed: {
    finalized() {
      return this.test?.courses.some(c => c.finalized)
    }
  },
  methods: {
    changePoolSize(e) {
      const size = e.target.value
      if (size < 1) {
        this.test.hidden = 1
      } else if (this.initialHidden == 0 && this.test.hidden == 1) {
        this.test.hidden = 0
      }
      this.test.question_pool_size = size
    },
    duplicate() {
      this.loading = true
      this.axios.post('/tests/' + this.test.id + '/duplicate')
          .then(resp => {
            this.$router.push({name: 'ManageTests'})
          })
          .catch(e => console.log(e))
          .finally(() => {
            this.loading = false
          })
    },
    deleteTest() {
      this.deleting = true
      this.axios.delete('/tests/' + this.test.id)
          .then(() => {
            $(this.$refs['deleteTestModal']).modal('hide');
            this.$router.push({name: 'ManageTests'})
          })
          .catch(e => console.log(e))
          .finally(() => {
            this.deleting = false
          });
    },
    submit() {
      this.loading = true
      let test = this.$store.getters.removeEmptyOrNull(this.test);
      this.axios.patch('/tests/' + this.$route.params.test, test)
          .then(resp => {
            this.test = resp.data.test
            this.initialHidden = resp.data.test.hidden
          })
          .catch()
          .finally(() => this.loading = false)
    }
  }
}
</script>
<style scoped>
#text-assembler {
  margin-top: 30px;
}

/* CUSTOM CHECKBOX */

.c-inp {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.c-inp input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 2px solid #333E48;
}

.c-inp input:checked ~ .checkmark {
  background-color: #333E48;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.c-inp input:checked ~ .checkmark:after {
  display: block;
}

.c-inp .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.c-inp span {
  white-space: normal;
  word-wrap: break-word;
}

.button-bottom {
  margin-top: 100px;
}
</style>
