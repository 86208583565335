<template>
    
</template>
<script>
export default {
    name: 'Meeting',
    mounted() {
        ZoomMtg.setZoomJSLib('https://source.zoom.us/1.8.1/lib', '/av'); 
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareJssdk();
        this.axios.post(`/meeting/${this.$route.params.id}/join?role=${this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'Master' ? 1 : 0}`)
            .then(resp => {
                ZoomMtg.init({
                    leaveUrl: process.env.VUE_APP_FRONTEND_URL,
                    isSupportAV: true,
                    success: () => {
                        ZoomMtg.join({
                            signature: resp.data.signature,
                            apiKey: resp.data.api_key,
                            meetingNumber: this.$route.params.id,
                            userName: this.$store.state.user.name,
                            passWord: resp.data.password,
                            error(res) { 
                                console.log(res) 
                            }
                        })		
                    }
			    })
            })
    }
}
</script>