<template>
    <div class="w-100 h-100">
        <iframe :src="'/assets/js/ViewerJS/index.html#'+pdfData" :width="width" :height='height' allowfullscreen webkitallowfullscreen></iframe>
    </div>
</template>
<script>
import { Capacitor } from '@capacitor/core';
import * as pdf from 'pdfjs-dist'
import worker from 'pdfjs-dist/build/pdf.worker.entry'
export default {
    props: {
        pdfData: String,
        isBlob: Boolean,
        height: String,
        width: String
    },
    name: 'PdfViewer',
    created() {
        this.contractPdfData = this.pdfData
        this.defaultViewer = !(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || Capacitor.isNative);
        this.pdfScale = 1
    },
    data() {
        return {
            defaultViewer: true,
            pdfScale: 1,
            rendering: false,
            contractPdfData: null
        }
    },
    watch: {
        contractPdfData(val) {
            if(val && !this.defaultViewer) this.drawPDFonCanvas(val)
        },
        pdfScale() {
            if(this.contractPdfData && !this.defaultViewer)
                this.drawPDFonCanvas(this.contractPdfData)
        }
    },
    methods: {
         convertDataURIToBinary(dataURI) {
            var base64Index = dataURI.indexOf(';base64,') + ';base64,'.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));

            for(var i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        },
        drawPDFonCanvas(pdfData) {
            this.rendering = true
            pdfData = this.convertDataURIToBinary(pdfData)
            // Loaded via <script> tag, create shortcut to access PDF.js exports.
            var pdfjsLib = pdf

            // Using DocumentInitParameters object to load binary data.
            pdfjsLib.GlobalWorkerOptions.workerSrc = worker
            var loadingTask = pdfjsLib.getDocument({data: pdfData});
            loadingTask.promise.then((pdf) => {
                const scale = this.pdfScale
                const viewer = document.getElementById('the-canvas');
                
                // Fetch the first page
                for(let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                    let canvas = document.getElementById('page-'+pageNumber) ? document.getElementById('page-'+pageNumber) : document.createElement("canvas");    
                    canvas.className = 'pdf-page-canvas';
                    canvas.id ="page-"+pageNumber         
                    viewer.appendChild(canvas); 
                    pdf.getPage(pageNumber).then((page) => {
                        
                        var viewport = page.getViewport({scale: scale});

                        // Prepare canvas using PDF page dimensions
                        //var canvas = document.getElementById('the-canvas');
                        //canvas.height = mb.height
                        //canvas.width = mb.width
                        var context = canvas.getContext('2d');
                        canvas.width = viewport.width - 15;
                        canvas.height = viewport.height;

                        // Render PDF page into canvas context
                        var renderContext = {
                        canvasContext: context,
                        viewport: viewport
                        };
                        var renderTask = page.render(renderContext);
                        renderTask.promise.then( () =>{
                            this.rendering = false
                        });
                    });
                }
            });
        }
    }
} 
</script>