<template>
    <div v-if="!loading">
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('reporting.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Reporting'})">{{ $t('reporting.statistic') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('reporting.meta') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->
        <div id="content-2">
            <div class="container">
                <div class="row">
                    <section id="chart-list" class="mt-5">
                        <div class="row">
                            <div class="col-md-6 col-12 mb-4">
                                <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('course-meta-data-reports.started') }}</h3>
                                <div class="chart-box bg-white filter-shadow custom-input">
                                    <time-chart :data="test">
                                        <template v-slot="{data}">
                                            <CourseStartCreditChart :data="data" />
                                        </template>
                                    </time-chart>
                                </div>
                            </div>
                            <div class="col-md-6 col-12 mb-4">
                                <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('course-meta-data-reports.ratio') }}</h3>
                                <div class="chart-box bg-white filter-shadow custom-input">
                                    <time-chart :data="test">
                                        <template v-slot="{data}">
                                            <CourseStartFinishChart :data="data" />
                                        </template>
                                    </time-chart>
                                </div>
                            </div>
                            <div class="col-md-6 col-12 mb-4">
                                <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('course-meta-data-reports.ended') }}</h3>
                                <div class="chart-box bg-white filter-shadow custom-input">
                                    <time-chart :data="test">
                                        <template v-slot="{data}">
                                            <CourseFinishCreditChart :data="data" />
                                        </template>
                                    </time-chart>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>

    </div>
    <loading v-else />
</template>
<script>
import { DateTime } from 'luxon';
import Chart from 'chart.js';
import CourseStartCreditChart from '@/components/Charts/CourseStartCreditChart.vue'
import CourseFinishCreditChart from '@/components/Charts/CourseFinishCreditChart.vue'
import CourseStartFinishChart from '@/components/Charts/CourseStartFinishChart.vue'
import TimeChart from '@/components/Charts/TimeChart.vue'
import Loading from '../components/Loading.vue';
export default {
    name: 'CourseMetadataReports',
    components: {
        CourseStartCreditChart,
        CourseFinishCreditChart,
        CourseStartFinishChart,
        TimeChart,
        Loading
    },
    mounted() {
        this.createCourseStartCreditChart()
        this.test = [
            this.randomData(500),
            this.randomData(500)
        ]

        //This is a workaround to show the loading spinner
        //while the synchronous component rendering process finishes
        setTimeout(() => {
            this.loading = false
        }, 0)
    },
    data() {
        return {
            loading: true,
            test: [
            ]
        }
    },
    methods: {
        createCourseStartCreditChart(startDate = new Date(2020, 0, 1), endDate = new Date()) {
            let data = []
            let dates = []
            console.log(endDate)
            while(startDate <= endDate) {
                dates.push({
                    t: DateTime.fromJSDate(startDate).toFormat('yyyy-MM'),
                    y: 0
                })
                startDate = new Date(startDate.setMonth(startDate.getMonth()+1))
            }
            for(let i = 0; i < 500; i++) {
                let t = DateTime.fromJSDate(this.randomDate(Date.now(), new Date(2020, 0, 1))).toFormat('yyyy-MM')
                let idx = dates.findIndex(d => d.t == t)
                if(idx != -1)
                    dates[idx].y++
            }
            return dates
        },
        randomData(n) {
            let data = []
            for(let i = 0; i < n; i++) {
                let t = this.randomDate(Date.now(), new Date(2019, 0, 1))
                data.push(DateTime.fromJSDate(t).toISO())
            }
            return data
        },
        randomDate(date1, date2){
            function randomValueBetween(min, max) {
                return Math.random() * (max - min) + min;
            }
            var date1 = date1 || '01-01-1970'
            var date2 = date2 || new Date().toLocaleDateString()
            date1 = new Date(date1).getTime()
            date2 = new Date(date2).getTime()
            if( date1>date2){
                return new Date(randomValueBetween(date2,date1))
            } else{
                return new Date(randomValueBetween(date1, date2))
            }
        }
    }
}
</script>
<style scoped>
    .chart-box {
        padding: 15px;
        border-radius: 8px;
    }
</style>
