<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('glossary.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
              </div>
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'MyCourses'})">{{ $t('my-courses.my-courses') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('my-certificates.my-certificates') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div id="content-2">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- RESPONSIVE TABLE -->
            <div class="row">
              <div class="col-12 mt-5 mb-3">
                <h2 class="text-18 text-dark-gray semi-bold">{{ $t('my-certificates.my-certificates') }}</h2>
              </div>
              <div class="col-12 mb-4">
                <div class="new-table filter-shadow">
                  <table class="table borderless-table">
                    <thead class="bg-light-gray-primary">
                    <tr>
                      <th scope="col" :class="'pointer'+' '+(currentSort==='id'?'sorted':'')" @click="sort('id')">{{ $t('my-certificates.id') }}</th>
                      <th scope="col" :class="'pointer'+' '+(currentSort==='course_id'?'sorted':'')" @click="sort('course_id')">{{ $t('my-certificates.course') }}</th>
                      <th scope="col" :class="'pointer'+' '+(currentSort==='created_at'?'sorted':'')" @click="sort('created_at')">{{ $t('my-certificates.date') }}</th>
                      <th scope="col" >{{ $t('my-certificates.certificate') }}</th>
                      <th scope="col" >{{ $t('my-certificates.certificate-card') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="certificate in sortedCertificates" :key="certificate.course_id" >
                      <td>#{{certificate.id}}</td>
                      <td><a style="cursor: pointer;" @click.prevent="$router.push({name: 'Course', params: {id: certificate.course_id}})">{{certificate.course.group ? certificate.course.group.title : certificate.course.title}}</a></td>
                      <td>{{$store.getters.parseDateTime(certificate.created_at)}}</td>
                      <td><button
                          type="button"
                          v-if="certificate && certificate.path != null"
                          :disabled="certificateLoading"
                          class="btn btn-theme text-center text-white semi-bold"
                          @click.prevent="downloadCertificate(certificate.course)"
                      >
                        <div class="d-flex align-items-center">
                          <i class="fas fa-award mr-1"></i>
                          <span>{{ $t('my-certificates.download') }}</span>
                        </div>
                      </button>
                      </td>
                      <td>
                        <button
                            type="button"
                            :disabled="certificateCardLoading"
                            v-if="certificate && certificate.card_path != null"
                            class="mt-2 mb-2 btn btn-theme text-center text-white semi-bold"
                            @click.prevent="downloadCertificateCard(certificate.course)"
                        >
                          <div class="d-flex align-items-center">
                            <i class="fas fa-id-card mr-1"></i>
                            <span>{{ $t('my-certificates.download-card') }}</span>
                          </div>
                        </button>
                      </td>
                    </tr>
                    <tr v-if="$store.state.user.certificates.length == 0">
                      <td colspan="3" class="text-center text-light-black semi-bold pt-4 pb-4">{{ $t('no-data.certificates') }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- //RESPONSIVE TABLE -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Capacitor} from "@capacitor/core";

export default {
  name: 'MyCertificates',
  data () {
    return {
      certificateLoading: false,
      certificateCardLoading: false,
      currentSort:'id',
      currentSortDir:'asc',
    }
  },
  mounted() {
    console.log('MyCertificates mounted');
    console.log(this.$store.state.user);
  },
  methods: {
    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
    },
    downloadCertificate(course) {
      this.certificateLoading = true;
      this.axios.get('/courses/'+course.id+'/certificate', {responseType: 'blob'})
          .then(resp => {
            const name = (course.group?course.group.title:course.title)+'_cert.pdf'
            if(Capacitor.isNative) {
              this.$store.commit('downloadFileMobile', {name, mime: 'application/pdf', data: resp.data})
            }
            else {
              const url = window.URL.createObjectURL(new Blob([resp.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', name);
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(e => console.log(e.response))
          .finally(() => this.certificateLoading = false)
    },
    downloadCertificateCard(course) {
      this.certificateCardLoading = true;
      this.axios.get('/courses/'+course.id+'/certificate-card', {responseType: 'blob'})
          .then(resp => {
            const name = (course.group?course.group.title:course.title)+'card.pdf'
            if(Capacitor.isNative) {
              this.$store.commit('downloadFileMobile', {name, mime: 'application/pdf', data: resp.data})
            }
            else {
              const url = window.URL.createObjectURL(new Blob([resp.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', name);
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(e => console.log(e.response))
          .finally(() => this.certificateCardLoading = false)
    },
  },
  computed:{
    sortedCertificates:function() {
      return this.$store.state.user.certificates.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    }
  }
}
</script>
<style scoped>
.sorted{
  font-weight: normal;
}
</style>
