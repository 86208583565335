<template>
    <div class="modal fade" id="usersContractReceivedModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title">{{ $t('user-contracts.title') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body pb-0">
                <div class="row">
                    <div class="col-12 mt-2 mb-1">
                        <div class="mb-4 font-18">
                            <div>{{$t('user-contracts.all-users')}}  <strong>{{users.length}}</strong></div>
                            <div>{{$t('user-contracts.received')}} <strong class="text-success">{{users.filter(u => usersContractsReceived.find(u2 => u2.id == u.id)).length}}</strong></div>
                            <div>{{$t('user-contracts.not-received')}} <strong class="text-danger">{{users.filter(u => !usersContractsReceived.find(u2 => u2.id == u.id)).length}}</strong></div>
                        </div>
                        <div class="mb-3">
                            <i class="fas fa-info-circle text-primary" />
                            {{$t('user-contracts.info')}}
                        </div>
                        <div class="d-flex" style="justify-content: space-between">
                            <div class="font-17">{{$t('user-contracts.user')}}</div>
                            <div class="font-17">{{$t('user-contracts.received')}}</div>
                        </div>
                        <hr class="mt-2 mb-2">
                        <div v-for="user in users" :key="user.id">
                            <div class="d-flex align-items-center" style="justify-content: space-between">
                                <div class="d-flex align-items-center">
                                    <img v-if="user.avatar" :src="user.avatar.path+'?token='+$store.state.token" style="border-radius: 50%; height: 50px" class="ml-0" alt="">
                                    <avatar v-else class="team-image ml-0" :username="user.name" :size="50"/>
                                    <h6 class="name ml-2 mt-0 pl-0" style="font-weight: bold" :class="usersContractsReceived.find(u => u.id == user.id) ? 'text-success' : 'text-danger'">
                                        {{user.name}} <br />
                                        <span class="email mb-1">{{user.email}}</span>
                                    </h6>
                                </div>
                                <div class="mr-4" style="height: 20px;">
<!--                                    <input style="width: 20px; height: 20px;" @change="toggle(user.id, $event)" :checked="usersContractsReceived.find(u => u.id == user.id)" :disabled="!canSubmit" type="checkbox">-->
                                </div>
                            </div>
                            <hr class="mt-2 mb-2">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-0">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('common.close')}}</button>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import Avatar from './Avatar.vue'
export default {
  components: { Avatar },
    name: 'UsersContractReceivedModal',
    props: {
        courseId: Number,
        users: Array,
        usersContractsReceived: Array
    },
    data() {
        return {
            accepted: false,
            submitting: false,
        }
    },
    computed: {
        canSubmit() {
            return !this.submitting
        }
    },
    methods: {
        toggle(userId, event) {
            if(this.submitting) return;
            this.submitting = true
            this.axios.post(`/courses/${this.courseId}/users/${userId}/toggle-contract`)
                .then(resp => {
                    if(resp.data.changes.detached.length > 0) {
                        const idx = this.usersContractsReceived.findIndex(u => u.id == userId)
                        if(idx !== -1) {
                            this.usersContractsReceived.splice(idx, 1)
                        }
                    }
                    else {
                        this.usersContractsReceived.push({id: userId})
                    }
                })
                .catch(e => {
                    console.log(e)
                    event.target.checked = !event.target.checked
                })
                .finally(() => this.submitting = false)
        }
    }
}
</script>
<style scoped>
    /* MOBILE FONT SIZE UNDER 426px */
    @media (min-width: 426px) {
        .font-17 {
            font-size: 17px !important;
        }
        .font-18 {
            font-size: 18px !important;
        }
    }

    @media (max-width: 425px) {
        .font-17 {
            font-size: 12px !important;
        }
        .font-18 {
            font-size: 13px !important;
        }
    }
</style>
