<template>
  <div id="other-information">
    <div class="d-flex align-items-center justify-content-center">
      <img src="@/assets/img/settings/achievements.png" alt="" />
    </div>
    <div class="d-flex align-items-center justify-content-between my-4">
      <h1>{{ $t("settings.badges") }}</h1>
    </div>

    <table>
      <thead>
        <tr>
          <th>
            <span>{{ $t("settings.badges") }}</span>
          </th>
          <th>
            <span>{{ $t("settings.received") }}</span>
          </th>
          <th>
            <span>{{ $t("settings.valid") }}</span>
          </th>
          <th>
            <span>{{ $t("settings.action") }}</span>
          </th>
        </tr>
      </thead>

      <tbody
        v-for="(course, idx) in [
          ...new Map(
            coursesFinished.map((item) => [item['badge_id'], item])
          ).values(),
        ]"
        :key="idx"
      >
        <tr v-if="course.badge">
          <td>
            <img
              :src="
                $store.state.storageURL +
                course.badge.multimedia.path +
                `?token=${$store.state.token}`
              "
              :alt="course.badge.title"
              style="width: 50px"
            />
          </td>
          <td>
            <span>{{ $store.getters.parseDateTime(course.finished_at) }}</span>
          </td>
          <td>
            <span>--</span>
          </td>
          <td>
            <TransparentButton
              icon="save"
              :text="$t('common.save')"
              :click="() => setAsBadge(course.badge.id)"
              :disabled="
                $store.state.user.badge &&
                course.badge &&
                $store.state.user.badge?.id === course.badge.id
              "
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TransparentButton from "@/components/settings/TransparentButton.vue";

export default {
  components: {
    TransparentButton,
  },
  data() {
    return {
      coursesFinished: [],
    };
  },
  mounted() {
    this.coursesFinished = this.$store.state.user.coursesFinished;
    console.log(this.coursesFinished);
  },
  methods: {
    setAsBadge(id) {
      console.log("setAsBadge", id);
      this.loading = true;
      this.axios
        .post("/update-badge", { badge_id: id })
        .then((resp) => {
          this.$store.state.user = resp.data.user;
          //this.data.badge_id = resp.data.user.badge ? resp.data.user.badge.id : null;
        })
        .catch((e) => (this.errors = e.response.data.errors))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
#other-information {
  padding: 40px 25px;
}

h1 {
  font-size: 20px;
  font-weight: 500;
  color: #333e48;
}

.table-with-inputs .head > div {
  background-color: #cecece;
  border: 1px solid #333e48;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-with-inputs .head > div span {
  font-weight: 500;
  font-size: 20px;
  color: #333e48;
}

.table-with-inputs .table-input {
  border: 1px solid #cecece;
  background-color: #fff;
  padding: 15px;
  display: flex;
  align-items: center;
}

.table-with-inputs .remove-section,
.table-with-inputs .badge-section {
  display: flex;
  justify-content: center;
}

.table-with-inputs .table-input .form-control {
  border: none;
  color: #333e48;
  font-size: 14px;
  font-weight: 500;
}

.table-with-inputs .table-input .rounded-red {
  margin-top: 0;
  margin-right: 0;
}

/* BUTTONS */
.rounded-red {
  background-color: #d62b33;
  border: 3px solid #d62b33;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-right: 25px;
  margin-top: 10px;
}

.rounded-red:hover {
  border: 3px solid var(--dark-red);
  background-color: var(--dark-red);
}
.rounded-grey {
  background-color: #939090;
  border: 3px solid #939090;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-right: 25px;
  margin-top: 10px;
}

.rounded-grey:hover {
  border: 3px solid #dcdada;
  background-color: #dcdada;
}
/* START BUTTONS */

button.red-button {
  background-color: #d62b33;
  border-radius: 5px;
  border: 3px solid #d62b33;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  position: absolute;
  width: 153px;
  justify-content: center;
  height: 38px;
}

button.red-button span {
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-left: 10px;

  color: #ffffff;
}

::v-deep button.red-button .icon svg path {
  fill: #fff !important;
}
button.red-button:hover {
  background-color: var(--dark-red);
  border: 3px solid var(--dark-red);
}

button.secondary-button {
  background-color: transparent;
  border-radius: 5px;
  border: 1.5px solid #d62b33;
  display: flex;
  padding: 5px 20px;
  width: 153.36px;
  height: 38px;
}

button.secondary-button span {
  color: #d62b33;
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
}

::v-deep button.secondary-button .icon svg path {
  fill: #d62b33 !important;
}

button.secondary-button:hover {
  background-color: #d62b33;
  border: 1.5px solid #d62b33;
}

button.secondary-button:hover span {
  color: #fff;
}

::v-deep button.secondary-button:hover .icon svg path {
  fill: #fff !important;
}

/* END BUTTONS */

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

thead {
  background-color: #cecece;
  border-radius: 5px;
}

th {
  font-weight: 500;
  font-size: 15px;
  color: #333e48;
  padding: 10px;
  border: solid 1px #333e48;
  border-right: none;
  text-align: center;
}

th:first-of-type {
  border-top-left-radius: 5px;
}

th:last-of-type {
  border-top-right-radius: 5px;
  border-right: solid 1px #333e48;
}

tbody tr {
  border: 1px solid #cecece;
  background-color: #fff;
}

tbody tr:first-of-type {
  border-top: none;
}

tbody tr td {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #928c88;
  border: solid 1px #cecece;
  border-right: none;
  text-align: center;
  width: calc(100% / 3);
}

tbody tr td .secondary-button {
  margin: 0 auto;
}

tbody tr td:first-of-type {
  width: calc(100% / 6);
}

tbody tr td:last-of-type {
  border-right: solid 1px #cecece;
  width: calc(100% / 6);
}
</style>
