<template>
    <canvas id="courseStartFinish"></canvas>
</template>
<script>
export default {
    name: 'CourseStartFinish',
    props: {
        data: Array
    },
    mounted() {
        const ctx = $('#courseStartFinish')
        this.chart = new Chart(ctx, {
            type: 'bar',
                data: 
                {   
                    labels: this.data[0].map(d => d.x),
                    datasets: 
                    [   
                        { 
                            data: this.data[0].map(d => d.y),
                            borderWidth: 2,
                            backgroundColor: 'rgb(23, 111, 183, 0.8)',
                            borderColor: 'rgb(23, 111, 183, 1)',
                            label: this.$t('chart.started-courses'),
                        },
                        { 
                            data: this.data[1].map(d => d.y),
                            borderWidth: 2,
                            borderColor: 'rgb(40, 167, 69, 1)',
                            backgroundColor: 'rgb(40, 167, 69, 0.8)',
                            label: this.$t('chart.finished-courses'),
                            fill: false,
                        },
                        { 
                            data: this.calculatePercentage(),
                            borderWidth: 2,
                            borderColor: "rgb(220, 53, 69, 1)",
                            backgroundColor: "rgb(220, 53, 69, 1)",
                            label: this.$t('chart.ratio'),
                            fill: false,
                            type: 'line',
                            lineTension: 0, 
                            options: {
                                cubicInterpolationMode: 'monotone',
                                lineTension: 0, 
                            }
                        },
                    ]
                },
                options:
                {  
                    scales: 
                    {
                        xAxes: 
                        [{
                            ticks: {
                                beginAtZero: true
                            },
                            stacked: true
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                            },
                            stacked: true,
                        }]
                    },
                }  
        });
    },
    data() {
        return {
            chart: null
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale
        }
    },
    watch: {
        locale() {
            if(this.chart) {
                this.chart.data.datasets[0].label = this.$t('chart.started-courses')
                this.chart.data.datasets[1].label = this.$t('chart.finished-courses')
                this.chart.data.datasets[2].label = this.$t('chart.ratio')
                this.chart.update()
            }
        },
        data() {
            if(this.chart) {
                this.chart.data.labels = this.data[0].map(d => d.x)
                this.chart.data.datasets.forEach((dataset,index) => {
                    if(index < this.chart.data.datasets.length -1)
                        this.chart.data.datasets[index].data = this.data[index].map(d => d.y)
                })
                this.chart.data.datasets[2].data = this.calculatePercentage()
                this.chart.update()
            }
        }
    },
    methods: {
        calculatePercentage() {
            let copy = JSON.parse(JSON.stringify(this.data[1]));
            for(let i = 0; i < this.data[1].length; i++) {
                let low = this.data[1][i].y > this.data[0][i].y ? this.data[0][i].y : this.data[1][i].y;
                let high = this.data[1][i].y < this.data[0][i].y ? this.data[0][i].y : this.data[1][i].y;
                copy[i].y = high > 0 ? Number((low * 100) / high).toFixed(2) : 0;
            }
            return copy
        }
    }
}
</script>