<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('my-courses.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'CourseGroupList'})">{{ $t('course-groups.course-groups') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{loading || !courseGroup ? 'Edit course group' : courseGroup.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div id="content-2" v-if="!loading && courseGroup">
      <div class="container">
        <form @submit.prevent="submit">
          <div class="row">
            <div class="col-lg-6">
              <section id="media-input" class="custom-input">
                <div class="d-flex flex-wrap mb-2">
                  <dib class="d-flex mr-auto">
                    <h3 class="text-18 text-light-black medium">{{ $t('edit-course.cover-img') }}</h3>
                  </dib>
                  <dib class="d-flex" v-if="courseGroup.image">
                    <img @click.stop="removeCoverImage" :disabled="courseGroup.imageUploading"
                         src="assets/img/icons/trash-red.svg" class="pointer" alt="">
                  </dib>
                </div>
                <div v-if="!courseGroup.image && !courseGroup.imageUploading" @click="$refs.coverImageUpload.click()"
                     class="cover-upload filter-shadow mb-3 d-flex align-items-center justify-content-center pointer">
                  <img src="assets/img/icons/add-photo.svg" class="upload-photo" alt="">
                </div>
                <div v-else-if="courseGroup.image && !courseGroup.imageUploading"
                     @click="$refs.coverImageUpload.click()" class="cover-upload filter-shadow"
                     :style="{backgroundImage: `url(${$store.state.storageURL+courseGroup.image.path}?token=${$store.state.token})`}"></div>
                <div v-else class="col-12 d-flex justify-content-center align-items-center"
                     style="border: 2px solid #e0e0e0; height: 300px;">
                  <Loading height="100%"/>
                </div>
                <ImageCropper v-if="uploadedAvatarURL" @urlChanged="createUpload" :src="uploadedAvatarURL"
                              :type="uploadType" :aspectRatio="null"/>
                <input ref="coverImageUpload" type="file" accept="image/jpeg, image/jpg, image/png" @change="readURL"
                       style="display: none;">
              </section>
            </div>
            <div class="col-lg-6">
              <section id="text-input" class="custom-input">
                <div class="form-group mb-3">
                  <label for="title" class="text-16 text-dark-warm-gray">{{ $t('edit-course.name') }}</label>
                  <div v-if="errors['title']">
                    <span class="text-13 semi-bold" v-for="(error, idx) in errors['title']" style="color: red;"
                          :key="idx">{{ error }}</span>
                  </div>
                  <input @input="errors['title'] = null" type="text" v-model="courseGroup.title" id="title"
                         class="form-control" :style="{border: errors['title'] ? '1px solid red' : '1px solid #ced4da'}"
                         required>
                </div>
                <div class="form-group mb-3">
                  <label for="parent" class="text-16 text-dark-warm-gray">{{ $t('course-groups.parent-group') }}</label>
                  <div v-if="errors['parent']">
                    <span class="text-13 semi-bold" v-for="(error, idx) in errors['parent']" style="color: red;"
                          :key="idx">{{ error }}</span>
                  </div>
<!--                  <select @input="errors['parent'] = null" type="text" v-model="courseGroup.parent_id" id="parent"
                         class="form-control" :style="{border: errors['parent'] ? '1px solid red' : '1px solid #ced4da'}">
                    <option value="">{{ $t('course-groups.no-parent') }}</option>
                    <option v-for="(group, idx) in courseGroups" :key="idx" :value="group.id">{{ group.title }}</option>
                  </select>-->
                  <Select2 v-model="courseGroup.parent_id" :options="courseGroups" :settings="{width:'100%'}" />
                </div>
                <div class="form-group mb-3">
                  <label for="title" class="text-16 text-dark-warm-gray">{{ $t('course-groups.sub-groups') }}</label>
                  <div class="col-12 big-group" v-for="(group, index) in courseGroup.children" :key="group.id">
                    <div class="row">
                      <div class="col">
                        <div class="training-card group filter-shadow">
                          <div class="training-image pointer"
                               v-bind:style="{backgroundImage: `url(${group.image ? $store.getters.getStorageURL(group.image.path) : 'assets/img/demo/training.png'})`, height:`90px`}"></div>
                          <div class="training-card-bottom bg-light-gray-secondary fix-height">
                            <div class="row">
                              <div class="col-md-10 col-9 pointer">
                                <h4 class="text-14 regular text-white">{{ group.title }}</h4>
                              </div>
                              <div class="col-md-2 col-3">
                                <img @click.prevent="$router.push({name: 'EditCourseGroup', params: {group: group.id}})"
                                     src="assets/img/icons/pen.svg" class="pointer" alt="">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="row col-12">
                          <div class="btn-group btn-group-sm" role="group" aria-label="...">
                            <button type="button" @click.prevent="updateCourseGroupOrder(group, index, 'down')"
                                    class="btn btn-light"><i class="fa fa-arrow-down" aria-hidden="true"></i>
                            </button>
                            <button type="button" disabled class="btn btn-light">{{ group.order+1 }}</button>
                            <button type="button" @click.prevent="updateCourseGroupOrder(group, index, 'up')"
                                    class="btn btn-light"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="courseGroup.children.length===0" class="col-12 col-sm-6 big-group">
                    <span>{{$t('no-data.sub-groups')}}</span>
                  </div>
                </div>
                <div class="space-helper"></div>
                <button type="button" class="btn btn-theme-outline float-right mt-3 mb-3 ml-3" data-toggle="modal"
                        data-target="#deleteCourseGroupModal"><img src="assets/img/icons/trash-red.svg" class="img-fluid mr-1" style="height: 23px;margin-top: -4px;" alt="">
                  {{ $t('course-groups.delete') }}
                </button>
                <button :disabled="loading" type="submit" class="btn btn-theme float-right mt-3 mb-3 "><img
                    src="assets/img/icons/save.svg" class="img-fluid mr-2" alt="">{{ $t('edit-course.save-changes') }}
                </button>
              </section>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- Delete course group modal -->
    <div class="modal fade" id="deleteCourseGroupModal" ref="deleteCourseGroupModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('edit-course.delete') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <span>Are you sure you want to delete this course group?</span>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('common.cancel') }}</button>
            <button type="button" class="btn btn-danger" :disabled="deleting" @click.prevent="deleteCourseGroup">
              {{ $t('common.delete') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from '@/components/Loading'
import ImageCropper from '@/components/ImageCropper.vue'
import Select2 from "vue3-select2-component";

export default {
  name: 'EditCourse',
  components: {
    Loading,
    ImageCropper,
    Select2
  },
  mounted() {
    this.loading = true
    this.axios.get('/course-groups/' + this.$route.params.group)
        .then(resp => {
          this.courseGroup = resp.data.course_group
        })
        .finally(() => this.loading = false);
    this.loading = true
    this.axios.get('/course-groups')
        .then(resp => {
          this.courseGroups = resp.data.course_groups.map(group => {
            if (group.id != this.$route.params.group){
              return {
                id: group.id,
                text: group.title,
              }
            }
            return null;
          });
          console.log(this.courseGroups);
        })
        .finally(() => this.loading = false)

  },
  data() {
    return {
      submitting: false,
      uploadedAvatarURL: null,
      uploadType: null,
      loading: false,
      courseGroups: [],
      courseGroup: null,
      errors: Object(),
      deleting: false
    }
  },
  methods: {
    createUpload(url) {
      if (url) {
        let file = this.dataURLtoFile(url)
        let formData = new FormData;
        formData.set('image', file, file.name);
        this.courseGroup.imageUploading = true
        console.log(formData)
        let config = {
          method: 'POST',
          data: formData,
          url: `/upload/image`,
          baseURL: process.env.VUE_APP_STORAGE_URL,
          headers: {
            'Content-Type': 'application/octet-stream'
          },
        };
        this.axios(config)
            .then(async (resp) => {
              config.url += '/' + resp.data.name
              config.url += '/finish';
              config.data = null;
              resp = await this.axios(config)
              this.courseGroup.image = resp.data.multimedia;
              this.courseGroup.image_id = resp.data.multimedia.id
              await this.axios.patch('/course-groups/' + this.courseGroup.id, {image_id: this.courseGroup.image_id})

            })
            .catch(() => {
            })
            .finally(() => this.courseGroup.imageUploading = false)
      }

    },
    readURL(input) {
      input = input.target
      if (input.files && input.files[0]) {
        this.uploadType = input.files[0].type
        var reader = new FileReader()
        reader.onload = (e) => {
          this.uploadedAvatarURL = e.target.result
        }

        reader.readAsDataURL(input.files[0]) // convert to base64 string
      }
    },
    dataURLtoFile(dataurl) {

      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], `${this.courseGroup.id}_image`, {type: mime});
    },
    removeCoverImage() {
      this.axios.post(process.env.VUE_APP_STORAGE_URL + '/upload/image/' + this.courseGroup.image.id + '/abort')
      this.courseGroup.image = null
      this.courseGroup.image_id = null
      this.$refs.coverImageUpload.value = null
    },
    removeEmptyOrNull(obj) {
      Object.keys(obj).forEach(k => {
        if ((obj[k] === null || obj[k] === '') && k !== 'location_id')
          delete obj[k]
      })
      return obj;
    },
    submit() {
      this.loading = true
      this.axios.patch('/course-groups/' + this.courseGroup.id, this.courseGroup)
          .then(() => {
            this.uploadedAvatarURL = null
            this.$router.push({name: 'CourseGroupList'})
          })
          .catch(e => this.errors = e.response.data.errors)
          .finally(() => this.loading = false)
    },
    deleteCourseGroup() {
      this.deleting = true
      this.axios.delete('/course-groups/' + this.courseGroup.id)
          .then(() => {
            $(this.$refs['deleteCourseGroupModal']).modal('hide')
            this.$router.push({name: 'CourseGroupList'})
          })
          .catch(e => console.log(e))
          .finally(() => this.deleting = false)
    },
    updateCourseGroupOrder(group, order, direction) {
      this.loading = true;
      this.axios.post('/course-groups/' + group.id + '/order', {direction: direction})
          .finally(() => {
            this.axios.get('/course-groups/' + this.$route.params.group)
                .then(resp => {
                  this.courseGroup = resp.data.course_group
                })
                .finally(() => this.loading = false);
          });
      ///courses/{course}/order
    }
  }
}
</script>
<style scoped>
#media-input {
  margin-top: 30px;
}

#text-input {
  margin-top: 30px;
}

.space-helper {
  margin-top: 87px;
}
</style>
