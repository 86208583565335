<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-news.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('manage-news.manage-news') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->
        <div id="content-2">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3">
                        <section id="new-news">
                            <div class="row">
                                <div class="col-12">
                                    <div class="flex-wrap d-flex">
                                        <div class="d-flex align-items-center pointer mr-auto" @click.prevent="$router.push({name: 'CreateNews'})">
                                            <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                <img src="assets/img/icons/plus.svg" class="img-fluid" alt="">
                                            </div>
                                            <div class="d-flex flex-column ml-2">
                                                <h6 class="text-16 text-dark-gray">{{ $t('manage-news.new') }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div class="col-lg-9">
                        <section id="news-list">
                            <draggable @end="onOrderChange" v-model="news" :scrollSensitivity="250" :forceFallback="true" :fallback-tolerance="1" item-key="id">
                                <template #item="{element}">
                                    <div class="row">
                                        <!-- NEWS CARD -->
                                        <div class="col-12 big-group">
                                            <div class="training-card group filter-shadow">
                                                <div class="training-image pointer" :style="{backgroundImage: `url(${$store.state.storageURL+element.multimedia.path+'?token='+$store.state.token})`}">
                                                    <img data-toggle="modal" :data-target="'#newsDeleteModal'+element.id" style="cursor: pointer;" src="assets/img/icons/trash-red.svg" class="float-end mt-2 mr-2 filter-red" alt="">
                                                </div>
                                                <div class="training-card-bottom bg-light-gray-primary fix-height">
                                                    <div class="row">
                                                        <div class="col-md-10 col-9 pointer">
                                                            <h4 v-if="element.link" style="cursor: pointer;" @click.prevent="processLink(element.link)" class="text-14 regular text-white">{{element.content[$store.state.locale]}}</h4>
                                                            <h4 v-else class="text-14 regular text-white">{{element.content[$store.state.locale]}}</h4>
                                                        </div>
                                                        <div class="col-md-2 col-3">
                                                            <img @click.prevent="$router.push({name: 'EditNews', params: {newsProp: JSON.stringify(element), id: element.id}})" src="assets/img/icons/pen.svg" class="pointer" alt="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- //NEWS CARD -->
                                        <!-- NEW DELETE MODAL -->
                                        <div class="modal fade" :id="'newsDeleteModal'+element.id" :ref="'newsDeleteModal-'+element.id" tabindex="-1"  aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title">{{ $t('manage-news.delete-sure') }}</h5>
                                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    {{ $t('manage-news.delete-question') }} <strong>{{element.content[$store.state.locale]}}</strong> 
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('manage-news.cancel') }}</button>
                                                    <button type="button" class="btn btn-danger" :disabled="deleting" @click="deleteNews(element)">{{ $t('manage-news.delete') }}</button>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </draggable>
                            <div class="row" v-if="news. length < 1">
                                <div class="col-12">
                                    <h6 class="text-13 text-dark-gray semi-bold">{{ $t('manage-news.no-news') }}</h6>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>


        <!-- RÉGI -->
    </div>
</template>
<script>
import draggable from 'vuedraggable'
import {sanitizeUrl} from "@braintree/sanitize-url"
import Loading from '@/components/Loading'
export default {
    name: 'ManageNews',
    components: {
        Loading,
        draggable
    },
    mounted() {
        this.axios.get('/news')
            .then(resp => this.news = resp.data)
            .catch(e => console.log(e))
            .finally(() => this.loading = false)
    },
    data() {
        return {
            loading: true,
            news: [],
            deleting: false
        }
    },
    methods: {
        processLink(link) {
            if(link.includes(process.env.VUE_APP_FRONTEND_URL)) {
                link = link.split(process.env.VUE_APP_FRONTEND_URL)[1]
                this.$router.push({path: link})
            }
            else {
                window.open(sanitizeUrl(link), '_blank');
            }
        },
        deleteNews(news) {
            this.deleting = true
            this.axios.delete('/news/'+news.id)
                .then(resp => {
                    this.news.splice(this.news.findIndex(n => n.id == news.id), 1)
                    $(this.$refs['newsDeleteModal-'+news.id]).modal('hide');
                })
                .catch(e => console.log(e))
                .finally(() => this.deleting = false)
        },
        onOrderChange(e) {
            if(e.oldIndex !== e.newIndex) {
                let news = this.news[e.newIndex]
                this.axios.post('/news/'+news.id+'/order', {order: e.newIndex+1})
            }
        },
    }
}
</script>
<style scoped>
    #news-list,
    #new-news {
        margin-top: 30px !important;
    }
</style>