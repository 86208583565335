<template>
    <div class="align-items-center">
        <button type="button" :disabled="loading" :class="pdf ? 'btn-outline-danger' : 'btn-outline-success'" class="btn mb-2" @click.prevent="download">
            <div class="d-flex align-items-center" v-if="pdf">
                <i class="fas fa-file-pdf mr-1"></i>
                <span>{{$t('activity-history.download-activity-diary2')}}</span>
            </div>
            <div class="d-flex align-items-center" v-else>
                <i class="fas fa-file-excel mr-1"></i>
                <span>{{$t('activity-history.download-activity-diary1')}}</span>
            </div>
        </button>
        <div v-if="loading && spinner" :class="pdf ? 'text-danger' : 'text-success'" class="spinner-border ml-2 mb-2" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>
<script>
import { Capacitor } from '@capacitor/core';
export default {
    name: 'GenericDocumentDownload',
    props: {
        text: String,
        link: String,
        name: String,
        pdf: Boolean,
        spinner: {
            type: Boolean,
            optional: true,
            default: true
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        download() {
            this.loading = true;
            this.axios.get(this.link, {responseType: 'blob'})
                .then(resp => {
                    const mime = this.pdf ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    if(Capacitor.isNative) {
                        this.$store.commit('downloadFileMobile', {name: this.name, mime, data: resp.data})
                    }
                    else {
                        const url = window.URL.createObjectURL(new Blob([resp.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.name);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
                .catch(e => console.log(e.response))
                .finally(() => this.loading = false)
        },
    }
}
</script>
