<template>
    <canvas height="150" id="browserChart"></canvas>
</template>
<script>
import UAParser from 'ua-parser-js'
import randomColor from 'randomcolor'
export default {
    name: 'BrowserChart',
    props: {
        data: Array,
        colors: Array,
    },
    mounted() {
        this.processData()
        const ctx = $('#browserChart')
        this.chart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: Object.keys(this.processedData),
                datasets: [
                    {
                        data: Object.values(this.processedData),
                        backgroundColor: randomColor({luminosity: 'light', count: Object.keys(this.processedData).length, format: 'rgb'})
                    }
                ]
            }
        });
    },
    data() {
        return {
            chart: null,
            processedData: {}
        }
    },
    methods: {
        processData() {
            let parser = new UAParser()
            this.data.forEach(d => {
                const browser = parser.setUA(d).getResult().browser.name
                this.processedData[browser] = (this.processedData[browser] ?? 0) + 1
            })
        }
    }
}
</script>