<template>
    <form @submit.prevent="setBillingData">
        <div class="row">
        <slot>
            <div class="col-12">
                <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('settings.invoice-data') }} </h3>
            </div>
        </slot>
        <div class="col-12 settings-first-item">
            <label>{{ $t('common.type') }}</label>
            <select v-model="billingData.is_company" class="form-control">
                <option :value="0">{{ $t('settings.private') }}</option>
                <option :value="1">{{ $t('settings.company') }}</option>
            </select>
        </div>
        <div class="col-12 mt-2">
            <label>{{ $t('settings.name') }}</label>
            <div v-if="billingErrors['name']">
                <span v-for="(error, idx) in billingErrors['name']" style="color: red;" :key="idx">{{error}}</span>
            </div> 
            <input type="text" :placeholder="$t('settings.bill-address')" v-model="billingData.name" class="form-control" :style="{border: billingErrors['name'] ? '1px solid red' : '1px solid #ced4da'}" required>
        </div>
        <div class="col-12 mt-2">
            <label>{{ $t('settings.phone') }}</label>
            <div v-if="billingErrors['phone_number']">
                <span v-for="(error, idx) in billingErrors['phone_number']" style="color: red;" :key="idx">{{error}}</span>
            </div> 
            <input type="phone" :placeholder="$t('settings.phone')" v-model="billingData.phone_number" class="form-control" :style="{border: billingErrors['phone_number'] ? '1px solid red' : '1px solid #ced4da'}" required>
        </div>
        <div class="col-12 mt-2">
            <label>{{ $t('settings.country') }}</label>
            <div v-if="billingErrors['country']">
                <span v-for="(error, idx) in billingErrors['country']" style="color: red;" :key="idx">{{error}}</span>
            </div> 
            <select v-model="billingData.country_id" class="form-control">
                <option v-for="country in countries" :value="country.id" :key="country.id" >{{$t('countries.'+country.name)}}</option>
            </select>
        </div>
        <div class="col-12 mt-2">
            <label>{{ $t('settings.zip-code') }}</label>
            <div v-if="billingErrors['zip_code']">
                <span v-for="(error, idx) in billingErrors['zip_code']" style="color: red;" :key="idx">{{error}}</span>
            </div> 
            <input type="text" :placeholder="$t('common.zip-code')" v-model="billingData.zip_code" class="form-control" :style="{border: billingErrors['zip_code'] ? '1px solid red' : '1px solid #ced4da'}" required>
        </div>
        <div class="col-12 mt-2">
            <label>{{ $t('settings.city') }}</label>
            <div v-if="billingErrors['locality']">
                <span v-for="(error, idx) in billingErrors['locality']" style="color: red;" :key="idx">{{error}}</span>
            </div> 
            <input type="text"  :placeholder="$t('common.city')" v-model="billingData.locality" class="form-control" :style="{border: billingErrors['locality'] ? '1px solid red' : '1px solid #ced4da'}" required>
        </div>
        <div class="col-12 mt-2">
            <label>{{ $t('settings.street-name') }}</label>
            <div v-if="billingErrors['public_space']">
                <span v-for="(error, idx) in billingErrors['public_space']" style="color: red;" :key="idx">{{error}}</span>
            </div> 
            <input type="text" :placeholder="$t('common.street-name')" v-model="billingData.public_space" class="form-control" :style="{border: billingErrors['public_space'] ? '1px solid red' : '1px solid #ced4da'}" required>
        </div>
        <div class="col-12 mt-2">
            <label>{{ $t('settings.street-type') }}</label>
            <div v-if="billingErrors['public_space_type']">
                <span v-for="(error, idx) in billingErrors['public_space_type']" style="color: red;" :key="idx">{{error}}</span>
            </div> 
            <input type="text" :placeholder="$t('settings.street')" v-model="billingData.public_space_type" class="form-control" :style="{border: billingErrors['public_space_type'] ? '1px solid red' : '1px solid #ced4da'}" required>
        </div>
        <div class="col-12 mt-2">
            <label>{{ $t('settings.other') }}</label>
            <div v-if="billingErrors['other']">
                <span v-for="(error, idx) in billingErrors['other']" style="color: red;" :key="idx">{{error}}</span>
            </div> 
            <input type="text" :placeholder="$t('course-materials.placeholder')" v-model="billingData.other" class="form-control" :style="{border: billingErrors['other'] ? '1px solid red' : '1px solid #ced4da'}">
        </div>
        <div class="col-12 p-0" v-if="billingData.is_company">
            <div class="col-12 mt-2">
                <label>{{ $t('settings.company-name') }}</label>
                <div v-if="billingErrors['company_name']">
                    <span v-for="(error, idx) in billingErrors['company_name']" style="color: red;" :key="idx">{{error}}</span>
                </div>
                <input :placeholder="$t('settings.company-name')" type="text" v-model="billingData.company_name" class="form-control" :style="{border: billingErrors['company_name'] ? '1px solid red' : '1px solid #ced4da'}" required>
            </div>
            <div class="col-12 mt-2">
                <label>{{ $t('settings.company-tax') }}</label>
                <div v-if="billingErrors['company_tax_id']">
                    <span v-for="(error, idx) in billingErrors['company_tax_id']" style="color: red;" :key="idx">{{error}}</span>
                </div>
                <input :placeholder="$t('settings.company-tax')" type="text" v-model="billingData.company_tax_id" class="form-control" :style="{border: billingErrors['company_tax_id'] ? '1px solid red' : '1px solid #ced4da'}" required>
            </div>
            <div class="col-12 mt-2">
                <label>{{ $t('settings.company-eu-tax') }}</label>
                <div v-if="billingErrors['company_eu_tax_id']">
                    <span v-for="(error, idx) in billingErrors['company_eu_tax_id']" style="color: red;" :key="idx">{{error}}</span>
                </div>
                <input :placeholder="$t('settings.company-eu-tax')" v-model="billingData.company_eu_tax_id" type="text" class="form-control" :style="{border: billingErrors['company_eu_tax_id'] ? '1px solid red' : '1px solid #ced4da'}" required>
            </div> 
        </div>
        <div class="col-12">
            <div class="flex-wrap d-flex">
                <div class="d-flex ms-auto">
                    <button :disabled="loading" type="submit" class="btn btn-theme mt-3 mb-3"><img src="assets/img/icons/save.svg" class="img-fluid mr-2" alt="">{{ $t('settings.save-invoice') }}</button>
                </div>
            </div>
        </div>
            
        </div>
    </form>
</template>
<script>
export default {
    name: 'BillingData',
    props: {
        errors: {
            type: Object,
            optional: true,
            default: null
        },
        withSave: {
            type: Boolean,
            optional: true,
            default: true
        }
    },
    created() {
        this.axios.get('/countries')
            .then(resp => this.countries = this.countries.concat(resp.data))
    },
    computed: {
        billingErrors() {
            return this.errors ?? this.localErrors
        }
    },
    data() {
        return {
            loading: false,
            localErrors: {},
            countries: [],
            billingData: this.$store.state.user && this.$store.state.user.billing_information ? 
            {
                name: this.$store.state.user.billing_information.name,
                phone_number: this.$store.state.user.billing_information.phone_number,
                country_id: this.$store.state.user.billing_information.location.country.id,
                zip_code: this.$store.state.user.billing_information.location.zip_code,
                locality: this.$store.state.user.billing_information.location.locality,
                public_space: this.$store.state.user.billing_information.location.public_space,
                public_space_type: this.$store.state.user.billing_information.location.public_space_type,
                other: this.$store.state.user.billing_information.location.other,
                company_name: this.$store.state.user.billing_information.company_name,
                company_tax_id: this.$store.state.user.billing_information.company_tax_id,
                company_eu_tax_id: this.$store.state.user.billing_information.company_eu_tax_id,
                is_company: Number(
                    (this.$store.state.user.billing_information.company_name !== null && 
                    this.$store.state.user.billing_information.company_tax_id !== null && 
                    this.$store.state.user.billing_information.company_eu_tax_id !== null))
            } : {country_id: 141, is_company: 0},
        }
    },
    methods: {
        setBillingData() {
            this.loading = true
            if(!this.billingData.is_company) {
                this.billingData.company_name = null
                this.billingData.company_tax_id = null
                this.billingData.company_eu_tax_id = null
            }
            this.axios.post('/billing-information', this.$store.getters.removeEmptyOrNull(this.billingData))
                .then(resp => {
                    this.$store.state.user = resp.data.user
                })
                .catch(e => this.localErrors = e.response.data.errors)
                .finally(() => this.loading = false)
        },
    }
}
</script>