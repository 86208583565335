<template>
    <div class="modal fade" tabindex="-1" role="dialog" :id="`editBarionWalletModal-${wallet.id}`" :ref="`editBarionWalletModal-${wallet.id}`">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t('barion-wallets.title-edit')}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="submit" :id="`editBarionWalletForm-${wallet.id}`">
                        <label class="required">Email</label>
                        <input class="form-control" type="email" placeholder="A barion tárcád email címe" v-model="email"/>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary" :disabled="submitting" :form="`editBarionWalletForm-${wallet.id}`">{{$t('barion-wallets.save')}}</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="email = wallet.email">{{$t('barion-wallets.cancel')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CreateNewBarionWalletModal',
    emits: ['updated'],
    props: {
        wallet: Object
    },
    created() {
        this.email = this.wallet.email
    },
    data() {
        return {
            email: null,
            submitting: false,
        }
    },
    methods: {
        submit() {
            this.submitting = true
            this.axios.patch(`/barion-wallets/${this.wallet.id}`, {email: this.email})
                .then(resp => {
                    this.$emit('updated', resp.data.barion_wallet)
                    $(this.$refs[`editBarionWalletModal-${this.wallet.id}`]).modal('hide')
                    this.email = null
                })
                .catch(e => console.log(e))
                .finally(() => {
                    this.submitting = false
                })
        }
    }
}
</script>
