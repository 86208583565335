<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray" v-if="!loading && article">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex" v-if="article.approved">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Glossary'})">{{ $t('create-glossary-article.knowledge-base') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'GlossaryArticle', params: {id: $route.params.id}})">{{article ? article.title : $route.params.id}}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('edit-glossary-article.edit') }}</span>
                            </div>
                        </div>
                        <div class="flex-wrap d-flex" v-else>
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'UnapprovedGlossary'})">{{ $t('edit-glossary-article.waiting') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('edit-glossary-article.controll') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->
        <div id="content-2" v-show="!loading && article">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <section id="media-buttons">
                            <div class="row">
                                <div class="col-lg-6 mb-3">
                                    <div class="flex-wrap d-flex" @click="$refs.imageUpload.click()">
                                        <input type="file" accept="image/png, image/jpeg, image/jpg, image/gif" id="uploadImage" ref="imageUpload" @change="createUpload('image')" style="display: none;">
                                        <div class="d-flex align-items-center pointer mr-auto">
                                            <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                <img src="assets/img/icons/white-camera.svg" class="img-fluid ml-1" alt="">
                                            </div>
                                            <div class="d-flex flex-column ml-2">
                                                <h6 class="text-16 text-dark-gray">{{$t('course-materials.add-picture')}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-3">
                                    <div class="flex-wrap d-flex" @click="$refs.videoUpload.click()">
                                        <input type="file" accept="video/mp4, video/webm, video/avi" id="uploadVideo" ref="videoUpload" @change="createUpload('video')" style="display: none;">
                                        <div class="d-flex align-items-center pointer mr-auto">
                                            <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                <img src="assets/img/icons/videocam.svg" class="img-fluid ml-1" alt="">
                                            </div>
                                            <div class="d-flex flex-column ml-2">
                                                <h6 class="text-16 text-dark-gray">{{$t('course-materials.add-video')}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-3">
                                    <div class="flex-wrap d-flex" @click="$refs.audioUpload.click()">
                                        <input type="file" accept="audio/mp3, audio/mpeg, audio/wav" id="uploadAudio" ref="audioUpload" @change="createUpload('audio')" style="display: none;">
                                        <div class="d-flex align-items-center pointer mr-auto">
                                            <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                <img src="assets/img/icons/microphone.svg" class="img-fluid" alt="">
                                            </div>
                                            <div class="d-flex flex-column ml-2">
                                                <h6 class="text-16 text-dark-gray">{{$t('course-materials.add-voice')}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-3">
                                    <div class="flex-wrap d-flex" @click="$refs.documentUpload.click()">
                                        <input type="file" accept=".doc, .docx, .pdf, .xls, .xlsx, .ods, .ppt, .pptx, .txt" id="uploadDocument" ref="documentUpload" @change="createUpload('document')" style="display: none;">
                                        <div class="d-flex align-items-center pointer mr-auto">
                                            <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                <img src="assets/img/icons/file.svg" class="img-fluid ml-1" alt="">
                                            </div>
                                            <div class="d-flex flex-column ml-2">
                                                <h6 class="text-16 text-dark-gray">{{$t('course-materials.add-document')}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id="media-input" class="custom-input">
                            <form @submit.prevent="submit">
                                <div class="form-group mb-4">
                                    <label for="" class="text-18 text-dark-warm-gray">{{ $t('create-glossary-article.title') }}</label>
                                    <input v-if="!loading && article" type="text" class="form-control" placeholder="" v-model="article.title">
                                </div>
                                <div class="form-group mb-4">
                                    <label for="exampleFormControlTextarea1" class="text-18 text-dark-warm-gray">{{ $t('create-glossary-article.content') }}</label>
                                    <textarea ref="summernote" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                </div>
                                <div class="form-group">
                                    <label for="" class="text-18 text-dark-warm-gray">{{ $t('create-glossary-article.labels') }}</label>
                                    <input class="form-control"   v-model="commaLabels" />  
                                </div>
                                <div>
                                    <label for="">{{ $t('edit-glossary-article.attachments') }}</label>
                                </div>
                                <div class="d-flex">
                                    <span class="font-18" style="font-size: 18px" v-if="$store.state.user.role !== 'Admin'">
                                        <i class="fas fa-info-circle mr-2" style="color: var(--black)"></i>
                                        <span class="mb-3" style="font-size: 0.9em !important;">{{ $t('create-glossary-article.admin-msg') }}</span>
                                    </span>
                                </div>
                                <div class="col-12 ml-2 d-flex align-items-center" v-if="!loading && article" v-for="multimedia in article.multimedia.filter((mm) => mm.type == 'document')" :key="multimedia.id">
                                    <span>
                                        <a :href="$store.state.storageURL+multimedia.path+'?token='+$store.state.token" target="_blank">
                                            <i class="far fa-file-alt mr-1" alt="#" style="font-size: 20px !important;"></i>
                                            <span style="color: var(--black);">{{multimedia.name}}</span>
                                        </a>
                                    </span>
                                    <span v-if="!multimedia.loading" @click.prevent="removeMultimedia(multimedia)" class="d-inline-flex align-items-center ml-2" style="cursor: pointer;">
                                        <img src="assets/img/icons/remove.svg"  class="pointer" alt="">
                                    </span>
                                    <span v-else class="d-inline-flex align-items-center ml-2">
                                        <img src="assets/img/icons/remove.svg"  class="pointer" alt="">
                                    </span>
                                </div>
                                <div class="col-12 mt-4" v-if="!loading && article" v-for="multimedia in article.multimedia.filter((mm) => mm.type == 'audio')" :key="multimedia.id">
                                    <p class="ml-3 mb-0">{{multimedia.name}}</p>
                                    <div class="d-flex align-items-center">
                                        <audio controls>
                                            <source :src="$store.state.storageURL+multimedia.path+'?token='+$store.state.token">
                                        </audio>
                                        <span v-if="!multimedia.loading" @click.prevent="removeMultimedia(multimedia)" class="d-inline-flex align-items-center ml-2" style="cursor: pointer;">
                                            <img src="assets/img/icons/remove.svg"  class="pointer" alt="">
                                        </span>
                                        <span v-else class="d-inline-flex align-items-center ml-2">
                                            <img src="assets/img/icons/remove.svg"  class="pointer" alt="">
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 row mb-3">
                                    <div v-if="!loading && article" v-for="multimedia in article.multimedia.filter((mm) => mm.type == 'video' || mm.type == 'image')" :key="multimedia.id" class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-4" style="height: 260px;">
                                        <div v-if="multimedia.type == 'video' || multimedia.type == 'image' " style="color: var(--black); cursor:pointer; background-color: grey; width: 100%; height: 100%" 
                                            data-fancybox="fancy" 
                                            :href="multimedia.type != 'video' ? $store.state.storageURL+multimedia.path+'?token='+$store.state.token : '#'+multimedia.id" target="_blank"
                                            :data-caption="multimedia.name"
                                        >
                                            <div v-if="multimedia.type == 'video'" style="width: 100%; height: 100%">
                                                <video style="width: 100%; height: 100%; object-fit: cover; pointer-events: none;" nocontrols >
                                                    <source :src="$store.state.storageURL+multimedia.path+'?token='+$store.state.token">
                                                </video>
                                                <video :id="multimedia.id" style="display: none;" controls controlsList="nodownload">
                                                    <source :src="$store.state.storageURL+multimedia.path+'?token='+$store.state.token">
                                                </video>
                                                <i class="far fa-play-circle playbtn"></i>
                                                <span v-if="!multimedia.loading" @click.prevent="removeMultimedia(multimedia)">
                                                    <img src="assets/img/icons/remove.svg"  class="remove pointer" alt="">
                                                </span>
                                                <span v-else >
                                                    <img src="assets/img/icons/remove.svg"  class="remove pointer" alt="">
                                                </span>
                                            </div>
                                            <div v-if="multimedia.type == 'image'" style="width: 100%; height: 100%; position: relative;" >
                                                <img :src="$store.state.storageURL+multimedia.path+'?token='+$store.state.token" style="width: 100%; height: 100%; object-fit: cover;">
                                                <span v-if="!multimedia.loading" @click.prevent="removeMultimedia(multimedia)">
                                                    <img src="assets/img/icons/remove.svg"  class="remove pointer" alt="">
                                                </span>
                                                <span v-else >
                                                    <img src="assets/img/icons/remove.svg"  class="remove pointer" alt="">
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-wrap d-flex" v-if="!loading && article">
                                    <div class="d-flex">
                                        <button type="button" class="btn btn-theme-outline mt-3 mb-3" data-toggle="modal" data-target="#articleDeleteModal"><i class="fas fa-trash-alt"></i> {{ $t('edit-glossary-article.delete') }}</button>
                                    </div>
                                    <div class="d-flex ml-auto">
                                        <button v-if="article.approved" :disabled="submitting" type="submit" class="btn btn-theme mt-3 mb-3">{{ $t('edit-glossary-article.save-changes') }}</button>
                                        <button v-else :disabled="submitting" type="submit" class="btn btn-theme mt-3 mb-3">{{ $t('edit-glossary-article.save-allow') }}</button>
                                    </div>
                                </div>
                                <!-- DELETE MODAL -->
                                <div class="modal fade" id="articleDeleteModal" tabindex="-1" aria-labelledby="articleDeleteModalLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="articleDeleteModalLabel">{{ $t('edit-glossary-article.delete-sure') }}</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            {{ $t('edit-glossary-article.delete-question') }}
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('edit-glossary-article.cancel') }}</button>
                                            <button type="button" class="btn btn-theme-outline ml-auto" :disabled="submitting" @click="deleteArticle">{{ $t('edit-glossary-article.delete2') }}</button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </section>
                    </div>
                    <div class="col-lg-5">
                        <section id="buttons">
                            <div class="row">
                                <div class="col-12">
                                    <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('create-glossary-article.uploads') }}</h2>
                                </div>
                                <!-- <div class="col-12">
                                    <h6 class="text-13 text-dark-gray semi-bold">No data placeholder</h6>
                                </div> -->
                                <div class="col-12">
                                    <div class="row">
                                        <div v-for="(file, index) in files" :key="file.id" class="col-12 mb-3">
                                            <Upload v-if="file.shown" :file="file.file" :type="file.type" :id="file.id" @uploadFinished="finishUpload" @uploadCancelled="cancelUpload"/>
                                        </div>
                                        <div v-if="files.length == 0" class="mb-2 col-12">
                                            <h6 class="text-13 text-dark-gray semi-bold">{{ $t('fast-share.no-uploads') }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <Loading v-if="loading" height="70vh" />
    </div>
</template>
<script>
import Loading from '@/components/Loading.vue'
import Upload from '@/components/Upload.vue'
export default {
    name: 'CreateGlossaryArticle',
    components: {
        Loading,
        Upload
    },
    mounted() {
        this.loading = true
        $(this.$refs.summernote).summernote({
            height: 300,
            toolbar: [
                ['style', ['style.h1', 'style.h2', 'style.h3','style.h4', 'style.h5', 'style.h6']],
                ['style', ['bold', 'italic', 'underline',]],
                ['insert', ['link']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['view', ['fullscreen', 'help']],
            ],
            callbacks: {
                onChange: (contents, $editable) => {
                    this.article.content = contents
                }
            }
        })
        this.axios.get('/glossary-articles/'+this.$route.params.id)
            .then(resp => {
                this.article = resp.data.glossaryArticle
                $(this.$refs.summernote).summernote('code', this.article.content)
                this.commaLabels = this.article.labels.length > 0 ? this.article.labels.map(l => l.name).join(',') : null
            })
            .finally(() => this.loading = false)
    },
    data() {
        return {
            article: null,
            loading: false,
            submitting: false,
            commaLabels: null,
            files: []
        }
    },
    methods: {
        createUpload(type) {
            if(event.target.files.item(0))
                this.files.push({file: event.target.files.item(0), type: type, shown: true, uuid: null, id: this.files.length})
        },
        finishUpload(id, multimedia) {
            this.files.find(file => file.id === id).uuid = multimedia.id
            this.article.multimedia.push(multimedia)
            const multimediaIdx = this.article.multimedia.findIndex(m => m.id == multimedia.id)
            this.article.multimedia[multimediaIdx].loading = true
            this.axios.post('/glossary-articles/'+this.$route.params.id+'/multimedia/'+multimedia.id)
                .then(() => {
                    this.article.multimedia[multimediaIdx].loading = false
                    this.files.splice(this.files.indexOf(this.files.find(file => file.id === id)), 1)
                })
                .catch(() => this.article.multimedia.splice(this.article.multimedia.findIndex(m => m.id == multimedia.id), 1))
        },
        cancelUpload(id, uuid) {
            this.files.splice(this.files.indexOf(this.files.find(file => file.id === id)), 1)
        },
        removeMultimedia(multimedia) {
            const multimediaIdx = this.article.multimedia.findIndex(m => m.id == multimedia.id);
            this.article.multimedia[multimediaIdx].loading = true
            this.axios.delete('/glossary-articles/'+this.$route.params.id+'/multimedia/'+multimedia.id)
                .then(() => {
                    this.article.multimedia.splice(this.article.multimedia.findIndex(m => m.id == multimedia.id), 1)
                    this.article.multimedia[multimediaIdx].loading = false
                })
                .catch(() =>  this.article.multimedia[multimediaIdx].loading = false)
        },
        deleteArticle() {
            this.submitting = true
            this.axios.delete('/glossary-articles/'+this.$route.params.id)
                .then(() => {
                    $('#articleDeleteModal').modal('hide')
                    if(this.article.approved)
                        this.$router.push({name: 'Glossary'})
                    else
                        this.$router.push({name: 'UnapprovedGlossary'})
                })
                .finally(() => this.submitting = false)
        },
        submit() {
            this.submitting = true
            let formData = JSON.parse(JSON.stringify(this.article))
            formData.labels = this.commaLabels
            if(!this.article.approved)
                formData.approved = 1

            this.axios.patch('/glossary-articles/'+this.$route.params.id, formData)
                .then(resp => {
                    if(this.article.approved)
                        this.$router.push({name: 'GlossaryArticle', params: {id: this.$route.params.id}})
                    else
                        this.$router.push({name: 'UnapprovedGlossary'})
                })
                .catch(e => console.log(e.response))
                .finally(() => this.submitting = false)
        }
    }
}
</script>
<style>
.note-editor {
    background: white;
}
</style>
<style scoped>
.playbtn {
    position: absolute; 
    left: 50%; top: 50%; 
    transform: translate(-50%, -50%); 
    font-size: 70px; 
    color:white
}
.playbtn:hover {
    color: rgb(196, 196, 196);
}
.remove {
    position: absolute;
    top: 0;
    right: 4px;
    color: red;
    font-size: 25px;
}
.remove-disabled {
    position: absolute;
    top: 0;
    right: 4px;
    color: grey;
    font-size: 25px;
}
.remove:hover {
    color: rgb(112, 0, 0)
}

.remove-inline {
    color: red;
    font-size: 22px;
}
.remove-inline-disabled {
    color: grey;
    font-size: 22px;
}
.remove-inline:hover {
    color: rgb(112, 0, 0)
}

    @media (max-width: 425px) {
        .playbtn {
            font-size: 49px;
        }
        .remove,
        .remove-disabled {
            font-size: 17px;
        }
        .remove-inline,
        .remove-inline-disabled {
            font-size: 15px;
        }
    }

    .mobile-view-button .fa-file-video,
.mobile-view-button .fa-image,
.mobile-view-button .fa-microphone,
.mobile-view-button .fa-file-alt {
    font-size: 55px !important;
}

.mobile-item-1,
.mobile-item-2,
.mobile-item-3,
.mobile-item-4 {
    min-height: 150px !important;border-radius: 5px !important;padding-top: 34.5px;
}

.mobile-view-button .pc-text {font-size: 0..85rem;}

@media (min-width: 768px) {
    .mobile-view-button .mobile-text {display: none !important;}
}

@media (max-width: 767px) {
    .mobile-view-button .fa-file-video,
    .mobile-view-button .fa-image,
    .mobile-view-button .fa-microphone,
    .mobile-view-button .fa-file-alt {
        font-size: 30px !important;
    }
    .mobile-view-button .pc-text {display: none !important;}
    .mobile-view-button .mobile-text {
        font-size: 0.6rem;
        white-space: nowrap;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .mobile-item-1,
    .mobile-item-2,
    .mobile-item-3,
    .mobile-item-4 {
        padding-top: 15px;
        min-height: 80px !important;
    }
    .mobile-view-button.mobile-padding-1 {
        padding-right: 5px !important;
    }
    .mobile-view-button.mobile-padding-2 {
        padding-right: 5px !important;
        padding-left: 5px !important;
    }
    .mobile-view-button.mobile-padding-3 {
        padding-left: 5px !important;
    }
    #uploads h1.file-upload-text-header {
        font-size: 16px !important;
    }
    #uploads h2.file-upload-text {
        font-size: 14px !important;
    }
}
</style>
