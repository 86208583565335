<template>
  <div id="barion-wallets" v-if="!loading">
    <div class="row justify-content-between align-items-end">
      <div class="col">
        <img
          class="barion-logo"
          src="@/assets/img/settings/barion.png"
          alt=""
        />
      </div>
      <div class="col d-flex justify-content-center">
        <img
          class="wallet-logo"
          src="@/assets/img/settings/wallet.png"
          alt=""
        />
      </div>
      <div class="col" />
    </div>
    <div class="d-flex align-items-center justify-content-between my-4">
      <h1>{{ $t("settings.barion-wallets") }}</h1>
      <div
        class="add-button-section"
        data-toggle="modal"
        data-target="#createBarionWalletModal"
      >
        <p class="m-0">{{ $t("barion-wallets.add-new") }}</p>
        <IconButton icon="add" class="position-static" />
      </div>
    </div>

    <table>
      <thead>
        <tr>
          <th style="width: 70%">
            <span>{{ $t("barion-wallets.address") }}</span>
          </th>
          <th>
            <span>{{ $t("settings.action") }}</span>
          </th>
        </tr>
      </thead>
      <tbody v-for="(wallet, idx) in barionWallets" :key="idx">
        <tr>
          <td
            style="cursor: pointer"
            data-toggle="modal"
            :data-target="`#viewUserQualificationModal-${wallet.id}`"
          >
            <span> {{ wallet.email }} </span>
          </td>
          <td>
            <div class="d-flex align-items-center" style="gap: 20px">
              <IconButton
                icon="edit"
                class="position-static"
                data-toggle="modal"
                :data-target="'#editBarionWalletModal-' + wallet.id"
              />
              <TransparentButton
                data-toggle="modal"
                :data-target="'#delete-barion-wallet-modal-' + wallet.id"
                icon="delete-red"
                :text="$t('user-qualifications.delete')"
              />
            </div>
          </td>
          <edit-barion-wallet-modal
            :wallet="wallet"
            @updated="updateBarionWallet"
          />
          <generic-delete-modal
            :id="'delete-barion-wallet-modal-' + wallet.id"
            :itemDeletedReturnValue="wallet.id"
            :text="$t('barion-wallets.description-delete')"
            :title="$t('barion-wallets.title-delete')"
            :url="'/barion-wallets/' + wallet.id"
            @itemDeleted="removeBarionWallet"
          />
        </tr>
      </tbody>
    </table>

    <h6
      v-if="barionWallets.length === 0"
      class="text-13 text-dark-gray semi-bold mt-4"
    >
      {{ $t("user-qualifications.no-quals") }}
    </h6>
    <create-new-barion-wallet-modal
      @created="this.barionWallets.push($event)"
    />
  </div>
  <loading v-else />
</template>
<script>
import Loading from "../Loading.vue";
import GenericDeleteModal from "../GenericDeleteModal.vue";
import CreateNewBarionWalletModal from "../UserData/BarionWallets/CreateNewBarionWalletModal";
import EditBarionWalletModal from "../UserData/BarionWallets/EditBarionWalletModal";
import IconButton from "./IconButton.vue";
import TransparentButton from "@/components/settings/TransparentButton.vue";

export default {
  components: {
    TransparentButton,
    IconButton,
    Loading,
    CreateNewBarionWalletModal,
    EditBarionWalletModal,
    GenericDeleteModal,
  },
  name: "BarionWallet",
  created() {
    this.axios
      .get("/barion-wallets")
      .then((resp) => (this.barionWallets = resp.data.barion_wallets))
      .catch((e) => console.log(e))
      .finally(() => (this.loading = false));
  },
  data() {
    return {
      barionWallets: [],
      loading: true,
    };
  },
  methods: {
    updateBarionWallet(wallet) {
      const idx = this.barionWallets.findIndex((w) => w.id == wallet.id);
      if (idx !== -1) {
        this.barionWallets[idx] = wallet;
      }
    },
    removeBarionWallet(barionWalletId) {
      const idx = this.barionWallets.findIndex((q) => q.id == barionWalletId);
      if (idx !== -1) {
        this.barionWallets.splice(idx, 1);
      }
    },
  },
};
</script>
<style scoped>
#barion-wallets {
  padding: 40px 25px;
}
h1 {
  font-size: 20px;
  font-weight: 500;
  color: #333e48;
}
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #333e48;
}

/* START FORM */

form .form-group label {
  color: #333e48;
  font-size: 14px;
  font-weight: 500;
}

form .form-group .form-control {
  border: 1px solid #928c88;
  border-radius: 5px;
}
.table-with-inputs .head > div {
  background-color: #cecece;
  border: 1px solid #333e48;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-with-inputs .head > div span {
  font-weight: 500;
  font-size: 20px;
  color: #333e48;
}

.table-with-inputs .table-input {
  border: 1px solid #cecece;
  background-color: #fff;
  padding: 15px;
}

.table-with-inputs .remove-section {
  display: flex;
  justify-content: center;
}

.table-with-inputs .table-input .form-control {
  border: none;
  color: #333e48;
  font-size: 14px;
  font-weight: 500;
}

.table-with-inputs .table-input .rounded-red {
  margin-top: 0;
  margin-right: 0;
}

.add-button-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-button-section p {
  font-size: 18px;
  font-weight: 500;
  color: #837e7f;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

thead {
  background-color: #cecece;
  border-radius: 5px;
}

th {
  font-weight: 500;
  font-size: 15px;
  color: #333e48;
  padding: 10px;
  border: solid 1px #333e48;
  border-right: none;
  text-align: center;
}

th:first-of-type {
  border-top-left-radius: 5px;
}

th:last-of-type {
  border-top-right-radius: 5px;
  border-right: solid 1px #333e48;
}

tbody tr {
  border: 1px solid #cecece;
  background-color: #fff;
}

tbody tr:first-of-type {
  border-top: none;
}

tbody tr td {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #928c88;
  border: solid 1px #cecece;
  border-right: none;
}

tbody tr td .secondary-button {
  margin: 0 auto;
}

tbody tr td:last-of-type {
  border-right: solid 1px #cecece;
}

.barion-logo {
  height: 47px;
  width: 149px;
  object-fit: contain;
}

.wallet-logo {
  height: 100px;
  width: 100px;
  object-fit: contain;
}

button.icon-button {
  height: 38px;
  width: 38px;
}
</style>
