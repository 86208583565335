<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('course-catalog.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('feedback.name') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->

        <section id="feedback" class="mt-5">
            <div class="container">
                <form @submit.prevent="submit">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <div style="display: flex; justify-content: space-between; gap: 1rem; align-items: center; flex-wrap: wrap;">
                                <div class="text-left">
                                    <h1 class="weight-600 text-title-gray text-20">{{$t('feedback.header')}}</h1>
                                </div>
                                <div class="d-flex align-items-center">
                                    <router-link :to="{name: 'FeedbackList'}" v-if="$store.state.user.role == 'Admin'" class="btn btn-outline-primary float-right">{{$t('feedback.unresolved')}}</router-link>
                                    <router-link :to="{name: 'ResolvedFeedbackList'}" v-if="$store.state.user.role == 'Admin'" class="btn btn-outline-primary float-right ml-2">{{$t('feedback.resolved')}}</router-link>
                                </div>
                             </div>
                            <h2 class="mt-3 weight-500 text-16" style="color: #222222">{{$t('feedback.intro')}}</h2>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="form-group">
                                <label for="exampleFormControlSelect1" class="weight-500 text-title-gray text-16">{{$t('feedback.feedback-type')}}<span class="orange">*</span></label>
                                <select required :disabled="feedback.submitting" class="form-control" id="exampleFormControlSelect1" v-model="feedback.feedback_type_id">
                                    <option selected :value="null" disabled>-- {{$t('feedback.select')}} --</option>
                                    <option v-for="feedbackType in feedbackTypes"  :key="feedbackType.id" :value="feedbackType.id">{{$t('feedback.'+feedbackType.name)}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="exampleFormControlTextarea1" class="weight-500 text-title-gray text-16">{{$t('feedback.feedback-text')}} <span class="orange">*</span></label>
                                <textarea required :disabled="feedback.submitting" v-model="feedback.feedback" class="form-control" id="exampleFormControlTextarea1" rows="8" :placeholder="$t('feedback.textarea')"></textarea>
                            </div>
                        </div>
                        <div class="col-12">
                            <button type="submit" :disabled="feedback.submitting || feedback.feedback_type_id == null" class="btn btn-secondary float-right">{{$t('feedback.send')}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name: 'Feedback',
    mounted() {
        this.axios.get('/feedback-types')
            .then(resp => this.feedbackTypes = resp.data)
    },
    data() {
        return {
            feedbackTypes: [],
            feedback: {
                feedback_type_id: null,
                feedback: null,
                submitting: false
            }
        }
    },
    methods: {
        submit() {
            this.feedback.submitting = true
            this.axios.post('/feedback', this.feedback)
                .then(resp => {
                    this.$router.back()
                })
                .finally(() => this.feedback.submitting = false)

        }
    }
}
</script>
<style scoped>
 @media (max-width: 768px) {
     .btn-primary {
         width: 100% !important;
     }
 }

  @media (max-width: 730px) {
     .intro-container {
        justify-content: flex-end !important;
     }
 }
 .intro-container {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-direction: row-reverse;
     flex-wrap: wrap;
 }
</style>
