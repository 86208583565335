<template>
        <div class="modal" id="cropModal" ref="cropModal" tabindex="-1" data-backdrop="static" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-lg modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{ $t('image-cropper.cut') }}</h5>
                    <button type="button" class="close" @click="close(false)" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="img-container">
                        <img class="crop-img" ref="image" :src="src">
                    </div>
                </div>
                <div class="modal-footer">
                  <PrimaryButton data-dismiss="modal" :click="close" :text="$t('image-cropper.save')"/>
                </div>
                </div>
            </div>
        </div>
</template>

<script>
    import Cropper from "cropperjs";
    import 'cropperjs/dist/cropper.min.css'
    import PrimaryButton from "./settings/PrimaryButton.vue";
    export default {
        name: "ImageCropper",
      components: {PrimaryButton},
        data() {
            return {
                cropModal: {},
                cropper: {},
                destination: {},
                image: {}
            }
        },
        props: {
            src: String,
            type: String,
            aspectRatio: {
                optional: true,
                type: Number,
                default: 1
            }
        },
        methods: {
            close(save=true) {
                const canvas = this.cropper.getCroppedCanvas();
                this.destination = canvas.toDataURL(this.type);
                this.cropper.destroy();
                this.$emit('urlChanged', this.destination,save)
            }
        },
        emits: ['urlChanged'],
        mounted() {
            this.image = this.$refs.image;
            this.cropModal = this.$refs.cropModal;
            $(this.cropModal).modal('show')
            $(this.cropModal).on('hidden.bs.modal', () => {
                this.cropper.destroy();
            })
            this.cropper = new Cropper(this.image, {
            zoomable: false,
            scalable: false,
            responsive: true,
            aspectRatio: this.aspectRatio,
            checkOrientation: false,
         });
        },
        updated() {
            this.image = this.$refs.image;
            this.cropModal = this.$refs.cropModal;
            $(this.cropModal).modal('show')
            this.cropper = new Cropper(this.image, {
            zoomable: false,
            scalable: false,
            responsive: true,
            aspectRatio: this.aspectRatio,
            checkOrientation: false,
         });
        }
    }
</script>

<style scoped>
    .img-container {
        max-width: 100%;
        max-height: 100%;
    }
    .crop-img {
        max-width: 100%;
        display: block;
    }
    .img-preview {
        width: 200px;
        height: 200px;
        float: left;
        margin-left: 10px;
    }
</style>
