<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('course-catalog.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('chat.chat') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->
        <section id="chat" v-if="$store.state.user">
            <div class="container mt-4 mb-2">
                <div ref="chat" class="row">
                    <div class="col-xl-5 p-0 border-right">
                            <div class="chat-list">
                                <div ref="search-bar" class="p-3" style="background: #AABAC6; border-top-left-radius: 5px;">
                                    <div class="input-group chat-search-bg text-gray">
                                        <input v-debounce:300ms="filterGroups" type="text" class="form-control" :placeholder="$t('chat.search-2')" :aria-label="$t('chat.search-2')" aria-describedby="basic-addon1" style="color: gray;">
                                        <div class="input-group-append" style="border-radius: 5px;">
                                            <span class="input-group-text" id="basic-addon1">
                                                <i class="fas fa-search"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                              <!--<div class="pt-2 pb-2 pl-1 pr-1 d-flex justify-content-between" style="background: rgba(170, 186, 198, 0.25)">
                                  <button type="button" class="btn d-flex justify-content-center align-items-center" style="gap: 0.3rem;" data-toggle="modal" data-target="#newGroupChatModal">
                                      <div class="d-flex align-items-center justify-content-center" style="width: 35px; height: 35px; border-radius: 50%; background: var(&#45;&#45;red); color: white;">
                                          <i class="fas fa-user-friends" />
                                      </div>
                                      <div style="font-weight: 600; font-size: 16px; color: #545454">
                                          {{ $t('chat.create-group') }}
                                      </div>
                                  </button>
                                  <button type="button" class="btn d-flex justify-content-center align-items-center" style="gap: 0.3rem;" data-toggle="modal" data-target="#newChatModal">
                                      <div class="d-flex align-items-center justify-content-center" style="width: 35px; height: 35px; border-radius: 50%; background: var(&#45;&#45;red); color: white;">
                                          <i class="fas fa-edit" />
                                      </div>
                                      <div style="font-weight: 600; font-size: 16px; color: #545454">
                                          {{ $t('chat.new-chat') }}
                                      </div>
                                  </button>
                              </div>-->
                                <div class="row members" ref="conversations" style="overflow-y: scroll">
                                    <div v-for="chat in filteredChats" :key="chat.id" class="col-12 ">
                                        <div class="member new-pad" :class="getSelectedChat && getSelectedChat.id == chat.id ? 'active-convo' : ''">
                                            <div class="mb-xl-0 d-flex align-items-center pointer" @click="viewConversation(chat)">
                                                <div class="no-right " style="align-self: flex-start">
                                                    <img v-if="chat.name == null && chat.users.find(u => u.name != $store.state.user.name).avatar" style="width: 50px !important" class="img-fluid chat-prof" :src="$store.state.storageURL+chat.users.find(u => u.name != $store.state.user.name).avatar.path+'?token='+$store.state.token">
                                                    <Avatar v-else class="chat-prof" :username="chat.name ?? chat.users.find(u => u.name != $store.state.user.name).name" :size="50" />
                                                </div>
                                                <div class="ml-2 d-flex justify-between w-100" style="flex-wrap: wrap;">
                                                    <div class="d-flex flex-column w-100">
                                                        <div style="font-weight: 600; font-size: 16px; color: #222222">
                                                            {{chat.name ?? chat.users.find(u => u.name != $store.state.user.name).name}}
                                                        </div>
                                                        <div
                                                            class="text-truncate"
                                                            v-if="latestMessage(chat) && latestMessage(chat).sender_id == $store.state.user.id"
                                                            style="font-weight: 600; font-size: 14px; color: #888888; max-width: calc(100% - 120px)"
                                                        >
                                                            {{ $t('chat.you') }}: {{latestMessage(chat).message ?? $t('chat.multimedia-sent')}}
                                                        </div>
                                                        <div
                                                             class="text-truncate"
                                                             v-else-if="latestMessage(chat)"
                                                             style="font-weight: 600; font-size: 14px; color: #888888"
                                                             :style="{color: latestMessage(chat).chat_group_message_receivers.find(r => r.receiver_id == $store.state.user.id).read_at == null ? 'var(--black)' : ''}"
                                                        >
                                                            {{latestMessage(chat).message ?? $t('chat.multimedia-arrived')}}
                                                        </div>
                                                        <div
                                                            class="text-truncate"
                                                            style="font-weight: 600; font-size: 14px; color: #888888"
                                                            v-else
                                                        >
                                                            {{ $t('chat.no-messages') }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-if="latestMessage(chat)"
                                                        style="font-weight: 600; font-size: 14px; color: #888888; align-self: flex-end;"
                                                    >
                                                        {{getRelativeTime(latestMessage(chat).created_at)}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 no-chat" v-if="chats.length < 1">
                                        {{ $t('chat.no-chat') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div class="col-xl-7 p-0">
                        <ChatConversation v-if="getSelectedChat && !newConversation" :chat="getSelectedChat" :key="getSelectedChat.id" />
                        <div v-if="newConversation" class="w-100 h-100 d-flex flex-column">
                            <div class="w-100">
                                <div class="d-flex align-items-center phone-p-top p-3" style="background: #AABAC6; height: 70px;">
                                    <img v-if="newConversation.user.avatar" :src="$store.state.storageURL+newConversation.user.avatar.path+'?token='+$store.state.token" style="border-radius: 50%; height: 50px" class="ml-0" alt="">
                                    <Avatar v-else class="team-image ml-0" :username="newConversation.user.name" :size="50"/>
                                    <h6 class="name ml-2 mt-0 pl-0" style="font-size: 18px; font-weight: 600; color: white;">
                                        {{newConversation.user.name}} <br />
                                    </h6>
                                </div>
                            </div>
                            <div style="flex-grow: 1"></div>
                            <div class="w-100 p-2 d-flex flex-column align-items-center" style="background: #AABAC640">
                                <div class="d-flex align-items-center w-100">
                                    <div class="bot p-0" style="background: white; flex-grow: 1">
                                        <div class="input-group" style="border: 1px solid #B5ADA6; border-radius: 5px;">
                                            <textarea class="form-control" style="height: 100% !important; margin-right: 0px !important;padding-right: 0px !important; border: none;" :placeholder="$t('chat-conversation.type')" rows="1" v-model="newConversation.message"></textarea>
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            type="button"
                                            class="btn d-flex justify-content-center align-items-center"
                                            :disabled="submitting"
                                            @click="submitNewConversation"
                                        >
                                            <div class="d-flex align-items-center justify-content-center" style="width: 50px; height: 50px; border-radius: 50%; background: var(--red); color: white;">
                                                <i class="fas fa-paper-plane" style="font-size: 22px;" />
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="!getSelectedChat" class="mt-4">
                            <h3 class="text-center">{{ $t('chat.no-active') }}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      <!-- New Chat & New Group Chat Modals below -->
      <!--        <div class="modal fade" id="newChatModal" tabindex="-1" role="dialog" aria-labelledby="newChatModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                    <h5 class="modal-title" id="newChatModalLabel">{{ $t('chat.new-chat') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <form class="input-group mb-3 chat-search-bg modal-search-field" @submit.prevent="startSearchChattable">
                                <input type="text" class="form-control" :placeholder="$t('chat.user-name')" v-model="chattableSearchStr" style="color:gray">
                                <div :disabled="searching" class="input-group-append" style="border-radius: 5px;cursor: pointer;" @click="startSearchChattable()">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div v-if="!searching" class="col-12 mt-2 mb-3">
                            <div class="mb-4 d-flex" style="flex-wrap: wrap; gap: 1rem;">
                                <h5 class="text-16 text-title-gray" v-if="chattablePaginator">{{ $t('chat.all') }} {{chattablePaginator.total}}</h5>
                                <h5 class="text-16 text-title-gray" v-if="chattablePaginator && chattableResults.length > 0">{{ $t('chat.show') }} {{chattableResults.length}}</h5>
                            </div>
                            <div v-for="user in chattableResults" :key="user.id" class="mt-3">
                                <div class="d-flex align-items-center" style="cursor: pointer;" @click="startNewConversation(user)" data-dismiss="modal">
                                    <img v-if="user.avatar" :src="$store.state.storageURL+user.avatar.path+'?token='+$store.state.token" style="border-radius: 50%; height: 50px" class="ml-0" alt="">
                                    <Avatar v-else class="team-image ml-0" :username="user.name" :size="50"/>
                                    <h6 class="name ml-2 mt-0 pl-0" style="font-weight: bold">
                                        {{user.name}} <br />
&lt;!&ndash;                                        <span class="email mb-1" style="font-weight: 400;">{{user.email}}</span>&ndash;&gt;
                                    </h6>
                                </div>
                            </div>
                            <button v-if="chattablePaginator && chattablePaginator.current_page < chattablePaginator.last_page"
                                :disabled="chattablePaginator.loading"
                                class="mt-3 btn btn-primary"
                                @click.prevent="loadChattable()"
                            >
                                {{ $t('chat.more') }}
                            </button>
                        </div>
                        <div v-else class="col-12">
                            <Loading height="30vh" />
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>-->
      <!--
        <div class="modal fade" id="newGroupChatModal" tabindex="-1" role="dialog" aria-labelledby="newGroupChatModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                    <h5 class="modal-title" id="newGroupChatModalLabel">{{ $t('chat.create-group') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <form @submit.prevent="createGroupChat" class="w-100">
                            <div class="col-12">
                                <label>{{ $t('chat.group-name') }}</label>
                                <input :placeholder="$t('chat.group-name')" class="form-control w-100" type="text" v-model="groupChatName" required>
                            </div>
                            <div class="col-12 mt-3">
                                <span>{{ $t('chat.added-users') }} </span>
                                <div class="mt-2 d-flex align-items-center" v-for="user in groupChatSelected" :key="user.id">
                                    <div class="d-inline-flex align-items-center">
                                        <img v-if="user.avatar" :src="$store.state.storageURL+user.avatar.path+'?token='+$store.state.token" style="border-radius: 50%; height: 50px" class="ml-0" alt="">
                                        <Avatar v-else class="team-image ml-0" :username="user.name" :size="50"/>
                                        <h6 class="name ml-2 mt-0 pl-0" style="font-weight: bold">
                                            {{user.name}} <br />
&lt;!&ndash;                                            <span class="email mb-1">{{user.email}}</span>&ndash;&gt;
                                        </h6>
                                    </div>
                                    <span class="ml-4 mb-1" style="cursor: pointer" @click="removeFromGroupChatSelected(user)">
                                        <i class="fas fa-times" style="color: red; font-size: 24px"></i>
                                    </span>
                                </div>
                                <div class="mt-3 ml-2" v-if="groupChatSelected.length < 1">
                                    <strong>{{ $t('chat.no-users') }}</strong>
                                </div>
                            </div>
                            <div class="col-12 mt-4">
                                <button class="btn btn-primary w-100" :disabled="submitting">{{ $t('chat.create-group') }}</button>
                            </div>
                        </form>
                        <label class="col-12 mt-5">{{ $t('chat.add-users') }}</label>
                        <div class="col-12">
                            <form class="input-group mb-3 chat-search-bg modal-search-field" @submit.prevent="startSearchGroupChattable">
                                <input type="text" class="form-control" :placeholder="$t('chat.user-name')" v-model="groupChattableSearchStr" style="color:gray">
                                <div :disabled="searching" class="input-group-append" style="border-radius: 5px;cursor: pointer;" @click="startSearchGroupChattable()">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div v-if="!searching" class="col-12 mt-2 mb-3">
                            <div class="mb-4">
                                <h5 v-if="groupChattablePaginator">{{ $t('chat.all') }} {{groupChattablePaginator.total}}</h5>
                                <h5 v-if="groupChattablePaginator && groupChattableResults.length > 0">{{ $t('chat.show') }} {{groupChattableResults.length}}</h5>
                            </div>
                            <div v-for="user in groupChattableResults" :key="user.id" class="mt-3">
                                <div class="d-flex align-items-center" style="cursor: pointer;" @click="addToGroupChatSelected(user)" data-dismiss="modal">
                                    <img v-if="user.avatar" :src="$store.state.storageURL+user.avatar.path+'?token='+$store.state.token" style="border-radius: 50%; height: 50px" class="ml-0" alt="">
                                    <Avatar v-else class="team-image ml-0" :username="user.name" :size="50"/>
                                    <h6 class="name ml-2 mt-0 pl-0" style="font-weight: bold">
                                        {{user.name}} <br />
&lt;!&ndash;                                        <span class="email mb-1">{{user.email}}</span>&ndash;&gt;
                                    </h6>
                                </div>
                            </div>
                            <button v-if="groupChattablePaginator && groupChattablePaginator.current_page < groupChattablePaginator.last_page"
                                :disabled="groupChattablePaginator.loading"
                                class="mt-3 btn btn-primary"
                                @click.prevent="loadGroupChattable()"
                            >
                                {{ $t('chat.more') }}
                            </button>
                        </div>
                        <div v-else class="col-12">
                            <Loading height="30vh" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                </div>
            </div>
            </div>
        </div>
-->
    </div>
</template>
<script>
import { DateTime } from 'luxon';
import Avatar from '@/components/Avatar.vue'
import Loading from '@/components/Loading.vue'
import ChatConversation from '@/components/ChatConversation.vue'
import InfiniteScroll from '../components/InfiniteScroll.vue';
export default {
    name: 'Chat',
    components: {
        ChatConversation,
        Avatar,
        Loading
    },
    props: {
        conversation: {
            type: String,
            optional: true,
            default: null
        }
    },
    mounted() {
        if(this.chats) {
            const chat = this.chats[this.chats.findIndex(c => c.id === Number(this.conversation))] ?? this.chats[0]
            if(chat)
                this.viewConversation(chat)
        }
    },
    data() {
        return {
            chatsUnordered: this.$store.state.user ? this.$store.state.user.chat_groups_with_latest_message : null,
            formatter: DateTime.DATETIME_MED,
            selectedChat: null,
            chattableSearchStr: null,
            groupChattableSearchStr: null,
            groupChattablePaginator: null,
            chattablePaginator: null,
            filterStr: '',
            searching: false,
            chattableResults: [],
            groupChattableResults: [],
            groupChatSelected: [],
            groupChatName: null,
            newConversation: null,
            submitting: false
        }
    },
    updated() {
        if(this.$refs.conversations) {
          //get height of class chat-list
          let chatHeight = this.$refs["chat"].clientHeight;
          let searchBarHeight = this.$refs["search-bar"].clientHeight;
          //console.log(chatHeight, searchBarHeight);
            this.$refs.conversations.style.maxHeight = window.innerHeight - (460-70-80) + "px";
        }
    },
    computed: {
        getSelectedChat() {
            return this.selectedChat ?? (this.chats.length > 0 ? this.chats[0] : null);
        },
        chats() {
            return this.$store.state.user ? this.$store.state.user.chat_groups.sort((a,b) => new Date(this.latestMessage(b) ? this.latestMessage(b).created_at : b.created_at) - new Date(this.latestMessage(a) ? this.latestMessage(a).created_at : a.created_at)) : null
        },
        filteredChats() {
            const e = this.filterStr
            if(e.trim() == '') {
                return this.chats
            }
            else {
                return this.chats.filter(c => String(c.name).toLowerCase().includes(e) || c.users.some(u => String(u.name).toLowerCase().includes(e)))
            }
        }
    },
    methods: {
        getRelativeTime(isoDateStr) {
            const dt = DateTime.fromISO(isoDateStr)
            if(Math.abs(dt.diffNow().milliseconds) < 5000) {
                return this.$t('common.now')
            }
            return dt.toRelative()
        },
        latestMessage(group) {
            return group ? group.chat_group_messages[group.chat_group_messages.length -1] : null
        },
        startSearchChattable() {
            this.searching = true
            let params = {
                search: this.chattableSearchStr
            }
            this.axios.get('/chattable', {params})
                .then(resp => {
                    this.chattableResults = resp.data.users
                    this.chattablePaginator = resp.data.paginator
                    this.chattablePaginator.search = params.search
                })
                .finally(() => this.searching = false)
        },
        loadChattable() {
            this.chattablePaginator.loading = true
            this.axios.get('/chattable', {params: {page: this.chattablePaginator.current_page + 1, search: this.chattablePaginator.search}})
                .then(resp => {
                    const search = this.chattablePaginator.search
                    this.chattableResults.push(...resp.data.users)
                    this.chattablePaginator = resp.data.paginator
                    this.chattablePaginator.loading = true
                    this.chattablePaginator.search = search
                })
                .finally(() => this.chattablePaginator.loading = false)
        },
        startSearchGroupChattable() {
            this.searching = true
            let params = {
                search: this.groupChattableSearchStr
            }
            this.axios.get('/group-chattable', {params})
                .then(resp => {
                    this.groupChattableResults = resp.data.users.filter(u => !this.groupChatSelected.some(user => user.id == u.id))
                    this.groupChattablePaginator = resp.data.paginator
                    this.groupChattablePaginator.search = params.search
                })
                .finally(() => this.searching = false)
        },
        loadGroupChattable() {
            this.groupChattablePaginator.loading = true
            this.axios.get('/group-chattable', {params: {page: this.groupChattablePaginator.current_page + 1, search: this.groupChattablePaginator.search}})
                .then(resp => {
                    const search = this.groupChattablePaginator.search
                    this.groupChattableResults.push(...resp.data.users)
                    this.groupChattablePaginator = resp.data.paginator
                    this.groupChattablePaginator.loading = true
                    this.groupChattablePaginator.search = search
                })
                .finally(() => this.groupChattablePaginator.loading = false)
        },
        addToGroupChatSelected(user) {
            if(!this.groupChatSelected.some(u => u.id == user.id)) {
                this.groupChatSelected.push(user)
                const idx = this.groupChattableResults.findIndex(u => u.id == user.id)
                if(idx != -1) {
                    this.groupChattableResults.splice(idx, 1)
                }
            }
        },
        removeFromGroupChatSelected(user) {
            const idx = this.groupChatSelected.findIndex(u => u.id == user.id)
            if(idx != -1) {
                this.groupChatSelected.splice(idx, 1)
                this.groupChattableResults.push(user)
            }
        },
        createGroupChat() {
            this.submitting = true
            let formData = {name: this.groupChatName}
            if(this.groupChatSelected.length > 0) {
                formData.users = this.groupChatSelected.map(u => u.id)
            }
            this.axios.post('/conversation/group', formData)
                .then(resp => {
                    this.chats.push(resp.data.group)
                    this.selectedChat = resp.data.group
                    $('#newGroupChatModal').modal('hide')
                    this.newConversation = null
                    this.groupChatName = null
                    this.groupChatSelected = []
                    this.groupChattableSearchStr = null
                    this.groupChattableResults = []
                    this.$store.commit('subscribeToGroup', resp.data.group)
                })
                .finally(() => this.submitting = false)
        },
        submitNewConversation() {
            this.submitting = true
            this.axios.post('/conversation/user/'+this.newConversation.user.id, {message: this.newConversation.message})
                .then(resp => {
                    this.chats.push(resp.data.group)
                    this.selectedChat = resp.data.group
                    this.newConversation = null
                    this.$store.commit('subscribeToGroup', resp.data.group)
                })
                .finally(() => this.submitting = false)
        },
        startNewConversation(user) {
            this.newConversation = {
                user: user,
                message: null
            }
        },
        viewConversation(chat) {
            this.newConversation = null
            this.selectedChat = chat
            this.axios.post('/conversation/'+chat.id+'/read-messages')
                .then(() => {
                    this.selectedChat.chat_group_messages.forEach(msg => {
                        const idx = msg.chat_group_message_receivers.findIndex(r => r.receiver_id == this.$store.state.user.id && r.read_at == null)
                        if(idx !== -1)
                            msg.chat_group_message_receivers[idx].read_at = new Date()
                    })
                })
        },
        filterGroups(e) {
            this.filterStr = e.toLowerCase()
        }
    }
}
</script>
<style scoped>
#chat .container {
    background: #FFFFFF;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.15), -2px -2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}
.active-convo, .member:hover {
    background-color: #F8F8F8;
    border-radius: 5px;
}
.modal-search-field {
    border-radius: 5px;
}
.new-pad {
    padding: 15px 12px !important;
}

.no-chat {
  height: 60vh !important;
  text-align: center;
  line-height: 60vh;
}

::-webkit-input-placeholder { /* Edge */
  color: #C7C1BD !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #C7C1BD !important;
}

::placeholder {
  color: #C7C1BD !important;
}
</style>
