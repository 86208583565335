<template>
    <canvas id="deviceChart"></canvas>
</template>
<script>
import UAParser from 'ua-parser-js'
import randomColor from 'randomcolor'
export default {
    name: 'DeviceChart',
    props: {
        data: 
        {
           default: ['60000', '60000', '40000']
        }
    },
    mounted() {
        const ctx = $('#deviceChart')
        this.processData()
        this.chart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: Object.keys(this.processedData),
                datasets: [
                    {
                        data: Object.values(this.processedData),
                                  backgroundColor: [
                                    'rgb(255, 99, 132)',
                                    'rgb(54, 162, 235)',
                                    'rgb(255, 205, 86)'
                                ],
                    }
                ]
            },
            options: {
                legend: {
                    display: false,
                    labels: {
                        fontColor: 'rgb(255, 99, 132)'
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1
                        }
                    }]
                }
            }
        });
    },
    data() {
        return {
            processedData: {
                mobile: 0,
                tablet: 0,
                desktop: 0
            }
        }
    },
    methods: {
        processData() {
            let parser = new UAParser()
            this.data.forEach(d => {
                const device = parser.setUA(d).getResult().device.name ?? 'desktop'
                this.processedData[device] = (this.processedData[device] ?? 0) + 1
            })
        }
    }
}
</script>