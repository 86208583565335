<template>
    <div class="modal fade" tabindex="-1" role="dialog" id="createBarionWalletModal" ref="createBarionWalletModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{$t('barion-wallets.add-new')}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form @submit.prevent="submit" id="createBarionWalletForm">
                    <label class="required">Email</label>
                    <input class="form-control" type="email" :placeholder="$t('barion-wallets.wallet-address')" v-model="email"/>
                </form>
            </div>
            <div class="modal-footer">
                <button type="submit" form="createBarionWalletForm" class="btn btn-primary" :disabled="submitting">{{$t('barion-wallets.save')}}</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('barion-wallets.cancel')}}</button>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CreateNewBarionWalletModal',
    emits: ['created'],
    data() {
        return {
            email: null,
            submitting: false,
        }
    },
    methods: {
        submit() {
            this.submitting = true
            this.axios.post('/barion-wallets', {email: this.email})
                .then(resp => {
                    this.$emit('created', resp.data.barion_wallet)
                    $(this.$refs.createBarionWalletModal).modal('hide')
                    this.email = null
                })
                .catch(e => console.log(e))
                .finally(() => {
                    this.submitting = false
                })
        }
    }
}
</script>
