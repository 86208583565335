<template>
    <div v-if="!loading">
        <h3 class="text-18 text-dark-gray semi-bold mb-3 mt-4">Barion tárcák</h3>
        <div class="flex-wrap d-flex mb-3">
            <div class="d-flex align-items-center pointer mr-auto" data-toggle="modal" data-target="#createBarionWalletModal">
                <div class="new-button-40 d-flex align-items-center justify-content-center">
                    <img src="assets/img/icons/plus.svg" class="img-fluid" alt="">
                </div>
                
                <div class="d-flex flex-column ml-2">
                    <h6 class="text-16 text-dark-gray">Új hozzáadása</h6>
                </div>
            </div>
        </div>
        <create-new-barion-wallet-modal @created="this.barionWallets.push($event)" />
        <div class="mt-3">
            <div v-for="wallet in barionWallets" :key="wallet.id" class="d-flex">
                <div>{{wallet.email}}</div>
                <div class="ml-3" style="cursor: pointer" data-toggle="modal" :data-target="'#editBarionWalletModal-'+wallet.id">
                    <i class="fas fa-pen"/>
                </div>
                <div class="ml-2" style="cursor: pointer" data-toggle="modal" :data-target="'#delete-barion-wallet-modal-'+wallet.id">
                    <i class="fas fa-trash-alt text-danger"/>
                </div>
                <edit-barion-wallet-modal :wallet="wallet" @updated="updateBarionWallet"/>
                <generic-delete-modal 
                    :id="'delete-barion-wallet-modal-'+wallet.id" 
                    :itemDeletedReturnValue="wallet.id"
                    text="Are you sure you want to delete the selected barion wallet? You will stop receiving course purchase rewards on this wallet."
                    title="Delete barion wallet"
                    :url="'/barion-wallets/'+wallet.id"
                    @itemDeleted="removeBarionWallet"
                />
            </div>
        </div>
        <h6 v-if="barionWallets.length === 0" class="text-13 text-dark-gray semi-bold">Még nem adtál hozzá barion tárcát a profilodhoz.</h6>
    </div>
    <loading v-else />
</template>
<script>
import Loading from '../Loading.vue'
import CreateNewBarionWalletModal from './BarionWallets/CreateNewBarionWalletModal.vue'
import EditBarionWalletModal from './BarionWallets/EditBarionWalletModal.vue'
import GenericDeleteModal from '../GenericDeleteModal.vue'
export default {
  components: { Loading, CreateNewBarionWalletModal, EditBarionWalletModal, GenericDeleteModal },
    name: 'BarionWallets',
    created() {
        this.axios.get('/barion-wallets')
            .then(resp => this.barionWallets = resp.data.barion_wallets)
            .catch(e => console.log(e))
            .finally(() => this.loading = false)
    },
    data() {
        return {
            barionWallets: [],
            loading: true
        }
    },
    methods: {
        updateBarionWallet(wallet) {
            const idx = this.barionWallets.findIndex(w => w.id == wallet.id)
            if(idx !== -1) {
                this.barionWallets[idx] = wallet
            }
        },
        removeBarionWallet(barionWalletId) {
            const idx = this.barionWallets.findIndex(q => q.id == barionWalletId)
            if(idx !== -1) {
                this.barionWallets.splice(idx, 1)
            }
        }
    }
}
</script>