<template>
  <div>
    <section id="content-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <section id="knowledge-share">
              <div class="row mb-3">
                <div class="col-12">
                  <div class="flex-wrap d-flex" @click="$router.push({name: 'FastShare'})">
                    <div class="d-flex align-items-center pointer mr-auto">
                      <div class="new-button-60 d-flex align-items-center justify-content-center">
                        <img src="assets/img/icons/plus.svg" class="img-fluid" alt="">
                      </div>
                      <div class="d-flex flex-column ml-2">
                        <h6 class="text-18">{{ $t('dla-user-home.share-knowledge') }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="trainings">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('admin-home.suggested') }}</h2>
                </div>
                <div v-for="course in recommendedCourses" :key="course.id"
                     class="col-12 col-sm-6 col-lg-12 mb-4"
                     style="cursor: pointer;"
                     @click="$router.push({name: 'Course', params: {id: course.id}})"
                >
                  <course-card :course="course"/>
                </div>
                <div v-if="recommendedCourses.length == 0" class="col-12 col-sm-6 col-lg-12">
                  <h6 class="text-13 text-dark-gray semi-bold">{{ $t('admin-home.no-recommended') }}</h6>
                </div>
              </div>
              <!--      New Courses       -->
              <div class="row">
                <div class="col-12">
                  <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('master-home.new-courses') }}</h2>
                </div>
                <div v-for="course in newCourses" :key="course.id"
                     class="col-md-6 mb-4 col-12"
                     style="cursor: pointer;"
                     @click="$router.push({name: 'Course', params: {id: course.id}})"
                >
                  <course-card :course="course"/>
                </div>
                <div v-if="newCourses.length == 0" class=" mb-4 ml-4 col-12">
                  <h6 class="text-13 text-dark-gray semi-bold">{{ $t('admin-home.no-recommended') }}</h6>
                </div>
              </div>
            </section>
          </div>
          <div class="col-lg-8">
            <section id="timeline">
              <Feed/>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Feed from '@/components/Feed'
import CourseCard from './CourseCard.vue'

export default {
  name: 'AdminHome',
  components: {
    Feed,
    CourseCard
  },
  mounted() {
    this.axios.get('/test-attempts/uncorrected')
        .then((resp) => this.assignmentsToReturn = resp.data)
    this.axios.get('/courses/recommended')
        .then(resp => {
          this.recommendedCourses = resp.data.courses
        });
    this.axios.get('/courses/new')
        .then(resp => {
          this.newCourses = resp.data.courses
        });
  },
  data() {
    return {
      recommendedCourses: [],
      newCourses: [],
      assignmentsToReturn: []
    }
  }
}
</script>
<style scoped>
.card-title {
  margin-bottom: 0px !important;
  padding-top: 20px !important;
  text-align: center !important;
}

.card-body {
  background: none !important;
}

.dark-bg-line {
  background-color: var(--red-90);
}

@media (min-width: 426px) {
  .home-page-sep {
    display: none !important;
  }
}

@media (max-width: 425px) {
  .mt-5 {
    margin-top: 0px !important;
  }

  #trainings h1 {
    margin-top: 10px !important;
  }

  .home-page-sep {
    border: none !important;
    border-bottom: 2px solid var(--wlgray) !important;
  }
}
</style>
