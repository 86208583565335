<template>
    <canvas :id="'courseTestPercentage'+id"></canvas>
</template>
<script>
export default {
    name: 'courseTestPercentageChart',
    props: {
        data: Object,
        id: Number
    },
    mounted() {
        console.log(this.data)
        this.processedData = this.createData()
        Chart.defaults.scale.gridLines.display = false;
        const ctx = $('#courseTestPercentage'+this.id)
        this.chart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: Object.keys(this.processedData),
                datasets: [
                    {
                        data: Object.values(this.processedData).map(d => d.count),
                        backgroundColor: 'rgb(23, 111, 183, 0.8)',
                        borderColor: 'rgb(23, 111, 183, 1)',
                    }
                ]
            },
            options: {
                legend: {
                    display: false,
                    labels: {
                        fontColor: 'rgb(255, 99, 132)'
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    },
    data() {
        return {
            chart: null,
            processedData: null
        }
    },
    methods: {
        createData() {
            let tresholds = {
                "0%-10%": {
                    l: 0,
                    h: 10,
                    count: 0
                },
                "10%-20%": {
                    l: 10,
                    h: 20,
                    count: 0
                },
                "20%-30%": {
                    l: 20,
                    h: 30,
                    count: 0
                },
                "30%-40%": {
                    l: 30,
                    h: 40,
                    count: 0
                },
                "40%-50%": {
                    l: 40,
                    h: 50,
                    count: 0
                },
                "50%-60%": {
                    l: 50,
                    h: 60,
                    count: 0
                },
                "60%-70%": {
                    l: 60,
                    h: 70,
                    count: 0
                },
                "70%-80%": {
                    l: 70,
                    h: 80,
                    count: 0
                },
                "80%-90%": {
                    l: 80,
                    h: 90,
                    count: 0
                },
                "90%-100%": {
                    l: 90,
                    h: 100,
                    count: 0
                },
            }
            //console.log(Object.keys(tresholds))
            this.data.forEach(d => {
                const idx = d == 0 ? "0%-10%" : Object.keys(tresholds).find(t => d > tresholds[t].l && d <= tresholds[t].h)
                //console.log(`${idx} : ${d}`)      
                tresholds[idx].count++
            })

            return tresholds

        }
    }
}
</script>