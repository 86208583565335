<template>
  <button
    v-if="click"
    :disabled="disabled"
    type="submit"
    @click.prevent="click"
    class="red-button"
    :class="{
      'flex-row-reverse': rightIcon,
      'small-text': smallText,
      'position-static': isStatic,
    }"
  >
    <Icon
      v-show="icon"
      :name="icon"
      :is-disabled="disabled"
      style="color: #3f464d"
    />
    <span>{{ text }}</span>
  </button>
  <button
    v-else
    :disabled="disabled"
    type="submit"
    class="red-button"
    :class="{
      'flex-row-reverse': rightIcon,
      'small-text': smallText,
      'position-static': isStatic,
    }"
  >
    <Icon
      v-show="icon"
      :name="icon"
      :is-disabled="disabled"
      style="color: #3f464d"
    />
    <span>{{ text }}</span>
  </button>
</template>
<script>
import Icon from "../Icon";

export default {
  name: "PrimaryButton",
  components: { Icon },
  props: {
    disabled: {},
    text: {
      type: String,
      default: "Submit",
    },
    icon: {
      type: String,
    },
    click: {},
    rightIcon: {
      type: Boolean,
      default: false,
    },
    smallText: {
      type: Boolean,
      default: false,
    },
    isStatic: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
button.red-button {
  background-color: #d62b33;
  border-radius: 5px;
  border: 3px solid #d62b33;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  /*position: absolute;*/
  justify-content: center;
  height: 38px;
  gap: 10px;
}

button.red-button span {
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  color: #ffffff;
}

button.red-button.small-text span {
  font-size: 14px;
}

button.red-button:hover {
  background-color: var(--dark-red);
  border: 3px solid var(--dark-red);
}

button.red-button:disabled {
  border: 1.5px solid #cecece;
  background-color: #cecece;
}

button.red-button:disabled span {
  color: #fff;
}

:deep(button.red-button:disabled .icon svg path) {
  color: #fff;
  fill: #fff !important;
}
</style>
