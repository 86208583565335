<template>
    <div>
        <button type="button" class="btn btn-primary" @click.prevent="submit" :disabled="submitting">
            <span v-if="!submitting">{{ $t('edit-test.duplicate') }}</span>
            <div v-else class="d-flex align-items-center justify-content-center" style="height: 100%">
                <div class="mr-2">{{ $t('create-far.wait') }}</div>
                <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">{{ $t('create-far.disabled') }}</span>
                </div>
            </div>
        </button>
    </div>
</template>
<script>
export default {
    name: 'TestDuplicator',
    props: {
        testId: Number
    },
    data() {
        return {
            submitting: false
        }
    },
    methods: {
        submit() {
            if(this.submitting) return;
            this.submitting = true;
            this.axios.post(`/tests/${this.testId}/duplicate`)
                .then(resp => {
                    this.$router.push({name: 'ManageTests'})
                })
                .catch(e => console.log(e))
                .finally(() => this.submitting = false)
        }
    }
}
</script>
