<template>
  <div class="modal fade" id="uploadOrAttach" ref="uploadOrAttach" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Upload or Attach</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <span><strong>Upload</strong> new file or <strong>attach</strong> existing one</span>
        </div>
        <div class="modal-footer">
          <TransparentButton data-dismiss="modal" :click="()=>confirm(true)" :text="$t('common.attach')"/>
          <PrimaryButton data-dismiss="modal" :click="()=>confirm(false)" :text="$t('common.upload')"/>
        </div>
      </div>
    </div>
  </div>

  <MultiMediaList :uploadType="uploadType" @attach="attach"/>

</template>
<script>

import PrimaryButton from "./settings/PrimaryButton.vue";
import TransparentButton from "./settings/TransparentButton.vue";
import MultiMediaList from "./MultiMediaList.vue";

export default {
  name: "UploadOrAttach",
  components: {MultiMediaList, TransparentButton, PrimaryButton},
  props: {
    uploadType: {
      type: String,
    }
  },
  emits: ["attach", 'upload'],
  data() {
    return {
      isAttach: false
    };
  },
  methods: {
    confirm: function (isAttach) {
      if (!isAttach) {
        this.$emit("upload", isAttach);
      } else {
        //this.$emit("attach", isAttach);
        console.log("upload type", this.uploadType);
        this.isAttach = true;
        $("#multimediaListModal").modal("show");
      }
    },
    attach: async function (media) {
      let name = media.name;
      let rewind = false;
      if (!name && media.first_course_multimedia) {
        name = media.first_course_multimedia.name;
        rewind = media.first_course_multimedia.rewind;
      }
      let duration = await this.duration(media);
      this.$emit("attach", {
        file: media.path,
        name: name,
        type: media.type,
        shown: false,
        uuid: media.id,
        multimedia: media,
        length: duration,
        is_public: media.is_public,
        seconds:Math.floor(duration - (Math.floor(duration / 60) * 60)),
        minutes: Math.floor(duration / 60),
        rewind: rewind,
        loading: false
      });
      this.isAttach = false;
    },
    async duration(media) {
      let duration = 0;
      switch (media.type) {
        case 'video':
          if (media.first_course_multimedia) {
            duration = media.first_course_multimedia.length;
          } else {
            duration = 0;
          }
          break;
        case 'audio':
          if (media.first_course_multimedia) {
            duration = media.first_course_multimedia.length;
          } else {
            duration = await this.reCalculateDuration(media);
            console.log("duration", duration);
          }
          break;
        case 'image':
          duration = 0;
          break;
        case 'document':
          duration = 0;
          break;
        default:
          duration = 0;
      }
      return duration;
    },
    async reCalculateDuration(file) {
      let duration=0;

      const loadMedia = async file => new Promise(async (resolve, reject) => {
        try {
          let video = document.createElement('video')
          video.preload = 'metadata'
          //TODO: start conversion if file is video/avi
          video.onloadedmetadata = function () {
            resolve(this)
          }
          video.onerror = function () {
            reject("Invalid video. Please select a video file.")
          }
          video.src = this.$store.state.storageURL+file.path+'?token='+this.$store.state.token;
        } catch (e) {
          reject(e)
        }
      });
      const media = await loadMedia(file)
      console.log("media", media, media.duration);
      if (media) {
        duration = media.duration
      }

      return duration;
    }
  }
}

</script>
