<template>
    <div v-if="!loading">
        <div class="row mt-2">
            <div class="col-12 mb-4">
                <h2 class="mb-4">{{ $t('create-user-course-feedback.feedback') }}</h2>
                <p>{{ $t('create-user-course-feedback.thanks') }}</p>
                <p>{{ $t('create-user-course-feedback.please') }}</p>
            </div>
            <div v-for="currentQuestion in feedback.course_feedback_questions.filter(q => q.matrix_group === null)" :key="currentQuestion.id" class="col-12 mb-4">
                <div class="col-12 mb-4 p-0">
                    <h4>{{currentQuestion.question}}</h4>
                </div>
                <div class="col-12 mt-2 p-0">
                    <div v-if="currentQuestion.course_feedback_question_type.type == 'Single choice'">
                        <div v-for="answer in currentQuestion.course_feedback_question_answers" :key="answer.id" class="input-group mb-1">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <input type="radio" :value="answer.id" v-model="currentQuestion.answer" :name="currentQuestion.id" :aria-label="$t('correct-test-attempt.radio')">
                                </div>
                            </div>
                            <div type="text" class="form-control" style="height: fit-content !important;" :aria-label="$t('correct-test-attempt.text-input')">{{answer.answer}}</div>
                        </div>
                    </div>
                    <div v-else-if="currentQuestion.course_feedback_question_type.type == 'Multiple choice'">
                        <div v-for="answer in currentQuestion.course_feedback_question_answers" :key="answer.id" class="input-group mb-1">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <input type="checkbox" v-model="answer.chosen" :aria-label="$t('correct-test-attempt.radio')">
                                </div>
                            </div>
                            <div type="text" class="form-control" style="height: fit-content !important;" :aria-label="$t('correct-test-attempt.text-input')">{{answer.answer}}</div>
                        </div>
                    </div>
                    <div v-else-if="currentQuestion.course_feedback_question_type.type == 'Free text'">
                        <textarea class="form-control" v-model="currentQuestion.answer" :aria-label="$t('correct-test-attempt.txt-area')"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-12 mb-4" v-if="feedback.course_feedback_questions.filter(q => q.matrix_group !== null).length > 0">
                <div class="col-12 mt-2 p-0">
                    <h4>{{ $t('create-user-course-feedback.feedback-q') }}</h4>
                    <div class="mb-4 ml-1">
                        <span><strong>1:</strong> {{ $t('create-user-course-feedback.f-1') }}</span><br>
                        <span><strong>2:</strong> {{ $t('create-user-course-feedback.f-2') }}</span><br>
                        <span><strong>3:</strong> {{ $t('create-user-course-feedback.f-3') }}</span><br>
                        <span><strong>4:</strong> {{ $t('create-user-course-feedback.f-4') }}</span>
                    </div>
                    <table>
                        <tr class="font-24" style="font-size: 24px; font-variant-numeric: tabular-nums;">
                            <th></th>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                        </tr>
                        <tr v-for="currentQuestion in feedback.course_feedback_questions.filter(q => q.matrix_group !== null)" :key="currentQuestion.id">
                            <td class="font-24" style="font-size: 24px;">{{currentQuestion.question}}</td>
                            <td v-for="answer in currentQuestion.course_feedback_question_answers.sort((a,b) => Number(a.answer) - Number(b.answer))" :key="answer.id">
                                <input style="width: 20px; height: 20px;" type="radio" :value="answer.id" v-model="currentQuestion.answer" :name="currentQuestion.id" :aria-label="$t('correct-test-attempt.radio')">
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-12">
                <button :disabled="submitting || !canSend" @click="submit" class="btn btn-primary">{{ $t('create-user-course-feedback.send') }}</button>
            </div>
        </div>
    </div>
    <loading height="500px" v-else />
</template>
<script>
import Loading from '../components/Loading.vue'
export default {
    components: { Loading },
    props: {
        course: [String, Number]
    },
    emits: ['submitted'],
    name: 'CreateUserCourseFeedback',
    mounted() {
        this.axios.get('/courses/'+this.course+'/feedback')
            .then(resp => this.feedback = resp.data)
            .finally(() => this.loading = false)
    },
    data() {
        return {
            feedback: null,
            loading: true,
            submitting: false
        }
    },
    computed: {
        canSend() {
            return !this.getAnswers().some(question => question.answers.length == 0 || question.answers.some(a => !a.course_feedback_question_answer_id && !a.answer))
        }
    },
    methods: {
        submit() {
            this.submitting = true
            this.axios.post('/courses/'+this.course+'/user-feedback', {questions: this.getAnswers()})
                .then(resp => {
                    this.$emit('submitted')
                })
                .finally(() => this.submitting = false)
            
        },
        getAnswers() {
            let questions = []
            this.feedback.course_feedback_questions.forEach(question => {
                let current = {
                    course_feedback_question_id: question.id,
                    answers: Array()
                }
                if(question.course_feedback_question_type.type == 'Multiple choice') {
                    current.answers.push(
                        ...question.course_feedback_question_answers.filter(a => a.chosen)
                            .map(a => Object({course_feedback_question_answer_id: a.id}))
                    )
                }
                else if(question.course_feedback_question_type.type == 'Single choice')  {
                    current.answers.push({
                        course_feedback_question_answer_id: question.answer
                    })
                }
                else if (question.course_feedback_question_type.type == 'Free text') {
                    current.answers.push({
                        answer: question.answer
                    })
                }
                questions.push(current)
            })
            return questions
        }
    }
}
</script>
<style scoped>
th, td {
  padding: 15px;
}
</style>
