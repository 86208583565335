<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('reporting.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Reporting'})">{{ $t('reporting.statistic') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'BarAssociationReports'})">{{ $t('reporting.report') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{course ? course.title : $t('course-bar-association-report.course')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->
        <div id="content-2">
            <div class="container">
                <!-- RESPONSIVE TABLE -->
                <div class="row">
                    <div class="col-12 mt-5 mb-3">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex">
                                <h2 class="text-18 text-dark-gray semi-bold">{{ $t('course-bar-association-report.credit-report') }}</h2>
                            </div>
                            <div class="d-flex ms-auto" v-if="false">
                                <button type="button" :disabled="creditExportLoading" class="btn btn-theme-outline" @click.prevent="downloadCreditExport">
                                        <i class="fas fa-file-excel"></i>
                                        <span class="ml-2">{{ $t('course-bar-association-report.download-table') }}</span>
                                </button>
                                <div v-if="creditExportLoading" class="spinner-border text-danger ml-2 mb-2 mr-4" role="status">
                                    <span class="sr-only">{{ $t('activity-history.loading') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mb-4">
                        <div class="new-table filter-shadow">
                            <table class="table borderless-table">
                                <thead class="bg-light-gray-primary">
                                    <tr>
                                        <th :class="'pointer'+' '+(currentSort==='name'?'sorted':'')" @click="sort('name')">{{ $t('course-bar-association-report.name') }}</th>
                                        <th :class="'pointer'+' '+(currentSort==='finished_at'?'sorted':'')" @click="sort('finished_at')">{{ $t('course-bar-association-report.date') }}</th>
                                        <th >{{ $t('course-bar-association-report.download') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="user in sortedCreditReports" :key="user.bar_association_number">
                                        <td>{{user.name}} </td>
                                        <td>{{$store.getters.parseDate(user.finished_at)}} </td>
                                        <td> <course-certificate-download :user="user.id" :course="$route.params.course" :spinner="false" /> </td>
                                    </tr>
                                    <tr v-if="creditReports.length == 0">
                                        <td colspan="6" class="text-center text-light-black semi-bold pt-4 pb-4">{{ $t('no-data.analytics')}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- //RESPONSIVE TABLE -->
                <!-- RESPONSIVE TABLE -->
                <div class="row" v-if="false">
                    <div class="col-12 mt-5 mb-3">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex">
                                <h2 class="text-18 text-dark-gray semi-bold">{{ $t('course-bar-association-report.far-members') }}</h2>
                            </div>
                            <div class="d-flex ms-auto">
                                <button style="margin-left: 10px" type="button" :disabled="farExportLoading" class="btn btn-theme-outline" @click.prevent="downloadFarExport">
                                        <i class="fas fa-file-excel"></i>
                                        <span class="ms-2">{{ $t('course-bar-association-report.far') }}</span>
                                </button>
                                <div v-if="farExportLoading" class="spinner-border text-danger ml-2 mb-2 mr-4" role="status">
                                    <span class="sr-only">{{ $t('activity-history.loading') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mb-4">
                        <div class="new-table filter-shadow">
                            <table class="table borderless-table">
                                <thead class="bg-light-gray-primary">
                                    <tr>
                                        <th>{{ $t('course-bar-association-report.educated') }}</th>
                                        <th>{{ $t('course-bar-association-report.current-name') }}</th>
                                        <th>{{ $t('course-bar-association-report.birth-name') }}</th>
                                        <th>{{ $t('course-bar-association-report.mother-name') }}</th>
                                        <th>{{ $t('course-bar-association-report.birth-country') }}</th>
                                        <th>{{ $t('course-bar-association-report.birth-place') }}</th>
                                        <th>{{ $t('course-bar-association-report.birth-date') }}</th>
                                        <th>{{ $t('course-bar-association-report.email') }}</th>
                                        <th>{{ $t('course-bar-association-report.foreign') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="user in farReports" :key="user.name">
                                        <td>
                                            {{user.qualification}}
                                        </td>
                                        <td>{{user.name}} </td>
                                        <td>{{user.birth_name}} </td>
                                        <td>{{user.mothers_name}} </td>
                                        <td>{{user.country}}</td>
                                        <td>{{user.birth_place}} </td>
                                        <td>{{user.birth_date}} </td>
                                        <td>{{user.email}} </td>
                                        <td>{{user.foreigner}}</td>
                                    </tr>
                                    <tr v-if="farReports == 0">
                                        <td colspan="9" class="text-center text-light-black semi-bold pt-4 pb-4">{{ $t('no-data.analytics')}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- //RESPONSIVE TABLE -->
                <!-- RESPONSIVE TABLE -->
                <div class="row" v-if="false">
                    <div class="col-12 mt-5 mb-3">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mb-3">
                                <h2 class="text-18 text-dark-gray semi-bold">{{ $t('course-bar-association-report.adult') }}</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mb-4">
                        <div class="new-table filter-shadow">
                            <table class="table borderless-table">
                                <thead class="bg-light-gray-primary">
                                    <tr>
                                        <th>#</th>
                                        <th>{{ $t('course-bar-association-report.user-name') }}</th>
                                        <th>{{ $t('course-bar-association-report.user-mail') }}</th>
                                        <th>{{ $t('course-bar-association-report.signature-date') }}</th>
                                        <th>{{ $t('course-bar-association-report.user-download') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="user in contracts" :key="user.id">
                                        <td>
                                            {{user.id}}
                                        </td>
                                        <td>{{user.name}} </td>
                                        <td>{{user.email}} </td>
                                        <td>{{$store.getters.parseDateTime(user.signed_at)}} </td>
                                        <td><far-contract-download :user="user.id" :course="$route.params.course" /></td>
                                    </tr>
                                    <tr v-if="contracts == 0">
                                        <td colspan="9" class="text-center text-light-black semi-bold pt-4 pb-4">{{ $t('no-data.analytics')}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- //RESPONSIVE TABLE -->
            </div>
        </div>
    </div>
</template>
<script>
import { Capacitor } from '@capacitor/core';
import {DateTime} from 'luxon'
import FarContractDownload from '../components/FarContractDownload.vue'
import CourseCertificateDownload from '../components/CourseCertificateDownload.vue'
import Loading from '../components/Loading.vue'
export default {
  components: { Loading, FarContractDownload, CourseCertificateDownload },
    name: 'CourseBarAssociationreprt',
    mounted() {
        this.axios.get('/reports/bar-assoc/'+this.$route.params.course)
            .then(resp => {
                this.course = resp.data.course
                this.creditReports = resp.data.credit
                this.farReports = resp.data.far
                this.contracts = resp.data.contracts
            })
            .finally(() => this.loading = false)
    },
    data() {
        return {
            loading: true,
            course: null,
            creditReports: [],
            farReports: [],
            contracts: [],
            creditExportLoading: false,
            farExportLoading: false,
            currentSort:'name',
            currentSortDir:'asc',
        }
    },
    methods: {
        downloadCreditExport() {
            this.creditExportLoading = true;
            this.axios.get('/reports/export/credit/'+this.$route.params.course, {responseType: 'blob'})
                .then(resp => {
                    const name = `kredit_lejelentes_${this.course.title}_${DateTime.local().toLocaleString()}.xlsx`
                    if(Capacitor.isNative) {
                        this.$store.commit('downloadFileMobile', {name, mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', data: resp.data})
                    }
                    else {
                        const url = window.URL.createObjectURL(new Blob([resp.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', name);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
                .catch(e => console.log(e.response))
                .finally(() => this.creditExportLoading = false)
        },
        downloadFarExport() {
            this.farExportLoading = true;
            this.axios.get('/reports/export/far/'+this.$route.params.course, {responseType: 'blob'})
                .then(resp => {
                    const name = `far_lejelentes_${this.course.title}_${DateTime.local().toLocaleString()}.xlsx`
                    if(Capacitor.isNative) {
                        this.$store.commit('downloadFileMobile', {name, mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', data: resp.data})
                    }
                    else {
                        const url = window.URL.createObjectURL(new Blob([resp.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', name);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
                .finally(() => this.farExportLoading = false)
        },
        sort: function (s) {
          //if s == current sort, reverse
          if (s === this.currentSort) {
            this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
          }
          this.currentSort = s;
        },

    },
  computed: {
    sortedCreditReports: function () {
      return this.creditReports.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    }
  }
}
</script>

<style scoped>

.sorted{
  font-weight: normal;
}
</style>
