<template>
  <div id="billing-data">
    <div class="row align-items-center">
      <div class="col">
        <h1 class="ps-2">{{ $t("settings.billing-info") }}</h1>
      </div>
      <div class="col">
        <img
          src="@/assets/img/settings/bill.png"
          alt=""
          class="mx-auto d-block"
        />
      </div>
      <div class="d-flex flex-column" style="gap: 20px">
        <a :href="xbodyIdUrl" target="_blank">
          <img
            src="@/assets/xbody_id.svg"
            style="max-height: 40px !important"
            alt=""
          />
        </a>
        <a :href="xbodyIdUrl" target="_blank" class="red-button small-text position-static">Manage your Billing information in XBody ID</a>
      </div>
    </div>
    <form class="mt-5">
      <div class="form-group">
        <label>Type</label>
        <select
          v-model="billingData.is_company"
          disabled
          class="form-select"
        >
          <option :value="0">{{ $t("settings.private") }}</option>
          <option :value="1">{{ $t("settings.company") }}</option>
        </select>
      </div>
      <div class="form-group">
        <label>{{ $t("settings.name") }}</label>
        <div v-if="billingErrors['name']">
          <span
            v-for="(error, idx) in billingErrors['name']"
            style="color: red"
            :key="idx"
            >{{ error }}</span
          >
        </div>
        <input
          type="text"
          :placeholder="$t('settings.bill-address')"
          class="form-control"
          v-model="billingData.name"
          :style="{
            border: billingErrors['name']
              ? '1px solid red'
              : '1px solid #ced4da',
          }"
          disabled
          required
        />
      </div>
      <div class="form-group">
        <label>{{ $t("settings.phone") }}</label>
        <div v-if="billingErrors['phone_number']">
          <span
            v-for="(error, idx) in billingErrors['phone_number']"
            style="color: red"
            :key="idx"
            >{{ error }}</span
          >
        </div>
        <input
          type="phone"
          :placeholder="$t('settings.phone')"
          v-model="billingData.phone_number"
          :style="{
            border: billingErrors['phone_number']
              ? '1px solid red'
              : '1px solid #ced4da',
          }"
          required
          disabled
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label>{{ $t("settings.country") }}</label>
        <div v-if="billingErrors['country']">
          <span
            v-for="(error, idx) in billingErrors['country']"
            style="color: red"
            :key="idx"
            >{{ error }}</span
          >
        </div>
        <Select2
          v-model="billingData.country_id"
          :options="countries"
          disabled
        />
      </div>
      <div class="form-group">
        <label>{{ $t("settings.zip-code") }}</label>
        <div v-if="billingErrors['zip_code']">
          <span
            v-for="(error, idx) in billingErrors['zip_code']"
            style="color: red"
            :key="idx"
            >{{ error }}</span
          >
        </div>
        <input
          type="text"
          :placeholder="$t('common.zip-code')"
          v-model="billingData.zip_code"
          class="form-control"
          :style="{
            border: billingErrors['zip_code']
              ? '1px solid red'
              : '1px solid #ced4da',
          }"
          disabled
          required
        />
      </div>
      <div class="form-group">
        <label>{{ $t("settings.city") }}</label>
        <div v-if="billingErrors['locality']">
          <span
            v-for="(error, idx) in billingErrors['locality']"
            style="color: red"
            :key="idx"
            >{{ error }}</span
          >
        </div>
        <input
          type="text"
          :placeholder="$t('common.city')"
          v-model="billingData.locality"
          class="form-control"
          :style="{
            border: billingErrors['locality']
              ? '1px solid red'
              : '1px solid #ced4da',
          }"
          disabled
          required
        />
      </div>
      <div class="form-group">
        <label>{{ $t("settings.street-name") }}</label>
        <div v-if="billingErrors['public_space']">
          <span
            v-for="(error, idx) in billingErrors['public_space']"
            style="color: red"
            :key="idx"
            >{{ error }}</span
          >
        </div>
        <input
          type="text"
          :placeholder="$t('common.street-name')"
          v-model="billingData.public_space"
          class="form-control"
          :style="{
            border: billingErrors['public_space']
              ? '1px solid red'
              : '1px solid #ced4da',
          }"
          disabled
          required
        />
      </div>
      <!-- <div class="form-group">
        <label>{{ $t("settings.street-type") }}</label>
        <div v-if="billingErrors['public_space_type']">
          <span
            v-for="(error, idx) in billingErrors['public_space_type']"
            style="color: red"
            :key="idx"
            >{{ error }}</span
          >
        </div>
        <input
          type="text"
          :placeholder="$t('settings.street')"
          v-model="billingData.public_space_type"
          class="form-control"
          :style="{
            border: billingErrors['public_space_type']
              ? '1px solid red'
              : '1px solid #ced4da',
          }"
          disabled
          required
        />
      </div> -->
      <div class="form-group">
        <label>street number</label>
        <div v-if="billingErrors['other']">
          <span
            v-for="(error, idx) in billingErrors['other']"
            style="color: red"
            :key="idx"
            >{{ error }}</span
          >
        </div>
        <input
          type="text"
          :placeholder="$t('course-materials.placeholder')"
          v-model="billingData.other"
          class="form-control"
          :style="{
            border: billingErrors['other']
              ? '1px solid red'
              : '1px solid #ced4da',
          }"
          disabled
        />
      </div>
      <div v-if="billingData && billingData.sap_code" class="form-group">
        <label>{{ $t("settings.sap-code") }}</label>
        <input
          type="text"
          :disabled="true"
          class="form-control"
          :value="billingData.sap_code"
          :style="{ border: '1px solid #ced4da' }"
        />
      </div>
      <template v-if="billingData.is_company">
        <div class="form-group">
          <label>{{ $t("settings.company-name") }}</label>
          <div v-if="billingErrors['company_name']">
            <span
              v-for="(error, idx) in billingErrors['company_name']"
              style="color: red"
              :key="idx"
              >{{ error }}</span
            >
          </div>
          <input
            :placeholder="$t('settings.company-name')"
            type="text"
            v-model="billingData.company_name"
            class="form-control"
            :style="{
              border: billingErrors['company_name']
                ? '1px solid red'
                : '1px solid #ced4da',
            }"
            disabled
            required
          />
        </div>
        <div v-show="billingData.is_company && !hasEUVat" class="form-group">
          <label>{{ $t("settings.company-tax") }}</label>
          <div v-if="billingErrors['company_tax_id']">
            <span
              v-for="(error, idx) in billingErrors['company_tax_id']"
              style="color: red"
              :key="idx"
              >{{ error }}</span
            >
          </div>
          <input
            :placeholder="$t('settings.company-tax')"
            type="text"
            v-model="billingData.company_tax_id"
            class="form-control"
            :style="{
              border: billingErrors['company_tax_id']
                ? '1px solid red'
                : '1px solid #ced4da',
            }"
            disabled
            required
          />
        </div>
        <div v-show="billingData.is_company && hasEUVat" class="form-group">
          <label>{{ $t("settings.company-eu-tax") }}</label>
          <div v-if="billingErrors['company_eu_tax_id']">
            <span
              v-for="(error, idx) in billingErrors['company_eu_tax_id']"
              style="color: red"
              :key="idx"
              >{{ error }}</span
            >
          </div>
          <input
            :placeholder="$t('settings.company-eu-tax')"
            v-model="billingData.company_eu_tax_id"
            type="text"
            class="form-control"
            :style="{
              border: billingErrors['company_eu_tax_id']
                ? '1px solid red'
                : '1px solid #ced4da',
            }"
            disabled
            required
          />
        </div>
      </template>
      <div class="d-flex mt-5 justify-content-end">
        <!-- <button
          type="submit"
          :disabled="loading"
          @click.prevent="setBillingData"
          class="red-button"
        >
          <span>{{ $t("settings.save-invoice") }}</span>
          <Icon name="save" />
        </button> -->
      </div>
    </form>
    <ConfirmationModal
      id="hasEUVatModal2"
      title="Do you have a EU VAT number?"
      text="You can use EU VAT number."
      @hasEUConsent="hasEUConsent"
    />
  </div>
</template>

<script>
import Icon from "../Icon";
import Select2 from "vue3-select2-component";
import ConfirmationModal from "../ConfirmationModal.vue";
import PrimaryButton from "@/components/settings/PrimaryButton.vue";

export default {
  created() {
    this.axios.get("/countries").then((resp) => {
      resp.data.map((country) => {
        this.countries.push({
          ...country,
          text: country.name,
        });
      });
    });
  },
  components: {
    PrimaryButton,
    ConfirmationModal,
    Icon,
    Select2,
  },
  props: {
    errors: {
      type: Object,
      optional: true,
      default: null,
    },
    withSave: {
      type: Boolean,
      optional: true,
      default: true,
    },
  },
  data() {
    return {
      myValue: "",
      xbodyIdUrl:process.env.VUE_APP_XBODY_URL,
      type: null,
      VATValidity: null,
      hasEUVat: false,
      loading: false,
      localErrors: {},
      countries: [],
      billingData:
        this.$store.state.user && this.$store.state.user.billing_information
          ? {
              name: this.$store.state.user.billing_information.name,
              phone_number:
                this.$store.state.user.billing_information.phone_number,
              country_id:
                this.$store.state.user.billing_information.location.country.id,
              zip_code:
                this.$store.state.user.billing_information.location.zip_code,
              locality:
                this.$store.state.user.billing_information.location.locality,
              public_space:
                this.$store.state.user.billing_information.location
                  .public_space,
              public_space_type:
                this.$store.state.user.billing_information.location
                  .public_space_type,
              other: this.$store.state.user.billing_information.location.other,
              company_name:
                this.$store.state.user.billing_information.company_name,
              company_tax_id:
                this.$store.state.user.billing_information.company_tax_id,
              company_eu_tax_id:
                this.$store.state.user.billing_information.company_eu_tax_id,
              sap_code: this.$store.state.user.billing_information.sap_code,
              is_company: Number(
                  this.$store.state.user.billing_information.company_tax_id !==
                    null ||
                  this.$store.state.user.billing_information
                    .company_eu_tax_id !== null
              ),
            }
          : { country_id: 141, is_company: 0 },
    };
  },
  computed: {
    billingErrors() {
      return this.errors ?? this.localErrors;
    },
  },
  methods: {
    // setBillingData() {
    //   this.loading = true;
    //   if (!this.billingData.is_company) {
    //     this.billingData.has_eu_vat = false;
    //     this.billingData.company_name = null;
    //     this.billingData.company_tax_id = null;
    //     this.billingData.company_eu_tax_id = null;
    //     this.axios
    //       .post(
    //         "/billing-information",
    //         this.$store.getters.removeEmptyOrNull(this.billingData)
    //       )
    //       .then((resp) => {
    //         this.$store.state.user = resp.data.user;
    //         this.billingData.sap_code =
    //           resp.data.user?.billing_information?.sap_code;
    //       })
    //       .catch(
    //         (e) =>
    //           (this.localErrors = e.response.data.errors
    //             ? e.response.data.errors
    //             : Object())
    //       )
    //       .finally(() => (this.loading = false));
    //   } else {
    //     //checking if country is Hungary(HU)
    //     this.billingData.has_eu_vat = this.hasEUVat;
    //     if (this.hasEUVat) {
    //       this.billingData.company_tax_id = null;
    //       this.axios
    //         .post(
    //           "/check-vat-number",
    //           this.$store.getters.removeEmptyOrNull(this.billingData)
    //         )
    //         .then((resp) => {
    //           this.VATValidity = resp.data;
    //           this.axios
    //             .post(
    //               "/billing-information",
    //               this.$store.getters.removeEmptyOrNull(this.billingData)
    //             )
    //             .then((resp) => {
    //               this.$store.state.user = resp.data.user;
    //               this.billingData.sap_code =
    //                 resp.data.user?.billing_information?.sap_code;
    //             })
    //             .catch(
    //               (e) =>
    //                 (this.localErrors = e.response.data.errors
    //                   ? e.response.data.errors
    //                   : Object())
    //             )
    //             .finally(() => (this.loading = false));
    //         })
    //         .catch((e) => (this.localErrors = e.response.data.errors))
    //         .finally(() => {
    //           this.loading = false;
    //         });
    //     } else {
    //       this.billingData.company_eu_tax_id = null;
    //       this.axios
    //         .post(
    //           "/billing-information",
    //           this.$store.getters.removeEmptyOrNull(this.billingData)
    //         )
    //         .then((resp) => {
    //           this.$store.state.user = resp.data.user;
    //           this.billingData.sap_code =
    //             resp.data.user?.billing_information?.sap_code;
    //         })
    //         .catch(
    //           (e) =>
    //             (this.localErrors = e.response.data.errors
    //               ? e.response.data.errors
    //               : Object())
    //         )
    //         .finally(() => (this.loading = false));
    //     }
    //   }
    // },
    hasEUConsent(val) {
      this.hasEUVat = val;
    },
  },
  watch: {
    "billingData.country_id": function (val) {
      console.log("country changed", val);
      //check if country is eu using country id in countries
      let isEU = this.countries.find((c) => c.id == val && c.eu === 1);
      //toggle has EU VAT modal using ref
      console.log(isEU);
      if (isEU && this.billingData.is_company && val != 141) {
       // $("#hasEUVatModal2").modal("show");
       this.hasEUVat = true;
      } else {
        this.hasEUVat = false;
      }
    },
    "billingData.is_company": function (val) {
      //check if country is eu using country id in countries
      console.log("is company", val);
      let isEU = this.countries.find(
        (c) => c.id === this.billingData.country_id && c.eu == 1
      );
      //toggle has EU VAT modal using ref
      console.log(isEU);
      if (isEU && val && this.billingData.country_id != 141) {
     // $("#hasEUVatModal2").modal("show");
     this.hasEUVat = true;
      } else {
        this.hasEUVat = false;
      }
    },
  },
};
</script>

<style scoped>
#billing-data {
  padding: 50px 25px;
}

h1 {
  font-size: 20px;
  font-weight: 500;
  color: #333e48;
}

a.red-button {
  background-color: #d62b33;
  border-radius: 5px;
  border: 3px solid #d62b33;
  color: white;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  position: absolute;
  width: 153px;
  justify-content: center;
  height: 38px;
  gap: 10px;
}

/* START BUTTONS */

button.red-button {
  background-color: #d62b33;
  border-radius: 5px;
  border: 3px solid #d62b33;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  position: absolute;
  width: 153px;
  justify-content: center;
  height: 38px;
  gap: 10px;
}

button.red-button span {
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  color: #ffffff;
}

::v-deep button.red-button .icon svg path {
  fill: #fff !important;
}

button.red-button:hover {
  background-color: var(--dark-red);
  border: 3px solid var(--dark-red);
}

button.secondary-button {
  background-color: transparent;
  border-radius: 5px;
  border: 1.5px solid #d62b33;
  display: flex;
  padding: 5px 20px;
  width: 153.36px;
  height: 38px;
}

button.secondary-button span {
  color: #d62b33;
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
}

::v-deep button.secondary-button .icon svg path {
  fill: #d62b33 !important;
}

button.secondary-button:hover {
  background-color: #d62b33;
  border: 1.5px solid #d62b33;
}

button.secondary-button:hover span {
  color: #fff;
}

::v-deep button.secondary-button:hover .icon svg path {
  fill: #fff !important;
}

/* END BUTTONS */

/* START FORM */

form .form-group label {
  color: #333e48;
  font-size: 14px;
  font-weight: 500;
}

form .form-group .form-control {
  border: 1px solid #928c88;
  border-radius: 5px;
}

.form-select {
  display: block;
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #928c88;
}

.row .red-button {
  width: fit-content;
}
</style>
