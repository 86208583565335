<template>
    <div class="row" v-if="!loading">
        <div v-if="!safeMode" class="col-12 col-xl-4 mb-3">
            <label>{{$t('time-chart.unit')}}</label>
            <select v-model="selectedTimeUnit" class="form-control">
                <option v-for="(unit, index) in timeUnitList" :key="index" :value="unit">{{$t('time-unit.'+unit)}}</option>
            </select>
        </div>
        <div :class="safeMode ? 'col-6 mb-2' : 'col-12 col-xl-4 mb-2'">
            <label>{{$t('time-chart.from')}}</label>
            <datetime v-if="dateTime" type="datetime" class="form-control" :max-datetime="till" :deleteEnabled="false" v-model="from" />
            <datetime v-else :valueZone="DateTime.local().zoneName" :max-datetime="till" v-model="from" :deleteEnabled="false" class="form-control" />
        </div>
        <div :class="safeMode ? 'col-6 mb-2' : 'col-12 col-xl-4 mb-2'">
            <label>{{$t('time-chart.till')}}</label>
            <datetime v-if="dateTime" type="datetime" class="form-control" :min-datetime="from" :deleteEnabled="false" v-model="till" />
            <datetime v-else :valueZone="DateTime.local().zoneName" v-model="till" :min-datetime="from" :deleteEnabled="false" class="form-control" />
        </div>
        <slot v-if="data.length > 0" :data="datasets"></slot>
    </div>
    <Loading v-else height="100%" />
</template>
<script>
import Loading from '@/components/Loading.vue'
import Datetime from '../DateTimePicker/Datetime.vue'
import {DateTime, Duration} from 'luxon'
export default {
    components: { 
        Datetime,
        Loading
    },
    name: 'TimeChart',
    props: {
        data: Array,
        defaultTimeUnit: {
            type: String,
            default: 'month',
            required: false
        },
        safeMode: {
            required: false,
            type: Boolean,
            default: false
        },
        dateTime: {
            required: false,
            type: Boolean,
            default: true
        },
        defaultFrom: {
            default: DateTime.local().startOf('year').toISO(),
            required: false
        },
        defaultTill: {
            default: DateTime.local().toISO(),
            required: false
        },
        disabledUnits: {
            default: Array(),
            required: false
        }
    },
    mounted() {
        this.loading = false
    },
    data() {
        return {
            loading: true,
            selectedTimeUnit: this.defaultTimeUnit,
            from: this.defaultFrom,
            till: this.defaultTill,
            DateTime: DateTime
        }
    },
    computed: {
        computedSelectedTimeUnit() {
            return this.safeMode ? this.getSafeUnit() : this.selectedTimeUnit
        },
        timeUnitList() {
            return [
                'minute',
                'hour',
                'day',
                'week',
                'month',
                'quarter',
                'year'
            ].filter(e => !this.disabledUnits.some(d => e == d))
        },
        formatString() {
            switch(this.computedSelectedTimeUnit) {
                case 'minute': return 'yyyy. MMM dd. HH:mm'
                case 'hour': return 'yyyy. MMM dd. HH:mm'
                case 'day': return 'yyyy. MMM dd.'
                case 'week': return 'yyyy-WW'
                case 'month': return 'yyyy. MMM'
                case 'quarter': return 'yyyy-Qq'
                case 'year': return 'yyyy'
            }
        },
        dates() {
            let dates = []
            let startDate = DateTime.fromISO(this.from, {locale: this.$store.state.locale}).toLocal()
            const endDate = DateTime.fromISO(this.till, {locale: this.$store.state.locale}).toLocal()
            while(startDate <= endDate) {
                dates.push({
                    x: startDate.toFormat(this.formatString, {locale: this.$store.state.locale}),
                    y: 0
                })
                startDate = this.addUnitToDate(startDate)
            }
            return dates
        },
        datasets() {
            let datasets = []
            this.data.forEach(dataset => {
                let dates = JSON.parse(JSON.stringify(this.dates))
                dataset.forEach(entry => {
                    const t = DateTime.fromISO(entry).toFormat(this.formatString)
                    const idx = dates.findIndex(d => d.x == t)
                    if(idx != -1)
                        dates[idx].y++
                })
                datasets.push(dates)
            })
            return datasets
        }
    },
    methods: {
        addUnitToDate(date) {
            switch(this.computedSelectedTimeUnit) {
                case 'minute': return date.plus({minutes: 1})
                case 'hour': return date.plus({hours: 1})
                case 'day': return date.plus({days: 1})
                case 'week': return date.plus({weeks: 1})
                case 'month': return date.plus({months: 1})
                case 'quarter': return date.plus({quarters: 1})
                case 'year': return date.plus({years: 1})
            }
        },
        getSafeUnit() {
            const duration = Duration.fromMillis(new Date(this.till) - new Date(this.from))
            console.log(duration.as('months'))
            if(duration.as('days') <= 2) {
                return 'hour'
            }
            else if(duration.as('days') <= 31) {
                return 'day'
            }
            else if(duration.as('months') <= 6) {
                return 'week'
            }
            else if(duration.as('years') <= 2) {
                return 'month' 
            }
            else {
                return 'year'
            }
        }
    }
}
</script>