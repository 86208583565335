<template>
    <!-- Modal Edit Question-->
    <div class="modal fade" :id="'editQuestion'+question.id+'Modal'" tabindex="-1" role="dialog" :aria-labelledby="'editQuestion'+question.id+'ModalLabel'" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header question-modal-header" style="background-color: var(--red) !important;">
                <h5 class="modal-title text-white" :id="'editQuestion'+question.id+'ModalLabel'">{{ $t('test-question-assembler.edit-question') }}</h5>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <form @submit.prevent="editQuestion(question)">
                    <div class="modal-body nono">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="question-text">{{ $t('test-question-assembler.question') }}</label>
                                    <textarea required maxlength="2000" id="question-text" v-model="question.edit.question" class="form-control"  rows="3"></textarea>
                                </div>
                            </div>
                            <div class="col-12">
                                {{$t('test-question-assembler.multimedia')}}
                            </div>
                            <generic-file-uploader
                                v-if="!question.edit.multimedia_id"
                                @uploadFinished="attachMultimediaToQuestion(question, $event)"
                                containerClasses="col-12 row"
                                videoContainerClasses="col-6 mb-1 p-0 pr-1"
                                imageContainerClasses="col-6 mb-1 p-0 pr-1"
                            >
                                <template v-slot:video-btn>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex" style="flex-direction: column; align-items: center; justify-content: center;">
                                                <i class="fas fa-file-video font-28 mb-1" />
                                                <span>{{$t('test-question-assembler.video')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-slot:image-btn>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex" style="flex-direction: column; align-items: center; justify-content: center;">
                                                <i class="fas fa-file-image font-28 mb-1" />
                                                <span>{{$t('test-question-assembler.image')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </generic-file-uploader>
                            <div class="col-12 mb-3" v-else>
                                <div>
                                <div v-if="question.edit.multimedia.type == 'video' || question.edit.multimedia.type == 'image' " style="color: var(--black); cursor:pointer; width: 100%; height: 100%"
                                    :data-fancybox="`newQuestion`"
                                    :href="question.edit.multimedia.type != 'video' ? $store.getters.getStorageURL(question.edit.multimedia.path) : `#question-${question.edit.id}-${question.edit.multimedia.id}`" target="_blank"
                                    :data-caption="question.edit.multimedia.name"
                                >
                                    <div v-if="question.edit.multimedia.type == 'video'" style="max-width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: var(--lgray); border-radius: 5px; position: relative;">
                                        <video style="max-width: 100%; max-height: 100%; object-fit: cover; border-radius: 5px; pointer-events: none;" nocontrols>
                                            <source :src="$store.getters.getStorageURL(question.edit.multimedia.path)">
                                        </video>
                                        <video :id="`question-${question.edit.id}-${question.edit.multimedia.id}`" style="display: none;" controls controlsList="nodownload">
                                            <source :src="$store.getters.getStorageURL(question.edit.multimedia.path)">
                                        </video>
                                        <i class="far fa-play-circle playbtn"></i>
                                    </div>
                                    <div v-if="question.edit.multimedia.type == 'image'" style="max-width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: var(--lgray); border-radius: 5px;">
                                        <img :src="$store.getters.getStorageURL(question.edit.multimedia.path)" style="max-width: 100%; max-height: 100%; object-fit: cover; border-radius: 5px">
                                    </div>
                                </div>
                                <button class="w-100 btn btn-danger mt-2" @click.prevent="deleteQuestionMultimedia(question)">{{$t('test-question-assembler.delete-multimedia')}}</button>
                                </div>
                            </div>
                            <div class="col-12 marg-bottom">
                                <label for="">{{ $t('test-question-assembler.max-score') }}</label>
                                <input type="number" :disabled="finalized" v-model="question.edit.max_points" min="1" class="form-control" placeholder="" required>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="q-type">{{ $t('test-question-assembler.question-type') }}</label>
<!--                                    <select class="form-control" id="q-type"  v-model="question.edit.question_type" required :disabled="finalized">
                                        <option v-for="questionType in questionTypes" :key="questionType.type" :value="questionType.type">{{$t('question-type.'+questionType.type)}}</option>
                                    </select>-->
                                  <Select2 v-model="question.edit.question_type" :options="questionTypes" :settings="{width:'100%',required:true, disabled:finalized}" />
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="">{{$t('test-groups.group')}}</label>
<!--                                    <select class="form-control"  v-model="question.edit.test_group_id" :required="test.test_groups.length > 0">
                                        <option :value="null" disabled>{{test.test_groups.length > 0 ? $t('test-groups.choose') : $t('test-groups.none')}}</option>
                                        <option v-for="group in test.test_groups" :key="group.id" :value="group.id">{{group.name}}</option>
                                    </select>-->
                                  <Select2 v-model="question.edit.test_group_id" :disabled="test?.courses.some(c => c.finalized)" :options="test.test_groups.map((tg)=>({id:tg.id, text:tg.name}))" :settings="{width:'100%', placeholder: test.test_groups.length > 0 ? $t('test-groups.choose') : $t('test-groups.none'), required:(test.test_groups.length > 0), allowClear:true}" />
                                    <small v-show="test?.courses.some(c => c.finalized)" id="test-group-help" class="form-text text-muted">This option is disabled, since it belongs to finalized course</small>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="exampleFormControlTextarea1">{{ $t('test-question-assembler.comment') }}</label>
                                    <textarea class="form-control" v-model="question.edit.note"  rows="3"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-secondary w-100" :disabled="question.loading">{{ $t('test-question-assembler.save') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import GenericFileUploader from '../GenericFileUploader.vue'
import Select2 from "vue3-select2-component";
export default {
  components: { GenericFileUploader, Select2 },
    name: 'EditQuestionModal',
    props: {
        question: Object,
        test: Object,
        questionTypes: Object
    },
    methods: {
        editQuestion(question) {
            question.loading = true
            this.axios.patch('/tests/'+this.test.id+'/questions/'+question.id, this.$store.getters.removeEmptyOrNull(question.edit, ['multimedia_id', 'test_group_id']))
                .then(resp => {
                    resp.data.question.test_question_answers.forEach(qa => {
                        qa.edit = JSON.parse(JSON.stringify(qa))
                    })
                    resp.data.question.sub_questions.forEach(sq => {
                        sq.edit = JSON.parse(JSON.stringify(sq))
                    })
                    resp.data.question.edit = JSON.parse(JSON.stringify(resp.data.question))
                    resp.data.question.newAnswer = {answer: null, correct: question.questionType == 'Free text'}
                    resp.data.question.newSubQuestion = {
                        question: null,
                        multimedia_id: null,
                        correct_answer_id: null
                    }
                    this.test.test_questions[this.test.test_questions.findIndex((q) => q.id == question.id)] = resp.data.question
                    $('#editQuestion'+question.id+'Modal').modal('hide')
                })
                .finally(() => question.loading = false)
        },
        attachMultimediaToQuestion(question, file) {
            const multimedia = {
                id: file.uuid,
                type: file.type,
                name: file.file.name,
                path: `/download/${file.type}/${file.uuid}`
            }
            question = this.test.test_questions.find(q => q.id == question.id)
            question.edit.multimedia = multimedia
            question.edit.multimedia_id = multimedia.id
        },
        deleteQuestionMultimedia(question) {
            question = this.test.test_questions.find(q => q.id == question.id)
            question.edit.multimedia_id = null
            question.edit.multimedia = null
        },
    },
  mounted() {
    console.log(this.question);
    console.log(this.questionTypes);
  }
}
</script>
