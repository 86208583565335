<template>
    <canvas id="osChart"></canvas>
</template>
<script>
import UAParser from 'ua-parser-js'
import randomColor from 'randomcolor'
export default {
    name: 'OsChart',
    props: {
        data: 
        {
           default: ['60000', '60000', '40000']
        }
    },
    mounted() {
        const ctx = $('#osChart')
        this.processData()
        this.chart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: Object.keys(this.processedData),
                datasets: [
                    {
                        data: Object.values(this.processedData),
                        backgroundColor: randomColor({luminosity: 'light', count: Object.keys(this.processedData).length, format: 'rgb'})
          
                    }
                ]
            },
            options: {
                legend: {
                    display: false,
                    labels: {
                        fontColor: 'rgb(255, 99, 132)'
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1
                        }
                    }]
                }
            }
        });
    },
    data() {
        return {
            processedData: {}
        }
    },
    methods: {
        processData() {
            let parser = new UAParser()
            this.data.forEach(d => {
                const os = parser.setUA(d).getResult().os.name
                this.processedData[os] = (this.processedData[os] ?? 0) + 1
            })
        }
    }
}
</script>