<template>
    <canvas :id="'matrixChart'+id"></canvas>
</template>
<script>
export default {
    name: 'MatrixChart',
    props: {
        datasets: Array,
        id: Number,
        labels: Object,
    },
    mounted() {
        console.log(this.datasets)
        const colors = ['#ff2b00', '#ffaa00', '#80ff00', '#28a745']
        let datasetsArr = []
        this.datasets.forEach((d, i) => {
            datasetsArr.push({
                label: i+1,
                data: d,
                backgroundColor: colors[i]
            })
        })
        const ctx = $('#matrixChart'+this.id)
        this.chart = new Chart(ctx, {
            type: 'horizontalBar',
            data: {
                labels: this.labels,
                datasets: datasetsArr
            },
            options: {
                legend: {
                    display: true,
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1
                        },
                        stacked: true
                    }],
                    xAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1
                        },
                    }],
                }
            }
        });
    },
    data() {
        return {
            chart: null
        }
    }
}
</script>