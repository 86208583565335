<template>
  <div class="mb-0">
    <div class="uploaded-item mb-3">
      <img v-if="!loading && !converting && finished && !withoutDelete" @click="cancelUpload" src="assets/img/icons/remove.svg"
           class="remove" alt="">
      <div class="flex-wrap d-flex">
        <div class="d-flex align-items-center pointer ml-auto">
          <div class="d-flex flex-column ml-auto mr-2">
            <h6 v-if="converting" class="text-16 text-dark-gray">({{$t('disabled.converting')}})</h6>
            <h6 class="text-16 text-dark-gray">{{ file.name }}</h6>
          </div>

          <div class="new-button-50 d-flex align-items-center justify-content-center">
            <img v-if="progress==100 && !converting" :src="icons" class="img-fluid ml-1" alt="">
            <div v-else class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Upload',
  props: {
    file: Object,
    type: String,
    id: Number,
    withoutDelete: {type: Boolean, default: false}
  },
  mounted() {
    if (this.file) {
      console.log('Starting upload!')
      this.createChunks()
      this.upload()
    }
  },
  data() {
    return {
      file: this.file,
      chunks: [],
      uploaded: 0,
      done: null,
      uuid: null,
      loading: true,
      converting: false,
      finished: false,
      duration: null,
      icons: this.getMediaIcon(this.type)
    };
  },

  computed: {
    progress() {
      return this.file ? Math.floor((this.uploaded * 100) / this.file.size) : 0
    },
    cancelColor() {
      return this.loading ? 'grey' : 'red'
    }
  },

  methods: {
    async upload() {
      try {
        const start = Date.now();
        let formData = new FormData;
        console.log("file", this.file);
        let fileType = this.file.type;
        formData.set(this.type, this.chunks[0], this.file.name);
        let config = {
          method: 'POST',
          data: formData,
          url: `/upload/${this.type}`,
          baseURL: process.env.VUE_APP_STORAGE_URL,
          headers: {
            'Content-Type': 'application/octet-stream'
          },
        };

        let resp = await this.axios(config);
        this.loading = false;
        console.log(resp)
        this.uploaded += this.chunks[0].size;
        this.chunks.shift()
        config.url += '/' + resp.data.name
        this.uuid = resp.data.name
        for (let i = 0; i < this.chunks.length; i++) {
          if (this.loading) {
            return;
          }
          formData.set(this.type, this.chunks[i])
          resp = await this.axios(config)
          this.uploaded += this.chunks[i].size
          //console.log(resp)
          //console.log(`${i+1} / ${this.chunks.length}`)
        }
        config.url += '/finish';
        config.data = null;
        this.loading = true;
        if (this.type == 'audio' || this.type == 'video') {
          const loadVideo = async file => new Promise(async (resolve, reject) => {
            try {
              let video = document.createElement('video')
              video.preload = 'metadata'
              console.log("fileType", fileType);
              //TODO: start conversion if file is video/avi
              if (fileType === "video/avi") {
                console.log("start conversion", this.uuid);
                this.converting = true;
                await this.convertVideo(this.uuid);
                this.converting = false;
                video.onloadedmetadata = function () {
                  resolve(this)
                }
                video.onerror = function () {
                  reject("Invalid video. Please select a video file.")
                }
                //console.log(process.env.VUE_APP_STORAGE_URL+"/download/video/"+this.uuid);
                console.log(this.$store.getters.getStorageURL("/download/video/"+this.uuid));
                video.src = this.$store.getters.getStorageURL("/download/video/"+this.uuid);
              }else {
              video.onloadedmetadata = function () {
                resolve(this)
              }
              video.onerror = function () {
                reject("Invalid video. Please select a video file.")
              }
              video.src = window.URL.createObjectURL(file);
              }
            } catch (e) {
              reject(e)
            }
          })
          const video = await loadVideo(this.file)
          if (video) this.duration = video.duration
        }
        resp = await this.axios(config)
        this.loading = false
        this.finished = true
        this.$emit('uploadFinished', this.id, resp.data.multimedia, this.duration)
        const finish = Date.now();
        console.log((this.file.size * 0.000001) / (new Date(finish - start).getTime() / 1000));
        console.log(resp);
      } catch (e) {
        this.file = null;
        this.chunks = [];
        this.uploaded = 0;
        this.$emit('uploadCancelled', this.id, this.uuid)
        console.log(e)
      }
    },
    createChunks() {
      let size = 2000000, chunks = Math.ceil(this.file.size / size);

      for (let i = 0; i < chunks; i++) {
        this.chunks.push(this.file.slice(
            i * size, Math.min(i * size + size, this.file.size), this.file.type
        ));
      }
    },
    async cancelUpload() {
      if (!this.loading) {
        this.loading = true;
        let config = {
          method: 'POST',
          data: null,
          url: `/upload/${this.type}/${this.uuid}/abort`,
          baseURL: process.env.VUE_APP_STORAGE_URL,
          headers: {
            'Content-Type': 'application/octet-stream'
          },
        };
        await this.axios(config);
        this.$emit('uploadCancelled', this.id, this.uuid)
      }
    },
    getMediaIcon() {
      switch (this.type) {
        case 'video':
          return 'assets/img/icons/videocam.svg'
        case 'audio':
          return 'assets/img/icons/microphone.svg'
        case 'document':
          return 'assets/img/icons/file.svg'
        case 'image':
          return 'assets/img/icons/white-camera.svg'
        default:
          return null
      }
    },
    async convertVideo(uuid) {
      let config = {
        method: 'POST',
        data: null,
        url: `/convert/video/${uuid}`,
        baseURL: process.env.VUE_APP_STORAGE_URL,
      };
      //this.axios(config);
      let resp = await this.axios(config);
    },
  }

}
</script>
<style scoped>
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 0px;
  margin-left: -50px;
  height: 40px;
}

.lds-ellipsis div {
  position: absolute;
  top: 16px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 32px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


</style>
