<template>
  <div
    class="modal fade"
    id="newCouponModal"
    tabindex="-1"
    role="dialog"
    ref="newCouponModal"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("coupon-modal.new") }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="submit" id="newCouponForm">
        <div class="modal-body row">
            <div class="col-12">
              <label class="d-flex">
                <div class="mr-2">{{ $t("checkout-summary.coupon-code") }}</div>
                <div
                  class="p-1 d-flex align-items-center justify-content-center"
                  style="height: 23px; border-radius: 7px;font-size: 12px; background-color: var(--black); cursor: pointer"
                  @click="generateNewCode"
                  :style="
                    generating
                      ? 'cursor: not-allowed;background-color: var(--secondary);'
                      : ''
                  "
                >
                  <i class="fas fa-sync-alt text-white"></i>
                </div>
              </label>
              <input
                type="text"
                class="form-control"
                required
                v-model="coupon.code"
              />
            </div>
            <div class="col-12 mt-3">
              <label
                >{{ $t("coupon-modal.disc") }}
                {{ coupon.percentage_based ? "(%)" : "(EUR)" }}</label
              >
              <input
                type="number"
                min="1"
                class="form-control w-90"
                required
                v-model="coupon.discount"
              />
            </div>
            <div class="col-12 mt-3">
              <label>{{ $t("coupon-modal.disc-type") }}</label>
              <select class="form-control" v-model="coupon.percentage_based">
                <option :value="true">{{
                  $t("coupon-modal.percentage")
                }}</option>
                <option :value="false">{{ $t("coupon-modal.cash") }}</option>
              </select>
            </div>
            <div class="col-12 mt-3">
              <label>{{ $t("coupon-modal.usage-type") }}</label>
              <Select2
                required
                v-model="coupon.usage_type"
                class="w-100"
                :options="typesOptions"
                :placeholder="$t('common.select-from-list')"
                :settings="{
                  sortResults: typesOptions.sort((a, b) =>
                    a.text.localeCompare(b.text)
                  ),
                }"
              />
            </div>
            <div v-if="$store.state.user.role != 'Master'" class="col-12 mt-3">
              <label>{{ $t("manage-coupons.membership_course") }}</label>
              <select v-model="coupon.membership" class="form-control">
                <option :value="0">{{$t("manage-coupons.course")}}</option>
                <option :value="1">{{$t("manage-coupons.membership")}}</option>
              </select>
            </div>
            <div v-else class="col-12 mt-3" style="display:none">
              <select v-model="coupon.membership" class="form-control">
                <option :value="0" selected>{{$t("manage-coupons.course")}}</option>
              </select>
            </div>
            <div v-show="!coupon.membership" class="col-12 mt-3">
              <label>{{ $t("coupon-modal.course") }}</label>
              <Select2
                v-model="coupon.course_id"
                class="w-100"
                :options="coursesOptions"
                :placeholder="$t('coupon-modal.all-courses')"
                :settings="{
                  sortResults: coursesOptions.sort((a, b) =>
                    a.text.localeCompare(b.text)
                  ),
                }"
              />
            </div>
            <div class="col-12 mt-3">
              <label>{{ $t("coupon-modal.validity") }}</label>
              <datetime
                type="datetime"
                :placeholder="$t('coupon-modal.unlimited')"
                v-model="coupon.valid_until"
                class="form-control"
              />
            </div>
        </div>
        <div class="modal-footer">
          <PrimaryButton :disabled="submitting || coupon.discount < 0 || !String(coupon.code).trim()" :text="$t('coupon-modal.create')" type="submit"/>
          <TransparentButton :text="$t('common.cancel')" data-dismiss="modal"/>
        </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Datetime from "@/components/DateTimePicker/Datetime";
import Select2 from "vue3-select2-component";
import membership from "./settings/Membership.vue";
import PrimaryButton from "./settings/PrimaryButton.vue";
import TransparentButton from "./settings/TransparentButton.vue";

export default {
  name: "CreateCouponModal",
  computed: {
    membership() {
      return membership
    }
  },
  emits: ["couponCreated"],
  components: {
    TransparentButton,
    PrimaryButton,
    Datetime,
    Select2,
  },
  props: {
    courses: Array,
    usageTypes: Array,
  },
  watch: {
    courses() {
      if (this.courses.length) {
        for (let course of this.courses) {
          this.coursesOptions.push({
            id: course.id,
            text: course.title,
          });
        }
      }
    },
    usageTypes() {
      if (this.usageTypes.length) {
        for (let type of this.usageTypes) {
          this.typesOptions.push({
            id: type.type,
            text: type.type,
          });
        }
      }
    },
  },
  created() {
    this.generating = true;
    this.axios
      .get("/coupons/generate-code")
      .then((resp) => {
        if (this.coupon.code === null) this.coupon.code = resp.data.code;
      })
      .catch((e) => console.log(e))
      .finally(() => (this.generating = false));
  },
  data() {
    return {
      coupon: {
        code: null,
        discount: 0,
        percentage_based: false,
        membership:0,
        course_id: null,
        usage_type: null,
        valid_until: null,
      },
      submitting: false,
      generating: false,
      coursesOptions: [
          { id: null, text: this.$t("coupon-modal.all-courses") },
      ],
      typesOptions: [{ id: null, text: this.$t("common.select-from-list") }],
    };
  },
  methods: {
    generateNewCode() {
      this.generating = true;
      this.axios
        .get("/coupons/generate-code")
        .then((resp) => {
          this.coupon.code = resp.data.code;
        })
        .catch((e) => console.log(e))
        .finally(() => (this.generating = false));
    },
    submit() {
      this.submitting = true;
      console.log("create: ", this.coupon);
      this.axios
        .post("/coupons", this.coupon)
        .then((resp) => {
          this.$emit("couponCreated", resp.data.coupon);
          $(this.$refs.newCouponModal).modal("hide");
        })
        .catch((e) => console.log(e))
        .finally(() => (this.submitting = false));
    },
  },
};
</script>

<style scoped>
::v-deep .select2-container {
  width: inherit !important;
}

::v-deep
  .select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: #495057;
  font-size: 1rem;
  font-weight: 400;
}

::v-deep
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 39px;
  padding-left: 16px;
}
</style>
