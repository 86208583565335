<template>
        <div class="modal fade" :id="id" :ref="id" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{title}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <span v-if="item">{{text}} <span :style="boldItem ? 'font-weight: bold' : ''">{{item}}</span>?</span>
                    <span v-else>{{text}}</span>
                </div>
                <div class="modal-footer">
                  <PrimaryButton data-dismiss="modal" :text="$t('common.cancel')"/>
                  <TransparentButton :disabled="deleting" :click="submit" :text="$t('common.delete')"/>
                </div>
                </div>
            </div>
        </div>
</template>
<script>
import PrimaryButton from "./settings/PrimaryButton.vue";
import TransparentButton from "./settings/TransparentButton.vue";

export default {
    name: 'GenericDeleteModal',
  components: {TransparentButton, PrimaryButton},
    emits: ['itemDeleted'],
    props: {
        id: String,
        itemDeletedReturnValue: {
            required: false,
            default: null,
        },
        text: {
            required: false
        },
        url: {
            required: false,
            default: null,
            type: String,
        },
        title: String,
        item: {
            required: false,
            type: String
        },
        boldItem: {
            default: false
        }
    },
    data() {
        return {
            deleting: false
        }
    },
    methods: {
        submit() {
            if(this.url) {
                this.deleting = true
                this.axios.delete(this.url)
                    .then(resp => {
                        this.$emit('itemDeleted', this.itemDeletedReturnValue ?? this.id)
                        $(this.$refs[this.id]).modal('hide')
                    })
                    .catch(e => console.log(e))
                    .finally(() => this.deleting = false)
            }
            else {
                this.$emit('itemDeleted', this.itemDeletedReturnValue ?? this.id)
                $(this.$refs[this.id]).modal('hide')
            }
        }
    }
}
</script>
