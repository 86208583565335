<template>
    <canvas id="appTimeTracking"></canvas>
</template>
<script>
import humanizeDuration from 'humanize-duration'
export default {
    name: 'AppTimeTrackingChart',
    props: {
        data: Array,
        isBar: {
            default: false 
        }
    },
    mounted() {
        const ctx = $('#appTimeTracking')
        this.chart = new Chart(ctx, {
            type: 'bar',
                data: 
                {   
                    labels: this.data[0].map(d => d.x),
                    datasets: 
                    [   
                        
                        { 
                            data: this.data[0].map(d => d.y),
                            borderWidth: 2,
                            backgroundColor: 'rgb(23, 111, 183, 0.8)',
                            borderColor: 'rgb(23, 111, 183, 1)',
                            label: 'Alkalmazásban töltött idő',
                        },
                    ]
                },
                options:
                {
                    legend: {
                        display: false,
                    },
                    scales: 
                    {
                        xAxes: 
                        [{
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            label: (tooltipItem, data) => {
                                return humanizeDuration(tooltipItem.value*60*1000, {language: this.$store.state.locale})
                            }
                        }
                    }
                }  
        });
    },
    data() {
        return {
            chart: null,
            processedData: {}
        }
    },
    watch: {
        data() {
            if(this.chart) {
                this.chart.data.labels = this.data[0].map(d => d.x)
                this.chart.data.datasets[0].data = this.data[0].map(d => d.y)
                this.chart.update()
            }
        }
    },
}
</script>