<template>
    <div class="d-flex align-items-center">
        <button type="button" :disabled="contractLoading" class="btn btn-outline-primary mb-2" @click.prevent="downloadContract">
            <div class="d-flex align-items-center">
                <i class="fas fa-file-signature"></i>
                <span class="ml-1">{{ $t('far-download.title') }}</span>
            </div>
        </button>
        <div v-if="contractLoading" class="spinner-border text-primary ml-2 mb-2" role="status">
            <span class="sr-only">{{ $t('common.loading') }}</span>
        </div>
    </div>
</template>
<script>
import { Capacitor } from '@capacitor/core';
export default {
    name: 'FarContractDownload',
    props: {
        user: {
            type: Number,
            optional: true,
            default: null
        },
        course: Number
    },
    data() {
        return {
            contractLoading: false
        }
    },
    methods: {
        downloadContract() {
            let params = {}
            if(this.user) params.user = this.user
            this.contractLoading = true;
            this.axios.get('/far-contracts/'+this.course+'/download', {responseType: 'blob', params})
                .then(resp => {
                    const name = `${this.course}_${this.user ?? this.$store.state.user.id}.pdf`
                    if(Capacitor.isNative) {
                        this.$store.commit('downloadFileMobile', {name, mime: 'application/pdf', data: resp.data})
                    }
                    else {
                        const url = window.URL.createObjectURL(new Blob([resp.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download',name);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
                .catch(e => console.log(e.response))
                .finally(() => this.contractLoading = false)
        }
    }
}
</script>
