<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('my-courses.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
              </div>
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'CourseGroupList'})">{{ $t('course-groups.course-groups') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('course-groups.create-group') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div id="content-2">
      <div class="container">
        <form @submit.prevent="submit">
          <div class="row">
            <div class="col-lg-6">
              <section id="media-input" class="custom-input">
                <div class="d-flex flex-wrap mb-2">
                  <dib class="d-flex mr-auto">
                    <h3 class="text-18 text-light-black medium">{{ $t('create-course.cover-img') }}</h3>
                  </dib>
                  <dib class="d-flex" v-if="coverImage">
                    <img @click.stop="removeCoverImage" :disabled="coverImageUploading"
                         src="assets/img/icons/trash-red.svg" class="pointer" alt="">
                  </dib>
                </div>
                <div v-if="!coverImage && !coverImageUploading" @click="$refs.coverImageUpload.click()"
                     class="cover-upload filter-shadow mb-3 d-flex align-items-center justify-content-center pointer">
                  <img src="assets/img/icons/add-photo.svg" class="upload-photo" alt="">
                </div>
                <div v-else-if="coverImage && !coverImageUploading" @click="$refs.coverImageUpload.click()"
                     class="cover-upload filter-shadow"
                     :style="{backgroundImage: `url(${$store.state.storageURL+coverImage.path}?token=${$store.state.token})`}"></div>
                <div v-else class="col-12 d-flex justify-content-center align-items-center"
                     style="border: 2px solid #e0e0e0; height: 300px;">
                  <Loading height="100%"/>
                </div>
                <ImageCropper v-if="uploadedAvatarURL" @urlChanged="createUpload" :type="uploadType"
                              :src="uploadedAvatarURL" :aspectRatio="null"/>
                <input ref="coverImageUpload" type="file" accept="image/jpeg, image/jpg, image/png" @change="readURL"
                       style="display: none;">
              </section>
            </div>
            <div class="col-lg-6">
              <section id="text-input" class="custom-input">
                <div class="form-group mb-3">
                  <label for="title" class="text-16 text-dark-warm-gray">{{ $t('create-course.name') }}</label>
                  <div v-if="errors['title']">
                    <span class="text-13 semi-bold" v-for="(error, idx) in errors['title']" style="color: red;"
                          :key="idx">{{ error }}</span>
                  </div>
                  <input @input="errors['title'] = null" type="text" id="title" v-model="data.title"
                         class="form-control" :style="{border: errors['title'] ? '1px solid red' : '1px solid #ced4da'}"
                         required>
                </div>
                <div class="form-group mb-3">
                  <label for="parent" class="text-16 text-dark-warm-gray">{{ $t('course-groups.parent-group') }}</label>
                  <div v-if="errors['parent']">
                    <span class="text-13 semi-bold" v-for="(error, idx) in errors['parent']" style="color: red;"
                          :key="idx">{{ error }}</span>
                  </div>
<!--                  <select @input="errors['parent'] = null" type="text" v-model="data.parent_id" id="parent"
                          class="form-control" :style="{border: errors['parent'] ? '1px solid red' : '1px solid #ced4da'}">
                    <option value="">{{ $t('course-groups.no-parent') }}</option>
                    <option v-for="(group, idx) in courseGroups" :key="idx" :value="group.id">{{ group.title }}</option>
                  </select>-->
                  <Select2 v-model="data.parent_id" :options="courseGroups" :settings="{width:'100%'}" />
                </div>
                <div class="space-helper"></div>
                <button type="submit" :disabled="loading" class="btn btn-theme float-right mt-3 mb-3 ">
                  {{ $t('create-course.create-course') }}
                </button>
              </section>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
</template>
<script>
import Loading from '@/components/Loading'
import ImageCropper from '@/components/ImageCropper'
import Select2 from "vue3-select2-component";

export default {
  name: 'CreateCourse',
  components: {
    Loading,
    ImageCropper,
    Select2
  },
  mounted() {
    this.loading = true
    this.axios.get('/course-groups')
        .then(resp => {
          this.courseGroups = resp.data.course_groups.map(group => {
            return {
              id: group.id,
              text: group.title,
            }
          });
        })
        .finally(() => this.loading = false)

  },
  data() {
    return {
      coverImageUploading: false,
      coverImage: null,
      uploadedAvatarURL: null,
      uploadType: null,
      loading: false,
      courseGroups: [],
      data: {
        image_id: null,
        title: null,
        parent_id: null,
      },
      errors: Object(),
    }
  },
  methods: {
    createUpload(url) {
      if (url) {
        let file = this.dataURLtoFile(url)
        let formData = new FormData;
        formData.set('image', file, file.name);
        this.coverImageUploading = true
        console.log(formData)
        let config = {
          method: 'POST',
          data: formData,
          url: `/upload/image`,
          baseURL: process.env.VUE_APP_STORAGE_URL,
          headers: {
            'Content-Type': 'application/octet-stream'
          },
        };
        this.axios(config)
            .then(resp => {
              config.url += '/' + resp.data.name
              config.url += '/finish';
              config.data = null;
              this.axios(config)
                  .then(resp => {
                    this.coverImage = resp.data.multimedia;
                  })
                  .finally(() => this.coverImageUploading = false)
            })
            .catch(() => this.coverImageUploading = false)
      }

    },
    readURL(input) {
      input = input.target
      if (input.files && input.files[0]) {
        this.uploadType = input.files[0].type
        var reader = new FileReader()
        reader.onload = (e) => {
          this.uploadedAvatarURL = e.target.result
        }

        reader.readAsDataURL(input.files[0]) // convert to base64 string
      }
    },
    dataURLtoFile(dataurl) {

      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], 'coverimg', {type: mime});
    },
    removeCoverImage() {
      this.axios.post(process.env.VUE_APP_STORAGE_URL + '/upload/image/' + this.coverImage.id + '/abort')
      this.coverImage = null
      this.$refs.coverImageUpload.value = null;
    },
    removeEmptyOrNull(obj) {
      Object.keys(obj).forEach(k => {
        if (obj[k] === null || obj[k] === '')
          delete obj[k]
      })
      return obj;
    },
    submit() {
      this.loading = true
      this.data.image_id = this.coverImage ? this.coverImage.id : null
      this.axios.post('/course-groups', this.removeEmptyOrNull(this.data))
          .then(() => {
            this.$router.push({name: 'CourseGroupList'})
          })
          .catch(e => this.errors = e.response.data.errors)
          .finally(() => this.loading = false)
    },
  }
}
</script>
<style scoped>
#text-input, #media-input {
  margin-top: 30px;
}

.space-helper {
  margin-top: 87px;
}
</style>
