<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('reporting.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Reporting'})">{{ $t('reporting.statistic') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('reporting.quiz') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div id="content-2">
      <div class="container">
        <!-- RESPONSIVE TABLE -->
        <div class="row">
          <div class="col-12 mt-5 mb-3">
            <h2 class="text-18 text-dark-gray semi-bold">{{ $t('common.courses') }}</h2>
          </div>
          <div v-if="!loading && courses" class="col-12 mb-4">
            <div class="new-table filter-shadow">
              <table class="table borderless-table">
                <thead class="bg-light-gray-primary">
                <tr>
                  <th :class="'pointer'+' '+(currentSort==='title'?'sorted':'')" @click="sort('title')">{{ $t('manage-courses.cname') }}</th>
                  <th :class="'pointer'+' '+(currentSort==='start_date'?'sorted':'')" @click="sort('start_date')">{{ $t('manage-courses.cbegin') }}</th>
                  <th :class="'pointer'+' '+(currentSort==='end_date'?'sorted':'')" @click="sort('end_date')">{{ $t('manage-courses.cend') }}</th>
                  <th :class="'pointer'+' '+(currentSort==='active'?'sorted':'')" @click="sort('active')">{{ $t('manage-courses.visibility') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="course in sortedCourses" :key="course.id"
                    @click.prevent="$router.push({name: 'CourseQuizReport', params: {course: course.id}})">
                  <td>
                    <a href="#">{{ course.title }}</a>
                  </td>
                  <td>
                    {{ course.start_date ? $store.getters.parseDateTime(course.start_date) : $t('manage-courses.no') }}
                  </td>
                  <td><span
                      class="left">{{ course.end_date ? $store.getters.parseDateTime(course.end_date) : $t('manage-courses.no') }}</span>
                  </td>
                  <td :style="{color: course.active ? 'green' : 'red'}">
                    {{ course.active ? $t('manage-courses.public') : $t('manage-courses.hidden') }}
                  </td>
                </tr>

                <tr v-if="courses.length == 0">
                  <td colspan="4" class="text-center text-light-black semi-bold pt-4 pb-4">There are no quizzes to
                    display
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- //RESPONSIVE TABLE -->
      </div>
    </div>
  </div>
</template>
<script>
import Loading from '../components/Loading.vue'

export default {
  components: {
    Loading
  },
  name: 'CourseQuizReports',
  mounted() {
    this.axios.get('courses/admin')
        .then(resp => {
          this.courses = resp.data.courses
        })
        .finally(() => this.loading = false)
  },
  data() {
    return {
      loading: true,
      courses: [],
      currentSort: 'title',
      currentSortDir: 'asc',
    }
  },
  methods: {
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
  },
  computed: {
    sortedCourses: function () {
      return this.courses.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] === null && b[this.currentSort] === null) return 1 * modifier;
        if(a[this.currentSort] !== null && b[this.currentSort] === null) return -1 * modifier;
        if(a[this.currentSort] === null && b[this.currentSort] !== null) return 1 * modifier;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    }
  }

}
</script>
<style scoped>
.sorted{
  font-weight: normal;
}
</style>
