<template>
  <div>
<!-- generate code modal -->
    <div class="modal fade" id="newCodeModal" tabindex="-1" role="dialog" ref="newCodeModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("manage-codes.new") }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="generate" id="newCodeForm">
            <div class="modal-body row">
              <div class="col-12">
                <label>{{ $t("manage-codes.amount") }}</label>
                <input type="number" min="1" class="form-control w-90" required v-model="generateObj.amount" />
              </div>
              <div class="col-12 mt-3">
                <label>{{ $t("manage-codes.course") }}</label>
                <select class="form-control" v-model="generateObj.course_id">
                  <option v-for="course in courses" :key="course.id" :value="course.id">{{ course.title }}</option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <PrimaryButton :disabled="generating" :text="$t('manage-codes.create')" type="submit" />
              <TransparentButton :text="$t('common.cancel')" data-dismiss="modal" />
            </div>
          </form>


        </div>
      </div>
    </div>

    <!-- attach code modal -->
    <div class="modal fade" id="attachCodeModal" tabindex="-1" role="dialog" ref="attachCodeModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("manage-codes.attach") }} {{ attached.code }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="attach" id="attachCodeFrom">
            <div class="modal-body row">
              <div class="col-12 mt-3">
              <label>{{ $t("manage-codes.user") }}</label>
              <Select2
                required
                v-model="attached.user_id"
                class="w-100"
                :options="users"
                :placeholder="$t('common.select-from-list')"
              />
            </div>
            </div>
            <div class="modal-footer">
              <PrimaryButton :disabled="attaching" :text="$t('manage-codes.attach')" type="submit" />
              <TransparentButton :text="$t('common.cancel')" data-dismiss="modal" />
            </div>
          </form>


        </div>
      </div>
    </div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({ name: 'Home' })">{{
              $t('manage-coupons.homepage') }}</span>
              </div>
              <div class="d-flex mr-4 ">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                    alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('manage-codes.manage-codes') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div class="container-xxl p-4">
      <div class="row mt-4">
        <div class="col-md-4 border-right border-dark pr-2">
            <div class="d-flex align-items-center pointer mr-auto" data-toggle="modal" data-target="#newCodeModal">
              <div class="new-button-50 d-flex align-items-center justify-content-center">
                <img src="assets/img/icons/plus.svg" class="img-fluid" alt="">
              </div>
              <div class="d-flex flex-column ml-2">
                <h6 class="text-16 text-dark-gray">{{ $t('manage-codes.generate') }}</h6>
              </div>
            </div>
            <div style="max-height: 75vh;overflow:auto;padding:10px">
              <ul class="mt-4" v-for="code in openCodes" :key="code">
                <li>{{ code.course_title }}</li>
            <li  v-for="item in code.codes" :key="item" class="d-flex align-items-center justify-content-between mt-1 pb-2 border-bottom border-dark">
              <strong>{{ item.code }}</strong> <button class="btn btn-primary" data-toggle="modal" data-target="#attachCodeModal" @click="attachModal(item.course.id,item.id,item.code)">delegate</button></li>
          </ul>
            </div>
          
        </div>
        <div class="col-md-8">
          <div class="mb-1 row ">
            <div class="col-6">
              <span>Filter by Course : </span>
              <select class="border-0 p-2" v-model="courseSelected" >
                <option v-for="course in courses" :key="course" :value="course.id" >{{course.title}}</option>
              </select>
            </div>
            <div class="col-6">
              <custom-paginator :paginator="paginator" @loadPage="paginate"  />
            </div>
            
                                      
          </div>
          <div style="max-height: 75vh;overflow:auto" class="rounded position-relative">
            <table class="table " >
              <thead class="table-head bg-light-gray-primary text-center text-white rounded position-sticky" style="top:0px">
                  <th style="width: 25% !important;" class="pointer" @click="orderByFunction('user_name')">Name \ Email <span v-if="orderBy && orderBy=='user_name'"><span v-if="orderByDirection=='asc'">↑</span> <span v-else> ↓</span></span> </th>
                  <th style="width: 20% !important;" class="pointer" @click="orderByFunction('attached_date')">Code \ Attached Date <span v-if="orderBy && orderBy=='attached_date'"><span v-if="orderByDirection=='asc'">↑</span> <span v-else> ↓</span></span></th>
                  <th style="width: 10% !important;" class="pointer" @click="orderByFunction('course_title')">Course <span v-if="orderBy && orderBy=='course_title'"><span v-if="orderByDirection=='asc'">↑</span> <span v-else> ↓</span></span></th>
                  <th style="width: 10% !important;" class="pointer" @click="orderByFunction('status')">Status <span v-if="orderBy && orderBy=='status'"><span v-if="orderByDirection=='asc'">↑</span> <span v-else> ↓</span></span></th>
                  <th style="width: 10% !important;">Action</th>
              </thead>
              <tbody >
                <tr v-for="code in delegatedCodes" :key="code.id" class="text-center border-bottom-grey">
                  <td class="text-left">
                    <span class="text-14">{{ code.user.name }} <br> {{ code.user.email }}</span>

                  </td>
                  <td class="text-14"><span class="left">{{ code.code }} <br> {{ code.attached_date }}</span>
                  </td>
                  <td class="text-14">
                   <a :href="'/course/'+code.course.id" target="_blank">{{ code.course.title }}</a>  
                  </td>
                  <td class="text-14"><strong class="tag" :class="'tag-'+code.status">{{ code.status }}</strong>

                  </td>
                  <td class="text-14">
                    <button v-if="code.status=='not shared'" class="btn btn-primary" @click="share(code.id)">Share</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomPaginator from '../components/CustomPaginator.vue'
import Loading from '../components/Loading.vue'
import Select2 from "vue3-select2-component";
import PrimaryButton from "../components/settings/PrimaryButton.vue";
import TransparentButton from "../components/settings/TransparentButton.vue";
import store from '../store'
export default {
  components: { Loading, TransparentButton, PrimaryButton ,Select2,CustomPaginator},
  name: 'ManageCodes',

  data() {
    return {
      loading:false,
      attaching: false,
      courseSelected: null,
      attached: {
        code: null,
        user_id: null,
        code_id: null
      },
      delegatedCodes: [],
      users: [],
      openCodes: [],
      courses: [],
      generating: false,
      generateObj: {
        amount: 1,
        course_id: null
      },
      orderBy:null,
      orderByDirection:null,
      page:1,
      perPage: 10,
      paginator: {
        current_page:1,
        last_page:1
      },
    }
  },
  mounted() {
    this.fetchUsedCode();
    this.fetchOpenCodes();
    this.axios.get('/course_code/courses')
      .then(resp => {
        this.courses = resp.data.data
      })
  },
  watch: {
    courseSelected: function (newVal) {
      this.page = 1;
      this.fetchOpenCodes();
      this.fetchUsedCode();
    }
  },
  methods: {
    paginate(page, perPage) {
      this.page = page;  
      this.perPage = perPage;
      this.fetchUsedCode()  
    },
    orderByFunction(column) {
      if (this.orderBy == column) { 
        if (this.orderByDirection == "desc") {
          this.orderBy = null;
          this.orderByDirection = null;
          this.fetchUsedCode();
          return;
        }
        this.orderByDirection = "desc";
        this.fetchUsedCode();
          return;
      }
      this.orderBy = column;
      this.orderByDirection = "asc";
      this.fetchUsedCode();
          return;
      
    },
    fetchOpenCodes() {
      let params = {
        course_id:this.courseSelected,
      }
      this.axios.get('/course_code/openCodes',{params})
        .then(resp => {
          this.openCodes = resp.data.data
        })
    },
    fetchUsedCode() {
      let params = {
        course_id:this.courseSelected,
        page:this.page,
        perPage:this.perPage,
        orderBy:this.orderBy,
        orderByDirection:this.orderByDirection,
      }
      this.axios.get('/course_code/attachedCodes',{params})
        .then(resp => {
          this.delegatedCodes = resp.data.data.data
          this.paginator = { "current_page": resp.data.data.current_page, "last_page": resp.data.data.last_page }
        })
    },
    attachModal(course_id,code_id,code) {
      this.attached.code=code;
      this.attached.code_id=code_id;
      this.axios.get('/course_code/users/'+course_id)
        .then(resp => {
          this.users =resp.data.data.map(user => ({
          id: user.id,
          text: user.name +' ('+user.email+')'
        }))
        })
},
    attach() {
      this.attaching = true;
      this.axios.post('/course_code/attach', this.attached)
        .finally(fn => {
          
           this.fetchOpenCodes();
           this.fetchUsedCode();
           this.attaching = false;
           $(this.$refs.attachCodeModal).modal("hide");
        });
    },
    generate() {
      this.generating = true;
      this.axios.post('/course_code/create', this.generateObj)
        .finally(fn => {
          this.generating = false; 
          this.fetchOpenCodes();
          $(this.$refs.newCodeModal).modal("hide");
        });
    },
    share(id){
      this.axios.post('/course_code/share/'+ id)
        .then(resp => {
           this.delegatedCodes.map(code=>{
            if(code.id==resp.data.data.id){
              code.status=resp.data.data.status;
            }
           })
           store.state.toastNotifications.push({status: 'Success', message: 'Mail sent successfully'})
        })
    }

  }
}
</script>

<style scoped>
.tag{
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
  color:white;
  background-color: #d63330;
}
  
  .tag-used{
    background-color: #06aa16;
  }
  .tag-shared{
    background-color: rgb(0, 102, 255);
  }

</style>