<template>
  <div>
    <label>{{ $t('create-course.available_in') }} </label>
    <Select2 v-model="available" @update:modelValue="$emit('update:availableIn', $event)" :options="countries"
      :settings="{ width: '100%', allowClear: true, multiple: true }" />
    <div class="d-flex align-items-center mt-1">
      <i class="fas fa-info-circle mr-1 text-info font-15" style="font-size: 15px;"></i>
      <span class="font-12" style="font-size: 12px">{{ $t('create-course.available_in_desc') }}</span>
    </div>
  </div>
</template>
<script>
import Select2 from "vue3-select2-component";
export default {
  name: 'AvailableInCountries',
  components: { Select2 },
  data() {
    return {
      available: []
    }
  },
  props: {
    countries: {
      type: Array,
      required: true
    },
    availableIn: {
      type: Array,
      required: true
    }
  },
  emits: ['update:availableIn'],
  mounted() {
    this.available = this.availableIn
  },
  watch: {
    availableIn: {
      handler: function (val) {
        this.$emit('update:availableIn', val)
      },
      deep: true
    }
  }
}
</script>
<style scoped></style>
