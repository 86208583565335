<template>
     <!-- Modal Question Answer Delete -->
    <div class="modal fade" :id="'deleteQuestionAnswer'+answer.id+'Modal'" tabindex="-1" role="dialog" :aria-labelledby="'deleteQuestionAnswer'+answer.id+'ModalLabel'" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header delete-modal-header" style="background-color: #dc3545 !important">
                <h5 class="modal-title text-white" :id="'deleteQuestionAnswer'+answer.id+'ModalLabel'" >{{ $t('test-question-assembler.delete-sure') }}</h5>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    {{ $t('test-question-assembler.delete-question') }}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="deleteQuestionAnswer(answer)">{{ $t('test-question-assembler.delete') }}</button>
                    <button type="button" class="btn btn-primary" data-dismiss="modal">{{ $t('test-question-assembler.cancel') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'DeleteAnswerModal',
    props: {
        answer: Object,
        test: Object
    },
    methods: {
        deleteQuestionAnswer(answer) {
            answer.loading = true
            this.axios.delete('/questions/'+answer.test_question_id+'/answers/'+answer.id)
                .then(resp => {
                    let question = this.test.test_questions[this.test.test_questions.findIndex((q) => q.id == answer.test_question_id)]
                    console.log(question.test_question_answers.findIndex((qa) => qa.id == answer.id))
                    question.test_question_answers.splice(question.test_question_answers.findIndex((qa) => qa.id == answer.id), 1)
                })
                .finally(() => answer.loading = false)
        },
    }
}
</script>