<template>
    <div class="test-question-card px-3 py-3" :key="question.id">
        <div class="weight-500 text-16 d-flex justify-content-between pointer" @click.prevent="showDetails = !showDetails">
            <div>{{question.question}}</div>
            <div v-if="showDetails">
                <i class="fas fa-chevron-up" />
            </div>
            <div v-else>
                <i class="fas fa-chevron-down" />
            </div>
        </div>
        <div v-if="showDetails" class="mt-2" style="display: flex; justify-content: space-between; gap: 0.5rem;">
            <div class="d-flex flex-column gap-3" style="color: #222222">
                <div v-if="question.multimedia">
                    <div v-if="question.multimedia.type == 'video' || question.multimedia.type == 'image' " style="color: var(--black); cursor:pointer; width: 100%; height: 100%"
                        :data-fancybox="`newQuestion`"
                        :href="question.multimedia.type != 'video' ? $store.getters.getStorageURL(question.multimedia.path) : `#question-${question.id}-${question.multimedia.id}`" target="_blank"
                        :data-caption="question.multimedia.name"
                    >
                        <div v-if="question.multimedia.type == 'video'" class="test-attempt-video-container">
                            <video class="test-attempt-video" nocontrols>
                                <source :src="$store.getters.getStorageURL(question.multimedia.path)">
                            </video>
                            <video :id="`question-${question.id}-${question.multimedia.id}`" style="display: none;" controls controlsList="nodownload">
                                <source :src="$store.getters.getStorageURL(question.multimedia.path)">
                            </video>
                            <i class="far fa-play-circle playbtn" style="text-shadow: 10px 10px 10px #000"></i>
                        </div>
                        <div v-if="question.multimedia.type == 'image'" class="test-attempt-image-container">
                            <img :src="$store.getters.getStorageURL(question.multimedia.path)" class="test-attempt-image">
                        </div>
                    </div>
                </div>
                <div v-if="question.question_type === 'Subgrouped'">
                    <div class="weight-700 text-18 mt-3">Alkérdések válaszlehetőségei</div>
                </div>
                <test-question-answer-preview
                    class="d-flex flex-column gap-2"
                    v-for="answer in question.test_question_answers"
                    :key="answer.id"
                    :answer="answer"
                    :question="question"
                    :test="test"
                    :questionType="question.question_type"
                />
                <div v-if="question.question_type === 'Subgrouped'">
                    <div class="weight-700 text-18 mb-2 mt-3">Alkérdések</div>
                    <div v-for="subQuestion in question.sub_questions" :key="subQuestion.id" class="mb-3">
                        <div class="d-flex weight-500 text-16">
                            <div>{{subQuestion.question}}</div>
                            <div class="ml-2 mr-2" style="cursor: pointer;" data-toggle="modal" :data-target="'#editSubQuestionModal-'+subQuestion.id"><i class="fas fa-pen" /></div>
                            <edit-sub-question-modal :subQuestion="subQuestion" :question="question" />
                            <div class="mr-2 text-red" style="cursor: pointer;" data-toggle="modal" :data-target="'#deleteSubQuestionModal-'+subQuestion.id"><i class="fas fa-trash-alt" style="color: red;" /></div>
                            <delete-sub-question-modal :subQuestion="subQuestion" :test="test" />
                        </div>
                        <div v-if="subQuestion.multimedia">
                            <div v-if="subQuestion.multimedia.type == 'video' || subQuestion.multimedia.type == 'image' " style="color: var(--black); cursor:pointer; width: 100%; height: 100%"
                                :data-fancybox="`newQuestion`"
                                :href="subQuestion.multimedia.type != 'video' ? $store.getters.getStorageURL(subQuestion.multimedia.path) : `#question-${subQuestion.id}-${subQuestion.multimedia.id}`" target="_blank"
                                :data-caption="subQuestion.multimedia.name"
                            >
                                <div v-if="subQuestion.multimedia.type == 'video'" class="test-attempt-video-container">
                                    <video class="test-attempt-video" nocontrols>
                                        <source :src="$store.getters.getStorageURL(subQuestion.multimedia.path)">
                                    </video>
                                    <video :id="`question-${subQuestion.id}-${subQuestion.multimedia.id}`" style="display: none;" controls controlsList="nodownload">
                                        <source :src="$store.getters.getStorageURL(subQuestion.multimedia.path)">
                                    </video>
                                    <i class="far fa-play-circle playbtn" style="text-shadow: 10px 10px 10px #000"></i>
                                </div>
                                <div v-if="subQuestion.multimedia.type == 'image'" class="test-attempt-image-container">
                                    <img :src="$store.getters.getStorageURL(subQuestion.multimedia.path)" class="test-attempt-image">
                                </div>
                            </div>
                        </div>
                        <select class="form-control mt-2">
                            <option :value="null" disabled>{{$t('course-materials.choose-from-list')}}</option>
                            <option
                                v-for="answer in question.test_question_answers"
                                :key="answer.id"
                                :value="answer.id"
                                :style="subQuestion.correct_answer_id === answer.id ? 'color: green;' : 'color: red'"
                                :selected="subQuestion.correct_answer_id === answer.id"
                            >
                                {{answer.answer}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column gap-4" style="">
                <div
                    class="d-flex align-items-center gap-2"
                    v-if="(question.question_type !== 'Free text' || question.test_question_answers.length < 1)"
                >
                    <circle-button
                        width="35px"
                        height="35px"
                        data-toggle="modal"
                        :data-target="'#newAnswer'+question.id+'Modal'"
                    >
                        <i class="fas fa-plus" />
                    </circle-button>
                    <div class="text-16 weight-500 text-title-gray">
                        {{ $t('test-question-assembler.new-answer') }}
                    </div>
                    <new-answer-modal :question="question" />
                </div>
                <div v-if="question.question_type == 'Subgrouped'" class="d-flex align-items-center gap-2">
                    <circle-button
                        width="35px"
                        height="35px"
                        data-toggle="modal"
                        :data-target="'#newSubQuestionModal-'+question.id"
                    >
                        <i class="fas fa-plus" />
                    </circle-button>
                    <div class="text-16 weight-500 text-title-gray">
                        {{ $t('test-question-assembler.new-sub-question') }}
                    </div>
                    <new-sub-question-modal :question="question" />
                </div>
                <div class="d-flex align-items-center gap-2">
                    <circle-button
                        width="35px"
                        height="35px"
                        data-toggle="modal"
                        :data-target="'#editQuestion'+question.id+'Modal'"
                    >
                        <i class="fas fa-pen" />
                    </circle-button>
                    <div class="text-16 weight-500 text-title-gray">
                        {{ $t('test-question-assembler.edit-question') }}
                    </div>
                    <edit-question-modal :question="question" :test="test" :questionTypes="questionTypes" />
                </div>
                <div class="d-flex align-items-center gap-2" v-if="!test?.courses.some(c => c.finalized)">
                    <circle-button
                        width="35px"
                        height="35px"
                        data-toggle="modal"
                        :data-target="'#deleteQuestion'+question.id+'Modal'"
                    >
                        <i class="fas fa-trash-alt" />
                    </circle-button>
                    <div class="text-16 weight-500 text-title-gray">
                        {{ $t('test-question-assembler.delete') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
  <delete-question-modal :question="question" :test="test" />
</template>
<script>
import CircleButton from './CircleButton.vue'
import TestQuestionAnswerPreview from './TestQuestionAnswerPreview.vue'
import EditQuestionModal from './TestQuestionAssembler/EditQuestionModal.vue'
import EditSubQuestionModal from './TestQuestionAssembler/EditSubQuestionModal.vue'
import NewAnswerModal from './TestQuestionAssembler/NewAnswerModal.vue'
import NewSubQuestionModal from './TestQuestionAssembler/NewSubQuestionModal.vue'
import DeleteSubQuestionModal from './TestQuestionAssembler/DeleteSubQuestionModal.vue'
import DeleteQuestionModal from "./TestQuestionAssembler/DeleteQuestionModal";
export default {
    components: { CircleButton, TestQuestionAnswerPreview, NewAnswerModal, EditQuestionModal, NewSubQuestionModal, EditSubQuestionModal,DeleteQuestionModal, DeleteSubQuestionModal },
    name: 'TestQuestionCard',
    props: {
        question: Object,
        test: Object,
        questionTypes: Object,
    },
    data() {
        return {
            showDetails: false
        }
    }

}
</script>
<style scoped>
    .test-question-card {
        background: #FFFFFF;
        box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.1), 4px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
    }
</style>
