<template>
    <div v-if="$store.state.user">
        <div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('fast-share.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('fast-share.share-knowledge') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="content-2">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <section id="media-buttons">
                            <div class="row">
                                <div class="col-lg-6 mb-3">
                                    <div class="flex-wrap d-flex" @click="$refs.imageUpload.click()">
                                        <input type="file" accept="image/png, image/jpeg, image/jpg, image/gif" id="uploadImage" ref="imageUpload" @change="createUpload('image')" style="display: none;">
                                        <div class="d-flex align-items-center pointer mr-auto">
                                            <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                <img src="assets/img/icons/white-camera.svg" class="img-fluid ml-1" alt="">
                                            </div>
                                            <div class="d-flex flex-column ml-2">
                                                <h6 class="text-16 text-dark-gray">{{$t('course-materials.add-picture')}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-3">
                                    <div class="flex-wrap d-flex" @click="$refs.videoUpload.click()">
                                        <input type="file" accept="video/mp4, video/webm, video/avi" id="uploadVideo" ref="videoUpload" @change="createUpload('video')" style="display: none;">
                                        <div class="d-flex align-items-center pointer mr-auto">
                                            <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                <img src="assets/img/icons/videocam.svg" class="img-fluid ml-1" alt="">
                                            </div>
                                            <div class="d-flex flex-column ml-2">
                                                <h6 class="text-16 text-dark-gray">{{$t('course-materials.add-video')}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-3">
                                    <div class="flex-wrap d-flex" @click="$refs.audioUpload.click()">
                                        <input type="file" accept="audio/mp3, audio/mpeg, audio/wav" id="uploadAudio" ref="audioUpload" @change="createUpload('audio')" style="display: none;">
                                        <div class="d-flex align-items-center pointer mr-auto">
                                            <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                <img src="assets/img/icons/microphone.svg" class="img-fluid" alt="">
                                            </div>
                                            <div class="d-flex flex-column ml-2">
                                                <h6 class="text-16 text-dark-gray">{{$t('course-materials.add-voice')}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-3">
                                    <div class="flex-wrap d-flex" @click="$refs.documentUpload.click()">
                                        <input type="file" accept=".doc, .docx, .pdf, .xls, .xlsx, .ods, .ppt, .pptx, .txt" id="uploadDocument" ref="documentUpload" @change="createUpload('document')" style="display: none;">
                                        <div class="d-flex align-items-center pointer mr-auto">
                                            <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                <img src="assets/img/icons/file.svg" class="img-fluid ml-1" alt="">
                                            </div>
                                            <div class="d-flex flex-column ml-2">
                                                <h6 class="text-16 text-dark-gray">{{$t('course-materials.add-document')}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id="media-input" class="custom-input">
                            <form @submit.prevent="submit">
                                <div class="mb-4 mt-2">
                                    <label>{{ $t('audiorecorder.record') }}</label>
                                    <AudioRecorder @update="updateUnuploadedAudioCount" @upload="handleAudioRecording" ref="recorder" />
                                </div>
                                <div class="form-group mb-4">
                                    <label for="exampleFormControlTextarea1" class="text-18 text-dark-warm-gray">{{ $t('create-glossary-article.content') }}</label>
                                    <textarea ref="summernote" class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="content" />
                                </div>
                                <div class="form-group">
                                    <label for="" class="text-18 text-dark-warm-gray">{{ $t('create-glossary-article.labels') }}</label>
                                    <input class="form-control" v-model="labels" />  
                                </div>
                                <div class="d-flex">
                                    <span class="font-18" style="font-size: 18px" v-if="$store.state.user.role !== 'Admin'">
                                        <i class="fas fa-info-circle mr-2" style="color: var(--black)"></i>
                                        <span class="mb-3" style="font-size: 0.9em !important;">{{ $t('create-glossary-article.admin-msg') }}</span>
                                    </span>
                                </div>
                                <button :disabled="loading" type="submit" class="btn btn-theme float-right mt-3 mb-3">{{ $t('create-glossary-article.create') }}</button>
                            </form>
                        </section>
                    </div>
                    <div class="col-lg-5">
                        <section id="buttons">
                            <div class="row">
                                <div class="col-12">
                                    <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('create-glossary-article.uploads') }}</h2>
                                </div>
                                <!-- <div class="col-12">
                                    <h6 class="text-13 text-dark-gray semi-bold">No data placeholder</h6>
                                </div> -->
                                <div class="col-12">
                                    <div class="row">
                                        <div v-for="(file, index) in files" :key="file.id" class="col-12 mb-3">
                                            <Upload v-if="file.shown" :file="file.file" :type="file.type" :id="file.id" @uploadFinished="finishUpload" @uploadCancelled="cancelUpload"/>
                                        </div>
                                        <div v-if="files.length == 0" class="mb-2 col-12">
                                            <h6 class="text-13 text-dark-gray semi-bold">{{ $t('fast-share.no-uploads') }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" tabindex="-1" ref="leaveModal">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t('fast-share.leave-title')}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>{{$t('fast-share.leave-text')}}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('common.cancel')}}</button>
                    <button type="button" class="btn btn-primary" @click="confirmedLeave = true; this.$router.push(this.to ?? {name: 'Home'})" data-dismiss="modal">{{$t('common.yes')}}</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Upload from '@/components/Upload'
import AudioRecorder from '@/components/AudioRecorder'
import {ref, onMounted} from 'vue'
export default {
    name: 'FastShare',
    components: {
        Upload,
        AudioRecorder
    },
    mounted() {
        $(this.$refs.summernote).summernote({
            height: 400,
            toolbar: [
                ['style', ['style.h1', 'style.h2', 'style.h3','style.h4', 'style.h5', 'style.h6']],
                ['style', ['bold', 'italic', 'underline',]],
                ['insert', ['link']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['view', ['fullscreen', 'help']],
            ],
            callbacks: {
                onChange: (contents, $editable) => {
                    this.content = contents
                }
            }
        })

    },
    data() {
        return {
            content: null,
            files: [],
            loading: false,
            to: null,
            confirmedLeave: false,
            unuploadedAudioCount: 0
        }
    },
    watch: {
        files() {
            return this.files;
        },
    },
    computed: {
        canSend() {
            return !this.files.find(file => file.uuid === null)
        },
        charCount() {
            return $(this.$refs['summernote']).summernote('code')
        },
    },
    methods: {
        updateUnuploadedAudioCount(count) {
            this.unuploadedAudioCount = count
        },
        handleAudioRecording(audio) {
            const blob = new Blob(audio.chunks, {
                type: audio.type
            });
            const file = new File([blob], audio.name, {type: audio.type})
            this.files.push({file: file, type: 'audio', shown: true, uuid: null, id: this.files.length})
        },
        createUpload(type) {
            if(event.target.files.item(0))
                this.files.push({file: event.target.files.item(0), type: type, shown: true, uuid: null, id: this.files.length})
        },
        finishUpload(id, multimedia) {
            this.files.find(file => file.id === id).uuid = multimedia.id
        },
        cancelUpload(id, uuid) {
            this.files.splice(this.files.indexOf(this.files.find(file => file.id === id)), 1)
        },
        submit() {
            this.loading = true
            let formData = {};
            const multimedia = this.files.filter(file => file.uuid !== null).map(file => file.uuid)
            if(this.content) {
                formData.content = this.content
            }
            if(multimedia.length > 0)
                formData.multimedia = multimedia

            this.axios.post('/quick-share', formData)
                .then(resp => {
                    this.files = []
                    this.content = null
                    $(this.$refs.summernote).summernote('code', '')
                })
                .catch(e => console.log(e.response))
                .finally(() => this.loading = false)
        }
    },
    async beforeRouteLeave (to, from, next) {
        if (!this.confirmedLeave && (this.files.length > 0 || (this.content != null && String(this.content).trim() != "") || this.unuploadedAudioCount > 0)) {
            this.to = to
            $(this.$refs.leaveModal).modal('show')
            return next(false)
        }
        return next()
    }
}
</script>
<style>
.note-editor {
    background: white;
}
.mobile-view-button .fa-file-video,
.mobile-view-button .fa-image,
.mobile-view-button .fa-microphone,
.mobile-view-button .fa-file-alt {
    font-size: 55px !important;
}

.mobile-item-1,
.mobile-item-2,
.mobile-item-3,
.mobile-item-4 {
    min-height: 150px !important;border-radius: 5px !important;padding-top: 34.5px;
}

.mobile-view-button .pc-text {font-size: 0..85rem;}

@media (min-width: 768px) {
    .mobile-view-button .mobile-text {display: none !important;}
}

@media (max-width: 767px) {
    .mobile-view-button .fa-file-video,
    .mobile-view-button .fa-image,
    .mobile-view-button .fa-microphone,
    .mobile-view-button .fa-file-alt {
        font-size: 30px !important;
    }
    .mobile-view-button .pc-text {display: none !important;}
    .mobile-view-button .mobile-text {
        font-size: 0.6rem;
        white-space: nowrap;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .mobile-item-1,
    .mobile-item-2,
    .mobile-item-3,
    .mobile-item-4 {
        padding-top: 15px;
        min-height: 80px !important;
    }
    .mobile-view-button.mobile-padding-1 {
        padding-right: 5px !important;
    }
    .mobile-view-button.mobile-padding-2 {
        padding-right: 5px !important;
        padding-left: 5px !important;
    }
    .mobile-view-button.mobile-padding-3 {
        padding-left: 5px !important;
    }
    #uploads h1.file-upload-text-header {
        font-size: 16px !important;
    }
    #uploads h2.file-upload-text {
        font-size: 14px !important;
    }
}
</style>
