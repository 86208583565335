<template>
    <div>
        <button type="button" ref="record" :disabled="recorder && recorder.state == 'recording'" class="btn btn-secondary d-inline-flex align-items-center mr-2">
            <div class="circle mr-2" :class="recorder && recorder.state == 'recording' ? 'pulse' : ''"></div>
            <div>{{$t('audiorecorder.start')}}</div>
        </button>
        <button type="button" :disabled="!recorder || recorder.state != 'recording'" ref="stop" class="btn btn-secondary  d-inline-flex align-items-center mr-2">
            <i class="fas fa-square mr-2" style="width: 18px; height: 18px; color: black"></i> 
            <span>{{$t('audiorecorder.stop')}}</span>
        </button>
        <span class="font-23" style="font-variant-numeric: tabular nums;"><strong>{{duration}}</strong></span>
        <div v-if="audios.length > 0" class="d-flex align-items-center mt-2">
            <i class="fas fa-info-circle text-primary"></i>
            <div class="ml-1">{{$t('audiorecorder.click')}}</div>
        </div>
        <div v-for="audio in audios" class="mt-3">
            <div class="ml-2 mb-2">
                <label class="mb-0">{{$t('audiorecorder.name')}}:</label>
                <input type="text" v-model="audio.name" class="form-control">
            </div>
            <div class="d-flex align-items-center">
                <audio controls preload="auto" @loadedmetadata="onLoadedMetadata">
                    <source :src="audio.src">
                </audio>
                <a href="#" @click.prevent="download(audio)" class="ml-3"><i class="fas fa-download" style="width: 20px; height: 20px;"></i></a>
                <a href="#" @click.prevent="upload(audio)" class="ml-3"><i class="fas fa-cloud-upload-alt" style="width: 23px; height: 23px;"></i></a>
                <a href="#" data-toggle="modal" :data-target="'#deleteRecording-'+audio.id" class="ml-3"><i class="fas fa-trash-alt text-danger" style="width: 20px; height: 20px;"></i></a>
                <generic-delete-modal 
                        :id="'deleteRecording-'+audio.id"
                        :title="$t('audiorecorder.deleteTitle')"
                        :text="$t('audiorecorder.deleteText')" 
                        :item="audio.name" 
                        :boldItem="true" 
                        @itemDeleted="remove"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { Plugins } from "@capacitor/core"
const { VoiceRecorder } = Plugins
import GenericDeleteModal from '@/components/GenericDeleteModal'
import { Duration } from 'luxon'
export default {
    name: 'AudioRecorder',
    emits: ['upload', 'update'],
    components: {
        GenericDeleteModal
    },
    mounted() {
        this.$refs.record.addEventListener('click', () => {
        // Request permissions to record audio
            if(this.$store.state.isNative) {
                VoiceRecorder.hasAudioRecordingPermission().then((result) => {
                    if(result.value) {
                        this.startNativeRecording()
                    }
                    else {
                        VoiceRecorder.requestAudioRecordingPermission().then((result) => {
                            if(result.value) {
                                this.startNativeRecording()
                            }
                            else {
                                alert('Cannot record audio because you have declined audio recording permissions.')
                            }
                        })
                    }
                })
            }
            else {
                navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                    this.recorder = new MediaRecorder(stream)
                    this.onStart()
                    //Remove the previous event listener and append it to avoid duplicate event firing
                    this.recorder.removeEventListener('dataavailable', this.onDataAvailable)
                    this.recorder.addEventListener('dataavailable', this.onDataAvailable)
                    
                    //Remove the previous event listener and append it to avoid duplicate event firing
                    this.recorder.removeEventListener('stop', this.onStop)
                    this.recorder.addEventListener('stop', this.onStop)


                    this.recorder.start()
                })
            }
        })

        this.$refs.stop.addEventListener('click', () => {
            if(this.$store.state.isNative) {
                this.stopNativeRecording()
            }
            else {
                // Stop recording
                this.recorder.stop()
                // Remove “recording” icon from browser tab
                this.recorder.stream.getTracks().forEach(i => i.stop())
            }
        })
    },
    data() {
        return {
            recorder: null,
            audios: [],
            currAudio: {
                id: new Date().getTime(),
                name: `${new Date().getTime()}`,
                type: null,
                chunks: [],
                src: null
            },
            interval: null,
            recordingStarted: null,
            duration: '00:00'
        }
    },
    computed: {
        unuploadedAudioCount() {
            return this.audios.length
        }
    },
    watch: {
        audios: {
            handler: function (audios) {
                this.$emit('update', audios.length)
            },
            deep: true
        }
    },
    methods: {
        onLoadedMetadata(e) {
            if(e.target.duration === Infinity) {
                e.target.currentTime = Number.MAX_SAFE_INTEGER;
                e.target.ontimeupdate = () => {
                    e.target.ontimeupdate = () => {}
                    e.target.currentTime = 0.1;
                }
            }
        },
        startNativeRecording() {
            VoiceRecorder.startRecording()
                .then((result) => {
                    this.recorder = {
                        state: 'recording'
                    }
                    this.onStart()
                })
                .catch(error => alert(`Audio recording start failed with the following error message: ${error}`))
        },
        stopNativeRecording() {
            VoiceRecorder.stopRecording()
                .then(async (result) => {
                    const data = result.value.recordDataBase64
                    const mime = result.value.mimeType
                    //const blob = (await fetch(`data:audio/aac;base64,${data}`)).blob()
                    clearInterval(this.interval)
                    this.recordingStarted = null
                    this.duration = '00:00'
                    this.currAudio.type = mime
                    this.currAudio.chunks = this.base64toBlob(data, mime)
                    //const blob = new Blob( this.currAudio.chunks, { type: this.currAudio.type })
                    this.currAudio.src = `data:audio/aac;base64,${data}`//window.URL.createObjectURL(blob);
                    this.audios.push(this.currAudio)
                    const timestamp = new Date().getTime()
                    this.recorder = null
                    this.currAudio = {
                        id: timestamp,
                        name: `${timestamp}`,
                        type: null,
                        chunks: [],
                        src: null
                    }
                })
                .catch(error => alert(`Audio recording stop failed with the following error message: ${error}`))
        },
        download(audio) {
            if(this.$store.state.isNative) {
                this.$store.commit(
                    'downloadFileMobile', 
                    {
                        name: audio.name, 
                        mime: audio.type, 
                        data: new Blob(audio.chunks, {type: audio.type})
                    }
                )
            }
            else {
                var blob = new Blob(audio.chunks, {
                    type: audio.type
                });
                var url = URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = url;
                a.download = audio.name;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        },
        upload(audio) {
            this.$emit('upload', audio);
            this.remove(audio.id)
        },
        remove(id) {
            const idx = this.audios.findIndex(a => a.id == id)
            this.audios.splice(idx, 1)
        },
        onDataAvailable(e) {
            this.currAudio.chunks.push(e.data)
            this.currAudio.type = e.data.type
        },
        onStart() {
            this.recordingStarted = new Date()
            this.interval = setInterval(() => {
                this.duration = Duration.fromMillis(new Date() - this.recordingStarted).toFormat('mm:ss')
            }, 50)
        },
        onStop(e) {
            clearInterval(this.interval)
            this.recordingStarted = null
            this.duration = '00:00'
            this.currAudio.chunks.push(e.data)
            const blob = new Blob( this.currAudio.chunks, { type: this.currAudio.type })
            this.currAudio.src = window.URL.createObjectURL(blob);
            this.audios.push(this.currAudio)
            const timestamp = new Date().getTime()
            this.currAudio = {
                id: timestamp,
                name: `${timestamp}`,
                type: null,
                chunks: [],
                src: null
            }
        },
        base64toBlob(base64Data, contentType) {
            //Credit: https://stackoverflow.com/a/20151856
            contentType = contentType || '';
            var sliceSize = 1024;
            var byteCharacters = atob(base64Data);
            var bytesLength = byteCharacters.length;
            var slicesCount = Math.ceil(bytesLength / sliceSize);
            var byteArrays = new Array(slicesCount);

            for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                var begin = sliceIndex * sliceSize;
                var end = Math.min(begin + sliceSize, bytesLength);

                var bytes = new Array(end - begin);
                for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                    bytes[i] = byteCharacters[offset].charCodeAt(0);
                }
                byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            //return new Blob(byteArrays, { type: contentType });
            return byteArrays
        }
    }
}
</script>
<style scoped>
.circle {
	background-color: rgba(255, 82, 82, 1);
	border-radius: 50%;
    flex-shrink: 0;
	height: 18px;
	width: 18px;
}
.pulse {
    animation: pulse-red 2s infinite;
}
@keyframes pulse-red {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}
	
	70% {
		transform: scale(0.6);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}
	
	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}
    /* MOBILE FONT SIZE UNDER 426px */
    @media (min-width: 426px) {
        .font-23 {
            font-size: 23px !important;
        }
    }

    @media (max-width: 425px) {
        .font-23 {
            font-size: 16px !important;
        }
    }
</style>