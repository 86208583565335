<template>
  <div class="h-100">
    <div
      class="training-card filter-shadow h-100 d-flex flex-column"
      style="position: relative; cursor: pointer; margin-bottom: 0px !important;"
    >
      <div v-if="showBadges" class="badge-container">
        <div v-if="isComingSoon" class="badge-item">
          {{ $t("course.coming-soon") }}
        </div>
        <div v-else-if="Math.floor((new Date() - new Date(course.created_at)) / 86400000) <30" class="badge-item">{{ $t("course.new") }}</div>
        <div
          v-if="isLocked"
          class="badge-item"
          style="font-size: 12px; font-weight: 600; padding-top: 1px; padding-bottom: 1px;"
        >
          {{ $t("course.prerequisite-not-met") }}
        </div>
      </div>
      <div v-if="(isLocked || isComingSoon) && showBadges" class="locked-overlay"></div>
      <div
        class="training-image pointer"
        v-bind:style="{
          backgroundImage: `url(${
            course.cover_image
              ? $store.getters.getStorageURL(course.cover_image.path)
              : 'assets/img/demo/training.png'
          })`,
        }"
      ></div>

      <div class="training-card-bottom bg-light-gray-primary" style="flex-grow: 1">
        <div class="d-flex justify-content-between">
          <div>
            <h5 class="text-14 regular text-white">
              {{ $t(`course-types.${course.course_type.name}`) }}
            </h5>
            <h4 class="text-18 text-white medium">{{ course.title }}</h4>
            <div v-if="showDates" class="mt-3">
              <h5 class="text-14 regular text-white mb-2">
                {{ $t("course.begin") }}: <br />
                <span class="semi-bold">
                  {{
                    course.start_date
                      ? $store.getters.parseDateTime(course.start_date)
                      : $t("course.no")
                  }}
                </span>
              </h5>
              <h5 class="text-14 regular text-white">
                {{ $t("course.end") }}: <br />
                <span class="semi-bold">
                  {{
                    course.end_date
                      ? $store.getters.parseDateTime(course.end_date)
                      : $t("course.no")
                  }}
                </span>
              </h5>
            </div>
          </div>
          <div v-if="showFav" @click.stop="toggleFav" style="padding: 0 0 0 5px">
            <img
              src="assets/img/icons/heart-full.svg"
              class="pointer"
              alt=""
              v-if="isFav"
            />
            <img
              v-else
              src="assets/img/icons/heart.svg"
              class="pointer"
              alt=""
            />
          </div>
        </div>
      </div>

      <div
        v-if="showProgress"
        class="training-card-progress"
        :style="{ width: `${progress}%` }"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CourseCard",
  props: {
    course: Object,
    showDates: {
      type: Boolean,
      default: false,
    },
    showFav: {
      type: Boolean,
      default: true,
    },
    showBadges: {
      type: Boolean,
      default: true,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isFav() {
      return this.$store.state.user.favorite_courses.some(
        (course) => course.id === this.course.id
      );
    },
    isLocked() {
      return (
        this.course.prerequisites.length > 0 &&
        !this.course.prerequisites.some((pre) =>
          this.$store.state.user.coursesFinished.some((f) => f.id === pre.id)
        )
      );
    },
    isComingSoon() {
      return (
        this.course.start_date && Date.now() < new Date(this.course.start_date)
      );
    },
  },
  methods: {
    toggleFav() {
      this.axios.post(`/courses/${this.course.id}/toggle-favorite`);
      if (this.isFav) {
        const idx = this.$store.state.user.favorite_courses.findIndex(
          (course) => course.id === this.course.id
        );
        this.$store.state.user.favorite_courses.splice(idx, 1);
      } else this.$store.state.user.favorite_courses.push(this.course);
    },
  },
};
</script>
<style scoped>
.badge-container {
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 0;
  display: flex;
  flex-direction: column;
}
.badge-item {
  background-color: #d63330;
  border-radius: 5px 0px 0px 5px;
  font-weight: 400;
  font-size: 16px;
  color: white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.locked-overlay {
  position: absolute;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.55);
  z-index: 9;
}
</style>
