<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span
                  class="text-16 text-white pt-3 pb-3"
                  @click.prevent="$router.push({ name: 'Home' })"
                  >{{ $t("glossary.homepage") }}</span
                >
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"
                  ><img
                    src="assets/img/icons/bc-seperator.svg"
                    class="h-100"
                    alt=""
                /></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{
                  $t("settings.settings")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="settings-content" class="py-4">
      <div class="container pb-4">
        <div class="row main-content">
          <div class="col-lg-3 side-bar-container">
            <div class="sidebar">
              <router-link
                v-for="(item, idx) in sidebar"
                :key="idx"
                :to="{ name: item.route }"
                :class="{ active: $route.name.endsWith(item.route) }"
              >
                {{ item.title }}
              </router-link>
            </div>
          </div>
          <div class="col-lg-9 content-container">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sidebar: [
        { title: this.$t("settings.normal-settings"), route: "General" },
        { title: this.$t("settings.membership"), route: "Membership" },
        { title: this.$t("settings.orders"), route: "Invoices" },
        {
          title: this.$t("user-qualifications.qualifications"),
          route: "Qualification",
        },
        {
          title: this.$t("settings.professional-history"),
          route: "ProfessionalHistory",
        },
        { title: this.$t("settings.billing-info"), route: "Billing" },
        { title: this.$t("settings.other-information"), route: "OtherInfo" },
        { title: this.$t("settings.badges"), route: "Badges" },
        { title: this.$t("settings.barion-wallets"), route: "BarionWallets" },
      ],
    };
  },
};
</script>

<style scoped>
#settings-content .main-content {
  box-shadow: 0 2px 34px rgba(0, 0, 0, 0.4);
  min-height: 750px;
  height: fit-content;
  background-color: #f5f5f5;
}
#settings-content .main-content .side-bar-container {
  background-color: #fff;
  padding-left: 0;
  padding-right: 0;
}
#settings-content .main-content .side-bar-container .sidebar {
  padding-left: 0;
  display: flex;
  flex-direction: column;
}

#settings-content .main-content .side-bar-container .sidebar a {
  padding: 20px 10px 20px 12px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  color: #333e48;
}

#settings-content .main-content .side-bar-container .sidebar a:hover,
#settings-content .main-content .side-bar-container .sidebar a.active {
  background-color: #cecece;
  border-left: 3px solid #d62b33;
  text-decoration: none;
}

#settings-content .main-content .content-container {
  background-color: #f5f5f5;
  overflow-y: auto;
  height: 100%;
}
</style>
