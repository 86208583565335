<template>
<!-- Modal New Question-->
<div class="modal fade" id="newQuestionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header question-modal-header" style="background-color: var(--red) !important;">
                <h5 class="modal-title text-white" id="exampleModalLabel">{{ $t('test-question-assembler.new-question') }}</h5>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form @submit.prevent="submitNewQuestion">
                <div class="modal-body nono">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="exampleFormControlTextarea1">{{ $t('test-question-assembler.question') }}</label>
                                <textarea required v-model="newQuestion.question" maxlength="2000" class="form-control"  rows="3"></textarea>
                            </div>
                        </div>
                        <div class="col-12">
                            {{$t('test-question-assembler.attach-multimedia')}}
                        </div>
                        <generic-file-uploader
                            v-if="!newQuestion.multimedia_id"
                            @uploadFinished="attachMultimediaToNewQuestion"
                            containerClasses="col-12 row"
                            videoContainerClasses="col-6 mb-1 p-0 pr-1"
                            imageContainerClasses="col-6 mb-1 p-0 pr-1"
                        >
                            <template v-slot:video-btn>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-flex" style="flex-direction: column; align-items: center; justify-content: center;">
                                            <i class="fas fa-file-video font-28 mb-1" />
                                            <span>{{$t('test-question-assembler.video')}}</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:image-btn>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-flex" style="flex-direction: column; align-items: center; justify-content: center;">
                                            <i class="fas fa-file-image font-28 mb-1" />
                                            <span>{{$t('test-question-assembler.image')}}</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </generic-file-uploader>
                        <div class="col-12 mb-3" v-else>
                            <div>
                            <div v-if="newQuestion.multimedia_type == 'video' || newQuestion.multimedia_type == 'image' " style="color: var(--black); cursor:pointer; width: 100%; height: 100%"
                                :data-fancybox="`newQuestion`"
                                :href="newQuestion.multimedia_type != 'video' ? newQuestionMultimediaURL : '#newQuestion-'+newQuestion.multimedia_id" target="_blank"
                                data-caption="Multimedia"
                            >
                                <div v-if="newQuestion.multimedia_type == 'video'" style="max-width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: var(--lgray); border-radius: 5px; position: relative;">
                                    <video style="max-width: 100%; max-height: 100%; object-fit: cover; border-radius: 5px; pointer-events: none;" nocontrols>
                                        <source :src="newQuestionMultimediaURL">
                                    </video>
                                    <video :id="'newQuestion-'+newQuestion.multimedia_id" style="display: none;" controls controlsList="nodownload">
                                        <source :src="newQuestionMultimediaURL">
                                    </video>
                                    <i class="far fa-play-circle playbtn"></i>
                                </div>
                                <div v-if="newQuestion.multimedia_type == 'image'" style="max-width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: var(--lgray); border-radius: 5px;">
                                    <img :src="newQuestionMultimediaURL" style="max-width: 100%; max-height: 100%; object-fit: cover; border-radius: 5px">
                                </div>
                            </div>
                            <button class="w-100 btn btn-danger mt-2" @click.prevent="deleteNewQuestionMultimedia">{{$t('test-question-assembler.delete-multimedia')}}</button>
                            </div>
                        </div>
                        <div class="col-12 marg-bottom">
                            <label for="">{{ $t('test-question-assembler.max-score') }}</label>
                            <input type="number" required v-model="newQuestion.max_points" min="1" class="form-control" placeholder="">
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="">{{ $t('test-question-assembler.question-type') }}</label>
<!--                                <select class="form-control"  v-model="newQuestion.question_type" required>
                                    <option v-for="questionType in questionTypes" :key="questionType.type" :value="questionType.type">{{$t('question-type.'+questionType.type)}}</option>
                                </select>-->
                              <Select2 v-model="newQuestion.question_type" :options="questionTypes" :settings="{width:'100%',required:true}" />
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="">{{$t('test-groups.group')}}</label>
<!--                                <select class="form-control" v-model="newQuestion.test_group_id" :required="test.test_groups.length > 0">
                                    <option :value="null" disabled>{{test.test_groups.length > 0 ? $t('test-groups.choose') : $t('test-groups.none')}}</option>
                                    <option v-for="group in test.test_groups" :key="group.id" :value="group.id">{{group.name}}</option>
                                </select>-->
                              <Select2 v-model="newQuestion.test_group_id" :options="test.test_groups.map((tg)=>({id:tg.id, text:tg.name}))" :settings="{width:'100%', placeholder: test.test_groups.length > 0 ? $t('test-groups.choose') : $t('test-groups.none'), required:(test.test_groups.length > 0), allowClear:true}" />
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="exampleFormControlTextarea1">{{ $t('test-question-assembler.comment') }}</label>
                                <textarea class="form-control" v-model="newQuestion.note"  rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-secondary w-100" :disabled="newQuestion.loading">{{ $t('test-question-assembler.save') }}</button>
                </div>
            </form>
        </div>
    </div>
</div>
</template>
<script>
import GenericFileUploader from '../GenericFileUploader.vue'
import Select2 from "vue3-select2-component";
export default {
    props: {
        questionTypes: Array,
        test: Object
    },
    components: { GenericFileUploader, Select2 },
    emits: ['newQuestionCreated'],
    name: 'NewQuestionModal',
    data() {
        return {
            newQuestion: {
                question: null,
                max_points: null,
                multimedia_id: null,
                multimedia: null,
                question_type: null,
                test_group_id: null,
                note: null,
                loading: false
            },
        }
    },
    computed: {
        newQuestionMultimediaURL() {
            return this.$store.getters.getStorageURL(`/download/${this.newQuestion.multimedia_type}/${this.newQuestion.multimedia_id}`)
        },
    },
    methods: {
         submitNewQuestion() {
            this.newQuestion.loading = true
            this.axios.post('/tests/'+this.test.id+'/questions', this.$store.getters.removeEmptyOrNull(this.newQuestion, ['multimedia_id', 'test_group_id']))
                .then(resp => {
                    resp.data.question.edit = JSON.parse(JSON.stringify(resp.data.question))
                    resp.data.question.newAnswer = {answer: null, correct: resp.data.question.questionType == 'Free text'}
                    resp.data.question.newSubQuestion = {
                        question: null,
                        multimedia_id: null,
                        correct_answer_id: null
                    }
                    this.$emit('newQuestionCreated', resp.data.question)
                    this.newQuestion = {
                        questionTypes: [],
                        question: null,
                        max_points: null,
                        question_type: null,
                        test_group_id: null,
                        multimedia_id: null,
                        note: null,
                        disabled: false
                    }
                    $('#newQuestionModal').modal('hide')
                    //this.test.test_questions = JSON.parse(JSON.stringify(this.test.test_questions))
                })
                .catch(e => console.log(e,e.response))
                .finally(() => this.newQuestion.loading = false)
        },
        attachMultimediaToNewQuestion(file) {
            this.newQuestion.multimedia_id = file.uuid
            this.newQuestion.multimedia_type = file.type
        },
        deleteNewQuestionMultimedia() {
            const url = this.$store.getters.getStorageURL(`/upload/${this.newQuestion.multimedia_type}/${this.newQuestion.multimedia_id}/abort`)
            this.newQuestion.multimedia_id = null
            this.newQuestion.multimedia_type = null
            this.axios.post(url)
            .then(resp => {})
            .catch(e => console.log(e.response))
        },
    }
}
</script>
