<template>
    <div class="modal fade" tabindex="-1" role="dialog" :id="`viewUserQualificationModal-${qualification.id}`">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{$t('user-qualifications.qualification')}} {{qualification.name}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="col-12">
                    <label>{{$t('user-qualifications.name')}}</label>
                    <input class="form-control" type="text" :placeholder="$t('user-qualifications.name-placeholder')" :value="qualification.name" disabled/>
                </div>
                <div class="col-12 mt-2">
                    <label>{{$t('user-qualifications.field')}}</label>
                    <input class="form-control" type="text" :placeholder="$t('user-qualifications.field-placeholder')" :value="qualification.field" disabled/>
                </div>
                <div class="col-12 mt-2">
                    <label>{{$t('user-qualifications.type')}}</label>
                    <select class="form-control" disabled>
                        <option selected disabled>{{$t(`qualifications.${qualification.qualification.name}`)}}</option>
                    </select>
                </div>
                <div class="col-12 mt-2">
                    <label>{{$t('user-qualifications.obtained-at')}}</label>
                    <datetime :placeholder="$t('user-qualifications.obtained-at-placeholder')"
                        :zone="DateTime.local().zoneName" 
                        :valueZone="DateTime.local().zoneName" 
                        v-model="qualification.obtained_at"
                        disabled 
                        class="form-control" 
                    />
                </div>
                <div class="col-12 mt-2">
                    <label class="mb-0">{{$t('user-qualifications.attachments')}}</label>
                    <div style="font-size: 80%" class="mb-2"><i>{{$t('user-qualifications.click-to-view')}}</i></div>
                    <div v-for="attachment in qualification.attachments" :key="attachment.id" class="d-flex">
                        <a v-if="attachment.type === 'image'" :href="$store.getters.getStorageURL(attachment.path)" data-fancybox="viewUserQualification" :data-caption="attachment.name">
                            <i class="far fa-image"/> {{attachment.name}}
                        </a>
                        <a v-else-if="attachment.type === 'document'" 
                            :href="$store.getters.getStorageURL(attachment.path)"
                            target="_blank"
                            @click="downloadAttachment(attachment, $event)">
                            <i class="fas fa-file-alt"/> {{attachment.name}}
                        </a>
                    </div>
                    <p v-if="qualification.attachments.length === 0">{{$t('user-qualifications.no-attachments')}}</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('user-qualifications.close')}}</button>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import { DateTime } from 'luxon'
import Datetime from '../../DateTimePicker/Datetime.vue'
export default {
    name: 'ViewUserQualificationModal',
    components: { Datetime },
    props: {
        qualification: Object
    },
    data() {
        return {
            DateTime: DateTime
        }
    },
    methods: {
        downloadAttachment(attachment, event) {
            if(!this.$store.isNative) return;
            event.preventDefault()
            this.downloading = true;
            this.axios.get(this.$store.getters.getStorageURL(attachment.path), {responseType: 'blob'})
                .then(resp => {
                    const name = this.element.course_multimedia.name+'.pdf'
                    this.$store.commit('downloadFileMobile', {name, mime: 'application/pdf', data: resp.data})
                })
                .catch(e => console.log(e.response))
                .finally(() => this.downloading = false)
        }
    }
}
</script>