<template>
    <div :id="'wordcloud-container#'+id" style="position: relative;" :style="{height: height, width: width}">
        <canvas :id="'wordcloud#'+id"></canvas>
        <div class="canvas-hover" :id="'wordcloud-hover#'+id" style="display: none;">
            <span class="hover-label" :id="'wordcloud-label#'+id"></span>
        </div>
    </div>
</template>
<script>
import WordCloud from 'wordcloud'
export default {
    name: 'WordCloud',
    props: {
        height: {
            default: '100%',
            type: String,
            optional: true
        },
        width: {
            default: '100%',
            type: String,
            optional: true
        },
        id: {
            type: String,
            optional: false
        },
        data: {
            type: Array,
            optional: false
        }
    },
    mounted() {
        //console.log(this.data)
        this.setupRefs()
        this.container.addEventListener("mouseleave", this.watchMouse)
        this.setDimensions()

        
        const labels = this.data.sort((a,b) => b[1] - a[1])
        const lo = labels[labels.length -1][1]
        const hi = labels[0][1]
        //console.log(lo)
        const factor = hi / (this.steps.length - 1)
        //console.log(this.steps.length)
        WordCloud(
            document.getElementById('wordcloud#'+this.id), 
            {
                ellipticity: 0.65, 
                shape: 
                'circle', 
                color: (word, weight, fontSize, distance, theta) => {
                    const idx = Math.round((weight - lo) / factor)
                    return this.steps[idx]
                },
                weightFactor: function (size) {
                    return Math.round((size + 1 - lo) / factor) * 10
                },
                list: JSON.parse(JSON.stringify(labels)),
                drawOutOfBound: false, 
                shrinkToFit: true, 
                fontFamily: 'Gordita', 
                hover: (item, dimension, event) => {
                    if(dimension) {
                        this.label.textContent = `${item[0]} (${labels.find(l => l[0] === item[0])[1]})`
                        this.word.style.display = 'block'
                        this.word.style.width = dimension.w+10+'px'
                        this.word.style.height = dimension.h+8+'px'
                        this.word.style.top = dimension.y+'px'
                        this.word.style.left = dimension.x+'px'
                    }
                }
            } 
        );
    },
    beforeUnmount() {
        this.container.removeEventListener("mouseleave", this.watchMouse)
    },
    data() {
        return {
            container: null,
            cloud: null,
            word: null,
            label: null,
            steps: [
                '#05213e',
                '#052647',
                '#052c50',
                '#04315a',
                '#043763',
                '#043c6c',
                '#044275',
                '#03477e',
                '#034d88',
                '#035291',
                '#03589a',
                '#025da3',
                '#0263ac',
                '#0268b5',
                '#026ebf',
                '#0173c8',
                '#0179d1',
                '#017eda',
                '#0184e3',
                '#0089ed',
                '#008ff6',
                '#0094ff',
            ].reverse()
        }
    },
    methods: {
        setDimensions() {
            let {width, height} = this.container.getBoundingClientRect()
            this.cloud.width = width
            this.cloud.height = height
        },
        watchMouse() {
            this.word.style.display = 'none'
        },
        setupRefs() {
            this.container = document.getElementById('wordcloud-container#'+this.id)
            this.cloud = document.getElementById('wordcloud#'+this.id)
            this.word = document.getElementById('wordcloud-hover#'+this.id)
            this.label = document.getElementById('wordcloud-label#'+this.id)
        }
    }
}
</script>
<style  scoped>
.canvas-hover {
  position: absolute;
  border: 2px solid var(--red);
  box-shadow: 0 0 4px 0 #008;
  padding: 2px;
  margin: -4px 0 0 -4px;
  pointer-events: none; 
}

.hover-label {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--black);
  color: white;
  margin-top: 6px;
  padding: 0 0.5em;
  border-radius: 0.5em;
  white-space: nowrap; 
}
</style>