<template>
<div>
    <!-- USER COMMENT -->
    <div class="flex-wrap d-flex mb-2">
        <div class="d-flex w-100">
            <span v-if="comment.user.avatar" class="img-frame mr-2" >
                <img :src="comment.user.avatar.path+'?token='+$store.state.token" class="avatar-rounded" alt="#" style="height: 33px;width: 33px;">
            </span>
            <Avatar v-else :username="comment.user.name" :size="33" class="avatar-rounded mr-2" style="align-self: start;" />
            <div class="d-flex flex-column w-100">
                <div class="user-comment bg-cream">
                    <div class="flex-wrap d-flex mb-2">
                        <div class="d-flex mr-auto minus-2">
                            <div class="d-flex flex-column mt-1">
                                <h4 class="text-light-black text-14 bold mb-1">{{comment.user.name}}</h4>
                                <h5 class="text-white text-10 medium">
                                    <span class="text-light-black text-8 medium mb-2">{{$store.getters.parseDateTime(comment.created_at)}}</span>
                                    <span class="ml-0t text-light-black text-8 medium mb-2" v-if="comment.updated_at != comment.created_at">, {{ $t('post-comment.edited') }} <span class="text-light-black text-7 medium mb-2">{{$store.getters.parseDateTime(comment.updated_at)}}</span></span>
                                </h5>
                            </div>
                        </div>
                        <div class="d-flex dropleft" v-if="comment.user.id === $store.state.user.id || $store.state.user.role === 'Admin'">
                            <span  type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-ellipsis-v font-17"></i>
                            </span>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <span class="dropdown-item" style="cursor: pointer; display: flex; align-items: center;" @click="$refs.imageUpload.click()"><i class="fas fa-image font-13"></i><span class="ml-1">{{ $t('post-comment.add-pic') }}</span></span>
                                <input type="file" accept="image/png, image/jpeg, image/jpg, image/gif" id="uploadImage" ref="imageUpload" @change="createUpload('image')" style="display: none;">
                                <span class="dropdown-item" style="cursor: pointer; display: flex; align-items: center;" @click="editingComment = true"><i class="fas fa-pen font-13" ></i><span class="ml-1">{{ $t('post-comment.edit') }}</span></span>
                                <span class="dropdown-item" style="cursor: pointer; display: flex; align-items: center;" data-toggle="modal" :data-target="'#commentDeleteModal-'+comment.article_id+'-'+comment.id"><i class="fas fa-trash-alt font-13" ></i><span class="ml-1">{{ $t('post-comment.delete') }}</span></span>
                            </div>
                        </div>
                    </div>
                    <textarea v-if="editingComment" v-model="comment.comment" oninput='this.style.height = "";this.style.height = this.scrollHeight+5 + "px"' onclick='this.style.height = "";this.style.height = this.scrollHeight+5 + "px"' style="font-size: 13px; height: fit-content"></textarea>
                    <h3 ref="commentContent" v-else-if="!editingComment && comment.comment" class="text-light-black text-12" style="padding-right: 0px; word-break: break-word;" v-html="$store.getters.getHTMLSafeText(comment.comment)"></h3>
                    <div v-if="editingComment" class="d-flex flex-wrap mt-3">
                        <span class="d-flex me-auto" @click="confirmCommentEdit">
                            <button class="btn btn-theme text-12">
                                {{ $t('post.save-changes') }}
                            </button>
                        </span>
                        <span class="d-flex"  @click="cancelCommentEdit">
                            <button class="btn btn-theme-outline text-12">
                                {{ $t('post.cancel') }}
                            </button>
                        </span>
                    </div>
                    <div v-for="(file, index) in files" :key="file.id" class="col-12 mb-3">
                        <Upload v-if="file.shown" :file="file.file" :type="file.type" :id="file.id" @uploadFinished="finishUpload" @uploadCancelled="cancelUpload"/>
                    </div>
                    <div class="row ml-0" style="max-width: calc(100% - 60px)">
                        <div v-for="multimedia in comment.multimedia.filter((mm) => mm.type == 'video' || mm.type == 'image')" :key="multimedia.id" class="col-sm-6 col-md-4 col-lg-4 col-xl-6 mt-1" style="height: 200px; padding-left: 0px;">
                            <div v-if="multimedia.type == 'video' || multimedia.type == 'image' " style="color: var(--black); cursor:pointer; width: 100%; height: 100%"
                                :data-fancybox="`comment-multimedia-${comment.id}`"
                                :href="multimedia.type != 'video' ? storageURL+multimedia.path+'?token='+token : '#'+comment.id+'-'+multimedia.id" target="_blank"
                                :data-caption="multimedia.name"
                            >
                                <div v-if="multimedia.type == 'video'" style="max-width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: var(--lgray); border-radius: 5px;">
                                    <video style="max-width: 100%; max-height: 100%; object-fit: cover; position: relative; border-radius: 5px; pointer-events: none;" nocontrols>
                                        <source :src="storageURL+multimedia.path+'?token='+token">
                                    </video>
                                    <video :id="comment.id+'-'+multimedia.id" style="display: none;" controls controlsList="nodownload">
                                        <source :src="storageURL+multimedia.path+'?token='+token">
                                    </video>
                                    <i class="far fa-play-circle playbtn"></i>
                                    <span v-if="editingComment" @click.prevent="setMultimediaToDelete(multimedia)" data-toggle="modal" :data-target="'#multimediaDeleteModal-'+comment.article_id+'-'+comment.id"><i class="fas fa-times remove"></i></span>
                                </div>
                                <div v-if="multimedia.type == 'image'" style="max-width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: var(--lgray); border-radius: 5px;">
                                    <img :src="storageURL+multimedia.path+'?token='+token" style="max-width: 100%; max-height: 100%; object-fit: cover; position: relative; border-radius: 5px">
                                    <span v-if="editingComment" @click.prevent="setMultimediaToDelete(multimedia)" data-toggle="modal" :data-target="'#multimediaDeleteModal-'+comment.article_id+'-'+comment.id"><i class="fas fa-times remove"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- //USER COMMENT -->
    <!-- DELETE COMMENT MODAL -->
    <div class="modal fade" ref="commentDeleteModal" :id="'commentDeleteModal-'+comment.article_id+'-'+comment.id" tabindex="-1" aria-labelledby="commentDeleteModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="commentDeleteModalLabel">{{ $t('post-comment.sure') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{ $t('post-comment.delete-question') }}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('post-comment.cancel') }}</button>
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="deleteComment">{{ $t('post-comment.delete') }}</button>
            </div>
            </div>
        </div>
    </div>
    <!-- DELETE MULTIMEDIA MODAL -->
    <div class="modal fade" ref="multimediaDeleteModal" :id="'multimediaDeleteModal-'+comment.article_id+'-'+comment.id" tabindex="-1" aria-labelledby="multimediaDeleteModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="multimediaDeleteModalLabel">{{ $t('post-comment.sure') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{ $t('post-comment.question1') }} {{multimediaToDeleteName}} {{ $t('post-comment.question2') }}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('post-comment.cancel') }}</button>
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="deleteMultimedia">{{ $t('post-comment.delete') }}</button>
            </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { DateTime } from 'luxon';
import Avatar from '@/components/Avatar'
import Upload from '@/components/Upload'
export default {
    name: 'PostComment',
    components: {
        Avatar,
        Upload
    },
    props: {
        commentProp: Object
    },
    created() {
        this.comment = this.commentProp
    },
    data() {
        return {
            token: this.axios.defaults.headers.common.Authorization.split(' ')[1],
            storageURL: process.env.VUE_APP_STORAGE_URL,
            files: [],
            comment: null,
            originalComment: this.commentProp.comment,
            loading: false,
            editingComment: false,
            multimediaToDelete: null,
        }
    },
    watch: {
        files() {
            return this.files
        },
        multimediaToDelete() {
            return this.multimediaToDelete
        }
    },
    computed: {
        canSend() {
            return !this.files.find(file => file.uuid === null) && !this.loading
        },
        multimediaToDeleteName() {
            return this.multimediaToDelete ? this.multimediaToDelete.name : ''
        }
    },
    methods: {
        createUpload(type) {
            if(event.target.files.item(0)) {
                this.files.push({file: event.target.files.item(0), type: type, shown: true, uuid: null, id: this.files.length})
                event.target.value = null
            }
        },
        finishUpload(id, multimedia) {
            this.files.find(file => file.id === id).uuid = multimedia.id
            this.files.find(file => file.id === id).path = multimedia.path
            this.files.find(file => file.id === id).name = multimedia.name
         //   this.files.find(file => file.id === id).shown = false
            this.attachFile()
        },
        cancelUpload(id, uuid) {
            this.files.splice(this.files.indexOf(this.files.find(file => file.id === id)), 1)
        },
        confirmCommentEdit() {
            if(this.editingComment) {
                this.editingComment = false
                if(this.originalComment != this.comment.comment) {
                    let formData = {}
                    const multimedia = this.files.filter(file => file.uuid !== null).map(file => file.uuid)
                    if(this.comment.comment)
                        formData.comment = this.comment.comment
                    if(multimedia.length > 0)
                        formData.multimedia = multimedia
                    this.loading = true;
                    this.axios.patch(`/posts/${this.comment.article_id}/comments/${this.comment.id}`, formData)
                    .then(resp => {
                        this.comment = resp.data.comment
                        this.originalComment = resp.data.comment.comment
                        this.files = []
                    }).catch(e => console.log(e))
                    .finally(() => this.loading = false)
                }
            }
        },
        cancelCommentEdit() {
            if(this.editingComment) {
                this.comment.comment = this.originalComment
                this.editingComment = false
            }
        },
        deleteComment() {
            this.axios.delete(`/posts/${this.comment.article_id}/comments/${this.comment.id}`).then(resp => this.$emit('commentDeleted', this.comment)).catch(e => console.log(e.response))
        },
        parseDate(date) {
            return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)
        },
        setMultimediaToDelete(multimedia) {
            console.log(multimedia)
            this.multimediaToDelete = multimedia
        },
        deleteMultimedia() {
            this.loading = true
            this.axios.post(`${this.storageURL}/upload/${this.multimediaToDelete.type}/${this.multimediaToDelete.id}/abort`)
            .then(resp => {
                this.comment.multimedia = this.comment.multimedia.filter(mm => { return mm.id !== this.multimediaToDelete.id})
            })
            .catch(e => console.log(e.response))
            .finally(() => this.loading = false)
        },
        attachFile() {
            const multimedia = this.files.filter(file => file.uuid !== null).map(file => file.uuid)
            if(multimedia.length > 0) {
                console.log(multimedia)
                this.loading = true
                this.axios.post(`posts/${this.comment.article_id}/comments/${this.comment.id}/multimedia`, {multimedia: multimedia})
                    .then(resp => {
                        this.files.forEach((file,index) => {
                            if(multimedia[0] == this.files[index].uuid) {
                                this.files[index].id = file.uuid
                                this.comment.multimedia.push(this.files[index])
                                this.files.splice(index, 1)
                            }
                        })
                    })
                   // .catch(e => console.log(e))
                    .finally(() => this.loading = false)
            }
        },
    }
}
</script>
<style scoped>
    /* USER COMMENT CSS */
    .user-comment {
        padding: 5px 10px 20px 10px;
        border-radius: 5px;
    }
    .helper-box {
        width: 35px;
    }
</style>
