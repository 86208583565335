<template>
    <div v-if="!loading">
        <h3 class="mb-4">{{ $t('after-exam.thank-you') }}</h3>
        <div v-if="attempt.autocorrected">
            <button class="btn btn-primary" @click.prevent="$emit('ok', attempt.id)">{{ $t('after-exam.view-result') }}</button>
        </div>
        <div v-else>
            <p>{{ $t('after-exam.test-check') }}</p>
            <button class="btn btn-primary mt-2" @click.prevent="$emit('ok')">{{ $t('after-exam.ok') }}</button>
        </div>
    </div>
    <loading height="500px" v-else />
</template>
<script>
import Loading from '../components/Loading.vue'
export default {
    name: 'AfterExam',
    emits: ['ok'],
    components: {
        Loading
    },
    props: {
        testId: Number,
        attemptId: Number
    },
    created() {
        this.axios.get(`/tests/${this.testId}/attempts/${this.attemptId}`)
            .then(async (resp) => {
                if(resp.data.testAttempt.autocorrected) {
                    this.$emit('ok', resp.data.testAttempt.id)
                }
                this.attempt = resp.data.testAttempt
            })
            .finally(() => this.loading = false)
    },
    data() {
        return {
            attempt: null,
            loading: true,
        }
    },
    beforeUnmount() {
        this.$emit('ok')
    }
}
</script>
