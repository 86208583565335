<template>
  <div id="general-settings">
    <div class="head">
      <div class="">
        <span class="row" style="font-size: 14px">{{
          $t("settings.profile-picture")
        }}</span>
        <div class="row" style="margin-top: 6px">
          <Loading
            v-if="avatarUploading"
            height="150px"
            style="border-radius: 50%"
          />
          <Avatar
            v-else-if="!avatarURL && $store.state.user"
            :username="$store.state.user.name"
            :size="150"
            :rounded="false"
            style="border-radius: 5px"
          />
          <img
            v-else
            class="mb-3"
            :src="avatarURL"
            width="150"
            alt=""
            height="150"
            style="cursor: pointer; border-radius: 5px"
          />
        </div>
        <div class="align-top mb-3 ml-sm-4 mb-sm-0" style="display: none">
          <label class="mt-0 mb-5"> {{ $t("settings.upload-new-pic") }}</label>
          <br />
          <input
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            id="uploadAvatar"
            ref="fileUpload"
            @change="readURL"
          />
          <br />
          <sub> {{ $t("settings.max-pic") }} </sub>
        </div>
        <ImageCropper
          v-if="uploadedAvatarURL"
          @urlChanged="setCroppedAvatarURL"
          :src="uploadedAvatarURL"
          :type="uploadType"
        />
      </div>
      <img src="@/assets/img/settings/settings.png" alt="" />
      <div class="row" style="width: min-content">
        <div class="col-12">
          <div class="language-selector">
            <p class="mb-1">{{ $t("settings.language") }}</p>
            <div class="flags-container">
              <button
                  :disabled="$store.state.locale === 'en'"
                  @click="changeLocale('en')"
              >
                <img src="@/assets/img/lang/en.png" alt="" />
              </button>
              <button
                  :disabled="$store.state.locale === 'hu'"
                  @click="changeLocale('hu')"
              >
                <img src="@/assets/img/lang/hu.png" alt="" />
              </button>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="language-selector">
            <p class="mb-1">{{ $t("checkout-summary.currency") }}</p>
            <select class="form-control" style="width: 150px;" v-model="selectedCurrency">
              <option v-for="currency in $store.state.currencies" :key="currency.currency"
                      :value="currency.currency" >{{ currency.currency }}
              </option>
            </select>
            <sub v-if="currency !== 'EUR'" style="font-weight: bold">{{ huFormat(1, "EUR") }} =
              {{ huFormat(exchangeRate, currency) }}</sub>
          </div>
        </div>
      </div>
    </div>
    <form class="mt-5">
      <div class="form-group">
        <label>{{ $t("settings.name") }}</label>
        <input
          type="text"
          placeholder="Name"
          class="form-control"
          v-model="data.name"
          required
          :style="{
            border: errors['name'] ? '1px solid red' : '1px solid #ced4da',
          }"
          disabled
        />
      </div>
      <div class="form-group">
        <label>{{ $t("settings.email") }}</label>
        <input
          type="email"
          placeholder="Email"
          class="form-control"
          v-model="data.email"
          disabled
          required
          :style="{
            border: errors['email'] ? '1px solid red' : '1px solid #ced4da',
          }"
        />
      </div>
      <div class="form-group">
        <label>{{ $t("common.country") }}</label>
        <Select2 v-model="data.country_id" :options="countries" :settings="{width:'100%'}" readonly="readonly" disabled  required/>
      </div>
    </form>

    <!-- <div class="mt-3">
      <h1 class="ps-2">{{ $t("settings.change-password") }}</h1>
      <form class="mt-3" @submit.prevent="onPasswordSubmit">
        <div class="form-group">
          <label>{{ $t("settings.curr-pw") }}</label>
          <input
            type="password"
            autocomplete="current-password"
            placeholder="Old Password"
            class="form-control"
            v-model="password_data.current_password"
          />
        </div>
        <div class="form-group">
          <label>{{ $t("reset-password.u-pw") }}</label>
          <input
            type="password"
            placeholder="New Password"
            autocomplete="new-password"
            class="form-control"
            @input="setPassword"
          />
          <ul
            v-if="password_data.new_password != null"
            class="text-left mt-2 pl-4"
          >
            <li
              :class="
                password_validation.length ? 'text-success' : 'text-danger'
              "
            >
              {{ $t("register.valid-1") }}
            </li>
            <li
              :class="
                password_validation.lowerCase ? 'text-success' : 'text-danger'
              "
            >
              {{ $t("register.valid-2") }}
            </li>
            <li
              :class="
                password_validation.upperCase ? 'text-success' : 'text-danger'
              "
            >
              {{ $t("register.valid-3") }}
            </li>
            <li
              :class="
                password_validation.number ? 'text-success' : 'text-danger'
              "
            >
              {{ $t("register.valid-4") }}
            </li>
            <li
              :class="
                password_validation.special ? 'text-success' : 'text-danger'
              "
            >
              {{ $t("register.valid-5") }}
            </li>
          </ul>
        </div>
        <div class="form-group">
          <label>{{ $t("settings.new-pw-again") }}</label>
          <input
            type="password"
            autocomplete="new-password"
            placeholder="Repeat New Password"
            class="form-control"
            v-model="password_data.new_password_confirm"
          />
        </div>
        <div class="d-flex mt-5 justify-content-between align-items-center">
          <TransparentButton
            :click="openDeleteModal"
            icon="delete-red"
            :text="$t('settings.delete-profile')"
            right-icon
          />
          <PrimaryButton
            :disabled="submitting || loading"
            icon="save"
            text="Save"
            right-icon
            :click="onSubmit"
          />
        </div>
      </form>
    </div> -->
    <DeleteProfileModal />
  </div>
</template>

<script>
import DeleteProfileModal from "../../components/DeleteProfileModal.vue";
import Avatar from "@/components/Avatar";
import Loading from "@/components/Loading";
import ImageCropper from "@/components/ImageCropper";
import PrimaryButton from "./PrimaryButton.vue";
import TransparentButton from "./TransparentButton.vue";
import store from "@/store";
import Select2 from "vue3-select2-component";

export default {
  components: {
    Select2,
    TransparentButton,
    PrimaryButton,
    DeleteProfileModal,
    Loading,
    Avatar,
    ImageCropper,
  },
  mounted() {
    //get countries
    this.axios.get('/countries').then(resp => {
      this.countries = resp.data.map((country)=>({id: country.id, text: country.name}));
    })
  },
  data() {
    return {
      avatarURL:
        this.$store.state.user && this.$store.state.user.avatar
          ? this.$store.state.user.avatar.path +
            "?token=" +
            this.$store.state.token
          : null,
      data: this.$store.state.user
        ? {
            name: this.$store.state.user.name,
            email: this.$store.state.user.email,
            country_id: this.$store.state.user.country_of_interest.id
          }
        : {},
      errors: Object(),
      loading: false,
      avatarUploading: false,
      uploadedAvatarURL: null,
      selectedCurrency: this.$store.state.currency,
      password_data: {
        current_password: null,
        new_password: null,
        new_password_confirm: null,
      },
      password_validation: {
        length: false,
        lowerCase: false,
        upperCase: false,
        number: false,
        special: false,
      },
      countries: [],
      submitting: false,
    };
  },
  computed: {
    pwErrors() {
      for (const prop in this.password_validation) {
        if (this.password_validation[prop] === false) return true;
      }
      return false;
    },
    currency() {
      return this.$store.state.currency;
    },
    exchangeRate() {
      console.log("exchangeRate", this.$store.state.exchangeRates);
      if (!this.$store.state.currency || this.$store.state.currency === "EUR") return 1;
      return this.$store.state.exchangeRates.find(r => r.secondary_currency === this.$store.state.currency)?.exchange_rate
    },
  },
  watch: {
    selectedCurrency(val) {
      this.$store.commit('setCurrency', val)
      this.$store.state.currency = val
    }
  },
  methods: {
    huFormat(price, currency = this.currency) {
      return new Intl.NumberFormat(this.$store.getters.isoLocale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0
      }).format(price)
    },
    changeLocale(lang) {
      const currentLocale = this.$store.state.user.locale;
      this.$i18n.locale = lang;
      this.$store.commit("changeLocale", lang);
      if (currentLocale != lang)
        this.axios
          .post("/locale", { locale: lang })
          .then(() => {
            this.$store.state.user.locale = lang;
          })
          .catch((e) => {
            console.log(e);
          });
    },
    changeCurrency(currency) {
      if (currency === "EUR") {
        this.$store.commit("setCurrency", currency);
      }
    },
    openDeleteModal() {
      $("#delete-profile-modal").modal("show");
    },
    readURL(input) {
      input = input.target;
      if (input.files && input.files[0]) {
        this.uploadType = input.files[0].type;
        var reader = new FileReader();
        reader.onload = (e) => {
          this.uploadedAvatarURL = e.target.result;
        };

        reader.readAsDataURL(input.files[0]); // convert to base64 string
      }
    },
    setCroppedAvatarURL(url, save) {
      console.log("clicked on close", save);
      if (!save) {
        this.avatarUploading = false;
        this.uploadedAvatarURL = null;
        return false;
      }
      this.avatarUploading = true;
      let file = this.dataURLtoFile(url);
      let formData = new FormData();
      formData.set("image", file, file.name);
      let config = {
        method: "POST",
        data: formData,
        url: `set-avatar`,
        baseURL: process.env.VUE_APP_API_URL,
        headers: {
          "Content-Type": "application/octet-stream",
        },
      };
      this.axios(config)
        .then((resp) => {
          console.log(resp);
          this.$store.state.user.avatar.path = resp.data.path;
          this.avatarURL = resp.data.path + "?token=" + this.$store.state.token;
        })
        .catch((e) => (this.avatarUploading = false))
        .finally(() => (this.avatarUploading = false));
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], "avatar", { type: mime });
    },
    onSubmit() {
      this.loading = true;

      this.axios
        .post("/update-profile", this.removeEmptyOrNull(this.data))
        .then((resp) => {
          this.$store.state.user = resp.data.user;
        })
        .catch((e) => (this.errors = e.response.data.errors))
        .finally(() => (this.loading = false));

      if (
        this.password_data.current_password &&
        this.password_data.new_password
      )
        this.onPasswordSubmit();
    },
    removeEmptyOrNull(obj) {
      Object.keys(obj).forEach((k) => {
        if (obj[k] === null && k !== "badge_id") delete obj[k];
      });
      return obj;
    },
    setPassword(pw) {
      this.password_data.new_password = pw.target.value;
      this.password_validation.length =
        this.password_data.new_password.length >= 8;
      this.password_validation.lowerCase = new RegExp("[a-z]").test(
        this.password_data.new_password
      );
      this.password_validation.upperCase = new RegExp("[A-Z]").test(
        this.password_data.new_password
      );
      this.password_validation.number = new RegExp("[0-9]").test(
        this.password_data.new_password
      );
      this.password_validation.special = new RegExp("[^A-Za-z0-9]").test(
        this.password_data.new_password
      );
    },
    onPasswordSubmit() {
      if (this.pwErrors) {
        this.$store.state.toastNotifications.push({
          status: "Error",
          message: "Password change is no valid",
          id: this.$store.state.toastCount++,
        });
        return;
      }
      this.submitting = true;
      this.axios
        .post("/password", this.password_data)
        .then(() => {
          this.password_data = {
            current_password: null,
            new_password: null,
            new_password_confirm: null,
          };
          $(this.$refs["changePasswordModal"]).modal("hide");
        })
        .catch()
        .finally(() => (this.submitting = false));
    },
    changeCountryOfInterest(){
      console.log("changeCountryOfInterest")
    }
  },
};
</script>

<style scoped>
h1 {
  font-size: 20px;
  font-weight: 500;
  color: #333e48;
}

form .form-group label {
  color: #333e48;
  font-size: 14px;
  font-weight: 500;
}

.profile-drop-item-lang > span {
}

@media (min-width: 992px) {
  .profile-drop-item {
    display: block;
    text-align: left !important;
  }

  .profile-drop-item-lang {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .profile-drop-item-lang span {
    background-color: transparent;
    color: var(--red);
    padding: 3px 10px;
    border-radius: 5px;
    margin-right: 10px;
    border: 2px solid var(--red);
    font-size: 14px;
  }

  .profile-drop-item-lang span.active {
    font-weight: bold;
    background-color: var(--red);
    color: var(--white);
  }
}

#general-settings {
  padding: 40px 25px;
  /*position: fixed;*/
}

#general-settings .head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /*padding-left: 150px;*/
}

#general-settings .head img {
  height: 150px;
  width: 150px;
}
/* START FORM */

form .form-group label {
  color: #333e48;
  font-size: 14px;
  font-weight: 500;
}

form .form-group .form-control {
  border: 1px solid #928c88;
  border-radius: 5px;
}
.head .col {
  min-height: 193px;
}

.flags-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flags-container button {
  border: none;
  height: 37px;
  width: 51px;
  padding: 0;
  opacity: 0.3;
}

.flags-container button img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}

.flags-container button:focus {
  outline: none;
}

.flags-container button:hover {
  opacity: 1;
}

.flags-container button:disabled {
  opacity: 1;
}

.language-selector p {
  color: #333e48;
  font-weight: 500;
  font-size: 14px;
}
</style>
