<template>
  <div class="row h-100">
    <div class="col-xl-4 left-side-image h-100">
      <img class="img-fluid small-logo" src="assets/img/xbody-dark.png" alt="">
    </div>
    <div class="col-xl-8 h-100 right-side-content">
      <div class="container pt-5 h-100">
        <div class="row justify-content-center align-items-center bg-dark-white h-100">
          <!-- <form class="col-xl-5 col-lg-8 col-md-8 col-sm-8 col-10 text-center" @submit.prevent="submitForm">
            <h2 class="mb-3 mt-4">{{ $t('login.registry') }}</h2>
            <div class="mb-3">
              <label for="inputName" class="form-label float-left">{{ $t('register.name') }}</label>
              <input
                  type="text"
                  id="inputName"
                  class="form-control"
                  :placeholder="$t('register.username')"
                  required
                  v-model="registration.name"
                  autofocus/>
              <ul v-if="registration.name != null" class="list-unstyled text-left mt-2 ml-3">
                <li >{{ $t('register.username-info') }}</li>
              </ul>
            </div>
            <div class="mb-3">
              <label for="country" class="form-label float-left">{{ $t('register.country') }}</label>
              <Select2 v-model="registration.country_id" :options="countries" :settings="{width:'100%', placeholder:$t('register.country')}"  required/>
            </div>
            <div class="mb-3">
              <label for="inputEmail" class="form-label float-left">{{ $t('register.email') }}</label>
              <input
                  type="email"
                  id="inputEmail"
                  autocomplete="email"
                  class="form-control"
                  :placeholder="$t('register.user-email')"
                  required
                  v-model="registration.email"
              />
            </div>
            <div class="mb-3">
              <label for="inputPassword" class="form-label float-left">{{ $t('register.password') }}</label>
              <input
                  type="password"
                  id="inputPassword"
                  autocomplete="new-password"
                  class="form-control"
                  :placeholder="$t('register.p1')"
                  required
                  @input="setPassword"/>
              <ul v-if="registration.password != null" class="text-left mt-2">
                <li :class="validation.length ? 'text-success' : 'text-danger'">{{ $t('register.valid-1') }}</li>
                <li :class="validation.lowerCase ? 'text-success' : 'text-danger'">{{ $t('register.valid-2') }}</li>
                <li :class="validation.upperCase ? 'text-success' : 'text-danger'">{{ $t('register.valid-3') }}</li>
                <li :class="validation.number ? 'text-success' : 'text-danger'">{{ $t('register.valid-4') }}</li>
                <li :class="validation.special ? 'text-success' : 'text-danger'">{{ $t('register.valid-5') }}</li>
              </ul>
            </div>
            <div class="mb-3">
              <label for="inputConfirmPassword" class="form-label float-left">{{
                  $t('register.password-again')
                }}</label>
              <input
                  type="password"
                  id="inputConfirmPassword"
                  class="form-control"
                  autocomplete="new-password"
                  :placeholder="$t('register.p2')"
                  required
                  v-model="registration.confirmPassword"/>
            </div>
            <div class="mb-2">
              <div class="d-flex align-items-center text-left">
                <input type="checkbox" style="cursor:pointer" v-model="acceptTerms">
                <p class="ml-2 mb-0">{{ $t('checkout-summary.accept_aszf') }} <a target="_blank"
                                                                                 href="/terms-and-conditions">{{
                    $t('checkout-summary.aszf')
                  }}</a> {{ $t('checkout-summary.and') }} <a target="_blank"
                                                             href="/data-privacy">{{ $t('checkout-summary.gdpr') }}</a>.
                </p>
              </div>
            </div>

            <button type="submit" class="btn btn-normal w-100"
                    :disabled="loading || pwErrors || !acceptTerms || !acceptPrivPol">{{ $t('register.registry') }}
            </button>
            <nda-modal @accepted="register"/>
            <h5 class="mt-4 text-13" @click.prevent="$router.push({name: 'Login'})">{{ $t('login.have-account') }} <a
                href="#">{{ $t('register.login') }}</a></h5>
            <h5 class="mt-2 text-13" @click.prevent="$router.push({name: 'ForgotPassword'})"><a
                href="#">{{ $t('register.forgot-password') }}</a></h5>
          </form> -->
          <div class="col-12">
             <a style="width: 255px;" class="d-block btn btn-theme m-auto" target="_blank" :href="xbodyIdUrl+'/registration'">
                      Register in XBody ID</a>
          <h5 class="d-block mt-4 text-13 text-center" @click.prevent="$router.push({name: 'Login'})">{{ $t('login.have-account') }} <a
                href="#">{{ $t('register.login') }}</a></h5>
          </div>
         
        </div>
        <div class="row">
          <div class="clearfix mx-auto m-2">
            <div class="lang-selector">
              <span :class="$store.state.locale == 'hu' ? 'active' : ''" @click.prevent="changeLocale('hu')">HU</span>
              <span :class="$store.state.locale == 'en' ? 'active' : ''" @click.prevent="changeLocale('en')">EN</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NdaModal from '../components/NdaModal.vue'
import Select2 from "vue3-select2-component";

export default {
  name: 'Register',
  components: {
    Select2,
    NdaModal
  },
  mounted() {
    //get countries
    this.axios.get('/countries').then(resp => {
      this.countries = resp.data.map((country)=>({id: country.id, text: country.name}));
    })
  },
  data() {
    return {
      xbodyIdUrl:process.env.VUE_APP_XBODY_URL,
      registration: {
        name: null,
        email: null,
        password: null,
        country_id: null,
        confirmPassword: null
      },
      countries: [],
      acceptTerms: false,
      acceptPrivPol: true,
      validation: {
        length: false,
        lowerCase: false,
        upperCase: false,
        number: false,
        special: false
      },
      loading: false
    }
  },
  computed: {
    pwErrors() {
      for (const prop in this.validation) {
        if (this.validation[prop] === false) return true
      }
      return false
    }
  },
  methods: {
    setPassword(pw) {
      this.registration.password = pw.target.value
      this.validation.length = this.registration.password.length >= 8
      this.validation.lowerCase = new RegExp('[a-z]').test(this.registration.password)
      this.validation.upperCase = new RegExp('[A-Z]').test(this.registration.password)
      this.validation.number = new RegExp('[0-9]').test(this.registration.password)
      this.validation.special = new RegExp('[^A-Za-z0-9]').test(this.registration.password)
    },
    submitForm() {
      $('#ndaModal').modal('show')
    },
    async changeLocale(lang) {
      this.$i18n.locale = lang
      this.$store.commit('changeLocale', lang)
    },
    register() {
      if (this.loading || this.pwErrors || !this.acceptTerms || !this.acceptPrivPol) return;
      this.loading = true;
      this.axios.post('/register', this.registration)
          .then(resp => {
            this.$store.commit('setUser', resp.data.user)
            localStorage.setItem('accessToken', resp.data.token)
            this.$router.push({name: 'VerifyEmail'})
            this.axios.defaults.headers.common = {
              'Authorization': `Bearer ${resp.data.token}`,
              'Accept': 'Application/json'
            }
            this.axios.defaults.headers.common['X-Socket-Id'] = window.Echo.socketId();
            this.$store.state.token = resp.data.token
            window.Echo.options.auth.headers.Authorization = `Bearer ${resp.data.token}`
            this.$store.dispatch('setupWebsocketListeners')
          })
          .catch(err => console.log(err.response))
          .finally(() => this.loading = false)
    }
  }
}
</script>
<style scoped>
.left-side-image {
  background-image: url('../assets/img/login-image.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 70px 70px !important;
}

.small-logo {
  width: 150px;
}

label small {
  color: var(--gray);
}

h6 {
  font-weight: 400;
}
.lang-selector {
  margin-top: 0;
  margin-bottom: 7px;
}

.lang-selector span {
  background-color: transparent;
  cursor: pointer;
  color: var(--red);
  padding: 3px 10px;
  border-radius: 5px;
  margin-right: 10px;
  border: 2px solid var(--red);
  font-size: 14px;
}

.lang-selector span.active {
  font-weight: bold;
  background-color: var(--red);
  color: var(--white);
}
.container {
  max-width: 100% !important;
}

@media (min-width: 1200px) {
  .right-side-content {
    padding-left: 0px !important;
  }
}

@media (max-width: 1199px) {
  .left-side-image {
    height: 100px !important;
    padding: 37px 50px !important;
  }

  .right-side-content {
    max-height: calc(100% - 100px);
  }

  .remove-text {
    display: none;
  }

  .small-logo {
    width: 100px;
  }
}

.btn-normal {
  padding: 10px 10px;
  border-radius: 5px !important;
}

input {
  border-color: var(--gray) !important;
  box-shadow: none !important;
  outline: none !important;
  color: var(--black);
}

input:focus {
  border-color: var(--red) !important;
}

label,
.d-flex a:active,
.d-flex a:visited,
.d-flex a:link {
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
}

a:active,
a:visited,
a:link {
  color: var(--black);
  text-decoration: none;
  font-weight: 400;
}

a:hover {
  color: var(--red);
}

@media (max-width: 991px) {
  label,
  .d-flex a:active,
  .d-flex a:visited,
  .d-flex a:link {
    font-size: 13px !important;
  }
}
</style>
