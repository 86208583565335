<template>
  <!-- BREADCRUMB -->
  <div class="margin-sticky-fix"></div>
  <div class="breadcrumb bg-dark-gray">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="flex-wrap d-flex">
            <div class="d-flex mr-4 pointer">
              <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({ name: 'Home' })">{{
                $t("course-catalog.homepage") }}</span>
            </div>
            <div class="d-flex mr-4">
              <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt="" /></span>
            </div>
            <div class="d-flex pointer mr-4">
              <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({ name: 'Cart' })">{{
                $t("cart.cart") }}</span>
            </div>
            <div class="d-flex mr-4">
              <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt="" /></span>
            </div>
            <div class="d-flex mr-4">
              <span class="text-16 text-white pt-3 pb-3">{{ $t('checkout-payment.sum') }}</span>
            </div>
            <div class="d-flex mr-4">
              <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt="" /></span>
            </div>
            <div class="d-flex mr-4">
              <span class="text-16 text-white pt-3 pb-3">{{ $t('checkout-summary.invoice-preview') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- //BREADCRUMB -->
  <section id="content-2">
    <div class="container">
      <div v-if="!loading && !submitting" class="row">
        <div class="col-md-8 col-sm-12">
          <h5 class="mb-2">{{ $t("checkout-summary.invoice-preview") }}</h5>
          <iframe :src="invoicePreviewPDF" width="100%" height="800px" frameborder="0"></iframe>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="d-flex mt-4 justify-content-center">
            <button class="btn btn-theme" style="font-weight: 600" :disabled="submitting" @click.prevent="startPayment">
              {{ $t("checkout-summary.finish") }}
            </button>
          </div>
          <div class="d-flex mt-4 justify-content-center">
            <h6>
              {{$t('checkout-summary.invoice-notice')}}
            </h6>
          </div>
          <img src="../assets/img/barion-card-strip-intl.svg" class="w-100 mt-3" alt="">
        </div>
      </div>
      <Loading v-else height="70vh" />
    </div>

  </section>
</template>

<script>
import {Capacitor, Plugins} from '@capacitor/core';
import Loading from '../components/Loading';

const { Browser } = Plugins;
export default {
  components: { Loading },
  name: "InvoicePreviewPage",
  created() {

    if(!this.$store.state.checkout || !this.$store.state.checkout.currency) {
      this.$router.push({name: 'CheckoutUserData'})
    }
    else if(this.$store.state.checkout.card == 0) {
      this.$router.push({name: 'CheckoutPaymentMethod'})
    }
    else {
      this.coupon_code = this.$store.state.checkout.coupon_code ? this.$store.state.checkout.coupon_code.code : null
      this.created = true
    }
    this.$store.dispatch('getExchangeRates')
    this.$store.dispatch('getCurrencies')
  },
  mounted() {
    const preview = new URL(process.env.VUE_APP_API_URL+'/preview-invoice/show');
    preview.searchParams.append("token", this.$store.state.token);
    preview.searchParams.append("currency", this.$store.state.checkout?.currency);
    if (this.$store.state.checkout?.coupon_code){
      preview.searchParams.append("discount", this.discount);
    }
    this.invoicePreviewPDF = preview.href;
  },
  data() {
    return {
      loading:false,
      invoicePreviewPDF:null,
      coupon_code: null,
      submitting: false,
    }
  },

  computed: {
    orderInProgress() {
      return this.$store.state.user.orders.find(o => o.status == 'Prepared' || o.status == 'Started' || o.status == 'InProgress');
    },
    currency() {
      return this.$store.state.currency;
    },
    exchangeRate() {
      if(!this.$store.state.checkout || this.$store.state.checkout.currency == 'EUR') return 1;
      return this.$store.state.exchangeRates.find(r => r.secondary_currency == this.$store.state.currency).exchange_rate
    },
    netTotal() {
      if(this.$store.state.user.cart_items.length > 0) {
        return this.$store.state.user.cart_items.reduce((acc, item) => acc + item.cartable.net_price, 0);
      }
      return 0
    },
    discount() {
      const checkout = this.$store.state.checkout;
      const couponCode = checkout && checkout.coupon_code;
      let discount = 0;

      console.log("couponCode", couponCode);

      if (couponCode) {
        if (couponCode.course || couponCode.membership) {
          this.$store.state.user.cart_items.forEach((item) => {
            // Check if the item is a course and if the coupon is for a specific course
            if (item.cartable_type === 'App\\Models\\Course' && couponCode.course && couponCode.course.id === item.cartable.id) {
              if (item.cartable.coupon_compatible) {
                discount += couponCode.percentage_based
                  ? Math.min(
                    item.cartable.net_price * (couponCode.discount / 100),
                    item.cartable.net_price
                  )
                  : Math.min(couponCode.discount, item.cartable.net_price);
              }
            }
            // Check if the item is a membership and if the coupon is for a specific membership
            else if (item.cartable_type === 'App\\Models\\Membership' && couponCode.membership && couponCode.membership.id === item.cartable.id) {
              discount += couponCode.percentage_based
                ? Math.min(
                  item.cartable.net_price * (couponCode.discount / 100),
                  item.cartable.net_price
                )
                : Math.min(couponCode.discount, item.cartable.net_price);
            }
          });
        } else {
          // Calculate the general coupon discount
          discount = couponCode.percentage_based
            ? Math.min(
              this.netTotal * (couponCode.discount / 100),
              this.netTotal
            )
            : Math.min(couponCode.discount, this.netTotal);
        }

        console.log("discount", Math.round(discount));
        return Math.round(discount);
      }

      return 0;
    },
    vatSum() {
      if (this.$store.state.user.cart_items.length > 0) {
        return this.$store.state.user.cart_items.reduce(
          (acc, item) =>
            acc +
            (item.cartable.net_price - this.discount) *
            (this.$store.getters.vatRate / 100),
          0
        );
      }
      return 0
    },
    grossTotal() {
      return this.netTotal + this.vatSum - this.discount
    }
  },
  methods: {
    huFormat(price, currency = this.currency) {
      return new Intl.NumberFormat(this.$store.getters.isoLocale, { style: 'currency', currency: currency, minimumFractionDigits: 0}).format(price)
    },
    startPayment() {
      this.submitting = true
      this.$store.state.checkout.currency = this.$store.state.currency
      this.axios.post('/payment/start', this.$store.state.checkout)
          .then(resp => {
            if(resp.data.paymentRequired) {
              if(this.$store.state.isNative && Capacitor.getPlatform() !== 'ios') {
                Browser.open({ url: resp.data.order.checkout_url, presentationStyle: 'popover' });
              }
              else {
                window.open(resp.data.order.checkout_url, '_self');
              }
              this.loading = true
            }
            else {
              this.$router.push({name: 'AfterPayment',params: {'paymentRequestId': resp.data.order.payment_request_id}, query: {'paymentId': resp.data.order.payment_id}})
            }
            this.$store.state.checkout = null
            this.axios.get('/user')
                .then(resp => this.$store.state.user = resp.data)
                .finally(() => this.loading = false)
          })
          .catch(e => console.log(e))
          .finally(() => this.submitting = false)
    },
  }
}
</script>

<style scoped>

</style>
