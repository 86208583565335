<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('reporting.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Reporting'})">{{ $t('reporting.statistic') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'CourseFeedbackReports'})">{{ $t('reporting.rates') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('reporting.rate') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->
        <!-- RÉGI FRONTEND -->
        <div id="content-2">
            <div class="container">
                <div v-if="!loading">
                    <div v-if="course.course_feedback" class="row">
                        <div class="col-12 mt-2 text-left" v-if="course">
                            <h3 class="text-22 text-dark-gray semi-bold mb-4">{{course.title}}</h3>
                        </div>
                        <div class="col-md-6 col-12 mb-4">
                            <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('course-feedback-list.rating1') }}</h3>
                            <div class="chart-box bg-white filter-shadow custom-input">
                                <course-feedback-chart :filledCount="course.course_feedback.user_course_feedback_count" :notFilledCount="course.users_count - course.course_feedback.user_course_feedback_count"/>
                            </div>
                        </div>
                        <div class="col-md-6 col-12 mb-4" v-for="question in course.course_feedback.course_feedback_questions.filter(q => q.course_feedback_question_type.type != 'Free text' && q.matrix_group == null)" :key="question.id">
                            <h3 class="text-18 text-dark-gray semi-bold mb-3">{{question.question}}</h3>
                            <div class="chart-box bg-white filter-shadow custom-input">
                                <course-feedback-question-chart :id="question.id" :data="question.course_feedback_question_answers.map(a => Object({key: a.answer, value: a.user_course_feedback_question_answers_count}))" />
                            </div>
                        </div>
                        <div class="col-md-6 col-12 mb-4" v-if="matrixData.labels.length > 0">
                            <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('course-feedback-list.matrix') }}</h3>
                            <div class="chart-box bg-white filter-shadow custom-input">
                                <matrix-chart :labels="matrixData.labels" :datasets="matrixData.data" />
                            </div>
                        </div>
                        <div class="col-12 mb-4">
                            <!-- RESPONSIVE TABLE -->
                            <div class="row">
                                <div class="col-12 mt-5 mb-3">
                                    <h2 class="text-18 text-dark-gray semi-bold">{{ $t('course-feedback-list.rating2') }}</h2>
                                </div>
                                <div class="col-12 mb-4">
                                    <div class="new-table filter-shadow">
                                        <table class="table borderless-table">
                                            <thead class="bg-light-gray-primary">
                                                <tr>
                                                    <th :class="'pointer'+' '+(currentSort==='name'?'sorted':'')" @click="sort('name')">{{ $t('course-feedback-list.user-name') }}</th>
                                                    <th :class="'pointer'+' '+(currentSort==='email'?'sorted':'')" @click="sort('email')">{{ $t('course-feedback-list.user-email') }}</th>
                                                    <th :class="'pointer'+' '+(currentSort==='created_at'?'sorted':'')" @click="sort('created_at')">{{ $t('course-feedback-list.date') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="userFeedback in sortedFeedbackUsers"
                                                    :key="userFeedback.id"
                                                    @click.prevent="$router.push({name: 'UserCourseFeedback', params: {course: course.id, feedback: userFeedback.id}})"
                                                >
                                                    <td>{{userFeedback.user.name}}</td>
                                                    <td>{{userFeedback.user.email}} </td>
                                                    <td><span class="left">{{$store.getters.parseDateTime(userFeedback.created_at)}}</span></td>
                                                </tr>
                                                <tr v-if="course.course_feedback.user_course_feedback == 0">
                                                    <td colspan="3" class="text-center text-light-black semi-bold pt-4 pb-4">{{ $t('no-data.analytics')}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- //RESPONSIVE TABLE -->
                        </div>
                    </div>
                    <div v-else class="row">
                        <div  class="col-12 mt-2">
                            <h3 class="text-13 text-dark-gray semi-bold">{{ $t('course-feedback-list.not-exists') }}</h3>
                        </div>
                    </div>
                </div>
                <loading v-else />
            </div>
        </div>
    </div>
</template>
<script>
import CourseFeedbackChart from '../components/Charts/CourseFeedbackChart.vue'
import CourseFeedbackQuestionChart from '../components/Charts/CourseFeedbackQuestionChart.vue'
import MatrixChart from '../components/Charts/MatrixChart.vue'
import Loading from '../components/Loading.vue'
export default {
    components: { CourseFeedbackChart, Loading, CourseFeedbackQuestionChart, MatrixChart },
    name: 'CourseFeedbackList',
    mounted() {
        this.axios.get('/courses/'+this.$route.params.course+'/feedback/list')
            .then(resp => {
                this.course = resp.data
                this.constructMatrixData()
            })
            .finally(() => this.loading = false)
    },
    data() {
        return {
            loading: true,
            course: null,
            submitting: false,
          currentSort:'name',
          currentSortDir:'asc',
            matrixData: {
                labels: [],
                data: [
                    [],
                    [],
                    [],
                    [],
                ]
            }
        }
    },
    methods: {
        createFeedback() {
            this.submitting = true
            this.axios.post('/courses/'+this.course.id+'/feedback')
                .then(resp => {
                    this.$router.push({name: 'EditCourseFeedback', params: {course: this.course.id}})
                })
                .finally(() => this.submitting = false)
        },
        constructMatrixData() {
            this.course.course_feedback?.course_feedback_questions.filter(q => q.matrix_group != null).forEach(q => {
                this.matrixData.labels.push(q.question)
                q.course_feedback_question_answers.forEach(a => {
                    this.matrixData.data[Number(a.answer) - 1].push(a.user_course_feedback_question_answers_count)
                })
            })
        },
      sort: function (s) {
        //if s == current sort, reverse
        if (s === this.currentSort) {
          this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
        }
        this.currentSort = s;
      },
    },
    computed: {
      sortedFeedbackUsers: function () {
        return this.course.course_feedback.user_course_feedback.sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDir === 'desc') modifier = -1;
          if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
        });
      }
  }
}
</script>
<style scoped>
    .chart-box {
        padding: 15px;
        border-radius: 8px;
    }
    .sorted{
      font-weight: normal;
    }
</style>
