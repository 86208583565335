<template>
    <div>
        <div class="col-12">
            <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('settings.qualifications') }} </h3>
        </div>
        <div class="col-12 mt-2">
            <div class="flex-wrap d-flex mb-3">
                <div class="d-flex align-items-center pointer mr-auto" data-toggle="modal" data-target="#createUserQualificationModal">
                    <div class="new-button-40 d-flex align-items-center justify-content-center">
                        <img src="assets/img/icons/plus.svg" class="img-fluid" alt="">
                    </div>
                    
                    <div class="d-flex flex-column ml-2">
                        <h6 class="text-16 text-dark-gray">{{$t('user-qualifications.add-new')}}</h6>
                    </div>
                </div>
            </div>
            <create-user-qualification-modal @created="addQualification" />
        </div>
        <div v-if="userQualifications !== null" class="col-12 mb-4">
            <div v-for="userQualification in userQualifications" :key="userQualification.id" class="d-flex">
                <div style="cursor: pointer" data-toggle="modal" :data-target="`#viewUserQualificationModal-${userQualification.id}`">{{userQualification.name}}</div>
                <div class="ml-3" style="cursor: pointer" data-toggle="modal" :data-target="'#editUserQualificationModal-'+userQualification.id">
                    <i class="fas fa-pen"/>
                </div>
                <div class="ml-2" style="cursor: pointer" data-toggle="modal" :data-target="'#delete-qualification-modal-'+userQualification.id">
                    <i class="fas fa-trash-alt text-danger"/>
                </div>
                <view-user-qualification-modal :qualification="userQualification" />
                <edit-user-qualifications-modal :userQualification="userQualification" @updated="updateQualification"/>
                <generic-delete-modal 
                    :id="'delete-qualification-modal-'+userQualification.id" 
                    :boldItem="true"
                    :item="userQualification.name"
                    :itemDeletedReturnValue="userQualification.id"
                    text="Are you sure you want to delete the following qualification: "
                    title="Delete qualification"
                    :url="'/user-qualifications/'+userQualification.id"
                    @itemDeleted="removeQualification"
                />
            </div>
            <h6 v-if="userQualifications.length === 0" class="text-13 text-dark-gray semi-bold">{{$t('user-qualifications.no-quals')}}</h6>
        </div>
        <div class="col-12 mt-2 justify-content-center w-100" v-else>
            <loading height="100%" />
        </div>
    </div>
</template>
<script>
import GenericDeleteModal from '../../GenericDeleteModal.vue';
import Loading from '../../Loading.vue';
import CreateUserQualificationModal from './CreateUserQualificationModal.vue';
import EditUserQualificationsModal from './EditUserQualificationsModal.vue';
import ViewUserQualificationModal from './ViewUserQualificationModal.vue';
export default {
    components: { 
        Loading, 
        CreateUserQualificationModal, 
        GenericDeleteModal, 
        ViewUserQualificationModal,
        EditUserQualificationsModal 
    },
    name: 'UserQualificationList',
    created() {
        if(this.$store.state.user.userQualifications) {
            this.userQualifications = this.$store.state.user.userQualifications;
        }
        this.axios.get('/user-qualifications')
            .then(resp => this.userQualifications = resp.data.user_qualifications)
            .catch(e => console.log(e))
            .finally(() => this.loading = false)
    },
    data() {
        return {
            userQualifications: null,
            loading: true
        }
    },
    methods: {
        addQualification(qualification) {
            this.userQualifications.push(qualification)
        },
        updateQualification(qualification) {
            const idx = this.userQualifications.findIndex(q => q.id == qualification.id)
            if(idx !== -1) {
                this.userQualifications[idx] = qualification
            }
        },
        removeQualification(qualificationId) {
            const idx = this.userQualifications.findIndex(q => q.id == qualificationId)
            if(idx !== -1) {
                this.userQualifications.splice(idx, 1)
            }
        }
    }
}
</script>