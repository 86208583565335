<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({ name: 'Home' })">{{
                  $t('glossary.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
              </div>
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({ name: 'MyOrders' })">{{
                  $t('my-orders.my-orders') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('order.order2') }} {{ order.invoice_id }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div class="container mt-4">
      <div class="row">
        <div class="col-12 mb-4">
          <h3 class="text-title-gray"><strong>{{ $t('order.order1') }} {{ order.invoice_id }}</strong></h3>
          <h6 class="mt-2 text-title-gray">{{ $t('my-orders.condition') }}: <strong :class="getStatusColor()">{{
            $t('status.' + order.status) }}</strong></h6>
          <div v-if="order.currency != 'EUR'" class="text-16 text-title-gray weight-500 mt-1">
            {{ $t('my-orders.exchange-rate') }} <strong>{{ huFormat(1, 'EUR') }}</strong> =
            <strong>{{ huFormat(order.exchange_rate, order.currency) }}</strong>
          </div>
        </div>
        <div id="cart-items" class="col-lg-8 col-12 d-flex flex-column">
          <div v-for="item in order.items" :key="item.id" class="cart-item-container">
            <div class="cart-item">
              <div class="cart-item-image">
                <img style="width: 100%;"
                  :src="item.multimedia ? $store.getters.getStorageURL(item.multimedia.path) : '/assets/img/demo/training.png'" />
              </div>
              <div class="cart-item-content pb-2 pt-2">
                <div>
                  <div style="font-weight: 600; color: white; font-size: 18px;">{{ item.title }}</div>
                  <div style="font-weight: 400; color: white; font-size: 14px;">
                    {{
                      (item.orderable_type === 'App\\Models\\Course' && item.orderable.course_type) ?
                      $t(`course-types.${item.orderable.course_type.name}`) : 'Membership fee'
                    }}
                  </div>
                </div>
                <div v-if="item.orderable_type === 'App\\Models\\Course'">
                  <div style="font-weight: 400; color: white; font-size: 11px;">
                    {{ $t('course.begin') }}:
                    <strong>
                      {{
                        item.orderable.start_date
                        ? $store.getters.parseDateTime(item.orderable.start_date)
                        : $t('course.no')
                      }}
                    </strong>
                  </div>
                  <div style="font-weight: 400; color: white; font-size: 11px;">
                    {{ $t('course.end') }}:
                    <strong>
                      {{
                        item.orderable.end_date
                        ? $store.getters.parseDateTime(item.orderable.end_date)
                        : $t('course.no')
                      }}
                    </strong>
                  </div>
                </div>
                <div>
                  <div style="font-weight: 600; color: white; font-size: 20px;">
                    {{ huFormat(item.gross_price * order.exchange_rate) }}
                  </div>
                  <div style="font-weight: 400; color: white; font-size: 11px;">
                    <span>{{ huFormat(item.net_price * order.exchange_rate) }} + {{ item.vat_rate }}% {{
                      $t('cart.vat')
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-12 order-summary-container">
          <div class="order-summary">
            <div class="d-flex justify-content-between">
              <div style="font-weight: 600; color: #757575; font-size: 20px;">
                {{ $t('cart.netto-total') }}
              </div>
              <div style="font-weight: 600; font-size: 20px;">
                {{ huFormat(netPrice) }}
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div style="font-weight: 600; color: #757575; font-size: 20px;">
                {{ $t('checkout-summary.vat') }}
              </div>
              <div style="font-weight: 600; font-size: 20px;">
                {{ huFormat(vatSum) }}
              </div>
            </div>
            <div v-if="order.coupon" class="d-flex justify-content-between">
              <div style="font-weight: 600; color: #757575; font-size: 20px;">
                {{ $t('order.coupon') }}
              </div>
              <div style="font-weight: 600; font-size: 20px;">
                -{{ huFormat(discount) }}
              </div>
            </div>
            <div style="border: 1px dashed #DADADA;" class="mt-2 mb-2"></div>
            <div class="d-flex justify-content-between">
              <div style="font-weight: 600; color: #757575; font-size: 20px;">
                {{ $t('cart.gross-total') }}
              </div>
              <div style="font-weight: 600; font-size: 20px;">
                {{ huFormat(grossPrice) }}
              </div>
            </div>
            <div style="border: 1px dashed #DADADA;" class="mt-2 mb-2"></div>
            <div v-if="orderInProgress" class="mb-2">
              <p>{{ $t('cart.msg') }}</p>
              <p>{{ $t('cart.click') }} <a :href="orderInProgress.checkout_url" target="_blank"
                  style="text-decoration: underline; color: var(--black)"> {{ $t('cart.here') }}</a> {{
                    $t('cart.continue')
                  }}</p>
            </div>
            <div v-if="order.invoice_id" class="d-flex justify-content-center">
              <button type="button" :disabled="pdfLoading" class="btn btn-theme mb-2 w-100"
                @click.prevent="downloadInvoice(order.invoice_id)"
                style="color: white; font-weight: 600; font-size: 16px;">
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-arrow-circle-down mr-2"></i>
                  <span>{{ $t('order.download') }}</span>
                </div>
              </button>
            </div>
            <div v-else-if="order.status == 'Succeeded'">
              <p v-show="errorMsg" class="text-danger">{{ errorMsg }}</p>
              <button type="button" :disabled="loading" class="btn btn-theme mb-2 w-100"
                @click.prevent="createInvoice(order)" style="color: white; font-weight: 600; font-size: 16px;">
                <div class="d-flex align-items-center justify-content-center">
                  <span v-if="!loading">{{ $t('order.create') }}</span>
                  <span v-else>{{ $t('order.in-progress') }}</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Capacitor } from '@capacitor/core';

export default {
  name: 'Order',
  data() {
    return {
      pdfLoading: false,
      loading: false,
      errorMsg: null,
    }
  },
  computed: {
    orderInProgress() {
      return this.order.status == 'Prepared' || this.order.status == 'Started' || this.order.status == 'InProgress'
    },
    order() {
      return this.$store.state.user ? this.$store.state.user.orders.find(o => o.id == this.$route.params.order) : {}
    },
    netPrice() {
      return this.order.items.reduce((acc, val) => acc + val.net_price * this.order.exchange_rate, 0)
    },
    discount() {
      if (this.order.coupon) {
        const discount = this.order.coupon.percentage_based ? this.netPrice * (this.order.coupon.discount / 100) : this.order.coupon.discount * this.order.exchange_rate
        return discount > this.netPrice ? this.netPrice : discount
      }
      return 0
    },
    vatSum() {
      return this.order.items.reduce((acc, item) => acc + (item.gross_price - item.net_price) * this.order.exchange_rate, 0)
    },
    grossPrice() {
      return this.netPrice + this.vatSum - this.discount
    },
  },
  methods: {
    huFormat(price, currency = this.order.currency) {
      return new Intl.NumberFormat(this.$store.getters.isoLocale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0
      }).format(price)
    },
    getStatusColor() {
      if (this.order.status == 'Prepared' || this.order.status == 'Started' || this.order.status == 'InProgress') {
        return 'text-primary';
      } else if (this.order.status == 'Canceled' || this.order.status == 'Expired') {
        return 'text-danger'
      } else if (this.order.status == 'Succeeded') {
        return 'text-success'
      }
    },
    //TODO: fix this invoice download function
    downloadPDF() {
      this.pdfLoading = true;
      this.axios.get('/invoice/' + this.order.id, { responseType: 'blob' })
        .then(resp => {
          const name = this.order.invoice_id + '.pdf'
          if (Capacitor.isNative) {
            this.$store.commit('downloadFileMobile', { name, mime: 'application/pdf', data: resp.data })
          } else {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch(e => console.log(e.response))
        .finally(() => this.pdfLoading = false)
    },
    downloadInvoice(dockEntry) {
      this.downloading = true;
      this.axios.get('/invoice/' + dockEntry + '/doc-entry')
        .then(resp => {
          window.open(resp.data.invoice.preview_pdf, '_blank');
          this.downloading = false;
        })
        .finally(() => this.downloading = false);
    },
    createInvoice(order) {
      console.log("create invoice");
      this.errorMsg = null;
      this.loading = true;
      this.axios.post('/order/' + order.id + '/invoice')
        .then(resp => {
          let index = this.$store.state.user.orders.findIndex(o => o.id == this.$route.params.order);
          this.$store.state.user.orders[index] = resp.data.order;
          this.loading = false;
        }).catch(err => {
          this.errorMsg = err.response.data.message;
          // let index = this.$store.state.user.orders.findIndex(o => o.id == this.$route.params.order);
          //  this.$store.state.user.orders[index] = err.response.data.order;
        })
        .finally(() => this.loading = false);
    }
  }
}
</script>
<style scoped>
hr {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  margin-left: 15px !important;
  margin-right: 15px !important;
  background-color: #c6c6c6;
  height: 1px;
}
</style>
