<template>
<div>
     <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
            <div class="breadcrumb bg-dark-gray">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="flex-wrap d-flex">
                                <div class="d-flex mr-4 pointer">
                                    <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-courses.homepage') }}</span>
                                </div>
                                <div class="d-flex mr-4">
                                    <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                                </div>
                                <div class="d-flex">
                                    <span class="text-16 text-white pt-3 pb-3">{{ $t('verify-email.verify-email') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- //BREADCRUMB -->
    <div style="display:flex; height: 50vh; justify-content: center; align-items: center; flex-wrap: wrap; ">
    <form class="form-signin text-center" @submit.prevent="verifyEmail" style="max-width: 400px">
      <h4 class="mt-2 mb-5 font-weight-normal" style="word-wrap: break-word; min-width: 0;">{{ $t('verify-email.write-code') }}</h4>
      <label for="inputCode" class="sr-only">{{ $t('verify-email.code') }}</label>
      <input type="number" id="inputEmail" class="form-control" :placeholder="$t('verify-email.confirmation-code')" required v-model="verificationCode" autofocus>
      <button class="mt-4 mb-4 btn btn-secondary w-100" style="background-color: var(--black); color: white" type="submit" :disabled="loading">{{ $t('verify-email.verify') }}</button>
      <a href="#" @click.prevent="resendCode">{{ $t('verify-email.new-code') }}</a>
    </form>
    </div>
</div>
</template>

<script>
export default {
    name: 'VerifyEmail',
    mounted() {
        this.verificationCode = this.$route.query.code ? atob(decodeURI(this.$route.query.code)) : null
    },
    data() {
        return {
            verificationCode: null,
            loading: false
        }
    },
    watch: {
        $route(to, from) {
            if(to.name == 'VerifyEmail' && from.name == 'VerifyEmail') {
                this.verificationCode = this.$route.query.code ? atob(decodeURI(this.$route.query.code)) : null
            }
        }
    },
    methods: {
        verifyEmail() {
            this.loading = true;
            this.axios.post('/verify-email', {verificationCode: this.verificationCode})
                .then(resp => {
                    this.$store.state.user.email_verified_at = resp.data.email_verified_at
                    this.$router.push({name: 'Home'})
                })
                .catch(err => console.log(err.response))
                .finally(() => this.loading = false)
        },
        resendCode() {
            this.loading = true;
            this.axios.post('/new-verification')
                .then(resp => {
                    console.log('New verification sent')
                })
                .catch(err => console.log(err.response))
                .finally(() => this.loading = false)
        }
    }
}
</script>
