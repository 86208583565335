<template>
    <canvas height="150" id="courseFeedback"></canvas>
</template>
<script>
export default {
    name: 'CourseFeedbackChart',
    props: {
        filledCount: Number,
        notFilledCount: Number
    },
    mounted() {
        console.log(this.filledCount)
        const ctx = $('#courseFeedback')
        this.chart = new Chart(ctx, {
            type: 'pie',
            data: {
                labels: [this.$t('chart.not-filled'), this.$t('chart.filled')],
                datasets: [
                    {
                        data: [this.notFilledCount, this.filledCount],
                        backgroundColor: ['rgb(220, 53, 69, 1)', 'rgb(40, 167, 69, 1)'],
                    }
                ]
            }
        });
    },
    data() {
        return {
            chart: null
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale
        }
    },
    watch: {
        locale() {
            if(this.chart) {
                this.chart.data.labels = [this.$t('chart.not-filled'), this.$t('chart.filled')]
                this.chart.update()
            }
        },
    }
}
</script>