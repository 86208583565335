<template>
<div v-if="!element.course_event.loading">
    <div v-if="element.course_event.zoom_meeting_id === null" class="row ml-1">
        <div class="col-12 mt-2">
            <h5><strong>{{ $t('course-event.name') }}</strong></h5>
            <span>{{element.course_event.name}}</span>
        </div>
        <div class="col-12 mt-3">
            <h5><strong>{{ $t('course-event.location') }}</strong></h5>
            <span>{{`${element.course_event.location.zip_code}, ${element.course_event.location.locality}, ${element.course_event.location.public_space} ${element.course_event.location.public_space_type}, ${element.course_event.location.other}, ${element.course_event.location.country.name}`}}</span>
        </div>
        <div class="col-12 mt-3">
            <h5><strong>{{$t('course-event.start-date')}}</strong></h5>
            <span>{{$store.getters.parseDateTime(new Date(element.course_event.start_date).toISOString())}}</span>
        </div>
        <div class="col-12 mt-3">
            <h5><strong>{{$t('course-event.length')}}</strong></h5>
            <span>{{element.course_event.length}} {{$t('time-unit.minute')}}</span>
        </div>
        <div class="col-12 mt-3">
            <h5><strong>{{$t('course-event.add-to-calendar')}}</strong></h5>
            <div class="d-inline-flex align-items-center mt-1">
                <button type="button" :disabled="calendarLoading" class="btn btn-outline-danger mb-2" @click.prevent="downloadCalendar(element)">
                    <div class="d-flex align-items-center">
                        <i class="far fa-calendar-alt mr-1"></i>
                        <span>{{$t('course-event.download-icalendar')}}</span>
                    </div>
                </button>
                <div v-if="calendarLoading" class="spinner-border text-danger ml-2 mb-2" role="status">
                    <span class="sr-only">{{$t('common.loading')}}</span>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="element.course_event.zoom_meeting_id !== null" class="row ml-1">
        <div class="col-12 mt-2">
            <h5><strong>{{$t('course-event.name')}}</strong></h5>
            <span>{{element.course_event.topic}}</span>
        </div>
        <div class="col-12 mt-3">
            <h5><strong>{{$t('course-event.start-date')}}</strong></h5>
            <span>{{$store.getters.parseDateTime(element.course_event.start_time)}}</span>
        </div>
        <div class="col-12 mt-3">
            <h5><strong>{{$t('course-event.length')}}</strong></h5>
            <span v-if="element.course_event.duration != element.course_event.length" style="color: red">
                <i class="fas fa-exclamation-circle" style="color: red"></i>
                <span>{{$t('course-event.length-diff-warning')}}</span>
                <br>
                <span>{{$t('course-event.length-from-zoom-api')}}: {{element.course_event.duration}}</span>
                <br>
                <span>{{$t('course-event.length-from-database')}}: {{element.course_event.length}}</span>
            </span>
            <span v-else>{{element.course_event.duration}} {{$t('time-unit.minute')}}</span>
        </div>
        <div class="col-12 mt-3">
            <h5><strong>{{$t('course-event.connect-zoom')}}</strong></h5>
            <a :href="element.course_event.join_url" target="_blank" style="color: var(--black)">{{$t('course-event.click-to-join')}}</a>
        </div>
        <div class="col-12 mt-3" v-if="$store.state.user.role === 'Admin'">
            <h5><strong>{{$t('course-event.get-started-zoom')}}</strong></h5>
            <a :href="element.course_event.start_url" target="_blank" style="color: var(--black)">{{$t('course-event.start-zoom-event')}}</a>
        </div>
        <div class="col-12 mt-3">
            <h5><strong>{{$t('course-event.add-to-calendar')}}</strong></h5>
            <div class="d-inline-flex align-items-center mt-1">
                <button type="button" :disabled="calendarLoading" class="btn btn-outline-danger mb-2" @click.prevent="downloadCalendar(element)">
                    <div class="d-flex align-items-center">
                        <i class="far fa-calendar-alt mr-1"></i>
                        <span>{{$t('course-event.download-icalendar')}}</span>
                    </div>
                </button>
                <div v-if="calendarLoading" class="spinner-border text-danger ml-2 mb-2" role="status">
                    <span class="sr-only">{{$t('common.loading')}}</span>
                </div>
            </div>
        </div>
        <div class="col-12 mt-3 mb-3">
            <h5><strong>{{$t('course-event.opening')}}</strong></h5>
            <a v-if="element.course_event.zoom_meeting_id !== null" type="button" style="color: white" :href="frontendURL+'/meeting/'+element.course_event.id" :disabled="locationLoading" class="btn btn-primary">{{$t('course-event.open-web')}}</a>
        </div>
    </div>
</div>
<loading v-else height="500px" />
</template>
<script>
import { Capacitor } from '@capacitor/core';
import Loading from '../Loading.vue';
export default {
  components: { Loading },
    name: 'EventElement',
    props: {
        element: Object,
    },
    data() {
        return {
            calendarLoading: false,
            frontendURL: process.env.VUE_APP_FRONTEND_URL,
        }
    },
    methods: {
        downloadCalendar() {
            this.calendarLoading = true;
            const url = '/courses/'+this.element.course_id+'/events/'+this.element.course_event_id+'/calendar'
            this.axios.get(url, {responseType: 'blob'})
                .then(resp => {
                    const name = `${this.element.course_event.name}.ics`
                    if(Capacitor.isNative) {
                        this.$store.commit('downloadFileMobile', {name, mime: 'text/calendar', data: resp.data})
                    }
                    else {
                        const url = window.URL.createObjectURL(new Blob([resp.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', name);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
                .catch(e => console.log(e.response))
                .finally(() => this.calendarLoading = false)
        },
    }
}
</script>
