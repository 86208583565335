<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('my-courses.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{$t("common.course-groups")}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->
        <div id="content-2" v-if="!loading && courseGroups">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3">
                        <section id="new-group">
                            <div class="row">
                                <div class="col-12">
                                    <div class="flex-wrap d-flex">
                                        <div class="d-flex align-items-center pointer mr-auto" @click.prevent="$router.push({name: 'CreateCourseGroup'})">
                                            <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                <img src="assets/img/icons/plus.svg" class="img-fluid" alt="">
                                            </div>
                                            <div class="d-flex flex-column ml-2">
                                                <h6 class="text-16 text-dark-gray">{{$t("course-groups.create-group")}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div class="col-lg-9">
                        <section id="training-group">
                            <div class="row">
                                <!-- <div class="col-12">
                                    <h6 class="text-13 text-dark-gray semi-bold">No data placeholder</h6>
                                </div> -->
                                <!-- TRAINING GROUP CARD -->
                                <div class="col-12 col-sm-6 big-group" v-for="group in courseGroups" :key="group.id">
                                    <div class="training-card group filter-shadow">
                                        <div class="training-image pointer" v-bind:style="{backgroundImage: `url(${group.image ? $store.getters.getStorageURL(group.image.path) : 'assets/img/demo/training.png'})`}"></div>
                                        <div class="training-card-bottom bg-light-gray-secondary fix-height">
                                            <div class="row">
                                                <div class="col-md-10 col-9 pointer">
                                                    <h4 class="text-14 regular text-white">{{group.title}}</h4>
                                                </div>
                                                <div class="col-md-2 col-3">
                                                    <img @click.prevent="$router.push({name: 'EditCourseGroup', params: {group: group.id}})" src="assets/img/icons/pen.svg" class="pointer" alt="">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12" v-if="courseGroups.length == 0">
                                    <h6 class="text-13 text-dark-gray semi-bold">There are no course groups.</h6>
                                </div>
                                <!-- //TRAINING GROUP CARD -->
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loading from '@/components/Loading'
export default {
    name: 'CourseManagement',
    components: {
        Loading,
    },
    mounted() {
        this.loading = true;
        this.axios.get('/course-groups')
            .then(resp => this.courseGroups = resp.data.course_groups)
            .finally(() => this.loading = false)
    },
    data() {
        return {
            courseGroups: null,
            loading: false
        }
    }

}
</script>
<style scoped>
    #new-group,
    #training-group {
        margin-top: 30px;
    }
</style>
