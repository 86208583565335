<template>
    <div>
        <div class="row mt-4" v-if="!loading">
            <div class="col-md-6 col-12 mb-3">
                <h4 class="mb-4">{{ $t('personal-analytics.time') }}</h4>
                <time-chart :data="timeTrackingDataset" :dateTime="false" :safeMode="true"  :defaultFrom="DateTime.local().minus({weeks: 1}).startOf('day').toISO()" :disabledUnits="Array('minute')">
                    <template v-slot="{data}">
                        <AppTimeTrackingChart :data="data" />
                    </template>
                </time-chart>
            </div>
            <div class="col-md-6 col-12 mb-3">
                <h4 class="mb-4">{{ $t('personal-analytics.activity') }}</h4>
                <time-chart :data="activityDataset" :dateTime="false" :safeMode="true"  :defaultFrom="DateTime.local().minus({months: 1}).startOf('day').toISO()">
                    <template v-slot="{data}">
                        <ActivityChart :data="data" />
                    </template>
                </time-chart>
            </div>
        </div>
        <loading v-else />
    </div>
</template>
<script>
import ActivityChart from '@/components/Charts/ActivityChart'
import TimeChart from '@/components/Charts/TimeChart'
import AppTimeTrackingChart from '@/components/Charts/AppTimeTrackingChart'
import {DateTime} from 'luxon'
import Loading from '../components/Loading.vue'
export default {
    name: 'PersonalAnalytics',
    components: {
        AppTimeTrackingChart,
        TimeChart,
        ActivityChart,
        Loading
    },
    mounted() {
        const path = this.$route.params.user ? `/reports/user/${this.$route.params.user}` : '/reports/user'
        this.axios.get(path)
            .then(resp => {
                this.activityDataset = Array(resp.data.activities)
                let timeTracking = []
                resp.data.timeTrackings.forEach(t => {
                        let startDate = DateTime.fromISO(t.from, {locale: this.$store.state.locale}).toLocal()
                        const endDate = DateTime.fromISO(t.to, {locale: this.$store.state.locale}).toLocal()
                        while(startDate <= endDate) {
                            timeTracking.push(startDate.toISO())
                            startDate = startDate.plus({minute: 1})
                        }
                })
                this.timeTrackingDataset = Array(timeTracking)
            
            })
            .catch()
            .finally(() => this.loading = false)
    },
    data() {
        return {
            startFinishDataset: null,
            activityDataset: null,
            timeTrackingDataset: null,
            DateTime: DateTime,
            loading: true
        }
    }
}
</script>
