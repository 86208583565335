<template>
    <div class="modal fade" :id="'deleteQuestion'+question.id+'Modal'" tabindex="-1" role="dialog" :aria-labelledby="'deleteQuestion'+question.id+'ModalLabel'" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header delete-modal-header" style="background-color: #dc3545 !important">
                <h5 class="modal-title text-white" :id="'deleteQuestion'+question.id+'ModalLabel'">{{ $t('test-question-assembler.delete-sure') }}</h5>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    {{ $t('test-question-assembler.delete-question') }}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="deleteQuestion(question)">{{ $t('common.delete') }}</button>
                    <button type="button" class="btn btn-primary" data-dismiss="modal">{{ $t('test-question-assembler.cancel') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'DeleteQuestionModal',
    props: {
        question: Object,
        test: Object
    },
    methods: {
        deleteQuestion(question) {
            question.loading = true
            this.axios.delete('/tests/'+this.test.id+'/questions/'+question.id)
                .then(resp => {
                    this.test.test_questions.splice(this.test.test_questions.findIndex((q) => q.id == question.id), 1)
                })
                .finally(() => question.loading = false)
        },
    }
}
</script>