<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-courses.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">Temporary certificates</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->

        <!-- <div class="p-0 col-12 mb-0">
            <h2 class="breadcrumb-element w-100">{{ $t('manage-courses.managing-courses') }}</h2>
        </div> -->
        <create-temporary-certificate-modal @temporaryCertificateCreated="temporaryCertificates.unshift($event)" />

        <div class="container mt-4">
            <section v-if="!loading && temporaryCertificates" section id="list-table" class="table-responsive" style="height: 100% !important;">
                <div class="d-flex flex-wrap align-items-center justify-content-between">
                    <div class="d-flex">
                        <h3 class="mb-3 mt-3 text-22 text-title-gray weight-700">Temporary Certificates </h3>
                    </div>
                    <div class="d-flex">
                        <button class="btn btn-outline-primary mt-3 mb-3 mr-2" data-toggle="modal" data-target="#newTemporaryCertificateModal">Create new temporary certificate</button>
                    </div>
                </div>
                <div class="new-table filter-shadow" style="height: 100% !important;">
                    <table class="table borderless-table">
                        <thead class="bg-light-gray-primary">
                            <tr>
                                <th>Type</th>
                                <th>Recipient name</th>
                                <th>Certificate number</th>
                                <th>Issued at</th>
                                <th>Valid Until</th>
                                <th>Issued by</th>
                                <th>Download</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="cert in temporaryCertificates" :key="cert.id">
                                <td>{{cert.certificate_type}}</td>
                                <td>{{cert.recipient_name ?? 'Not specified'}} </td>
                                <td>{{cert.public_certificate_number}}</td>
                                <td>{{$store.getters.parseDateTime(cert.created_at)}}</td>
                                <td>{{DateTime.fromISO(cert.valid_until, {zone: 'utc'}).toLocaleString(DateTime.DATE_MED)}}</td>
                                <td>{{cert.creator.name}}</td>
                                <td><generic-document-download :name="cert.path.split('/')[cert.path.split('/').length - 1]" :link="`/temporary-certificates/${cert.id}/download`" :pdf="true" text="Download" /></td>
                                <td align="center"><a @click="deleteTemporaryCertificate(cert.id)" style="color: var(--red) !important; cursor: pointer"><i class="fa fa-trash" aria-hidden="true"></i></a></td>
                            </tr>
                            <tr v-if="temporaryCertificates.length == 0">
                                <td colspan="5" class="text-center">
                                    There are no temporary certificates yet
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
            <div v-else>
                <Loading height="80vh" />
            </div>
        </div>
    </div>
</template>
<script>
import {DateTime} from 'luxon'
import Loading from '@/components/Loading'
import RecommendedCoursesModal from '../components/RecommendedCoursesModal.vue'
import GenericDocumentDownload from '../components/GenericDocumentDownload.vue';
import CreateTemporaryCertificateModal from '../components/CreateTemporaryCertificateModal.vue';
export default {
    name: 'CourseManagement',
    components: {
        Loading,
        RecommendedCoursesModal,
        GenericDocumentDownload,
        CreateTemporaryCertificateModal
    },
    mounted() {
        this.loading = true;
        this.axios.get('/temporary-certificates')
            .then(resp => this.temporaryCertificates = resp.data.temporary_certificates)
            .finally(() => this.loading = false)
    },
    data() {
        return {
            temporaryCertificates: null,
            loading: false,
            DateTime: DateTime
        }
    },
  methods:{
      deleteTemporaryCertificate(id) {
      if (prompt('Are you sure you want to delete this temporary certificate? Type "DELETE" to confirm.') == 'DELETE') {
        this.axios.delete(`/temporary-certificates/${id}`)
          .then(() => {
            this.temporaryCertificates = this.temporaryCertificates.filter(cert => cert.id != id);
            this.$toast.success('Temporary certificate deleted successfully');
          })
          .catch(err => {
            this.$toast.error(err.response.data.message);
          });
      }
      }
  }
}
</script>
<style scoped>
    .btn-outline-primary:hover {
        background-color: var(--red) !important;
        border-color: var(--red) !important;
    }
</style>
