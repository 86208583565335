<template>
<div class="pl-2 pr-2 pt-4 pb-4 d-flex justify-content-center">
    <div v-show="!loading">
        <div v-if="element.course_multimedia.multimedia.type == 'video'">
            <video @loadedmetadata="loading = false" :id="'cm'+element.id" :src="$store.getters.getStorageURL(element.course_multimedia.multimedia.path)" :ref="'cm'+element.id" controls controlsList="nodownload" style="max-width: 100%">
                <source :src="$store.getters.getStorageURL(element.course_multimedia.multimedia.path)">
            </video>
        </div>
        <div v-if="element.course_multimedia.multimedia.type == 'audio'">
            <audio @loadedmetadata="loading = false" :id="'cm'+element.id" :ref="'cm'+element.id" controls controlsList="nodownload" style="max-width: 100%">
                <source :src="$store.getters.getStorageURL(element.course_multimedia.multimedia.path)">
            </audio>
        </div>
    </div>
    <loading v-if="loading" height="500px" />
</div>
</template>
<script>
import Loading from '../Loading.vue';
export default {
  components: { Loading },
    emits: ['progressMade'],
    name: 'AudioVideoElement',
    props: {
        element: Object
    },
    mounted() {
        const prevCurr = (this.userProgress && this.userProgress.progress < this.element.course_multimedia.length) ? this.userProgress.progress : 0
        this.timeTracking = {
            watchedTime: this.userProgress ? this.userProgress.progress : 0,
            prevTime: prevCurr,
            currentTime: prevCurr,
            progressMade: 0
        },
        this.$refs['cm'+this.element.id].currentTime = this.timeTracking.currentTime
        this.$refs['cm'+this.element.id].addEventListener('timeupdate', this.onTimeUpdate);
        this.$refs['cm'+this.element.id].addEventListener('seeking', this.onSeeking);
        this.$refs['cm'+this.element.id].addEventListener('ended', this.onEnded);
    },
    beforeUnmount() {
        this.$refs['cm'+this.element.id].removeEventListener('timeupdate', this.onTimeUpdate);
        this.$refs['cm'+this.element.id].removeEventListener('seeking', this.onSeeking);
        this.$refs['cm'+this.element.id].removeEventListener('ended', this.onEnded);
    },
    data() {
        return {
            userProgress: this.element.course_material_progress[0],
            timeTracking: null,
            lastUpdated: 'currentTime',
            loading: true,
            localProgress: this.element.course_material_progress.length == 0 ? 0 : this.element.course_material_progress[0].progress
        }
    },
    watch: {
        element: {
            handler(el, prev) {
                if(el.course_material_progress.length == 0 && prev.course_material_progress.length > 0){
                    this.timeTracking = {
                        watchedTime: 0,
                        prevTime: 0,
                        currentTime: 0,
                        progressMade: 0
                    }
                }
                this.localProgress = this.element.course_material_progress.length == 0 ? 0 : this.element.course_material_progress[0].progress
            },
            deep: true
        }
    },
    methods: {
        makeProgress(unlockNext = false) {
            if(this.element.course_material_progress.length == 0 || this.element.course_multimedia.length > this.element.course_material_progress[0].progress) {
                this.$emit('progressMade', this.element.course_multimedia_id, unlockNext)
               //console.log('Making progress!')
            }
        },
        onTimeUpdate() {
            //console.log(this.$refs['cm'+this.element.id].currentTime)
            if (!this.$refs['cm'+this.element.id].seeking) {
                if (this.$refs['cm'+this.element.id].currentTime > this.timeTracking.watchedTime) {
                    this.timeTracking.progressMade += this.$refs['cm'+this.element.id].currentTime - this.timeTracking.prevTime
                    this.timeTracking.prevTime = this.$refs['cm'+this.element.id].currentTime;
                    //console.log(this.timeTracking.watchedTime - this.localProgress,this.timeTracking.watchedTime,this.localProgress)
                    if(this.timeTracking.watchedTime - this.localProgress >= 10) {
                        this.localProgress += this.timeTracking.watchedTime - this.localProgress;
                        this.makeProgress(this.localProgress == this.element.course_multimedia.length)
                        //console.log('Db progress:', this.element.course_material_progress.length == 0 ? 0 : this.element.course_material_progress[0].progress)
                        //console.log('Current progress: ', this.localProgress)
                        //console.log('Current watch time: ', this.timeTracking.watchedTime)
                        //console.log('10 second progress made!')
                    }
                    this.timeTracking.watchedTime = this.$refs['cm'+this.element.id].currentTime;
                    this.lastUpdated = 'watchedTime';
                }
                //tracking time updated  after user rewinds
                else {
                    this.timeTracking.currentTime = this.$refs['cm'+this.element.id].currentTime;
                    this.lastUpdated = 'currentTime';
                }
            }
        },
        onSeeking() {
            // guard against infinite recursion:
            //this.$refs['cm'+this.element.id].pause()
            // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
            var delta = this.$refs['cm'+this.element.id].currentTime - this.timeTracking.watchedTime;
            //console.log('delta: ', delta, this.element.course_multimedia.rewind);
            if (delta > 0 && !this.element.course_multimedia.rewind) {
                //if(!this.isTimeout) {
                    //   this.isTimeout = true;
                //    this.$store.state.toastNotifications.push({status: 'Error', message: 'A videó még meg nem nézett részéhez tekerés nem engedélyezett'})
                //     setTimeout(() => {this.isTimeout = false}, 1000)
                //}
                //this.$refs['cm'+this.element.id].pause()
                //play back from where the user started seeking after rewind or without rewind
                this.$refs['cm'+this.element.id].currentTime = this.timeTracking[this.lastUpdated];
            }
        },
        onEnded() {
            if(this.localProgress == this.element.course_multimedia.length) return;
            const factor = Math.ceil((this.timeTracking.watchedTime - this.localProgress) / 10)
            //console.log(factor, this.timeTracking.watchedTime, this.localProgress)
            this.makeProgress(factor == 1)
            for(let i = 0; i < factor - 1; i++) {
                setTimeout(() => {
                    this.makeProgress(i == (factor - 2))
                }, 100);
            }
            //console.log('Db progress:', this.element.course_material_progress.length == 0 ? 0 : this.element.course_material_progress[0].progress)
            //console.log('Current progress: ', this.localProgress)
            //console.log('Current watch time: ', this.timeTracking.watchedTime)
            //console.log('Finished media progress made')
        },
    }
}
</script>
