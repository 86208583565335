<template>
  <div id="other-information">
    <div class="d-flex align-items-center justify-content-center">
      <img src="@/assets/img/settings/languages.png" alt="" />
    </div>

    <div class="d-flex align-items-center justify-content-between my-4">
      <h1>{{ $t("settings.other-information") }}</h1>
      <div class="add-button-section">
        <p class="m-0">{{ $t("other-information.add-new-info") }}</p>
        <IconButton icon="add" class="position-static" :click="addLanguage" />
      </div>
    </div>

    <table>
      <thead>
        <tr>
          <th>
            <span>{{ $t("other-information.lang") }}</span>
          </th>
          <th>
            <span>{{ $t("other-information.level") }}</span>
          </th>
          <th>
            <span>{{ $t("settings.action") }}</span>
          </th>
        </tr>
      </thead>
      <tbody
        v-for="(lang, idx) in otherInformationData.spoken_languages"
        :key="idx"
      >
        <tr>
          <td>
            <input
              class="form-control"
              v-model="lang.language"
              @input="localChanges = true"
              type="text"
              @blur="setOtherInformation"
            />
          </td>
          <td>
            <input
              class="form-control"
              v-model="lang.level"
              @input="localChanges = true"
              type="text"
              @blur="setOtherInformation"
            />
          </td>
          <td>
            <TransparentButton
              data-toggle="modal"
              :data-target="`#delete-language-modal-${lang.id}`"
              icon="delete-red"
              :text="$t('common.delete')"
              :click="() => removeLanguage(idx)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import IconButton from "./IconButton.vue";
import TransparentButton from "@/components/settings/TransparentButton.vue";

export default {
  created() {
    if (this.otherInformationData.spoken_languages === null) {
      const obj = { language: null, level: null };
      const arr = Array();
      arr.push(JSON.parse(JSON.stringify(obj)));
      arr.push(JSON.parse(JSON.stringify(obj)));
      arr.push(JSON.parse(JSON.stringify(obj)));
      this.otherInformationData.spoken_languages = arr;
    }
  },
  components: {
    TransparentButton,
    IconButton,
  },
  data() {
    return {
      loading: false,
      localErrors: {},
      rows: [],
      otherInformationData: this.getOtherInfoData(),
      localChanges: false,
    };
  },
  methods: {
    getOtherInfoData() {
      return this.$store.state.user && this.$store.state.user.other_information
        ? {
            spoken_languages: JSON.parse(
              this.$store.state.user.other_information.spoken_languages
            ),
            translations_needed:
              this.$store.state.user.other_information.translations_needed,
          }
        : { spoken_languages: [], translations_needed: false };
    },
    addLanguage() {
      this.otherInformationData.spoken_languages.push({
        language: null,
        level: null,
      });
    },
    async removeLanguage(idx) {
      this.otherInformationData.spoken_languages.splice(idx, 1);
      this.setOtherInformation();
    },
    setOtherInformation() {
      if (!this.localChanges) return;
      this.loading = true;
      this.axios
        .post(
          "/other-information",
          this.$store.getters.removeEmptyOrNull(this.otherInformationData)
        )
        .then((resp) => {
          this.$store.state.user = resp.data.user;
          this.localChanges = false;
        })
        .catch((e) => (this.localErrors = e.response.data.errors))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
#other-information {
  padding: 40px 25px;
}

h1 {
  font-size: 20px;
  font-weight: 500;
  color: #333e48;
}

.table-with-inputs .head > div {
  background-color: #cecece;
  border: 1px solid #333e48;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-with-inputs .head > div span {
  font-weight: 500;
  font-size: 20px;
  color: #333e48;
}

.table-with-inputs .table-input {
  border: 1px solid #cecece;
  background-color: #fff;
  padding: 15px;
}

.table-with-inputs .remove-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

table tbody tr td .form-control {
  border: none;
  color: #333e48;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

table tbody tr td .form-control:focus {
  text-align: left;
}

.table-with-inputs .table-input .rounded-red {
  margin-top: 0;
  margin-right: 0;
}

/* BUTTONS */
/* START BUTTONS */

button.red-button {
  background-color: #d62b33;
  border-radius: 5px;
  border: 3px solid #d62b33;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  position: absolute;
  justify-content: center;
  height: 38px;
}

button.red-button span {
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-left: 10px;

  color: #ffffff;
}

::v-deep button.red-button .icon svg path {
  fill: #fff !important;
}
button.red-button:hover {
  background-color: var(--dark-red);
  border: 3px solid var(--dark-red);
}

button.secondary-button {
  background-color: transparent;
  border-radius: 5px;
  border: 1.5px solid #d62b33;
  display: flex;
  padding: 5px 20px;
  height: 38px;
}

button.secondary-button span {
  color: #d62b33;
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
}

::v-deep button.secondary-button .icon svg path {
  fill: #d62b33 !important;
}

button.secondary-button:hover {
  background-color: #d62b33;
  border: 1.5px solid #d62b33;
}

button.secondary-button:hover span {
  color: #fff;
}

::v-deep button.secondary-button:hover .icon svg path {
  fill: #fff !important;
}

/* END BUTTONS */

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

thead {
  background-color: #cecece;
  border-radius: 5px;
}

th {
  font-weight: 500;
  font-size: 15px;
  color: #333e48;
  padding: 10px;
  border: solid 1px #333e48;
  border-right: none;
  text-align: center;
}

th:first-of-type {
  border-top-left-radius: 5px;
}

th:last-of-type {
  border-top-right-radius: 5px;
  border-right: solid 1px #333e48;
}

tbody tr {
  border: 1px solid #cecece;
  background-color: #fff;
}

tbody tr:first-of-type {
  border-top: none;
}

tbody tr td {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #928c88;
  border: solid 1px #cecece;
  border-right: none;
  text-align: center;
}

tbody tr td .secondary-button {
  margin: 0 auto;
}

tbody tr td:last-of-type {
  border-right: solid 1px #cecece;
}

.add-button-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-button-section p {
  font-size: 18px;
  font-weight: 500;
  color: #837e7f;
  cursor: pointer;
}
</style>
