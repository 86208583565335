<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('reporting.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Reporting'})">{{ $t('reporting.statistic') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('reporting.search-results') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->
        <div id="content-2">
            <div class="container">
                <!-- RESPONSIVE TABLE -->
                <div class="row">
                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-12 mt-5 mb-3" v-if="words.filter(w => w.count > 0).length > 0">
                                <h2 class="text-18 text-dark-gray semi-bold">{{ $t('reporting.knowledge-searched') }}</h2>
                            </div>
                            <div class="col-12" style="min-height: 50vh" v-if="words.filter(w => w.count > 0).length > 0">
                                <word-cloud id="1"  :data="words.map(w => Array(w.word, w.count))" />
                            </div>
                            <div class="col-12 mt-5 mb-3" v-if="labels.filter(l => l.search_count > 0).length > 0">
                                <h2 class="text-18 text-dark-gray semi-bold">{{ $t('reporting.knowledge-searched') }}</h2>
                            </div>
                            <div class="col-12" style="min-height: 50vh" v-if="labels.filter(l => l.search_count > 0).length > 0">
                                <word-cloud id="2"  :data="labels.filter(l => l.search_count > 0).map(l => Array(l.name, l.search_count))" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="row">
                            <!-- TABLE #1 -->
                            <div class="col-12 mt-2 mb-3">
                                <div class="flex-wrap d-flex">
                                    <div class="d-flex me-auto">
                                        <h2 class="text-18 text-dark-gray semi-bold">{{ $t('reporting.knowledge-searched') }}</h2>
                                    </div>
                                    <div class="d-flex ms-auto">
                                        <generic-document-download
                                            class="mr-2"
                                            text="Letölés PDF formátumban"
                                            :pdf="true"
                                            link="/reports/export/words/pdf"
                                            name="words.pdf"
                                        />
                                        <generic-document-download
                                            text="Letölés XLSX formátumban"
                                            :pdf="false"
                                            link="/reports/export/words/xlsx"
                                            name="words.xlsx"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-4">
                                <div class="new-table filter-shadow">
                                    <table class="table borderless-table">
                                        <thead class="bg-light-gray-primary">
                                            <tr>
                                                <th style="width: 50% !important" :class="'pointer'+' '+(currentSort==='word'?'sorted':'')" @click="sort('word')">{{ $t('reporting.word') }}</th>
                                                <th style="width: 50% !important" :class="'pointer'+' '+(currentSort==='count'?'sorted':'')" @click="sort('count')">{{ $t('reporting.searches') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="word in sortedWords" :key="word.word">
                                                <td style="width: 50% !important">
                                                    {{word.word}}
                                                </td>
                                                <td style="width: 50% !important">{{word.count}} </td>
                                            </tr>

                                            <tr v-if="words.length == 0">
                                                <td colspan="2" class="text-center text-light-black semi-bold pt-4 pb-4">{{ $t('no-data.analytics') }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- TABLE #1 -->
                            <!-- TABLE #2 -->
                            <div class="col-12 mt-5 mb-3">
                                <div class="flex-wrap d-flex">
                                    <div class="d-flex me-auto">
                                        <h2 class="text-18 text-dark-gray semi-bold">{{ $t('reporting.label-clicks') }}</h2>
                                    </div>
                                    <div class="d-flex ms-auto">
                                        <generic-document-download
                                            class="mr-2"
                                            text="Letölés PDF formátumban"
                                            :pdf="true"
                                            link="/reports/export/labels/pdf"
                                            name="labels.pdf"
                                        />
                                        <generic-document-download
                                            text="Letölés XLSX formátumban"
                                            :pdf="false"
                                            link="/reports/export/labels/xlsx"
                                            name="labels.xlsx"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-4">
                                <div class="new-table filter-shadow">
                                    <table class="table borderless-table">
                                        <thead class="bg-light-gray-primary">
                                            <tr>
                                                <th style="width: 50% !important" :class="'pointer'+' '+(currentSort2==='name'?'sorted':'')" @click="sort2('name')">{{ $t('reporting.label') }}</th>
                                                <th style="width: 50% !important" :class="'pointer'+' '+(currentSort2==='search_count'?'sorted':'')" @click="sort2('search_count')">{{ $t('reporting.clicks') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="label in sortedLabels" :key="label.name">
                                                <td style="width: 50% !important">
                                                    {{label.name}}
                                                </td>
                                                <td style="width: 50% !important">{{label.search_count}} </td>
                                            </tr>

                                            <tr v-if="labels.length == 0">
                                                <td colspan="3" class="text-center text-light-black semi-bold pt-4 pb-4">{{ $t('no-data.analytics') }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- TABLE #2 -->
                        </div>
                    </div>
                </div>
                <!-- //RESPONSIVE TABLE -->
            </div>
        </div>
    </div>
</template>
<script>
import Loading from '../components/Loading.vue'
import WordCloud from '../components/Charts/WordCloud.vue'
import GenericDocumentDownload from '../components/GenericDocumentDownload.vue'
export default {
  components: { Loading, WordCloud, GenericDocumentDownload },
    name: 'SearchReport',
    mounted() {
        this.axios.get('/reports/search-results')
            .then(resp => {
                this.words = resp.data.words.data
                this.labels = resp.data.labels.data
            })
            .finally(() => this.loading = false)
    },
    data() {
        return {
            loading: true,
            words: [],
            labels: [],
          currentSort:'word',
          currentSortDir:'asc',
          currentSort2:'name',
          currentSort2Dir:'asc',
        }
    },
  methods: {
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
    sort2: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort2) {
        this.currentSort2Dir = this.currentSort2Dir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort2 = s;
    },
  },
  computed: {
    sortedWords: function () {
      return this.words.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    sortedLabels: function () {
      return this.labels.sort((a, b) => {
        let modifier = 1;
        if (this.currentSort2Dir === 'desc') modifier = -1;
        if (a[this.currentSort2] < b[this.currentSort2]) return -1 * modifier;
        if (a[this.currentSort2] > b[this.currentSort2]) return 1 * modifier;
        return 0;
      });
    }
  }
}
</script>

<style scoped>
.sorted{
  font-weight: normal;
}
</style>
