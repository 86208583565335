<template>
    <div v-if="!loading">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#" @click.prevent="$router.push({name: 'Home'})">{{ $t('edit-course.homepage') }}</a></li>
                <li class="breadcrumb-item"><a href="#" @click.prevent="$router.push({name: 'ManageCourses'})">{{ $t('edit-course.managing-course') }}</a></li>
                <li class="breadcrumb-item" aria-current="page"><a href="#" @click.prevent="$router.push({name: 'EditCourse', params: {course: this.$route.params.course}})">{{ $t('edit-course.edit-course') }}</a></li>
                <li class="breadcrumb-item" aria-current="page"><a href="#" @click.prevent="$router.push({name: 'CourseFeedbackList', params: {course: this.$route.params.course}})">{{ $t('user-course-feedback.rating') }}</a></li>
                <li class="breadcrumb-item active">{{ $t('user-course-feedback.rating') }}</li>
            </ol>
        </nav>
        <div class="row mt-2">
            <div class="col-12 mb-4">
                <h2 class="mb-4">{{ $t('user-course-feedback.rating') }}</h2>
                <p><strong>{{ $t('user-course-feedback.user') }} </strong>{{feedback.user.name}} ({{feedback.user.email}})</p>
                <p><strong>{{ $t('user-course-feedback.date') }} </strong>{{$store.getters.parseDateTime(feedback.created_at)}}</p>
            </div>
            <div v-for="currentQuestion in feedback.course_feedback.course_feedback_questions.filter(q => q.matrix_group === null)" :key="currentQuestion.id" class="col-12 mb-4">
                <div class="col-12 mb-4 p-0">
                    <h4>{{currentQuestion.question}}</h4>
                </div>
                <div class="col-12 mt-2 p-0">
                    <div v-if="currentQuestion.course_feedback_question_type.type == 'Single choice'">
                        <div v-for="answer in currentQuestion.course_feedback_question_answers" :key="answer.id" class="input-group mb-1">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <input type="radio" @click.prevent style="cursor: not-allowed;" :checked="isChecked(answer)" :data-debug="isChecked(answer)" :name="currentQuestion.id" :aria-label="$t('correct-test-attempt.radio')">
                                </div>
                            </div>
                            <div type="text" class="form-control" style="height: fit-content !important;" :aria-label="$t('correct-test-attempt.text-input')">{{answer.answer}}</div>
                        </div>
                    </div>
                    <div v-else-if="currentQuestion.course_feedback_question_type.type == 'Multiple choice'">
                        <div v-for="answer in currentQuestion.course_feedback_question_answers" :key="answer.id" class="input-group mb-1">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <input @click.prevent style="cursor: not-allowed;" type="checkbox" :checked="isChecked(answer)" :aria-label="$t('correct-test-attempt.radio')">
                                </div>
                            </div>
                            <div type="text" class="form-control" style="height: fit-content !important;" :aria-label="$t('correct-test-attempt.text-input')">{{answer.answer}}</div>
                        </div>
                    </div>
                    <div v-else-if="currentQuestion.course_feedback_question_type.type == 'Free text'">
                        <textarea readonly class="form-control" :value="getFreeTextAnswer(currentQuestion)" :aria-label="$t('correct-test-attempt.txt-area')"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-12 mb-4" v-if="feedback.course_feedback.course_feedback_questions.filter(q => q.matrix_group !== null).length > 0">
                <div class="col-12 mt-2 p-0">
                    <h4>{{ $t('user-course-feedback.question') }}</h4>
                    <div class="mb-4 ml-1">
                        <span><strong>1:</strong> {{ $t('user-course-feedback.first') }}</span><br>
                        <span><strong>2:</strong> {{ $t('user-course-feedback.second') }}</span><br>
                        <span><strong>3:</strong> {{ $t('user-course-feedback.third') }}</span><br>
                        <span><strong>4:</strong> {{ $t('user-course-feedback.fourth') }}</span>
                    </div>
                    <table>
                        <tr class="font-24" style="font-size: 24px; font-variant-numeric: tabular-nums;">
                            <th></th>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                        </tr>
                        <tr v-for="currentQuestion in feedback.course_feedback.course_feedback_questions.filter(q => q.matrix_group !== null)" :key="currentQuestion.id">
                            <td class="font-24" style="font-size: 24px;">{{currentQuestion.question}}</td>
                            <td v-for="answer in currentQuestion.course_feedback_question_answers.sort((a,b) => Number(a.answer) - Number(b.answer))" :key="answer.id">
                                <input type="radio" @click.prevent style="cursor: not-allowed; width: 20px; height: 20px" :checked="isChecked(answer)" :data-debug="isChecked(answer)" :name="currentQuestion.id" :aria-label="$t('correct-test-attempt.radio')">
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <loading v-else />
</template>
<script>
import Loading from '../components/Loading.vue'
export default {
  components: { Loading },
    name: 'UserCourseFeedback',
    mounted() {
        this.axios.get('/courses/'+this.$route.params.course+'/user-feedback/'+this.$route.params.feedback)
            .then(resp => this.feedback = resp.data)
            .finally(() => this.loading = false)
    },
    data() {
        return {
            feedback: null,
            loading: true,
        }
    },
    methods: {
        isChecked(answer) {
            return this.feedback.user_course_feedback_question_answers.some(a => a.course_feedback_question_answer_id == answer.id)
        },
        getFreeTextAnswer(question) {
            const answer = this.feedback.user_course_feedback_question_answers.find(a => a.course_feedback_question_id == question.id)
            return answer ? answer.answer : ''
        }
    }
}
</script>
<style scoped>
th, td {
  padding: 15px;
}
</style>
