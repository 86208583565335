<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('glossary.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('unapproved-glossary.waiting') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->

        <!-- Modal -->

            <section id="glossary" class="container mt-5">
                <div class="row gloss-top">
                    <div class="col-12">
                        <h4 class="text-title-gray weight-600 text-20">{{ $t('unapproved-glossary.waiting') }}</h4>
                    </div>
                </div>

                <div class="row" v-if="!loading && articles.length > 0">
                    <div v-for="article in articles" :key="article.id" class="col-lg-6 col-12 glossary-item">
                        <div style="border-left: 3px solid var(--yellow)">
                        <h3 class="pl-3">
                            <span style="cursor: pointer;" @click.prevent="$router.push({name: 'EditGlossaryArticle', params: {id: article.id}})">{{article.title ?? 'Nincs cím'}}</span>
                        </h3>
                        <h4 style="cursor: pointer;margin: 0px !important" class="line-clamp pl-3" v-html="$store.getters.sanitizeSummernoteText(article.content)" @click.prevent="$router.push({name: 'EditGlossaryArticle', params: {id: article.id}})"></h4>
                        <div class="modal fade" :id="'glossaryDeleteModal'+article.id" tabindex="-1" aria-labelledby="glossaryDeleteModal" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                <div class="modal-header">
                                    <h2 class="modal-title" id="glossaryDeleteModalLabel">{{ $t('unapproved-glossary.delete-sure') }}</h2>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    {{ $t('unapproved-glossary.delete-q1') }}<strong>{{article.title}}</strong> {{ $t('unapproved-glossary.delete-q2') }}
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('unapproved-glossary.cancel') }}</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal" @click="deleteArticle(article)">{{ $t('unapproved-glossary.delete') }}</button>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="article.multimedia && article.multimedia.length > 0" class="mt-3">
                        <div class="mb-2">
                            <div v-for="multimedia in article.multimedia.filter((mm) => mm.type == 'document')" :key="multimedia.id" class="ml-2 mt-2">
                                <span>
                                    <a :href="storageURL+multimedia.path+'?token='+token" target="_blank">
                                        <i class="far fa-file-alt mr-1 font-20" alt="#" style="font-size: 20px !important;"></i>
                                        <span style="color: var(--black)">{{multimedia.name}}</span>
                                    </a>
                                </span>
                            </div>
                            <div v-for="multimedia in article.multimedia.filter((mm) => mm.type == 'audio')" :key="multimedia.id" class="mt-4">
                                <p class="ml-3 mb-0">{{multimedia.name}}</p>
                                <audio controls>
                                    <source :src="storageURL+multimedia.path+'?token='+token">
                                </audio>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div v-for="multimedia in article.multimedia.filter((mm) => mm.type == 'video' || mm.type == 'image')" :key="multimedia.id" class="col-sm-12 col-md-6 col-lg-12 col-xl-6 mt-4" style="height: 160px;">
                                <div v-if="multimedia.type == 'video' || multimedia.type == 'image' " style="color: var(--black); cursor:pointer; background-color: grey; width: 100%; height: 100%" 
                                    data-fancybox="fancy" 
                                    :href="multimedia.type != 'video' ? storageURL+multimedia.path+'?token='+token : '#'+multimedia.id" target="_blank"
                                    :data-caption="multimedia.name"
                                >
                                    <div v-if="multimedia.type == 'video'" style="width: 100%; height: 100%">
                                        <video style="width: 100%; height: 100%; pointer-events: none;" nocontrols >
                                            <source :src="storageURL+multimedia.path+'?token='+token">
                                        </video>
                                        <video :id="multimedia.id" style="display: none;" controls controlsList="nodownload">
                                            <source :src="storageURL+multimedia.path+'?token='+token">
                                        </video>
                                        <i class="far fa-play-circle playbtn"></i>
                                    </div>
                                    <div v-if="multimedia.type == 'image'" style="width: 100%; height: 100%">
                                        <img :src="storageURL+multimedia.path+'?token='+token" style="width: 100%; height: 100%">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <h5 class="labels">
                        <span v-for="label in article.labels" :key="label.id" class="label mr-1" style="cursor: pointer;" @click="addLabelToFilter(label.name)">{{label.name}}</span>    
                    </h5>
                    </div>
                </div>
                <div v-else-if="loading" class="row">
                    <div class="col-12">
                        <Loading height="50vh" />
                    </div>
                </div>
                <div v-else-if="searchBy" class="col-12 mt-5">
                    <h3 class="text-title-gray weight-500 text-16"> {{ $t('unapproved-glossary.no-found') }} </h3>
                </div>
                <div v-else class="col-12 mt-5">
                    <h3 class="text-title-gray weight-500 text-16">{{ $t('unapproved-glossary.no-article') }}</h3>    
                </div>
        </section>
    </div>
</template>
<script>
import Loading from '@/components/Loading'
export default {
    name: 'UnapprovedGlossary',
    components: {
        Loading
    },
    mounted() {
        this.axios.get('/glossary-articles/unapproved').then(resp => {
            this.articles = resp.data.glossaryArticles
            this.loading = false
        })
    },
    data() {
        return {
            articles: [],
            articlesFiltered: [],
            filteringBy: false,
            searchBy: null,
            labels: [],
            loading: true,
            token: this.axios.defaults.headers.common.Authorization.split(' ')[1],
            storageURL: process.env.VUE_APP_STORAGE_URL,
            searchStr: '',
        }
    },
    methods: {
        startSearch() {
            this.loading = true
            this.searchBy = this.searchStr ? this.searchStr.trim() : null
            let params = {
                search: this.searchBy,
                labels: this.labels
            }
            console.log(params)
            this.axios.get(`/glossary-articles`, {params}).then(resp => {
                this.articles = resp.data.glossaryArticles

            }).finally(() => this.loading = false)
        },
        clearSearch() {
            this.searchStr = null
            this.startSearch()
        },
        addLabelToFilter(labelName) {
            if(!this.labels.some(label => label == labelName)) {
                this.labels.push(labelName)
                this.startSearch()
            }
        },
        removeLabelFromFilter(labelName) {
            const labelIdx = this.labels.findIndex(label => label == labelName)
            if(labelIdx !== -1) {
                this.labels.splice(labelIdx, 1)
                this.startSearch()
            }
        },
        deleteArticle(article) {
            this.loading = true
            this.axios.delete('glossary-articles/'+article.id)
                .then((resp) => this.articles.splice(this.articles.findIndex(a => article.id == a.id), 1))
                .finally(() => this.loading = false)
        }
    }
}
</script>
<style scoped>
.playbtn {
    position: absolute; 
    left: 50%; top: 50%; 
    transform: translate(-50%, -50%); 
    font-size: 70px; 
    color:white
}

@media (max-width: 425px) {
        .playbtn {
            font-size: 49px !important;
        }
    }
.playbtn:hover {
    color: rgb(196, 196, 196);
}
@supports(-webkit-line-clamp: 6) { 
    .line-clamp {
        -webkit-line-clamp: 6;
        padding-bottom: 2px;
        -webkit-box-pack: end;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
    .line-clamp::v-deep(p) {
        margin: 0px !important;
        line-height: 19.2px;
    }
}
</style>
