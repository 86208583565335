<template>
  <div>
    <div class="modal fade" id="submitCode" tabindex="-1" role="dialog" ref="submitCode">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Course Code verification</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="submitCode" id="submitCodeForm">
            <div class="modal-body row">
              <div class="col-12">
                  <strong>Please insert the received Course Code here</strong>
                <div class="m-auto d-flex gap-1 mt-2" style="width: 60%;">
                  <input type="text" maxlength="1" class="form-control" style="width: 15%; font-weight:bold" v-model="code1" ref="input1" @input="focusNext(1)"  @paste="handlePaste(1, $event)"/>
                  <input type="text" maxlength="1" class="form-control" style="width: 15%; font-weight:bold" v-model="code2" ref="input2" @input="focusNext(2)"  @paste="handlePaste(2, $event)"/>
                  <input type="text" maxlength="1" class="form-control" style="width: 15%; font-weight:bold" v-model="code3" ref="input3" @input="focusNext(3)"  @paste="handlePaste(3, $event)"/>
                  <input type="text" maxlength="1" class="form-control" style="width: 15%; font-weight:bold" v-model="code4" ref="input4" @input="focusNext(4)"  @paste="handlePaste(4, $event)"/>
                  <input type="text" maxlength="1" class="form-control" style="width: 15%; font-weight:bold" v-model="code5" ref="input5" @input="focusNext(5)"  @paste="handlePaste(5, $event)"/>
                  <input type="text" maxlength="1" class="form-control" style="width: 15%; font-weight:bold" v-model="code6" ref="input6" @input="focusNext(6)"  @paste="handlePaste(6, $event)"/>
                </div>
                
              </div>
            </div>
            <div class="modal-footer">
              <PrimaryButton :disabled="submittingCode" :text="$t('manage-codes.submit')" type="submit" />
              <TransparentButton :text="$t('common.cancel')" data-dismiss="modal" />
            </div>
          </form>


        </div>
      </div>
    </div>
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({ name: 'Home' })">{{
              $t('my-courses.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                    alt=""></span>
              </div>
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({ name: 'CourseCatalog' })">{{
              $t('course.courses') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                    alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ (loading || !course) ? $t('common.cname') : course?.title
                  }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading && course" class="course-container">
      <div class="w-100 bg-light-gray-primary">
        <div class="row pt-5 restricted-width">
          <div class="col-12 col-xl-8 col-lg-7 col-md-6">
            <h1 class="text-white semi-bold" style="font-size: 30px">{{ course?.title }}</h1>
            <h2 class="text-white" style="font-size: 18px; font-weight: 400">{{ course.course_type.name }}</h2>
            <div class="course-info mt-3">
              {{ $t('course.duration') }}:
              <span class="semi-bold">{{ courseDuration }}</span>
            </div>
            <div class="course-info" v-if="!!course.start_date">
              {{ $t('course.begin') }}:
              <span class="semi-bold">
                {{
              course.start_date ?
                $store.getters.parseDateTime(course.start_date)
                : $t('course.no')
            }}
              </span>
            </div>
            <div class="course-info" v-if="course.end_date">
              {{ $t('course.end') }}:
              <span class="semi-bold">{{ course.end_date ? $store.getters.parseDateTime(course.end_date) :
              $t('course.no') }}</span>
            </div>
            <div class="course-info mt-2">
              {{ $t('course.group') }}: <span class="semi-bold">{{ course.group ? course.group.title : $t('common.none')
                }}</span>
            </div>
            <div class="course-info mt-2 mb-4">
              {{ $t('course.prerequisites') }}
              {{ course.prerequisites.length > 1 ? "(" + $t('course.minimum-of-1-must-be-met') + ")" : '' }}:
              <span class="semi-bold" v-if="course.prerequisites.length == 0">{{ $t('course.no') }}</span>
              <ul v-else class="list-unstyled mt-1">
                <li v-for="prerequisite in course.prerequisites" :key="prerequisite.id" class="ml-3"
                  @click="$router.push({ name: 'Course', params: { id: prerequisite.id } })" style="cursor: pointer;">
                  <span v-if="$store.state.user.coursesFinished.some(c => c.id === prerequisite.id)">
                    <i class="fas fa-check text-success" />
                  </span>
                  <span v-else>
                    <i class="fas fa-times text-danger" />
                  </span>
                  <span class="ml-2"> {{ prerequisite.title }}</span>

                </li>
                <div class="badge-container-course"
                  v-if="!(course.prerequisites.every((prerequisite) => ($store.state.user.coursesFinished.some(c => c.id === prerequisite.id))))">
                  <div class="badge-item-course"
                    style="font-size: 12px; font-weight: 600; padding-top: 1px; padding-bottom: 1px;">
                    {{ $t('course.prerequisite-not-met') }}
                  </div>
                </div>
              </ul>
            </div>
          </div>
          <div class="col-12 col-xl-4 col-lg-5 col-md-6 card-align" style="display: flex;">
            <div
              style="box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.1), 4px 4px 10px rgba(0, 0, 0, 0.15); border-top-left-radius: 5px; border-top-right-radius: 5px;">
              <img class="course-image pl-2 pr-2 pt-2" style="background-color: white; margin-top: auto;"
                :src="course.cover_image ? $store.getters.getStorageURL(course.cover_image.path) : 'assets/img/demo/training.png'" />
            </div>
          </div>
        </div>
      </div>
      <div class="row restricted-width">
        <div class="col-12 col-xl-8 col-lg-7 col-md-6 order-2 order-md-1">
          <div class="row">
            <div v-show="course.meeting_link && isOwned" class="col-12">
              <h3 class="mt-4" style="font-weight: 600; font-size: 18px; color: #87817C">{{ $t('course.meeting_link') }}
              </h3>
              <div class="mt-2" style="color: #222222; font-weight: 500; text-align: justify; font-size: 16px;">
                <a :href="course.meeting_link" target="_blank">{{ $t('course-event.click-to-join') }}</a>
              </div>
            </div>
            <div class="col-12">
              <h3 class="mt-4" style="font-weight: 600; font-size: 18px; color: #87817C">{{ $t('course.content') }}</h3>
              <div class="mt-2" style="color: #222222; font-weight: 500; text-align: justify; font-size: 16px;"
                v-html="$store.getters.getHTMLSafeText(course.contents)">
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-4 col-lg-5 col-md-6 order-1 order-md-2">
          <div class="d-flex flex-column w-100 card-align">
            <div class="course-main-card pl-3 pr-3">
              <h3 class="mt-4" style="font-weight: 600; font-size: 18px; color: #87817C">{{ $t('course.description') }}
              </h3>
              <div style="font-weight: 600; font-size: 20px; color: #222222"
                v-html="$store.getters.getHTMLSafeText(course.description)">
              </div>
              <div v-if="!isOwned && hasActiveMembership" class="d-flex align-items-center mt-4">
                <h3 style="font-weight: 600; font-size: 18px; color: #87817C">
                  {{ $t('course.price') }}
                </h3>
              </div>
              <div v-if="!isOwned && hasActiveMembership" class="semi-bold text-black" style="font-size: 30px">
                {{ getPrice(course) > 0 ? $store.getters.formatPrice(getPrice(course)) : $t('course.free') }}<span
                  v-if="getPrice(course) > 0" class="small text-16">({{ $store.getters.formatPrice(getPrice(course),
              course) }})</span>
              </div>
              <!-- <div v-if="membership && !hasActiveMembership && !isOwned" class="semi-bold text-black"
                style="font-size: 30px">
                {{ $store.getters.formatPrice(membership.net_price) }} <span class="small text-16">({{
                  $t('manage-coupons.annual-membership') }})</span>
              </div> -->
              <div class="semi-bold text-black" style="font-size: 12px"
                v-if="getPrice(course) > 0 && !isOwned && hasActiveMembership">
                + {{ $t('cart.vat') }}
              </div>
              <div v-if="!hasActiveMembership" class="semi-bold text-black"
                style="font-size: 30px; margin-top: 1rem; line-height: 1.2rem">
                <i class="fas fa-eye-slash" style="color: var(--gray)"></i>
                <div style="font-size: small" class="ml-1">{{ $t('course.no-membership') }}</div>
              </div>
              <div class="mt-2">
                <div class="d-flex align-items-center" v-if="isOwned">
                  <i class="fas fa-hourglass-start" style="font-size: 2rem; color: var(--gray)"></i>
                  <div class="ml-1">{{ $t('course.in-progress') }}</div>
                </div>
                <div class="d-flex align-items-center" v-else-if="isInCart">
                  <i class="fas fa-shopping-cart" style="color: var(--red)"></i>
                  <div class="ml-1">{{ $t('course.in-cart') }}</div>
                </div>
                <div class="d-flex align-items-center" v-if="isLocked">
                  <i class="fas fa-info-circle" style="color: var(--red)"></i>
                  <div class="ml-1">{{ $t('course.prerequisite-not-met') }}</div>
                </div>
                <div class="d-flex align-items-center" v-if="isInactive">
                  <i class="fas fa-info-circle" style="color: var(--red)"></i>
                  <div class="ml-1">
                    <span class="mr-1">{{ $t('course.inactive-short') }}:</span>
                    <span v-if="course.start_date && Date.now() < new Date(course.start_date)">
                      {{ $t('course.coming-soon') }}
                    </span>
                    <span v-else>{{ $t('course.ended') }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-5 mb-4">
                <div v-show="course?.certificate && course?.certificate?.card_path != null" class="mb-3">
                  <button type="button" v-if="course.certificate && course.certificate.path != null"
                    :disabled="certificateLoading" class="btn btn-theme w-100 text-center text-white semi-bold"
                    @click.prevent="downloadCertificate">
                    <span class="d-flex align-items-center">
                      <i class="fas fa-award mr-1"></i>
                      <span>{{ $t('course.download') }}</span>
                    </span>
                  </button>
                  <button type="button" :disabled="certificateCardLoading"
                    v-if="course.certificate && course.certificate.card_path != null"
                    class="mt-2 mb-2 btn btn-theme w-100 text-center text-white semi-bold"
                    @click.prevent="downloadCertificateCard">
                    <div class="d-flex align-items-center">
                      <i class="fas fa-id-card mr-1"></i>
                      <span>{{ $t('course.download-card') }}</span>
                    </div>
                  </button>
                </div>
                <div
                  v-show="course?.user_temporary_certificate && course?.user_temporary_certificate?.public_certificate_number"
                  class="mb-3 text-center justify-content-center">
                  <button type="button"
                    v-if="course.user_temporary_certificate && course.user_temporary_certificate.path != null"
                    :disabled="certificateLoading" class="btn btn-theme w-100 text-center text-white semi-bold"
                    @click.prevent="downloadTemporaryCertificate">
                    <span class="d-flex align-items-center">
                      <i class="fas fa-award mr-1"></i>
                      <span>{{ $t('course.download_temporary_cert') }}</span>
                    </span>
                  </button>
                </div>
                <button v-if="isOwned && hasActiveMembership && !isCodeRequired"
                  class="btn btn-theme w-100 text-center text-white semi-bold"
                  @click.prevent="$router.push({ name: 'ViewCourse', params: { id: course.id } })" style="font-size: 22px"
                  :disabled="!canStart">
                  {{ $t('course.start') }}
                </button>
                <button v-else-if="getPrice(course) == 0 && hasActiveMembership && !isCodeRequired" data-toggle="modal"
                  data-target="#courseStartModal" class="btn btn-theme w-100 text-center text-white semi-bold"
                  style="font-size: 22px" :disabled="!canStart">
                  {{ $t('course.start') }}
                </button>
                
                <button v-else-if="(getPrice(course) == 0 || isOwned) && hasActiveMembership && isCodeRequired"
                  data-toggle="modal" data-target="#submitCode"
                  class="btn btn-theme w-100 text-center text-white semi-bold" style="font-size: 22px"
                  :disabled="!canStart">
                  {{ $t('course.submit_code') }}
                </button>
                <button v-else-if="hasActiveMembership" class="btn btn-theme w-100 text-center text-white semi-bold"
                  style="font-size: 22px"
                  :disabled="!canStart || isInCart || (course.end_date && Date.now() > new Date(course.end_date))"
                  @click.prevent="addToCart">
                  <i class="fas fa-shopping-cart" /> {{ isInCart ? $t('course.in-cart') : $t('course.cart') }}
                </button>
                <button v-else class="btn btn-theme w-100 text-center text-white semi-bold" style="font-size: 22px"
                  :disabled="!canStart || isInCart || (course.end_date && Date.now() > new Date(course.end_date))"
                  @click.prevent="buyMembership">{{ $t('membership.buy') }}
                </button>
              </div>
            </div>
          </div>
          <div v-if="course?.prerequisite_for && course.prerequisite_for.length"
            class="prerequisite-for-course-container mt-4 flex-column card-align float-right" style="width: 350px">
            <h3 class="text-left" style="font-weight: 600; font-size: 18px; color: #87817C; padding-bottom: 20px">
              {{ $t('course.next-courses') }}</h3>
            <div v-for="preCourse in course?.prerequisite_for" :key="preCourse.id"
              style="cursor: pointer; margin-bottom: 20px;"
              @click="$router.push({ name: 'Course', params: { id: preCourse.id } })">
              <course-card :course="preCourse" />
            </div>
          </div>
        </div>
      </div>
      <div class="row restricted-width mt-5">
        <div class="col-12 col-xl-8 col-lg-7 col-md-6 order-2 order-md-1">
          <hr>
          <feed v-if="isOwned && $store.state.user.role !== 'User'" :courseId="course.id" />
        </div>
        <div class="col-12 col-xl-4 col-lg-5 col-md-6 order-1 order-md-2 mb-5">
          <div class="recommended-course-container">
            <h3 class="text-left" style="font-weight: 600; font-size: 18px; color: #87817C; padding-bottom: 20px">{{
              $t('course.suggested-courses') }}</h3>
            <div v-for="course in recommendedCourses" :key="course.id" style="cursor: pointer;"
              @click="$router.push({ name: 'Course', params: { id: course.id } })">
              <course-card :course="course" />
            </div>
            <div v-if="recommendedCourses.length === 0">{{ $t('dla-user-home.no-rec') }}</div>
          </div>
        </div>
      </div>
    </div>
    <loading v-else height="70vh" />
  </div>
  <!-- Course Start Confirmation -->
  <div v-if="course != null" class="modal fade" id="courseStartModal" tabindex="-1" role="dialog"
    aria-labelledby="courseStartModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="courseStartModalLabel">{{ $t('course.course-start') }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <p class="col-12 text-center">{{ $t("course.course-start-text") }}</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('course.cancel') }}</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" @click.prevent="buyCourse">
            {{ $t('course.start') }}
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { Capacitor } from '@capacitor/core';
import Loading from '@/components/Loading.vue';
import store from '../store';
import CourseFeed from '@/components/CourseFeed.vue';
import { DateTime } from 'luxon';
import FarContractDownload from '../components/FarContractDownload.vue';
import CourseCard from '../components/CourseCard.vue';
import Feed from '../components/Feed.vue';
import GenericDocumentDownload from "../components/GenericDocumentDownload.vue";
import PrimaryButton from "../components/settings/PrimaryButton.vue";
import TransparentButton from "../components/settings/TransparentButton.vue";
export default {
  name: 'Course',
  components: {
    GenericDocumentDownload,
    Loading,
    CourseFeed,
    FarContractDownload,
    CourseCard,
    Feed,
    PrimaryButton,
    TransparentButton
  },
  mounted() {
    this.axios.get('/courses/' + this.$route.params.id).then(resp => {
      this.course = resp.data.course;
      //console.log(resp.data.course);
      this.axios.get('/courses/recommended/' + this.course.id)
        .then(resp => this.recommendedCourses = resp.data.courses)
      if (this.course.course_events)
        this.course.course_events.forEach((event, index) => {
          event.loading = true
          this.axios.get(`/courses/${this.course.id}/events/${event.id}`)
            .then(res => this.course.course_events[index] = res.data.courseEvent)
            .catch(e => console.log(e.response))
            .finally(() => event.loading = false)
        })
      if (this.course.code_required) {
        this.checkIfCodeRequired();
      }
    }).finally(() => {
      this.loading = false
    });
    this.axios.get('/memberships/1')
      .then(resp => {
        this.membership = resp.data.membership
      })
      .finally(() => this.loading = false);
    //console.log(this.$store.state.user)
  },
  data() {
    return {
      course: null,
      loading: true,
      isTimeout: false,
      DateTime: DateTime,
      membership: null,
      token: this.axios.defaults.headers.common.Authorization.split(' ')[1],
      storageURL: process.env.VUE_APP_STORAGE_URL,
      frontendURL: process.env.VUE_APP_FRONTEND_URL,
      certificateLoading: false,
      certificateCardLoading: false,
      recommendedCourses: [],
      submitting: false,
      codeSubmitted: false,
      code1: null,
      code2: null,
      code3: null,
      code4: null,
      code5: null,
      code6: null,
      submittingCode: false
    }
  },
  computed: {
    isCodeRequired() {
      let required = this.course.code_required;
      if (required && this.codeSubmitted) {
        required = false;
      }

      return required;
    },
    isInCart() {
      return this.$store.state.user?.cart_items.find(c => c.cartable_type === 'App\\Models\\Course' && c.cartable_id === this.course.id)
    },
    isOwned() {
      return this.$store.state.user?.courses.find(c => c.id === this.course.id);
    },
    hasActiveMembership() {
      return this.$store.state.user?.hasActiveMembership;
    },
    overallProgress() {
      let completed = 0
      let all = this.course.duration
      this.course.course_multimedia_progress.forEach((el) => {
        completed += el.progress
      })
      this.course.course_event_progress.forEach((el) => {
        completed += el.progress
      })
      return all !== 0 ? (completed / (all / 100)).toFixed(2) : 0
    },
    courseDuration() {
      let all = this.course.duration
      const hours = Math.floor(all / 3600)
      const minutes = Math.floor((all % 3600) / 60)
      return `${hours !== 0 ? hours + " " + this.$t('time-unit.hour') + " " : ''}${minutes !== 0 ? minutes + " " + this.$t('time-unit.minute') : ''}`
    },
    isLocked() {
      return this.course && this.course.prerequisites.length > 0 && !this.course.prerequisites.some(pre => this.$store.state.user.coursesFinished.some(f => f.id === pre.id))
    },
    isInactive() {
      return this.course?.is_inactive;
    },
    canStart() {
      return !this.isLocked && !this.isInactive;
    }
  },
  methods: {
    focusNext(currentInput) {
      if (currentInput < 6) {
        this.$refs['input' + (currentInput + 1)].focus();
      }
    },
    handlePaste(inputIndex, event) {
      event.preventDefault();
      const pastedData = event.clipboardData.getData('text').trim();
      if (pastedData.length <= 6 && inputIndex + pastedData.length <= 6) {
        for (let i = 0; i < pastedData.length; i++) {
          this['code' + (inputIndex + i)] = pastedData.charAt(i);
          if (inputIndex + i < 6) {
            this.$refs['input' + (inputIndex + i + 1)].focus();
          }
        }
      }
    },
    submitCode() {
      this.submittingCode = true;
      let code= this.code1+this.code2+this.code3+this.code4+this.code5+this.code6
      this.axios.post('/course_code/using', { code: code, course_id: this.course.id }).then(resp => {
        this.checkIfCodeRequired();
        $(this.$refs.submitCode).modal("hide");
        store.state.toastNotifications.push({status: 'Success', message: 'Code is Correct'})
      }).catch(e => {
        console.log(e)
      }).finally(fn => {
        this.submittingCode = false;
      });
    },
    checkIfCodeRequired() {
      this.axios.post('/course_code/checkCode', { user_id: this.$store.state.user?.id, course_id: this.course.id }).then(resp => {
        this.codeSubmitted = true;
      }).catch(e => {
        console.log(e)
      });
    },
    getLocation(location) {
      return `${location.zip_code}, ${location.locality}, ${location.public_space} ${location.public_space_type}, ${location.other}`
    },
    addToCart() {
      if (this.submitting) return;
      this.submitting = true
      this.axios.post('/courses/' + this.$route.params.id + '/cart')
        .then((resp) => this.$store.state.user.cart_items = resp.data.cartItems)
        .finally(() => this.submitting = false)
    },
    buyCourse() {
      if (this.submitting) return;
      this.submitting = true
      this.axios.post('/courses/' + this.$route.params.id + '/buy').then(resp => {
        this.loading = true
        this.axios.get('/courses/' + this.$route.params.id).then(resp => {
          this.course = resp.data.course
          this.$store.state.user.courses.push(resp.data.course)
          this.$router.push({ name: 'ViewCourse', params: { id: this.course.id } })
        })
          .finally(() => {
            this.loading = false
            this.submitting = false
          })
      })
    },
    buyMembership() {
      if (this.submitting) return;
      if (this.$store.state.user.cart_items.some(c => c.cartable_type == 'App\\Models\\Membership' && c.cartable_id == this.membership.id)) {
        this.$router.push({ name: 'Cart' })
        return;
      }
      this.submitting = true
      this.axios.post(`/memberships/${this.membership.id}/cart`)
        .then(resp => {
          const cartItem = resp.data.cart_item
          this.$store.state.user.cart_items.push(cartItem)
          this.$router.push({ name: 'Cart' })
        })
        .catch(e => console.log(e))
        .finally(() => this.submitting = false)
    },
    setupRefs(cm) {
      //console.log('setupRefs')
      if (cm.multimedia.type == 'image' || cm.multimedia.type == 'document') {
        this.makeProgress(cm.id)
      }
      this.course.course_multimedia.forEach(cm => {
        let userProgress = this.course.course_multimedia_progress.filter(el => el.course_multimedia_id == cm.id)[0];
        if (!cm.timeTracking && !(cm.multimedia.type == 'image' || cm.multimedia.type == 'document')) {
          cm.timeTracking = {
            watchedTime: userProgress ? userProgress.progress : 0,
            prevTime: (userProgress && userProgress.progress < cm.length) ? userProgress.progress : 0,
            currentTime: (userProgress && userProgress.progress < cm.length) ? userProgress.progress : 0,
            progressMade: 0
          };
          var lastUpdated = 'currentTime'
          this.$refs['cm' + cm.id].currentTime = cm.timeTracking.currentTime

          this.$refs['cm' + cm.id].addEventListener('timeupdate', () => {
            //console.log(this.$refs['cm'+cm.id].currentTime)
            if (!this.$refs['cm' + cm.id].seeking) {
              if (this.$refs['cm' + cm.id].currentTime > cm.timeTracking.watchedTime) {
                cm.timeTracking.progressMade += this.$refs['cm' + cm.id].currentTime - cm.timeTracking.prevTime
                cm.timeTracking.prevTime = this.$refs['cm' + cm.id].currentTime;
                // console.log(cm.timeTracking.progressMade)
                if (Math.round(cm.timeTracking.progressMade >= 10)) {
                  cm.timeTracking.progressMade = 0;
                  this.makeProgress(cm.id)
                  console.log('10 second progress made!')
                }
                cm.timeTracking.watchedTime = this.$refs['cm' + cm.id].currentTime;
                lastUpdated = 'watchedTime';
              }
              //tracking time updated  after user rewinds
              else {
                cm.timeTracking.currentTime = this.$refs['cm' + cm.id].currentTime;
                lastUpdated = 'currentTime';
              }
            }


          });
          // prevent user from seeking
          this.$refs['cm' + cm.id].addEventListener('seeking', (e) => {
            // guard against infinite recursion:
            //this.$refs['cm'+cm.id].pause()
            // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
            var delta = this.$refs['cm' + cm.id].currentTime - cm.timeTracking.watchedTime;
            if (delta > 0) {
              if (!this.isTimeout) {
                this.isTimeout = true;
                this.$store.state.toastNotifications.push({
                  status: 'Error',
                  message: 'A videó még meg nem nézett részéhez tekerés nem engedélyezett'
                })
                setTimeout(() => {
                  this.isTimeout = false
                }, 1000)
              }
              //this.$refs['cm'+cm.id].pause()
              //play back from where the user started seeking after rewind or without rewind
              this.$refs['cm' + cm.id].currentTime = cm.timeTracking[lastUpdated];
            }
          });
        }
      })

    },
    makeProgress(cmId) {
      this.axios.post(`/courses/${this.course.id}/course-multimedia/${cmId}/make-progress`).then(resp => {
        let idx = this.course.course_multimedia_progress.findIndex(el => el.course_multimedia_id == cmId);
        if (idx > -1) {
          this.course.course_multimedia_progress[idx] = resp.data.courseMultimediaProgress
        } else {
          this.course.course_multimedia_progress.push(resp.data.courseMultimediaProgress)
        }
      })
    },
    parseDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)
    },
    getPrice(course) {
      return course.net_price;
    },
    downloadCertificate() {
      this.certificateLoading = true;
      this.axios.get('/courses/' + this.course.id + '/certificate', { responseType: 'blob' })
        .then(resp => {
          const name = this.course.title + '_cert.pdf'
          if (Capacitor.isNative) {
            this.$store.commit('downloadFileMobile', { name, mime: 'application/pdf', data: resp.data })
          } else {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch(e => console.log(e.response))
        .finally(() => this.certificateLoading = false)
    },
    downloadCertificateCard() {
      this.certificateCardLoading = true;
      this.axios.get('/courses/' + this.course.id + '/certificate-card', { responseType: 'blob' })
        .then(resp => {
          const name = this.course.title + '_card.pdf'
          if (Capacitor.isNative) {
            this.$store.commit('downloadFileMobile', { name, mime: 'application/pdf', data: resp.data })
          } else {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch(e => console.log(e.response))
        .finally(() => this.certificateCardLoading = false)
    },
    downloadTemporaryCertificate() {
      this.certificateCardLoading = true;
      console.log(this.course.user_temporary_certificate);
      this.axios.get('/temporary-certificates/' + this.course.user_temporary_certificate.id + '/download', { responseType: 'blob' })
        .then(resp => {
          const name = this.course.title + '_temporary.pdf'
          if (Capacitor.isNative) {
            this.$store.commit('downloadFileMobile', { name, mime: 'application/pdf', data: resp.data })
          } else {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch(e => console.log(e.response))
        .finally(() => this.certificateCardLoading = false)
    },
    start() {

    }
  },
  watch: {
    $route(to, from) {
      if (to.name == 'Course' && from.name == 'Course') {
        this.loading = true
        this.axios.get('/courses/' + this.$route.params.id).then(resp => {
          this.course = resp.data.course
          this.recommendedCourses = []
          this.axios.get('/courses/recommended/' + this.course.id)
            .then(resp => this.recommendedCourses = resp.data.courses)
          if (this.course.course_events)
            this.course.course_events.forEach((event, index) => {
              event.loading = true
              this.axios.get(`/courses/${this.course.id}/events/${event.id}`)
                .then(res => this.course.course_events[index] = res.data.courseEvent)
                .catch(e => console.log(e.response))
                .finally(() => event.loading = false)
            })


        }).finally(() => {
          this.loading = false
        })
      }
    }
  }
}
</script>

<style scoped>
.recommended-course-container {
  width: 350px;
  margin-left: auto;
}

.card-align {
  justify-content: flex-end;
  align-items: flex-end;
}

@media (max-width: 767px) {
  .card-align {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .recommended-course-container {
    margin-left: 0px;
  }
}

.restricted-width {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.course-info {
  font-weight: 300;
  font-size: 18px;
  color: white;
}

.course-image {
  object-fit: cover;
  width: 350px;
  max-height: 200px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.course-main-card {
  background-color: white;
  width: 350px;
  box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.1), 4px 5px 10px rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.badge-container-course {
  z-index: 10;
  top: 10px;
  right: 0;
  display: flex;
}

.badge-item-course {
  background-color: #D63330;
  border-radius: 8px 0px 0px 8px;
  font-weight: 400;
  font-size: 16px;
  color: white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
