<template>
  <div v-if="$store.state.user">
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('my-courses.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('my-courses.my-courses') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <section id="content-2">
      <div class="row justify-content-center">
        <div class="button-col">
          <button type="button" @click="goto('in-progress')" class="btn btn-theme text-center text-white semi-bold">{{ $t('my-courses.ongoing-courses') }}</button>
        </div>
        <div class="button-col">
          <button type="button" @click="goto('completed')" class="btn btn-theme text-center text-white semi-bold">{{$t('my-courses.ended') }}</button>
        </div>
        <div class="button-col">
          <button type="button" @click="goto('owned')" class="btn btn-theme text-center text-white semi-bold">{{$t('my-courses.owned-courses') }}</button>
        </div>
        <div class="button-col">
          <button type="button" @click="goto('fav-groups')" class="btn btn-theme text-center text-white semi-bold">{{ $t('my-courses.fav-course-groups') }}</button>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <section ref="fav-courses" id="training-fav">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('my-courses.fav-courses') }}</h2>
                </div>
                <!-- TRAINING CARD-->
                <div
                    class="col-12 col-sm-6 col-lg-3 mb-4"
                    v-for="course in $store.state.user.favorite_courses"
                    :key="course.id"
                    @click.prevent="$router.push({name: 'Course', params: {id: course.id}})"
                >
                  <course-card
                      :showDates="true"
                      :course="course"
                      :showFav="true"
                      :showBadges="false"
                  />
                </div>
                <div clas="col-12" v-if="$store.state.user.favorite_courses.length == 0">
                  <h6 class="ml-3 text-13 text-dark-gray semi-bold">{{ $t('my-courses.no-fav') }}</h6>
                </div>
              </div>
            </section>
            <section ref="owned" id="training-progress">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-18 text-dark-gray semi-bold mb-3">
                    {{ $t('my-courses.owned-courses') }}
                  </h2>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 mb-4"
                     v-for="course in $store.state.user.courses"
                     :key="course.id"
                     @click.prevent="$router.push({name: 'Course', params: {id: course.id}})"
                >
                  <course-card
                      :showDates="true"
                      :course="course"
                      :showFav="false"
                      :showBadges="false"
                      :showProgress="true"
                      :progress="getProgress(course)"
                  />
                </div>
                <div class="col-12" v-if="$store.state.user.courses.length == 0">
                  <h6 class="text-13 text-dark-gray semi-bold">{{ $t('my-courses.no-owned') }}</h6>
                </div>
              </div>
            </section>
            <section ref="in-progress" id="training-progress-2">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-18 text-dark-gray semi-bold mb-3">
                    {{ $t('my-courses.ongoing-courses') }}
                  </h2>
                </div>
                <div
                    class="col-12 col-sm-6 col-lg-3 mb-4"
                    v-for="course in $store.state.user.coursesInProgress"
                    :key="course.id"
                    @click.prevent="$router.push({name: 'Course', params: {id: course.id}})"
                >
                  <course-card
                      :showDates="true"
                      :course="course"
                      :showFav="false"
                      :showBadges="false"
                      :showProgress="true"
                      :progress="getProgress(course)"
                  />
                </div>
                <div class="col-12" v-if="$store.state.user.coursesInProgress.length == 0">
                  <h6 class="text-13 text-dark-gray semi-bold">{{ $t('my-courses.no-ongoing') }}</h6>
                </div>
              </div>
            </section>
            <section ref="completed" id="training-completed">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('my-courses.ended') }}</h2>
                </div>
                <div
                    class="col-12 col-sm-6 col-lg-3 mb-4"
                    v-for="course in Array($store.state.user.locked_courses, $store.state.user.coursesFinished).flat()"
                    :key="course.id"
                    @click.prevent="$router.push({name: 'Course', params: {id: course.id}})"
                >
                  <course-card
                      :showDates="true"
                      :course="course"
                      :showFav="false"
                      :showBadges="false"
                  />
                </div>
                <div class="col-12"
                     v-if="Array($store.state.user.locked_courses, $store.state.user.coursesFinished).flat().length == 0">
                  <h6 class="text-13 text-dark-gray semi-bold">{{ $t('my-courses.no-ended') }}</h6>
                </div>
              </div>
            </section>
            <section ref="fav-groups"  id="training-fav-groups">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('my-courses.fav-course-groups') }}</h2>
                </div>
                <!-- TRAINING CARD-->
                <div
                    class="col-12 col-sm-6 col-lg-3 mb-4"
                    v-for="courseGroup in $store.state.user.favorite_course_groups"
                    :key="courseGroup.id"
                    @click.prevent="$router.push({name: 'CourseCatalogGrouped', params: {group: courseGroup.id}})"
                >
                  <course-group-card
                      :courseGroup="courseGroup"
                      :showFav="false"
                  />
                </div>
                <div clas="col-12" v-if="$store.state.user.favorite_course_groups.length == 0">
                  <h6 class="ml-3 text-13 text-dark-gray semi-bold">{{ $t('my-courses.no-fav-groups') }}</h6>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {DateTime} from 'luxon';
import CourseCard from '../components/CourseCard.vue';
import CourseGroupCard from '../components/CourseGroupCard.vue';

export default {
  components: {CourseCard, CourseGroupCard},
  name: 'MyCourses',

  data() {
    return {
      coursesInProgress: [],
      coursesFinished: [],
    }
  },
  methods: {
    lockCourse(course) {
      const idx = this.$store.state.user.coursesInProgress.findIndex(c => c.id === course.id)
      if (idx !== -1)
        this.$store.state.user.coursesInProgress.splice(idx, 1)

      this.$store.state.user.locked_courses.push(course)

      this.axios.post('/courses/' + course.id + '/lock')

    },
    unlockCourse(course) {
      const idx = this.$store.state.user.locked_courses.findIndex(c => c.id === course.id)
      if (idx !== -1)
        this.$store.state.user.locked_courses.splice(idx, 1)

      if (this.getProgress(course) < 100)
        this.$store.state.user.coursesInProgress.push(course)
      else
        this.$store.state.user.coursesFinished.push(course)

      this.axios.post('/courses/' + course.id + '/unlock')
    },
    getStatus(courseId) {
      const finishedCourse = this.$store.state.user.coursesFinished.find((course) => {
        return course.id === courseId
      });
      const startedCourse = this.$store.state.user.coursesInProgress.find((course) => {
        return course.id === courseId
      });
      if (!this.$store.state.user.courses.find((course) => {
        return course.id === courseId
      })) {
        return 'Nem megvásárolt'
      } else if (startedCourse || finishedCourse) {
        return this.getProgress(this.$store.state.user.courses.find(c => c.id == courseId)) + '%'
      }
    },
    getProgress(course) {
      let progress = 0;
      this.$store.state.user.course_material_progress.filter(cm => cm.course_material.course_id == course.id).forEach((cm) => {
        progress += cm.progress
      })
      return course.duration !== 0 ? Math.floor(progress / (course.duration / 100)) : 0
    },
    parseDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)
    },
    goto(refName) {
      let element = this.$refs[refName];
      var headerOffset = 160;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  },
  mounted() {
    this.axios.get('/user').then(resp => {
      this.$store.commit('setUser', resp.data)
    })
  },
}
</script>
<style scoped>
#training-progress,
#training-progress-2,
#training-progress-3,
#training-completed,
#training-fav {
  margin-top: 30px;
}

.training-card-progress {
  max-width: 95% !important;
}
.button-col{
  flex-basis: 0;
  flex-grow: 1;
  margin-right: 10px;
  margin-left: 10px;
  max-width: fit-content;
}
</style>
