<template>
  <div>
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray"
         v-if="$store.state.user.role == 'User' || $store.state.user.role == 'Member User' || !this.$route.params.id">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('activity-history.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('activity-history.activity-details') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="breadcrumb bg-dark-gray" v-else-if="$store.state.user.role == 'Master'">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('activity-history.homepage') }}</span>
              </div>
              <div class="d-flex mr-4"><span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span></div>
              <div class="d-flex mr-4 pointer"><span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'MyTeam'})">{{ $t('overview.my-team') }}</span></div>
              <div class="d-flex mr-4"><span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span></div>
              <div class="d-flex"><span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Overview', params: {id: this.$route.params.id}})">{{ $t('activity-history.look-in') }}</span></div>
              <div class="d-flex mr-4"><span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span></div>
              <div class="d-flex"><span class="text-16 text-white pt-3 pb-3">{{ $t('activity-history.activity-details') }}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="breadcrumb bg-dark-gray" v-else>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('activity-history.homepage') }}</span>
              </div>
              <div class="d-flex mr-4"><span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span></div>
              <div class="d-flex mr-4 pointer"><span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'ManageUsers'})">{{ $t('activity-history.manage-users') }}</span></div>
              <div class="d-flex mr-4"><span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span></div>
              <div class="d-flex"><span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Overview', params: {id: this.$route.params.id}})">{{ $t('activity-history.look-in') }}</span></div>
              <div class="d-flex mr-4"><span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span></div>
              <div class="d-flex"><span class="text-16 text-white pt-3 pb-3">{{ $t('activity-history.activity-details') }}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="d-flex flex-wrap mt-4">
        <div class="d-flex align-items-center" style="flex-direction: column; margin-left: auto">
          <button type="button" :disabled="xlsxLoading" class="btn btn-outline-success  mb-2 mr-2"
                  @click.prevent="downloadXLSX">
            <div class="d-flex align-items-center">
              <i class="fas fa-file-excel mr-1"></i>
              <span>{{ $t('activity-history.download-activity-diary1') }}</span>
            </div>
          </button>
          <div v-if="xlsxLoading" class="spinner-border text-success ml-2 mb-2 mr-4" role="status">
            <span class="sr-only">{{ $t('activity-history.loading') }}</span>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <button type="button" :disabled="pdfLoading" class="btn btn-outline-danger mb-2" @click.prevent="downloadPDF">
            <div class="d-flex align-items-center">
              <i class="fas fa-file-pdf mr-1"></i>
              <span>{{ $t('activity-history.download-activity-diary2') }}</span>
            </div>
          </button>
          <div v-if="pdfLoading" class="spinner-border text-danger ml-2 mb-2" role="status">
            <span class="sr-only">{{ $t('activity-history.loading') }}</span>
          </div>
        </div>
        <div v-if="false" class="d-flex align-items-center">
          <button class="btn btn-outline-info mb-2"
                  @click.prevent="$router.push({name: 'PersonalAnalytics', params: {user: this.$route.params.id}})"><i
              class="fas fa-chart-bar"></i> {{ $t('activity-history.my-analytics') }}
          </button>
        </div>
      </div>
      <ul v-if="!loading" class="notifications">

        <li v-for="activity in activities" :key="activity.id" class="notification">
          <div class="media">
            <div class="media-body">
              <strong class="notification-title"><a>{{ activity.activity }}</a></strong>
              <div class="notification-meta mt-1">
                <small><b>{{ $t('activity-history.ip') }}</b> {{ activity.ip_address }}</small>
              </div>
              <div class="notification-meta">
                <small v-if="activity.created_at === activity.updated_at"
                       class="timestamp">{{ $store.getters.parseActivityDate(activity.created_at) }}</small>
                <small v-else class="timestamp">{{ $store.getters.parseActivityDate(activity.created_at) }} -
                  {{ $store.getters.parseActivityDate(activity.updated_at) }}</small>
              </div>
            </div>
          </div>
        </li>
        <li class="text-center" v-if="activities.length == 0">
          {{ $t('no-data.activity') }}
        </li>
        <div class="mt-3 w-100 d-flex justify-content-center">
          <infinite-scroll @shouldLoad="loadActivities" :shouldLoad="paginator.current_page < paginator.last_page"/>
        </div>
      </ul>
      <div v-else>
        <Loading height="80vh"/>
      </div>
    </div>
  </div>
</template>
<script>
import {Capacitor} from '@capacitor/core';
import Loading from '@/components/Loading.vue'
import InfiniteScroll from '../components/InfiniteScroll.vue'

export default {
  name: 'ActivityHistory',
  components: {
    Loading,
    InfiniteScroll
  },
  mounted() {
    this.loading = true
    this.axios.get('/activity/' + this.userId)
        .then(resp => {
          this.activities = resp.data.activity
          this.paginator = resp.data.paginator
        })
        .finally(() => this.loading = false)
  },
  data() {
    return {
      loading: true,
      userId: this.$route.params.id ? this.$route.params.id : this.$store.state.user.id,
      pdfLoading: false,
      xlsxLoading: false,
      activities: [],
      paginator: null
    }
  },
  methods: {
    loadActivities(state) {
      this.axios.get('/activity/' + this.userId, {params: {page: this.paginator.current_page + 1}})
          .then(resp => {
            this.activities.push(...resp.data.activity)
            this.paginator = resp.data.paginator
          })
          .finally(() => state.finishLoad())
    },
    downloadPDF() {
      this.pdfLoading = true;
      this.axios.get('/activity/' + this.userId + '/export/pdf', {responseType: 'blob'})
          .then(resp => {
            const name = 'activity-log.pdf'
            if (Capacitor.isNative) {
              this.$store.commit('downloadFileMobile', {name, mime: 'application/pdf', data: resp.data})
            } else {
              const url = window.URL.createObjectURL(new Blob([resp.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', name);
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(e => console.log(e))
          .finally(() => this.pdfLoading = false)
    },
    downloadXLSX() {
      this.xlsxLoading = true;
      this.axios.get('/activity/' + this.userId + '/export/xlsx', {responseType: 'blob'})
          .then(resp => {
            const name = 'activity-log.xlsx';
            if (Capacitor.isNative) {
              this.$store.commit('downloadFileMobile', {
                name,
                mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                data: resp.data
              })
            } else {
              const url = window.URL.createObjectURL(new Blob([resp.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', name);
              document.body.appendChild(link);
              link.click()
            }
            ;
          })
          .catch(e => console.log(e))
          .finally(() => this.xlsxLoading = false)
    }
  }
}
</script>
