<template>
  <div v-if="created">
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('course-catalog.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex pointer mr-4">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Cart'})">{{ $t('cart.cart') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex pointer mr-4">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'CheckoutUserData'})">{{
                    $t('checkout-payment.billing-data')
                  }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'CheckoutPaymentMethod'})">{{
                    $t('checkout-payment.payment-method')
                  }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('checkout-payment.sum') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->

    <section id="content-2">
      <div class="container">
        <div id="pay-list" v-if="!loading">
          <div class="row">
            <div class="col-12 mb-1" style="font-weight: 600; font-size: 20px; color: #757575">
              {{ $t('checkout-summary.sum') }}
            </div>
            <div id="cart-items" class="col-lg-8 col-12 d-flex flex-column">
              <div v-for="item in $store.state.user.cart_items" :key="item.id" class="cart-item-container">
                <div v-if="false" class="remove-cart-container" @click.prevent="removeFromCart(item)"
                     :class="submitting ? 'disabled' : ''">
                  <i class="fas fa-times"/>
                </div>
                <div class="cart-item">
                  <div class="cart-item-image">
                    <img
                        :src="item.cartable.cover_image ? $store.getters.getStorageURL(item.cartable.cover_image.path) : '/assets/img/demo/training.png'"
                    />
                  </div>
                  <div class="cart-item-content pb-2 pt-2">
                    <div>
                      <div style="font-weight: 600; color: white; font-size: 18px;">{{ item.cartable.title }}</div>
                      <div style="font-weight: 400; color: white; font-size: 14px;">
                        {{
                          item.cartable.course_type ? $t(`course-types.${item.cartable.course_type.name}`) : 'Membership fee'
                        }}
                      </div>
                    </div>
                    <div v-if="item.cartable_type === 'App\\Models\\Course'">
                      <div style="font-weight: 400; color: white; font-size: 11px;">
                        {{ $t('course.begin') }}:
                        <strong>
                          {{
                            item.cartable.start_date
                                ? $store.getters.parseDateTime(item.cartable.start_date)
                                : $t('course.no')
                          }}
                        </strong>
                      </div>
                      <div style="font-weight: 400; color: white; font-size: 11px;">
                        {{ $t('course.end') }}:
                        <strong>
                          {{
                            item.cartable.end_date
                                ? $store.getters.parseDateTime(item.cartable.end_date)
                                : $t('course.no')
                          }}
                        </strong>
                      </div>
                    </div>
                    <div>
                      <div style="font-weight: 600; color: white; font-size: 20px;">
                        {{
                          huFormat(
                            (parseFloat(item.cartable.net_price )* exchangeRate) +
                            ((parseFloat(item.cartable.net_price )* exchangeRate) *
                              ($store.getters.vatRate / 100)) 
                           
                          )
                        }}

                      </div>
                      <div style="font-weight: 400; color: white; font-size: 11px;">
                        <span>{{ huFormat(item.cartable.net_price * exchangeRate) }} + {{ $store.getters.vatRate }}% {{
                            $t('cart.vat')
                          }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-wrap justify-content-between" style="gap: 1.5rem;">
                <div>
                  <div style="font-weight: 600; color: #757575; font-size: 18px;">{{
                      $t('checkout-summary.coupon-inp')
                    }}
                  </div>
                  <div v-if="$store.state.user.cart_items.some((item)=>item.cartable?.coupon_compatible)"
                       class="d-flex flex-wrap" style="gap: 1.5rem;">
                    <div>
                      <input type="text" :style="coupon_error ? 'border: 1px solid red;' : '' " class="form-control"
                             @input="coupon_error = false" v-model="coupon_code"
                             :placeholder="$t('checkout-summary.coupon-code')">
                      <p class="mt-1 mb-0 ml-1" style="color: red;" v-show="coupon_error">
                        {{ $t('checkout-summary.coupon-error') }}</p>
                      <p class="ml-1" style="color: red;" v-for="err in coupon_errors" :key="err">{{ err }}</p>
                    </div>
                    <div v-if="$store.state.checkout?.coupon_code">
                      <button class="w-100 btn btn-theme" style="font-weight: 600" @click="removeCoupon"
                              :disabled="submitting">{{ $t('checkout-summary.coupon-remove') }}
                      </button>
                    </div>
                    <div v-else>
                      <button class="w-100 btn btn-theme" style="font-weight: 600" @click="setCoupon"
                              :disabled="submitting">{{ $t('checkout-summary.coupon-set') }}
                      </button>
                    </div>
                  </div>
                  <div v-else class="d-flex flex-wrap" style="gap: 1.5rem;">
                    <div>
                      <p class="mt-1 ml-1" style="color: #757575">{{ $t('checkout-summary.coupon-usage-disabled') }}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div style="font-weight: 600; color: #757575; font-size: 18px;">
                    {{ $t('checkout-summary.currency') }}
                  </div>
                  <select class="form-control" style="width: 150px;" v-model="selectedCurrency">
                    <option v-for="currency in $store.state.currencies" :key="currency.currency"
                            :value="currency.currency" >{{ currency.currency }}
                    </option>
                  </select>
                  <sub v-if="currency !== 'EUR'" style="font-weight: bold">{{ huFormat(1, 'EUR') }} =
                    {{ huFormat(exchangeRate, currency) }}</sub>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-12 order-summary-container">
              <div class="order-summary mt-2" style="margin-bottom: 0px !important;">
                <div class="d-flex justify-content-between">
                  <div style="font-weight: 600; color: #757575; font-size: 20px;">
                    {{ $t('cart.netto-total') }}
                  </div>
                  <div style="font-weight: 600; font-size: 20px;">
                    {{ huFormat(netTotal * exchangeRate) }}
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div style="font-weight: 600; color: #757575; font-size: 20px;">
                    {{ $t('checkout-summary.vat') }}
                  </div>
                  <div style="font-weight: 600; font-size: 20px;">
                    {{ huFormat(vatSum * exchangeRate) }}
                  </div>
                </div>
                <div class="d-flex justify-content-between" v-if="discount > 0">
                  <div style="font-weight: 600; color: #757575; font-size: 20px;">
                    {{ $t('checkout-summary.coupon') }}
                  </div>
                  <div style="font-weight: 600; font-size: 20px;">
                    -{{ $store.getters.formatPrice(discount ) }}
                  </div>
                </div>
                <div style="border: 1px dashed #DADADA; height:unset" class="mt-2 mb-2"></div>
                <div class="d-flex justify-content-between">
                  <div style="font-weight: 600; color: #757575; font-size: 20px;">
                    {{ $t('cart.gross-total') }}
                  </div>
                  <div style="font-weight: 600; font-size: 20px;">
                    {{ huFormat(grossTotal * exchangeRate) }}
                  </div>
                </div>
                <div style="border: 1px dashed #DADADA; height:unset" class="mt-2 mb-2"></div>
                <div v-if="orderInProgress" class="col-12 mt-3" style="font-size: 22px">
                  <p>{{ $t('checkout-summary.payment-process-1') }} <strong>{{
                      $t('checkout-summary.payment-process-2')
                    }}</strong> {{ $t('checkout-summary.payment-process-3') }}</p>
                  <p>{{ $t('checkout-summary.payment-process-4') }} <a :href="orderInProgress.checkout_url"
                                                                       target="_blank"
                                                                       style="text-decoration: underline; color: var(--black)">{{
                      $t('checkout-summary.payment-process-5')
                    }}</a> {{ $t('checkout-summary.payment-process-6') }}</p>
                </div>
                <div class="d-flex justify-content-center">
                  <!--                                <button class="btn btn-theme" style="font-weight: 600;" :disabled="orderInProgress || submitting" @click.prevent="startPayment">{{ $t('checkout-summary.finish') }}</button>-->
                  <button v-if="(grossTotal * exchangeRate) != 0" class="btn btn-theme" style="font-weight: 600"
                    :disabled="orderInProgress || submitting" @click.prevent="$router.push({ name: 'InvoicePreview' })">
                    {{ $t("checkout-summary.invoice-preview") }}
                  </button>
                  <button v-else class="btn btn-theme" style="font-weight: 600" :disabled="orderInProgress || submitting"
                    @click.prevent="startPayment">
                    {{ $t("checkout-summary.finish") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div id="empty-cart-section" class="d-flex justify-content-center"
               style="margin-top: 12vh; flex-direction: column; align-items: center"
               v-if="$store.state.user.cart_items.length === 0">
            <h3 style="width: 100%;text-align: center;margin-bottom: 20px;margin-top: 50px;">{{ $t('cart.empty') }}</h3>
            <button class="btn btn-outline-primary-red" @click.prevent="$router.push({name: 'MyOrders'})"
                    style="width: fit-content;">{{ $t('cart.my-orders') }}
            </button>
            <div></div>
          </div>
        </div>
        <Loading v-else height="70vh"/>
      </div>
    </section>
  </div>
</template>
<script>
import {Capacitor, Plugins} from '@capacitor/core';

const {Browser} = Plugins;
export default {
  name: 'CheckoutSummary',
  created() {
    if (!this.$store.state.checkout) {
      this.$router.push({name: 'CheckoutUserData'})
    } else if (this.$store.state.checkout.card === 0) {
      this.$router.push({name: 'CheckoutPaymentMethod'})
    } else {
      this.coupon_code = this.$store.state.checkout.coupon_code ? this.$store.state.checkout.coupon_code.code : null
      this.created = true
    }
    this.$store.dispatch('getExchangeRates')
    this.$store.dispatch('getCurrencies')
  },
  data() {
    return {
      coupon_code: null,
      coupon_error: false,
      coupon_errors: [],
      submitting: false,
      acceptPrivPol: false,
      acceptTerms: false,
      selectedCurrency: this.$store.state.currency,
      created: false
    }
  },
  watch: {
    selectedCurrency(val) {
      this.$store.commit('setCurrency', val)
      this.$store.state.checkout.currency = val
    }
  },
  methods: {
    huFormat(price, currency = this.currency) {
      return new Intl.NumberFormat(this.$store.getters.isoLocale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0
      }).format(price)
    },
    startPayment() {
      this.submitting = true
      this.$store.state.checkout.currency = this.$store.state.currency
      this.axios.post('/payment/start', this.$store.state.checkout)
          .then(resp => {
            if (resp.data.paymentRequired) {
              if (this.$store.state.isNative && Capacitor.getPlatform() !== 'ios') {
                Browser.open({url: resp.data.order.checkout_url, presentationStyle: 'popover'});
              } else {
                window.open(resp.data.order.checkout_url, '_self');
              }
              this.loading = true
            } else {
              this.$router.push({
                name: 'AfterPayment',
                params: {'paymentRequestId': resp.data.order.payment_request_id},
                query: {'paymentId': resp.data.order.payment_id}
              })
            }
            this.$store.state.checkout = null
            this.axios.get('/user')
                .then(resp => this.$store.state.user = resp.data)
                .finally(() => this.loading = false)
          })
          .catch(e => console.log(e))
          .finally(() => this.submitting = false)
    },
    setCoupon() {
      this.submitting = true
      this.coupon_error = false;
      this.coupon_errors = [];
      this.axios.get('/coupons/check', {params: {coupon: this.coupon_code}})
          .then(resp => {
            this.$store.state.checkout.coupon_code = resp.data.coupon
          })
          .catch((e) => {
            this.coupon_error = true;
            this.coupon_errors = e.response.data.errors.coupon;
          })
          .finally(() => this.submitting = false)
    },
    removeCoupon() {
      this.coupon_code = null;
      this.coupon_error = false;
      this.coupon_errors = [];
      this.$store.state.checkout.coupon_code = null;
    }
  },
  computed: {
    orderInProgress() {
      return this.$store.state.user.orders.find(o => o.status === 'Prepared' || o.status === 'Started' || o.status === 'InProgress');
    },
    currency() {
      return this.$store.state.currency;
    },
    exchangeRate() {
      if (!this.$store.state.checkout || this.$store.state.checkout.currency === "EUR") return 1;
      return this.$store.state.exchangeRates.find(r => r.secondary_currency === this.$store.state.currency).exchange_rate
    },
    netTotal() {
      let total = 0;
      if (this.$store.state.user.cart_items.length > 0) {
        this.$store.state.user.cart_items.forEach((item) => {
          if (item.cartable.coupon_compatible) {
            total += item.cartable.net_price;
          }
        });
      }
      return total
    },
    discount() {
      const checkout = this.$store.state.checkout;
      const couponCode = checkout && checkout.coupon_code;
      let discount = 0;

      console.log("couponCode", couponCode);

      if (couponCode) {
        if (couponCode.course || couponCode.membership) {
          this.$store.state.user.cart_items.forEach((item) => {
            // Check if the item is a course and if the coupon is for a specific course
            if (item.cartable_type === 'App\\Models\\Course' && couponCode.course && couponCode.course.id === item.cartable.id) {
              if (item.cartable.coupon_compatible) {
                discount += couponCode.percentage_based
                    ? Math.min(item.cartable.net_price * (couponCode.discount / 100), item.cartable.net_price)
                    : Math.min(couponCode.discount, item.cartable.net_price);
              }
            }
            // Check if the item is a membership and if the coupon is for a specific membership
            else if (item.cartable_type === 'App\\Models\\Membership' && couponCode.membership) {
              discount += couponCode.percentage_based
                  ? Math.min(item.cartable.net_price * (couponCode.discount / 100), item.cartable.net_price)
                  : Math.min(couponCode.discount, item.cartable.net_price);
            }
          });
        } else {
          // Calculate the general coupon discount
          discount = couponCode.percentage_based
              ? Math.min(this.netTotal * (couponCode.discount / 100), this.netTotal)
              : Math.min(couponCode.discount, this.netTotal);
        }

        console.log("discount", Math.round(discount));
        return Math.round(discount);
      }

      return 0;
    },
    discountedNetTotal() {
      return this.netTotal - this.discount;
    },
    vatSum() {
      const vatAmount = (this.discountedNetTotal * (this.$store.getters.vatRate / 100));
      return vatAmount;
    },
    grossTotal() {
      return parseFloat(this.netTotal) + this.vatSum - this.discount;
    },
  },
}
</script>
<style scoped>
hr {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #c6c6c6;
  height: 1px;
}

.cp-bg {
  background-color: var(--progressbg);
  margin-left: 0px !important;
  margin-right: 0px !important;
  border-top: 1px solid #c6c6c6;
  border-bottom: 1px solid #c6c6c6;
  padding-top: 15px;
  margin-top: 40px;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.cp-bg h4 {
  margin-top: 5px;
}

.btn-share {
  font-size: 1rem;
  width: auto;
}

@media (max-width: 991px) {
  .btn-share {
    width: 100% !important;
  }
}
</style>
