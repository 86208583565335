<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('glossary.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('glossary-article.knowledge-base') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->

        <!-- Modal -->
        <div class="modal fade" id="helpModal" tabindex="-1" role="dialog" aria-labelledby="helpModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="helpModalLabel">{{ $t('glossary.search-help') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        {{ $t('glossary.search-info') }}
                        <br>
                        <ul>
                        <div class="row mt-4">
                            <div class="col-12">
                                <h2><strong>+</strong></h2>
                            </div>
                             <div class="col-12">
                                 {{ $t('glossary.text1') }}

                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12">
                                <h2><strong>-</strong></h2>
                            </div>
                             <div class="col-12">
                                 {{ $t('glossary.text2') }}
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12">
                                <h4><strong>{{ $t('glossary.no-operator') }}</strong></h4>
                            </div>
                             <div class="col-12" >
                              {{ $t('glossary.text3') }}
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12">
                                <h4><strong>@distance</strong></h4>
                            </div>
                             <div class="col-12" >
                                 {{ $t('glossary.text4') }}
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12">
                                <h4><strong>&gt; &lt;</strong></h4>
                            </div>
                             <div class="col-12" >
                                 {{ $t('glossary.text5') }}
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12">
                                <h4><strong>( )</strong></h4>
                            </div>
                             <div class="col-12" >
                                 {{ $t('glossary.text6') }}
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12">
                                <h4><strong>~</strong></h4>
                            </div>
                             <div class="col-12" >
                                 {{ $t('glossary.text7') }}
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12">
                                <h4><strong>*</strong></h4>
                            </div>
                             <div class="col-12" >
                               <p>{{ $t('glossary.text8') }}
                                   </p>
                               <p>{{ $t('glossary.text9') }}
                                   </p>
                               <p>{{ $t('glossary.text10') }}
                                   </p>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12">
                                <h4><strong>"</strong></h4>
                            </div>
                             <div class="col-12" >
                                <p>{{ $t('glossary.text11') }}
                                    </p>
                                <p>{{ $t('glossary.text12') }}
                                    </p>
                            </div>
                        </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div id="content-2">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3" v-if="$store.state.user.role == 'Admin' || $store.state.user.role == 'Master'">
                        <section id="new-entry">
                            <div class="row">
                                <div class="col-12">
                                    <div class="flex-wrap d-flex">
                                        <div class="d-flex align-items-center pointer mr-auto" @click="$router.push({name: 'CreateGlossaryArticle'})">
                                            <div class="new-button-50 d-flex align-items-center justify-content-center">
                                                <img src="assets/img/icons/plus.svg" class="img-fluid" alt="">
                                            </div>
                                            <div class="d-flex flex-column ml-2">
                                                <h6 class="text-16 text-dark-gray">{{ $t('glossary.new-entry') }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div v-bind:class="{ 'col-lg-9': $store.state.user.role == 'Admin' || $store.state.user.role == 'Master', 'col-12': '' }">
                        <section id="search-bar">
                            <div class="row">
                                <div class="col-lg-6 col-12 custom-input">
                                    <form @submit.prevent="startSearch">
                                        <div class="input-group mb-3">
                                            <input type="text" class="form-control" :placeholder="$t('chat.search-2')" v-model="searchStr">
                                            <div class="input-group-append" @click="startSearch()">
                                                <span class="input-group-text" id=""><img src="assets/img/icons/search.svg" alt=""></span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-12 mt-4">
                                    <span v-for="label in labels" :key="label" class="mr-2 p-1 d-inline-flex align-items-center" style="flex-grow: 0; background-color: var(--red); color: white; border-radius: 3px; width: fit-content">
                                        <span>{{label}}</span>
                                        <span @click="removeLabelFromFilter(label)" class="d-inline-flex align-items-center font-18"><i class="fas fa-times ml-1" style="font-size: 18px; cursor: pointer;"></i></span>
                                    </span>
                                </div>
                                <div class="col-12 mt-4 mb-2" v-if="searchBy">
                                    <h4 class="d-inline-flex align-items-end">
                                        <span>{{ $t('glossary.found') }} <strong>{{searchBy}}</strong></span>
                                        <span @click="clearSearch()" class="ml-1 d-inline-flex align-items-center" style="cursor: pointer"><i class="fas fa-times" style="color: red"></i></span>
                                    </h4>
                                </div>
                            </div>
                        </section>
                        <section id="article-list">
                            <div class="row" v-if="!loading && articles.length > 0">
                                <div class="col-12 text-18 text-dark-gray semi-bold mb-3">{{$t('glossary.article-count')}} {{paginator.total}} </div>
                                <div class="col-12" v-for="article in articles" :key="article.id">
                                    <!-- ARTICLE -->
                                    <div class="article-box bg-light-gray-primary mb-3 col-12">
                                        <div class="row">
                                            <div class="article-header col-lg-1" v-if="$store.state.user.role == 'Admin'">
                                                <div class="flex-wrap d-flex h-100">
                                                    <div class="d-flex flex-column article-editor mr-3">
                                                        <img src="assets/img/icons/trash.svg" class="img-fluid mb-3" alt="" data-toggle="modal" :data-target="'#glossaryDeleteModal'+article.id">
                                                        <img src="assets/img/icons/pen.svg" class="img-fluid mb-3" alt="" @click.prevent="$router.push({name: 'EditGlossaryArticle', params: {id: article.id}})">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal fade" :id="'glossaryDeleteModal'+article.id" tabindex="-1" aria-labelledby="glossaryDeleteModal" aria-hidden="true">
                                                <div class="modal-dialog">
                                                    <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h2 class="modal-title text-20 text-light-black" id="glossaryDeleteModalLabel">{{ $t('glossary.delete-sure') }}</h2>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        {{ $t('glossary.delete-q1') }} <strong>{{article.title}}</strong> {{ $t('glossary.delete-q2') }}
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-theme mr-auto" data-dismiss="modal">{{ $t('common.cancel') }}</button>
                                                        <button type="button" class="btn btn-theme-outline" data-dismiss="modal" @click="deleteArticle(article)">{{ $t('common.delete') }}</button>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                                <div v-if="article.multimedia && article.multimedia.length > 0" class="mt-3">
                                                <div class="mb-2">
                                                    <div v-for="multimedia in article.multimedia.filter((mm) => mm.type == 'document')" :key="multimedia.id" class="ml-2 mt-2">
                                                        <span>
                                                            <a :href="storageURL+multimedia.path+'?token='+token" target="_blank">
                                                                <i class="far fa-file-alt mr-1 font-20" alt="#" style="font-size: 20px !important;"></i>
                                                                <span style="color: var(--black)">{{multimedia.name}}</span>
                                                            </a>
                                                        </span>
                                                    </div>
                                                    <div v-for="multimedia in article.multimedia.filter((mm) => mm.type == 'audio')" :key="multimedia.id" class="mt-4">
                                                        <p class="ml-3 mb-0">{{multimedia.name}}</p>
                                                        <audio controls>
                                                            <source :src="storageURL+multimedia.path+'?token='+token">
                                                        </audio>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div v-for="multimedia in article.multimedia.filter((mm) => mm.type == 'video' || mm.type == 'image')" :key="multimedia.id" class="col-sm-12 col-md-6 col-lg-12 col-xl-6 mt-4" style="height: 160px;">
                                                        <div v-if="multimedia.type == 'video' || multimedia.type == 'image' " style="color: var(--black); cursor:pointer; background-color: grey; width: 100%; height: 100%"
                                                            data-fancybox="fancy"
                                                            :href="multimedia.type != 'video' ? storageURL+multimedia.path+'?token='+token : '#'+multimedia.id" target="_blank"
                                                            :data-caption="multimedia.name"
                                                        >
                                                            <div v-if="multimedia.type == 'video'" style="width: 100%; height: 100%">
                                                                <video style="width: 100%; height: 100%; pointer-events: none;" nocontrols >
                                                                    <source :src="storageURL+multimedia.path+'?token='+token">
                                                                </video>
                                                                <video :id="multimedia.id" style="display: none;" controls controlsList="nodownload">
                                                                    <source :src="storageURL+multimedia.path+'?token='+token">
                                                                </video>
                                                                <i class="far fa-play-circle playbtn"></i>
                                                            </div>
                                                            <div v-if="multimedia.type == 'image'" style="width: 100%; height: 100%">
                                                                <img :src="storageURL+multimedia.path+'?token='+token" style="width: 100%; height: 100%">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-bind:class="{ 'col-lg-11': $store.state.user.role == 'Admin', 'col-12': '' }">
                                                <!-- BADGE FRAME -->
                                                    <div class="flex-wrap d-flex">
                                                        <!-- BADGE -->
                                                        <div class="d-flex mr-3 mb-2" v-for="label in article.labels" :key="label.id">
                                                            <span class="article-badge"><img src="assets/img/icons/badge.svg" class="mr-1 img-fluid" alt="" @click="addLabelToFilter(label.name)">{{label.name}}</span>
                                                        </div>
                                                        <!-- //BADGE -->
                                                    </div>
                                                <!-- //BADGE FRAME -->
                                                <h1 class="text-22 text-white semi-bold pointer" @click.prevent="$router.push({name: 'GlossaryArticle', params: {id: article.id}})">{{article.title}}</h1>
                                                <div class="article-lore mt-3">
                                                    <h2 class="text-14 medium text-white" v-html="$store.getters.sanitizeSummernoteText(article.content)" @click.prevent="$router.push({name: 'GlossaryArticle', params: {id: article.id}})"></h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- //ARTICLE -->
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
                <div v-if="loading" class="row">
                    <div class="col-12">
                        <Loading height="20vh" />
                    </div>
                </div>
                <div class="row" v-else-if="paginator">
                    <regular-paginator :paginator="paginator" @loadPage="loadArticles" classes="w-100 mb-2" />
                </div>
                <div v-else-if="searchBy" class="col-12 mt-5" style="text-align: center;">
                    <h3> {{ $t('glossary.nothing') }} </h3>
                </div>
                <div v-else class="col-12 mt-5" style="text-align: center;">
                    <h3>{{ $t('glossary.empty') }}</h3>
                </div>
    </div>
</template>
<script>
import { Capacitor } from '@capacitor/core';
import Loading from '@/components/Loading'
import InfiniteScroll from '../components/InfiniteScroll.vue'
import RegularPaginator from '../components/RegularPaginator.vue'
export default {
    name: 'Glossary',
    components: {
        Loading,
        InfiniteScroll,
        RegularPaginator
    },
    mounted() {
      this.axios.get('/glossary-articles').then(resp => {
            this.articles = resp.data.glossaryArticles
            this.paginator = resp.data.paginator
            this.loading = false
        })
    },
    data() {
        return {
            isNative: Capacitor.isNative,
            articles: [],
            paginator: null,
            articlesFiltered: [],
            recentlyAddedLabel: null,
            filteringBy: false,
            searchBy: null,
            labels: [],
            loading: true,
            token: this.axios.defaults.headers.common.Authorization.split(' ')[1],
            storageURL: process.env.VUE_APP_STORAGE_URL,
            searchStr: '',
        }
    },
    computed: {
        pages() {
            if(this.paginator) {
                let arr = Array()
                for(let i = 1; i <= this.paginator.last_page; i++) {
                    arr.push(i)
                }
                return arr
            }
            return 1
        }
    },
    methods: {
        startSearch() {
            this.loading = true
            this.searchBy = this.searchStr ? this.searchStr.trim() : null
            let params = {
                search: this.searchBy,
                labels: this.labels,
                newLabel: this.recentlyAddedLabel
            }
            this.recentlyAddedLabel = null
            console.log(params)
            this.axios.get(`/glossary-articles`, {params}).then(resp => {
                this.articles = resp.data.glossaryArticles
                this.paginator = resp.data.paginator

            }).finally(() => this.loading = false)
        },
        loadArticles(page) {
            this.loading = true
            let params = {
                search: this.searchBy,
                labels: this.labels,
                page: page
            }
            this.paginator.current_page = page
            this.axios.get('/glossary-articles', {params})
                .then(resp => {
                    this.articles = resp.data.glossaryArticles
                    this.paginator = resp.data.paginator
                })
                .finally(() => this.loading = false)
        },
        clearSearch() {
            this.searchStr = null
            this.startSearch()
        },
        addLabelToFilter(labelName) {
            if(!this.labels.some(label => label == labelName)) {
                this.recentlyAddedLabel = labelName
                this.labels.push(labelName)
                this.startSearch()
            }
        },
        removeLabelFromFilter(labelName) {
            const labelIdx = this.labels.findIndex(label => label == labelName)
            if(labelIdx !== -1) {
                this.labels.splice(labelIdx, 1)
                this.startSearch()
            }
        },
        deleteArticle(article) {
            this.loading = true
            this.axios.delete('glossary-articles/'+article.id)
                .then((resp) => this.articles.splice(this.articles.findIndex(a => article.id == a.id), 1))
                .finally(() => this.loading = false)
        }
    }
}
</script>
<style scoped>
    #new-entry,
    #search-bar {
        margin-top: 30px;
    }
</style>
