<template>
    <section id="big-menu">
        <div class="container2">
            <div class="row text-center">
                <div class="col-lg-6 col-12 no-left">
                    <div class="butt shadow" @click.prevent="$router.push({name: 'ManageCourses'})">
                        <a href="#" >{{ $t('admin-menu.managing-course') }}</a>
                    </div>
                </div>
                <div class="col-lg-6 col-12 no-right">
                    <div class="butt shadow"  @click.prevent="$router.push({name: 'ManageTests'})">
                        <a href="#">{{ $t('admin-menu.managing-test') }}</a>
                    </div>
                </div>
                <div class="col-lg-6 col-12 no-left">
                    <div class="butt shadow"  @click.prevent="$router.push({name: 'ManageUsers'})">
                        <a href="#">{{ $t('admin-menu.managing-user') }}</a>
                    </div>
                </div>
                <div class="col-lg-6 col-12 no-right">
                    <div @click.prevent="$router.push({name: 'Reporting'})" class="butt shadow ">
                        <a href="#">{{ $t('admin-menu.stats') }}</a>
                    </div>
                </div>
                <div class="col-lg-6 col-12 no-left">
                    <div @click.prevent="$router.push({name: 'CreateGlossaryArticle'})" class="butt shadow">
                        <a href="#">{{ $t('admin-menu.knowledge-new') }}</a>
                    </div>
                </div>
                <div class="col-lg-6 col-12 no-right" @click.prevent="$router.push({name: 'UnapprovedGlossary'})">
                    <div class="butt shadow">
                        <a href="#">{{ $t('admin-menu.knowledge-edit') }}</a>
                    </div>
                </div>
                <div class="col-lg-6 col-12 no-left" @click.prevent="$router.push({name: 'ManageNews'})">
                    <div class="butt shadow">
                        <a href="#">{{ $t('admin-menu.manage-news') }}</a>
                    </div>
                </div>
                <div class="col-lg-6 col-12 no-right" @click.prevent="$router.push({name: 'ManageCoupons'})">
                    <div class="butt shadow">
                        <a href="#">{{ $t('admin-menu.manage-coupons') }}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'AdminMenu',
}
</script>
<style scoped>
.butt.shadow {
    cursor: pointer;
}
.butt.shadow:hover {
    background: var(--red) !important;
}

@media (max-width: 991px) { 
    #big-menu {
        display: none;
    }
}
</style>