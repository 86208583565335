<template>
    <form @submit.prevent="setProfessionalHistory">
        <slot>
            <div class="col-12 mt-2">
                <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('settings.professional-history') }} </h3>
            </div>
        </slot>
        <div class="col-12 mt-2">
            <label class="required">{{ $t('settings.job-sector') }}</label>
            <div v-if="professionalHistoryErrors['job_sector']">
                <span v-for="(error, idx) in professionalHistoryErrors['job_sector']" style="color: red;" :key="idx">{{error}}</span>
            </div>
            <select class="form-control" @input="setJobSector">
                <option disabled :selected="professionalHistoryData.job_sector === null">{{$t('common.select-from-list')}}</option>
                <option v-for="jobSector in jobSectors" :value="jobSector" :key="jobSector" :selected="professionalHistoryData.job_sector === jobSector">{{ $t('job-sectors.'+jobSector) }}</option>
                <option value="Other" :selected="isOtherSelected">{{$t('common.other')}}</option>
            </select>
            <div v-if="isOtherSelected">
                <label class="required">{{$t('settings.other-job-sector')}}</label>
                <input type="text" :placeholder="$t('settings.sq')" v-model="professionalHistoryData.job_sector" class="form-control" :style="{border: professionalHistoryErrors['job_sector'] ? '1px solid red' : '1px solid #ced4da'}" required>
            </div>
        </div>
        <div class="col-12 mt-2">
            <label class="required">{{ $t('settings.skills-and-qualifications') }}</label>
            <div v-if="professionalHistoryErrors['skills_and_qualifications']">
                <span v-for="(error, idx) in professionalHistoryErrors['skills_and_qualifications']" style="color: red;" :key="idx">{{error}}</span>
            </div>
            <textarea :placeholder="$t('settings.sqq')" v-model="professionalHistoryData.skills_and_qualifications" class="form-control" :style="{border: professionalHistoryErrors['skills_and_qualifications'] ? '1px solid red' : '1px solid #ced4da'}" required></textarea>
        </div>
        <div class="col-12 mt-2">
            <label class="required">{{ $t('settings.work-experience') }}</label>
            <div v-if="professionalHistoryErrors['work_experience']">
                <span v-for="(error, idx) in professionalHistoryErrors['work_experience']" style="color: red;" :key="idx">{{error}}</span>
            </div>
            <textarea :placeholder="$t('settings.wq')" v-model="professionalHistoryData.work_experience" class="form-control" :style="{border: professionalHistoryErrors['work_experience'] ? '1px solid red' : '1px solid #ced4da'}" required></textarea>
        </div>
        <div class="col-12 mt-2">
             <label class="required">{{ $t('settings.studio') }}</label>
            <input type="text" :placeholder="$t('settings.studio')" v-model="professionalHistoryData.studio" class="form-control" :style="{border: professionalHistoryErrors['studio'] ? '1px solid red' : '1px solid #ced4da'}" required>
        </div>
        <div class="col-12 mt-2">
             <label class="required">{{ $t('settings.current-position') }}</label>
            <input type="text" v-model="professionalHistoryData.current_position" class="form-control" :style="{border: professionalHistoryErrors['current_position'] ? '1px solid red' : '1px solid #ced4da'}" required>
        </div>
        <div class="col-12 mt-2">
             <label>{{ $t('settings.current-ems-certification') }}</label>
            <input type="text" v-model="professionalHistoryData.current_ems_certification" class="form-control" :style="{border: professionalHistoryErrors['current_ems_certification'] ? '1px solid red' : '1px solid #ced4da'}">
        </div>
        <div class="col-12 mt-2">
            <label class="required">{{ $t('settings.device') }}</label>
            <div v-if="professionalHistoryErrors['device']">
                <span v-for="(error, idx) in professionalHistoryErrors['device']" style="color: red;" :key="idx">{{error}}</span>
            </div>
            <select class="form-control" v-model="professionalHistoryData.device">
                <option disabled :value="null">{{$t('common.select-from-list')}}</option>
                <option v-for="device in devices" :value="device" :key="device">{{device}}</option>
            </select>
        </div>
        <div class="col-12">
            <div class="flex-wrap d-flex">
                <div class="d-flex ms-auto">
                    <button :disabled="loading" type="submit" class="btn btn-theme mt-3 mb-3 "><img src="assets/img/icons/save.svg" class="img-fluid mr-2" alt="">{{ $t('settings.save-invoice') }}</button>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
export default {
    name: 'professionalHistoryData',
    props: {
        errors: {
            type: Object,
            optional: true,
            default: null
        },
        withSave: {
            type: Boolean,
            optional: true,
            default: true
        }
    },
    data() {
        return {
            loading: false,
            localErrors: {},
            jobSectors: [
                'Business',
                'Beauty',
                'Fitness',
                'Medical',
                'Professional sports',
                'Physiotherapy'
            ],
            devices: [
                'Newave USA',
                'Newave',
                'Newave Med',
                'Actiwave'
            ],
            professionalHistoryData: this.$store.state.user && this.$store.state.user.professional_history ? this.$store.state.user.professional_history : {job_sector: null, device: null}
        }
    },
    computed: {
        professionalHistoryErrors() {
            return this.errors ?? this.localErrors
        },
        isOtherSelected() {
            return this.professionalHistoryData && this.professionalHistoryData.job_sector !== null && !this.jobSectors.find(js => js === this.professionalHistoryData.job_sector)
        }
    },
    methods: {
        setJobSector(e) {
            if(e.target.value === 'Other') this.professionalHistoryData.job_sector = ""
            else this.professionalHistoryData.job_sector = e.target.value
        },
        setProfessionalHistory() {
            this.loading = true
            this.axios.post('/professional-history', this.$store.getters.removeEmptyOrNull(this.professionalHistoryData))
                .then(resp => {
                    this.$store.state.user = resp.data.user
                })
                .catch(e => this.localErrors = e.response.data.errors)
                .finally(() => this.loading = false)
        },
    }
}
</script>