<template>
  <button @click="click" class="icon-button" :class="class">
    <Icon :name="icon" />
  </button>
</template>
<script>
import Icon from "../Icon";

export default {
  name: "IconButton",
  components: { Icon },
  props: {
    click: {},
    icon: {
      type: String,
      default: "add",
    },
    class: { type: String, default: "" },
  },
};
</script>
<style scoped>
/* START BUTTONS */

button.icon-button {
  background-color: #d62b33;
  border-radius: 5px;
  border: 3px solid #d62b33;
  display: flex;
  align-items: center;
  padding: 5px;
  position: absolute;
  justify-content: center;
  width: 50px;
  height: 50px;
  //box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
}

button.icon-button span {
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-left: 10px;

  color: #ffffff;
}

::v-deep button.icon-button .icon svg path {
  fill: #fff !important;
}
button.icon-button:hover {
  background-color: var(--dark-red);
  border: 3px solid var(--dark-red);
}
/* END BUTTONS */
</style>
