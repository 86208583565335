<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('edit-test.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'ManageTests'})">{{ $t('edit-test.managing-tests') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex mr-4">
                                <span v-if="test" class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'EditTest', params: {course: test.id}})">{{ test.name }}</span>
                                <span v-else class="text-16 text-white pt-3 pb-3">{{$t('test-question-assembler.test')}}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('test-question-assembler.test-edit') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->
        <div class="container mt-3">
            <div v-if="!loading && test" class="d-flex gap-3">
                <new-question-modal :questionTypes="questionTypes" :test="test" @newQuestionCreated="onNewQuestionCreated" />
                <new-test-group-modal :test="test" @newTestGroupCreated="onNewTestGroupCreated" />
                <div class="d-flex flex-column gap-3" style="">
                    <div class="d-flex align-items-center gap-2">
                        <circle-button width="50px" height="50px" data-toggle="modal" data-target="#newQuestionModal">
                            <i class="fas fa-plus" />
                        </circle-button>
                        <div class="text-18 weight-500 text-title-gray">
                            {{ $t('test-question-assembler.new-question') }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center gap-2">
                        <circle-button width="50px" height="50px" data-toggle="modal" data-target="#newTestGroupModal">
                            <i class="fas fa-plus" />
                        </circle-button>
                        <div class="text-18 weight-500 text-title-gray">
                            {{ $t('test-groups.new-group') }}
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column gap-3" style="flex-grow: 1;">
                    <div class="d-flex px-2 py-3 gap-2" style="align-items: center; border-radius: 7px; background-color: #768692; color: white;">
                        <div class="weight-700 text-22">{{$t('test-groups.groups')}}: </div>
                        <div class="text-22 pointer"
                            :class="groupFilter == null ? 'weight-700' : 'weight-500'"
                            @click.prevent="groupFilter = null"
                        >
                            {{test.test_groups.length == 0 ? $t('test-groups.none') : $t('test-groups.all')}} ({{test.test_questions.length}})
                        </div>
                        <div class="text-22 pointer"
                            v-for="group in test.test_groups"
                            :key="group.id"
                            :class="groupFilter == group.id ? 'weight-700' : 'weight-500'"
                            @click.prevent="groupFilter = group.id"
                        >
                            {{group.name}} ({{questionsInGroup(group.id)}})
                        </div>
                    </div>
                    <test-question-card v-for="question in filteredQuestions" :key="question.id" :question="question" :test="test" :questionTypes="questionTypes" />
                </div>
            </div>
            <loading v-else />
        </div>
    </div>
</template>
<script>
import Loading from '@/components/Loading.vue'
import GenericFileUploader from '../components/GenericFileUploader.vue';
import CircleButton from '../components/CircleButton.vue'
import TestQuestionCard from '../components/TestQuestionCard.vue';
import NewQuestionModal from '../components/TestQuestionAssembler/NewQuestionModal.vue';
import NewTestGroupModal from '../components/TestQuestionAssembler/NewTestGroupModal.vue';
export default {
    name: 'TestQuestionAssembler',
    components: {
        Loading,
        GenericFileUploader,
        CircleButton,
        TestQuestionCard,
        NewQuestionModal,
        NewTestGroupModal
    },
    mounted() {
        this.loading = true;
        this.axios.get('/tests/'+this.$route.params.test)
            .then(resp => {
                this.test = resp.data.test
                this.test.test_questions.forEach(el => {
                    el.edit = JSON.parse(JSON.stringify(el))
                    el.test_question_answers.forEach(qa => {
                        qa.edit = JSON.parse(JSON.stringify(qa))
                    })
                    el.sub_questions.forEach(sq => {
                        sq.edit = JSON.parse(JSON.stringify(sq))
                    })
                    el.newAnswer = {
                        answer: null,
                        correct: el.questionType == 'Free text'
                    }
                    el.newSubQuestion = {
                        question: null,
                        multimedia_id: null,
                        correct_answer_id: null
                    }
                })
            })
            .finally(() => this.loading = false)
        this.axios.get('/question-types')
            .then(resp => this.questionTypes = resp.data.map((qt)=>({id: qt.type, text: this.$t('question-type.'+qt.type)})))
    },
    data() {
        return {
            test: null,
            questionTypes: [],
            newQuestion: {
                question: null,
                max_points: null,
                multimedia_id: null,
                multimedia: null,
                question_type: null,
                test_group_id: null,
                note: null,
                loading: false
            },
            newTestGroup: {
                name: null
            },
            submitting: false,
            groupFilter: null,
            loading: false
        }
    },
    computed: {
        finalized() {
            return this.test?.courses.some(c => c.finalized)
        },
        filteredQuestions() {
            return this.groupFilter ? this.test.test_questions.filter(q => q.test_group_id == this.groupFilter) : this.test.test_questions
        }
    },
    methods: {
        onNewQuestionCreated(question) {
            this.test.test_questions.push(question)
        },
        questionsInGroup(group) {
            return this.test.test_questions.filter(q => q.test_group_id === group).length
        },
        deleteQuestion(question) {
            question.loading = true
            this.axios.delete('/tests/'+this.test.id+'/questions/'+question.id)
                .then(resp => {
                    this.test.test_questions.splice(this.test.test_questions.findIndex((q) => q.id == question.id), 1)
                })
                .finally(() => question.loading = false)
        },
        editQuestionAnswer(answer) {
            answer.loading = true
            this.axios.patch('/questions/'+answer.test_question_id+'/answers/'+answer.id, this.$store.getters.removeEmptyOrNull(answer.edit, ['multimedia_id']))
                .then(resp => {
                    resp.data.answer.edit = JSON.parse(JSON.stringify(resp.data.answer))
                    let question = this.test.test_questions.find((q) => q.id == answer.test_question_id)
                    question.test_question_answers[question.test_question_answers.findIndex((qa) => qa.id == answer.id)] = resp.data.answer
                    //question.newAnswer = {answer: null, correct: question.questionType == 'Free text'}
                    $('#editQuestionAnswer'+answer.id+'Modal').modal('hide')
                })
                .finally(() => answer.loading = false)
        },
        deleteQuestionAnswer(answer) {
            answer.loading = true
            this.axios.delete('/questions/'+answer.test_question_id+'/answers/'+answer.id)
                .then(resp => {
                    let question = this.test.test_questions[this.test.test_questions.findIndex((q) => q.id == answer.test_question_id)]
                    console.log(question.test_question_answers.findIndex((qa) => qa.id == answer.id))
                    question.test_question_answers.splice(question.test_question_answers.findIndex((qa) => qa.id == answer.id), 1)
                })
                .finally(() => answer.loading = false)
        },
        makeVisible() {
            this.axios.post('/tests/'+this.test.id+'/visible')
                .then(resp => this.test.hidden = 0)
        },
        makeHidden() {
            this.axios.post('/tests/'+this.test.id+'/hidden')
                .then(resp => this.test.hidden = 1)
        },
        attachMultimediaToAnswer(answer, file) {
            const multimedia = {
                id: file.uuid,
                type: file.type,
                name: file.file.name,
                path: `/download/${file.type}/${file.uuid}`
            }
            answer.edit.multimedia = multimedia
            answer.edit.multimedia_id = multimedia.id
        },
        attachMultimediaToNewSubQuestion(question, file) {
            question.newSubQuestion.multimedia_id = file.uuid
            question.newSubQuestion.multimedia_type = file.type
        },
        attachMultimediaToSubQuestion(subQuestion, file) {
            const multimedia = {
                id: file.uuid,
                type: file.type,
                name: file.file.name,
                path: `/download/${file.type}/${file.uuid}`
            }
            subQuestion.edit.multimedia = multimedia
            subQuestion.edit.multimedia_id = multimedia.id
        },
        deleteSubQuestionMultimedia(subQuestion) {
            subQuestion.edit.multimedia_id = null
            subQuestion.edit.multimedia = null
        },
        editSubQuestion(subQuestion) {
            subQuestion.loading = true
            this.axios.patch('/questions/'+subQuestion.test_question_id+'/sub-questions/'+subQuestion.id, this.$store.getters.removeEmptyOrNull(subQuestion.edit, ['multimedia_id']))
                .then(resp => {
                    resp.data.subQuestion.edit = JSON.parse(JSON.stringify(resp.data.subQuestion))
                    let question = this.test.test_questions.find((q) => q.id == subQuestion.test_question_id)
                    question.sub_questions[question.sub_questions.findIndex((sq) => sq.id == subQuestion.id)] = resp.data.subQuestion
                    $('#editSubQuestionModal-'+subQuestion.id).modal('hide')
                })
                .finally(() => subQuestion.loading = false)
        },
        submitNewSubQuestion(question) {
            question.newSubQuestion.loading = true
            this.axios.post('/questions/'+question.id+'/sub-questions', this.$store.getters.removeEmptyOrNull(question.newSubQuestion))
                .then(resp => {
                    resp.data.subQuestion.edit = JSON.parse(JSON.stringify(resp.data.subQuestion))
                    question.sub_questions.push(resp.data.subQuestion)
                    question.newSubQuestion = {
                        question: null,
                        multimedia_id: null,
                        correct_answer_id: null
                    }
                    $('#newSubQuestionModal-'+question.id).modal('hide')
                })
                .finally(() => question.newSubQuestion.loading = false)
        },
        onNewTestGroupCreated(testGroup) {
            this.test.test_groups.push(testGroup)
        }
    }
}
</script>
<style lang="css" scoped>
.close {
    color: white !important;
    opacity: 1 !important;
    font-weight: 500;
}
.card {
    box-shadow: 0px 0px 0px grey;
    transition:  box-shadow .1s ease-out;
    cursor: pointer;
}
.card:hover {
    text-shadow:0px 0px 1px rgb(207, 206, 206) !important;
    box-shadow: 1px 1px 5px rgb(207, 206, 206) !important;
    transition:  box-shadow .1s ease-in;
}
#groupNav .item {
    transition: color 0.2s;
}
#groupNav .active {
    color: red;
}
#groupNav .item:hover {
    color: red;
    cursor: pointer;
}
</style>
<style>
.playbtn {
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
    font-size: 70px;
    color:white
}
.remove {
    position: absolute;
    top: 0;
    right: 0;
    color: red;
    font-size: 25px;
    transform: translate(-100%, 0%);
}
.remove:hover {
    color: rgb(112, 0, 0)
}
.playbtn:hover {
    color: grey;
}
</style>
