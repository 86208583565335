<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span
                  class="text-16 text-white pt-3 pb-3"
                  @click.prevent="$router.push({ name: 'Home' })"
                  >{{ $t("manage-coupons.homepage") }}</span
                >
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"
                  ><img
                    src="assets/img/icons/bc-seperator.svg"
                    class="h-100"
                    alt=""
                /></span>
              </div>
              <div class="d-flex mr-4 pointer">
                <span
                  class="text-16 text-white pt-3 pb-3"
                  @click.prevent="$router.push({ name: 'ManageCoupons' })"
                  >{{ $t("manage-coupons.manage-coupons") }}</span
                >
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"
                  ><img
                    src="assets/img/icons/bc-seperator.svg"
                    class="h-100"
                    alt=""
                /></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{
                  $t("manage-coupons.coupon-statistics")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div id="content-2">
      <div class="container">
        <div class="row">
          <div class="col-12 mt-4 mb-5">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="text-18 text-dark-gray semi-bold mb-3">
                {{ $t("manage-coupons.manage-coupons") }}
              </h2>
              <div class="d-flex flex-column">
                <button class="red-button mb-3" @click="printPage">
                  <span>Download PDF</span>
                  <Icon name="arrow-down" /></button
                ><button class="red-button" :disabled="downloading" @click="downloadExcel">
                  <span>Download Excel</span>
                  <Icon name="arrow-down" />
                </button>
              </div>
            </div>
          </div>
          <div
            class="col mt-4 align-content-end"
            style="display: flex; justify-content: flex-end;"
          ></div>
          <div class="col-12 px-0">
            <div class="total-coupons-statistics">
              <span class="title">{{
                $t("manage-coupons.total-number").toUpperCase()
              }}</span>
              <span class="total-coupons-count">{{ count }}</span>
            </div>
          </div>
          <div class="col-12">
            <div class="row charts-container position-relative">
              <div class="col-6 left-side px-0">
                <div class="first-part px-4 py-4">
                  <h4 class="mb-3">
                    {{ $t("manage-coupons.most-used").toUpperCase() }}
                  </h4>
                  <h5 class="mb-4">
                    {{ $t("manage-coupons.percentage-coupons") }}
                  </h5>
                  <percentage-coupon-usage-chart
                    @valueSelected="setPercentageCouponChart"
                    :data="percentage"
                  />
                </div>
                <!-- <div class="line"></div> -->
                <div class="second-part px-4 py-4">
                  <h5 class="mb-4">
                    {{ $t("manage-coupons.currency-coupons") }}
                  </h5>
                  <fiat-coupon-usage-chart
                    @valueSelected="setFiatCouponChart"
                    :data="fiat"
                  />
                </div>
              </div>
              <div class="line"></div>
              <div class="col-6 right-side px-0">
                <div class="first-part">
                  <percentage-coupon-usage-details-chart
                    :selected="percentageselected"
                  />
                </div>
                <!-- <div class="line"></div> -->
                <div class="second-part">
                  <fiat-coupon-usage-details-chart :selected="fiatSelected" />
                </div>
              </div>

              <!-- <div class="col-12 right-side">

              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PercentageCouponUsageChart from "@/components/Charts/PercentageCouponUsageChart";
import FiatCouponUsageChart from "@/components/Charts/FiatCouponUsageChart";
import PercentageCouponUsageDetailsChart from "@/components/Charts/PercentageCouponUsageDetailsChart";
import FiatCouponUsageDetailsChart from "@/components/Charts/FiatCouponUsageDetailsChart";
import Icon from "@/components/Icon";
import {Capacitor} from "@capacitor/core";

export default {
  name: "CouponStatistics",
  components: {
    PercentageCouponUsageChart,
    FiatCouponUsageChart,
    PercentageCouponUsageDetailsChart,
    FiatCouponUsageDetailsChart,
    Icon,
  },
  mounted() {
    this.axios
      .get("/coupons/statistics")
      .then((resp) => {
        this.percentage = resp.data.percentage;
        this.fiat = resp.data.fiat;
        this.count = resp.data.coupon_count;
        //console.log(this.percentage, this.fiat);
      })
      .catch((e) => console.log(e?.response))
      .finally(() => (this.loading = false));
  },
  data() {
    return {
      percentage: [],
      fiat: [],
      count: 0,
      usageTypes: [],
      loading: true,
      downloading: false,
      percentageselected: null,
      fiatSelected: null,
    };
  },
  methods: {
    setPercentageCouponChart(val) {
      this.percentageselected = val;
    },
    printPage() {
      window.print();
    },
    downloadExcel(){
      console.log('download excel');
      this.downloading = true;
      this.axios.get('/coupons/statistics/download/xlsx',{responseType: 'blob'}).then((resp)=>{
        const name = 'activity-log.xlsx';
        if (Capacitor.isNative) {
          this.$store.commit('downloadFileMobile', {
            name,
            mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            data: resp.data
          })
        } else {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click()
        };
      }).finally(() => (this.downloading = false));
    },
    setFiatCouponChart(val) {
      this.fiatSelected = val;
    },
  },
};
</script>

<style scoped>
.total-coupons-statistics {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-items: center;
  padding: 10px;
  /* width: 216px;
  height: 107px;
  left: 100px;
  top: 272px; */
  width: fit-content;
  color: black;
  background: #ffffff;
  /* Dark grey */
  border: 1px solid #333e48;
  box-sizing: border-box;
}

.total-coupons-statistics .title {
  font-size: 16px;
  font-weight: 500;
  color: #333e48;
}

.charts-container {
  background: transparent;
  /* Dark grey */
  border: 1px solid #333e48;
  box-sizing: border-box;
}

.charts-container h5,
.charts-container h4 {
  font-size: 20px;
  font-weight: 500;
  color: #333e48;
}

.charts-container .right-side {
  background-color: #fff;
}

.total-coupons-count {
  position: static;
  width: 33px;
  height: 37px;
  left: 91.5px;
  top: 60px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 10px 0px;
}

.line {
  position: absolute;
  top: 51%;
  left: 63px;
  height: 2px;
  width: 88%;
  background-color: #768692;
  z-index: 5;
}

/* BUTTONS */
.rounded-red {
  background-color: #d62b33;
  border: 3px solid #d62b33;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-right: 25px;
  margin-top: 10px;
}

.rounded-red:hover {
  border: 3px solid #d62b33;
  background-color: transparent;
}

::v-deep .rounded-red:hover svg path {
  fill: #d62b33;
}
::v-deep .rounded-red:hover svg line {
  stroke: #d62b33;
}

button.red-button {
  background-color: #d62b33;
  border-radius: 25px;
  border: 3px solid #d62b33;
  display: flex;
  align-items: center;
  padding: 5px 20px;
}

button.red-button span {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-right: 15px;
}

::v-deep button.red-button .icon svg path {
  fill: #fff !important;
}

button.red-button:hover {
  background-color: transparent;
  border: 3px solid #d62b33;
}

button.red-button:hover span {
  color: #d62b33;
}

::v-deep button.red-button:hover .icon svg path {
  fill: #d62b33 !important;
}

button.red-button:disabled {
  background-color: #d62b33;
  border: 3px solid #d62b33;
  opacity: 0.5;
}
</style>
