<template>
  <div id="invoices">
    <div class="row align-items-center justify-content-center mb-4">
      <div class="col">
        <h1 class="ps-2">{{ $t("settings.orders") }}</h1>
      </div>
      <div class="col">
        <img src="@/assets/img/settings/invoices.png" alt="" />
      </div>
      <div class="col">
        <a :href="$store.state.xbodyID+'/invoices'" target="_blank">
          <img
              src="@/assets/xbody_id.svg"
              style="max-height: 40px !important"
              alt=""
          />
        </a>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th
            v-for="(header, index) in headers"
            :key="index"
            :class="'pointer ' + (currentSort === header.key ? 'sorted' : '')"
            @click="sort(header.key)"
          >
            {{ header.label }}
          </th>
        </tr>
      </thead>
      <tbody v-for="(item, index) in sortedOrders" :key="index">
        <tr @click="()=>$router.push({ name: 'Order', params:{order:item.id} })" style="cursor: pointer">
          <template v-for="(header, headerIndex) in headers" :key="headerIndex">
            <td v-if="header.key !== 'actions'" :data-label="header.label">
              <div v-if="header.key === 'total'">
                {{ getTotal(item) }}
              </div>
              <div v-else-if="header.key === 'created_at'">
                {{ $store.getters.parseDateTime(item[header.key]) }}
              </div>
              <div v-else>
                {{ item[header.key] ? item[header.key] : '-' }}
              </div>
            </td>
            <td :data-label="header.label" v-else-if="header.key === 'actions'">
              <div class="status-section">
                <div v-if="!downloading" class="payment-status" :class="{failed:item.status=='Canceled', expired:item.status=='Expired', prepared:item.status=='Prepared'}">{{ $t('status.' + item.status) }}</div>
                </div>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
  <loading height="50vh" width="100%" v-if="loading" />
</template>

<script>
import Loading from "../Loading";
import PrimaryButton from "./PrimaryButton.vue";

export default {
  name: "TeamMembers",
  components: {
    PrimaryButton,
    Loading,
  },
  data() {
    return {
      headers: [
        { key: "xbodyid_invoice_id", label: "Academy Nr" },
        { key: "invoice_id", label: "Invoice number" },
        { key: "created_at", label: "Date" },
        { key: "total", label: "Total" },
        { key: "actions", label: "Status" },
      ],
      items: [],
      currentSort: "invoice_id",
      currentSortDir: "asc",
      loading: false,
      downloading: false,
    };
  },
  methods: {
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    downloadInvoice(dockEntry) {
      this.downloading = true;
      this.axios
        .get("/invoice/" + dockEntry + "/doc-entry")
        .then((resp) => {
          window.open(resp.data.invoice.preview_pdf, "_blank");
          this.downloading = false;
        })
        .finally(() => (this.downloading = false));
    },
    getTotal(order) {
      let grossTotal = order.items.reduce((acc, c) => acc + c.gross_price * order.exchange_rate, 0)
      if (order.coupon) {
        const discount = order.coupon.percentage_based ? grossTotal * (order.coupon.discount / 100) : order.coupon.discount
        grossTotal -= discount
      }
      return grossTotal < 0 ? 0 : grossTotal
    },
  },
  computed: {
    sortedOrders: function () {
      return this.$store.state.user.orders.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    }
  },
};
</script>

<style scoped>
#invoices {
  padding: 40px 25px;
}

h1 {
  font-size: 20px;
  font-weight: 500;
  color: #333e48;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

thead {
  background-color: #cecece;
  border-radius: 5px;
}

th {
  font-weight: 500;
  font-size: 15px;
  color: #333e48;
  padding: 10px;
  border: solid 1px #333e48;
  border-right: none;
  text-align: center;
}

th:first-child {
  border-top-left-radius: 5px;
}

th:last-of-type {
  border-top-right-radius: 5px;
  border-right: solid 1px #333e48;
}

tbody tr {
  border: 1px solid #cecece;
  background-color: #fff;
}

tbody tr:first-of-type {
  border-top: none;
}

tbody tr td {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #928c88;
  border: solid 1px #cecece;
  border-right: none;
  text-align: center;
}

tbody tr td:last-of-type {
  border-right: solid 1px #cecece;
}

.status-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.status-section .payment-status {
  color: #06aa16;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid #06aa16;
  padding: 3px 35px;
  height: 25px;
  width: 100%;
  text-align: center;
  border-radius: 5px;
}
.status-section .payment-status.failed {
  color: #ff0000;
  border: 1px solid #ff0000;
}
.status-section .payment-status.expired {
  color: #ff0000;
  border: 1px solid #ff0000;
}
.status-section .payment-status.prepared {
  color: #768692;
  border: 1px solid #768692;
}
.status-section .downloading {
  color: #768692;
  font-size: 12px;
  font-weight: 400;
  padding: 3px 35px;
  height: 25px;
  width: 100%;
  text-align: center;
}
.status-section .download {
  color: #070707;
  font-size: 12px;
  font-weight: 400;
  border: 0 solid #06aa16;
  padding: 5px 35px;
  border-radius: 25px;
}
.sorted {
  font-weight: bold;
}
</style>
