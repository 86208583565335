<template>
    <div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#" @click.prevent="$router.push({name: 'Home'})">{{ $t('edit-course.homepage') }}</a></li>
                <li class="breadcrumb-item"><a href="#" @click.prevent="$router.push({name: 'ManageCourses'})">{{ $t('edit-course.managing-course') }}</a></li>
                <li class="breadcrumb-item" aria-current="page"><a href="#" @click.prevent="$router.push({name: 'EditCourse', params: {course: this.$route.params.course}})">{{ $t('edit-course.edit-course') }}</a></li>
                <li class="breadcrumb-item active">{{ $t('edit-course-feedback.edit') }}</li>
            </ol>
        </nav>

        <div id="test-question" v-if="!loading && feedback">
            <div class="row">
                <div class="col-12">
                    <button type="button" class="btn btn-create-new" data-toggle="modal" data-target="#newQuestionModal">{{ $t('test-question-assembler.new-question') }}</button>
                </div>
            </div>
            <div class="row">
                <div class="col-12 quick-table">
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th scope="col" :class="'pointer'+' '+(currentSort==='question'?'sorted':'')" @click="sort('question')">{{ $t('test-question-assembler.question') }}</th>
                            <th scope="col" >{{ $t('test-question-assembler.type') }}</th>
                            <th scope="col">{{ $t('test-question-assembler.answers') }}</th>
                            <th scope="col" class="text-right testmin">{{ $t('test-question-assembler.actions') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="question in sortedQuestions" :key="question.id">
                            <td> <a href="#" data-toggle="modal" data-target="#exampleModal5"> {{question.question}}</a></td>
                            <td>{{question.matrix_group ? $t('question-type.Matrix') : $t(`question-type.${question.course_feedback_question_type.type}`)}}</td>
                            <td v-if="question.matrix_group === null">
                                <div class="row mb-2" v-for="answer in question.course_feedback_question_answers" :key="answer.id">
                                    <div class="col-11 clear-padding">{{answer.answer}} </div>
                                    <div  class="col-1 clear-padding">
                                        <a href="#" data-toggle="modal" :data-target="'#editQuestionAnswer'+answer.id+'Modal'"><i class="fas fa-pen mr-2"></i></a>
                                        <a href="#" data-toggle="modal" :data-target="'#deleteQuestionAnswer'+answer.id+'Modal'"><i class="fas fa-trash-alt" style="color: red"></i></a>
                                    </div>
                                    <!-- Modal Question Answer Delete -->
                                    <div  class="modal fade" :id="'deleteQuestionAnswer'+answer.id+'Modal'" tabindex="-1" role="dialog" :aria-labelledby="'deleteQuestionAnswer'+answer.id+'ModalLabel'" aria-hidden="true">
                                        <div class="modal-dialog" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header delete-modal-header" style="background-color: #dc3545 !important">
                                                <h5 class="modal-title" :id="'deleteQuestionAnswer'+answer.id+'ModalLabel'" >{{ $t('test-question-assembler.delete-sure') }}</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                </div>
                                                <div class="modal-body">
                                                    {{ $t('test-question-assembler.delete-question') }}
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-danger" data-dismiss="modal" @click="deleteQuestionAnswer(answer)">{{ $t('test-question-assembler.delete') }}</button>
                                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('test-question-assembler.cancel') }}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Modal edit question Answer-->
                                    <div class="modal fade" :id="'editQuestionAnswer'+answer.id+'Modal'" tabindex="-1" role="dialog" :aria-labelledby="'editQuestionAnswer'+answer.id+'ModalLabel'" aria-hidden="true">
                                        <div class="modal-dialog" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header question-modal-header" style="background-color: #007bff !important;">
                                                <h5 class="modal-title" :id="'editQuestionAnswer'+answer.id+'ModalLabel'">{{ $t('test-question-assembler.edit-answer') }}</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                </div>
                                                <form @submit.prevent="editQuestionAnswer(answer)">
                                                    <div class="modal-body nono">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label for="exampleFormControlTextarea1">{{ $t('test-question-assembler.answer') }}</label>
                                                                    <textarea v-model="answer.edit.answer" class="form-control" id="exampleFormControlTextarea1" rows="3" required></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="submit" :disabled="answer.loading" class="btn btn-danger">{{ $t('test-question-assembler.edit') }}</button>
                                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('test-question-assembler.cancel') }}</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td v-else>
                                {{$t('question-type.scale')}}
                            </td>
                            <td class="text-right">
                                <button v-if="question.course_feedback_question_type.type !== 'Free text' && question.matrix_group === null" type="button" class="btn btn-action-agree" data-toggle="modal" :data-target="'#newAnswer'+question.id+'Modal'">{{ $t('test-question-assembler.new-answer') }}</button> <br />
                                <button type="button" class="btn btn-info mb-2 w-100" style="max-width: 244px; !important;" data-toggle="modal" :data-target="'#editQuestion'+question.id+'Modal'">{{ $t('test-question-assembler.edit-question') }}</button> <br>
                                <button type="button" class="btn btn-danger w-100" style="max-width: 244px; !important;" data-toggle="modal" :data-target="'#deleteQuestion'+question.id+'Modal'">{{ $t('test-question-assembler.delete') }}</button>
                            </td>
                            <!-- Modal New Answer-->
                            <div v-if="question.course_feedback_question_type.type !== 'Free text' && question.matrix_group === null" class="modal fade" :id="'newAnswer'+question.id+'Modal'" tabindex="-1" role="dialog" :aria-labelledby="'newAnswer'+question.id+'ModalLabel'" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header question-modal-header" style="background-color: #007bff !important;">
                                        <h5 class="modal-title" :id="'newAnswer'+question.id+'ModalLabel'">{{ $t('test-question-assembler.add-new-answer') }}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        </div>
                                        <form @submit.prevent="createQuestionAnswer(question)">
                                            <div class="modal-body nono">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label for="exampleFormControlTextarea1">{{ $t('test-question-assembler.answer') }}</label>
                                                            <textarea v-model="question.newAnswer.answer" class="form-control" id="exampleFormControlTextarea1" rows="3" required></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="submit" :disabled="question.newAnswer.loading" class="btn btn-primary">{{ $t('test-question-assembler.add') }}</button>
                                                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('test-question-assembler.cancel') }}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!-- Modal Delete -->
                            <div class="modal fade" :id="'deleteQuestion'+question.id+'Modal'" tabindex="-1" role="dialog" :aria-labelledby="'deleteQuestion'+question.id+'ModalLabel'" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header delete-modal-header" style="background-color: #dc3545 !important">
                                        <h5 class="modal-title" :id="'deleteQuestion'+question.id+'ModalLabel'">{{ $t('test-question-assembler.delete-sure') }}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        </div>
                                        <div class="modal-body">
                                            {{ $t('test-question-assembler.delete-question') }}
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-danger" data-dismiss="modal" @click="deleteQuestion(question)">Törlés</button>
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('test-question-assembler.cancel') }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <!-- Modal Edit Question-->
                            <div class="modal fade" :id="'editQuestion'+question.id+'Modal'" tabindex="-1" role="dialog" :aria-labelledby="'editQuestion'+question.id+'ModalLabel'" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header question-modal-header" style="background-color: #007bff !important;">
                                        <h5 class="modal-title" :id="'editQuestion'+question.id+'ModalLabel'">{{ $t('test-question-assembler.edit-question') }}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        </div>
                                        <form @submit.prevent="editQuestion(question)">
                                            <div class="modal-body nono">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label for="exampleFormControlTextarea1">{{ $t('test-question-assembler.question') }}</label>
                                                            <textarea required maxlength="2000" v-model="question.edit.question" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="submit" class="btn btn-primary w-100" :disabled="question.loading">{{ $t('test-question-assembler.save') }}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
                <!-- Modal New Question-->
                <div class="modal fade" id="newQuestionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header question-modal-header" style="background-color: #007bff !important;">
                            <h5 class="modal-title" id="exampleModalLabel">{{ $t('test-question-assembler.new-question') }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <form @submit.prevent="submitNewQuestion">
                                <div class="modal-body nono">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="exampleFormControlTextarea1">{{ $t('test-question-assembler.question') }}</label>
                                                <textarea required v-model="newQuestion.question" maxlength="2000" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="">{{ $t('test-question-assembler.question-type') }}</label>
                                                <select class="form-control" id="exampleFormControlSelect1" v-model="newQuestion.course_feedback_question_type_id" required>
                                                    <option v-for="questionType in questionTypes" :key="questionType.type" :value="questionType.id">{{$t(`question-type.${questionType.type}`)}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-primary w-100" :disabled="newQuestion.loading">{{ $t('test-question-assembler.save') }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
        </div>
        <div v-else>
            <Loading height="80vh" />
        </div>
    </div>
</template>
<script>
import Loading from '@/components/Loading.vue'
export default {
    name: 'EditCourseFeedback',
    components: {
        Loading
    },
    mounted() {
        this.loading = true;
        this.axios.get('/courses/'+this.$route.params.course+'/feedback/edit')
            .then(resp => {
                this.feedback = resp.data
                this.feedback.course_feedback_questions.forEach(el => {
                    el.edit = JSON.parse(JSON.stringify(el))
                    el.course_feedback_question_answers.forEach(qa => {
                        qa.edit = JSON.parse(JSON.stringify(qa))
                    })
                    el.newAnswer = {
                        answer: null,
                    }
                })
            })
            .finally(() => this.loading = false)
        this.axios.get('/course-feedback-question-types')
            .then(resp => {
                resp.data.push({id: -1, type: 'Matrix'})
                this.questionTypes = resp.data
            })
    },
    data() {
        return {
            feedback: null,
            questionTypes: [],
            newQuestion: {
                question: null,
                course_feedback_question_type_id: null,
                matrix_group: null,
                loading: false
            },
            loading: false,
          currentSort:'id',
          currentSortDir:'asc',
        }
    },
    methods: {
        submitNewQuestion() {
            this.newQuestion.loading = true
            let q = JSON.parse(JSON.stringify(this.newQuestion))
            if(q.course_feedback_question_type_id == -1) {
                q.course_feedback_question_type_id = 2,
                q.matrix_group = 1
            }
            this.axios.post('/courses/'+this.feedback.course_id+'/feedback/questions', this.$store.getters.removeEmptyOrNull(q))
                .then(resp => {
                    resp.data.question.edit = JSON.parse(JSON.stringify(resp.data.question))
                    resp.data.question.newAnswer = {answer: null}
                    this.feedback.course_feedback_questions.push(resp.data.question)
                    this.newQuestion = {
                        question: null,
                        course_feedback_question_type_id: null,
                        matrix_group: null,
                        loading: false
                    }
                    $('#newQuestionModal').modal('hide')
                })
                .finally(() => this.newQuestion.loading = false)
        },
        editQuestion(question) {
            question.loading = true
            this.axios.patch('/courses/'+this.feedback.course_id+'/feedback/questions/'+question.id, this.$store.getters.removeEmptyOrNull(question.edit))
                .then(resp => {
                    resp.data.question.course_feedback_question_answers.forEach(qa => {
                        qa.edit = JSON.parse(JSON.stringify(qa))
                    })
                    resp.data.question.edit = JSON.parse(JSON.stringify(resp.data.question))
                    resp.data.question.newAnswer = {answer: null}
                    this.feedback.course_feedback_questions[this.feedback.course_feedback_questions.findIndex((q) => q.id == question.id)] = resp.data.question
                    $('#editQuestion'+question.id+'Modal').modal('hide')
                })
                .finally(() => question.loading = false)
        },
        deleteQuestion(question) {
            question.loading = true
            this.axios.delete('/courses/'+this.feedback.course_id+'/feedback/questions/'+question.id)
                .then(resp => {
                    this.feedback.course_feedback_questions.splice(this.feedback.course_feedback_questions.findIndex((q) => q.id == question.id), 1)
                })
                .finally(() => question.loading = false)
        },
        createQuestionAnswer(question) {
            question.newAnswer.loading = true
            this.axios.post('/courses/'+this.feedback.course_id+'/feedback/questions/'+question.id+'/answers/', this.$store.getters.removeEmptyOrNull(question.newAnswer))
                .then(resp => {
                    resp.data.answer.edit = JSON.parse(JSON.stringify(resp.data.answer))
                    question.course_feedback_question_answers.push(resp.data.answer)
                    question.newAnswer = {answer: null}
                    $('#newAnswer'+question.id+'Modal').modal('hide')
                })
                .finally(() => question.newAnswer.loading = false)
        },
        editQuestionAnswer(answer) {
            answer.loading = true
            this.axios.patch('/courses/'+this.feedback.course_id+'/feedback/questions/'+answer.course_feedback_question_id+'/answers/'+answer.id, this.$store.getters.removeEmptyOrNull(answer.edit))
                .then(resp => {
                    resp.data.answer.edit = JSON.parse(JSON.stringify(resp.data.answer))
                    let question = this.feedback.course_feedback_questions.find((q) => q.id == answer.course_feedback_question_id)
                    question.course_feedback_question_answers[question.course_feedback_question_answers.findIndex((qa) => qa.id == answer.id)] = resp.data.answer
                    question.newAnswer = {answer: null}
                    $('#editQuestionAnswer'+answer.id+'Modal').modal('hide')
                })
                .finally(() => answer.loading = false)
        },
        deleteQuestionAnswer(answer) {
            answer.loading = true
            this.axios.delete('/courses/'+this.feedback.course_id+'/feedback/questions/'+answer.course_feedback_question_id+'/answers/'+answer.id)
                .then(resp => {
                    let question = this.feedback.course_feedback_questions[this.feedback.course_feedback_questions.findIndex((q) => q.id == answer.course_feedback_question_id)]
                    console.log(question.course_feedback_question_answers.findIndex((qa) => qa.id == answer.id))
                    question.course_feedback_question_answers.splice(question.course_feedback_question_answers.findIndex((qa) => qa.id == answer.id), 1)
                })
                .finally(() => answer.loading = false)
        },
      sort: function (s) {
        //if s == current sort, reverse
        if (s === this.currentSort) {
          this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
        }
        this.currentSort = s;
      },
    },
    computed: {
      sortedQuestions: function () {
        return this.feedback.course_feedback_questions.sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDir === 'desc') modifier = -1;
          if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
        });
      }
    }
}
</script>
<style scoped>
.close {
    color: white !important;
    opacity: 1 !important;
    font-weight: 500;
}
 .sorted{
   font-weight: normal;
 }
</style>
