<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-courses.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'ManageCourses'})">{{
                    $t('manage-courses.managing-course')
                  }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('edit-course.edit-course') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->

    <section id="settings" v-if="!loading && course">
      <div class="container">
        <RecommendedCoursesModal :course="course"/>
        <prerequisite-courses-modal :course="course"/>
        <form @submit.prevent="submit">
          <div class="row">
            <div class="col-lg-6 col-12 mt-2">
              <div class="row">
                <div class="col-12">
                  <p>{{ $t('edit-course.cover-img') }} </p>
                  <div v-if="!course.cover_image && !course.cover_imageUploading"
                       @click="$refs.coverImageUpload.click()"
                       class="col-md-7 col-lg-12 col-xl-9 col-12 d-flex justify-content-center align-items-center"
                       style="border: 2px solid #e0e0e0; height: 269px; flex-direction: column; border-radius: 5px; cursor: pointer;background: white;">
                    <i class="far fa-image" style="font-size: 100px; color: #e0e0e0"></i>
                    <span style="color: grey">{{ $t('edit-course.upload-cover-img') }}</span>
                  </div>
                  <div v-else-if="course.cover_image && !course.cover_imageUploading"
                       @click="$refs.coverImageUpload.click()"
                       class="col-md-7 col-lg-12 col-xl-9 col-12 d-flex justify-content-center align-items-center"
                       style="border: 2px solid #e0e0e0; height: 300px; flex-direction: column; border-radius: 5px; cursor: pointer; background-position: center; background-repeat: no-repeat; background-size: cover;"
                       :style="{backgroundImage: `url(${$store.state.storageURL+course.cover_image.path}?token=${$store.state.token})`}"
                  >
                  </div>
                  <div v-else
                       class="col-md-7 col-lg-12 col-xl-9 col-12 d-flex justify-content-center align-items-center"
                       style="border: 2px solid #e0e0e0; height: 300px;background: white;">
                    <Loading height="100%"/>
                  </div>
                  <ImageCropper v-if="uploadedAvatarURL" @urlChanged="createUpload" :src="uploadedAvatarURL"
                                :type="uploadType" :aspectRatio="null"/>
                  <input ref="coverImageUpload" type="file" accept="image/jpeg, image/jpg, image/png" @change="readURL"
                         style="display: none;">
                  <button v-if="course.cover_image" class="btn btn-outline-danger"
                          style="width: 128px;margin-bottom: 1px !important;margin-top: 15px !important"
                          @click.stop="removeCoverImage" :disabled="course.cover_imageUploading">{{
                      $t('common.delete')
                    }}
                  </button>
                </div>
                <div class="mt-3 col-12 text-center pl-3 pr-3 d-flex" style="flex-direction: column; flex-grow: 0">
                  <button class="mb-2 btn btn-outline-primary" type="button"
                          @click="$router.push({name: 'CourseMaterials', params: {course: course.id}})"><i
                      class="fas fa-book"></i> {{ $t('edit-course.edit-materials') }}
                  </button>
                  <button v-if="$store.state.user?.role == 'Admin'" class="mb-2 btn btn-outline-primary" type="button"
                          data-toggle="modal" data-target="#usersContractReceivedModal"><i
                      class="fas fa-file-signature mr-1"></i>{{ $t('user-contracts.btn-title') }}
                  </button>
                  <button v-if="course.course_feedback && $store.state.user?.role == 'Admin'" type="button"
                          class="btn btn-outline-primary mb-0 w-100"
                          @click="$router.push({name: 'EditCourseFeedback', params: {course: course.id}})"><i
                      class="fas fa-star"></i> {{ $t('edit-course.feedback-edit') }}
                  </button>
                  <button v-else-if="$store.state.user?.role == 'Admin'" type="button" :disabled="submitting"
                          class="btn btn-outline-success mb-0 w-100" @click="createFeedback()"><i
                      class="fas fa-star"></i> {{ $t('edit-course.feedback-create') }}
                  </button>
                  <button v-if="$store.state.user?.role == 'Admin'" class="btn btn-outline-primary mt-2 w-100"
                          type="button" data-toggle="modal" data-target="#recommendedCoursesModal"><i
                      class="fas fa-graduation-cap"></i> {{ $t('edit-course.feedback-manage') }}
                  </button>
                  <users-contract-received-modal v-if="$store.state.user?.role == 'Admin'" :courseId="course.id"
                                                 :usersContractsReceived="course.contract_received_users"
                                                 :users="course.users"/>
                </div>
                <div class="col-12 mt-2">
                  <label>{{ $t('edit-course.type') }}</label>
<!--                  <select class="form-control" :disabled="course.finalized" v-model="course.course_type_id" required>
                    <option v-for="courseType in courseTypes" :value="courseType.id" :key="courseType.id">
                      {{ courseType.name }}
                    </option>
                  </select>-->
                  <Select2 v-model="course.course_type_id" :options="courseTypes" :settings="{ width: '100%' }"
                    :disabled="course.finalized" />

                </div>
                <div class="col-12 mt-2">
                  <label>{{ $t('create-course.cert-type') }} </label>
<!--                  <select class="form-control" v-model="course.certificate_type">
                    <option :value="null">{{ $t('create-course.no-cert-type') }}</option>
                    <option v-for="certType in certificateTypes" :value="certType.type" :key="certType.type">
                      {{ certType.type }}
                    </option>
                  </select>-->
                  <Select2 v-model="course.certificate_type" :options="certificateTypes" :settings="{width:'100%', placeholder: $t('create-course.no-cert-type'), allowClear:true}" />

                </div>
                <div class="col-12 mt-2">
                  <!-- <TemporaryCertificateInput :certificate_types="certificateTypes"
                    v-model:temporary_certificate="course.temporary_certificate"
                    v-model:temporary_certificate_type="course.temporary_certificate_type"
                    v-model:temporary_certificate_validity="course.temporary_certificate_validity" /> -->
                  <div>
                    <!-- Toggle temporary certificate-->
                    <div class="mt-2">
                      <label>{{ $t('create-course.temporary_certificate') }}</label>
                      <div class="custom-control custom-switch">
                        <!--suppress HtmlUnknownAttribute -->
                        <input type="checkbox" class="custom-control-input" id="temporary_certificate"
                          v-model="course.temporary_certificate" :true-value="true" :false-value="false" />
                        <label class="custom-control-label" for="temporary_certificate">{{
                          $t('create-course.temporary_certificate_toggle') }}</label>
                      </div>
                    </div>
                    <div v-show="course.temporary_certificate" class="mt-2">
                      <label>{{ $t('create-course.temporary_certificate_type') }}</label>
                      <Select2 v-model="course.temporary_certificate_type" :options="certificateTypes"
                        :settings="{ width: '100%', allowClear: false }" />
                      <div class="d-flex align-items-center mt-1">
                        <i class="fas fa-info-circle mr-1 text-info font-15" style="font-size: 15px;"></i>
                        <span class="font-12" style="font-size: 12px">{{ $t('create-course.temporary_certificate_desc')
                        }}</span>
                      </div>
                    </div>
                    <div v-show="course.temporary_certificate" class="mt-2">
                      <label>{{ $t('create-course.temporary_certificate_validity') }}</label>
                      <input type="number" class="form-control" min="1" max="6"
                        v-model="course.temporary_certificate_validity" />
                      <div class="d-flex align-items-center mt-1">
                        <i class="fas fa-info-circle mr-1 text-info font-15" style="font-size: 15px;"></i>
                        <span class="font-12" style="font-size: 12px">{{
                          $t('create-course.temporary_certificate_validity_desc') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-2">
                  <label>{{ $t('create-course.course-group') }} </label>
<!--                  <select class="form-control" v-model="course.course_group_id">
                    <option :value="null">{{ $t('create-course.no-course-group') }}</option>
                    <option v-for="group in courseGroups" :value="group.id" :key="group.id">{{ group.title }}</option>
                  </select>-->
                  <Select2 v-model="course.course_group_id" :options="courseGroups" :settings="{width:'100%', placeholder: $t('create-course.no-course-group'), allowClear:true}" />
                </div>
                <div class="col-12 mt-2">
                  <label>{{ $t('edit-course.visibility') }} </label>
                  <select class="form-control" :class="course.active ? 'text-success' : 'text-danger'"
                          v-model="course.active" required>
                    <option class="text-success" :value="1">{{ $t('edit-course.public') }}</option>
                    <option class="text-danger" :value="0">{{ $t('edit-course.hidden') }}</option>
                  </select>
                </div>
                <div class="col-12 mt-2">
                  <label>{{ $t('edit-course.name') }}</label>
                  <div v-if="errors['title']">
                    <span v-for="(error, idx) in errors['title']" style="color: red;" :key="idx">{{ error }}</span>
                  </div>
                  <input @input="errors['title'] = null" type="text" :disabled="course.finalized" v-model="course.title"
                         class="form-control" :style="{border: errors['title'] ? '1px solid red' : '1px solid #ced4da'}"
                         required>
                </div>
                <div class="col-12 mt-2">
                  <label>{{ $t('create-course.meeting_link') }}</label>
                  <div v-if="errors['meeting_link']">
                    <span v-for="(error, idx) in errors['meeting_link']" style="color: red;" :key="idx">{{ error }}</span>
                  </div>
                  <input @input="errors['meeting_link'] = null" type="text" v-model="course.meeting_link" class="form-control"
                         :style="{border: errors['meeting_link'] ? '1px solid red' : '1px solid #ced4da'}">
                </div>
                <div class="col-12 mt-2">
                  <label>{{ $t('edit-course.speakers') }}</label>
                  <button type="button" class="form-control btn btn-secondary" data-toggle="modal"
                          data-target="#presentersModal">{{ $t('edit-course.speakers-mod') }}
                  </button>
                </div>
                <div class="col-12 mt-2">
                  <label>{{ $t('edit-course.speakers-about') }} </label>
                  <div v-if="errors['description']">
                    <span v-for="(error, idx) in errors['description']" style="color: red;"
                          :key="idx">{{ error }}</span>
                  </div>
                  <textarea @input="errors['description'] = null" rows="5" v-model="course.description"
                            class="form-control"
                            :style="{border: errors['description'] ? '1px solid red' : '1px solid #ced4da'}"
                            required></textarea>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-12 mt-lg-0 pl-lg-3 mt-4 pl-0">
              <div class="col-12 mt-2">
                <label>{{ $t('edit-course.course-desc') }} </label>
                <div v-if="errors['contents']">
                  <span v-for="(error, idx) in errors['contents']" style="color: red;" :key="idx">{{ error }}</span>
                </div>
                <textarea @input="errors['contents'] = null" rows="5" v-model="course.contents" class="form-control"
                          :style="{border: errors['contents'] ? '1px solid red' : '1px solid #ced4da'}"
                          required></textarea>
                <span class="limiter" :class="limitExceeded && 'limit_exceeded'">{{charactersLeft}}</span>
              </div>
              <div class="col-12 mt-2">
                <label>{{ $t("edit-course.prerequisites") }}</label>
                <button class="btn btn-secondary mt-2 w-100" type="button" data-toggle="modal"
                        data-target="#prerequisiteCoursesModal">{{ $t("edit-course.edit-prerequisites") }}
                </button>
              </div>
              <div class="col-12 mt-2">
                <label>{{ $t("edit-course.badge") }}
                  <div class="d-flex align-items-center mt-1">
                    <i class="fas fa-info-circle mr-1 text-info font-15" style="font-size: 15px;"></i>
                    <span class="font-12" style="font-size: 12px">{{ $t('edit-course.badge-text') }}</span>
                  </div>
                  <div class="d-flex align-items-center mt-1">
                    <i class="fas fa-info-circle mr-1 text-info font-15" style="font-size: 15px;"></i>
                    <span class="font-12" style="font-size: 12px">{{ $t('edit-course.badge-delete-info') }}</span>
                  </div>
                </label>
                <div class="justify-content-start badge-wrapper">
                  <div class='badge-container text-center border-1'>
                    <input type="radio" name="selected_badge" v-model="course.badge_id" id="badge_none" class="d-none imgbgchk" value="null">
                    <label for="badge_none">
                      <div style="width: 50px; height: 50px; display: table-cell; vertical-align: middle" :style="course.badge_id === 'null' || course.badge_id === null ? 'border: 3px solid #d62b33' : 'border: none' ">None</div>
                      <div class="tick_container">
                        <div class="tick"></div>
                      </div>
                    </label>
                  </div>
                  <div v-for="badge in badges" :key="badge.id" class='badge-container text-center border-1'>
                    <input type="radio" name="selected_badge" v-model="course.badge_id" :id="'badge'+ badge.id" class="d-none imgbgchk" :value="badge.id">
                    <label :for="'badge'+ badge.id">
                      <img :src="$store.state.storageURL+badge.multimedia.path+`?token=${$store.state.token}`" :alt="badge.title" style="width: 50px">
                      <button v-if="badge.courses_count===0 && badge.users_count===0 && course.badge_id!==badge.id" type="button" :onclick="badgeToDelete = badge.id" class="delete-badge-button" data-toggle="modal" data-target="#deleteBadgeModal">
                        <i class="fas fa-times fa-xs" style="margin-bottom: 5px; margin-right: 0px"></i>
                      </button>
                      <div class="tick_container">
                        <div class="tick"><i class="fa fa-check"></i></div>
                      </div>
                    </label>
                  </div>
                  <div v-if="!badgeUploading" class='badge-container text-center border-1' @click="$refs.badgeUpload.click()">
                    <label>
                      <img style="width: 50px; height: 50px; border:none">
                      <div class="tick_container-add">
                        <div class="tick-add"><i class="fa fa-plus"></i></div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <input ref="badgeUpload" type="file" accept="image/jpeg, image/jpg, image/png" @change="uploadBadgeImage"
                     style="display: none;">
              <div class="col-12 mt-2">
                <label>{{ $t('edit-course.location') }} </label>
<!--                <select class="form-control" :disabled="course.finalized" v-model="course.location_id">
                  <option :value="null" selected>{{ $t('edit-course.online') }}</option>
                  <option v-for="location in locations" :value="location.id" :key="location.id">
                    {{ `${location.zip_code}, ${location.locality}, ${location.public_space} ${location.public_space_type}, ${location.other}, ${location.country.name}` }}
                  </option>
                </select>-->
                <Select2 v-model="course.location_id" :options="locations" :settings="{width:'100%', disabled:course.finalized, placeholder: $t('edit-course.online'), allowClear:true}" />
                <button type="button" :disabled="course.finalized"
                        class="btn btn-outline-primary mt-2 d-flex align-items-center" data-toggle="modal"
                        data-target="#newLocationModal">
                  <i class="fas fa-map-marker-alt mr-2"></i>
                  <span>{{ $t('edit-course.add-new-location') }}</span>
                </button>
              </div>
              <div class="col-12 mt-2">
                <AvailableInCountries :countries="countries" v-model:available-in="course.available_in" />
              </div>
              <div class="col-12 mt-4">
                <label for="start">{{ $t('edit-course.begin') }} <br>
                  <div class="d-flex align-items-center mt-1">
                    <i class="fas fa-info-circle mr-1 text-success font-15" style="font-size: 15px;"></i>
                    <span class="font-12" style="font-size: 12px">{{ $t('edit-course.text1') }}</span>
                  </div>
                </label>
                <datetime id="start" type="datetime" :max-datetime="course.end_date" v-model="course.start_date"
                          input-class="form-control"/>
              </div>
              <div class="col-12 mt-2">
                <label for="end">{{ $t('edit-course.end') }}<br>
                  <div class="d-flex align-items-center mt-1">
                    <i class="fas fa-info-circle mr-1 text-success font-15" style="font-size: 15px;"></i>
                    <span class="font-12" style="font-size: 12px">{{ $t('edit-course.text2') }}</span>
                  </div>
                </label>
                <datetime id="end" type="datetime" :min-datetime="course.start_date" v-model="course.end_date"
                          input-class="form-control"/>
              </div>
              <div class="col-12 mt-2">
                <label>{{ $t('create-course.barion-wallet') }}</label>
                <div>
                  <i class="fas fa-info-circle"/>{{ $t('create-course.wallet-info') }}
                </div>
                <Select2 v-model="course.barion_wallet_id" :options="barionWallets" :settings="{width:'100%', placeholder: 'XBody system', allowClear:true}" />
              </div>
              <div class="col-12 mt-2">
                <label>{{ $t('create-course.price') }} </label>
                <div v-if="errors['base_price']">
                  <span v-for="(error, idx) in errors['base_price']" style="color: red;" :key="idx">{{ error }}</span>
                </div>
                <input @input="errors['base_price'] = null" type="number" min="0" :disabled="course.finalized"
                       v-model="course.base_price" class="form-control"
                       :style="{border: errors['base_price'] ? '1px solid red' : '1px solid #ced4da'}" required>
              </div>
              <div class="col-12 mt-2">
                <label>{{ $t('create-course.currency') }} </label>
                <div v-if="errors['currency']">
                  <span v-for="(error, idx) in errors['currency']" style="color: red;" :key="idx">{{ error }}</span>
                </div>
                <Select2 v-model="course.currency" :options="currencies" :settings="{width:'100%', placeholder:'currency', allowClear:false}" />
              </div>
              <div class="col-12 mt-2">
                <input  v-model="course.coupon_compatible" true-value='1' false-value='0' id="coupon-compatible"
                        type="checkbox"
                        :style="{border:'1px solid #ced4da',marginRight:'10px'}" >
                <label class="" for="coupon-compatible">{{ $t('edit-course.coupon-compatible') }} </label>
              </div>
              <div class="col-12 mt-2">
                <input  v-model="course.code_required" true-value='1' false-value='0' id="code_required"
                        type="checkbox"
                        :style="{border:'1px solid #ced4da',marginRight:'10px'}" >
                <label class="" for="code_required">{{ $t('edit-course.code_required') }} </label>
              </div>
              <div class="col-12 mt-2">
                <label>{{ $t('manage-courses.finalized') }}</label>
                <div class="ml-2" :style="{color: course.finalized ? 'green' : 'red'}">
                  {{ course.finalized ? $t('common.yes') : $t('common.no') }}
                </div>
                <button type="button" data-toggle="modal" data-target="#finalizeModal"
                        class="btn btn-primary w-100 mt-2"
                        v-if="!course.finalized && $store.state.user?.role === 'Admin'">{{ $t('finalize.title') }}
                </button>
                <button type="button" data-toggle="modal" data-target="#finalizeModal"
                        class="btn btn-primary w-100 mt-2"
                        v-if="!course.finalized && $store.state.user?.role === 'Master'">{{ $t('finalize.title-master') }}
                </button>
                <span v-else-if="!course.finalized" class="ml-2"><i
                    class="fas fa-info-circle text-primary"/> {{ $t("edit-course.finalize-course-message") }}</span>
              </div>
              <div class="col-12 mt-2">
                <label>{{ $t('edit-course.delete') }}</label>
                <div v-if="course.finalized" class="d-flex align-items-center mt-1 mb-0">
                  <i class="fas fa-info-circle mr-1 text-danger"></i>
                  <div>{{ $t('edit-course.delete-info') }}</div>
                </div>
                <button type="button" data-toggle="modal" data-target="#deleteCourseModal"
                        class="btn btn-danger w-100 mt-2" :disabled="course.finalized">{{ $t('common.delete') }}
                </button>
              </div>
              <div class="col-12 mt-2">
                <label>{{ $t('common.duplicate') }}</label>
                <button type="button" class="btn btn-danger w-100 mt-2" @click.prevent="duplicate"
                  :disabled="submitting">{{
                    $t('common.duplicate') }}</button>
              </div>
              <div class="col-12">
                <button type="submit" :disabled="loading" class="btn btn-secondary mt-5 w-100">
                  {{ $t('edit-course.save-changes') }}
                </button>
              </div>
            </div>
          </div>
        </form>

        <!-- Delete course modal -->
        <div class="modal fade" id="deleteCourseModal" ref="deleteCourseModal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{ $t('edit-course.delete') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <span>{{ $t('edit-course.delete-warning') }}</span>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('common.cancel') }}</button>
                <button type="button" class="btn btn-danger" :disabled="deleting" @click.prevent="deleteCourse">
                  {{ $t('common.delete') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Delete badge modal -->
        <div class="modal fade" id="deleteBadgeModal" ref="deleteBadgeModal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{ $t('edit-course.delete-badge') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <span>{{ $t('edit-course.delete-badge-warning') }}</span>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('common.cancel') }}</button>
                <button type="button" class="btn btn-danger" :disabled="deleting" @click.prevent="deleteBadge">
                  {{ $t('common.delete') }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Presenters modal -->
        <div class="modal fade" id="presentersModal" tabindex="-1" role="dialog"
             aria-labelledby="newpresentersModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="newpresentersModalLabel">{{ $t('edit-course.speakers-mod') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-12">
                    <label>{{ $t('create-course.no-user') }}</label>
                    <textarea class="form-control w-100" type="text" v-model="course.external_presenters"></textarea>
                  </div>
                  <div class="col-12 mt-3">
                    <span>{{ $t('create-course.registered') }} </span>
                    <div class="mt-2 d-flex align-items-center" v-for="user in course.presenters" :key="user.id">
                      <div class="d-inline-flex align-items-center">
                        <img v-if="user.avatar"
                             :src="$store.state.storageURL+user.avatar.path+'?token='+$store.state.token"
                             style="border-radius: 50%; height: 50px" class="ml-0" alt="">
                        <Avatar v-else class="team-image ml-0" :username="user.name" :size="50"/>
                        <h6 class="name ml-2 mt-0 pl-0" style="font-weight: bold">
                          {{ user.name }} <br/>
                          <span class="email mb-1">{{ user.email }}</span>
                        </h6>
                      </div>
                      <span class="ml-4 mb-1" style="cursor: pointer" @click.prevent="removeFromPresenters(user)">
                                            <i class="fas fa-times font-24" style="color: red; font-size: 24px"></i>
                                        </span>
                    </div>
                    <div class="mt-3 ml-2" v-if="course.presenters.length < 1">
                      <strong>{{ $t('create-course.no-speakers') }}</strong>
                    </div>
                  </div>
                  <label class="col-12 mt-5">{{ $t('create-course.new-speakers') }}</label>
                  <form class="col-md-8 mt-0 col-12 d-flex alig-items-center" @submit.prevent="startPresenterSearch">
                    <input type="text" class="form-control" :placeholder="$t('edit-course.username')"
                           v-model="presenterSearchStr">
                  </form>
                  <div class="col-md-4 mt-0 col-12 mb-4">
                    <button :disabled="searching" type="button" class="btn btn-secondary"
                      @click="startPresenterSearch()">{{
                        $t('create-course.search') }}
                    </button>
                  </div>
                  <div v-if="!searching" class="col-12 mt-2 mb-3">
                    <div
                        v-for="user in presenterResults.filter(u => course.presenters.findIndex(user => user.id == u.id) === -1)"
                        :key="user.id" class="mt-3">
                      <div class="d-flex align-items-center" style="cursor: pointer;"
                           @click.prevent="addToPresenters(user)" data-dismiss="modal">
                        <img v-if="user.avatar"
                             :src="$store.state.storageURL+user.avatar.path+'?token='+$store.state.token"
                             style="border-radius: 50%; height: 50px" class="ml-0" alt="">
                        <Avatar v-else class="team-image ml-0" :username="user.name" :size="50"/>
                        <h6 class="name ml-2 mt-0 pl-0" style="font-weight: bold">
                          {{ user.name }} <br/>
                          <span class="email mb-1">{{ user.email }}</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div v-else class="col-12">
                    <Loading height="30vh"/>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
              </div>
            </div>
          </div>
        </div>


        <!-- New location modal -->
        <div class="modal fade" ref="newLocationModal" id="newLocationModal" tabindex="-1" role="dialog"
             aria-labelledby="newLocationModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="newLocationModalLabel">{{ $t('edit-course.new-location') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="row">
                    <div class="col-12 mt-2">
                      <label>{{ $t('edit-course.country') }}</label>
                      <div v-if="errors['country']">
                        <span v-for="(error, idx) in errors['country']" style="color: red;"
                              :key="idx">{{ error }}</span>
                      </div>
<!--                      <select :disabled="course.finalized" v-model="locationData.country_id" class="form-control">
                        <option v-for="country in countries" :value="country.id" :key="country.id">{{ country.name }}
                        </option>
                      </select>-->
                      <Select2 v-model="locationData.country_id" :options="countries" :settings="{width:'100%', disabled:course.finalized}" />
                    </div>
                    <div class="col-12 mt-2">
                      <label>{{ $t('edit-course.zip-code') }}</label>
                      <div v-if="errors['zip_code']">
                        <span v-for="(error, idx) in errors['zip_code']" style="color: red;"
                              :key="idx">{{ error }}</span>
                      </div>
                      <input type="text" :disabled="course.finalized" v-model="locationData.zip_code"
                             class="form-control"
                             :style="{border: errors['zip_code'] ? '1px solid red' : '1px solid #ced4da'}" required>
                    </div>
                    <div class="col-12 mt-2">
                      <label>{{ $t('edit-course.city') }}</label>
                      <div v-if="errors['locality']">
                        <span v-for="(error, idx) in errors['locality']" style="color: red;"
                              :key="idx">{{ error }}</span>
                      </div>
                      <input type="text" :disabled="course.finalized" v-model="locationData.locality"
                             class="form-control"
                             :style="{border: errors['locality'] ? '1px solid red' : '1px solid #ced4da'}" required>
                    </div>
                    <div class="col-12 mt-2">
                      <label>{{ $t('edit-course.street-name') }}</label>
                      <div v-if="errors['public_space']">
                        <span v-for="(error, idx) in errors['public_space']" style="color: red;"
                              :key="idx">{{ error }}</span>
                      </div>
                      <input type="text" :disabled="course.finalized" v-model="locationData.public_space"
                             class="form-control"
                             :style="{border: errors['public_space'] ? '1px solid red' : '1px solid #ced4da'}" required>
                    </div>
                    <div class="col-12 mt-2">
                      <label>{{ $t('edit-course.street-type') }}</label>
                      <div v-if="errors['public_space_type']">
                        <span v-for="(error, idx) in errors['public_space_type']" style="color: red;"
                              :key="idx">{{ error }}</span>
                      </div>
                      <input type="text" :disabled="course.finalized" v-model="locationData.public_space_type"
                             class="form-control"
                             :style="{border: errors['public_space_type'] ? '1px solid red' : '1px solid #ced4da'}"
                             required>
                    </div>
                    <div class="col-12 mt-2">
                      <label>{{ $t('edit-course.other') }}</label>
                      <div v-if="errors['other']">
                        <span v-for="(error, idx) in errors['other']" style="color: red;" :key="idx">{{ error }}</span>
                      </div>
                      <input type="text" :placeholder="$t('course-materials.placeholder')" :disabled="course.finalized"
                             v-model="locationData.other" class="form-control"
                             :style="{border: errors['other'] ? '1px solid red' : '1px solid #ced4da'}">
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{
                    $t('edit-course.close')
                  }}
                </button>
                <button type="button" @click="createLocation" :disabled="locationLoading" class="btn btn-primary">
                  {{ $t('edit-course.create') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="finalizeModal" ref="finalizeModal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{ $t('finalize.title') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>{{ $t('finalize.intro') }}</p>
                <ul>
                  <li>{{ $t('finalize.li1') }}</li>
                  <li>{{ $t('finalize.li2') }}</li>
                  <li>{{ $t('finalize.li3') }}</li>
                </ul>
                <p>{{ $t('finalize.outro') }} </p>
                <div>{{ $t('delete-modal.line-3') }} <br><strong>{{ confirmText }}</strong></div>
                <input type="text" class="form-control mt-3" :placeholder="$t('delete-modal.placeholder')"
                       v-model="userText">
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('common.cancel') }}</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" :disabled="userText !== confirmText"
                        @click.prevent="finalize">{{ $t('finalize.finalize') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Loading v-else height="80vh"/>
  </div>
</template>
<script>
import Loading from '@/components/Loading'
import Datetime from '@/components/DateTimePicker/Datetime.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import Avatar from '@/components/Avatar.vue'
import RecommendedCoursesModal from '@/components/RecommendedCoursesModal.vue'
import PrerequisiteCoursesModal from '../components/PrerequisiteCoursesModal.vue'
import UsersContractReceivedModal from '../components/UsersContractReceivedModal.vue'
import Select2 from "vue3-select2-component";
import AvailableInCountries from "./AvailableInCountries.vue";
import TemporaryCertificateInput from "./TemporaryCertificateInput.vue";

export default {
  name: 'EditCourse',
  components: {
    TemporaryCertificateInput,
    AvailableInCountries,
    Loading,
    Datetime,
    ImageCropper,
    Avatar,
    RecommendedCoursesModal,
    PrerequisiteCoursesModal,
    UsersContractReceivedModal,
    Select2
  },
  mounted() {
    this.loading = true
    this.axios.get('/currencies')
        .then(resp => this.currencies = resp.data.map(currency => ({
          id: currency.currency,
          text: currency.currency
        })))
    this.axios.get('/courses/' + this.$route.params.course + '/edit')
        .then(resp => {
          this.course = resp.data.course
          this.finalized = this.course.finalized
          console.log(this.course);
        })
        .finally(() => this.loading = false)
    this.axios.get('/barion-wallets')
        .then(resp => this.barionWallets = resp.data.barion_wallets.map(wallet => ({
          id: wallet.id,
          text: wallet.email
        })));
    this.axios.get('/course-types')
        .then(resp => this.courseTypes = resp.data.map(type => ({
          id: type.id,
          text: type.name
        })));
    this.axios.get('/courses/locations')
        .then(resp => {
          this.locations = resp.data.map(location => ({
            id: location.id,
            text: `${location.zip_code}, ${location.locality}, ${location.public_space} ${location.public_space_type}, ${location.other}, ${location.country.name}`
          }));
        });
    this.axios.get('/countries')
        .then(resp => this.countries = resp.data.map(country => ({
          id: country.id,
          text: country.name
        })))
    this.getBadges();
    this.axios.get('/vat-rates')
        .then(resp => this.vatRates = this.vatRates.concat(resp.data))
    this.axios.get('/certificate-types')
        .then(resp => this.certificateTypes = resp.data.map(type => ({
          id: type.type,
          text: type.type
        })))
    this.axios.get('/course-groups')
        .then(resp => {
              this.courseGroups = [];
                  resp.data.course_groups.forEach(courseGroup => {
                //add course group children also to the list
                    this.courseGroups.push({id: courseGroup.id, text: courseGroup.title});
                    if (courseGroup.children.length > 0) {
                  courseGroup.children.forEach(child => {
                    this.courseGroups.push({id: child.id, text: " - "+child.title});
                  });
                }
              });});
  },
  data() {
    return {
      courseTypes: [],
      badgeToDelete: null,
      submitting: false,
      limitExceeded:false,
      uploadedAvatarURL: null,
      badgeUploading: false,
      badgeImage: null,
      uploadType: null,
      presenterResults: [],
      presenterSearchStr: null,
      searching: false,
      locations: [],
      badges: [],
      countries: [],
      vatRates: [],
      certificateTypes: [],
      courseGroups: [],
      barionWallets: [],
      userText: null,
      confirmText: 'I UNDERSTAND',
      currencies: [],
      locationData: {
        country_id: 141
      },
      finalized: null,
      loading: false,
      locationLoading: false,
      course: null,
      errors: Object(),
      regexErrors: {
        bar_association_number: null,
        tax_id: null,
        taj_id: null,
        educational_id: null
      },
      deleting: false
    }
  },
  methods: {
    removeFromPresenters(user) {
      const idx = this.course.presenters.findIndex(p => p.id == user.id)
      if (idx !== -1)
        this.course.presenters.splice(idx, 1)
    },
    addToPresenters(user) {
      this.course.presenters.push(user);
    },
    startPresenterSearch() {
      this.searching = true
      let params = {
        search: this.presenterSearchStr
      }
      this.axios.get('/users/presenters/' + this.course.id, {params})
          .then(resp => {
            this.presenterResults = resp.data.users
          })
          .finally(() => this.searching = false)
    },
    createUpload(url) {
      if (url) {
        let file = this.dataURLtoFile(url)
        let formData = new FormData;
        formData.set('image', file, file.name);
        this.course.cover_imageUploading = true
        console.log(formData)
        let config = {
          method: 'POST',
          data: formData,
          url: `/upload/image`,
          baseURL: process.env.VUE_APP_STORAGE_URL,
          headers: {
            'Content-Type': 'application/octet-stream'
          },
        };
        this.axios(config)
            .then(async (resp) => {
              config.url += '/' + resp.data.name
              config.url += '/finish';
              config.data = null;
              resp = await this.axios(config)
              this.course.cover_image = resp.data.multimedia;
              this.course.cover_image_id = resp.data.multimedia.id
              await this.axios.patch('/courses/' + this.course.id, {cover_image_id: this.course.cover_image_id})

            })
            .catch((e) => {
              console.log(e)
            })
            .finally(() => this.course.cover_imageUploading = false)
      }

    },
    readURL(input) {
      input = input.target
      if (input.files && input.files[0]) {
        this.uploadType = input.files[0].type
        var reader = new FileReader()
        reader.onload = (e) => {
          this.uploadedAvatarURL = e.target.result
        }

        reader.readAsDataURL(input.files[0]) // convert to base64 string
      }
    },
    dataURLtoFile(dataurl, filename) {
      console.log(filename);
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], `${this.course.id}_cover_image`, {type: mime});
    },
    uploadBadgeImage(event){
      //Upload image from input
      let file = event.target.files[0];
      let formData = new FormData();
      formData.set('image', file, file.name);
      this.badgeUploading = true;
      let config = {
        method: 'POST',
        data: formData,
        url: `/upload/image`,
        baseURL: process.env.VUE_APP_STORAGE_URL,
        headers: {
          'Content-Type': 'application/octet-stream'
        },
      };
      this.axios(config)
          .then(resp => {
            config.url += '/' + resp.data.name
            config.url += '/finish';
            config.data = null;
            this.axios(config)
                .then(resp => {
                  console.log(resp.data);
                  this.badgeImage = resp.data.multimedia;
                  this.createBadge(resp.data.multimedia);
                })
          })
          .catch((e) => this.badgeUploading = false)
    },
    createBadge(multimedia) {
      let data = {
        title: multimedia.name,
        description: multimedia.name,
        multimedia_id: multimedia.id,
      }
      this.axios.post('/badges', data)
          .then(resp => {
            this.badges.push(resp.data.badge)
          })
          .catch(e => console.log(e))
          .finally(() => this.badgeUploading = false);
    },
    getBadges() {
      this.axios.get('/badges')
          .then(resp => this.badges = this.badges.concat(resp.data.badges));
    },
    deleteBadge() {
      if (this.badgeToDelete) {
        this.deleting = true;
        this.axios.delete('/badges/' + this.badgeToDelete)
            .then(resp => {
              $(this.$refs['deleteBadgeModal']).modal('hide');
              this.badges = this.badges.filter(badge => badge.id !== this.badgeToDelete)
            })
            .catch(e => console.log(e))
            .finally(() => this.deleting = false)
      }

    },
    async removeCoverImage() {
      // this.axios.post(process.env.VUE_APP_STORAGE_URL + '/upload/image/' + this.course.cover_image.id + '/abort')
      await this.axios.post('/courses/' + this.course.id + '/removeImage/' + this.course.cover_image.id)
        .then(resp => {
          location.reload()
        })
        .catch(e => console.log(e))

    },
    removeEmptyOrNull(obj) {
      Object.keys(obj).forEach(k => {
        if ((obj[k] === null || obj[k] === '') && k !== 'location_id')
          delete obj[k]
      })
      return obj;
    },
    duplicate(){
      if(this.submitting) return;
      this.submitting = true;
      this.axios.post(`/courses/${this.course.id}/duplicate`)
          .then(resp => {
           console.log(resp.data);
            this.$router.push({name: 'EditCourse', params: {course: resp.data.course.id}});
          })
          .catch(e => console.log(e))
          .finally(() => this.submitting = false)
    },
    submit() {
      this.loading = true
      if (!this.course.temporary_certificate){
        delete this.course.temporary_certificate_type;
        delete this.course.temporary_certificate_validity;
      }
      this.axios.patch('/courses/' + this.course.id, this.course)
          .then(resp => {
            this.uploadedAvatarURL = null
            this.course = resp.data.course
          })
          .catch(e => this.errors = e.response.data.errors)
          .finally(() => this.loading = false)
    },
    createLocation() {
      this.locationLoading = true;
      this.axios.post('/locations', this.locationData)
          .then(resp => {
            this.locations.push(resp.data.location)
            this.course.location_id = resp.data.location.id
            this.locationData = {
              country_id: 141
            }
            $(this.$refs.newLocationModal).modal('hide')
          })
          .catch(e => {
            Object.assign(this.errors, e.response.data.errors)
          })
          .finally(() => this.locationLoading = false)
    },
    createFeedback() {
      this.submitting = true
      this.axios.post('/courses/' + this.course.id + '/feedback')
          .then(resp => {
            this.$router.push({name: 'EditCourseFeedback', params: {course: this.course.id}})
          })
          .finally(() => this.submitting = false)
    },
    finalize() {
      this.loading = true
      this.axios.post('/courses/' + this.course.id + '/finalize')
          .then(resp => {
            this.course.finalized = true
          })
          .catch(e => console.log(e))
          .finally(() => this.loading = false)
    },
    deleteCourse() {
      this.deleting = true
      this.axios.delete('/courses/' + this.course.id)
          .then(resp => {
            $(this.$refs['deleteCourseModal']).modal('hide')
            this.$router.push({name: 'ManageCourses'})
          })
          .catch(e => console.log(e))
          .finally(() => this.deleting = false)
    }
  },
  computed: {
    charactersLeft() {
      let char = this.course.contents.length,
          limit = 3000;
      this.limitExceeded = limit<char;
      return (limit - char) + " / " + limit;
    }
  },
  watch:{
    "course.certificate_type":{
      handler: function (val) {
        if (this.course.temporary_certificate){
          this.course.temporary_certificate_type = val;
        }
      }
    }
  }
}
</script>
<style scoped>
.badge-wrapper {
  border: 1px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}

.badge-container {
  flex-basis: 0;
  margin: 8px;
  max-width: 100%;
  width: 60px;
  height: 60px;
}


.badge-container img {
  cursor: pointer;
  transition: transform 1s;
  object-fit: cover;
}

.badge-container label {
  overflow: hidden;
  width: 50px;
  position: relative;
}
.imgbgchk:checked + label > .tick_container {
  opacity: 1;
}

/*         aNIMATION */
.imgbgchk:checked + label > img {
  transform: scale(1.1);
  opacity: 0.3;
}

.tick_container {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  cursor: pointer;
  text-align: center;
}
.tick_container-add {
  background-color: var(--red);
  transition: .5s ease;
  opacity: 1;
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  cursor: pointer;
  text-align: center;
}

.tick {
  color: var(--red);
  font-size: 16px;
  height: 20px;
  width: 20px;
  border-radius: 100%;
}
.tick-add {
  color: var(--white);
  font-size: 16px;
  height: 20px;
  width: 20px;
  border-radius: 100%;
}
.delete-badge-button{
  top: -50px;
  left: 16px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border:none;
  color: white;
  background-color: var(--red);
  position: relative;
}
.limiter{
  font-size: 12px;
}
.limit_exceeded{
  color: red;
}

</style>
