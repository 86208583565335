<template>
    <div class="modal fade" id="createCustomPageGroupModal" tabindex="-1" role="dialog" ref="createCustomPageGroupModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{$t('custom-page-groups.new')}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form @submit.prevent="submit" class="row" id="newCategoryForm">
                    <div class="col-12 mt-3">
                        <div class="d-flex align-items-center mb-2 text-18 weight-600 text-title-gray">
                            <img src="assets/img/lang/hu.png" style="height: 20px;">
                            <label class="mb-0 ml-1 mt-0">{{ $t('custom-page-groups.title') }}</label>
                        </div>
                        <input type="text" class="form-control" required v-model="customPageGroup.title.hu">
                    </div>
                    <div class="col-12 mt-3">
                        <div class="d-flex align-items-center mb-2 mt-3 text-18 weight-600 text-title-gray">
                            <img src="assets/img/lang/en.png" style="height: 20px;">
                            <label class="mb-0 ml-1 mt-0">{{ $t('custom-page-groups.title') }}</label>
                        </div>
                        <input type="text" class="form-control" required v-model="customPageGroup.title.en">
                    </div>
                    <div class="col-12 mt-3">
                        <label class="text-18 weight-600 text-title-gray">{{ $t('custom-page-groups.order') }}</label>
                        <input type="number" min="1" class="form-control" required v-model="customPageGroup.order">
                    </div>
                </form>
            </div>
            <div class="modal-footer">
              <PrimaryButton :disabled="submitting" form="newCategoryForm" :text="$t('custom-page-groups.create')" />
              <TransparentButton data-dismiss="modal" :text="$t('common.cancel')"/>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import PrimaryButton from "./settings/PrimaryButton.vue";
import TransparentButton from "./settings/TransparentButton.vue";

export default {
    name: 'NewCourseCategoryModal',
  components: {TransparentButton, PrimaryButton},
    emits: ['categoryCreated'],
    data() {
        return {
            customPageGroup: {
                title: {
                    hu: null,
                    en: null
                },
                order: null
            },
            submitting: false
        }
    },
    methods: {
        submit() {
            this.submitting = true
            this.axios.post('/custom-page-groups', this.customPageGroup)
                .then(resp => {
                    const customPageGroups = this.$store.state.customPageGroups
                    customPageGroups.push(resp.data.custom_page_group)
                    this.$store.commit('setCustomPageGroups', customPageGroups)
                    $(this.$refs.createCustomPageGroupModal).modal('hide')
                })
                .catch(e => console.log(e))
                .finally(() => this.submitting = false)
        }
    }
}
</script>
