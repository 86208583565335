<template>
<div class="modal fade" :id="'editCouponModal-'+coupon.id" tabindex="-1" role="dialog" :ref="'editCouponModal-'+coupon.id">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{$t('coupon-modal.edit')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form @submit.prevent="submit" class="row" :id="'editCouponForm-'+coupon.id">
                <div class="col-12">
                    <label>{{$t('checkout-summary.coupon-code')}}</label>
                    <input type="text" class="form-control" required v-model="coupon.code">
                </div>
                <div class="col-12 mt-3">
                    <label>{{$t('coupon-modal.disc')}} {{coupon.percentage_based ? '(%)' : '(EUR)'}}</label>
                    <input type="number" min="1" class="form-control w-90" required v-model="coupon.discount">
                </div>
                <div class="col-12 mt-3">
                    <label>{{$t('coupon-modal.disc-type')}}</label>
                    <select class="form-control" v-model="coupon.percentage_based">
                        <option :value="true">{{$t('coupon-modal.percentage')}}</option>
                        <option :value="false">{{$t('coupon-modal.cash')}}</option>
                    </select>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="submit" :form="'editCouponForm-'+coupon.id" :disabled="submitting || coupon.discount < 0 || !String(coupon.code).trim()" class="btn btn-primary">{{$t('common.save-changes')}}</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('common.cancel')}}</button>
        </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: 'EditCouponModal',
    props: {
        coupon: Object
    },
    emits: ['couponEdited'],
    data() {
        return {
            submitting: false
        }
    },
    methods: {
        submit() {
            this.submitting = true
            this.axios.patch('/coupons/'+this.coupon.id, this.coupon)
                .then(resp => {
                    this.$emit('couponEdited', resp.data.coupon)
                    $(this.$refs['editCouponModal-'+this.coupon.id]).modal('hide')
                })
                .catch(e => console.log(e))
                .finally(() => this.submitting = false)
        }
    }
}
</script>