<template>
  <div class="row ml-1" v-if="!activeAttempt && !afterAttemptData.show  && !attemptData.show && !loading">
    <div class="col-12 mt-3">
      <span><strong>{{ $t('test-element.min-pass-percentage') }}</strong> {{ test.min_pass_percentage }}%</span>
    </div>
    <div class="col-12 mt-3">
      <span><strong>{{ $t('test-element.length') }}</strong> {{ test.length }} {{ $t('view-course.minute') }}</span>
    </div>
    <div class="col-12 mt-3">
      <span><strong>{{ $t('test-element.type') }}</strong> {{ test.exam ? $t('test-element.exam') : $t('test-element.check') }}</span>
    </div>
    <div class="col-12 mt-3">
      <span><strong>{{ $t('test-element.opens_at') }}</strong> {{ test.start_date ? $store.getters.parseDateTime(test.start_date) : $t('test-element.cbegin') }}</span>
    </div>
    <div class="col-12 mt-3">
      <span><strong>{{ $t('test-element.closes_at') }}</strong> {{ test.end_date ? $store.getters.parseDateTime(test.end_date) : $t('test-element.cend') }}</span>
    </div>
    <div class="col-12 mt-3">
      <span><strong>{{ $t('test-element.tries') }}</strong> {{ attempts.length }} / {{ test.max_attempts }}</span>
      <ul class="mt-1">
        <li v-for="(attempt, idx) in attempts" :key="attempt.id">
          <div v-if="attempt.corrected">
            <strong v-if="attempt.corrected" data-dismiss="modal" style="color: var(--black);cursor: pointer"
                    @click="viewAttempt(attempt.id)">#{{ idx + 1 }}</strong>:
            <span
                :style="{color: attempt.percentage >= attempt.test.min_pass_percentage ? 'green' : 'red'}">{{ Math.floor(attempt.percentage) }}%</span>
          </div>
          <strong v-else>#{{ idx + 1 }} -
            {{ new Date(Date.parse(attempt.finished_at)) > new Date() ? $t('test-element.in-progress') : $t('test-element.correcting') }}</strong>
        </li>
      </ul>
    </div>
    <div v-if="$store.state.user.activeTestAttempt != null" class="col-12 mt-3">
      {{ $t('test-element.active-attempt-warning') }}
    </div>
    <div v-if="attempts.length >= test.max_attempts && noSuccessfulAttempts()"
         class="col-12 d-flex mt-2" style="flex-direction: column;">
      <span>{{ $t('test-element.no-attempts-left') }}</span>
      <span class="mb-4">{{ $t('test-element.rewatch') }}</span>
      <button v-if="test.attempt_package" class="btn btn-primary mb-3" style="width: fit-content;"
              @click.prevent="buyTestAttempt" :disabled="attemptStarting">
        {{ $t('test-element.buy-test-attempts') + " (" + test.attempt_package.attempt_count + " : " + test.attempt_package.net_price + "€)" }}
      </button>
    </div>
    <div class="col-12 mb-2">
      <button
          v-if="test.start_date && new Date(Date.parse(test.start_date)) > new Date()"
          type="button" data-dismiss="modal" class="btn btn-primary" disabled>{{ $t('test-element.not-open') }}
      </button>
      <button
          v-else-if="test.end_date && new Date(Date.parse(test.end_date)) < new Date()"
          type="button" data-dismiss="modal" class="btn btn-primary" disabled>{{ $t('test-element.closed') }}
      </button>
      <!-- <button v-else-if="attempts.length >= test.max_attempts" type="button" data-dismiss="modal" class="btn btn-primary" disabled>Nincs több próbálkozása</button> -->
<!--      <button v-else-if="attempts.length < test.max_attempts && !fullPointAttempt"
              data-toggle="modal" data-target="#retryConfirmModal"
              :disabled="attemptStarting || test.loading || $store.state.user.activeTestAttempt != null"
              class="btn btn-primary">{{ $t('test-element.new-attempt') }}
      </button>-->
    </div>
  </div>
  <div class="p-4" v-else-if="activeAttempt">
    <active-test-attempt @finish="onFinish" :isQuestionnaire="true"/>
  </div>
  <div class="p-4" v-else-if="afterAttemptData.show">
    <after-exam :attemptId="afterAttemptData.attempt" :testId="afterAttemptData.test" @ok="onOk" :isQuestionnaire="true"/>
  </div>
  <div class="p-4" v-else-if="attemptData.show">
    <view-test-attempt @back="back" :testAttempt="attemptData.attempt" :nested="true" :isQuestionnaire="true"/>
  </div>
  <loading height="300px" v-else/>
</template>
<script>
import ActiveTestAttempt from '../../views/ActiveTestAttempt.vue'
import AfterExam from '../../views/AfterExam.vue'
import Loading from '@/components/Loading.vue'
import ViewTestAttempt from '../../views/ViewTestAttempt.vue'

export default {
  components: {ActiveTestAttempt, AfterExam, Loading, ViewTestAttempt},
  name: 'FillableTestElement',
  props: {
    test: Object,
    attempts: Object
  },
  data() {
    return {
      attemptStarting: false,
      afterAttemptData: {
        show: false,
        test: null,
        attempt: null
      },
      attemptData: {
        show: false,
        attempt: null
      },
      loading: false
    }
  },
  computed: {
    activeAttempt() {
      return this.$store.state.user.activeTestAttempt?.test_id == this.test.id
    },
    //check if attempt percentage is 100%
    fullPointAttempt() {
      let attempt = this.attempts.find(attempt => (attempt.finished_at != null && attempt.finished && attempt.percentage === 100));
      if (attempt) {
        this.viewAttempt(attempt.id);
      }
      return !!attempt;
    }
  },
  watch: {
    element: {
      handler(el, prev) {
        this.loading = false
      },
      deep: true
    }
  },
  mounted() {
    console.log("attempts", this.fullPointAttempt)
  },
  methods: {
    viewAttempt(attempt) {
      this.attemptData = {
        show: true,
        attempt: attempt
      }
    },
    onOk(attempt = null) {
      console.log("onOk", this.attemptData, this.afterAttemptData, this.activeAttempt);
      if (this.afterAttemptData.show = true && attempt == null) {
        //this.loading = true
        this.afterAttemptData.show = false
      } else if (attempt) {
        this.attemptData = {
          show: true,
          attempt: attempt
        }
      }
    },
    onFinish(test, attempt) {
      this.afterAttemptData = {
        show: true,
        test: test,
        attempt: attempt
      }
    },
    buyTestAttempt() {
      this.attemptStarting = true
      this.axios.post(`/attempt-package/${this.test.attempt_package.id}/add-to-cart`)
          .then(resp => {
            //this.$emit('pullback');
            console.log('resp', resp);
            this.$router.push({name: 'Cart'});
          })
          .catch(() => this.attemptStarting = false)
    },
    noSuccessfulAttempts() {
      return !this.attempts.some(a => a.percentage >= this.test.min_pass_percentage) && !this.attempts.some(a => !a.corrected && !a.autocorrected)
    },
    back() {
      this.attemptData.show = false;
      console.log(!this.activeAttempt && !this.afterAttemptData.show  && !this.attemptData.show && !this.loading);
      console.log("back", this.activeAttempt, this.afterAttemptData.show, this.attemptData.show, this.loading);
    }
  }
}
</script>
