<template>
  <div class="main-part px-4 py-4">
    <select
      v-model="selectedInterval"
      :disabled="loading"
      name="interval"
      id="interval"
      class="mb-5"
    >
      <option value="month">{{ $t("time-unit.month") }}</option>
      <option value="year">{{ $t("time-unit.year") }}</option>
    </select>
    <canvas id="percentageCouponUsageDetailsChart"></canvas>
  </div>
</template>

<script>
export default {
  mounted() {
    // eslint-disable-next-line no-undef
    const pcdctx = $("#percentageCouponUsageDetailsChart");

    this.percentageCouponDetailsChart = new Chart(pcdctx, {
      type: "line",
      data: {
        labels: this.selectedPercentageCouponData.map((d) => d.interval),
        datasets: [
          {
            label: "Dataset 1",
            data: this.selectedPercentageCouponData.map((d) => d.count),
            backgroundColor: "rgb(23, 111, 183, 0.8)",
            borderColor: "rgb(23, 111, 183, 1)",
          },
        ],
      },
      options: {
        responsive: true,
        indexAxis: "y",
        scales: {
          y: {
            min: 0,
          },
        },
        legend: {
          display: false,
          labels: {
            fontColor: "rgb(255, 99, 132)",
          },
        },
      },
    });
  },
  props: ["selected"],
  watch: {
    selectedInterval() {
      if (this.percentageCouponDetailsChart && this.selectedInterval) {
        this.getSelectedPercentageCouponData();
      }
    },
    selected() {
      if (this.selected) {
        this.getSelectedPercentageCouponData();
      }
    },
  },
  data() {
    return {
      selectedInterval: "month",
      loading: false,
      selectedPercentageCoupon: null,
      selectedPercentageCouponData: [],
    };
  },
  methods: {
    getSelectedPercentageCouponData() {
      this.loading = true;
      this.axios
        .get(
          `/coupons/statistics/${this.selected}/percentage/${this.selectedInterval}`
        )
        .then((resp) => {
          this.selectedPercentageCouponData = resp.data;
          this.percentageCouponDetailsChart.data.labels = this.selectedPercentageCouponData.map(
            (d) => d.interval
          );
          this.percentageCouponDetailsChart.data.datasets[0].data = this.selectedPercentageCouponData.map(
            (d) => d.count
          );
          this.percentageCouponDetailsChart.update();
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.main-part {
  height: 100%;
  min-height: 396px;
}

select {
  background-color: #f3f3f3;
  width: 60%;
  border-radius: 8px;
  border: 1px solid #768692;
  padding: 5px;
  color: #768692;
  background-image: url("~@/assets/img/icons/arrow-down.png");
  background-repeat: no-repeat;
  background-position: right 8px center;
  appearance: none;
}
</style>
