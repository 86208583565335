<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('glossary.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('settings.settings') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div id="content-2">
      <div class="container">
        <div class="row">
          <section id="settings" class="custom-input">
            <div class="row">
              <div class="col-lg-6">
                <div class="row ml-0">
                  <!-- GENERAL SETTINGS -->
                  <div class="col-12">
                    <form @submit.prevent="submit">
                      <div class="row">
                        <div class="col-12">
                          <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('settings.normal-settings') }}</h2>
                        </div>
                        <div class="col-lg-4">
                          <div class="row">
                            <div class="col-12">
                              <Loading v-if="avatarUploading" height='160px' style="border-radius: 50%"/>
                              <Avatar v-else-if="!avatarURL && $store.state.user" :username="$store.state.user.name"
                                      :size="160" :rounded="false" style="cursor: pointer;border-radius: 50%"
                                      @click="$refs.fileUpload.click()"/>
                              <img v-else class="mb-3 avatar-rounded" :src="avatarURL" width="160" height="160"
                                   @click="$refs.fileUpload.click()" style="cursor: pointer;">
                              <div class="align-top mb-3 ml-sm-4 mb-sm-0" style="display: none;">
                                <label class="mt-0 mb-5"> {{ $t('settings.upload-new-pic') }}</label>
                                <br/>
                                <input type="file" accept="image/png, image/jpeg, image/jpg" id="uploadAvatar"
                                       ref="fileUpload" @change="readURL">
                                <br/>
                                <sub> {{ $t('settings.max-pic') }} </sub>
                              </div>
                              <ImageCropper v-if="uploadedAvatarURL" @urlChanged="setCroppedAvatarURL"
                                            :src="uploadedAvatarURL" :type="uploadType"/>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <div class="justify-content-start badge-wrapper">
                                <div class='badge-container text-center border-1'>
                                  <input type="radio" name="selected_badge" v-model="data.badge_id" id="none"
                                         class="d-none imgbgchk" :value="null">
                                  <label for="none">
                                    <img style="width: 50px; height: 50px">
                                    <div class="tick_container">
                                      <div class="tick"><i class="fa fa-check"></i></div>
                                    </div>
                                  </label>
                                </div>
                                <div v-for="course in [...new Map(coursesFinished.map(item =>[item['badge_id'], item])).values()]" :key="course.id">
                                  <div v-if="course.badge" class='badge-container text-center border-1'>
                                    <input type="radio" name="selected_badge" v-model="data.badge_id"
                                           :id="'badge'+ course.badge.id" class="d-none imgbgchk"
                                           :value="course.badge.id">
                                    <label :for="'badge'+ course.badge.id">
                                      <img
                                          :src="$store.state.storageURL+course.badge.multimedia.path+`?token=${$store.state.token}`"
                                          :alt="course.badge.title" style="width: 50px">
                                      <div class="tick_container">
                                        <div class="tick"><i class="fa fa-check"></i></div>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-8">
                          <div class="form-group">
                            <label for="">{{ $t('settings.name') }}</label>
                            <div v-if="errors['name']">
                              <span v-for="(error, idx) in errors['name']" style="color: red;"
                                    :key="idx">{{ error }}</span>
                            </div>
                            <input type="text" class="form-control input-disabled" v-model="data.name" required
                                   :style="{border: errors['name'] ? '1px solid red' : '1px solid #ced4da'}" disabled>
                          </div>
                          <div class="form-group">
                            <label for="">{{ $t('settings.email') }}</label>
                            <div v-if="errors['email']">
                              <span v-for="(error, idx) in errors['email']" style="color: red;"
                                    :key="idx">{{ error }}</span>
                            </div>
                            <input type="email" class="form-control" v-model="data.email" required
                                   :style="{border: errors['email'] ? '1px solid red' : '1px solid #ced4da'}">
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <button type="button" class="btn btn-theme mt-3 mb-3 w-100"
                                      data-target="#changePasswordModal" data-toggle="modal">{{
                                  $t('settings.change-pw')
                                }}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="flex-wrap d-flex mt-3">
                            <div class="d-flex">
                              <button v-if="avatarURL" type="button" class="btn btn-theme-outline mt-3 mb-3"
                                      @click.stop="removeAvatar" :disabled="avatarUploading"><img
                                  src="assets/img/icons/trash-red.svg" class="img-fluid mr-1"
                                  style="height: 23px;margin-top: -4px;" alt="">{{ $t('settings.delete') }}
                              </button>
                            </div>
                            <div class="d-flex ml-auto">
                              <button type="submit"
                                      :disabled="loading || !Object.values(regexErrors).every(o => o === null)"
                                      class="btn btn-theme mt-3 mb-3 "><img src="assets/img/icons/save.svg"
                                                                            class="img-fluid mr-2"
                                                                            alt="">{{ $t('settings.save') }}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 mt-5 mb-0">
                          <h4 class="text-21 text-light-black medium">{{ $t('settings.membership') }}
                            <span v-if="$store.state.user?.hasActiveMembership"
                                  class="text-green bold">{{ $t('settings.active') }}</span>
                            <span v-else class="text-red bold">{{ $t('settings.inactive') }}</span>
                          </h4>
                          <div v-if="$store.state.user?.latestMembership">
                            <h5 class="text-14 text-light-black medium mb-3"
                                v-if="$store.state.user?.hasActiveMembership">
                              {{ $t('settings.expires') }}:
                              {{ $store.getters.parseDateTime($store.state.user.latestMembership.expires_at) }}
                            </h5>
                            <h5 class="text-14 text-light-black medium mb-3" v-else>
                              {{ $t('settings.expired') }}:
                              {{ $store.getters.parseDateTime($store.state.user.latestMembership.expires_at) }}
                            </h5>
                          </div>
                          <h5 v-else class="text-14 text-light-black medium mb-3">{{ $t("settings.NPMY") }}</h5>
                          <a href="" @click.prevent="buyMembership"
                             class="buy-tag">{{
                              $store.state.user?.latestMembership ? $t('settings.CTRYM') : $t('settings.CTPM')
                            }}</a>
                          <div v-if="submitting" class="ml-1 spinner-border text-primary spinner-border-sm"
                               role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <barion-wallets class="col-12 mt-4" v-if="$store.state?.user?.role === 'Master'"/>
                  <!-- //GENERAL SETTINGS -->
                  <!-- BILLING DATA -->
                  <div class="col-12 mt-5">
                    <billing-data/>
                  </div>
                  <!-- //BILLING DATA -->
                </div>
              </div>
              <!-- Settings right pane -->
              <div class="col-lg-6">
                <div class="row">
                  <!-- Qualifications -->
                  <user-qualification-list class="w-100"/>
                  <!-- //Qualifications -->

                  <!-- Professional History -->
                  <professional-history class="w-100"/>
                  <!-- //Professional History -->

                  <!-- Additional Information -->
                  <other-information/>
                  <!-- //Additional Information -->

                  <!-- Delete profile -->
                  <div class="col-12">
                    <h3 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('settings.delete-profile') }}</h3>
                    <button type="button" data-toggle="modal" data-target="#delete-profile-modal" :disabled="loading"
                            class="btn btn-theme mt-2 mb-3 w-100">{{ $t('settings.delete-profile') }}
                    </button>
                    <delete-profile-modal/>
                  </div>
                  <!-- //Delete profile -->
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

    <!-- OLD FRONTEND -->

    <section id="settings">
      <div class="row">
        <div class="col-lg-6 col-12 mt-lg-0 pl-lg-3 mt-4 pl-0 settings-right-side">

          <div class="w-100 mt-4">
            <div class="col-12">

            </div>
          </div>
        </div>
      </div>
    </section>
    <change-password-modal/>
  </div>
</template>
<script>
import {DateTime} from 'luxon'
import ImageCropper from '@/components/ImageCropper'
import Avatar from '@/components/Avatar'
import Loading from '@/components/Loading'
import ChangePasswordModal from '../components/ChangePasswordModal.vue'
import Datetime from '../components/DateTimePicker/Datetime'
import DeleteProfileModal from '../components/DeleteProfileModal.vue'
import BillingData from '../components/UserData/BillingData.vue'
import ProfessionalHistory from '../components/UserData/ProfessionalHistory.vue'
import OtherInformation from '../components/UserData/OtherInformation.vue'
import BarionWallets from '../components/UserData/BarionWallets.vue'
import UserQualificationList from '../components/UserData/UserQualifications/UserQualificationList.vue'

export default {
  name: 'Settings',
  components: {
    ImageCropper,
    Avatar,
    Loading,
    ChangePasswordModal,
    Datetime,
    DeleteProfileModal,
    BillingData,
    ProfessionalHistory,
    OtherInformation,
    BarionWallets,
    UserQualificationList
  },
  mounted() {
    this.axios.get('/bar-associations')
        .then(resp => this.barAssociations = this.barAssociations.concat(resp.data))

    this.axios.get('/qualifications')
        .then(resp => this.qualifications = this.qualifications.concat(resp.data))

    this.axios.get('/countries')
        .then(resp => this.countries = this.countries.concat(resp.data))
    console.log(this.$store.state.user);
  },
  data() {
    return {
      avatarURL: this.$store.state.user && this.$store.state.user.avatar ? this.$store.state.storageURL + this.$store.state.user.avatar.path + '?token=' + this.$store.state.token : null,
      croppedAvatarURL: null,
      uploadedAvatarURL: null,
      uploadType: null,
      avatarUploading: false,
      barAssociations: [],
      nationalities: [],
      qualifications: [],
      coursesFinished: this.$store.state.user.coursesFinished,
      countries: [],
      loading: false,
      submitting: false,
      DateTime: DateTime,
      data: this.$store.state.user ? {
        name: this.$store.state.user.name,
        email: this.$store.state.user.email,
        badge_id: this.$store.state.user.badge ? this.$store.state.user.badge.id : null,
      } : {},
      farContractData: this.$store.state.user && this.$store.state.user.far_contract_data ? {
        birth_name: this.$store.state.user.far_contract_data.birth_name,
        birth_place: this.$store.state.user.far_contract_data.birth_place,
        mothers_name: this.$store.state.user.far_contract_data.mothers_name,
        birth_date: this.$store.state.user.far_contract_data.birth_date,
        first_name: this.$store.state.user.far_contract_data.first_name,
        last_name: this.$store.state.user.far_contract_data.last_name,
        qualification_id: this.$store.state.user.far_contract_data.qualification_id,
        educational_id: this.$store.state.user.far_contract_data.educational_id,
        email: this.$store.state.user.far_contract_data.email
      } : {qualification_id: null},
      barAssociationData: this.$store.state.user ? {
        bar_association_id: this.$store.state.user.bar_association_id,
        bar_association_number: this.$store.state.user.bar_association_number,
        bar_association_name: this.$store.state.user.bar_association_name,
        wants_credits: this.$store.state.user.wants_credits,
      } : {},
      billingData: this.$store.state.user && this.$store.state.user.billing_information ?
          {
            name: this.$store.state.user.billing_information.name,
            phone_number: this.$store.state.user.billing_information.phone_number,
            country_id: this.$store.state.user.billing_information.location.country.id,
            zip_code: this.$store.state.user.billing_information.location.zip_code,
            locality: this.$store.state.user.billing_information.location.locality,
            public_space: this.$store.state.user.billing_information.location.public_space,
            public_space_type: this.$store.state.user.billing_information.location.public_space_type,
            other: this.$store.state.user.billing_information.location.other,
            company_name: this.$store.state.user.billing_information.company_name,
            company_tax_id: this.$store.state.user.billing_information.company_tax_id,
            company_eu_tax_id: this.$store.state.user.billing_information.company_eu_tax_id,
            is_company: Number(
                (this.$store.state.user.billing_information.company_name !== null &&
                    this.$store.state.user.billing_information.company_tax_id !== null &&
                    this.$store.state.user.billing_information.company_eu_tax_id !== null))
          } : {country_id: 141, is_company: 0},
      billingErrors: Object(),
      errors: Object(),
      regexErrors: {
        bar_association_number: null,
        tax_id: null,
        taj_id: null,
        educational_id: null
      }

    }
  },
  computed: {
    shouldShow() {
      return this.wants_credits ? $(this.wants_credits).val() : false
    }
  },
  methods: {
    buyMembership() {
      if (this.submitting) return;
      if (this.$store.state.user.cart_items.some(c => c.cartable_type == 'App\\Models\\Membership')) {
        this.$router.push({name: 'Cart'})
        return;
      }
      this.submitting = true
      this.axios.post(`/memberships/1/cart`)
          .then(resp => {
            const cartItem = resp.data.cart_item
            this.$store.state.user.cart_items.push(cartItem)
          })
          .catch(e => console.log(e))
          .finally(() => this.submitting = false)
    },
    validateBarAssociationNumber() {
      if (/^[0-9]{8}$/.test(this.barAssociationData.bar_association_number)) {
        this.regexErrors['bar_association_number'] = null
      } else {
        this.regexErrors['bar_association_number'] = 'Nem megfelelő formátum'
      }
    },
    validateTaxId() {
      if (/^[0-9]{10}$/.test(this.data.tax_id)) {
        this.regexErrors['tax_id'] = null
      } else {
        this.regexErrors['tax_id'] = 'Nem megfelelő formátum'
      }
    },
    validateTajId() {
      if (/^[0-9]{9}$/.test(this.data.taj_id)) {
        this.regexErrors['taj_id'] = null
      } else {
        this.regexErrors['taj_id'] = 'Nem megfelelő formátum'
      }
    },
    readURL(input) {
      input = input.target
      if (input.files && input.files[0]) {
        this.uploadType = input.files[0].type
        var reader = new FileReader()
        reader.onload = (e) => {
          this.uploadedAvatarURL = e.target.result
        }

        reader.readAsDataURL(input.files[0]) // convert to base64 string
      }
    },
    createUpload(type) {
      if (event.target.files.item(0))
        this.files.push({file: event.target.files.item(0), type: type, shown: true, uuid: null, id: this.files.length})
    },
    finishUpload(id, multimedia) {
      this.files.find(file => file.id === id).uuid = multimedia.id
    },
    cancelUpload(id, uuid) {
      this.files.splice(this.files.indexOf(this.files.find(file => file.id === id)), 1)
    },
    dataURLtoFile(dataurl, filename) {

      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], 'avatar', {type: mime});
    },
    setCroppedAvatarURL(url) {
      this.avatarUploading = true
      let file = this.dataURLtoFile(url)
      let formData = new FormData;
      formData.set('image', file, file.name);
      let config = {
        method: 'POST',
        data: formData,
        url: `/upload/image`,
        baseURL: process.env.VUE_APP_STORAGE_URL,
        headers: {
          'Content-Type': 'application/octet-stream'
        },
      }
      this.axios(config).then(resp => {
        config.url += '/' + resp.data.name
        config.url += '/finish';
        config.data = null;
        this.axios(config)
            .then(resp => {
              const multimedia = resp.data.multimedia;
              this.axios.post('set-avatar', {avatar_id: resp.data.multimedia.id})
                  .then(() => {
                    this.$store.state.user.avatar = multimedia
                    this.avatarURL = this.$store.state.storageURL + multimedia.path + '?token=' + this.$store.state.token
                  })
                  .finally(() => this.avatarUploading = false)
            })
            .catch(() => {
              this.avatarUploading = false
            })
      }).catch((e) => this.avatarUploading = false)
      //    baseURL: process.env.VUE_APP_STORAGE_URL
      // })
    },
    removeAvatar() {
      this.avatarUploading = true
      this.avatarURL = null
      this.$store.state.user.avatar = null
      this.axios.post('/set-avatar', {avatar_id: null})
          .finally(this.avatarUploading = false)
    },
    removeEmptyOrNull(obj) {
      Object.keys(obj).forEach(k => {
        if (obj[k] === null && k!=='badge_id')
          delete obj[k]
      })
      return obj;
    },
    submit() {
      this.loading = true
      if (!this.barAssociationData.wants_credits) {
        this.barAssociationData.bar_association_id = null
        this.barAssociationData.bar_association_name = null
        this.barAssociationData.bar_association_number = null
      }
      this.axios.post('/update-profile', this.removeEmptyOrNull(this.data))
          .then(resp => {
            this.$store.state.user = resp.data.user;
            this.data.badge_id = resp.data.user.badge ? resp.data.user.badge.id : null;

          })
          .catch(e => this.errors = e.response.data.errors)
          .finally(() => this.loading = false)
    },
    setBillingData() {
      this.loading = true
      if (!this.billingData.is_company) {
        this.billingData.company_name = null
        this.billingData.company_tax_id = null
        this.billingData.company_eu_tax_id = null
      }
      this.axios.post('/billing-information', this.removeEmptyOrNull(this.billingData))
          .then(resp => {
            this.$store.state.user = resp.data.user
          })
          .catch(e => this.billingErrors = e.response.data.errors)
          .finally(() => this.loading = false)
    },
    setFarContractData() {
      this.loading = true
      this.farContractData.birth_date = DateTime.fromISO(this.farContractData.birth_date).toFormat('yyyy-MM-dd')
      this.axios.post('/far-contract-data', this.removeEmptyOrNull(this.farContractData))
          .then(resp => {
            this.$store.state.user = resp.data.user
          })
          .catch(e => this.errors = e.response.data.errors)
          .finally(() => this.loading = false)
    },
    updateBarAssociationData() {
      this.loading = true
      this.axios.post('/bar-association-data', this.removeEmptyOrNull(this.barAssociationData))
          .then(resp => {
            Object.assign(this.$store.state.user, resp.data.barAssociationData)
          })
          .catch(e => this.errors = e.response.data.errors)
          .finally(() => this.loading = false)
    }
  }
}
</script>
<style scoped>
#settings {
  margin-top: 30px !important;
}

.buy-tag {
  color: #09A5FC;
  text-decoration: underline;
}

.input-disabled {
  background-color: #f5f5f5;
  border-color: #e3e3e3;
  color: #6c757d;
  cursor: not-allowed;
}

.buy-tag:hover {
  color: #0496e4;
}

textarea {
  height: 60px !important;
}

.badge-wrapper {
  border: 1px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}

.badge-container {
  flex-basis: 0;
  margin: 8px;
  max-width: 100%;
}


.badge-container img {
  cursor: pointer;
  transition: transform 1s;
  object-fit: cover;
}

.badge-container label {
  overflow: hidden;
  position: relative;
}

.imgbgchk:checked + label > .tick_container {
  opacity: 1;
}

/*         aNIMATION */
.imgbgchk:checked + label > img {
  transform: scale(1.1);
  opacity: 0.3;
}

.tick_container {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  cursor: pointer;
  text-align: center;
}

.tick_container-add {
  background-color: var(--red);
  transition: .5s ease;
  opacity: 1;
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  cursor: pointer;
  text-align: center;
}

.tick {
  color: var(--red);
  font-size: 16px;
  height: 20px;
  width: 20px;
  border-radius: 100%;
}

.tick-add {
  color: var(--white);
  font-size: 16px;
  height: 20px;
  width: 20px;
  border-radius: 100%;
}
</style>

