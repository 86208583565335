<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-tests.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('team-members.team-members') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div id="content-2">
      <div class="container">
        <div class="row mt-5">
          <div class="col-12">
            <h2 class="text-18 text-dark-gray semi-bold mb-3"> {{ $t('team-members.certificates') }}</h2>
          </div>
          <section id="list-table" class="table-responsive">
            <!-- TABLE #1 -->
            <h3 class="text-16 text-dark-gray semi-bold mb-3">
              {{ member?.name }}
            </h3>
            <div class="table-responsive">
              <!-- NO DATA -->
              <table class="table table-borderless" v-if="member?.certificates.length == 0">
                <thead>
                <tr class="table-head bg-light-gray-primary text-left">
                  <th scope="col">{{ member?.name }}</th>
                </tr>
                </thead>
                <tbody>
                <tr class="text-center">
                  <td class="text-18 text-light-black semib-old">{{ $t('no-data.certificates') }}</td>
                </tr>
                </tbody>
              </table>
              <!-- //NO DATA -->
              <table class="table" v-if="member?.certificates.length > 0">
                <thead>
                <tr>
                  <th scope="col">{{ $t('my-certificates.id') }}</th>
                  <th scope="col">{{ $t('my-certificates.course') }}</th>
                  <th scope="col">{{ $t('my-certificates.date') }}</th>
                  <th scope="col">{{ $t('my-certificates.certificate') }}</th>
                  <th scope="col">{{ $t('my-certificates.certificate-card') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="certificate in member.certificates" :key="certificate.course_id">
                  <td>#{{ certificate.id }}</td>
                  <td><a style="cursor: pointer;"
                         @click.prevent="$router.push({name: 'Course', params: {id: certificate.course_id}})">{{ certificate.course.title }}</a>
                  </td>
                  <td>{{ $store.getters.parseDateTime(certificate.created_at) }}</td>
                  <td>
                    <button
                        type="button"
                        v-if="certificate && certificate.path != null"
                        :disabled="certificateLoading"
                        class="mt-2 mb-2 btn btn-theme text-center text-white semi-bold"
                        @click.prevent="downloadCertificate(certificate.course)"
                    >
                      <div class="d-flex align-items-center">
                        <i class="fas fa-award mr-1"></i>
                        <span>{{ $t('my-certificates.download') }}</span>
                      </div>
                    </button>
                  </td>
                  <td>
                    <button
                        type="button"
                        :disabled="certificateCardLoading"
                        v-if="certificate && certificate.card_path != null"
                        class="mt-2 mb-2 btn btn-theme text-center text-white semi-bold"
                        @click.prevent="downloadCertificateCard(certificate.course)"
                    >
                      <div class="d-flex align-items-center">
                        <i class="fas fa-id-card mr-1"></i>
                        <span>{{ $t('my-certificates.download-card') }}</span>
                      </div>
                    </button>
                  </td>
                </tr>
                <tr v-if="member.certificates.length == 0">
                  <td colspan="3" class="text-center text-light-black semi-bold pt-4 pb-4">{{
                      $t('no-data.certificates')
                    }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!-- //TABLE #1 -->
          </section>
          <loading height="50vh" width="100%" v-if="loading"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Capacitor} from "@capacitor/core";

export default {
  name: "TeamMember",
  mounted() {
    this.loading = true;
    this.axios.get('/team-members/' + this.$route.params.member)
        .then(resp => this.member = resp.data.member)
        .finally(() => this.loading = false);
  },
  data() {
    return {
      loading: false,
      member: null,
      submitting: false,
      certificateLoading: false,
      certificateCardLoading: false
    }
  },
  methods: {
    downloadCertificate(course) {
      this.certificateLoading = true;
      this.axios.get('/courses/' + course.id + '/certificate?user='+ this.$route.params.member, {responseType: 'blob'})
          .then(resp => {
            const name = course.title + '_cert.pdf'
            if (Capacitor.isNative) {
              this.$store.commit('downloadFileMobile', {name, mime: 'application/pdf', data: resp.data})
            } else {
              const url = window.URL.createObjectURL(new Blob([resp.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', name);
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(e => console.log(e.response))
          .finally(() => this.certificateLoading = false)
    },
    downloadCertificateCard(course) {
      this.certificateCardLoading = true;
      this.axios.get('/courses/' + course.id + '/certificate-card?user='+ this.$route.params.member, {responseType: 'blob'})
          .then(resp => {
            const name = course.title + '_card.pdf'
            if (Capacitor.isNative) {
              this.$store.commit('downloadFileMobile', {name, mime: 'application/pdf', data: resp.data})
            } else {
              const url = window.URL.createObjectURL(new Blob([resp.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', name);
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(e => console.log(e.response))
          .finally(() => this.certificateCardLoading = false)
    },
  }
}
</script>

<style scoped>

</style>
