<template>
    <div v-if="$store.state.user">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#" @click.prevent="$router.push({name: 'CourseCatalog'})">{{ $t('course.courses') }}</a></li>
                <li v-if="courseComputed" class="breadcrumb-item" aria-current="page"><a href="#" @click.prevent="$router.push({name: 'Course', params: {id: courseComputed.id}})">{{courseComputed.title}}</a></li>
                <li class="breadcrumb-item active">{{$t('register2.title')}}</li>
            </ol>
        </nav>
        <section id="settings">
            <div class="row">
                <div class="col-12 mt-2">
                    <span class="mb-4 mt-5">
                        <strong>{{ $t('register2.text4') }}</strong>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-12 mt-2">
                    <div class="row">
                        <form @submit.prevent="submit">
                        <div v-if="false" class="col-12 mt-2">
                            <!-- Jelenleg nincs szükség a profilkép megadásra itt, de ha mégis kell később akkor itt van. --->
                            <p>{{ $t('register2.profile-pic') }}</p>
                            <div class="d-flex flex-wrap">
                                <Loading v-if="avatarUploading" height='128px' />
                                <Avatar v-else-if="!avatarURL && $store.state.user" :username="$store.state.user.name" :size="128" :rounded="false" style="cursor: pointer;" @click="$refs.fileUpload.click()"/>
                                <img v-else class="mb-3" :src="avatarURL" width="128" height="128" @click="$refs.fileUpload.click()" style="cursor: pointer;">
                                <div class="align-top mb-3 ml-sm-4 mb-sm-0" style="display: none;">
                                    <label class="mt-0 mb-5"> {{ $t('register2.upload-new-pic') }}</label>
                                    <br/>
                                    <input type="file" accept="image/png, image/jpeg, image/jpg" id="uploadAvatar" ref="fileUpload" @change="readURL">
                                    <br/>
                                    <sub> {{ $t('register2.max-pic') }} </sub>
                                </div>
                                <ImageCropper v-if="uploadedAvatarURL" @urlChanged="setCroppedAvatarURL" :src="uploadedAvatarURL" :type="uploadType" />
                            </div>
                            <button v-if="avatarURL" class="btn btn-outline-danger mt-2" style="width: 128px" @click.stop="removeAvatar" :disabled="avatarUploading">{{ $t('register2.delete') }}</button>
                        </div>
                        <!-- <datetime :valueZone="DateTime.local().zoneName" :max-datetime="new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString()" v-model="data.birth_time" required class="form-control" :style="{border: errors['birth_time'] ? '1px solid red' : '1px solid #ced4da'}" /> -->
                        <div class="col-12 mt-2">
                            <label>{{ $t('register2.lawyer-credit') }}</label>
                            <select v-model="data.wants_credits" class="form-control" :style="{border: errors['wants_credits'] ? '1px solid red' : '1px solid #ced4da'}">
                                <option :value="false">{{ $t('common.no') }}</option>
                                <option :value="true">{{ $t('common.yes') }}</option>
                            </select>
                        </div>
                        <div class="col-12 p-0" v-if="data.wants_credits">
                            <div class="col-12 mt-2">
                                <label>{{ $t('register2.lawyer-name') }} <span style="color: red">*</span></label>
                                <div v-if="errors['bar_association_name']">
                                    <span v-for="(error, idx) in errors['bar_association_name']" style="color: red;" :key="idx">{{error}}</span>
                                </div> 
                                <input type="text" class="form-control"  :placeholder="$t('settings.lawyer-name')" v-model="data.bar_association_name" required :style="{border: errors['bar_association_name'] ? '1px solid red' : '1px solid #ced4da'}">
                            </div>
                            <div class="col-12 mt-2">
                                <label>{{ $t('register2.lawyer-id') }} <span style="color: red">*</span></label>
                                <div v-if="errors['bar_association_number']">
                                    <span v-for="(error, idx) in errors['bar_association_number']" style="color: red;" :key="idx">{{error}}</span>
                                </div>
                                <div>
                                    <span v-if="regexErrors['bar_association_number']" style="color: red;">{{regexErrors['bar_association_number']}}</span>
                                </div>
                                <input type="text" v-debounce:400ms="validateBarAssociationNumber" :placeholder="$t('settings.lawyer-id-placeholder')"  class="form-control" v-model="data.bar_association_number" required :style="{border: errors['bar_association_number'] || regexErrors['bar_association_name'] ? '1px solid red' : '1px solid #ced4da'}">
                            </div>
                            <div class="col-12 mt-2">
                            <label>{{ $t('register2.lawyer-location') }} <span style="color: red">*</span></label>
                                <div v-if="errors['bar_association_id']">
                                    <span v-for="(error, idx) in errors['bar_association_id']" style="color: red;" :key="idx">{{error}}</span>
                                </div> 
                                <select class="form-control" v-model="data.bar_association_id" :style="{border: errors['bar_association_id'] ? '1px solid red' : '1px solid #ced4da'}">
                                    <option v-for="barAssociation in barAssociations" :value="barAssociation.id" :key="barAssociation.id">{{barAssociation.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 mt-4">
                            <div><strong>{{ $t('register2.attention') }}:</strong> {{ $t('register2.attention-2') }}</div>
                        </div>
                        <div class="col-12 mt-1">
                            <button type="submit" :disabled="loading || !Object.values(regexErrors).every(o => o === null)" class="btn btn-save">{{ $t('register2.save') }}</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import {DateTime} from 'luxon'
import ImageCropper from '@/components/ImageCropper'
import Avatar from '@/components/Avatar'
import Loading from '@/components/Loading'
import Datetime from '../components/DateTimePicker/Datetime.vue'

export default {
    name: 'Register2',
    components: {
        ImageCropper,
        Avatar,
        Loading,
        Datetime
    },
    props: {
        course: String
    },
    mounted() {
        this.axios.get('/bar-associations')
            .then(resp => this.barAssociations = this.barAssociations.concat(resp.data))
        
        this.axios.get('/qualifications')
            .then(resp => this.qualifications = this.qualifications.concat(resp.data))
    },
    data() {
        return {
            avatarURL: this.$store.state.user && this.$store.state.user.avatar ? this.$store.state.storageURL+this.$store.state.user.avatar.path+'?token='+this.$store.state.token : null,
            croppedAvatarURL: null,
            uploadedAvatarURL: null,
            uploadType: null,
            avatarUploading: false,
            barAssociations: [],
            nationalities: [],
            qualifications: [],
            DateTime: DateTime,
            data: this.$store.state.user && this.$store.state.user.bar_association_id ? {
                bar_association_id: this.$store.state.user.bar_association_id,
                bar_association_number: this.$store.state.user.bar_association_number,
                bar_association_name: this.$store.state.user.bar_association_name,
                wants_credits: Boolean(this.$store.state.user.wants_credits),
            } : {
                bar_association_id: 1,
                bar_association_number: null,
                bar_association_name: null,
                wants_credits: true,
            },
            loading: false,
            errors: Object,
            regexErrors: {
                bar_association_number: null,
                tax_id: null,
                taj_id: null,
                educational_id: null
            },
            
        }
    },
    computed: {
        shouldShow() {
            return this.wants_credits ? $(this.wants_credits).val() : false
        },
        courseComputed() {
            return this.course ? JSON.parse(this.course) : null
        }
    },
    methods: {
        validateBarAssociationNumber() {
            if(/^[0-9]{8}$/.test(String(this.data.bar_association_number))){
                this.regexErrors['bar_association_number'] = null
            }
            else {
                this.regexErrors['bar_association_number'] = 'Nem megfelelő formátum'
            }
        },
        validateTaxId() {
            if(/^[0-9]{10}$/.test(this.data.tax_id)){
                this.regexErrors['tax_id'] = null
            }
            else {
                this.regexErrors['tax_id'] = 'Nem megfelelő formátum'
            }
        },
        validateTajId() {
            if(/^[0-9]{9}$/.test(String(this.data.taj_id))){
                this.regexErrors['taj_id'] = null
            }
            else {
                this.regexErrors['taj_id'] = 'Nem megfelelő formátum'
            }
        },
        readURL(input) {
            input = input.target
            if (input.files && input.files[0]) {
                this.uploadType = input.files[0].type
                var reader = new FileReader()
                reader.onload = (e) => {
                    this.uploadedAvatarURL = e.target.result
                }
                
                reader.readAsDataURL(input.files[0]) // convert to base64 string
            }
        },
        createUpload(type) {
            if(event.target.files.item(0))
                this.files.push({file: event.target.files.item(0), type: type, shown: true, uuid: null, id: this.files.length})
        },
        finishUpload(id, multimedia) {
            this.files.find(file => file.id === id).uuid = multimedia.id
        },
        cancelUpload(id, uuid) {
            this.files.splice(this.files.indexOf(this.files.find(file => file.id === id)), 1)
        },
        dataURLtoFile(dataurl, filename) {
 
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), 
                n = bstr.length, 
                u8arr = new Uint8Array(n);
                
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            
            return new File([u8arr], 'avatar', {type:mime});
        },
        setCroppedAvatarURL(url) {
            this.avatarUploading = true
            let file = this.dataURLtoFile(url)
            let formData = new FormData;
            formData.set('image', file, file.name);
            let config = {
                    method: 'POST',
                    data: formData,
                    url: `/upload/image`,
                    baseURL: process.env.VUE_APP_STORAGE_URL,
                    headers: {
                        'Content-Type': 'application/octet-stream'
                    },
            }
            this.axios(config).then(resp => {
                config.url += '/' + resp.data.name
                config.url += '/finish';
                config.data = null;
                this.axios(config)
                .then(resp => {
                    const multimedia = resp.data.multimedia;
                    this.axios.post('set-avatar', {avatar_id: resp.data.multimedia.id})
                    .then(() => {
                        this.$store.state.user.avatar = multimedia
                        this.avatarURL = this.$store.state.storageURL + multimedia.path + '?token=' + this.$store.state.token
                    })
                    .finally(() => this.avatarUploading = false)
                })
                .catch(() => {
                    this.avatarUploading = false
                })
            }).catch((e) => this.avatarUploading = false)
            //    baseURL: process.env.VUE_APP_STORAGE_URL
           // })
        },
        removeAvatar() {
            this.avatarUploading = true
            this.avatarURL = null
            this.$store.state.user.avatar = null
            this.axios.post('/set-avatar', {avatar_id: null})
            .finally(this.avatarUploading = false)
        },
        removeEmptyOrNull(obj) {
            Object.keys(obj).forEach(k => {
                if(obj[k] === null)
                    delete obj[k]
            })
            return obj;
        },
        submit() {
            this.loading = true
            if(!this.data.wants_credits) {
                this.data.bar_association_id = null
                this.data.bar_association_name = null
                this.data.bar_association_number = null
            }
            this.axios.post('/finish-registration', this.removeEmptyOrNull(this.data))
                .then(resp => {
                    this.$store.state.user = resp.data.user
                    if(!this.courseComputed)
                        this.$router.push({name: 'Home'})
                    else
                        this.$router.push({name: 'ViewCourse', params: {id: this.courseComputed.id}})
                })
                .catch(e => this.errors = e.response.data.errors)
                .finally(() => this.loading = false)
        }
    }
}
</script>
