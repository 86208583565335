<template>
<div>
  <!-- BREADCRUMB -->
  <div class="margin-sticky-fix"></div>
  <div class="breadcrumb bg-dark-gray">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="flex-wrap d-flex">
            <div class="d-flex mr-4 pointer">
              <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-users.homepage') }}</span>
            </div>
            <div class="d-flex mr-4">
              <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
            </div>
            <div class="d-flex mr-4 pointer">
              <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Templates'})">{{ $t('admin-menu.templates') }}</span>
            </div>
            <div class="d-flex mr-4">
              <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
            </div>
            <div class="d-flex">
              <span class="text-16 text-white pt-3 pb-3">{{ $t('templates.edit-template') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- //BREADCRUMB -->
  <div id="content-2">
    <div class="container">
      <div class="row mt-5">
        <div class="col-12">
          <h2 class="text-18 text-dark-gray semi-bold mb-3"> {{ template?.name }}</h2>
          <div class="col-12 d-flex align-items-center mt-1 mb-3">
            <i class="fas fa-info-circle mr-1 text-info font-15" style="font-size: 15px;"></i>
            <span v-if="template.name==='confirm_email'" class="font-12" style="font-size: 12px">"confirm_email" should contain <code v-pre>{{code}}</code> and <code v-pre>{{link}}</code> keywords</span>
            <span v-else-if="template.name==='reset_password'" class="font-12" style="font-size: 12px">"confirm_email" should contain <code v-pre>{{token}}</code> and <code v-pre>{{link}}</code> keywords</span>
            <span v-else-if="template.name==='course_bought'" class="font-12" style="font-size: 12px">"confirm_email" should contain <code v-pre>{{title}}</code> and <code v-pre>{{link}}</code> keywords</span>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center mb-2">
                <img src="assets/img/lang/en.png" style="height: 20px;">
                <label class="mb-0 ml-1 text-title-gray weight-600 text-16">{{ $t('templates.content') }}</label>
              </div>
              <textarea ref="content-en" />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center mb-2">
                <img src="assets/img/lang/hu.png" style="height: 20px;">
                <label class="mb-0 ml-1 text-title-gray weight-600 text-16">{{ $t('custom-pages.content') }}</label>
              </div>
              <textarea ref="content-hu" />
            </div>
          </div>
          <div class="col-12 mt-3">
            <button class="btn btn-secondary" @click.prevent="submit" :disabled="submitting">{{$t('common.save-changes')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="loading" class="row">
    <div class="col-12">
      <Loading height="20vh" />
    </div>
  </div>
</div>
</template>

<script>
import Loading from '../components/Loading.vue';
export default {
  name: "EditTemplate",
  components: {Loading},
  mounted() {
    this.axios.get('/templates/' + this.$route.params.id)
        .then(resp => {
          this.template.id = resp.data.template.id;
          this.template.name = resp.data.template.name;
          this.template.content.en = JSON.parse(resp.data.template.content).en;
          this.template.content.hu = JSON.parse(resp.data.template.content).hu;
          this.onlyLink = this.template.content.hu == null && this.template.content.en == null
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = false
          this.$nextTick(() => {
            $(this.$refs['content-en']).summernote({
              height: 400,
              callbacks: {
                onChange: (contents, $editable) => {
                  this.template.content.en = contents
                }
              }
            })
            $(this.$refs['content-en']).summernote('code', this.template.content.en)

            $(this.$refs['content-hu']).summernote({
              height: 400,
              code: this.template.content.hu,
              callbacks: {
                onChange: (contents, $editable) => {
                  this.template.content.hu = contents
                }
              }
            })
            $(this.$refs['content-hu']).summernote('code', this.template.content.hu)
          })
        })
  },
  data() {
    return {
      submitting: false,
      loading: true,
      httpError: false,
      template: {
        id: this.$route.params.id,
        name: '',
        content: {
          en: null,
          hu: null
        }
      },
      }
    },
  methods:{
    submit() {
      if(this.submitting) return;
      this.submitting = true

      if(this.onlyLink) {
        this.page.content.hu = null
        this.page.content.en = null
      }
      this.axios.patch('/templates/' + this.template.id, this.template)
          .then(resp => {
            console.log(resp);
            //this.$router.push({name: 'Templates'})
          })
          .catch(e => console.log(e))
          .finally(() => this.submitting = false)
    }
  }
}
</script>

<style scoped>

</style>
