<template>
  <div class="main-part">
    <!-- <select
      v-model="selectedFiatCoupon"
      :disabled="loading"
      name="labels"
      id="labels"
    >
      <option v-bind:value="null">Please select</option>
      <option
        v-for="label in data"
        v-bind:value="label.discount"
        v-bind:key="label.discount"
        >{{ label.discount }}</option
      >
    </select> -->
    <canvas id="fiatCouponUsageChart"></canvas>
  </div>
</template>

<script>
export default {
  name: "FiatCouponUsageChart",
  props: {
    data: Object,
    type: {
      required: false,
      default: "horizontalBar",
    },
  },
  mounted() {
    //console.log("data", this.data);
    // eslint-disable-next-line no-undef
    Chart.defaults.scale.gridLines.display = false;
    // eslint-disable-next-line no-undef
    const ctx = document.getElementById("fiatCouponUsageChart");
    // eslint-disable-next-line no-undef
    this.chart = new Chart(ctx, {
      type: this.type,
      data: {
        labels: this.data.map((d) => d.discount),
        datasets: [
          {
            label: "Dataset 1",
            data: this.data.map((d) => d.count),
            backgroundColor: "rgb(23, 111, 183, 0.8)",
            borderColor: "rgb(23, 111, 183, 1)",
          },
        ],
      },
      options: {
        responsive: true,
        indexAxis: "y",
        scales: {
          y: {
            min: 0,
          },
        },
        legend: {
          display: false,
          labels: {
            fontColor: "rgb(255, 99, 132)",
          },
        },
      },
    });

    ctx.onclick = this.clickHandler;
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    // selectedFiatCoupon() {
    //   if (this.fiatCouponDetailsChart && this.selectedFiatCoupon) {
    //     this.getSelectedFiatCouponData();
    //   }
    // },
    // selectedInterval() {
    //   if (this.fiatCouponDetailsChart && this.selectedInterval) {
    //     this.getSelectedFiatCouponData();
    //   }
    // },
    data() {
      if (this.chart) {
        this.chart.data.labels = this.data.map((d) => d.discount);
        this.chart.data.datasets[0].data = this.data.map((d) => d.count);
        this.chart.update();
      }
    },
  },
  methods: {
    clickHandler(click) {
      const points = this.chart.getElementsAtEventForMode(
        click,
        "nearest",
        { intersect: true },
        true
      );

      if (points.length) {
        const firstPoint = points[0];
        const value = this.chart.data.labels[firstPoint._index];
        this.$emit("valueSelected", value);
        // this.getSelectedPercentageCouponData(value);
      }
    },
  },
};
</script>

<style scoped>

</style>
