<template>
  <div
      id="membership"
  >
    <div class="head">
      <div></div>
      <div class="mx-auto">
        <img src="@/assets/img/settings/membership.png" alt=""/>
      </div>
      <!--      <div class="delete-note-container d-flex align-items-center mx-auto">
              <Icon name="delete-red" :classes="['delete-gray']" />
              <span class="delete-note">{{ $t("settings.delete-renewal") }}</span>
            </div>-->
    </div>
    <table>
      <tr class="table-head">
        <th>{{ $t("settings.membership") }}</th>
        <th>{{ $t("settings.membership_expiration_date") }}</th>
        <th></th>
      </tr>
      <tr v-if="$store.state.user.latestMembership" class="memberships-data">
        <td>{{ $store.state.user.latestMembership?.membership?.title }}</td>
        <td>
          {{
            $store.getters.parseDateTime(
                $store.state.user.latestMembership?.expires_at
            )
          }}
        </td>
        <td>
          <button v-show="$store.state.user.latestMembership?.order?.id" @click="()=>$router.push({ name: 'Order', params:{order:$store.state.user.latestMembership.order.id} })">
            <img src="@/assets/img/icons/save.svg" alt=""/>
            <span>{{ $t("manage-orders.invoice") }}</span>
          </button>
        </td>
      </tr>
    </table>
    <div
        class="d-flex justify-content-center flex-column align-items-center"
        :class="[
      { active: $store.state.user?.hasActiveMembership },
      { inactive: !$store.state.user?.hasActiveMembership },
    ]"
        style="flex: 1"
        id="membership"
    >
      <button
          :disabled="$store.state.user?.hasActiveMembership"
          @click="renewMembership"
          class="red-button"
      >
        <span>{{ $t("settings.CTRYM") }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import Icon from "../Icon";

export default {
  components: {
    Icon,
  },
  data() {
    return {
      submitting: false,
    };
  },
  methods: {
    renewMembership() {
      if (this.submitting) return;
      if (
          this.$store.state.user.cart_items.some(
              (c) => c.cartable_type === "App\\Models\\Membership"
          )
      ) {
        this.$router.push({name: "Cart"});
        return;
      }
      this.submitting = true;
      this.axios
          .post(`/memberships/1/cart`)
          .then((resp) => {
            const cartItem = resp.data.cart_item;
            this.$store.state.user.cart_items.push(cartItem);
          })
          .catch((e) => console.log(e))
          .finally(() => (this.submitting = false));
    },
  },
};
</script>

<style scoped>
#membership {
  padding: 40px 25px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 0;
  min-height: 700px;
}

#membership.inactive::after {
  content: "";
  background-image: url("~@/assets/img/settings/inactive-membership.png");
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 1;
}

#membership.active::after {
  content: "";
  background-image: url("~@/assets/img/settings/active-membership.png");
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 1;
}

#membership .invoices-link {
  cursor: pointer;
  z-index: 5;
}

#membership .invoices-link:hover {
  border-bottom: 1px solid #333e48;
}

h3.status {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}

h2.status-note-inactive {
  font-size: 19px;
  font-weight: 700;
  color: #d62b33;
  border: 1px solid #d62b33;
  padding: 10px 8px;
}

h2.status-note-active {
  font-size: 19px;
  font-weight: 700;
  color: #029e12;
  border: 1px solid #029e12;
  padding: 10px 8px;
}

.expire-date-label {
  font-weight: 500;
  font-size: 20px;
  color: #768692;
}

.expire-date {
  font-size: 16px;
  font-weight: 500;
  color: #768692;
}

.invoices {
  font-size: 12px;
  font-weight: 600;
  color: #333e48;
  margin-bottom: 0;
  margin-right: 5px;
}

/* START BUTTONS */

button.red-button {
  background-color: #d62b33;
  border: 3px solid #d62b33;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 31px;
  z-index: 5;
}

button.red-button:disabled {
  background-color: #cecece;
  border: 3px solid rgba(214, 43, 51, 0);
}

button.red-button span {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

button.red-button:not(:disabled):hover {
  background-color: var(--dark-red);
  border: 3px solid var(--dark-red);
}

.delete-note-container {
  cursor: pointer;
  border: solid 2px #9f999a;
  height: fit-content;
  margin: auto;
  border-radius: 5px;
  padding: 5px 15px;
  z-index: 5;
}

.delete-note-container .delete-note {
  color: #9f999a;
  font-weight: 600;
}

.delete-note-container:hover {
  border-color: #d62b33;
}

::v-deep .delete-note-container:hover svg path {
  fill: #d62b33;
}

.head {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.head img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  z-index: 5;
}

.table-head th {
  font-size: 18px;
  font-weight: 500;
  border-top: solid 1px black;
  border-bottom: solid 1px #333e48;
  background-color: #cecece;
  color: #333e48;
}

.table-head th:first-child {
  border-radius: 5px 0 0 0;
  border-left: solid 1px #333e48;
}

.table-head th:last-of-type {
  border-radius: 0 5px 0 0;
  border-right: solid 1px #333e48;
}

table tr th,
table tr td {
  padding: 20px;
}

.memberships-data td {
  background-color: white;
  color: #333e48;
  font-size: 18px;
  font-weight: 500;
  border-top: solid 1px #cecece;
  border-bottom: solid 1px #cecece;
}

.memberships-data td:first-child {
  border-left: solid 1px #cecece;
}

.memberships-data td:nth-child(2) {
  color: #768692;
}

.memberships-data td:last-of-type {
  border-right: solid 1px #cecece;
}

.memberships-data:last-child td:first-child {
  border-bottom-left-radius: 5px;
}

.memberships-data:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

.memberships-data button {
  padding: 5px 20px;
  border-radius: 5px;
  background-color: #d62b33;
  color: #ffffff;
  font-weight: 600;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.memberships-data button img {
  height: 20px;
  width: 16px;
  margin-top: 4px;
}

.memberships-data button:focus {
  outline: none;
}
</style>

<style>
.delete-gray path {
  fill: #9f999a !important;
}
</style>
