<template>
    <canvas id="activityChart"></canvas>
</template>
<script>
export default {
    name: 'activityChart',
    props: {
        data: Object,
        type: {
            required: false,
            default: 'line'
        }
    },
    mounted() {
        //console.log(this.data)
        //Chart.defaults.scale.gridLines.display = false;
        const ctx = $('#activityChart')
        this.chart = new Chart(ctx, {
            type: this.type,
            data: {
                labels: this.data[0].map(d => d.x),
                datasets: [
                    {
                        data: this.data[0].map(d => d.y),
                        backgroundColor: 'rgb(23, 111, 183, 0.8)',
                        borderColor: 'rgb(23, 111, 183, 1)',
                        lineTension: 0, 
                        options: {
                            cubicInterpolationMode: 'monotone',
                            lineTension: 0, 
                        }
                    }
                ]
            },
            options: {
                legend: {
                    display: false,
                    labels: {
                        fontColor: 'rgb(255, 99, 132)'
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1
                        }
                    }]
                }
            }
        });
    },
    data() {
        return {
            chart: null
        }
    },
    watch: {
        data() {
            if(this.chart) {
                this.chart.data.labels = this.data[0].map(d => d.x)
                this.chart.data.datasets[0].data = this.data[0].map(d => d.y)
                this.chart.update()
            }
        }
    }
}
</script>