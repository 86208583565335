<template>
<div ref="pdfContainer" class="pl-2 pr-2 pt-1 pb-1 w-100" style="height: 70vh">
    <pdf-viewer v-if="pdfData" :pdfData="pdfData" :isBlob="false" :height="height" :width="width" />
</div>
</template>
<script>
import PdfViewer from '../PdfViewer.vue'
export default {
    emits: ['progressMade'],
    components: { PdfViewer },
    name: 'DocumentElement',
    props: {
        element: Object
    },
    mounted() {
        this.pdfData = this.$store.getters.getStorageURL(this.element.course_multimedia.multimedia.path);
        this.width = this.$refs.pdfContainer.clientWidth - 20
        this.height = this.$refs.pdfContainer.clientHeight - 10
        this.makeProgress(true)
    },
    data() {
        return {
            downloading: false,
            pdfData: null,
            height: 400,
            width: 200
        }
    },
    methods: {
        makeProgress(e) {
                this.$emit('progressMade', this.element.course_multimedia_id, e);
        }
    }
}
</script>
<style scoped>
a:disabled {
    background-color: grey !important;
    border-color: grey !important;
}
</style>
