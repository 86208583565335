<template>
    <div :class="containerClasses" :style="containerStyles">
        <div :class="videoContainerClasses" :style="videoContainerStyles" @click.prevent="$refs.videoUpload.click()">
            <slot name="video-btn"></slot>
        </div>
        <div :class="audioContainerClasses" :style="audioContainerStyles" @click.prevent="$refs.audioUpload.click()">
            <slot name="audio-btn"></slot>
        </div>
        <div :class="imageContainerClasses" :style="imageContainerStyles" @click.prevent="$refs.imageUpload.click()">
            <slot name="image-btn"></slot>
        </div>
        <div :class="documentContainerClasses" :style="documentContainerStyles" @click.prevent="$refs.documentUpload.click()">
            <slot name="document-btn"></slot>
        </div>
        <input type="file" accept="video/mp4, video/webm, video/x-msvideo, video/quicktime" ref="videoUpload" @change="createUpload('video')" style="display: none;">
        <input type="file" accept="image/png, image/jpeg, image/jpg, image/gif" ref="imageUpload" @change="createUpload('image')" style="display: none;">
        <input type="file" accept="audio/mp3, audio/mpeg, audio/wav" ref="audioUpload" @change="createUpload('audio')" style="display: none;">
        <input type="file" accept=".doc, .docx, .pdf, .xls, .xlsx, .ods, .ppt, .pptx, .txt" ref="documentUpload" @change="createUpload('document')" style="display: none;">
        <div v-for="(file, index) in files" :key="file.id" :class="uploadContainerClasses" :style="uploadContainerStyles">
            <Upload v-if="file.shown" :file="file.file" :type="file.type" :id="file.id" @uploadFinished="finishUpload" @uploadCancelled="cancelUpload"/>
        </div>
    </div>
</template>
<script>
import Upload from '@/components/Upload.vue'
export default {
    name: 'GenericFileUploader',
    emits: ['canSend', 'uploadFinished', 'uploadCancelled'],
    components: {
        Upload
    },
    props: {
        containerStyles: {
            optional: true,
            default: '',
        },
        containerClasses: {
            optional: true,
            default: '',
        },
        uploadContainerClasses: {
            optional: true,
            default: "col-12 mb-3"
        },
        uploadContainerStyles: {
            optional: true,
            default: ''
        },
        videoContainerClasses: {
            optional: true,
            default: ''
        },
        videoContainerStyles: {
            optional: true,
            default: ''
        },
        audioContainerClasses: {
            optional: true,
            default: ''
        },
        audioContainerStyles: {
            optional: true,
            default: ''
        },
        imageContainerClasses: {
            optional: true,
            default: ''
        },
        imageContainerStyles: {
            optional: true,
            default: ''
        },
        documentContainerClasses: {
            optional: true,
            default: ''
        },
        documentContainerStyles: {
            optional: true,
            default: ''
        },
        hideBarAfterUpload: {
            optional: true,
            default: false
        }

    },
    data() {
        return {
            files: []
        }
    },
    watch: {
        files() {
            const canSend = !this.files.find(file => file.uuid === null)
            this.$emit('canSend', canSend)
            return this.files;
        },
    },
    methods: {
        createUpload(type) {
            if(event.target.files.item(0))
                this.files.push({file: event.target.files.item(0), type: type, shown: true, uuid: null, id: this.files.length})
        },
        finishUpload(id, multimedia) {
            let file = this.files.find(file => file.id === id)
            file.uuid = multimedia.id
            if(this.hideBarAfterUpload) {
                file.shown = false
            }
            this.$emit('uploadFinished', file)
        },
        cancelUpload(id, uuid) {
            let file = this.files.find(file => file.id === id)
            this.files.splice(this.files.indexOf(file), 1)
            if(file.uuid != null) {
                this.$emit('uploadCancelled', file)
            }
        },
    }
}
</script>
