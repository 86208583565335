<template>
    <div class="row">
        <div class="col-12">
            <h2 class="mb-3 font-135" style="font-weight: 600 !important;"> {{ $t('course-feed.timeline') }} </h2>
        </div>

        <div class="col-12">
            <div class="row line" style="display: flex;margin-left: 0px !important;margin-right: 0px !important;padding-left: 0px !important;padding-right: 0px !important;">
                <img v-if="$store.state.user.avatar" :src="$store.state.user.avatar.path+'?token='+$store.state.token" class="img-fluid modal-profile" alt="">
                <Avatar v-else :username="$store.state.user.name" :size="55" />
                <div class="text-center" style="display: flex; align-items: center; flex-grow: 1; border-radius: 10px !important; background-color: #f1f1f1; cursor: pointer;margin-left: -25px;z-index: 1;" data-toggle="modal" data-target="#newPostModal">
                    <span class="w-100" style="color: grey">{{ $t('course-feed.new-post') }}</span>
                </div>
            </div>
        </div>


            <!-- Modal -->
            <div class="modal fade" id="newPostModal" tabindex="-1" role="dialog" aria-labelledby="newPostModalLabel" ref="newPostModal"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header  text-center">
                            <h5 class="post-title w-100" id="newPostModalLabel">{{ $t('course-feed.new-entry') }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body w-100">
                            <div class="row">
                                <div class="col-sm-2 col-3">
                                    <img v-if="$store.state.user.avatar" :src="$store.state.user.avatar.path+'?token='+$store.state.token" class="img-fluid modal-profile" alt="">
                                    <Avatar v-else :username="$store.state.user.name" />
                                </div>
                                <div class="col-sm-10 col-9 modal-nop">
                                    <h1 class="modal-nick">{{$store.state.user.name}}</h1>
                                </div>
                                <div class="col-12 modal-message">
                                    <div class="form-group">
                                        <textarea placeholder="Ide írja a poszt tartalmát" class="form-control"
                                            id="newPostModalTextarea"
                                            style="width: 100%"
                                            v-model="content"
                                            :disabled="sending"></textarea>
                                            <div class="text-right"><sub>{{content ? content.length : 0}} / 2000</sub></div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button type="button" :disabled="sending" class="btn btn-image-upload w-100 mb-2" @click="$refs.videoUpload.click()">
                                        <input type="file" accept="video/mp4, video/webm, video/avi" id="uploadVideo" ref="videoUpload" @change="createUpload('video')" style="display: none;">
                                        {{ $t('course-feed.attach-vid') }}
                                    </button>
                                    <button type="button" :disabled="sending" class="btn btn-image-upload w-100 mb-2" @click="$refs.imageUpload.click()">
                                        <input type="file" accept="image/png, image/jpeg, image/jpg, image/gif" id="uploadImage" ref="imageUpload" @change="createUpload('image')" style="display: none;">
                                        {{ $t('course-feed.attach-pic') }}
                                    </button>
                                    <button type="button" :disabled="sending" class="btn btn-image-upload w-100 mb-2" @click="$refs.audioUpload.click()">
                                        <input type="file" accept="audio/mp3, audio/mpeg, audio/wav" id="uploadAudio" ref="audioUpload" @change="createUpload('audio')" style="display: none;">
                                        {{ $t('course-feed.attach-sound') }}
                                    </button>
                                    <button type="button" :disabled="sending" class="btn btn-image-upload w-100" @click="$refs.documentUpload.click()">
                                        <input type="file" accept=".doc, .docx, .pdf, .xls, .xlsx, .ods, .ppt, .pptx, .txt" id="uploadDocument" ref="documentUpload" @change="createUpload('document')" style="display: none;">
                                        {{ $t('course-feed.attach-doc') }}
                                    </button>
                                </div>
                                <div v-if="files.length > 0" class="col-12">
                                    <h5 class="mb-2 mt-4">{{ $t('course-feed.uploads') }}</h5>
                                    <div class="row">
                                        <div v-for="(file, index) in files" :key="file.id" class="col-12 mb-3">
                                            <Upload v-if="file.shown" :file="file.file" :type="file.type" :id="file.id" @uploadFinished="finishUpload" @uploadCancelled="cancelUpload"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" :disabled="!canSend" @click="submit" class="btn btn-share w-100">{{ $t('course-feed.share') }}</button>
                        </div>
                    </div>
                </div>
            </div>

        <div v-if="posts.length > 0">
            <div v-for="post in posts" :key="post.id" class="col-12 m-top">
                <div v-if="!post.deleted">
                    <Post @postDeleted="post.deleted = true" :postProp="post" />
                </div>
            </div>
        </div>
        <div v-else-if="loading" class="col-12">
            <Loading height="50vh" />
        </div>
        <div v-else class="col-12 mt-4 ml-4">
            <h2> {{ $t('course-feed.no-posts') }} </h2>
        </div>
    </div>
</template>
<script>
import Post from '@/components/Post'
import Loading from '@/components/Loading'
import Avatar from '@/components/Avatar'
import Upload from '@/components/Upload'
export default {
    name: 'CourseFeed',
    components: {
        Post,
        Loading,
        Avatar,
        Upload
    },
    props: {
        course: null
    },
    mounted() {
        this.axios.get(`/courses/${this.course.id}/posts`).then(resp => {
            this.loading = false
            this.posts = resp.data.posts
        })
    },
     data() {
        return {
            posts: [],
            files: [],
            content: null,
            loading: true,
            sending: false,
        }
    },
    watch: {
        files() {
            return this.files
        }
    },
    computed: {
        canSend() {
            return !this.files.find(file => file.uuid === null) && !this.sending
        }
    },
    methods: {
        createUpload(type) {
            if(event.target.files.item(0))
                this.files.push({file: event.target.files.item(0), type: type, shown: true, uuid: null, id: this.files.length})
        },
        finishUpload(id, multimedia) {
            this.files.find(file => file.id === id).uuid = multimedia.id
        },
        cancelUpload(id, uuid) {
            this.files.splice(this.files.indexOf(this.files.find(file => file.id === id)), 1)
        },
        submit() {
            if(!this.sending) {
                let formData = {course_id: this.course.id}
                const multimedia = this.files.filter(file => file.uuid !== null).map(file => file.uuid)
                if(this.content)
                    formData.content = this.content
                if(multimedia.length > 0)
                    formData.multimedia = multimedia

                this.sending = true
                this.axios.post(`/posts`, formData)
                    .then(resp => {
                        this.files = []
                        this.content = null
                        this.posts.unshift(resp.data.post)
                        $(this.$refs.newPostModal).modal('hide')
                    })
                    .catch(e => console.log(e.response))
                    .finally(() => this.sending = false)
            }
        }
    }
}
</script>
<style scoped>
    /* MOBILE FONT SIZE UNDER 426px */
    @media (min-width: 426px) {
        .font-135 {
            font-size: 1.35rem !important;
        }
    }

    @media (max-width: 425px) {
        .font-135 {
            font-size: 1rem !important;
        }
    }
</style>
