<template>
  <div>
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('course-catalog.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
              </div>
              <div class="d-flex pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'CourseCatalog'})">{{$t('course-catalog.course-list') }}</span>
              </div>
              <div v-if="courseGroup && courseGroup.parent_id" class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
              </div>
              <div v-if="courseGroup && courseGroup.parent_id" class="d-flex pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'CourseCatalogGrouped', params: {group: courseGroup.parent_id}})">{{ courseGroup.parent.title }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
              </div>
              <div class="d-flex">
                <span
                    class="text-16 text-white pt-3 pb-3">{{ courseGroup ? courseGroup.title : 'Képzés csoport' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section id="content-2">
      <div class="row">
        <div class="container">
          <div class="col-lg-12">
            <section id="trainings">
              <div class="row" v-if="!loading && courseGroup">
                <div class="col-12 row">
                  <!--                                <div class="col-12">
                                                      <h2 class="text-18 text-dark-gray semi-bold mb-3">{{$t('common.course-groups')}}</h2>
                                                  </div>-->
                  <div
                      v-for="Group in courseGroup.children_not_hidden"
                      :key="Group.id"
                      class="col-lg-3 col-md-4 col-sm-6 col-12"
                      style="cursor: pointer;"
                      @click="$router.push({name: 'CourseCatalogGrouped', params: {group: Group.id}})"
                  >
                    <course-group-card :courseGroup="Group"/>
                  </div>
                </div>

                <!--                            <div class="col-12">
                                                <h2 class="text-22 text-dark-gray semi-bold mb-3">{{$t('course-catalog.catalog-content', {group: courseGroup.title})}}</h2>
                                            </div>-->
                <div
                    v-for="course in courses"
                    :key="course.id"
                    class="col-lg-3 col-md-4 col-sm-6 col-12"
                    style="cursor: pointer;"
                    @click="$router.push({name: 'Course', params: {id: course.id}})"
                >
                  <course-card :course="course"/>
                </div>
                <div v-if="courseGroup.children.length === 0 && courses.length === 0" class="col-12 mt-2 text-left">
                  <h6 class="text-16 text-dark-gray semi-bold">The group does not include course or sub-group.</h6>
                </div>
              </div>
              <Loading v-else/>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import CourseCard from '@/components/CourseCard'
import CourseGroupCard from '../components/CourseGroupCard.vue'

export default {
  name: 'CourseCatalog',
  components: {
    Loading,
    CourseCard,
    CourseGroupCard
  },
  mounted() {
    this.axios.get(`/course-groups/${this.$route.params.group}/courses`).then(resp => {
      // this.courseGroup = resp.data.course_group
      this.axios.get(`/course-groups/${this.$route.params.group}`).then(resp => {
        console.log(resp.data);
        this.courseGroup = resp.data.course_group
      });
      this.courses = resp.data.course_group.courses;
    }).finally(() => this.loading = false)
  },
  data() {
    return {
      loading: true,
      courseGroup: null,
      courses: []
    }
  }
}
</script>
<style scoped>
.card-title {
  margin-bottom: 0px !important;
  padding-top: 20px !important;
  text-align: center !important;
}

.card-body {
  background: none !important;
}

.dark-bg-line {
  background-color: var(--red-90);
}
</style>
