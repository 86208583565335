<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('test-assembler.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('test-assembler.admin') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('test-assembler.test-creator') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->
        <div class="content-2">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div id="text-assembler" class="custom-input">
                            <form @submit.prevent="submit">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="name">{{ $t('test-assembler.name') }}</label>
                                                    <input type="text" v-model="test.name" class="form-control" id="name" required>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="period">{{ $t('test-assembler.duration-min') }}</label>
                                                    <input type="number" v-model="test.length" min="0" class="form-control" id="period" required>
                                                </div>
                                            </div>
                                            <div class="col-12 mt-3">
                                                <div class="form-group">
                                                    <label for="name">{{ $t('test-assembler.size') }}</label><br>
                                                    <sub><img src="assets/img/icons/info-circle.svg" class="mb-1" alt="">  {{ $t('test-assembler.text1') }}</sub>
                                                    <input type="number" class="form-control" id="name" disabled placeholder="0">
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="try-number">{{ $t('test-assembler.max-try') }}</label>
                                                    <input type="number" v-model="test.max_attempts" min="1" class="form-control" id="try-number" required>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="min-points">{{ $t('test-assembler.min-percent') }}</label>
                                                    <input type="number" min="0" v-model="test.min_pass_percentage" class="form-control" id="min-points" required>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="start">{{ $t('test-assembler.begin') }}</label>
                                                    <datetime type="datetime" :max-datetime="test.end_date" v-model="test.start_date" input-class="form-control" />
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="end">{{ $t('test-assembler.end') }}</label>
                                                    <datetime type="datetime" :min-datetime="test.start_date" v-model="test.end_date" input-class="form-control" />
                                                </div>
                                            </div>
                                            <div class="col-12 mt-4">
                                                <label class="c-inp"> <span>{{ $t('test-assembler.shuffle-q') }}</span>
                                                    <input type="checkbox" v-model="test.shuffle_questions" checked>
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="col-12">
                                                <label class="c-inp"> <span>{{ $t('test-assembler.shuffle-a') }}</span>
                                                    <input type="checkbox"  v-model="test.shuffle_answers" checked>
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="col-12">
                                                <label class="c-inp"> <span>{{ $t('test-assembler.fillable') }}</span>
                                                    <input type="checkbox"  v-model="test.fillable" checked>
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                          <div class="col-12">
                                                <label class="c-inp"> <span>{{ $t('test-assembler.add-attempt-package') }}</span>
                                                    <input type="checkbox"  v-model="test.attempt_package" checked>
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                          <div v-if="test.attempt_package" class="col-12">
                                            <div class="row">
                                              <div class="col-6">
                                                <div class="form-group">
                                                  <label for="ap-attempt">{{ $t('test-assembler.ap-attempt') }}</label>
                                                  <input type="number" min="1" v-model="test.ap_attempt" class="form-control" id="ap-attempt" required>
                                                </div>
                                              </div>
                                              <div class="col-6">
                                                <div class="form-group">
                                                  <label for="ap-price">{{ $t('test-assembler.ap-price') }}</label>
                                                  <input type="number" min="0" v-model="test.ap_price" class="form-control" id="ap-price" required>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                            <div class="col-12 mt-3">
                                                <div class="form-group">
                                                    <label class="d-flex">
                                                        <div class="mr-2">{{ $t('pointing-formula.pointing-formula') }}</div>
                                                        <div id="popover" data-toggle="popover" data-trigger="click hover" :title="$t('pointing-formula.pointing-formula')" :data-html="true" :data-content="`<strong>${$t('pointing-formula.All or nothing')}:</strong><br>${$t('pointing-formula.all-or-nothing-info')}<strong><br>${$t('pointing-formula.Correct ratio')}:</strong><br>${$t('pointing-formula.correct-ratio-info')}`">
                                                            <i class="fas fa-question-circle text-primary" />
                                                        </div>
                                                    </label>
                                                    <select class="form-control" v-model="test.test_point_formula" required>
                                                        <option :value="null" disabled>{{$t('course-materials.choose-from-list')}}</option>
                                                        <option v-for="formula in pointingFormulas" :key="formula.name" :value="formula.name">{{$t('pointing-formula.'+formula.name)}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <button type="submit" :disabled="loading" class="btn btn-theme">{{ $t('test-assembler.go') }} <img src="assets/img/icons/right-arrow.svg" class="img-fluid" alt=""></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Datetime from '@/components/DateTimePicker/Datetime'
export default {
    name: 'TestAssembler',
    components: {
        Datetime
    },
    created() {
        this.axios.get('/test-point-formulas')
            .then(resp => this.pointingFormulas = resp.data)
            .catch(e => console.log(e))
    },
    mounted() {
        $('#popover').popover()
    },
    data() {
        return {
            test: {
                name: null,
                hidden: 1,
                max_attempts: null,
                shuffle_questions: true,
                shuffle_answers: true,
                min_pass_percentage: null,
                length: null,
                start_date: null,
                end_date: null,
                test_point_formula: null,
                fillable: false,
                attempt_package: false,
                ap_attempt: 2,
                ap_price: 0,
            },
            pointingFormulas: [],
            loading: false
        }
    },
    methods: {
        submit() {
            this.loading = true
            this.axios.post('/tests', this.$store.getters.removeEmptyOrNull(this.test))
                .then(resp => {
                    this.$router.push({name: 'TestQuestionAssembler', params: {test: resp.data.test.id}})
                })
                .catch()
                .finally(() => this.loading = false)
        }
    }
}
</script>
<style scoped>
    #text-assembler {
        margin-top: 30px;
    }
        /* CUSTOM CHECKBOX */

    .c-inp {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .c-inp input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: transparent;
        border: 2px solid #333E48;
    }

    .c-inp input:checked ~ .checkmark {
        background-color: #333E48;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .c-inp input:checked ~ .checkmark:after {
        display: block;
    }

    .c-inp .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .c-inp span {
        white-space: normal;
        word-wrap: break-word;
    }
    .button-bottom {
        margin-top: 100px;
    }
    .btn-theme img {
        transition: 0s all;
        height: 14px;
        margin-left: 5px;
        filter: invert(100%) sepia(10%) saturate(7410%) hue-rotate(294deg) brightness(122%) contrast(104%);
    }
    .btn-theme {
        float: right;
        font-size: 15px;
    }

    @media (min-width: 992px) {
        .btn-theme {
            margin-top: 150px;
        }
    }

    @media (max-width: 991px) {
        .btn-theme {
            width: 100%;
            margin-top: 20px;
        }
    }
</style>
