<template>
    <!-- Modal edit question Answer-->
    <div class="modal fade" :id="'editQuestionAnswer'+answer.id+'Modal'" tabindex="-1" role="dialog" :aria-labelledby="'editQuestionAnswer'+answer.id+'ModalLabel'" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header question-modal-header" style="background-color: var(--red) !important;">
                <h5 class="modal-title" :id="'editQuestionAnswer'+answer.id+'ModalLabel'">{{ $t('test-question-assembler.edit-answer') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <form @submit.prevent="editQuestionAnswer(answer)">
                    <div class="modal-body nono">
                        <div class="row">
                            <div class="col-12" v-if="question.question_type != 'Subgrouped'">
                                <div class="col-12">
                                    {{$t('test-question-assembler.multimedia')}}
                                </div>
                                <generic-file-uploader
                                    v-if="!answer.edit.multimedia_id"
                                    @uploadFinished="attachMultimediaToAnswer(answer, $event)"
                                    containerClasses="col-12 row"
                                    videoContainerClasses="col-6 mb-1 p-0 pr-1"
                                    imageContainerClasses="col-6 mb-1 p-0 pr-1"
                                >
                                  <template v-slot:video-btn>
                                    <div class="card">
                                      <div class="card-body">
                                        <div class="d-flex" style="flex-direction: column; align-items: center; justify-content: center;">
                                          <i class="fas fa-file-video font-28 mb-1" />
                                          <span>{{$t('test-question-assembler.video')}}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                  <template v-slot:image-btn>
                                    <div class="card">
                                      <div class="card-body">
                                        <div class="d-flex" style="flex-direction: column; align-items: center; justify-content: center;">
                                          <i class="fas fa-file-image font-28 mb-1" />
                                          <span>{{$t('test-question-assembler.image')}}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                </generic-file-uploader>
                                <div class="col-12 mb-3" v-else>
                                    <div>
                                    <div v-if="answer.edit.multimedia.type == 'video' || answer.edit.multimedia.type == 'image' " style="color: var(--black); cursor:pointer; width: 100%; height: 100%"
                                        :data-fancybox="`newQuestion`"
                                        :href="answer.edit.multimedia.type != 'video' ? $store.getters.getStorageURL(answer.edit.multimedia.path) : `#question-${answer.edit.id}-${answer.edit.multimedia.id}`" target="_blank"
                                        :data-caption="answer.edit.multimedia.name"
                                    >
                                        <div v-if="answer.edit.multimedia.type == 'video'" style="max-width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: var(--lgray); border-radius: 5px; position: relative;">
                                            <video style="max-width: 100%; max-height: 100%; object-fit: cover; border-radius: 5px; pointer-events: none;" nocontrols>
                                                <source :src="$store.getters.getStorageURL(answer.edit.multimedia.path)">
                                            </video>
                                            <video :id="`question-${answer.edit.id}-${answer.edit.multimedia.id}`" style="display: none;" controls controlsList="nodownload">
                                                <source :src="$store.getters.getStorageURL(answer.edit.multimedia.path)">
                                            </video>
                                            <i class="far fa-play-circle playbtn"></i>
                                        </div>
                                        <div v-if="answer.edit.multimedia.type == 'image'" style="max-width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: var(--lgray); border-radius: 5px;">
                                            <img :src="$store.getters.getStorageURL(answer.edit.multimedia.path)" style="max-width: 100%; max-height: 100%; object-fit: cover; border-radius: 5px">
                                        </div>
                                    </div>
                                    <button class="w-100 btn btn-danger mt-2" @click.prevent="deleteAnswerMultimedia(answer)">{{$t('test-question-assembler.delete-multimedia')}}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="exampleFormControlTextarea1">{{ $t('test-question-assembler.answer') }}</label>
                                    <textarea v-model="answer.edit.answer" class="form-control"  rows="3" required></textarea>
                                </div>
                            </div>
                            <div class="col-12" v-if="question.question_type !== 'Subgrouped'">
                                <label class="container2">{{ $t('test-question-assembler.correct-answer') }}
                                    <input v-model="answer.edit.correct"
                                        type="checkbox"
                                        :disabled="question.question_type == 'Free text' || (!answer.correct && !(question.question_type == 'Multiple choice' || (question.question_type !== 'Free text' && question.test_question_answers.filter((qa) => qa.correct == true).length < 1)))"
                                        :checked="answer.correct"
                                        :true-value="1"
                                        :false-value="0"
                                    >
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" :disabled="answer.loading" class="btn btn-danger">{{ $t('test-question-assembler.edit') }}</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('test-question-assembler.cancel') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import GenericFileUploader from '../GenericFileUploader.vue'
export default {
    name: 'EditAnswerModal',
  components: {
    GenericFileUploader
  },
    props: {
        test: Object,
        answer: Object,
        question: Object
    },
    methods: {
        editQuestionAnswer(answer) {

            answer.loading = true
            this.axios.patch('/questions/'+answer.test_question_id+'/answers/'+answer.id, this.$store.getters.removeEmptyOrNull(answer.edit, ['multimedia_id']))
                .then(resp => {
                    resp.data.answer.edit = JSON.parse(JSON.stringify(resp.data.answer))
                    let question = this.test.test_questions.find((q) => q.id == answer.test_question_id)
                    question.test_question_answers[question.test_question_answers.findIndex((qa) => qa.id == answer.id)] = resp.data.answer
                    //question.newAnswer = {answer: null, correct: question.questionType == 'Free text'}
                    $('#editQuestionAnswer'+answer.id+'Modal').modal('hide')
                })
                .finally(() => answer.loading = false)
        },
        attachMultimediaToAnswer(answer, file) {
            const multimedia = {
                id: file.uuid,
                type: file.type,
                name: file.file.name,
                path: `/download/${file.type}/${file.uuid}`
            }
            answer.edit.multimedia = multimedia
            answer.edit.multimedia_id = multimedia.id
        },
        deleteAnswerMultimedia(answer) {
            answer.edit.multimedia_id = null
            answer.edit.multimedia = null
        },
    },
  mounted() {
      console.log("EditAnswerModal mounted", this.answer.edit.multimedia_id);
  }
}
</script>
