<template>
     <!-- Modal edit SubQuestion-->
    <div v-if="question.question_type == 'Subgrouped'" class="modal fade" :id="'editSubQuestionModal-'+subQuestion.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header question-modal-header" style="background-color: var(--red) !important;">
                <h5 class="modal-title" id="exampleModalLabel">{{ $t('test-question-assembler.edit-sub-question') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <form @submit.prevent="editSubQuestion(subQuestion)">
                    <div class="modal-body nono">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="exampleFormControlTextarea1">{{ $t('test-question-assembler.question') }}</label>
                                    <textarea required v-model="subQuestion.edit.question" maxlength="2000" class="form-control"  rows="3"></textarea>
                                </div>
                            </div>
                            <div class="col-12">
                                {{$t('test-question-assembler.attach-multimedia')}}
                            </div>
                            <generic-file-uploader 
                                v-if="!subQuestion.edit.multimedia_id"
                                @uploadFinished="attachMultimediaToSubQuestion(subQuestion, $event)"
                                containerClasses="col-12 row"
                                videoContainerClasses="col-6 mb-1 p-0 pr-1"
                                imageContainerClasses="col-6 mb-1 p-0 pr-1"
                            >
                                <template v-slot:video-btn>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex" style="flex-direction: column; align-items: center; justify-content: center;">
                                                <i class="fas fa-file-video font-28 mb-1" />
                                                <span>{{$t('test-question-assembler.video')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-slot:image-btn>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex" style="flex-direction: column; align-items: center; justify-content: center;">
                                                <i class="fas fa-file-image font-28 mb-1" />
                                                <span>{{$t('test-question-assembler.image')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </generic-file-uploader>
                            <div class="col-12 mb-3" v-else>
                                <div>
                                <div v-if="subQuestion.edit.multimedia.type == 'video' || subQuestion.edit.multimedia.type == 'image' " style="color: var(--black); cursor:pointer; width: 100%; height: 100%" 
                                    :data-fancybox="`newQuestion`" 
                                    :href="subQuestion.edit.multimedia.type != 'video' ? $store.getters.getStorageURL(subQuestion.edit.multimedia.path) : `#question-${subQuestion.edit.id}-${subQuestion.edit.multimedia.id}`" target="_blank"
                                    :data-caption="subQuestion.edit.multimedia.name"
                                >
                                    <div v-if="subQuestion.edit.multimedia.type == 'video'" style="max-width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: var(--lgray); border-radius: 5px; position: relative;">
                                        <video style="max-width: 100%; max-height: 100%; object-fit: cover; border-radius: 5px; pointer-events: none;" nocontrols>
                                            <source :src="$store.getters.getStorageURL(subQuestion.edit.multimedia.path)">
                                        </video>
                                        <video :id="`question-${subQuestion.edit.id}-${subQuestion.edit.multimedia.id}`" style="display: none;" controls controlsList="nodownload">
                                            <source :src="$store.getters.getStorageURL(subQuestion.edit.multimedia.path)">
                                        </video>
                                        <i class="far fa-play-circle playbtn"></i>
                                    </div>
                                    <div v-if="subQuestion.edit.multimedia.type == 'image'" style="max-width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: var(--lgray); border-radius: 5px;">
                                        <img :src="$store.getters.getStorageURL(subQuestion.edit.multimedia.path)" style="max-width: 100%; max-height: 100%; object-fit: cover; border-radius: 5px">
                                    </div>
                                </div>
                                <button class="w-100 btn btn-danger mt-2" @click.prevent="deleteSubQuestionMultimedia(subQuestion)">{{$t('test-question-assembler.delete-multimedia')}}</button>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="">{{$t('test-question-assembler.correct-answer')}}</label>
                                    <select class="form-control" v-model="subQuestion.edit.correct_answer_id" required>
                                        <option :value="null" disabled>{{$t('test-groups.choose')}}</option>
                                        <option v-for="answer in question.test_question_answers" :key="answer.id" :value="answer.id">{{answer.answer}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary w-100" :disabled="subQuestion.loading">{{ $t('test-question-assembler.save') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import GenericFileUploader from '../GenericFileUploader.vue'
export default {
    name: 'EditSubQuestionModal',
    components: {GenericFileUploader},
    props: {
        question: Object,
        subQuestion: Object
    },
    methods: {
        editSubQuestion(subQuestion) {
            subQuestion.loading = true
            this.axios.patch('/questions/'+subQuestion.test_question_id+'/sub-questions/'+subQuestion.id, this.$store.getters.removeEmptyOrNull(subQuestion.edit, ['multimedia_id']))
                .then(resp => {
                    resp.data.subQuestion.edit = JSON.parse(JSON.stringify(resp.data.subQuestion))
                    let question = this.test.test_questions.find((q) => q.id == subQuestion.test_question_id)
                    question.sub_questions[question.sub_questions.findIndex((sq) => sq.id == subQuestion.id)] = resp.data.subQuestion
                    $('#editSubQuestionModal-'+subQuestion.id).modal('hide')
                })
                .finally(() => subQuestion.loading = false)
        },
        attachMultimediaToSubQuestion(subQuestion, file) {
            const multimedia = {
                id: file.uuid,
                type: file.type,
                name: file.file.name,
                path: `/download/${file.type}/${file.uuid}`
            }
            subQuestion.edit.multimedia = multimedia
            subQuestion.edit.multimedia_id = multimedia.id
        },
        deleteSubQuestionMultimedia(subQuestion) {
            subQuestion.edit.multimedia_id = null
            subQuestion.edit.multimedia = null
        },
    }
}
</script>