<template>
  <div class="row h-100">
    <div class="col-xl-4 left-side-image h-100">
      <img class="img-fluid small-logo" src="assets/img/xbody-dark.png" alt="">
    </div>
    <div class="col-xl-8 h-100 right-side-content">
      <div class="container h-100">
        <div class="row h-100 justify-content-center align-items-center bg-dark-white">
            <form class="col-xl-5 col-lg-8 col-md-8 col-sm-8 col-10 text-center" @submit.prevent="submit">
                <h2 class="mb-3 mt-4">{{ $t('common.forgot-password-2') }}</h2>
                <p>You Will Be Redirected To XBody ID Reset Password page</p>

                <p v-if="Redirect" >if you were not Redirected automatically click  <a :href="xbodyIdUrl+'/forgot-password'" target='_blank'>here</a></p>
                <!--<div class="mb-3">
                     <div class="flex-wrap d-flex">
                          <div class="d-flex">
                            <label for="inputEmail" class="form-label">{{ $t('forgot-password.email') }}</label>
                          </div>
                          <div class="d-flex ml-auto">
                            <a href="#" @click.prevent="$router.push({name: 'ResetPassword'})">{{ $t('forgot-password.click-here') }}</a>
                          </div>
                      </div>
                    <input type="email"
                    id="inputEmail"
                    class="form-control"
                    :placeholder="$t('register.user-email')"
                    required
                    v-model="email"
                    autofocus />
                </div> -->
                <!-- <button type="submit" class="btn btn-normal w-100" :disabled="loading">{{ $t('forgot-password.send-email') }}</button> -->
                <h5 class="mt-4 text-13" @click.prevent="$router.push({name: 'Register'})">{{ $t('login.no-account') }} <a href="#">{{ $t('login.registry') }}</a></h5>
                <h5 class="mt-2 text-13" @click.prevent="$router.push({name: 'Login'})">{{ $t('login.have-account') }} <a href="#">{{ $t('register.login') }}</a></h5>
            </form>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgotPassword",
  props: {
      emailProp: String
  },
  data() {
    return {
      email: !this.emailProp || this.emailProp == 'null' || this.emailProp == 'undefined' ? null : this.emailProp,
      loading: false,
      Redirect: false,
      xbodyIdUrl:process.env.VUE_APP_XBODY_URL,
    };
  },
  mounted(){
    setTimeout(()=>{
      let win = window.open(this.xbodyIdUrl+'/forgot-password','_blank');
      if (win && !win.closed) {
    win.focus();
} else {
    // Handle pop-up blocking or other issues
    this.Redirect=true;
}
    },1000);
  },
  methods: {
    submit() {
      this.loading = true;
      this.axios
        .post("/new-password-reset", { email: this.email })
        .then((resp) => {
            this.$router.push({name: 'Login', params: {email: this.email}})
        })
        .catch((err) => console.log(err.response))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style scoped>

.left-side-image {
  background-image: url('../assets/img/login-image.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 70px 70px !important;
}

.small-logo {
  width: 150px;
}

label small {
  color: var(--gray);
}

h6 {
  font-weight: 400;
}

.container {
  max-width: 100% !important;
}

@media (min-width: 1200px) {
  .right-side-content {
    padding-left: 0px !important;
  }
}

@media (max-width: 1199px) {
  .left-side-image {
    height: 100px !important;
    padding: 37px 50px !important;
  }
  .right-side-content {
    max-height: calc(100% - 100px);
  }
  .remove-text {
    display: none;
  }
  .small-logo {
    width: 100px;
  }
}

.btn-normal {
  padding: 10px 10px;
  border-radius: 5px !important;
}

input {
  border-color: var(--gray) !important;
  box-shadow: none !important;
  outline: none !important;
  color: var(--black);
}

input:focus {
  border-color: var(--red) !important;
}

label,
.d-flex a:active,
.d-flex a:visited,
.d-flex a:link {
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
}

a:active,
a:visited,
a:link {
  color: var(--black);
  text-decoration: none;
  font-weight: 400;
}

a:hover {
  color: var(--red);
}

@media (max-width: 991px) {
  label,
  .d-flex a:active,
  .d-flex a:visited,
  .d-flex a:link {
    font-size: 13px !important;
  }
}
</style>
