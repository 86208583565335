<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('glossary.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('notifications.notifications') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->
        <div id="content-2">
            <div class="container">
                <div class="row mb-4">
                    <div class="col-lg-12">
                        <section id="notifications">
                            <div class="notification-box bg-white filter-shadow">
                                <!-- NOTIFICATION ITEM -->
                                <div v-for="notification in notifications.data" :key="notification.id" class="notification-item" :class="{unread: !notification.read}">
                                    <div class="row">
                                        <div class="col-lg-10 col-9">
                                            <div class="flex-wrap d-flex">
                                                <div class="d-flex align-items-center">
                                                    <div class="red-dot mr-3" :style="notification.read ? 'visibility: hidden' : ''"></div>
                                                    <div class="d-flex align-items-center">
                                                        <img v-if="notification.multimedia" :src="$store.getters.getStorageURL(notification.multimedia.path)" class="mr-2 img-circle img-fluid" style="border-radius: 50%" alt="">
                                                        <Avatar v-else-if="notification.initials" class="mr-2 img-circle img-fluid" :username="notification.initials" :rounded="true" />
                                                        <h2 @click.prevent="navigate(notification)" class="text-20 text-light-black" :style="{cursor: notification.link ? 'pointer' : 'default'}" :class="notification.read ? '' : 'semi-bold'">
                                                            {{notification.notification}}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-3 d-flex align-items-center justify-content-center">
                                            <h4 class="text-14 medium text-very-light-gray">{{$store.getters.parseDateTime(notification.updated_at)}}</h4>
                                        </div>
                                    </div>
                                </div>
                                <h6 v-if="notifications.data.length === 0" class="text-16 text-dark-gray">{{ $t('no-data.notifications') }}</h6>
                                <!-- //NOTIFICATION ITEM -->
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Avatar from '@/components/Avatar'
import InfiniteScroll from '@/components/InfiniteScroll'
export default {
    name: 'Notifications',
    components: {
        Avatar,
        InfiniteScroll
    },
    created() {
        this.notifications = JSON.parse(JSON.stringify(this.rawNotifications))
        this.sendRead()
    },
    data() {
        return {
            notifications: []
        }
    },
    computed: {
        rawNotifications() {
            return this.$store.state.user ? this.$store.state.user.notifications : {data: []}
        }
    },
    watch: {
        rawNotifications: {
            handler(newNotifications) {
                this.notifications.current_page = newNotifications.current_page
                const diff = newNotifications.data.filter(n => !this.notifications.data.find(n2 => n.id === n2.id));
                const same = newNotifications.data.filter(n => this.notifications.data.find(n2 => n.id === n2.id && n.read == 0));
                same.forEach(s => {
                    this.notifications.data[this.notifications.data.findIndex(n => n.id == s.id)] = JSON.parse(JSON.stringify(s))
                })
                if(diff.length > 0) {

                    if(this.notifications.data.length === 0 || new Date(this.notifications.data[0].updated_at) < new Date(diff[0].updated_at)) {
                        this.notifications.data.unshift(...JSON.parse(JSON.stringify(diff)))
                    }
                    else {
                        this.notifications.data.push(...JSON.parse(JSON.stringify(diff)))
                    }
                    this.sendRead()
                }
                if(same.length > 0) {
                    this.notifications.data.sort((a,b) => new Date(b.updated_at) - new Date(a.updated_at))
                    this.sendRead()
                }
                return newNotifications
            },
            deep: true
        },
    },
    methods: {
        sendRead() {
            this.rawNotifications.data.forEach(n => {
                n.read = true
            })
            this.axios.post('/notifications/read')
        },
        navigate(notification) {
            if(notification.link) {
                this.$router.push(notification.link)
            }
        },
        loadMore(state) {
            this.axios.get('/notifications', {params: {page: this.notifications.current_page + 1}})
                .then(resp => {
                    const prev = JSON.parse(JSON.stringify(this.$store.state.user.notifications.data))
                    resp.data.notifications.data.push(...prev)
                    this.$store.state.user.notifications = resp.data.notifications
                })
                .finally(() => state.finishLoad())
        }
    }
}
</script>
<style scoped>
    #notifications {
        margin-top: 30px;
    }
    .notification-box {
        padding: 20px;
        border-radius: 5px;
    }
    .red-dot {
        height: 11px !important;
        width: 11px !important;
        min-width: 11px !important;
        min-height: 11px !important;
        background-color: var(--red);
        border-radius: 50%;
    }
    .red-dot.disabled {
        background-color: transparent;
    }
    .notification-item {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 2px solid rgba(181, 173, 166, 0.25);
    }
    .notification-item:last-child {
        border-bottom: 0px solid transparent !important;
        padding-bottom: 0px !important;
    }
    .notification-item:first-child {
        padding-top: 0px !important;
    }
</style>
