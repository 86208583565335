<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({ name: 'Home' })">{{
                  $t('manage-courses.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
              </div>
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({ name: 'ManageCourses' })">{{
                  $t('manage-courses.managing-course')
                }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
              </div>
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3" v-if="course"
                  @click.prevent="$router.push({ name: 'EditCourse', params: { course: course.id } })">{{
                    course.title
                  }}</span>
                <span class="text-16 text-white pt-3 pb-3" v-else>{{ $t('course-materials.course') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('course-materials.manage-materials') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div v-if="!loading && course" class="container">
      <section id="buttons" v-if="!course.finalized">
        <div class="row ml-0 mr-0">
          <div class="col-xl-6 col-lg-6 col-md-12 col-12 pl-0">
            <h3 class="text-title-gray text-20 weight-600">{{ $t('course-materials.upload-multimedia') }}</h3>
            <div class="row ml-0 mr-0">
              <section id="media-buttons">
                <div class="row">
                  <div class="col-lg-6 mb-3">
                    <div class="flex-wrap d-flex" @click="attachImage">
                      <input ref="imageUpload" type="file" accept="image/png, image/jpeg, image/jpg, image/gif"
                        id="uploadImage" @change="createUpload('image')" style="display: none;">
                      <div class="d-flex align-items-center pointer mr-auto">
                        <div class="new-button-50 d-flex align-items-center justify-content-center">
                          <img src="assets/img/icons/white-camera.svg" class="img-fluid ml-1" alt="">
                        </div>
                        <div class="d-flex flex-column ml-2">
                          <h6 class="text-16 text-dark-gray">{{ $t('course-materials.add-picture') }}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <div class="flex-wrap d-flex" @click="attachVideo">
                      <input type="file" accept="video/mp4, video/webm, video/avi, video/quicktime" id="uploadVideo"
                        ref="videoUpload" @change="createUpload('video')" style="display: none;">
                      <div class="d-flex align-items-center pointer mr-auto">
                        <div class="new-button-50 d-flex align-items-center justify-content-center">
                          <img src="assets/img/icons/videocam.svg" class="img-fluid ml-1" alt="">
                        </div>
                        <div class="d-flex flex-column ml-2">
                          <h6 class="text-16 text-dark-gray">{{ $t('course-materials.add-video') }}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <div class="flex-wrap d-flex" @click="attachAudio">
                      <input type="file" accept="audio/mp3, audio/mpeg, audio/wav" id="uploadAudio" ref="audioUpload"
                        @change="createUpload('audio')" style="display: none;">
                      <div class="d-flex align-items-center pointer mr-auto">
                        <div class="new-button-50 d-flex align-items-center justify-content-center">
                          <img src="assets/img/icons/microphone.svg" class="img-fluid" alt="">
                        </div>
                        <div class="d-flex flex-column ml-2">
                          <h6 class="text-16 text-dark-gray">{{ $t('course-materials.add-voice') }}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <div class="flex-wrap d-flex" @click="attachDocument">
                      <input type="file" accept=".pdf" id="uploadDocument" ref="documentUpload"
                        @change="createUpload('document')" style="display: none;">
                      <div class="d-flex align-items-center pointer mr-auto">
                        <div class="new-button-50 d-flex align-items-center justify-content-center">
                          <img src="assets/img/icons/file.svg" class="img-fluid ml-1" alt="">
                        </div>
                        <div class="d-flex flex-column ml-2">
                          <h6 class="text-16 text-dark-gray">{{ $t('course-materials.add-document') }}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div class="col-12" v-if="files.filter((file) => { return file.uuid !== null }).length > 0">
                <h4 class="weight-600 text-18 text-title-gray">{{ $t('course-materials.attach-multimedia') }}</h4>
                <div v-for="file in files.filter((file) => { return file.uuid !== null })" :key="file.uuid"
                  class="col-12 col-md-6 p-0 mt-4">
                  <label>{{ $t('course-materials.name') }}</label>
                  <input type="text" v-model="file.name" class="form-control mr-2">
                  <div class="row p-0 align-items-end">
                    <div class="col-6">
                      <label class="mt-3">{{ $t('course-materials.length-minute') }}</label>
                      <input type="number" v-model="file.minutes" class="form-control mr-2"
                        :placeholder="$t('time-unit.minute')">
                    </div>
                    <div class="col-6">
                      <label class="mt-3">{{ $t('course-materials.length-second') }}</label>
                      <input type="number" v-model="file.seconds" class="form-control mr-2"
                        :placeholder="$t('time-unit.second')">
                    </div>
                    <div class="col-12">
                      <div class="form-check mt-3">
                        <input class="form-check-input" v-model="file.rewind" type="checkbox" :value="true" id="rewind">
                        <label class="form-check-label" for="rewind">{{ $t('course-materials.allow-rewind') }}</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-check mt-3">
                        <input class="form-check-input" v-model="file.is_public" type="checkbox" :true-value="1"
                          :false-value="0" id="is_public">
                        <label class="form-check-label" for="is_public">{{ $t('course-materials.is_public') }}</label>
                        <div class="d-flex align-items-center mt-1">
                          <i class="fas fa-info-circle mr-1 text-info font-15" style="font-size: 15px;"></i>
                          <span class="font-12" style="font-size: 12px">{{ $t('course-materials.is_public_desc') }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button :disabled="file.loading" @click="attachMultimedia(file)"
                    class="btn btn-secondary w-100 mt-3 mb-3">{{ $t('course-materials.add-button') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row col-lg-6 col-12">
            <section id="uploads" style="width: 100%">
              <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                <h1 class="text-title-gray text-20 weight-600 mt-0">{{ $t('course-materials.uploads') }}</h1>
                <div class="row ml-0 mr-0">
                  <div v-for="(file) in files" :key="file.id" class="col-12 mb-3">
                    <Upload v-if="file.shown" :file="file.file" :type="file.type" :id="file.id"
                      @uploadFinished="finishUpload" @uploadCancelled="cancelUpload" />
                  </div>
                  <div v-if="files.length === 0" class="mb-2">
                    <h2 class="text-title-gray text-14 mt-2 weight-600">{{ $t('fast-share.no-uploads') }}</h2>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div>
        </div>
        <div class="col-12 mb-4 mt-4 p-0">
          <h3 class="text-title-gray text-20 weight-600">{{ $t('course-materials.add-event') }}</h3>
          <form @submit.prevent="attachEvent">
            <div class="col-12 col-md-6 mt-4 p-0">
              <label>{{ $t('course-materials.event-type') }}</label>
              <select class="form-control" v-model="isZoom" @change="clearEvent" required>
                <option :value="1">{{ $t('course-materials.zoom') }}</option>
                <option :value="0">{{ $t('course-materials.other') }}</option>
              </select>
            </div>
            <div class="col-12 col-md-6 mt-3 p-0">
              <input v-model="event.wait_event" true-value='1' false-value='0' id="wait_event" type="checkbox"
                :style="{ border: '1px solid #ced4da', marginRight: '10px' }">
              <label class="" for="wait_event">{{ $t('course-materials.wait-event') }} </label>
            </div>
            <div v-if="isZoom" class="mt-4">
              <div class="col-12 col-md-6 p-0">
                <label>{{ $t('course-materials.zoom-type') }}</label>
                <select class="form-control" required v-model="event.zoom_type">
                  <option value="Meeting">{{ $t('course-materials.meeting') }}</option>
                </select>
              </div>
              <div class="col-12 col-md-6 mt-3 p-0">
                <label>{{ $t('course-materials.zoom-id') }}</label>
                <input class="form-control" type="number" @input="event.checked = false" required
                  v-model="event.zoom_meeting_id">
                <small>The meeting ID is the meeting number associated with an instant or scheduled meeting. The meeting
                  ID can be a 10 or 11-digit number. The 11-digit number is used for instant, scheduled or recurring
                  meetings. </small>
              </div>
            </div>
            <div v-else>
              <div class="col-12 col-md-6 mt-3 p-0">
                <label>{{ $t('course-materials.event-name') }}</label>
                <input class="form-control" type="text" required v-model="event.name">
              </div>
              <div class="col-12 col-md-6 mt-3 p-0">
                <label>{{ $t('course-materials.event-length') }}</label>
                <input class="form-control" min="1" type="number" required v-model="event.length">
              </div>
              <div class="col-12 col-md-6 mt-3 p-0">
                <label>{{ $t('course-materials.location') }}</label>
                <!--                <select class="form-control" v-model="event.location_id" required>
                                  <option :value="null" selected>{{ $t('course-materials.choose-list-create') }}</option>
                                  <option v-for="location in locations" :value="location.id" :key="location.id">
                                    {{
                                      `${location.zip_code}, ${location.locality}, ${location.public_space} ${location.public_space_type}, ${location.other}, ${location.country.name}`
                                    }}
                                  </option>
                                </select>-->
                <Select2 v-model="event.location_id" :options="locations"
                  :settings="{ width: '100%', disabled: course.finalized, placeholder: $t('course-materials.choose-list-create'), allowClear: true }" />
                <button type="button" class="btn btn-outline-primary mt-2 d-flex align-items-center" data-toggle="modal"
                  data-target="#newLocationModal">
                  <i class="fas fa-map-marker-alt mr-2"></i>
                  <span>{{ $t('course-materials.add-new-location') }}</span>
                </button>
              </div>
              <div class="col-12 col-md-6 mt-4 p-0">
                <label for="start">{{ $t('course-materials.event-begin') }}</label>
                <datetime class="form-control" type="datetime" id="start" required v-model="event.start_date" />
              </div>
            </div>
            <div class="col-4 col-md-3 mt-3 p-0">
              <button v-if="!isZoom || event.checked" type="submit" :disabled="event.loading"
                class="btn btn-secondary w-100">{{ $t('course-materials.create-event') }}
              </button>
              <button v-else :disabled="zoomCheckInProgress" @click.stop="checkZoom" type="button"
                class="btn btn-secondary w-100">{{ $t('course-materials.control') }}
              </button>
            </div>
          </form>
        </div>
        <div class="col-12 mb-4 mt-5 p-0">
          <h3 class="text-title-gray text-20 weight-600">{{ $t('course-materials.add-test') }}</h3>
          <div class="col-12 col-md-6 mt-4 p-0">
            <label>{{ $t('course-materials.choose-from-list') }}</label>
            <!--            <select class="form-control" required v-model="testToAdd">
                          <option v-for="test in tests" :key="test" :value="test">{{ test.name }}</option>
                        </select>-->
            <Select2 v-model="testToAdd" :options="tests" :settings="{ width: '100%' }" />
            <div class="d-flex mt-2">
              <button :disabled="!testToAdd || loading" @click.prevent="attachTest" class="btn btn-secondary">
                {{ $t('course-materials.add') }}
              </button>
              <button class="ml-2 btn btn-secondary" @click.prevent="$router.push({ name: 'TestAssembler' })">
                {{ $t('manage-tests.create-new') }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 mb-4 mt-5 p-0">
          <h3 class="text-title-gray text-20 weight-600">{{ $t('course-materials.exam-new') }}</h3>
          <div class="col-12 col-md-6 mt-4 p-0">
            <div v-if="hasExam">{{ $t('course-materials.exam-has') }}</div>
            <div v-if="getExamPoolMaxSize == 0">{{ $t('course-materials.exam-no') }}</div>
            <button data-toggle="modal" :disabled="hasExam == true || getExamPoolMaxSize == 0"
              data-target="#createExamModal" class="btn btn-secondary mt-2">{{ $t('course-materials.exam-test') }}
            </button>
            <div class="modal fade" ref="createExamModal" id="createExamModal" tabindex="-1" role="dialog"
              aria-labelledby="createExamModalLabel" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <form @submit.prevent="createExam">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="createExamModalLabel">{{ $t('course-materials.exam-test') }}</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <label for="exam-name">{{ $t('course-materials.exam-name') }}</label>
                            <input type="text" v-model="exam.name" class="form-control" id="exam-name" required>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label for="exam-bank-max">{{ $t('course-materials.exam-bank') }}</label><br>
                            <sub>{{ $t('course-materials.exam-bank-max') }}: {{ getExamPoolMaxSize }}</sub>
                            <input type="number" class="form-control" id="exam-bank-max" v-model="exam.question_pool_size"
                              placeholder="0">
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label for="try-number">{{ $t('course-materials.exam-max') }}</label>
                            <input type="number" v-model="exam.max_attempts" min="1" class="form-control" id="try-number"
                              required>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label for="min-points">{{ $t('course-materials.exam-min') }}</label>
                            <input type="number" min="0" v-model="exam.min_pass_percentage" class="form-control"
                              id="min-points" required>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label for="period">{{ $t('course-materials.exam-length') }}</label>
                            <input type="number" v-model="exam.length" min="0" class="form-control" id="period" required>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label for="exam-start">{{ $t('course-materials.exam-start') }}</label>
                            <datetime type="datetime" :max-datetime="exam.end_date" v-model="exam.start_date"
                              id="exam-start" input-class="form-control" />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label for="end">{{ $t('course-materials.exam-finish') }}</label>
                            <datetime type="datetime" :min-datetime="exam.start_date" v-model="exam.end_date" id="end"
                              input-class="form-control" />
                          </div>
                        </div>
                        <div class="col-12 mb-2">
                          <label class="container2 p-0">{{ $t('course-materials.exam-mix') }}
                            <input type="checkbox" v-model="exam.shuffle_questions" checked>
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="col-12">
                          <label class="container2 p-0">{{ $t('course-materials.exam-answer') }}
                            <input type="checkbox" v-model="exam.shuffle_answers" checked>
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-dismiss="modal">
                        {{ $t('course-materials.exam-cancel') }}
                      </button>
                      <button type="submit" :disabled="exam.loading" class="btn btn-secondary">
                        {{ $t('course-materials.exam-create') }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-4 mt-5 p-0">
          <h3 class="text-title-gray text-20 weight-600">{{ $t('course-materials.exam-materials') }}</h3>
        </div>
        <div class="col-12 p-0" v-if="course">
          <draggable @end="onOrderChange" v-model="course.course_materials" item-key="id">
            <template #item="{ element }">
              <div>
                <div v-if="element.course_multimedia">
                  <div class="d-flex align-items-center mb-4">

                    <div
                      v-if="element.course_multimedia.multimedia.type == 'video' || element.course_multimedia.multimedia.type == 'image' || element.course_multimedia.multimedia.type == 'audio'"
                      style="cursor:pointer;" data-fancybox="fancy"
                      data-options='{"buttons": ["zoom", "close"], "protect": true}'
                      :href="element.course_multimedia.multimedia.type != 'video' && element.course_multimedia.multimedia.type != 'audio' ? $store.state.storageURL + element.course_multimedia.multimedia.path + '?token=' + $store.state.token : '#cm' + element.course_multimedia.id"
                      target="_blank" :data-caption="element.course_multimedia.name" class="d-flex align-items-center">
                      <h2 class="font-20"
                        style="font-size: 20px !important; display: flex; align-items: center; margin-bottom: 0px;">
                        <i v-if="element.course_multimedia.multimedia.type == 'video'"
                          class="far fa-file-video mr-2 font-25"
                          style="font-size: 25px !important; width: 25px; color: var(--red);"></i>
                        <i v-else-if="element.course_multimedia.multimedia.type == 'image'"
                          class="far fa-image mr-2 font-25" style="font-size: 25px !important; color: var(--red);"></i>
                        <i v-else-if="element.course_multimedia.multimedia.type == 'audio'"
                          class="fas fa-microphone mr-2 font-25"
                          style="font-size: 25px !important; color: var(--red);"></i>
                        <span style="color: var(--black);">{{ element.course_multimedia.name }}</span>
                      </h2>
                      <div v-if="element.course_multimedia.multimedia.type == 'video'">
                        <video :id="'cm' + element.course_multimedia.id"
                          :src="$store.state.storageURL + element.course_multimedia.multimedia.path + '?token=' + $store.state.token"
                          :ref="'cm' + element.course_multimedia.id" style="display: none;" controls
                          controlsList="nodownload">

                        </video>
                      </div>
                      <div v-if="element.course_multimedia.multimedia.type == 'audio'">
                        <audio :id="'cm' + element.course_multimedia.id" :ref="'cm' + element.course_multimedia.id"
                          style="display: none;" controls controlsList="nodownload">
                          <source
                            :src="$store.state.storageURL + element.course_multimedia.multimedia.path + '?token=' + $store.state.token">
                        </audio>
                      </div>
                    </div>
                    <div v-else class="d-flex align-items-center" style="align-items: center;">
                      <h2 class="font-20"
                        style="font-size: 20px !important; display: flex; align-items: center; margin-bottom: 0px;">
                        <i class="fas fa-file-alt mr-2 font-25"
                          style="font-size: 25px !important; width: 25px !important; color: var(--red);"></i>
                        <a style="color: var(--black);"
                          :href="$store.state.storageURL + element.course_multimedia.multimedia.path + '?token=' + $store.state.token"
                          target="_blank">{{ element.course_multimedia.name }}</a>
                      </h2>
                    </div>
                    <div class="ml-4 d-flex align-items-center">
                      <i class="far fa-clock mr-2 font-25" style="font-size: 25px"></i>
                      <span class="font-20" style="font-size: 20px;">{{ materialDuration(element.course_multimedia.length)
                      }}</span>
                    </div>
                    <div class="ml-4 d-flex align-items-center" style="cursor: pointer; color: black;" data-toggle="modal"
                      :data-target="`#edit${element.id}Modal`">
                      <i class="fas fa-pen mr-2 font-20" style="font-size: 20px"></i>
                    </div>
                    <div class="ml-2 d-flex align-items-center" style="cursor: pointer; color: red;"
                      @click="deleteCourseMaterial(element)">
                      <i class="fas fa-trash-alt mr-2 font-20" style="font-size: 20px"></i>
                    </div>
                    <!-- Multimedia edit modal -->
                    <div class="modal fade" :ref="`edit${element.id}Modal`" :id="`edit${element.id}Modal`" tabindex="-1"
                      role="dialog" :aria-labelledby="`edit${element.id}ModalLabel`" aria-hidden="true">
                      <div class="modal-dialog" role="document">
                        <form @submit.prevent="editMultimedia(element)">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" :id="`edit${element.id}ModalLabel`">
                                {{ $t('course-materials.change-materials') }}</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <div class="row">
                                <div class="col-12 mt-3">
                                  <label>{{ $t('course-materials.multimedia-name') }}</label>
                                  <input class="form-control" type="text" required
                                    v-model="element.edited.course_multimedia.name">
                                </div>
                                <div class="col-6">
                                  <label class="mt-3">{{ $t('course-materials.length-minute') }}</label>
                                  <input type="number" v-model="element.edited.course_multimedia.minutes"
                                    class="form-control mr-2" :placeholder="$t('time-unit.minute')">
                                </div>
                                <div class="col-6">
                                  <label class="mt-3">{{ $t('course-materials.length-second') }}</label>
                                  <input type="number" v-model="element.edited.course_multimedia.seconds"
                                    class="form-control mr-2" :placeholder="$t('time-unit.second')">
                                </div>
                                <div class="col-12">
                                  <div class="form-check mt-3">
                                    <input type="checkbox" class="form-check-input"
                                      v-model="element.edited.course_multimedia.rewind" true-value="1" false-value="0"
                                      id="edit-rewind">
                                    <label class="form-check-label" for="edit-rewind">{{
                                      $t('course-materials.allow-rewind') }}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary"
                                @click="element.edited = JSON.parse(JSON.stringify(element))" data-dismiss="modal">{{
                                  $t('course-materials.delete-changes') }}
                              </button>
                              <button type="submit" class="btn btn-secondary">{{
                                $t('course-materials.save-changes')
                              }}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="element.course_event" class="ml-2">
                  <div v-if="!element.course_event.loading && element.course_event.zoom_meeting_id !== null"
                    class="d-flex align-items-center mb-4">
                    <a data-toggle="modal" :data-target="'#event' + element.id + 'Modal'"
                      style="font-size: 20px !important; display: flex; align-items: center; cursor: pointer;"
                      class="font-20 d-flex align-items-center">
                      <i class="fas fa-video mr-2 font-25"
                        style="font-size: 25px !important; width: 25px !important; color: var(--red)"></i>
                      <span style="color: var(--black);">{{ element.course_event.topic }}</span>
                      <div v-if="element.course_event.status === 'started'" class="circle ml-2"></div>
                    </a>
                    <span class="ml-4 d-flex align-items-center">
                      <i class="far fa-calendar-alt mr-2 font-25" style="font-size: 25px"></i>
                      <span class="font-20" style="font-size: 20px;">{{
                        $store.getters.parseDateTime(element.course_event.start_time)
                      }}</span>
                    </span>
                    <div class="ml-4 d-flex align-items-center" style="cursor: pointer; color: red;"
                      @click="deleteCourseMaterial(element)">
                      <i class="fas fa-trash-alt mr-2 font-20" style="font-size: 20px"></i>
                    </div>
                  </div>
                  <div v-else-if="!element.course_event.loading && element.course_event.zoom_meeting_id === null"
                    class="d-flex align-items-center mb-4">
                    <a data-toggle="modal" :data-target="'#event' + element.id + 'Modal'"
                      style="font-size: 20px !important; display: flex; align-items: center; cursor: pointer;"
                      class="font-20 d-flex align-items-center">
                      <i class="fas fa-school mr-2 font-25"
                        style="font-size: 25px !important; width: 25px !important; color: var(--red)"></i>
                      <span style="color: var(--black);">{{ element.course_event.name }}</span>
                    </a>
                    <span class="ml-4 d-flex align-items-center">
                      <i class="far fa-calendar-alt mr-2 font-25" style="font-size: 25px"></i>
                      <span class="font-20" style="font-size: 20px;">{{
                        $store.getters.parseDateTime(element.course_event.start_date)
                      }}</span>
                    </span>
                    <div class="ml-4 d-flex align-items-center" style="cursor: pointer; color: black;" data-toggle="modal"
                      :data-target="`#edit${element.id}Modal`">
                      <i class="fas fa-pen mr-2 font-20" style="font-size: 20px"></i>
                    </div>
                    <div class="ml-2 d-flex align-items-center" style="cursor: pointer; color: red;"
                      @click="deleteCourseMaterial(element)">
                      <i class="fas fa-trash-alt mr-2 font-20" style="font-size: 20px"></i>
                    </div>
                    <!-- Event edit modal -->
                    <div class="modal fade" :ref="`edit${element.id}Modal`" :id="`edit${element.id}Modal`" tabindex="-1"
                      role="dialog" :aria-labelledby="`edit${element.id}ModalLabel`" aria-hidden="true">
                      <div class="modal-dialog" role="document">
                        <form @submit.prevent="editEvent(element)">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" :id="`edit${element.id}ModalLabel`">
                                {{ $t('course-materials.change-materials') }}</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <div>
                                <div class="col-12 mt-3">
                                  <label>{{ $t('course-materials.event-name') }}</label>
                                  <input class="form-control" type="text" required
                                    v-model="element.edited.course_event.name">
                                </div>
                                <div class="col-12 col-md-6 mt-3 p-0">
                                  <input v-model="element.edited.course_event.wait_event" true-value='1' false-value='0'
                                    id="edit_wait_event" type="checkbox"
                                    :style="{ border: '1px solid #ced4da', marginRight: '10px' }">
                                  <label class="" for="edit_wait_event">{{ $t('course-materials.wait-event') }} </label>
                                </div>
                                <div class="col-12 mt-3">
                                  <label>{{ $t('course-materials.event-length') }}</label>
                                  <input class="form-control" min="1" type="number" required
                                    v-model="element.edited.course_event.length">
                                </div>
                                <div class="col-12 mt-3">
                                  <label>{{ $t('course-materials.location') }} </label>
                                  <!--                                  <select class="form-control" v-model="element.edited.course_event.location_id"
                                                                            required>
                                                                      <option :value="null" selected>{{
                                                                          $t('course-materials.choose-list-create')
                                                                        }}
                                                                      </option>
                                                                      <option v-for="location in locations" :value="location.id" :key="location.id">
                                                                        {{
                                                                          `${location.zip_code}, ${location.locality}, ${location.public_space} ${location.public_space_type}, ${location.other}, ${location.country.name}`
                                                                        }}
                                                                      </option>
                                                                    </select>-->
                                  <Select2 v-model="element.edited.course_event.location_id" :options="locations"
                                    :settings="{ width: '100%', placeholder: $t('course-materials.choose-list-create'), allowClear: true }" />
                                  <button type="button" class="btn btn-outline-primary mt-2 d-flex align-items-center"
                                    data-toggle="modal" data-target="#newLocationModal">
                                    <i class="fas fa-map-marker-alt mr-2"></i>
                                    <span>{{ $t('course-materials.add-new-location') }}</span>
                                  </button>
                                </div>
                                <div class="col-12 mt-4">
                                  <label for="start">{{ $t('course-materials.event-begin') }}</label>
                                  <datetime class="form-control" type="datetime" required
                                    v-model="element.edited.course_event.start_date" />
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary"
                                @click="element.edited = JSON.parse(JSON.stringify(element))" data-dismiss="modal">{{
                                  $t('course-materials.changes-lost') }}
                              </button>
                              <button type="submit" class="btn btn-secondary">{{
                                $t('course-materials.save-changes')
                              }}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <!-- Event view modal -->
                  <div class="modal fade" :ref="'event' + element.id + 'Modal'" :id="'event' + element.id + 'Modal'" tabindex="-1"
                    role="dialog" :aria-labelledby="'event' + element.id + 'ModalLabel'" aria-hidden="true">
                    <div v-if="!element.course_event.loading" class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="modal-title" :id="'event' + element.id + 'ModalLabel'">
                            {{ element.course_event.name ?? element.course_event.topic }}</h4>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div v-if="element.course_event.zoom_meeting_id === null" class="row">
                            <div class="col-12 mt-2">
                              <h5><strong>{{ $t('course-materials.name') }}</strong></h5>
                              <span>{{ element.course_event.name }}</span>
                            </div>
                            <div class="col-12 mt-3">
                              <h5><strong>{{ $t('course-materials.location') }}</strong></h5>
                              <span>{{
                                `${element.course_event.location.zip_code}, ${element.course_event.location.locality},
                                                              ${element.course_event.location.public_space}
                                                              ${element.course_event.location.public_space_type},
                                                              ${element.course_event.location.other}, ${element.course_event.location.country.name}`
                              }}</span>
                            </div>
                            <div class="col-12 mt-3">
                              <h5><strong>{{ $t('course-materials.begin') }}</strong></h5>
                              <span>{{ $store.getters.parseDateTime(element.course_event.start_date) }}</span>
                            </div>
                            <div class="col-12 mt-3">
                              <h5><strong>{{ $t('course-materials.duration') }}</strong></h5>
                              <span>{{ element.course_event.length }} {{ $t('correct-test-attempt.minute') }}</span>
                            </div>
                          </div>
                          <div v-else-if="element.course_event.zoom_meeting_id !== null" class="row">
                            <div class="col-12 mt-2">
                              <h5><strong>{{ $t('course-materials.name') }}</strong></h5>
                              <span>{{ element.course_event.topic }}</span>
                            </div>
                            <div class="col-12 mt-3">
                              <h5><strong>{{ $t('course-materials.begin') }}</strong></h5>
                              <span>{{ $store.getters.parseDateTime(element.course_event.start_time) }}</span>
                            </div>
                            <div class="col-12 mt-3">
                              <h5><strong>{{ $t('course-materials.duration') }}</strong></h5>
                              <span>{{ element.course_event.duration }} {{ $t('correct-test-attempt.minute') }}</span>
                            </div>
                            <div class="col-12 mt-3">
                              <h5><strong>{{ $t('course-materials.connect-zoomapp') }}</strong></h5>
                              <a :href="element.course_event.join_url" target="_blank" style="color: var(--black)">{{
                                $t('course-materials.click-connect') }}</a>
                            </div>
                            <div class="col-12 mt-3" v-if="$store.state.user.role === 'Admin'">
                              <h5><strong>{{ $t('course-materials.start-zoom') }}</strong></h5>
                              <a :href="element.course_event.start_url" target="_blank" style="color: var(--black)">{{
                                $t('course-materials.start-zoom-admin') }}</a>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            {{ $t('course-materials.close') }}
                          </button>
                          <a v-if="element.course_event.zoom_meeting_id !== null" type="button" style="color: white"
                            :href="frontendURL + '/meeting/' + element.course_event.id" :disabled="locationLoading"
                            class="btn btn-secondary">{{ $t('course-materials.open-web') }}</a>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div v-if="element.course_test" class="ml-2">
                  <div class="d-flex align-items-center mb-4">
                    <a data-toggle="modal" :data-target="'#test' + element.id + 'Modal'"
                      style="font-size: 20px !important; display: flex; align-items: center; cursor: pointer;"
                      class="font-20 d-flex align-items-center">
                      <i class="fas fa-clipboard-list mr-2 font-25"
                        :style="{ color: element.course_test.test.exam ? 'var(--red)' : 'var(--red)' }"
                        style="font-size: 25px !important; width: 25px !important;"></i>
                      <span :style="{ color: element.course_test.test.exam ? 'var(--red)' : 'var(--black)' }">{{
                        element.course_test.test.name
                      }}</span>
                    </a>
                    <span class="ml-4 d-flex align-items-center">
                      <i class="far fa-calendar-alt mr-2 font-25" style="font-size: 25px"></i>
                      <span class="font-20" style="font-size: 20px;">{{
                        element.course_test.test.start_date ?
                        $store.getters.parseDateTime(element.course_test.test.start_date) : 'Képzés kezdetétől'
                      }} - {{
  element.course_test.test.end_date ?
  $store.getters.parseDateTime(element.course_test.test.end_date) : 'Képzés végéig'
}}</span>
                    </span>
                    <div v-if="element.course_test.test.exam" class="ml-4 d-flex align-items-center"
                      style="cursor: pointer;" data-toggle="modal" data-target="#editExamModal">
                      <i class="fas fa-pen mr-2 font-20" style="font-size: 20px; color: black"></i>
                    </div>
                    <div v-else class="ml-4 d-flex align-items-center" style="cursor: pointer;"
                      @click="$router.push({ name: 'EditTest', params: { test: element.course_test.test.id } })">
                      <i class="fas fa-pen mr-2 font-20" style="font-size: 20px; color: black"></i>
                    </div>
                    <div class="d-flex align-items-center ml-1" style="cursor: pointer; color: red;"
                      @click="deleteCourseMaterial(element)">
                      <i class="fas fa-trash-alt mr-2 font-20" style="font-size: 20px"></i>
                    </div>
                  </div>
                  <div v-if="element.course_test.test.exam" class="modal fade" ref="editExamModal" id="editExamModal"
                    tabindex="-1" role="dialog" aria-labelledby="editExamModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <form @submit.prevent="editExam(element)">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="editExamModalLabel">{{ $t('course-materials.exam-edit') }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group">
                                  <label for="name">{{ $t('course-materials.exam-name') }}</label>
                                  <input type="text" v-model="element.edited.course_test.test.name" class="form-control"
                                    id="name" required>
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="form-group">
                                  <label for="name">{{ $t('course-materials.exam-bank') }}</label><br>
                                  <sub>{{ $t('course-materials.exam-bank-max') }}: {{ getExamPoolMaxSize }}</sub>
                                  <input type="number" class="form-control" id="name"
                                    v-model="element.edited.course_test.test.question_pool_size" placeholder="0">
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="form-group">
                                  <label for="try-number">{{ $t('course-materials.exam-max') }}</label>
                                  <input type="number" v-model="element.edited.course_test.test.max_attempts" min="1"
                                    class="form-control" id="try-number" required>
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="form-group">
                                  <label for="min-points">{{ $t('course-materials.exam-min') }}</label>
                                  <input type="number" min="0"
                                    v-model="element.edited.course_test.test.min_pass_percentage" class="form-control"
                                    id="min-points" required>
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="form-group">
                                  <label for="period">{{ $t('course-materials.exam-length') }}</label>
                                  <input type="number" v-model="element.edited.course_test.test.length" min="0"
                                    class="form-control" id="period" required>
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="form-group">
                                  <label for="start">{{ $t('course-materials.exam-start') }}</label>
                                  <datetime type="datetime" :max-datetime="element.edited.course_test.test.end_date"
                                    v-model="element.edited.course_test.test.start_date" input-class="form-control" />
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="form-group">
                                  <label for="end">{{ $t('course-materials.exam-finish') }}</label>
                                  <datetime type="datetime" :min-datetime="element.edited.course_test.test.start_date"
                                    v-model="element.edited.course_test.test.end_date" input-class="form-control" />
                                </div>
                              </div>
                              <div class="col-12 mb-2">
                                <label class="container2 p-0">{{ $t('course-materials.exam-mix') }}
                                  <input type="checkbox" v-model="element.edited.course_test.test.shuffle_questions"
                                    true-value="1" false-value="0">
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                              <div class="col-12">
                                <label class="container2 p-0">{{ $t('course-materials.exam-answer') }}
                                  <input type="checkbox" v-model="element.edited.course_test.test.shuffle_answers"
                                    true-value="1" false-value="0">
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">
                              {{ $t('course-materials.exam-cancel') }}
                            </button>
                            <button type="submit" :disabled="exam.loading" class="btn btn-secondary">
                              {{ $t('course-materials.exam-changes') }}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <!-- Test view modal -->
                  <div class="modal fade" :ref="'test' + element.id + 'Modal'" :id="'test' + element.id + 'Modal'" tabindex="-1"
                    role="dialog" :aria-labelledby="'event' + element.id + 'ModalLabel'" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="modal-title" :id="'test' + element.id + 'ModalLabel'">
                            {{ element.course_test.test.name }}</h4>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col-12 mt-2">
                              <h5><strong>{{ $t('course-materials.name') }}</strong></h5>
                              <span>{{ element.course_test.test.name }}</span>
                            </div>
                            <div class="col-12 mt-2">
                              <h5><strong>{{ $t('course-materials.exam-type') }}</strong></h5>
                              <span>{{ element.course_test.test.exam ? 'Vizsga' : 'Ellenőrző teszt' }}</span>
                            </div>
                            <div class="col-12 mt-3">
                              <h5><strong>{{ $t('course-materials.min-percent') }}</strong></h5>
                              <span>{{ element.course_test.test.min_pass_percentage }}%</span>
                            </div>
                            <div class="col-12 mt-3">
                              <h5><strong>{{ $t('course-materials.max-attempts') }}</strong></h5>
                              <span>{{ element.course_test.test.max_attempts }}</span>
                            </div>
                            <div class="col-12 mt-3">
                              <h5><strong>{{ $t('course-materials.duration') }}</strong></h5>
                              <span>{{ element.course_test.test.length }} {{ $t('correct-test-attempt.minute') }}</span>
                            </div>
                            <div class="col-12 mt-3">
                              <h5><strong>{{ $t('course-materials.test-start') }}</strong></h5>
                              <span>{{
                                element.course_test.test.start_date ?
                                $store.getters.parseDateTime(element.course_test.test.start_date) : $t('common.cbegin')
                              }}</span>
                            </div>
                            <div class="col-12 mt-3">
                              <h5><strong>{{ $t('course-materials.test-end') }}</strong></h5>
                              <span>{{
                                element.course_test.test.end_date ?
                                $store.getters.parseDateTime(element.course_test.test.end_date) : $t('common.cend')
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            {{ $t('course-materials.exam-close') }}
                          </button>
                          <button v-if="!element.course_test.test.exam" type="button" data-dismiss="modal"
                            @click="$router.push({ name: 'EditTest', params: { test: element.course_test.test.id } })"
                            class="btn btn-secondary">{{ $t('course-materials.test-editor') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </draggable>
        </div>
      </section>
      <div v-if="course.finalized">
        <h3 class="mb-4">{{ $t('course-materials.manage-materials') }}</h3>
        <div v-for="element in course.course_materials" :key="element.id">
          <div v-if="element.course_multimedia">
            <div class="d-flex align-items-center mb-4">

              <div
                v-if="element.course_multimedia.multimedia.type == 'video' || element.course_multimedia.multimedia.type == 'image' || element.course_multimedia.multimedia.type == 'audio'"
                style="cursor:pointer;" data-fancybox="fancy"
                data-options='{"buttons": ["zoom", "close"], "protect": true}'
                :href="element.course_multimedia.multimedia.type != 'video' && element.course_multimedia.multimedia.type != 'audio' ? $store.state.storageURL + element.course_multimedia.multimedia.path + '?token=' + $store.state.token : '#cm' + element.course_multimedia.id"
                target="_blank" :data-caption="element.course_multimedia.name" class="d-flex align-items-center">
                <h2 class="font-20"
                  style="font-size: 20px !important; display: flex; align-items: center; margin-bottom: 0px;">
                  <i v-if="element.course_multimedia.multimedia.type == 'video'" class="far fa-file-video mr-2 font-25"
                    style="font-size: 25px !important; width: 25px; color: var(--red);"></i>
                  <i v-else-if="element.course_multimedia.multimedia.type == 'image'" class="far fa-image mr-2 font-25"
                    style="font-size: 25px !important; color: var(--red);"></i>
                  <i v-else-if="element.course_multimedia.multimedia.type == 'audio'"
                    class="fas fa-microphone mr-2 font-25" style="font-size: 25px !important; color: var(--red);"></i>
                  <span style="color: var(--black);">{{ element.course_multimedia.name }}</span>
                </h2>
                <div v-if="element.course_multimedia.multimedia.type == 'video'">
                  <video :id="'cm' + element.course_multimedia.id"
                    :src="$store.state.storageURL + element.course_multimedia.multimedia.path + '?token=' + $store.state.token"
                    :ref="'cm' + element.course_multimedia.id" style="display: none;" controls controlsList="nodownload">

                  </video>
                </div>
                <div v-if="element.course_multimedia.multimedia.type == 'audio'">
                  <audio :id="'cm' + element.course_multimedia.id" :ref="'cm' + element.course_multimedia.id"
                    style="display: none;" controls controlsList="nodownload">
                    <source
                      :src="$store.state.storageURL + element.course_multimedia.multimedia.path + '?token=' + $store.state.token">
                  </audio>
                </div>
              </div>
              <div v-else class="d-flex align-items-center" style="align-items: center;">
                <h2 style="font-size: 20px !important; display: flex; align-items: center; margin-bottom: 0px;">
                  <i class="fas fa-file-alt mr-2"
                    style="font-size: 25px !important; width: 25px !important; color: var(--red);"></i>
                  <a style="color: var(--black);"
                    :href="$store.state.storageURL + element.course_multimedia.multimedia.path + '?token=' + $store.state.token"
                    target="_blank">{{ element.course_multimedia.name }}</a>
                </h2>
              </div>
              <div class="ml-4 d-flex align-items-center">
                <i class="far fa-clock mr-2" style="font-size: 25px"></i>
                <span style="font-size: 20px;">{{ materialDuration(element.course_multimedia.length) }}</span>
              </div>
            </div>
          </div>

          <div v-if="element.course_event">
            <attendance-sheet-modal v-if="course" :event="element.course_event" :course="course" />
            <div v-if="!element.course_event.loading && element.course_event.zoom_meeting_id !== null"
              class="d-flex align-items-center mb-4">
              <a data-toggle="modal" :data-target="'#event' + element.id + 'Modal'"
                style="display: flex; align-items: center; cursor: pointer;" class="font-20 d-flex align-items-center">
                <i class="fas fa-video mr-2 font-25" style="width: 25px !important; color: var(--red)"></i>
                <span style="color: var(--black);">{{ element.course_event.topic }}</span>
                <div v-if="element.course_event.status === 'started'" class="circle ml-2"></div>
              </a>
              <span class="ml-4 d-flex align-items-center">
                <i class="far fa-calendar-alt mr-2 font-25"></i>
                <span class="font-20">{{ $store.getters.parseDateTime(element.course_event.start_time) }}</span>
              </span>
              <div class="ml-4" data-toggle="modal" :data-target="'#attendanceSheetModal' + element.course_event.id"
                style="cursor: pointer;">
                <i class="fas fa-users mr-2 font-25"></i>
                <span class="font-20">{{ $t('attendance-sheet.title') }}</span>
              </div>
            </div>
            <div v-else-if="!element.course_event.loading && element.course_event.zoom_meeting_id === null"
              class="d-flex align-items-center mb-4">
              <a data-toggle="modal" :data-target="'#event' + element.id + 'Modal'"
                style="display: flex; align-items: center; cursor: pointer;" class="font-20 d-flex align-items-center">
                <i class="fas fa-school mr-2 font-25" style="width: 25px !important; color: var(--red)"></i>
                <span style="color: var(--black);">{{ element.course_event.name }}</span>
              </a>
              <span class="ml-2 d-flex align-items-center">
                <i class="far fa-calendar-alt mr-2 font-25"></i>
                <span class="font-20">{{ $store.getters.parseDateTime(element.course_event.start_date) }}</span>
              </span>
              <div class="ml-4 d-flex align-items-center" style="cursor: pointer; color: black;" data-toggle="modal"
                :data-target="`#edit${element.id}Modal`">
                <i class="fas fa-pen mr-2 font-20"></i>
              </div>
              <div class="ml-4" data-toggle="modal" :data-target="'#attendanceSheetModal' + element.course_event.id"
                style="cursor: pointer;">
                <i class="fas fa-users mr-2 font-25"></i>
                <span class="font-20">{{ $t('attendance-sheet.title') }}</span>
              </div>
            </div>
            <div class="modal fade" :ref="`edit${element.id}Modal`" :id="`edit${element.id}Modal`" tabindex="-1"
              role="dialog" :aria-labelledby="`edit${element.id}ModalLabel`" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <form @submit.prevent="editEvent(element)">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" :id="`edit${element.id}ModalLabel`">
                        {{ $t('course-materials.change-materials') }}</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div>
                        <div class="col-12 mt-3">
                          <label>{{ $t('course-materials.event-name') }}</label>
                          <input class="form-control" type="text" required v-model="element.edited.course_event.name">
                        </div>
                        <div class="col-12 mt-3">
                          <label>{{ $t('course-materials.event-length') }}</label>
                          <input class="form-control" disabled min="1" type="number" required
                            v-model="element.edited.course_event.length">
                        </div>
                        <div class="col-12 mt-3">
                          <label>{{ $t('course-materials.location') }} </label>
                          <!--                          <select class="form-control" v-model="element.edited.course_event.location_id" required>
                                                      <option :value="null" selected>{{ $t('course-materials.choose-list-create') }}</option>
                                                      <option v-for="location in locations" :value="location.id" :key="location.id">
                                                        {{
                                                          `${location.zip_code}, ${location.locality}, ${location.public_space} ${location.public_space_type}, ${location.other}, ${location.country.name}`
                                                        }}
                                                      </option>
                                                    </select>-->
                          <Select2 v-model="element.edited.course_event.location_id" :options="locations"
                            :settings="{ width: '100%', placeholder: $t('course-materials.choose-list-create'), allowClear: true }" />
                          <button type="button" class="btn btn-outline-primary mt-2 d-flex align-items-center"
                            data-toggle="modal" data-target="#newLocationModal">
                            <i class="fas fa-map-marker-alt mr-2"></i>
                            <span>{{ $t('course-materials.add-new-location') }}</span>
                          </button>
                        </div>
                        <div class="col-12 mt-4">
                          <label for="start">{{ $t('course-materials.event-begin') }}</label>
                          <datetime class="form-control" type="datetime" required
                            v-model="element.edited.course_event.start_date" />
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary"
                        @click="element.edited = JSON.parse(JSON.stringify(element))" data-dismiss="modal">
                        {{ $t('course-materials.changes-lost') }}
                      </button>
                      <button type="submit" class="btn btn-secondary">{{ $t('course-materials.save-changes') }}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- Event view modal -->
            <div class="modal fade" :ref="'event' + element.id + 'Modal'" :id="'event' + element.id + 'Modal'" tabindex="-1"
              role="dialog" :aria-labelledby="'event' + element.id + 'ModalLabel'" aria-hidden="true">
              <div v-if="!element.course_event.loading" class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title" :id="'event' + element.id + 'ModalLabel'">
                      {{ element.course_event.name ?? element.course_event.topic }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div v-if="element.course_event.zoom_meeting_id === null" class="row">
                      <div class="col-12 mt-2">
                        <h5><strong>{{ $t('course-materials.name') }}</strong></h5>
                        <span>{{ element.course_event.name }}</span>
                      </div>
                      <div class="col-12 mt-3">
                        <h5><strong>{{ $t('course-materials.location') }}</strong></h5>
                        <span>{{
                          `${element.course_event.location.zip_code}, ${element.course_event.location.locality},
                                                  ${element.course_event.location.public_space}
                                                  ${element.course_event.location.public_space_type}, ${element.course_event.location.other},
                                                  ${element.course_event.location.country.name}`
                        }}</span>
                      </div>
                      <div class="col-12 mt-3">
                        <h5><strong>{{ $t('course-materials.begin') }}</strong></h5>
                        <span>{{ $store.getters.parseDateTime(element.course_event.start_date) }}</span>
                      </div>
                      <div class="col-12 mt-3">
                        <h5><strong>{{ $t('course-materials.duration') }}</strong></h5>
                        <span>{{ element.course_event.length }} {{ $t('correct-test-attempt.minute') }}</span>
                      </div>
                    </div>
                    <div v-else-if="element.course_event.zoom_meeting_id !== null" class="row">
                      <div class="col-12 mt-2">
                        <h5><strong>{{ $t('course-materials.name') }}</strong></h5>
                        <span>{{ element.course_event.topic }}</span>
                      </div>
                      <div class="col-12 mt-3">
                        <h5><strong>{{ $t('course-materials.begin') }}</strong></h5>
                        <span>{{ $store.getters.parseDateTime(element.course_event.start_time) }}</span>
                      </div>
                      <div class="col-12 mt-3">
                        <h5><strong>{{ $t('course-materials.duration') }}</strong></h5>
                        <span>{{ element.course_event.duration }} {{ $t('correct-test-attempt.minute') }}</span>
                      </div>
                      <div class="col-12 mt-3">
                        <h5><strong>{{ $t('course-materials.connect-zoomapp') }}</strong></h5>
                        <a :href="element.course_event.join_url" target="_blank" style="color: var(--black)">{{
                          $t('course-materials.click-connect') }}</a>
                      </div>
                      <div class="col-12 mt-3" v-if="$store.state.user.role === 'Admin'">
                        <h5><strong>{{ $t('course-materials.start-zoom') }}</strong></h5>
                        <a :href="element.course_event.start_url" target="_blank" style="color: var(--black)">{{
                          $t('course-materials.start-zoom-admin') }}</a>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{
                      $t('course-materials.close')
                    }}
                    </button>
                    <a v-if="element.course_event.zoom_meeting_id !== null" type="button" style="color: white"
                      :href="frontendURL + '/meeting/' + element.course_event.id" :disabled="locationLoading"
                      class="btn btn-secondary">{{ $t('course-materials.open-web') }}</a>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div v-if="element.course_test">
            <div class="d-flex align-items-center mb-4">
              <a data-toggle="modal" :data-target="'#test' + element.id + 'Modal'"
                style="display: flex; align-items: center; cursor: pointer;" class="font-20 d-flex align-items-center">
                <i class="fas fa-clipboard-list mr-2 font-25"
                  :style="{ color: element.course_test.test.exam ? 'var(--red)' : 'var(--red)' }"
                  style="width: 25px !important;"></i>
                <span :style="{ color: element.course_test.test.exam ? 'var(--red)' : 'var(--black)' }">{{
                  element.course_test.test.name
                }}</span>
              </a>
              <span class="ml-4 d-flex align-items-center">
                <i class="far fa-calendar-alt mr-2 font-25"></i>
                <span class="font-20">{{
                  element.course_test.test.start_date ? $store.getters.parseDateTime(element.course_test.test.start_date)
                  : 'Képzés kezdetétől'
                }} - {{
  element.course_test.test.end_date ? $store.getters.parseDateTime(element.course_test.test.end_date) :
  'Képzés végéig'
}}</span>
              </span>
            </div>
            <!-- Test view modal -->
            <div class="modal fade" :ref="'test' + element.id + 'Modal'" :id="'test' + element.id + 'Modal'" tabindex="-1"
              role="dialog" :aria-labelledby="'event' + element.id + 'ModalLabel'" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title" :id="'test' + element.id + 'ModalLabel'">
                      {{ element.course_test.test.name }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-12 mt-2">
                        <h5><strong>{{ $t('course-materials.name') }}</strong></h5>
                        <span>{{ element.course_test.test.name }}</span>
                      </div>
                      <div class="col-12 mt-2">
                        <h5><strong>{{ $t('course-materials.exam-type') }}</strong></h5>
                        <span>{{ element.course_test.test.exam ? 'Vizsga' : 'Ellenőrző teszt' }}</span>
                      </div>
                      <div class="col-12 mt-3">
                        <h5><strong>{{ $t('course-materials.min-percent') }}</strong></h5>
                        <span>{{ element.course_test.test.min_pass_percentage }}%</span>
                      </div>
                      <div class="col-12 mt-3">
                        <h5><strong>{{ $t('course-materials.max-attempts') }}</strong></h5>
                        <span>{{ element.course_test.test.max_attempts }}</span>
                      </div>
                      <div class="col-12 mt-3">
                        <h5><strong>{{ $t('course-materials.duration') }}</strong></h5>
                        <span>{{ element.course_test.test.length }} {{ $t('correct-test-attempt.minute') }}</span>
                      </div>
                      <div class="col-12 mt-3">
                        <h5><strong>{{ $t('course-materials.test-start') }}</strong></h5>
                        <span>{{
                          element.course_test.test.start_date ?
                          $store.getters.parseDateTime(element.course_test.test.start_date) : 'Képzés kezdete'
                        }}</span>
                      </div>
                      <div class="col-12 mt-3">
                        <h5><strong>{{ $t('course-materials.test-end') }}</strong></h5>
                        <span>{{
                          element.course_test.test.end_date ?
                          $store.getters.parseDateTime(element.course_test.test.end_date) : 'Képzés Vége'
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                      {{ $t('course-materials.exam-close') }}
                    </button>
                    <button v-if="!element.course_test.test.exam" type="button" data-dismiss="modal"
                      @click="$router.push({ name: 'EditTest', params: { test: element.course_test.test.id } })"
                      class="btn btn-secondary">{{ $t('course-materials.test-editor') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- New location modal -->
      <div class="modal fade" ref="newLocationModal" id="newLocationModal" tabindex="-1" role="dialog"
        aria-labelledby="newLocationModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="newLocationModalLabel">{{ $t('course-materials.new-location') }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-12 mt-2">
                    <label>{{ $t('course-materials.country') }}</label>
                    <div v-if="errors['country']">
                      <span v-for="(error, idx) in errors['country']" style="color: red;" :key="idx">{{ error }}</span>
                    </div>
                    <!--                    <select v-model="locationData.country_id" class="form-control">
                                          <option v-for="country in countries" :value="country.id" :key="country.id">{{ country.name }}
                                          </option>
                                        </select>-->
                    <Select2 v-model="locationData.country_id" :options="countries" :settings="{ width: '100%' }" />
                  </div>
                  <div class="col-12 mt-2">
                    <label>{{ $t('course-materials.zip-code') }}</label>
                    <div v-if="errors['zip_code']">
                      <span v-for="(error, idx) in errors['zip_code']" style="color: red;" :key="idx">{{ error }}</span>
                    </div>
                    <input type="text" v-model="locationData.zip_code" class="form-control"
                      :style="{ border: errors['zip_code'] ? '1px solid red' : '1px solid #ced4da' }" required>
                  </div>
                  <div class="col-12 mt-2">
                    <label>{{ $t('course-materials.city') }}</label>
                    <div v-if="errors['locality']">
                      <span v-for="(error, idx) in errors['locality']" style="color: red;" :key="idx">{{ error }}</span>
                    </div>
                    <input type="text" v-model="locationData.locality" class="form-control"
                      :style="{ border: errors['locality'] ? '1px solid red' : '1px solid #ced4da' }" required>
                  </div>
                  <div class="col-12 mt-2">
                    <label>{{ $t('course-materials.street-name') }}</label>
                    <div v-if="errors['public_space']">
                      <span v-for="(error, idx) in errors['public_space']" style="color: red;" :key="idx">{{ error
                      }}</span>
                    </div>
                    <input type="text" v-model="locationData.public_space" class="form-control"
                      :style="{ border: errors['public_space'] ? '1px solid red' : '1px solid #ced4da' }" required>
                  </div>
                  <div class="col-12 mt-2">
                    <label>{{ $t('course-materials.street-type') }}</label>
                    <div v-if="errors['public_space_type']">
                      <span v-for="(error, idx) in errors['public_space_type']" style="color: red;" :key="idx">{{ error
                      }}</span>
                    </div>
                    <input type="text" v-model="locationData.public_space_type" class="form-control"
                      :style="{ border: errors['public_space_type'] ? '1px solid red' : '1px solid #ced4da' }" required>
                  </div>
                  <div class="col-12 mt-2">
                    <label>{{ $t('course-materials.other') }}</label>
                    <div v-if="errors['other']">
                      <span v-for="(error, idx) in errors['other']" style="color: red;" :key="idx">{{ error }}</span>
                    </div>
                    <input type="text" :placeholder="$t('course-materials.placeholder')" v-model="locationData.other"
                      class="form-control" :style="{ border: errors['other'] ? '1px solid red' : '1px solid #ced4da' }">
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">{{
                $t('course-materials.close')
              }}
              </button>
              <button type="button" @click="createLocation" :disabled="locationLoading" class="btn btn-secondary">
                {{ $t('course-materials.create') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Delete confirm modal -->
      <div class="modal fade" ref="deleteConfirmModal" id="deleteConfirmModal" tabindex="-1" role="dialog"
        aria-labelledby="deleteConfirmModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteConfirmModalLabel">{{ $t('course-materials.delete-material') }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ $t('course-materials.delete-question') }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">{{
                $t('course-materials.cancel')
              }}
              </button>
              <button type="button" @click="createLocation" :disabled="locationLoading" class="btn btn-danger">
                ){{ $t('course-materials.delete') }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--Upload or Attach modal-->
      <UploadOrAttach @attach="addToFiles" :uploadType="uploadType" @upload="openFileInputDialog" />
    </div>
    <loading v-else />
  </div>
</template>
<script>
import Upload from '@/components/Upload.vue'
import Loading from '@/components/Loading.vue'
import Datetime from '@/components/DateTimePicker/Datetime.vue'
import draggable from 'vuedraggable'
import AttendanceSheetModal from '../components/AttendanceSheetModal.vue'
import Select2 from "vue3-select2-component";
import UploadOrAttach from "../components/UploadOrAttach.vue";

export default {
  name: 'CourseMaterials',
  components: {
    UploadOrAttach,
    Loading,
    Upload,
    draggable,
    Datetime,
    AttendanceSheetModal,
    Select2
  },
  mounted() {
    this.axios.get('/courses/' + this.$route.params.course + '/materials')
      .then(resp => {
        this.course = resp.data.course
        this.course.course_materials.forEach((val, idx) => {
          this.course.course_materials[idx].edited = JSON.parse(JSON.stringify(val))
        })
        this.getCourseEventZoomDetails()
      })
      .finally(() => this.loading = false)

    this.axios.get('/courses/locations/' + this.$route.params.course)
      .then(resp => this.locations = resp.data.map(location => ({
        id: location.id,
        text: `${location.zip_code}, ${location.locality}, ${location.public_space} ${location.public_space_type}, ${location.other}, ${location.country.name}`
      })))

    this.axios.get('/countries')
      .then(resp => this.countries = resp.data.map(country => ({
        id: country.id,
        text: country.name
      })))

    this.axios.get('/tests/courseless')
      .then(resp => this.tests = resp.data.tests.map(test => ({
        id: test.id,
        text: test.name
      })))
  },
  data() {
    return {
      files: [],
      tests: [],
      frontendURL: process.env.VUE_APP_FRONTEND_URL,
      course: null,
      loading: true,
      isZoom: 1,
      uploadType: null,
      locations: [],
      locationData: {
        country_id: 141
      },
      testToAdd: null,
      errors: Object(),
      locationLoading: false,
      countries: [],
      zoomCheckInProgress: false,
      event: {
        location_id: null,
        zoom_type: 'Meeting',
        checked: false,
        name: null,
        wait_event: false,
        zoom_meeting_id: null,
        length: null,
        loading: false,
      },
      exam: {
        name: null,
        hidden: 0,
        question_pool_size: null,
        max_attempts: null,
        shuffle_questions: true,
        shuffle_answers: true,
        min_pass_percentage: null,
        length: null,
        start_date: null,
        end_date: null
      },
    }
  },
  watch: {
    files() {
      console.log("files", this.files);
      return this.files;
    },
  },
  computed: {
    canSend() {
      return !this.files.find(file => file.uuid === null)
    },
    getExamPoolMaxSize() {
      if (!this.course)
        return 0
      let sumQuestions = 0
      this.course.course_materials.filter(cm => cm.course_tests_id != null && cm.course_test.test.exam != true).forEach(cm => {
        sumQuestions += cm.course_test.test.test_questions.length
      })
      return sumQuestions
    },
    hasExam() {
      if (!this.course)
        return false
      return this.course.course_materials.some(cm => cm.course_tests_id != null && cm.course_test.test.exam == true)
    }
  },
  methods: {
    onOrderChange(e) {
      if (e.oldIndex !== e.newIndex) {
        let courseMaterial = this.course.course_materials[e.newIndex]
        this.axios.post('/courses/' + this.course.id + '/materials/' + courseMaterial.id + '/order', { order: e.newIndex + 1 })
      }
    },
    createUpload(type) {
      console.log("createUpload", type);
      if (event.target.files.item(0)) {
        this.files.push({
          file: event.target.files.item(0),
          name: event.target.files.item(0).name,
          type: type,
          shown: true,
          uuid: null,
          multimedia: null,
          length: null,
          seconds: null,
          minutes: null,
          loading: false,
          id: this.files.length
        })
        event.target.value = ""
      }
    },
    finishUpload(id, multimedia, duration) {
      if (duration) {
        this.files.find(file => file.id === id).minutes = Math.floor(duration / 60)
        this.files.find(file => file.id === id).seconds = Math.floor(duration - (Math.floor(duration / 60) * 60))
      }
      this.files.find(file => file.id === id).uuid = multimedia.id
      this.files.find(file => file.id === id).multimedia = multimedia
    },
    cancelUpload(id, uuid) {
      this.files.splice(this.files.indexOf(this.files.find(file => file.id === id)), 1)
    },
    attachMultimedia(file) {
      file.loading = true
      console.log(file);
      this.axios.post(`/courses/${this.course.id}/materials/multimedia/${file.multimedia.id}`, {
        length: (Number(file.minutes * 60) + Number(file.seconds)),
        name: file.name,
        rewind: file.rewind,
        is_public: !!file.is_public
      })
        .then((resp) => {
          this.course.course_materials.push(resp.data.courseMaterial)
          this.course.course_materials[this.course.course_materials.length - 1].edited = JSON.parse(JSON.stringify(resp.data.courseMaterial))
          this.files.splice(this.files.indexOf(this.files.find(Ffile => Ffile.id === file.id)), 1)
        })
        .finally(() => file.loading = false)
    },
    editMultimedia(courseMaterial) {
      courseMaterial.edited.course_multimedia.length = Number(courseMaterial.edited.course_multimedia.minutes * 60) + Number(courseMaterial.edited.course_multimedia.seconds)
      this.axios.post(`/courses/${this.course.id}/materials/${courseMaterial.id}/multimedia`, courseMaterial.edited.course_multimedia)
        .then(resp => {
          this.course.course_materials[this.course.course_materials.findIndex((el) => {
            return el.id === courseMaterial.id
          })] = resp.data.courseMaterial
          courseMaterial = resp.data.courseMaterial
          courseMaterial.edited = JSON.parse(JSON.stringify(resp.data.courseMaterial))
        })
    },
    attachEvent() {
      this.event.loading = true
      this.axios.post(`/courses/${this.course.id}/materials/event`, this.event)
        .then((resp) => {
          this.course.course_materials.push(resp.data.courseMaterial)
          this.course.course_materials[this.course.course_materials.length - 1].edited = JSON.parse(JSON.stringify(resp.data.courseMaterial))
          if (resp.data.courseMaterial.course_event.zoom_meeting_id != null) {
            let courseMaterial = this.course.course_materials.find((el) => {
              return el.id == resp.data.courseMaterial.id
            })
            let index = this.course.course_materials.indexOf((el) => {
              return el.id == resp.data.courseMaterial.id
            })
            courseMaterial.course_event.loading = true
            this.axios.get(`/courses/${this.course.id}/events/${courseMaterial.course_event.id}`)
              .then(res => {
                courseMaterial.course_event = res.data.courseEvent
                courseMaterial.edited.course_event = JSON.parse(JSON.stringify(res.data.courseEvent))
              })
              //.catch(e => console.log(e.response))
              .finally(() => courseMaterial.course_event.loading = false)
          }
          this.event = {
            location_id: null,
            zoom_type: 'Meeting',
            checked: false,
            name: null,
            zoom_meeting_id: null,
            length: null,
            loading: false,
          }
        })
        .finally(() => this.event.loading = false)
    },
    editEvent(courseMaterial) {
      this.axios.post(`/courses/${this.course.id}/materials/${courseMaterial.id}/event`, courseMaterial.edited.course_event)
        .then(resp => {
          this.course.course_materials[this.course.course_materials.findIndex((el) => {
            return el.id === courseMaterial.id
          })] = resp.data.courseMaterial
          console.log(courseMaterial)
          courseMaterial = resp.data.courseMaterial
          courseMaterial.edited = JSON.parse(JSON.stringify(resp.data.courseMaterial))
        })
    },
    clearEvent() {
      this.event = {
        location_id: null,
        zoom_type: 'Meeting',
        checked: false,
        name: null,
        zoom_meeting_id: null,
        length: null,
        loading: false,
      }
    },
    materialDuration(duration) {
      // console.log(duration)
      let all = duration
      const hours = Math.floor(all / 3600)
      const minutes = Math.floor((all % 3600) / 60)
      const seconds = all - (hours * 3600) - (minutes * 60)
      return `${hours !== 0 ? hours + ' óra ' : ''}${minutes !== 0 ? minutes + ' perc ' : ''}${seconds !== 0 ? seconds + ' másodperc' : ''}`
    },
    createLocation() {
      this.locationLoading = true;
      this.axios.post('/locations', this.locationData)
        .then(resp => {
          console.log(this.isZoom)
          this.locations.push(resp.data.location)
          this.event.location_id = resp.data.location.id
          this.locationData = {
            country_id: 141
          }
          $(this.$refs.newLocationModal).modal('hide')
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => this.locationLoading = false)
    },
    checkZoom() {
      this.zoomCheckInProgress = true
      this.axios.get('/zoom/' + this.event.zoom_meeting_id + '/details')
        .then(resp => {
          console.log(resp)
          if (resp.data.code !== 300) {
            this.event.checked = true
            this.event.length = resp.data.duration
          } else {
            this.zoomError = resp.data.message
          }
        })
        .catch(e => console.log(e.response))
        .finally(() => this.zoomCheckInProgress = false)
    },
    getCourseEventZoomDetails() {
      if (this.course.course_materials)
        this.course.course_materials.forEach((courseMaterial, index) => {
          if (courseMaterial.course_event && courseMaterial.course_event.zoom_meeting_id !== null) {
            courseMaterial.course_event.loading = true
            this.axios.get(`/courses/${this.course.id}/events/${courseMaterial.course_event.id}`)
              .then(res => {
                let event = this.course.course_materials[index].course_event
                const id = event.id
                event = Object.assign(event, res.data.courseEvent)
                event.id = id
              })
              .catch(e => console.log(e.response))
              .finally(() => courseMaterial.course_event.loading = false)
          }
        })
    },
    deleteCourseMaterial(courseMaterial) {
      this.loading = true
      this.axios.delete(`/courses/${this.course.id}/materials/${courseMaterial.id}`)
        .then(resp => {
          this.course.course_materials = resp.data.courseMaterials
          this.course.course_materials.forEach((val, idx) => {
            this.course.course_materials[idx].edited = JSON.parse(JSON.stringify(val))
          })
          this.getCourseEventZoomDetails()
          this.axios.get('/tests/courseless')
            .then(resp => this.tests = resp.data.tests.map((test) => ({
              id: test.id,
              text: test.name
            })))
        })
        .catch(e => console.log(e.response))
        .finally(() => this.loading = false)
    },
    makeActive() {
      this.axios.post('/courses/' + this.course.id + '/active')
        .then(resp => this.course.active = 1)
    },
    makeInactive() {
      this.axios.post('/courses/' + this.course.id + '/inactive')
        .then(resp => this.course.active = 0)
    },
    attachTest() {
      this.loading = true
      this.axios.post(`/courses/${this.course.id}/materials/test/${this.testToAdd}`)
        .then((resp) => {
          this.course.course_materials.push(resp.data.courseMaterial)
          this.course.course_materials[this.course.course_materials.length - 1].edited = JSON.parse(JSON.stringify(resp.data.courseMaterial))
          //this.tests.splice(this.tests.findIndex(test => test.id == this.testToAdd.id), 1)
          this.testToAdd = null;
          this.loading = false;
        })
        .catch(() => this.loading = false)
    },
    editExam(courseMaterial) {
      this.axios.patch(`/courses/${this.course.id}/exam`, this.$store.getters.removeEmptyOrNull(courseMaterial.edited.course_test.test))
        .then(resp => {
          $('#editExamModal').modal('hide')
          this.course.course_materials[this.course.course_materials.findIndex((el) => {
            return el.id === courseMaterial.id
          })] = resp.data.courseMaterial
          console.log(courseMaterial)
          courseMaterial = resp.data.courseMaterial
          courseMaterial.edited = JSON.parse(JSON.stringify(resp.data.courseMaterial))

        })
    },
    createExam() {
      this.exam.loading = true
      this.axios.post(`/courses/${this.course.id}/exam`, this.$store.getters.removeEmptyOrNull(this.exam))
        .then(resp => {
          this.course.course_materials.push(resp.data.courseMaterial)
          $('#createExamModal').modal('hide')
          this.course.course_materials[this.course.course_materials.length - 1].edited = JSON.parse(JSON.stringify(resp.data.courseMaterial))
          this.exam = {
            name: null,
            hidden: 0,
            question_pool_size: null,
            max_attempts: null,
            shuffle_questions: true,
            shuffle_answers: true,
            min_pass_percentage: null,
            length: null,
            start_date: null,
            end_date: null
          }
        })
        .finally(() => this.exam.loading = false)
    },
    attachImage() {
      this.uploadType = 'image';
      $("#uploadOrAttach").modal("show");
    },
    attachVideo() {
      this.uploadType = 'video';
      $("#uploadOrAttach").modal("show");
    },
    attachDocument() {
      this.uploadType = 'document';
      $("#uploadOrAttach").modal("show");
    },
    attachAudio() {
      this.uploadType = 'audio';
      $("#uploadOrAttach").modal("show");
    },
    addToFiles(file) {
      file.id = this.files.length + 1;
      this.files.push(file);
    },
    openFileInputDialog() {
      switch (this.uploadType) {
        case 'image':
          this.$refs.imageUpload.click();
          break;
        case 'video':
          this.$refs.videoUpload.click();
          break;
        case 'document':
          this.$refs.documentUpload.click();
          break;
        case 'audio':
          this.$refs.audioUpload.click()
          break;
        default:
          break;
      }
      //this.$refs.videoUpload.click();
    },
  }
}
</script>
<style scoped>
.mobile-view-button .fa-file-video,
.mobile-view-button .fa-image,
.mobile-view-button .fa-microphone,
.mobile-view-button .fa-file-alt {
  font-size: 55px !important;
}

.mobile-item-1,
.mobile-item-2,
.mobile-item-3,
.mobile-item-4 {
  min-height: 150px !important;
  border-radius: 5px !important;
  padding-top: 34.5px;
}

.mobile-view-button .pc-text {
  font-size: 0.85rem;
}

@media (min-width: 768px) {
  .mobile-view-button .mobile-text {
    display: none !important;
  }
}

@media (max-width: 767px) {

  .mobile-view-button .fa-file-video,
  .mobile-view-button .fa-image,
  .mobile-view-button .fa-microphone,
  .mobile-view-button .fa-file-alt {
    font-size: 30px !important;
  }

  .mobile-view-button .pc-text {
    display: none !important;
  }

  .mobile-view-button .mobile-text {
    font-size: 0.6rem;
    white-space: nowrap;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .mobile-item-1,
  .mobile-item-2,
  .mobile-item-3,
  .mobile-item-4 {
    padding-top: 15px;
    min-height: 80px !important;
  }

  .mobile-view-button.mobile-padding-1 {
    padding-right: 5px !important;
  }

  .mobile-view-button.mobile-padding-2 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .mobile-view-button.mobile-padding-3 {
    padding-left: 5px !important;
  }

  #uploads h1.file-upload-text-header {
    font-size: 16px !important;
  }

  #uploads h2.file-upload-text {
    font-size: 14px !important;
  }
}
</style>
