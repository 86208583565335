<template>
    <div>
        <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Glossary'})">{{ $t('glossary-article.knowledge-base') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('glossary-article.knowledge-base-article') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->

        <div id="content-2">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <section id="article-list">
                            <div class="row">
                                <div class="col-12">
                                    <!-- ARTICLE -->
                                    <div class="article-box article-full bg-light-gray-primary mb-3">
                                        <div class="row" v-if="article">

                                            <div class="col-lg-12">
                                                <div class="d-flex flex-wrap">
                                                    <div class="d-flex">
                                                        <h1 class="text-22 text-white semi-bold">{{article.title}}</h1>
                                                    </div>
                                                    <div class="d-flex ml-auto">
                                                        <img v-if="$store.state.user.role == 'Admin'" @click.prevent="$router.push({name: 'EditGlossaryArticle', params: {id: article.id}})" src="assets/img/icons/pen.svg" class="img-fluid pointer" alt="">
                                                        <img v-if="$store.state.user.role == 'Admin'" data-toggle="modal" :data-target="'#glossaryDeleteModal'+article.id" src="assets/img/icons/trash.svg" class="img-fluid pointer" alt="">
                                                    </div>
                                                </div>
                                                <!-- BADGE FRAME -->
                                                <div class="flex-wrap d-flex">
                                                    <!-- BADGE -->
                                                    <div class="d-flex mr-3 mb-2" v-for="label in article.labels" :key="label.id">
                                                        <span class="article-badge"><img src="assets/img/icons/badge.svg" class="mr-1 img-fluid" alt="">{{label.name}}</span>
                                                    </div>
                                                    <!-- //BADGE -->
                                                </div>
                                                <!-- //BADGE FRAME -->
                                                <h2 class="text-14 medium text-white mt-3" v-html="$store.getters.sanitizeSummernoteText(article.content)"></h2>
                                                <div v-if="article.multimedia && article.multimedia.length > 0" class="mt-3">
                                                    <div class="mb-2">
                                                        <div v-for="multimedia in article.multimedia.filter((mm) => mm.type == 'document')" :key="multimedia.id" class="ml-2 mt-2">
                                                            <form target="_blank" :action="storageURL+multimedia.path" >
                                                                <input type="hidden" name="token" :value="token">
                                                                <PrimaryButton :text="$t('glossary-article.read')" />
                                                            </form>
                                                        </div>
                                                        <div v-for="multimedia in article.multimedia.filter((mm) => mm.type == 'audio')" :key="multimedia.id" class="mt-4">
                                                            <p class="ml-3 mb-0">{{multimedia.name}}</p>
                                                            <audio controls>
                                                                <source :src="storageURL+multimedia.path+'?token='+token">
                                                            </audio>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div v-for="multimedia in article.multimedia.filter((mm) => mm.type == 'video' || mm.type == 'image')" :key="multimedia.id" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4" style="max-height: 560px;">
                                                            <div v-if="multimedia.type == 'video' || multimedia.type == 'image' " style="color: var(--black); cursor:pointer; background-color: grey; width: 100%; height: 100%"
                                                                data-fancybox="fancy"
                                                                :href="multimedia.type != 'video' ? storageURL+multimedia.path+'?token='+token : '#'+multimedia.id" target="_blank"
                                                                :data-caption="multimedia.name"
                                                            >
                                                                <div v-if="multimedia.type == 'video'" style="width: 100%; height: 100%">
                                                                    <video style="width: 100%; height: 100%; object-fit: cover; pointer-events: none;" nocontrols >
                                                                        <source :src="storageURL+multimedia.path+'?token='+token">
                                                                    </video>
                                                                    <video :id="multimedia.id" style="display: none;" controls controlsList="nodownload">
                                                                        <source :src="storageURL+multimedia.path+'?token='+token">
                                                                    </video>
                                                                    <i class="far fa-play-circle playbtn"></i>
                                                                </div>
                                                                <div v-if="multimedia.type == 'image'" style="width: 100%; height: 100%;" >
                                                                    <img :src="storageURL+multimedia.path+'?token='+token" style="width: 100%; height: 100%; object-fit: cover;">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- DELETE MODAL -->
                                                <div class="modal fade" :id="'glossaryDeleteModal'+article.id" tabindex="-1" aria-labelledby="glossaryDeleteModal" aria-hidden="true">
                                                    <div class="modal-dialog">
                                                        <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h2 class="modal-title" id="glossaryDeleteModalLabel">{{ $t('glossary.delete-sure') }}</h2>
                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div class="modal-body">
                                                            {{ $t('glossary.delete-q1') }} <strong>{{article.title}}</strong> {{ $t('glossary.delete-q2') }}
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('glossary.cancel') }}</button>
                                                            <button type="button" class="btn btn-danger" data-dismiss="modal" @click="deleteArticle(article)">{{ $t('glossary.delete') }}</button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- //DELETE MODAL -->
                                            </div>
                                        </div>
                                        <!-- //IF ARTICLE  -->
                                        <div v-else-if="loading" class="row">
                                            <div class="col-12">
                                                <Loading height="50vh" />
                                            </div>
                                        </div>
                                        <div v-else class="col-12 mt-5" style="text-align: center;">
                                            <h6 class="text-13 text-dark-gray semi-bold">{{ $t('glossary-article.no-article') }}</h6>
                                        </div>
                                    </div>
                                    <!-- //ARTICLE -->
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loading from '@/components/Loading'
import PrimaryButton from "../components/settings/PrimaryButton.vue";
export default {
    name: 'Glossary',
    components: {
        PrimaryButton,
        Loading
    },
    mounted() {
        this.axios.get('/glossary-articles/'+this.$route.params.id).then(resp => {
            this.article = resp.data.glossaryArticle
            this.loading = false
        })
    },
    data() {
        return {
            article: null,
            loading: true,
            token: this.axios.defaults.headers.common.Authorization.split(' ')[1],
            storageURL: process.env.VUE_APP_STORAGE_URL,
            search: false,
            searchStr: '',
        }
    },
    methods: {
        startSearch() {
            this.loading = true
            this.search = this.searchStr ? true : false
            this.axios.get(`/glossary-articles${this.searchStr ? '?search='+this.searchStr : ''}`).then(resp => {
                this.articles = resp.data.glossaryArticles

            }).finally(() => this.loading = false)
        },
        deleteArticle(article) {
            this.loading = true
            this.axios.delete('glossary-articles/'+article.id)
                .then((resp) => this.$router.push({name: 'Glossary'}))
                .finally(() => this.loading = false)
        }
    }
}
</script>
<style scoped>
#article-list {
    margin-top: 30px;
}
</style>
