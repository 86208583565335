<template>
  <div v-if="selectedMaterial">
    <h4 class="d-flex mb-1" style="justify-content: space-between; align-items: flex-end">
      <div style="word-break: break-word;">{{ $parent.getName(selectedMaterial) }}</div>
      <div style="font-weight: bold">{{ Math.floor(getProgress(selectedMaterial)) }}%</div>

    </h4>
    <div class="progress" style="height: 25px !important; position: relative; background-color: #efefef !important;">
      <div class="progress-bar" :style="{ width: Math.floor(getProgress(selectedMaterial)) + '%' }"
        style="background-color: var(--red);">
      </div>
      <div v-if="false" class="d-flex align-items-center justify-content-center w-100"
        style="position: absolute; bottom: 12.5px; color: black; font-size: 20px; font-weight: bold;">10%
      </div>
    </div>
    <div class="mt-4 pt-1 pb-1" style="background: white; border-radius: 5px;">
      <course-material-list-element @progressMade="makeProgress" @pullback="pullback" @loadCourse="$emit('loadCourse')"
        :element="selectedMaterial" :key="selectedMaterial.id" />
    </div>

    <div class="d-flex mt-4 w-100" style="justify-content: space-between">
      <button class="btn btn-primary" :disabled="!canViewPrev" @click.prevent="viewPrev"><i
          class="fas fa-chevron-left"></i></button>
      <button class="btn btn-primary" :disabled="!canViewNext" @click.prevent="viewNext"><i
          class="fas fa-chevron-right"></i></button>
    </div>
    <div v-if="overallProgress === 100" class="d-flex justify-content-center">
      <PrimaryButton :text="$t('course.go-to-course-details')"
        @click="$router.push({ name: 'Course', params: { id: course.id } })" />
    </div>
    <div class="next-training pt-4">
      <h3 class="text-left" style="font-weight: 600; font-size: 18px; color: #87817C">{{ $t('course.next-courses') }}</h3>
      <div class="row mt-4">
        <div v-for="pre_req_for_course in course.prerequisite_for" :key="pre_req_for_course.id" style="cursor: pointer;"
          class="col-sm-12 col-md-4 col-lg-4"
          @click="$router.push({ name: 'Course', params: { id: pre_req_for_course.id } })">
          <course-card :course="pre_req_for_course" />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <img style="height: 400px; width: 100%; object-fit: cover; border-radius: 5px;"
      :src="course.cover_image ? $store.getters.getStorageURL(course.cover_image.path) : 'assets/img/template.jpg'">
    <div class="pt-1 pb-1 mt-4" style="background: white; border-radius: 5px;">
      <h4 class="w-100 mb-0 mb-2 mt-2 text-center">{{ $t('course-materials.choose') }}</h4>
    </div>
  </div>
</template>
<script>
import CourseMaterialListElement from './CourseMaterialListElements/CourseMaterialListElement.vue'
import CourseCard from "@/components/CourseCard";
import PrimaryButton from "./settings/PrimaryButton.vue";

export default {
  components: { PrimaryButton, CourseMaterialListElement, CourseCard },
  name: 'CourseMaterialList',
  emits: ['changeSelected', 'pullback', 'loadCourse'],
  props: {
    selected: Number,
    materials: Object,
    course: Object,
    overallProgress: Number
  },
  mounted() {
    //console.log('Mounted')
  },
  watch: {
    selected() {
      //console.log('Select changed')
    }
  },
  computed: {
    selectedMaterial() {
      return this.selected ? this.materials.find(m => m.id == this.selected) : null
    },
    canViewNext() {
      if (this.selectedMaterial) {
        //console.log(idx)
        let idx = this.materials.findIndex(m => m.id == this.selected)
        return this.materials.length > idx + 1 && !this.materials[idx + 1].locked
      }
      return false
    },
    canViewPrev() {
      if (this.selectedMaterial) {
        ///console.log(idx)
        let idx = this.materials.findIndex(m => m.id == this.selected)
        return idx > 0 && !this.materials[idx - 1].locked
      }
      return false
    }
  },
  methods: {
    makeProgress(cmId, unlockNext = false) {
      console.log("makeProgress");
      let courseMaterial = this.materials.find((el) => {
        return el.course_multimedia_id != null && el.course_multimedia.id == cmId
      })
      this.axios.post(`/courses/${this.course.id}/course-multimedia/${courseMaterial.course_multimedia.id}/make-progress`).then(resp => {
        courseMaterial.course_material_progress.length > 0 ? courseMaterial.course_material_progress[0] = resp.data.courseMaterialProgress : courseMaterial.course_material_progress.push(resp.data.courseMaterialProgress)
        if (unlockNext) {
          this.unlockNext()
        }
      }).catch(error => {
        alert('Something went wrong ! This page will reload !');
        location.reload();
      })
    },
    pullback() {
      this.$emit('pullback')
      if (this.canViewPrev) this.viewPrev()
      else this.$emit('changeSelected', null)
    },
    unlockNext() {
      let locked = this.materials.find(cm => cm.locked == true)
      if (locked) {
        this.axios.get('/courses/' + this.$route.params.id)
          .then(resp => {
            const lockedIdx = this.course.course_materials.findIndex(cm => cm.locked == true)
            this.materials[lockedIdx] = resp.data.course.course_materials.find(cm => cm.id == locked.id)
          })
      }
    },
    viewNext() {
      if (this.canViewNext) {
        this.$emit('changeSelected', this.materials[this.materials.findIndex(m => m.id == this.selected) + 1].id)
      }
    },
    viewPrev() {
      if (this.canViewPrev) {
        this.$emit('changeSelected', this.materials[this.materials.findIndex(m => m.id == this.selected) - 1].id)
      }
    },
    getProgress(courseMaterial) {
      let percentage = 0
      if (courseMaterial.course_tests_id != null) {
        const filtered = this.course.test_attempts.filter((attempt) => {
          return attempt.test_id == courseMaterial.course_test.test_id && attempt.corrected
        })
        percentage = filtered.length > 0 ? Math.max(...filtered.map(el => el.percentage)) : 0
      } else if (courseMaterial.course_multimedia_id != null) {
        const length = courseMaterial.course_multimedia.length
        const progress = courseMaterial.course_material_progress.length > 0 ? courseMaterial.course_material_progress[0].progress : 0
        percentage = length !== 0 ? (progress / (length / 100)) : 0
        console.log(length)
      } else if (courseMaterial.course_event_id != null) {
        const length = courseMaterial.course_event.length * 60
        const progress = courseMaterial.course_material_progress.length > 0 ? courseMaterial.course_material_progress[0].progress : 0
        percentage = length !== 0 ? (progress / (length / 100)) : 0
      }

      return percentage
    },
  }
}
</script>
