<template>
    <canvas id="netTimeChart"></canvas>
</template>
<script>
import { Interval, DateTime, Duration } from 'luxon'
import humanizeDuration from 'humanize-duration'
export default {
    name: 'NetTimeChart',
    props: {
        data: 
        {
           default: ['60000', '60000', '40000']
        }
    },
    mounted() {
        const ctx = $('#netTimeChart')
        this.processData()
        this.chart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: Object.keys(this.processedData),
                datasets: [
                    {
                        data: Object.values(this.processedData),
                        backgroundColor: 'rgb(23, 111, 183, 0.8)',
                        borderColor: 'rgb(23, 111, 183, 1)',
                    }
                ]
            },
            options: {
                legend: {
                    display: false,
                    labels: {
                        fontColor: 'rgb(255, 99, 132)'
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1
                        }
                    }]
                }
            }
        });
    },
    data() {
        return {
            processedData: {}
        }
    },
    methods: {
        processData() {
            console.log(this.data)
            this.data.forEach(d => {
                const interval = d * 1000
                const duration = humanizeDuration(interval, {round: true, units: ['m'], language: this.$store.state.locale})
                this.processedData[duration] = (this.processedData[duration] ?? 0) + 1
            })
        }
    }
}
</script>