<template>
<div class="modal fade" id="newTemporaryCertificateModal" tabindex="-1" role="dialog" ref="newTemporaryCertificateModal">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">New temporary certificate</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form @submit.prevent="submit" class="row" id="newTemporaryCertificateForm">
                <div class="col-12 mt-2">
                    <label>{{ $t('create-course.cert-type') }} </label>
                    <select class="form-control" v-model="cert.certificate_type" required>
                        <option :value="null" disabled>Choose from the list</option>
                        <option v-for="certType in certificateTypes" :value="certType.type" :key="certType.type">{{certType.type}}</option>
                    </select>
                </div>
                <div class="col-12 mt-3 d-flex align-items-center">
                    <input style="width: 14px; height: 14px;" type="checkbox" :true-value="true" :false-value="false" v-model="generateTrainerId" /><div class="ml-1">Generate a trainer ID automatically</div>
                </div>
                <div class="col-12" v-if="!generateTrainerId">
                    <label>Trainer ID</label>
                    <input type="text" class="form-control" required v-model="cert.trainer_id">
                </div>
                <div class="col-12 mt-3">
                    <label>Recipient name (leave blank if not applicable to current certificate type)</label>
                    <input type="text" class="form-control" v-model="cert.recipient_name">
                </div>
                <div class="col-12 mt-3">
                    <label>Valid until</label>
                    <datetime placeholder="Please select a date" :zone="DateTime.local().zoneName" :valueZone="DateTime.local().zoneName" v-model="cert.valid_until" required class="form-control" />
                </div>
            </form>
            <div v-if="error" class="text-danger mt-2">
                <i class="fas fa-exclamation-circle"></i> {{error}}
            </div>
        </div>
        <div class="modal-footer">
            <span v-if="submitting">Generating certificate, this might take a few seconds...</span>
          <PrimaryButton :disabled="submitting" form="newTemporaryCertificateForm" text="Create"/>
          <TransparentButton data-dismiss="modal" :text="$t('common.cancel')" />
        </div>
        </div>
    </div>
</div>
</template>
<script>
import { DateTime } from 'luxon'
import Datetime from '@/components/DateTimePicker/Datetime'
import PrimaryButton from "./settings/PrimaryButton.vue";
import TransparentButton from "./settings/TransparentButton.vue";
export default {
    name: 'CreateTemporaryCertificateModal',
    emits: ['temporaryCertificateCreated'],
    components: {
      TransparentButton,
      PrimaryButton,
        Datetime
    },
    created() {
        this.axios.get('/certificate-types')
            .then(resp => this.certificateTypes = this.certificateTypes.concat(resp.data))
    },
    data() {
        return {
            cert: {
                recipient_name: null,
                valid_until: null,
                ceritificate_type: null,
                trainer_id: null
            },
            certificateTypes: [],
            generateTrainerId: true,
            error: null,
            submitting: false,
            DateTime: DateTime
        }
    },
    methods: {
        submit() {
            this.submitting = true
            this.error = null
            if(this.generateTrainerId === true) {
                delete this.cert.trainer_id
            }
            this.axios.post('/temporary-certificates', this.cert)
                .then(resp => {
                    this.$emit('temporaryCertificateCreated', resp.data.temporary_certificate)
                    $(this.$refs.newTemporaryCertificateModal).modal('hide')
                })
                .catch(e => {
                    this.error = e.response.data.error
                })
                .finally(() => this.submitting = false)
        }
    }
}
</script>
