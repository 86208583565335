<template>
        <div class="training-card filter-shadow" style="position: relative; cursor: pointer;">
            <div class="training-image pointer" v-bind:style="{backgroundImage: `url(${courseGroup.image ? $store.getters.getStorageURL(courseGroup.image.path) : 'assets/img/demo/training.png'})`}"></div>
            <div class="training-card-bottom bg-light-gray-primary" style="flex-grow: 1">
                <div class="d-flex justify-content-between">
                    <div class="col-md-10 col-9">
                        <h4 class="text-18 text-white medium">{{courseGroup.title}}</h4>
                    </div>
                    <div v-if="showFav" @click.stop="toggleFav" style="z-index: 10; padding: 0 0 0 5px" >
                        <img
                            src="assets/img/icons/heart-full.svg"
                            class="pointer"
                            alt=""
                            v-if="isFav"
                        />
                        <img
                            v-else
                            src="assets/img/icons/heart.svg"
                            class="pointer"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
export default {
    name: 'CourseGroupCard',
    props: {
        courseGroup: Object,
        showFav: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        isFav() {
            return this.$store.state.user.favorite_course_groups.some(courseGroup => courseGroup.id === this.courseGroup.id)
        },
    },
    methods: {
        toggleFav() {
          this.axios.post(`/course-groups/${this.courseGroup.id}/toggle-favorite`)
          if(this.isFav) {
            const idx = this.$store.state.user.favorite_course_groups.findIndex(
                courseGroup => courseGroup.id === this.courseGroup.id
            );
            this.$store.state.user.favorite_course_groups.splice(idx, 1)
          } else this.$store.state.user.favorite_course_groups.push(this.courseGroup)
      },
    }
}
</script>
<style scoped>
    .card-title {
        margin-bottom: 0px !important;
        padding-top: 20px !important;
        text-align: center !important;
    }
    .card-body {
        background: none !important;
    }
    .dark-bg-line {
        background-color: var(--red-90);
    }
    .e-learning-label {
        border-bottom: 1px solid #06223e;
        left: 0;
        margin: 0px !important;
        width: fit-content;
    }
    .course-title {
        bottom: -1px !important;
        left: 0;
        margin: 0px !important;
        width: 100%;
        border-bottom-right-radius: 3.7px;
        border-bottom-left-radius: 3.7px;
        padding-top: 10px !important;;
        padding-bottom: 13px !important;
    }
    .text-container {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%
    }
    .locked-bg {
        background-color: #ff8281 !important;
    }
</style>
