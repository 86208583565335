<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('glossary.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Cart'})">{{ $t('my-orders.cart') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('my-orders.my-orders') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div id="content-2">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- RESPONSIVE TABLE -->
            <div class="row">
              <div class="col-12 mt-5 mb-3">
                <h2 class="text-18 text-dark-gray semi-bold">{{ $t('my-orders.my-orders') }}</h2>
              </div>
              <div class="col-12 mb-4">
                <div class="new-table filter-shadow">
                  <table class="table borderless-table">
                    <thead class="bg-light-gray-primary">
                    <tr>
                      <th scope="col" :class="'pointer'+' '+(currentSort==='created_at'?'sorted':'')" @click="sort('created_at')">{{ $t('my-orders.date') }}</th>
                      <th scope="col" :class="'pointer'+' '+(currentSort==='status'?'sorted':'')" @click="sort('status')">{{ $t('my-orders.condition') }}</th>
                      <th scope="col" :class="'pointer'+' '+(currentSort==='invoice'?'sorted':'')" @click="sort('status')">{{ $t('settings.invoices') }}</th>
                      <th scope="col">{{ $t('my-orders.sum') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="order in sortedOrders" :key="order.id" style="cursor: pointer;"
                        @click.prevent="$router.push({name: 'Order', params: {order: order.id}})">
                      <td>{{ $store.getters.parseDateTime(order.created_at) }}</td>
                      <td :class="getStatusColor(order)">{{ $t('status.' + order.status) }}</td>
                      <td>
                        <span v-if="order.invoice_id">{{ order.invoice_id}}</span>
                        <span v-else class="text-danger">{{$t('my-orders.invoice-failed')}}</span>
                      </td>
                      <td>{{ huFormat(getTotal(order), order.currency) }}</td>
                    </tr>
                    <tr v-if="$store.state.user.orders.length == 0">
                      <td colspan="3" class="text-center text-light-black semi-bold pt-4 pb-4">{{$t('no-data.orders') }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- //RESPONSIVE TABLE -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MyOrders',
  data(){
    return{
      currentSort:'id',
      currentSortDir:'asc',
    }
  },
  methods: {
    getStatusColor(order) {
      if (order.status == 'Prepared' || order.status == 'Started' || order.status == 'InProgress') {
        return 'text-primary';
      } else if (order.status == 'Canceled' || order.status == 'Expired') {
        return 'text-danger'
      } else if (order.status == 'Succeeded') {
        return 'text-success'
      }
    },
    huFormat(sum, currency = 'EUR') {
      return new Intl.NumberFormat(this.$store.getters.isoLocale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0
      }).format(sum)
    },
    getTotal(order) {
      let grossTotal = order.items.reduce((acc, c) => acc + c.gross_price * order.exchange_rate, 0)
      if (order.coupon) {
        const discount = order.coupon.percentage_based ? grossTotal * (order.coupon.discount / 100) : order.coupon.discount
        grossTotal -= discount
      }
      return grossTotal < 0 ? 0 : grossTotal
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
  },
  computed: {
    sortedOrders: function () {
      return this.$store.state.user.orders.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    }
  }
}
</script>
<style scoped>
.sorted{
  font-weight: normal;
}
</style>
