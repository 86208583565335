import { createApp } from "vue";
import * as Sentry from "@sentry/vue";
import App from "./App.vue";
import { Plugins, Capacitor } from "@capacitor/core";
const { App: CapacitorApp, Network, PushNotifications } = Plugins;
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import { createI18n } from "vue-i18n";
import hu from "./locales/hu.json";
import en from "./locales/en.json";
import $ from "jquery";
import vueDebounce from "vue-debounce";
import "@popperjs/core";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "malihu-custom-scrollbar-plugin";
import "summernote";
import "summernote/dist/summernote.min.css";
import "./assets/css/style.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "@fortawesome/fontawesome-free/css/all.css";
import "./registerServiceWorker";
import Chart from "chart.js";
import AudioRecorder from "audio-recorder-polyfill";

if (!window.MediaRecorder) window.MediaRecorder = AudioRecorder;

window.$ = $;
window.jQuery = $;

require("@fancyapps/fancybox");
require("@fancyapps/fancybox/dist/jquery.fancybox.css");

let i18n;
if (localStorage.getItem("locale") == null) {
  localStorage.setItem("locale", "en");

  i18n = createI18n({
    locale: "en",
    fallbackLocale: "en",
    messages: {
      hu: hu,
      en: en,
    },
  });
} else {
  i18n = createI18n({
    locale: localStorage.getItem("locale"),
    fallbackLocale: "en",
    messages: {
      hu: hu,
      en: en,
    },
  });
}

// window.Pusher = require('pusher-js');

setInterval(async () => {
  const status = await Network.getStatus();
  store.state.offline = !status.connected;
}, 1000);

if (Capacitor.isPluginAvailable("PushNotifications")) {
  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  PushNotifications.requestPermission().then((result) => {
    if (result.granted) {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
    } else {
      alert("Permission not granted.");
    }
  });

  PushNotifications.addListener("registration", (token) => {
    alert(
      "FIGYELEM: Ez egy debug üzenet, nem fog megjelenni productionben. \n Push registration success, token: " +
        token.value
    );
    store.state.pushToken = token.value;
  });

  PushNotifications.addListener("registrationError", (error) => {
    alert("Error on registration: " + JSON.stringify(error));
  });

  PushNotifications.addListener("pushNotificationReceived", (notification) => {
    //alert('Push received: ' + JSON.stringify(notification));
  });

  PushNotifications.addListener(
    "pushNotificationActionPerformed",
    (notification) => {
      console.log(
        "Notification action performed" +
          JSON.stringify(notification.notification)
      );
      notification = notification.notification;
      if (notification?.data?.read_url) {
        axios
          .post(notification.data.read_url)
          .then((resp) => {
            if (
              notification.data.read_url.match(/\/?notifications\/read\/\d+/)
            ) {
              const id = notification.data.read_url.match(/\d+/)[0];
              const idx = store.state.user?.notifications
                ? store.state.user.notifications.findIndex((n) => n.id == id)
                : -1;
              if (idx !== -1 && store.state.user) {
                store.state.user.notifications[idx].read = true;
              }
            }
          })
          .catch((e) => alert(e));
      }
      if (notification?.data.link) {
        const link =
          typeof notification.data.link == "string"
            ? JSON.parse(notification.data.link)
            : notification.data.link;
        router.replace(link);
      }
    }
  );
}

function initializeEcho() {
  window.Echo = new Echo({
    broadcaster: "pusher",
    key: process.env.VUE_APP_PUSHER_KEY,
    wsHost: process.env.VUE_APP_WS_HOST,
    wsPort: process.env.VUE_APP_WS_PORT,
    wssPort: process.env.VUE_APP_WS_PORT,
    wsPath: process.env.VUE_APP_WS_PATH,
    enabledTransports: ["ws", "wss"],
    forceTLS: process.env.VUE_APP_USE_TLS === "true",
    disableStats: true,
    authEndpoint: process.env.VUE_APP_API_URL + "/broadcasting/auth",
    auth: {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    },
  });

  window.Echo.connector.pusher.connection.bind("connected", function () {
    axios.defaults.headers.common["X-Socket-Id"] = window.Echo.socketId();
  });

  window.Echo.connector.pusher.connection.bind("state_change", (e) => {
    store.state.wsStatus = e;
    if (e.current == "failed") {
      initializeEcho();
    }
  });
}

initializeEcho();

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  Accept: "Application/json",
};
store.state.token = localStorage.getItem("accessToken");

axios.interceptors.request.use(
  function (config) {
    config.params = config.params || {};
    config.params["locale"] = i18n.global.locale;
    config.headers["Push-Token"] = store.state.pushToken;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    if (response.data.message)
      store.state.toastNotifications.push({
        status: "Success",
        message: response.data.message,
        id: store.state.toastCount++,
      });
    return response;
  },
  function (error) {
    if (error.response.status !== 403) {
      console.log(error.response.data);
      if (error.response.data.errors) {
        for (const field in error.response.data.errors) {
          error.response.data.errors[field].forEach((msg) =>
            store.state.toastNotifications.push({
              status: "Error",
              message: msg,
            })
          );
        }
        for (const field in Object.keys(error.response.data.errors)) {
          error.response.data.errors[field].forEach((msg) =>
            store.state.toastNotifications.push({
              status: "Error",
              message: msg,
            })
          );
        }
      } else if (error.response.data.message)
        store.state.toastNotifications.push({
          status: "Error",
          message: error.response.data.message,
          id: store.state.toastCount++,
        });
    } else if (error.response.status === 401) {
      store.commit("setUser", null);
      console.log(router.currentRoute._value);
      if (
        router.currentRoute._value.name &&
        (router.currentRoute._value.name !== "Login" ||
          router.currentRoute._value.name !== "Register")
      )
        router.push({ name: "Login" });
    }
    return Promise.reject(error);
  }
);

function routerLogic(to, from, next) {
  window.scrollTo(0, 0);
  //console.log(`From: ${from.name} \n To: ${to.name}`)
  if (store.state.user) {
    if (store.state.user.email_verified_at) {
      if (
        to.name == "Login" ||
        to.name == "Register" ||
        to.name == "VerifyEmail" ||
        to.name == "Confirmation" ||
        to.name == "ForgotPassword" ||
        to.name == "ResetPassword" ||
        (store.state.user.finished_registration && to.name == "Register2")
      )
        next({ name: "Home" });
      else if (
        (to.matched.some((record) => record.meta.requiresAdminAccess) &&
          store.state.user.role !== "Admin") ||
        (to.matched.some((record) => record.meta.requiresMasterAccess) &&
          store.state.user.role !== "Master" &&
          store.state.user.role !== "Admin") ||
        (to.matched.some((record) => record.meta.requiresDLAUserAccess) &&
          store.state.user.role !== "Member User" &&
          store.state.user.role !== "Master" &&
          store.state.user.role !== "Admin") ||
        (!to.matched.some((record) => record.meta.doesntRequireMembership) &&
          store.state.user.hasActiveMembership == false &&
          (store.state.user.role === "Member User" ||
            store.state.user.role === "User"))
      )
        next({ name: "Home" });
      else next();
    } else {
      if (to.name == "VerifyEmail") next();
      else next({ name: "VerifyEmail" });
    }
  } else {
    if (
      to.name == "Register" ||
      to.name == "Login" ||
      to.name == "Confirmation" ||
      to.name == "ForgotPassword" ||
      to.name == "ResetPassword" ||
      to.name == "CustomPage"
    )
      next();
    else next({ name: "Login" });
  }
  // if(to.name == 'Login' && store.state.user) next({name: 'Home'})
  //  else if(store.state.user && store.state.user.email_verified_at !== null && to.name == 'VerifyEmail') next({name: 'Home'})
  // else if(store.state.user && to.name == 'Register') next({name: 'Home'})
  //  else if(store.state.user && store.state.user.email_verified_at == null && to.name != 'VerifyEmail') next({name: 'VerifyEmail'})
  // else if(store.state.user && store.state.user.email_verified_at !== null && !store.state.user.finished_registration && to.name != 'Register2') next({name: 'Register2'})
  // else if(store.state.user && store.state.user.email_verified_at !== null && store.state.user.finished_registration && to.name == 'Register2') next({name: 'Home'})
  // else if (!(to.name === 'Login' || to.name === 'Register') && !store.state.user && !store.state.disabled) next({ name: 'Login' })
  //  else if(to.matched.some(record => record.meta.requiresAdminAccess) && store.state.user.role !== 'Admin') next({name: from.name})
  //else if(
  //      to.matched.some(record => record.meta.requiresMasterAccess) &&
  //      store.state.user.role !== 'Master' &&
  //      store.state.user.role !== 'Admin'
  // )
  //      next({name: from.name})
  // else if(
  //     to.matched.some(record => record.meta.requiresDLAUserAccess) &&
  //     store.state.user.role !== 'Member User' &&
  //     store.state.user.role !== 'Master' &&
  //     store.state.user.role !== 'Admin'
  // )
  //    next({name: from.name})
  // else next()
}

CapacitorApp.addListener("appUrlOpen", function (data) {
  // slug = /tabs/tabs2
  const slug = data.url.split(".com").pop();

  // We only push to the route if there is a slug present
  if (slug) {
    router.push(slug);
  }
});

router.beforeEach((to, from, next) => {
  if (store.state.firstLoad) {
    store.state.firstLoad = false;
    //check if token exists on local storage
    axios
      .get("/user")
      .then((resp) => {
        store.commit("setUser", resp.data);
        //store.commit("setCheckout");
        if (
          !localStorage.getItem("currency") ||
          localStorage.getItem("currency") === ""
        ) {
          store.commit("setCurrency", "EUR");
        }
        store.dispatch("setupWebsocketListeners");
        store.dispatch("getCurrencies");
        store.dispatch("getExchangeRates");
        store.dispatch("getCustomPages");
      })
      .catch((err) => console.log(err))
      .finally(() => {
        store.state.loading = false;
        routerLogic(to, from, next);
      });
  } else if (!store.state.loading) {
    routerLogic(to, from, next);
  } else {
    next();
  }
});

const app = createApp(App);
if (process.env.SENTRY) {
  Sentry.init({
    app,
    dsn: process.env.SENTRY,
  });
}

app
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(i18n)
  .use(vueDebounce)
  .mixin({
    data() {
      return {
        Chart: Chart,
      };
    },
  });

app.mount("#app");
store.state.i18n = app.$i18n;