<template>
  <div :style="{display: 'flex', alignItems: 'center', height: (height ?? '100vh'),width: (width ?? 'none'), justifyContent: 'center'}">
    <div>
      <div class="spinner-border" style="width: 4rem; height: 4rem; color: var(--red)" role="status">
        <span class="sr-only">{{ $t('disabled.disabled') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Loading',
  props: {
    height: String,
    width: String
  }
}
</script>
