<template>
    <div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a @click.prevent="$router.push({name: 'Home'})" href="#">{{ $t('settings.homepage') }}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('footer.user-manual') }}</li>
            </ol>
        </nav>
        <h3 class="mb-4 mt-2">{{$t('footer.user-manual')}}</h3>
        <div>
            <h4>{{ $t('user-manual.text1') }}</h4>
            <p>{{ $t('user-manual.text2') }}</p>
            <p>{{ $t('user-manual.text3') }}</p>
            <p>{{ $t('user-manual.text4') }}</p>
            <p>{{ $t('user-manual.text5') }}</p>
            <p>{{ $t('user-manual.text6') }}</p>
            <ol>
                <li>{{ $t('user-manual.orders') }}</li>
                <li>{{ $t('user-manual.chart') }}</li>
                <li>{{ $t('user-manual.chat') }}</li>
                <li>{{ $t('user-manual.notifications') }}</li>
                <li>{{ $t('user-manual.analitics') }}</li>
                <li>{{ $t('user-manual.activity') }}</li>
                <li>{{ $t('user-manual.settings') }}</li>
            </ol>
        </div>
        <div class="mt-4">
            <h4>{{ $t('user-manual.text7') }}</h4>
            <p>{{ $t('user-manual.text8') }}</p>
            <ul>
                <li>{{ $t('user-manual.homepage') }}</li>
                <li>{{ $t('user-manual.courses') }}</li>
                <li>{{ $t('user-manual.course-catalog') }}</li>
            </ul>
            <p>{{ $t('user-manual.text9') }}</p>
        </div>
        <div class="mt-4">
            <h4>{{ $t('user-manual.text10') }}</h4>
            <p>{{ $t('user-manual.text11') }}</p>
        </div>
        <div class="mt-4">
            <h4>{{ $t('user-manual.text12') }}</h4>
            <p>{{ $t('user-manual.text13') }}</p>
            <p>{{ $t('user-manual.text14') }}
                <br>
                {{ $t('user-manual.text15') }}
                <br>
                {{ $t('user-manual.text16') }}
                <br>
                {{ $t('user-manual.text17') }}
            </p>
            <p>{{ $t('user-manual.text18') }}</p>
        </div>
        <div class="mt-4">
            <h4>{{ $t('user-manual.text19') }}</h4>
            <p>{{ $t('user-manual.text20') }}</p>
            <ol>
                <li>
                    {{ $t('user-manual.text21') }}
                    <ol>
                        <li>
                            <strong>{{ $t('user-manual.text22') }}
                                </strong>
                            <span>{{ $t('user-manual.text23') }}
                                </span>
                        </li>
                        <li>
                            <strong>{{ $t('user-manual.text24') }}
                                </strong>
                            <span>{{ $t('user-manual.text25') }}
                                </span>
                        </li>
                        <li>
                            <strong>{{ $t('user-manual.text26') }}
                                </strong>
                            <span>{{ $t('user-manual.text27') }}
                                </span>
                        </li>
                        <li>
                            <strong>{{ $t('user-manual.text28') }}
                                </strong>
                            <span>{{ $t('user-manual.text29') }}
                                </span>
                        </li>
                    </ol>
                </li>
                <li>
                    {{ $t('user-manual.text30') }} 
                    <ol>
                        <li>{{ $t('user-manual.name') }}</li>
                        <li>{{ $t('user-manual.type') }}</li>
                        <li>{{ $t('user-manual.begin') }}</li>
                        <li>{{ $t('user-manual.end') }}</li>
                        <li>{{ $t('user-manual.length') }}</li>
                    </ol>
                </li>
                <li>{{ $t('user-manual.text31') }}
                    </li>
                <li>{{ $t('user-manual.notifications') }}</li>
                <li>{{ $t('user-manual.text32') }}
                    </li>
                <li>{{ $t('user-manual.text33') }}
                    </li>
            </ol>
        </div>
        <div class="mt-4">
            <h4>{{ $t('user-manual.text34') }}
                </h4>
            <p>{{ $t('user-manual.text35') }}
                </p>
            <p>{{ $t('user-manual.text36') }}
                </p>
            <p>{{ $t('user-manual.text37') }}
                </p>
            <p>{{ $t('user-manual.text38') }}
                </p>
            <ul>
                <li>
                    <strong>{{ $t('user-manual.text39') }}
                        </strong>
                    <span>{{ $t('user-manual.text40') }}
                        </span>
                </li>
                <li>
                    <strong>{{ $t('user-manual.text41') }}
                        </strong>
                    <span>{{ $t('user-manual.text42') }}
                        </span>
                </li>
                <li>
                    <strong>{{ $t('user-manual.text43') }}
                        </strong>
                    <span>{{ $t('user-manual.text44') }}
                        </span>
                </li>
                <li>
                    <strong>{{ $t('user-manual.text45') }}
                        </strong>
                    <span>{{ $t('user-manual.text46') }}
                        </span>
                </li>
                <li>
                    <strong>{{ $t('user-manual.text47') }}
                        </strong>
                    <span>{{ $t('user-manual.text48') }}
                        </span>
                </li>
                <li>
                    <strong>{{ $t('user-manual.text49') }}
                        </strong>
                    <span>{{ $t('user-manual.text50') }}
                        </span>
                </li>
            </ul>
        </div>
        <div class="mt-4">
            <h4>{{ $t('user-manual.text51') }}
                </h4>
            <p>{{ $t('user-manual.text52') }}
                </p>
            <ul>
                <li>
                    <strong>{{ $t('user-manual.text53') }}
                        </strong>
                    <span>{{ $t('user-manual.text54') }}
                        </span>
                </li>
                <li>
                    <strong>{{ $t('user-manual.text55') }}
                        </strong>
                    <span>{{ $t('user-manual.text56') }}
                        </span>
                </li>
                <li>
                    <strong>{{ $t('user-manual.text57') }}
                        </strong>
                    <span>{{ $t('user-manual.text58') }}
                        </span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: 'UserManual'
}
</script>
<style scoped>
    h4 {
        font-size: 1rem;
        font-weight: bold;
        color: var(--lblue);
    }
    ol {
        counter-reset: item
    }
    ol li {
        display: block
    }
    ol li:before {
        content: counters(item, ".") ") ";
        counter-increment: item
    }
    nav ol li:before {
        content: " " !important;
    }

    @media (max-width: 425px) {
        h4, p, strong, span, li {
            font-size: 0.9rem
        }
    }
</style>
