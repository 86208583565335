<template>
<div class="modal fade" id="editCustomPageGroupsModal" tabindex="-1" role="dialog" ref="editCustomPageGroupsModal">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{$t('custom-page-groups.edit')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-for="customPageGroup in $store.state.customPageGroups" :key="customPageGroup.id" class="row mb-4">
                    <div class="col-12 mt-3">
                        <div class="d-flex align-items-center mb-2 text-18 weight-600 text-title-gray">
                            <img src="assets/img/lang/hu.png" style="height: 20px;">
                            <label class="mb-0 ml-1 mt-0">{{ $t('custom-page-groups.title') }}</label>
                        </div>
                        <input type="text" class="form-control" required v-model="customPageGroup.title.hu">
                    </div>
                    <div class="col-12 mt-3">
                        <div class="d-flex align-items-center mb-2 mt-3 text-18 weight-600 text-title-gray">
                            <img src="assets/img/lang/en.png" style="height: 20px;">
                            <label class="mb-0 ml-1 mt-0">{{ $t('custom-page-groups.title') }}</label>
                        </div>
                        <input type="text" class="form-control" required v-model="customPageGroup.title.en">
                    </div>
                    <div class="col-12 mt-3">
                        <label class="text-18 weight-600 text-title-gray">{{ $t('custom-page-groups.order') }}</label>
                        <input type="number" min="1" class="form-control" required v-model="customPageGroup.order">
                    </div>
                <div class="col-12 d-flex justify-content-between flex-wrap">
                  <PrimaryButton class="mt-2" :disabled="submitting" :click="()=>update(customPageGroup)" :text="$t('common.save-changes')"/>
                  <TransparentButton class="mt-2" :disabled="submitting" :click="()=>deleteCustomPageGroup(customPageGroup)" :text="$t('common.delete')"/>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <TransparentButton data-dismiss="modal" :text="$t('common.cancel')"/>
        </div>
        </div>
    </div>
</div>
</template>
<script>
import PrimaryButton from "./settings/PrimaryButton.vue";
import TransparentButton from "./settings/TransparentButton.vue";

export default {
    name: 'EditCustomPageGroupsModal',
  components: {TransparentButton, PrimaryButton},
    data() {
        return {
            submitting: false
        }
    },
    methods: {
        update(customPageGroup) {
            this.submitting = true
            this.axios.patch('/custom-page-groups/'+customPageGroup.id, customPageGroup)
                .then(resp => {
                    const customPageGroups = this.$store.state.customPageGroups
                    const idx = customPageGroups.findIndex(c => c.id === customPageGroup.id)
                    if(idx !== -1) {
                        customPageGroups[idx] = resp.data.custom_page_group
                        this.$store.commit('setCustomPageGroups', customPageGroups)
                    }
                    $(this.$refs.createCustomPageGroupModal).modal('hide')
                })
                .catch(e => console.log(e))
                .finally(() => this.submitting = false)
        },
        deleteCustomPageGroup(customPageGroup) {
            this.submitting = true
            this.axios.delete('/custom-page-groups/'+customPageGroup.id)
                .then(resp => {
                    const customPageGroups = this.$store.state.customPageGroups
                    const idx = customPageGroups.findIndex(c => c.id === customPageGroup.id)
                    if(idx !== -1) {
                        customPageGroups.splice(idx, 1)
                        this.$store.state.customPages.forEach((customPage, idx) => {
                            if(customPage.group_id === customPageGroup.id) {
                                this.$store.state.customPages[idx].group_id = null;
                            }
                        })
                    }
                })
                .catch(e => console.log(e))
                .finally(() => this.submitting = false)
        }
    }
}
</script>
