<template>
  <div class="row h-100">
    <div class="col-xl-4 left-side-image h-100">
      <img class="img-fluid small-logo" src="assets/img/xbody-dark.png" alt="">
    </div>
    <div class="col-xl-8 h-100 right-side-content">
      <div class="container h-100">
        <div class="row h-100 justify-content-center align-items-center bg-dark-white">
            <form class="col-xl-5 col-lg-8 col-md-8 col-sm-8 col-10 text-center" @submit.prevent="submit">
                <h2 class="mb-3 mt-4">{{ $t('reset-password.new-password') }}</h2>
                <div class="mb-3">
                    <label for="inputEmail" class="form-label float-left">{{ $t('reset-password.email') }}</label>
                    <input
                    type="email"
                    id="inputEmail"
                    class="form-control"
                    :placeholder="$t('reset-password.u-email')"
                    required
                    disabled
                    v-model="data.email"
                    />
                </div>
                <div class="mb-3">
                    <label for="inputToken" class="form-label float-left">{{ $t('reset-password.code') }}</label>
                    <input
                    type="text"
                    id="inputToken"
                    class="form-control login-input"
                    :placeholder="$t('reset-password.u-code')"
                    required
                    v-model="data.token"
                    disabled
                    autofocus />
                </div>
                <div class="mb-3">
                    <label for="inputPassword" class="form-label float-left">{{ $t('reset-password.new-password') }}</label>
                    <input
                    type="password"
                    id="inputPassword"
                    class="form-control"
                    :placeholder="$t('reset-password.u-pw')"
                    required
                    @input="setPassword" />
                    <ul v-if="data.password != null" class="text-left mt-2">
                        <li :class="validation.length ? 'text-success' : 'text-danger'">{{ $t('register.valid-1') }}</li>
                        <li :class="validation.lowerCase ? 'text-success' : 'text-danger'">{{ $t('register.valid-2') }}</li>
                        <li :class="validation.upperCase ? 'text-success' : 'text-danger'">{{ $t('register.valid-3') }}</li>
                        <li :class="validation.number ? 'text-success' : 'text-danger'" >{{ $t('register.valid-4') }}</li>
                        <li :class="validation.special ? 'text-success' : 'text-danger'">{{ $t('register.valid-5') }}</li>
                    </ul>
                </div>
                <div class="mb-3">
                    <label for="inputConfirmPassword" class="form-label float-left">{{ $t('reset-password.confirm-password') }}</label>
                    <input
                    type="password"
                    id="inputConfirmPassword"
                    class="form-control"
                    :placeholder="$t('reset-password.u-pw2')"
                    required
                    v-model="data.confirmPassword" />
                </div>

                <button type="submit" class="btn btn-normal w-100" :disabled="loading || pwErrors">{{ $t('common.send') }}</button>
                <h5 class="mt-4 text-13" @click.prevent="$router.push({name: 'Login'})">{{ $t('login.have-account') }} <a href="#">{{ $t('register.login') }}</a></h5>
                <h5 class="mt-2 text-13" @click.prevent="$router.push({name: 'ForgotPassword'})"><a href="#">{{ $t('register.forgot-password') }}</a></h5>
                <h5 class="mt-2 text-13" @click.prevent="$router.push({name: 'ForgotPassword', params: {emailProp: data.email}})"><a href="#">{{ $t('reset-password.no-email') }}</a></h5>
            </form>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  props: {
      email: String,
  },
  data() {
    return {
      data: {
        email: this.$route.query.email,
        token: this.$route.query.token,
        password: null,
        confirmPassword: null,
      },
      loading: false,
        validation: {
            length: false,
            lowerCase: false,
            upperCase: false,
            number: false,
            special: false
        },
    };
  },
    computed: {
        pwErrors() {
            for(const prop in this.validation) {
                if(this.validation[prop] === false) return true
            }
            return false
        }
    },
  methods: {
    setPassword(pw) {
        this.data.password = pw.target.value
        this.validation.length = this.data.password.length >= 8
        this.validation.lowerCase = new RegExp('[a-z]').test(this.data.password)
        this.validation.upperCase = new RegExp('[A-Z]').test(this.data.password)
        this.validation.number = new RegExp('[0-9]').test(this.data.password)
        this.validation.special = new RegExp('[^A-Za-z0-9]').test(this.data.password)
    },
    submit() {
      this.loading = true;
      console.log(this.data);
      this.axios
        .post("/reset-password", this.data)
        .then((resp) => {
            this.$router.push({name: 'Login'})
        })
        .catch((err) => console.log(err.response))
        .finally(() => (this.loading = false));
    },
    newPasswordReset() {
      this.loading = true;
      this.axios
        .post("/new-password-reset", { email: this.data.email })
        .then((resp) => {
            //this.$router.push({name: 'ResetPassword'})
        })
        .catch((err) => console.log(err.response))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style scoped>
.left-side-image {
  background-image: url('../assets/img/login-image.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 70px 70px !important;
}

.small-logo {
  width: 150px;
}

label small {
  color: var(--gray);
}

h6 {
  font-weight: 400;
}

.container {
  max-width: 100% !important;
}

@media (min-width: 1200px) {
  .right-side-content {
    padding-left: 0px !important;
  }
}

@media (max-width: 1199px) {
  .left-side-image {
    height: 100px !important;
    padding: 37px 50px !important;
  }
  .right-side-content {
    max-height: calc(100% - 100px);
  }
  .remove-text {
    display: none;
  }
  .small-logo {
    width: 100px;
  }
}

.btn-normal {
  padding: 10px 10px;
  border-radius: 5px !important;
}

input {
  border-color: var(--gray) !important;
  box-shadow: none !important;
  outline: none !important;
  color: var(--black);
}

input:focus {
  border-color: var(--red) !important;
}

label,
.d-flex a:active,
.d-flex a:visited,
.d-flex a:link {
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
}

a:active,
a:visited,
a:link {
  color: var(--black);
  text-decoration: none;
  font-weight: 400;
}

a:hover {
  color: var(--red);
}

@media (max-width: 991px) {
  label,
  .d-flex a:active,
  .d-flex a:visited,
  .d-flex a:link {
    font-size: 13px !important;
  }
}
</style>
