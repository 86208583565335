<template>
    <div class="row mt-2">
        <div class="col-12 text-center">
            <h1 class="d-flex align-items-center justify-content-center">
                <i class="fas fa-stopwatch mr-2"></i>
                <span style="font-variant-numeric: tabular-nums">{{attempt.timeLeft}}</span>
            </h1>
        </div>
        <h1 v-if="attempt.test_group" class="col-12 text-center mt-3">
            {{attempt.test_group.name}}
        </h1>
        <div class="col-12 mt-5" v-for="(currentQuestion, idx) in attempt.questions" :key="currentQuestion.id">
            <div class="col-12 mb-2 p-0">
                <h3><strong>{{idx +1}}. {{currentQuestion.question}}</strong></h3>
                <div v-if="currentQuestion.multimedia">
                    <div v-if="currentQuestion.multimedia.type == 'video' || currentQuestion.multimedia.type == 'image' " style="color: var(--black); cursor:pointer; width: 100%; height: 100%"
                        :data-fancybox="`newQuestion`"
                        :href="currentQuestion.multimedia.type != 'video' ? $store.getters.getStorageURL(currentQuestion.multimedia.path) : `#question-${currentQuestion.id}-${currentQuestion.multimedia.id}`" target="_blank"
                        :data-caption="currentQuestion.multimedia.name"
                    >
                        <div v-if="currentQuestion.multimedia.type == 'video'" class="test-attempt-video-container">
                            <video class="test-attempt-video" nocontrols>
                                <source :src="$store.getters.getStorageURL(currentQuestion.multimedia.path)">
                            </video>
                            <video :id="`question-${currentQuestion.id}-${currentQuestion.multimedia.id}`" style="display: none;" controls controlsList="nodownload">
                                <source :src="$store.getters.getStorageURL(currentQuestion.multimedia.path)">
                            </video>
                            <i class="far fa-play-circle playbtn" style="text-shadow: 10 10 10px #000"></i>
                        </div>
                        <div v-if="currentQuestion.multimedia.type == 'image'" class="test-attempt-image-container">
                            <img :src="$store.getters.getStorageURL(currentQuestion.multimedia.path)" class="test-attempt-image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-2 p-0">
                <form @submit.prevent="submit">
                    <div v-if="currentQuestion.question_type == 'Single choice'">
                        <div v-for="(answer, ansId) in currentQuestion.answers" :key="answer.id" class="input-group mb-1">
                            <div class="input-group-prepend">
                                <div class="input-group-text d-flex">
                                    <input @change="submitAnswer(currentQuestion)" type="radio" :value="answer.answer" v-model="currentQuestion.answer" :name="currentQuestion.id" :checked="currentQuestion.answer === answer.answer" aria-label="Radio button for following text input">
                                </div>
                            </div>
                            <div type="text" class="form-control d-flex flex-column" style="height: fit-content !important;" aria-label="Text input with radio button">
                                <div :class="answer.multimedia ? 'mb-2' : ''">{{getLetterCode(ansId)}}. {{answer.answer}}</div>
                                <div v-if="answer.multimedia">
                                    <div v-if="answer.multimedia.type == 'video' || answer.multimedia.type == 'image' " style="color: var(--black); cursor:pointer; width: 100%; height: 100%"
                                        :data-fancybox="`newQuestion`"
                                        :href="answer.multimedia.type != 'video' ? $store.getters.getStorageURL(answer.multimedia.path) : `#question-${answer.id}-${answer.multimedia.id}`" target="_blank"
                                        :data-caption="answer.multimedia.name"
                                    >
                                        <div v-if="answer.multimedia.type == 'video'" class="test-attempt-video-container">
                                            <video class="test-attempt-video" nocontrols>
                                                <source :src="$store.getters.getStorageURL(answer.multimedia.path)">
                                            </video>
                                            <video :id="`question-${answer.id}-${answer.multimedia.id}`" style="display: none;" controls controlsList="nodownload">
                                                <source :src="$store.getters.getStorageURL(answer.multimedia.path)">
                                            </video>
                                            <i class="far fa-play-circle playbtn" style="text-shadow: 10 10 10px #000"></i>
                                        </div>
                                        <div v-if="answer.multimedia.type == 'image'" class="test-attempt-image-container">
                                            <img :src="$store.getters.getStorageURL(answer.multimedia.path)" class="test-attempt-image">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="currentQuestion.question_type == 'Subgrouped'">
                        <div v-for="subQuestion in currentQuestion.sub_questions" :key="subQuestion.id" class="mb-2">
                            <div type="text" class="form-control d-flex flex-column" style="height: fit-content !important;" aria-label="Text input with radio button">
                                <div :class="subQuestion.multimedia ? 'mb-2' : ''">{{subQuestion.question}}</div>
                                <div v-if="subQuestion.multimedia">
                                    <div v-if="subQuestion.multimedia.type == 'video' || subQuestion.multimedia.type == 'image' " style="color: var(--black); cursor:pointer; width: 100%; height: 100%"
                                        :data-fancybox="`newQuestion`"
                                        :href="subQuestion.multimedia.type != 'video' ? $store.getters.getStorageURL(subQuestion.multimedia.path) : `#subQuestion-${subQuestion.id}-${subQuestion.multimedia.id}`" target="_blank"
                                        :data-caption="subQuestion.multimedia.name"
                                    >
                                        <div v-if="subQuestion.multimedia.type == 'video'" class="test-attempt-video-container">
                                            <video class="test-attempt-video" nocontrols>
                                                <source :src="$store.getters.getStorageURL(subQuestion.multimedia.path)">
                                            </video>
                                            <video :id="`subQuestion-${subQuestion.id}-${subQuestion.multimedia.id}`" style="display: none;" controls controlsList="nodownload">
                                                <source :src="$store.getters.getStorageURL(subQuestion.multimedia.path)">
                                            </video>
                                            <i class="far fa-play-circle playbtn" style="text-shadow: 10 10 10px #000"></i>
                                        </div>
                                        <div v-if="subQuestion.multimedia.type == 'image'" class="test-attempt-image-container">
                                            <img :src="$store.getters.getStorageURL(subQuestion.multimedia.path)" class="test-attempt-image">
                                        </div>
                                    </div>
                                </div>
                                <select class="form-control mt-2" v-model="subQuestion.chosenAnswerId" @change="submitAnswer(currentQuestion)">
                                    <option :value="null" disabled>{{$t('course-materials.choose-from-list')}}</option>
                                    <option v-for="answer in currentQuestion.test_question_answers" :key="answer.id" :value="answer.id">{{answer.answer}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="currentQuestion.question_type == 'Multiple choice'">
                  <!--  Shuffle answers                      -->
                        <div v-for="(answer, ansId) in currentQuestion.answers" :key="answer.id" class="input-group mb-1">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <input @change="submitAnswer(currentQuestion)" type="checkbox" v-model="answer.chosen" aria-label="Radio button for following text input">
                                </div>
                            </div>
                            <div type="text" class="form-control d-flex flex-column" style="height: fit-content !important;" aria-label="Text input with radio button">
                                <div :class="answer.multimedia ? 'mb-2' : ''">{{getLetterCode(ansId)}}. {{answer.answer}}</div>
                                <div v-if="answer.multimedia">
                                    <div v-if="answer.multimedia.type == 'video' || answer.multimedia.type == 'image' " style="color: var(--black); cursor:pointer; width: 100%; height: 100%"
                                        :data-fancybox="`newQuestion`"
                                        :href="answer.multimedia.type != 'video' ? $store.getters.getStorageURL(answer.multimedia.path) : `#question-${answer.id}-${answer.multimedia.id}`" target="_blank"
                                        :data-caption="answer.multimedia.name"
                                    >
                                        <div v-if="answer.multimedia.type == 'video'" class="test-attempt-video-container">
                                            <video class="test-attempt-video" nocontrols>
                                                <source :src="$store.getters.getStorageURL(answer.multimedia.path)">
                                            </video>
                                            <video :id="`question-${answer.id}-${answer.multimedia.id}`" style="display: none;" controls controlsList="nodownload">
                                                <source :src="$store.getters.getStorageURL(answer.multimedia.path)">
                                            </video>
                                            <i class="far fa-play-circle playbtn" style="text-shadow: 10 10 10px #000"></i>
                                        </div>
                                        <div v-if="answer.multimedia.type == 'image'" class="test-attempt-image-container">
                                            <img :src="$store.getters.getStorageURL(answer.multimedia.path)" class="test-attempt-image">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="currentQuestion.question_type == 'Free text'">
                        <textarea @input="submitAnswer(currentQuestion, 400)" class="form-control" v-model="currentQuestion.answer" aria-label="With textarea"></textarea>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-12 mt-3">
            <div :style="!answerSubmitting ? 'visibility: hidden' : ''" class="mb-0">{{ $t('active-test-attempt.test-saving') }}</div>
            <button type="submit" :disabled="answerSubmitting || attempt.timeLeft == '00:00:00'" @click="finishTest" class="btn btn-primary mt-1">{{ $t('active-test-attempt.test-end-button') }}</button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActiveTestAttempt',
    emits: ['finish'],
    created() {
        this.$store.commit('setTimer');
        //shuffle answers
      if (this.attempt.shuffle_answers){
        this.attempt.questions.map(q => {
          if(q.question_type == 'Multiple choice') {
            q.answers = this.shuffle(q.answers)
          }
          if(q.question_type == 'Single choice') {
            q.answers = this.shuffle(q.answers)
          }
        });
      }

        this.attempt.questions.forEach(q => {
            if(q.question_type == 'Free text' && q.userAnswers.length > 0) {
                q.answer = q.answer ?? q.userAnswers[0].answer
            }
            else if(q.question_type == 'Single choice' && q.userAnswers.length > 0) {
                q.answer = q.answer ?? q.userAnswers[0].answer
            }
            else if(q.question_type == 'Subgrouped') {
                q.sub_questions.forEach(sq => {
                    const userAnswer = q.userAnswers.find(ua => ua.sub_question_id == sq.id)
                    sq.chosenAnswerId = sq.chosenAnswerId ?? (userAnswer ? q.test_question_answers.find(a => a.answer === userAnswer.answer).id : null)
                })
            }
            else {
                q.answers.forEach(qa => {
                    qa.chosen = qa.chosen ?? q.userAnswers.some(ua => ua.answer == qa.answer)
                })
            }
        })
    },
  props:{
    isQuestionnaire: [Boolean, String],
  },
    data() {
        return {
            attempt: this.$store.state.user.activeTestAttempt,
            answerSubmitting: false,
            timeout: null,
        }
    },
    watch: {
        attempt: {
            deep: true,
            handler(newVal, oldVal) {
                if((newVal === null && oldVal !== null) || newVal.timeLeft === '00:00:00' && oldVal.timeLeft !== '00:00:00')
                    this.$emit('finish', oldVal.test_id, oldVal.id)
            }
        }
    },
    methods: {
        submitAnswer(question, debounceTime = 10) {
            this.answerSubmitting = true
            if(this.timeout)
                clearTimeout(this.timeout)

            this.timeout = setTimeout(() => {
                let data = {answers: []};
                if(question.question_type == 'Free text' || question.question_type == 'Single choice') {
                    data.answers.push({answer: question.answer, sub_question_id: null})
                }
                else if(question.question_type == 'Subgrouped') {
                    question.sub_questions.forEach(sq => {
                        if(sq.chosenAnswerId != null) {
                            data.answers.push({answer: question.test_question_answers.find(tqa => tqa.id == sq.chosenAnswerId).answer, sub_question_id: sq.id})
                        }
                    })
                }
                else {
                    question.answers.forEach((answer) => {
                        if(answer.chosen)
                            data.answers.push({answer: answer.answer, sub_question_id: null})
                    })
                }
                this.axios.post(`/test-attempts/${this.attempt.id}/questions/${question.id}`, data)
                    .then(async (resp) => {
                    })
                    .finally(() => this.answerSubmitting = false)
            }, debounceTime)
        },
        finishTest() {
            this.answerSubmitting = true
          if (this.isQuestionnaire) {
            console.log('isQuestionnaire', this.isQuestionnaire);
            this.axios.post(`/tests/${this.attempt.test_id}/attempts/${this.attempt.id}/finish-questionnaire`)
                .then(resp => {
                  this.$emit('finish', this.attempt.test_id, this.attempt.id)
                  this.$store.state.user.activeTestAttempt = null
                  //this.$router.push({name: 'AfterExam', params: {test: this.attempt.test_id, attempt: this.attempt.id}})
                })
                .finally(() => this.answerSubmitting = false)
          }else {
            this.axios.post(`/tests/${this.attempt.test_id}/attempts/${this.attempt.id}/finish`)
                .then(resp => {
                  this.$emit('finish', this.attempt.test_id, this.attempt.id)
                  this.$store.state.user.activeTestAttempt = null
                  //this.$router.push({name: 'AfterExam', params: {test: this.attempt.test_id, attempt: this.attempt.id}})
                })
                .finally(() => this.answerSubmitting = false)
          }
        },
        getLetterCode(index) {
          const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
          return alphabet[index];
        },
        shuffle(array) {
            let currentIndex = array.length,  randomIndex;
            while (currentIndex !== 0) {
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
                [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
            }
            return array;
        }
    }
}
</script>
<style scoped>
h3 {
    font-size: 20px;
}
.playbtn {
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
    font-size: 70px;
    color:white;
}
.playbtn:hover {
    color: grey;
}
@media (max-width: 425px) {
    h3 {
        font-size: 14px !important;
    }
}
</style>
