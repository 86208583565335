<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-courses.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('custom-pages.custom-pages') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div id="content-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <section id="new-entry">
              <div class="row">
                <div class="col-12">
                  <div class="flex-wrap d-flex" @click.prevent="$router.push({name: 'CreateCustomPage'})">
                    <div class="d-flex align-items-center pointer mr-auto">
                      <div class="new-button-50 d-flex align-items-center justify-content-center">
                        <img src="assets/img/icons/plus.svg" class="img-fluid" alt="">
                      </div>
                      <div class="d-flex flex-column ml-2">
                        <h6 class="text-16 text-dark-gray">{{ $t('custom-pages.create-new') }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div class="col-lg-9">
            <!-- RESPONSIVE TABLE -->
            <div class="row" v-if="!loading && $store.state.customPageGroups">
              <div class="col-12 mb-4">
                <div class="new-table filter-shadow">
                  <table class="table borderless-table">
                    <thead class="bg-light-gray-primary">
                    <tr>
                      <th scope="col" :class="'pointer'+' '+(currentSort==='title'?'sorted':'')" @click="sort('title')">{{ $t('custom-pages.title') }}</th>
                      <th scope="col" :class="'pointer'+' '+(currentSort==='link'?'sorted':'')" @click="sort('link')">{{ $t('custom-pages.link') }}</th>
                      <th scope="col" :class="'pointer'+' '+(currentSort==='group'?'sorted':'')" @click="sort('group')">{{ $t('custom-pages.group') }}</th>
                      <th scope="col" :class="'pointer'+' '+(currentSort==='creator'?'sorted':'')" @click="sort('creator')">{{ $t('custom-pages.creator') }}</th>
                      <th scope="col" >{{ $t('custom-pages.edit') }}</th>
                      <th scope="col" >{{ $t('custom-pages.delete') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="page in sortedCustomPages" :key="page.id">
                      <td>{{ page.title }}</td>
                      <td>{{ page.link }}</td>
                      <td>{{ page.group }}</td>
                      <td>{{ page.creator }}</td>
                      <td class="text-center"
                          @click.prevent="$router.push({name: 'EditCustomPage', params: {customPage: page.id}})">
                        <a href="#"><i class="fas fa-pen"/></a>
                      </td>
                      <td class="text-center" :data-target="'#delete-custom-page-modal-'+page.id" data-toggle="modal"
                          style="cursor: pointer;"><i class="fas fa-trash-alt text-danger"/></td>
                      <generic-delete-modal
                          :id="'delete-custom-page-modal-'+page.id"
                          :itemDeletedReturnValue="page.id"
                          :text="$t('custom-pages.delete-warning')"
                          :title="$t('custom-pages.delete-title')"
                          :url="'/custom-pages/'+page.id"
                          @itemDeleted="removeCustomPage"
                      />
                    </tr>
                    <tr v-if="$store.state.customPageGroups.length == 0">
                      <td colspan="5" class="text-center text-light-black semi-bold pt-4 pb-4">
                        {{ $t('custom-pages.empty') }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- //RESPONSIVE TABLE -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from '@/components/Loading'
import GenericDeleteModal from '@/components/GenericDeleteModal'

export default {
  name: 'CustomPageList',
  components: {
    Loading,
    GenericDeleteModal
  },
  mounted() {
    this.$store.dispatch('getCustomPages')
  },
  computed: {
    sortedCustomPages: function () {
      let pages = this.$store.state.customPages.map((page)=>{
          return {
            id:page.id,
            title:page.title[this.$store.state.locale] ? page.title[this.$store.state.locale] : page.title['en'],
            link:page.link,
            group:this.getLocalizedGroupName(page),
            creator:page.creator.name
          }
      });
      return pages.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    }
  },
  data() {
    return {
      loading: false,
      currentSort:'title',
      currentSortDir:'asc',
    }
  },
  methods: {
    removeCustomPage(pageId) {
      const idx = this.$store.state.customPages.findIndex(p => p.id == pageId)
      if (idx !== -1) {
        this.$store.state.customPages.splice(idx, 1)
      }
    },
    getLocalizedGroupName(page) {
      const group = this.$store.state.customPageGroups.find(g => g.id === page.group_id)
      if (!group) {
        return 'None'
      }
      return group.title[this.$store.state.locale] ? group.title[this.$store.state.locale] : group.title['en']
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
  },


}
</script>
<style scoped>
.btn-outline-primary:hover {
  background-color: var(--red) !important;
  border-color: var(--red) !important;
}
.sorted{
  font-weight: normal;
}
</style>
