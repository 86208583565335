<template>
<div>
    <div v-if="questionType === 'Single choice' || questionType === 'Multiple choice'" class="input-group">
        <div class="input-group-prepend">
            <div class="input-group-text d-flex">
                <input :type="questionType === 'Single choice' ? 'radio' : 'checkbox'" aria-label="Radio button for following text input" disabled>
            </div>
        </div>
        <div type="text" class="form-control d-flex flex-column" style="height: fit-content !important;" aria-label="Text input with radio button">
            <div 
                class="d-flex"
                :class="answer.multimedia ? 'mb-2' : ''" 
            >
                <div :style="{color: questionType != 'Subgrouped' ? (answer.correct ? 'green' : 'red') : ''}">
                    {{answer.answer}}
                </div>
                <div v-if="!finalized">
                    <a href="#" data-toggle="modal" :data-target="'#editQuestionAnswer'+answer.id+'Modal'"><i class="fas fa-pen text-black ml-2 mr-2"></i></a>
                    <edit-answer-modal :question="question" :test="test" :answer="answer" />
                    <a href="#" data-toggle="modal" :data-target="'#deleteQuestionAnswer'+answer.id+'Modal'"><i class="fas fa-trash-alt" style="color: red"></i></a>
                    <delete-answer-modal :test="test" :answer="answer" />
                </div>
            </div>
            <test-question-answer-multimedia v-if="answer.multimedia" :answer="answer" />
        </div>
    </div>
    <div v-else-if="questionType === 'Free text'">
        <test-question-answer-multimedia v-if="answer.multimedia" :answer="answer" />
        <textarea class="form-control" :value="answer.answer" disabled/>
    </div>
    <div v-else-if="questionType === 'Subgrouped'">
        <div class="d-flex">
            <div>{{answer.answer}}</div>
            <a href="#" data-toggle="modal" :data-target="'#editQuestionAnswer'+answer.id+'Modal'"><i class="fas fa-pen text-black ml-2 mr-2"></i></a>
            <edit-answer-modal :question="question" :test="test" :answer="answer" />
            <a href="#" data-toggle="modal" :data-target="'#deleteQuestionAnswer'+answer.id+'Modal'"><i class="fas fa-trash-alt" style="color: red"></i></a>
            <delete-answer-modal :test="test" :answer="answer" />
        </div>
    </div>
</div>
</template>
<script>
import TestQuestionAnswerMultimedia from './TestQuestionAnswerMultimedia.vue'
import DeleteAnswerModal from './TestQuestionAssembler/DeleteAnswerModal.vue'
import EditAnswerModal from './TestQuestionAssembler/EditAnswerModal.vue'
export default {
  components: { TestQuestionAnswerMultimedia, EditAnswerModal, DeleteAnswerModal },
    name: 'TestQuestionAnswerPreview',
    props: {
        answer: Object,
        test: Object,
        question: Object,
        questionType: String,
    }
}
</script>