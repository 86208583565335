<template>
<div v-if="created">
            <!-- BREADCRUMB -->
<div class="margin-sticky-fix"></div>
        <div class="breadcrumb bg-dark-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-wrap d-flex">
                            <div class="d-flex mr-4 pointer">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'Home'})">{{ $t('course-catalog.homepage') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex pointer mr-4">
                                <span class="text-16 text-white pt-3 pb-3"  @click.prevent="$router.push({name: 'Cart'})">{{ $t('cart.cart') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex pointer mr-4">
                                <span class="text-16 text-white pt-3 pb-3" @click.prevent="$router.push({name: 'CheckoutUserData'})">{{ $t('checkout-payment.billing-data') }}</span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100" alt=""></span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-16 text-white pt-3 pb-3">{{ $t('checkout-payment.payment-method') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //BREADCRUMB -->
    <div id="content-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <section id="pm">
                        <div class="row">
                            <div class="col-12">
                                <h2 class="text-18 text-dark-gray semi-bold mb-3">{{ $t('checkout-payment.choose-payment') }}</h2>
                                <div class="row">
                                    <div class="col-12">
                                        <label class="c-inp"> <span>{{ $t('checkout-payment.credit-card') }} <img style="margin-left: 100px;width: 250px" src="/assets/img/credit_cards.jpg" /></span> 
                                            <input type="radio" v-model="this.$store.state.checkout.card" value="true">
                                            <span class="checkmark-circle"></span>
                                        </label>
                                    </div>
                                    <div class="col-12 mt-3">
                                        <button class="btn btn-theme continue" :disabled="!this.$store.state.checkout.card" @click.prevent="$router.push({name: 'CheckoutSummary'})">{{ $t('checkout-payment.next') }} <img src="assets/img/icons/right-arrow.svg" class="img-fluid ml-2" alt=""></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: 'CheckoutPaymentMethod',
    created() {
        if(!this.$store.state.checkout) {
            this.$router.push({name: 'CheckoutUserData'})
        }
        else {
            this.created = true
        }
    },
    data() {
        return {
            created: false
        }
    }
}
</script>
<style>
    #pm {
        margin-top: 30px;
    }
    .continue img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(189deg) brightness(105%) contrast(101%);
        height: 15px !important;
        margin-bottom: 0px;
    }
     /* CUSTOM RADIO BUTTON */
    .c-inp {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .c-inp input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark-circle {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        background-color: transparent;
        border: 2px solid #D63330;
    }

    .c-inp input:checked ~ .checkmark-circle {
        background-color: white;
    }

    .checkmark-circle:after {
        content: "";
        position: absolute;
        display: none;
    }

    .c-inp input:checked ~ .checkmark-circle:after {
        display: block;
    }

    .c-inp .checkmark-circle:after {
        top: 3px;
        left: 3px;
        border-radius: 50%;
        background-color: #D63330;
        width: 15px;
        height: 15px;
    }
    .c-inp span {
        white-space: normal;
        word-wrap: break-word;
    }
</style>