<template>
  <div class="modal fade" :id="id" :ref="id" tabindex="-1"  aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{title}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <span>{{text}}</span>
        </div>
        <div class="modal-footer">
          <TransparentButton data-dismiss="modal" :click="()=>confirm(false)" :text="$t('common.no')"/>
          <PrimaryButton data-dismiss="modal" :click="()=>confirm(true)" :text="$t('common.yes')"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PrimaryButton from "./settings/PrimaryButton.vue";
import TransparentButton from "./settings/TransparentButton.vue";

export default {
  name: 'ConfirmationModal',
  components: {TransparentButton, PrimaryButton},
  emits: ['hasEUConsent'],
  props: {
    id: String,
    itemDeletedReturnValue: {
      required: false,
      default: null,
    },
    text: {
      required: false
    },
    url: {
      required: false,
      default: null,
      type: String,
    },
    title: String,
    item: {
      required: false,
      type: String
    },
    boldItem: {
      default: false
    }
  },
  data() {
    return {
      deleting: false
    }
  },
  methods: {
    confirm(val) {
      this.$emit('hasEUConsent', !!val);
    }
  }
}
</script>
