<template>
    <div class="row">
        <div v-for="media in multimedia" :key="media.id" class="mb-5 col-md-6 col-lg-6 col-xl-4 col-12">
            <h3>{{media.name}}</h3>
            <div v-if="media.uploading">
                <h4>{{ $t('uploads.uploading') }}</h4>
                <h4>{{ $t('uploads.type') }} {{media.type}}</h4>
            </div>
            <div v-else-if="media.type == 'video'">
                <video controls style="width: 100%">
                    <source :src="storageURL+media.path+'?token='+token">
                    {{ $t('uploads.not-supportv') }}
                </video>
            </div>
            <div v-else-if="media.type == 'image'">
                <img :src="storageURL+media.path+'?token='+token" style="width: 100%" />
            </div>
            <div v-else-if="media.type == 'audio'">
                <audio controls>
                    <source :src="storageURL+media.path+'?token='+token">
                    {{ $t('uploads.not-supporta') }}
                </audio>
            </div>
            <div v-else-if="media.type == 'document'">
                <a :href="storageURL+media.path+'?token='+token" target="_blank" :ref="`download${media.id}`">{{ $t('uploads.download') }}</a>
                <h4>{{ $t('uploads.type-doc') }}</h4>
            </div>
        </div>
    </div>
  <div class="row">
        <div class="col-12">
            <RegularPaginator :paginator="{current_page: current_page, last_page: last_page}" @loadPage="getMultimedia" />
        </div>
  </div>
</template>
<script>
import RegularPaginator from "../components/RegularPaginator.vue";

export default {
    name: 'Uploads',
  components: {RegularPaginator},
    mounted() {
        this.axios.get('/multimedia').then(resp => {
          this.multimedia = resp.data.multimedia.data;
          this.current_page = resp.data.multimedia.current_page;
          this.last_page = resp.data.multimedia.last_page;
        })
    },
    data() {
        return {
            storageURL: process.env.VUE_APP_STORAGE_URL,
            multimedia: Object,
            page:1,
            current_page:1,
            last_page:1,
            token: this.axios.defaults.headers.common.Authorization.split(' ')[1]
        }
    },
  methods:{
      getMultimedia(page){
        this.page = page;
          this.axios.get('/multimedia?page='+this.page).then(resp => {
              this.multimedia = resp.data.multimedia.data;
              this.current_page = resp.data.multimedia.current_page;
              this.last_page = resp.data.multimedia.last_page;
          })
      },
  }
}
</script>
