<template>
<div class="pl-2 pr-2 pt-4 pb-4">
    <div>
        <div
            data-fancybox="fancy"
            data-options='{"buttons": ["zoom", "close"], "protect": true}'
            :href="$store.getters.getStorageURL(element.course_multimedia.multimedia.path)"
            :data-caption="element.course_multimedia.name"
            style="cursor: pointer;"
        >
        <div style="max-width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: white; border-radius: 5px;">
            <img :src="$store.getters.getStorageURL(element.course_multimedia.multimedia.path)" style="max-width: 100%; max-height: 400px; object-fit: cover; position: relative; border-radius: 5px">
        </div>
    </div>
</div>
</div>
</template>
<script>
export default {
    emits: ['progressMade'],
    name: 'ImageElement',
  props: {
    element: Object
  },
    mounted() {
            //this.$emit('progressMade', this.element.course_multimedia_id);
          this.makeProgress(true);
    },
  methods:{
    makeProgress(unlockNext = false) {
      this.$emit('progressMade', this.element.course_multimedia_id, unlockNext)
      //console.log('Making progress!')
    },
  },
}
</script>
