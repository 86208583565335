import {createStore} from 'vuex'
import DOMPurify from 'dompurify';
import {DateTime, Settings} from 'luxon';
import router from '../router'
import {Capacitor, Plugins, FilesystemDirectory, FilesystemEncoding} from '@capacitor/core';

const {Filesystem, Modals} = Plugins;
import {FileOpener} from '@ionic-native/file-opener/';
import axios from 'axios';

Settings.defaultLocale = localStorage.getItem('locale') ?? 'en'

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noreferrer');
    }
});

export default createStore({
    state: {
        user: null,
        i18n: null,
        loading: true,
        customPagesLoading: true,
        firstLoad: true,
        locale: localStorage.getItem('locale') ?? 'en',
        currency: localStorage.getItem('currency') ?? 'EUR',
        storageURL: process.env.VUE_APP_STORAGE_URL,
        xbodyID: process.env.VUE_APP_XBODY_ID_URL,
        token: null,
        pushToken: null,
        toastNotifications: Array(),
        toastCount: 0,
        checkout: null,
        offline: !window.navigator.onLine,
        wsStatus: null,
        isNative: Capacitor.isNative,
        currencies: [],
        exchangeRates: [],
        customPages: [],
        customPageGroups: []
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
            if (user?.locale) {
                this.commit("changeLocale", user.locale);
            }
            this.commit("setTimer");
        },
        setCheckout(state, checkout) {
            if (state.checkout == null || checkout === undefined) {
                state.checkout = {
                    billingData: state.user.billing_information
                        ? {
                            name: state.user.billing_information.name,
                            phone_number: state.user.billing_information.phone_number,
                            country_id: state.user.billing_information.location.country.id,
                            zip_code: state.user.billing_information.location.zip_code,
                            locality: state.user.billing_information.location.locality,
                            address: `${state.user.billing_information.location.public_space} ${state.user.billing_information.location.public_space_type}, ${state.user.billing_information.location.other}`,
                            public_space:
                            state.user.billing_information.location.public_space,
                            public_space_type:
                            state.user.billing_information.location.public_space_type,
                            other: state.user.billing_information.location.other,
                            company_name: state.user.billing_information.company_name,
                            company_tax_id: state.user.billing_information.company_tax_id,
                            company_eu_tax_id:
                            state.user.billing_information.company_eu_tax_id,
                            sap_code: state.user.billing_information.sap_code,
                            is_company:
                                state.user.billing_information.company_name !== null &&
                                (state.user.billing_information.company_tax_id !== null ||
                                    state.user.billing_information.company_eu_tax_id !== null),
                        }
                        : {country_id: 141, is_company: 0},
                    card: 1,
                    currency: state.currency,
                    coupon_code: null,
                    current_step: 1,
                };
            } else if (state.checkout && !checkout) {
                state.checkout = null;
            }
        },
        setGroups(state, groups) {
        },
        subscribeToGroup(state, group) {
            window.Echo.private("chatGroup." + group.id)
                .listen("ChatGroupMessageCreated", (e) => {
                    console.log("Message received in group #" + e.message.chat_group_id);
                    console.log(e);
                    const idx = state.user.chat_groups.findIndex(
                        (g) => g.id == e.message.chat_group_id
                    );
                    state.user.chat_groups[idx].chat_group_messages.push(e.message);
                })
                .listen("ChatGroupMessageDeleted", (e) => {
                    console.log("Message deleted in group #" + e.message.chat_group_id);
                    const idx = state.user.chat_groups.findIndex(
                        (g) => g.id == e.message.chat_group_id
                    );
                    const messageIdx = state.user.chat_groups[
                        idx
                        ].chat_group_messages.findIndex((m) => m.id == e.message.id);
                    state.user.chat_groups[idx].chat_group_messages.splice(messageIdx, 1);
                })
                .listen("UserAddedToChatGroup", (e) => {
                    console.log("User added to group #" + e.group.id);
                    const idx = state.user.chat_groups.findIndex(
                        (g) => g.id == e.group.id
                    );
                    if (idx != -1) {
                        state.user.chat_groups[idx].historical_users =
                            e.group.historical_users;
                        state.user.chat_groups[idx].users = e.group.users;
                    }
                })
                .listen("UserRemovedFromChatGroup", (e) => {
                    console.log("User removed from group #" + e.group.id);
                    const idx = state.user.chat_groups.findIndex(
                        (g) => g.id == e.group.id
                    );
                    if (idx != -1) state.user.chat_groups[idx].users = e.group.users;
                });
        },
        downloadFileMobile(state, {name, mime, data}) {
            let fileReader = new FileReader();
            fileReader.readAsDataURL(data);
            fileReader.onload = (e) => {
                Filesystem.writeFile({
                    path: name,
                    data: e.target.result,
                    directory: FilesystemDirectory.Documents,
                    recursive: true,
                })
                    .then(async (file) => {
                        const isConfirmed = await Modals.confirm({
                            title: state.i18n.t("download.title-success"),
                            message: state.i18n.t("download.text-success", {
                                name: name,
                                directory: FilesystemDirectory.Documents,
                            }),
                        });
                        if (isConfirmed.value) {
                            FileOpener.showOpenWithDialog(file.uri, mime);
                        }
                    })
                    .catch((e) => {
                        Modals.alert({
                            title: state.i18n.t("download.title-error"),
                            message: state.i18n.t("download.text-error", {
                                error: e.toString(),
                            }),
                        });
                    });
            };
        },
        changeLocale(state, locale) {
           // state.i18n.locale = locale;
            localStorage.setItem("locale", locale);
            state.locale = locale;
            Settings.defaultLocale = locale;
        },
        setTimer(state) {
            function updateTimeLeft() {
                const time = new Date(new Date(Date.parse(state.user.activeTestAttempt.finished_at)) - new Date());
                const timeLeftSum = Math.floor(time.getTime() / 1000)
                const seconds = String(Math.floor((time / 1000) % 60)).padStart(2, '0');
                const minutes = String(Math.floor((time / 1000 / 60) % 60)).padStart(2, '0');
                const hours = String(Math.floor((time / (1000 * 60 * 60)) % 24)).padStart(2, '0');
                const timeLeft = `${hours}:${minutes}:${seconds}`
                return {timeLeft, timeLeftSum}
            }

            if (state.user && state.user.activeTestAttempt && !state.user.activeTestAttempt.timeLeft) {
                state.user.activeTestAttempt.timeLeft = updateTimeLeft().timeLeft
                const interval = setInterval(() => {
                    if (!state.user || !state.user.activeTestAttempt) {
                        clearInterval(interval)
                        return;
                    }
                    const {timeLeft, timeLeftSum} = updateTimeLeft()
                    state.user.activeTestAttempt.timeLeft = timeLeft
                    if (timeLeftSum <= 0) {
                        clearInterval(interval)
                        state.user.activeTestAttempt = null
                    }
                }, 100)
            }
        },
        setCurrencies: (state, currencies) => {
            state.currencies = currencies
        },
        setExchangeRates: (state, exchangeRates) => {
            state.exchangeRates = exchangeRates
        },
        setCurrency: (state, currency) => {
            state.currency = currency
            localStorage.setItem('currency', currency)
        },
        setCustomPages: (state, customPages) => {
            state.customPages = customPages.sort((a, b) => a.order - b.order)
        },
        setCustomPageGroups: (state, customPageGroups) => {
            state.customPageGroups = customPageGroups.sort((a, b) => a.order - b.order)
        }
    },
    getters: {
        vatRate: (state) => {
            return state.user?.billing_information?.vat_rate
        },
        unreadMessagesCount: (state) => {
            if (state.user) {
                let sum = 0
                state.user.chat_groups.forEach(group => {
                    if (group.chat_group_messages.length > 0 && group.chat_group_messages.some(gm => gm.chat_group_message_receivers.some(r => r.receiver_id == state.user.id && r.read_at == null))) {
                        sum++
                    }
                })
                if (sum > 99)
                    return '99+'

                return sum
            }
            return 0
        },
        isoLocale: (state) => {
            return state.locale === 'hu' ? 'hu-HU' : 'en-US'
        },
        unreadNotificationsCount: (state) => {
            let sum = 0
            if (state.user) {
                state.user.notifications?.data?.forEach(notification => {
                    sum += notification.read ? 0 : 1
                })
            }
            return sum
        },
        formatPrice:
            (state, getters) =>
                (price, defCurrency = null) => {
                    let options = {
                        currency: "EUR",
                        fractionalDigits: 2,
                        exchangeRate: 1,
                    };

                    if (defCurrency) {
                        console.log(defCurrency);
                        return new Intl.NumberFormat(getters.isoLocale, {
                            style: "currency",
                            currency: defCurrency.currency,
                            minimumFractionDigits: options.fractionalDigits,
                        }).format(defCurrency.base_price);
                    }

                    if (state.exchangeRates.length === 0) {
                        const currency = state.currencies.find((c) => c.primary == 1);
                        options = {
                            currency: currency ? currency.currency : "EUR",
                            fractionalDigits: currency ? currency.fractional_digits : 2,
                            exchangeRate: 1,
                        };
                    } else {
                        const currency =
                            state.currencies.find((c) => c.currency == state.currency) ??
                            state.currencies.find((c) => c.primary == 1);
                        options = {
                            currency: currency.currency,
                            fractionalDigits: currency.fractional_digits,
                            exchangeRate:
                                currency.primary == 1
                                    ? 1
                                    : state.exchangeRates.find(
                                    (ecxr) => ecxr.secondary_currency == currency.currency
                                )?.exchange_rate ?? 1,
                        };
                    }

                    return new Intl.NumberFormat(getters.isoLocale, {
                        style: "currency",
                        currency: options.currency,
                        minimumFractionDigits: options.fractionalDigits,
                    }).format(price * options.exchangeRate);
                },
        getGrossPrice: (state, getters) => (course) => {
            const price = getters.getNetPrice(course)
            return price + (price * (Number(course.vat_rate) / 100))
        },
        getNetPrice: (state) => (course) => {
            if (!state.user)
                return ''
            else if (state.user.role != 'User') {
                return course.dla_price
            } else
                return course.base_price
        },
        getStorageURL: (state) => (url) => {
            return state.storageURL + url + '?token=' + state.token
        },
        getHTMLSafeText: (state) => (text) => {
            //console.log(text)
            text = text ?? ''
            let splitText = text.split(/(\s+)/)
            //console.log(splitText)
            splitText.forEach((word, index) => {
                // console.log(word)
                if (word.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) !== null) {
                    if (word.substring(0, 4) !== 'http')
                        splitText[index] = `<a href="http://${word}" target="_blank">${word}</a>`
                    else
                        splitText[index] = `<a href="${word}" target="_blank">${word}</a>`
                }
            })
            let dirtyText = splitText.join('').replace(/(?:\r\n|\r|\n)/g, '<br>');
            //console.log(dirtyText)
            return DOMPurify.sanitize(dirtyText, {ALLOWED_TAGS: ['br', 'a']})
        },
        sanitizeSummernoteText: (state) => (text) => {
            return DOMPurify.sanitize(text, {ALLOWED_TAGS: ['br', 'a', 'i', 'b', 'u', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ol', 'ul', 'li']})
        },
        parseDate: (state) => (date) => {
            return DateTime.fromISO(date, {locale: state.locale}).toLocaleString(DateTime.DATE_MED)
        },
        parseDateTime: (state) => (date) => {
            return DateTime.fromISO(date, {locale: state.locale}).toLocaleString(DateTime.DATETIME_MED)
        },
        parseActivityDate: (state) => (date) => {
            return DateTime.fromISO(date, {locale: state.locale}).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
        },
        removeEmptyOrNull:
            (state) =>
                (obj, excluded = []) => {
                    Object.keys(obj).forEach((k) => {
                        if (
                            (obj[k] === null || obj[k] === "") &&
                            excluded.findIndex((ex) => ex === k) === -1
                        )
                            delete obj[k];
                    });
                    return obj;
                },
        getProgress: (state) => (course) => {
            let progress = 0;
            state.user.course_material_progress.filter(cm => cm.course_material.course_id == course.id).forEach((cm) => {
                progress += cm.progress
            })
            return course.duration !== 0 ? (progress / (course.duration / 100)).toFixed(2) : 0
        },
        coursesFinished: state => {
            let coursesFinished = []
            let coursesInProgress = []
            state.user.courses.forEach((course) => {
                let progress = 0
                let finishDate = new Date(0)
                state.user.course_multimedia_progress.forEach((cmm, index) => {
                    if (cmm.course_multimedia.course_id == course.id) {
                        progress += state.user.course_multimedia_progress[index].progress
                        if (new Date(cmm.updated_at).getTime() > finishDate.getTime()) {
                            finishDate = new Date(cmm.updated_at)
                        }
                    }
                })
                state.user.course_event_progress.forEach((cep, index) => {
                    if (cep.course_event.course_id == course.id) {
                        progress += state.user.course_event_progress[index].progress * 60
                        if (new Date(cep.updated_at).getTime() > finishDate.getTime()) {
                            finishDate = new Date(cep.updated_at)
                        }
                    }
                })

                if (progress < course.duration || course.duration === 0) {
                    coursesInProgress.push({
                        course: course,
                        progress: progress
                    })
                } else {
                    coursesFinished.push({
                        course: course,
                        finish_date: DateTime.fromJSDate(finishDate).toLocaleString(DateTime.DATETIME_MED)
                    })
                }
            })
            return coursesFinished
        },
        coursesInProgress: state => {
            let coursesFinished = []
            let coursesInProgress = []
            state.user.courses.forEach((course) => {
                let progress = 0
                let finishDate = new Date(0)
                state.user.course_multimedia_progress.forEach((cmm, index) => {
                    if (cmm.course_multimedia.course_id == course.id) {
                        progress += state.user.course_multimedia_progress[index].progress
                        if (new Date(cmm.updated_at).getTime() > finishDate.getTime()) {
                            finishDate = new Date(cmm.updated_at)
                        }
                    }
                })
                state.user.course_event_progress.forEach((cep, index) => {
                    if (cep.course_event.course_id == course.id) {
                        progress += state.user.course_event_progress[index].progress * 60
                        if (new Date(cep.updated_at).getTime() > finishDate.getTime()) {
                            finishDate = new Date(cep.updated_at)
                        }
                    }
                })

                if (progress < course.duration || course.duration === 0) {
                    coursesInProgress.push({
                        course: course,
                        progress: progress
                    })
                } else {
                    coursesFinished.push({
                        course: course,
                        finish_date: DateTime.fromJSDate(finishDate).toLocaleString(DateTime.DATETIME_MED)
                    })
                }
            })
            return coursesInProgress
        }

    },
    actions: {
        setupWebsocketListeners({commit, state}) {
            console.log('Websocket connection is open.')
            state.user.chat_groups.forEach(group => {
                console.log('Listening to: chat group #' + group.id)
                commit('subscribeToGroup', group)
            })
            window.Echo.private('user.' + state.user.id)
                .listen('ChatGroupCreated', (e) => {
                    console.log('Chat group created')
                    state.user.chat_groups.push(e.group)
                    commit('subscribeToGroup', e.group)
                })
                .listen('UserAddedToChatGroup', (e) => {
                    console.log('You have been added to group #' + e.group.id)
                    state.user.chat_groups.push(e.group)
                    commit('subscribeToGroup', e.group)
                })
                .listen('UserRemovedFromChatGroup', (e) => {
                    console.log('You have removed from group #' + e.group.id)
                    window.Echo.leaveChannel('chatGroup.' + e.group.id)
                    const idx = state.user.chat_groups.findIndex(g => g.id == e.group.id)
                    if (idx != -1)
                        state.user.chat_groups.splice(idx, 1)
                })
                .listen('NotificationCreated', (e) => {
                    console.log('Notification received')
                    state.user.notifications.data.unshift(e.notification)
                })
                .listen('NotificationUpdated', (e) => {
                    console.log('Notification updated', e)
                    const idx = state.user.notifications.data.findIndex(n => n.id == e.notification.id)
                    if (idx !== -1)
                        state.user.notifications.data[idx] = e.notification
                })
        },
        getCurrencies({commit}) {
            axios.get('/currencies')
                .then((resp) => commit('setCurrencies', resp.data))
                .catch(e => console.log(e?.response))
        },
        getExchangeRates({commit}) {
            axios.get('/exchange-rates')
                .then((resp) => commit('setExchangeRates', resp.data.exchangeRates))
                .catch(e => console.log(e?.response))
        },
        getCustomPages({state, commit}) {
            axios.get('/custom-pages')
                .then((resp) => {
                    commit('setCustomPages', resp.data.custom_pages)
                    commit('setCustomPageGroups', resp.data.custom_page_groups)
                })
                .catch(e => console.log(e))
                .finally(() => state.customPagesLoading = false)
        }
    },
    modules: {}
})
