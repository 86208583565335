<template>
    <div id="carousel-top" ref="carousel" class="carousel slide" v-if="news.length > 0 && !loading" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carousel-top" v-for="(item, idx) in news" :data-slide-to="idx" :key="item.id" :class="{active: item.id == news[0].id}"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item" v-for="item in news" :key="item.id" :class="{active: item.id == news[0].id}" :style="{backgroundImage: `url('${$store.getters.getStorageURL(item.multimedia.path)}'), linear-gradient(0deg,transparent, transparent, #333E48 100%)`}">
                <div style="position: absolute; display: none; width: 100%; height: 100%; background-color: hsla(0,0%,0%,.30)"></div>
                <div class="carousel-caption d-flex align-items-center justify-content-center">
                    <h5 v-if="item.link" style="cursor: pointer;" @click.prevent="processLink(item.link)">{{item.content[$store.state.locale] ? item.content[$store.state.locale] : item.content['en']}}</h5>
                    <h5 v-else>{{item.content[$store.state.locale]}}</h5>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="loading" class="ssc-square" style="border-radius: 5px;"></div>
    <div v-else id="carousel-top" ref="carousel" class="carousel slide">
        <div class="carousel-inner">
            <div class="carousel-item active" style="background: url('/assets/img/demo/carousel.png'), linear-gradient(0deg,transparent, transparent, #333E48 100%);">
                <div class="carousel-caption d-flex align-items-center justify-content-center">
                    <h5>{{$t('manage-news.fix-no')}}</h5>
                </div>
            </div>
        </div>
    </div>
    <!-- <div id="carousel-top" ref="carousel" class="carousel slide" v-if="news.length > 0 && !loading">
        <ol class="carousel-indicators">
            <li data-target="#carousel-top" v-for="(item, idx) in news" :data-slide-to="idx" :key="item.id" :class="{active: item.id == news[0].id}"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item" v-for="item in news" :key="item.id" :class="{active: item.id == news[0].id}">
                <div class="crs-img" :style="{backgroundImage: `url('${$store.getters.getStorageURL(item.multimedia.path)}')`}" style="background-repeat: no-repeat; background-size: cover; background-position: center;"></div>
                <div class="carousel-caption">
                    <h5 v-if="item.link" style="cursor: pointer;" @click.prevent="processLink(item.link)">{{item.content[$store.state.locale] ? item.content[$store.state.locale] : item.content['en']}}</h5>
                    <h5 v-else>{{item.content[$store.state.locale]}}</h5>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="loading" class="ssc-square" style="border-radius: 5px;"></div>
    <div v-else id="carousel-top" ref="carousel" class="carousel slide">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="crs-img" :style="{backgroundImage: `url(/assets/img/button_background_1.jpg)`}" style="background-repeat: no-repeat; background-size: cover; background-position: center;"></div>
                <div class="carousel-caption">
                    <h5>{{$t('manage-news.fix-no')}}</h5>
                </div>
            </div>
        </div>
    </div> -->
</template>
<script>
import {sanitizeUrl} from "@braintree/sanitize-url"
export default {
    name: 'Carousel',
    mounted() {
        this.axios.get('/news')
            .then(resp => {
                this.news = resp.data
              console.log(resp.data)
            })
            .finally(() => this.loading = false)
    },
    data() {
        return {
            loading: true,
            news: [],
            carousel: this.$refs.carousel,
            routerLink: false
        }
    },
    methods: {
        processLink(link) {
            if(link.includes(process.env.VUE_APP_FRONTEND_URL)) {
                link = link.split(process.env.VUE_APP_FRONTEND_URL)[1]
                this.$router.push({path: link})
            }
            else {
                window.open(sanitizeUrl(link), '_blank');
            }
        }
    },
    updated() {
        if(this.$refs.carousel) {
            $(this.$refs.carousel).carousel({
                interval: 10000,
                ride: 'carousel'
            })
        }
    },
    beforeUnmount() {
        if(this.$refs.carousel) {
            $(this.$refs.carousel).carousel('dispose')
        }
    },
}
</script>
<style>
#carousel-top,
    .carousel-inner,
    .carousel-item,
    .carousel-caption {
        height: 325px;
    }
    .carousel-item {
        background-repeat: no-repeat !important;
        background-position: center center;
        background-size: cover !important;
    }

    .carousel-inner h5 {
        font-size: 48px;
        font-weight: bold;
        margin-bottom: 0px;
    }

    .carousel-indicators li {
        background-color: #D9D8D7;
        height: 5px;
        width: 60px;
        border: none;
        border-radius: 8px;
        opacity: 1;
    }

    .carousel-indicators .active {
        background-color: #D11E26;
    }
.ssc-square {
    display: block;
    background-color: rgba(0,0,0,.17);
    width: 100%;
    height: 650px;
}
.ssc-square, .ssc-head-line, .ssc-line, .ssc-circle {
    position: relative;
    overflow: hidden;
}
.ssc-square:after, .ssc-head-line:after, .ssc-line:after, .ssc-circle:after {
    content: "";
    -webkit-animation: ssc-loading 0.7s infinite;
    animation: ssc-loading 0.7s infinite;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    z-index: 1;
    background: -webkit-gradient(linear,left top,right top,from(transparent),color-stop(rgba(255,255,255,0.3)),to(transparent));
    background: linear-gradient(90deg,transparent,rgba(255,255,255,0.3),transparent);
}
@keyframes ssc-loading {
	from {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%)
	}
	to {
		-webkit-transform: translateX(100%);
		transform: translateX(100%)
	}
}


</style>
