<template>
<div class="modal fade" id="prerequisiteCoursesModal" ref="prerequisiteCoursesModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ $t('edit-course.prerequisites') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <loading v-if="loading" height="100%" />
            <div v-else>
                <div class="mb-3"><i class="fas fa-info-circle text-primary" /> {{ $t('course.prerequisite-definition') }}</div>
                <h6><strong>{{ $t('course.can-be-prerequisite') }}</strong></h6>
                <div class="d-flex align-items-center mb-4 mt-2">
<!--                <select class="form-control mr-3" v-model="selected">
                        <option :value="null">{{prerequisitable.length == 0 ? 'Nincsenek előkövetelménynek beállítható képzések' : $t('course-modal.list2')}}</option>
                        <option v-for="course in prerequisitable" :value="course.id" :key="course.id">{{course.title}}</option>
                    </select>-->
                  <Select2 v-model="selected" :options="prerequisitable" :settings="{dropdownParent: $refs.prerequisiteCoursesModal, placeholder:$t('course-modal.list2'),width: '100%' }" style="width: 100%"/>
                  <button class="btn btn-secondary d-flex" :disabled="selected == null" @click.prevent="addToRecommended(selected)"><i class="fas fa-plus" style="width: 22px; height: 22px;"></i></button>
                </div>

                <h6><strong>{{$t("course.prerequisites")}}</strong></h6>
                <div class="row">
                    <div class="col-12 mt-2" v-for="course in course.prerequisites" :key="course.id">
                        <div class="d-flex align-items-center">
                            <img :src="`${course.cover_image ? $store.state.storageURL+course.cover_image.path+'?token='+$store.state.token : 'assets/img/template.jpg'}`" style="border-radius: 50%; width: 60px; height: 60px; object-fit: cover;">
                            <div class="ml-3 mr-2">{{course.title}}</div>
                            <div style="margin-left: auto; font-size: 22px; color: red" class="mr-3">
                                <a href="#"  @click.prevent="removeFromRecommended(course.id)"><i class="fas fa-times"></i></a>
                            </div>
                        </div>
                    </div>
                    <div v-if="course.prerequisites.length == 0" class="col-12">
                        {{$t('course.empty-prerequisites')}}
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-danger" data-dismiss="modal">{{$t('common.close')}}</button>
        </div>
    </div>
</div>
</div>
</template>
<script>
import Loading from './Loading.vue'
import Select2 from 'vue3-select2-component';

export default {
    components: {Loading, Select2},
    name: 'PrerequisiteCoursesModal',
    props: {
        course: {
            type: Object,
            required: true,
        },
    },
    created() {
        this.getData()
    },
    data() {
        return {
            loading: true,
            courses: [],
            selected: null,
        }
    },
    computed: {
        prerequisitable() {
            return this.courses.filter(c => c.id !== this.course.id && !this.course.prerequisites.find(prereq => prereq.id === c.id))
        }
    },
    methods: {
        async getData() {
            try {
                this.courses = this.formatDataForSelect2(this.$store.state.user.role == 'Admin' ? (await this.axios.get('/courses/admin')).data.courses : (await this.axios.get('/courses')).data.courses,'id','title');
            }
            catch(e) {
                console.log(e)
            }
            finally {
                this.loading = false
            }
        },
        addToRecommended(id) {
            const course = this.prerequisitable.find(c => c.id === id)
            this.course.prerequisites.push(course)
            this.selected = null
        },
        removeFromRecommended(id) {
            const courseIdx = this.course.prerequisites.findIndex(c => c.id == id)
            this.course.prerequisites.splice(courseIdx, 1)
        },
      formatDataForSelect2(data, value, text, isLocation = false) {
        if (isLocation && typeof data === 'object' && data !== null && !Array.isArray(data)) {
          return {
            id: String(data.location.id),
            text: data.location.locality
          }
        }
        return data.map(item => {
          return {
            id: String(item[value]),
            text: item[text]
          }
        })
      },
    }
}
</script>
