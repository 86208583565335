<template>
    <!-- Modal delete SubQuestion -->
    <div class="modal fade" :id="'deleteSubQuestionModal-'+subQuestion.id" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header delete-modal-header" style="background-color: #dc3545 !important">
                <h5 class="modal-title text-white" >{{ $t('test-question-assembler.delete-sure') }}</h5>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    {{ $t('test-question-assembler.delete-sub-question') }}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="deleteSubQuestion(subQuestion)">{{ $t('test-question-assembler.delete') }}</button>
                    <button type="button" class="btn btn-primary" data-dismiss="modal">{{ $t('test-question-assembler.cancel') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'DeleteSubQuestionModal',
    props: {
        subQuestion: Object,
        test: Object
    },
    methods: {
        deleteSubQuestion(subQuestion) {
            subQuestion.loading = true
            this.axios.delete('/questions/'+subQuestion.test_question_id+'/sub-questions/'+subQuestion.id)
                .then(resp => {
                    let question = this.test.test_questions[this.test.test_questions.findIndex((q) => q.id == subQuestion.test_question_id)]
                    question.sub_questions.splice(question.sub_questions.findIndex((qs) => qs.id == subQuestion.id), 1)
                })
                .finally(() => subQuestion.loading = false)
        },
    }
}
</script>