<template>
  <div class="icon">
    <svg
      :class="typeof classes !== 'undefined' ? classes.join(' ') : ''"
      @click="iconClicked"
      v-if="name === 'delete-red'"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#d62b33"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5 4H19V6H5V4H8.5L9.5 3H14.5L15.5 4ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM9 8.92859C9.27614 8.92859 9.5 9.15245 9.5 9.42859V19.4286C9.5 19.7047 9.27614 19.9286 9 19.9286C8.72386 19.9286 8.5 19.7047 8.5 19.4286V9.42859C8.5 9.15245 8.72386 8.92859 9 8.92859ZM15.5 9.42859C15.5 9.15245 15.2761 8.92859 15 8.92859C14.7239 8.92859 14.5 9.15245 14.5 9.42859V19.4286C14.5 19.7047 14.7239 19.9286 15 19.9286C15.2761 19.9286 15.5 19.7047 15.5 19.4286V9.42859ZM12 8.92859C12.2761 8.92859 12.5 9.15245 12.5 9.42859V19.4286C12.5 19.7047 12.2761 19.9286 12 19.9286C11.7239 19.9286 11.5 19.7047 11.5 19.4286V9.42859C11.5 9.15245 11.7239 8.92859 12 8.92859Z"
      />
    </svg>
    <svg
      :class="typeof classes !== 'undefined' ? classes.join(' ') : ''"
      @click="iconClicked"
      v-if="name === 'delete-white'"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5 4H19V6H5V4H8.5L9.5 3H14.5L15.5 4ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM9 8.92859C9.27614 8.92859 9.5 9.15245 9.5 9.42859V19.4286C9.5 19.7047 9.27614 19.9286 9 19.9286C8.72386 19.9286 8.5 19.7047 8.5 19.4286V9.42859C8.5 9.15245 8.72386 8.92859 9 8.92859ZM15.5 9.42859C15.5 9.15245 15.2761 8.92859 15 8.92859C14.7239 8.92859 14.5 9.15245 14.5 9.42859V19.4286C14.5 19.7047 14.7239 19.9286 15 19.9286C15.2761 19.9286 15.5 19.7047 15.5 19.4286V9.42859ZM12 8.92859C12.2761 8.92859 12.5 9.15245 12.5 9.42859V19.4286C12.5 19.7047 12.2761 19.9286 12 19.9286C11.7239 19.9286 11.5 19.7047 11.5 19.4286V9.42859C11.5 9.15245 11.7239 8.92859 12 8.92859Z"
      />
    </svg>
    <svg
      :class="typeof classes !== 'undefined' ? classes.join(' ') : ''"
      @click="iconClicked"
      v-if="name === 'save'"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6036 0.500012H4.79753C4.61753 0.498887 4.44653 0.578762 4.33178 0.717137L0.831342 4.93645C0.742467 5.04276 0.694092 5.17776 0.694092 5.31726V15.8011C0.694092 17.2867 1.90572 18.5 3.39184 18.5H14.6036C16.0903 18.5 17.3058 17.2867 17.3058 15.8011V3.19889C17.3058 1.71332 16.0897 0.500012 14.6036 0.500012ZM6.79497 1.70207H11.1999V5.29982C11.1999 5.36507 11.1684 5.40051 11.1026 5.40051H6.89678C6.83097 5.40051 6.7944 5.36507 6.7944 5.29982V1.70207H6.79497ZM13.3042 17.2979H12.5668L12.5775 15.1014C12.5848 14.7616 12.3075 14.4821 11.9666 14.4894C11.6347 14.4961 11.3703 14.7695 11.3765 15.1014L11.3658 17.2979H10.6391L10.6498 15.1014C10.6571 14.7616 10.3798 14.4821 10.0389 14.4894C9.70703 14.4961 9.44265 14.7695 9.4494 15.1014L9.43759 17.2979H8.71197L8.72265 15.1014C8.72997 14.7622 8.45378 14.4854 8.11572 14.4894C7.78159 14.4939 7.51609 14.7678 7.52228 15.1014L7.51047 17.2979H6.78372L6.79553 15.1014C6.80284 14.7622 6.52722 14.4854 6.18859 14.4894C5.85503 14.4939 5.58897 14.7678 5.59515 15.1014L5.58334 17.2979H4.71428L4.69572 12.9999C4.69572 12.9341 4.73115 12.8975 4.79809 12.8975H13.2024C13.2688 12.8975 13.3048 12.9335 13.3048 12.9999V17.2979H13.3042ZM16.1055 15.8011C16.1055 16.6415 15.4457 17.2979 14.6036 17.2979H14.5052V12.9999C14.5052 12.2894 13.9123 11.6977 13.2024 11.6977H4.79753C4.08709 11.6977 3.49084 12.29 3.49365 12.9999L3.51222 17.2979H3.39184C2.54922 17.2979 1.89447 16.6415 1.89447 15.8011V5.53439L5.07653 1.70207H5.59459V5.29982C5.59459 6.01026 6.18747 6.60201 6.89678 6.60201H11.1026C11.8125 6.60201 12.4008 6.0097 12.4008 5.29982V1.70207H14.6036C15.4457 1.70207 16.1055 2.35851 16.1055 3.19889V15.8011Z"
        fill="white"
      />
    </svg>
    <svg
      :class="typeof classes !== 'undefined' ? classes.join(' ') : ''"
      @click="iconClicked"
      v-if="name === 'add'"
      width="33"
      height="34"
      viewBox="0 0 33 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6247 18.5536H17.5326V26.9242H14.8353V18.5536H6.74316V15.7635H14.8353V7.39294H17.5326V15.7635H25.6247V18.5536Z"
        fill="white"
      />
    </svg>
    <svg
      :class="typeof classes !== 'undefined' ? classes.join(' ') : ''"
      @click="iconClicked"
      v-if="name === 'minus'"
      width="19"
      height="4"
      viewBox="0 0 19 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="2.36316" x2="19" y2="2.36316" stroke="white" stroke-width="3" />
    </svg>
    <svg
      :class="typeof classes !== 'undefined' ? classes.join(' ') : ''"
      @click="iconClicked"
      v-if="name === 'edit'"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.6743 2.92463C20.1075 3.35791 20.1075 4.05782 19.6743 4.4911L17.6414 6.52418L13.4757 2.35804L15.5086 0.324959C15.9418 -0.10832 16.6416 -0.10832 17.0749 0.324959L19.6743 2.92463ZM0 20.0001V15.834L12.2863 3.54678L16.452 7.71287L4.16577 20.0001H0Z"
        fill="white"
      />
    </svg>

    <svg
      :class="typeof classes !== 'undefined' ? classes.join(' ') : ''"
      @click="iconClicked"
      v-if="name === 'arrow-down'"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8L14.59 6.59L9 12.17L9 -3.0598e-07L7 -3.93402e-07L7 12.17L1.41 6.59L-3.49691e-07 8L8 16L16 8Z"
        fill="white"
      />
    </svg>
    <svg
      :class="typeof classes !== 'undefined' ? classes.join(' ') : ''"
      @click="iconClicked"
      v-if="name === 'arrow-up'"
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0607 0.539244C12.4749 -0.0465431 11.5251 -0.0465431 10.9393 0.539244L1.3934 10.0852C0.807611 10.671 0.807611 11.6207 1.3934 12.2065C1.97918 12.7923 2.92893 12.7923 3.51472 12.2065L12 3.72122L20.4853 12.2065C21.0711 12.7923 22.0208 12.7923 22.6066 12.2065C23.1924 11.6207 23.1924 10.671 22.6066 10.0852L13.0607 0.539244ZM13.5 22.3999L13.5 1.5999L10.5 1.5999L10.5 22.3999L13.5 22.3999Z"
        fill="#F3F3F3"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: ["name", "classes", "isDisabled"],
  emits: ["clicked"],
  methods: {
    iconClicked() {
      this.$emit("clicked");
    },
  },
};
</script>
