<template>
    <!-- Modal New SubQuestion-->
    <div v-if="question.question_type == 'Subgrouped'" class="modal fade" :id="'newSubQuestionModal-'+question.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header question-modal-header" style="background-color: var(--red) !important;">
                <h5 class="modal-title text-white" id="exampleModalLabel">{{ $t('test-question-assembler.new-sub-question') }}</h5>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <form @submit.prevent="submitNewSubQuestion(question)">
                    <div class="modal-body nono">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="exampleFormControlTextarea1">{{ $t('test-question-assembler.question') }}</label>
                                    <textarea required v-model="question.newSubQuestion.question" maxlength="2000" class="form-control"  rows="3"></textarea>
                                </div>
                            </div>
                            <div class="col-12">
                                {{$t('test-question-assembler.attach-multimedia')}}
                            </div>
                            <generic-file-uploader 
                                v-if="!question.newSubQuestion.multimedia_id"
                                @uploadFinished="attachMultimediaToNewSubQuestion(question, $event)"
                                containerClasses="col-12 row"
                                videoContainerClasses="col-6 mb-1 p-0 pr-1"
                                imageContainerClasses="col-6 mb-1 p-0 pr-1"
                            >
                                <template v-slot:video-btn>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex" style="flex-direction: column; align-items: center; justify-content: center;">
                                                <i class="fas fa-file-video font-28 mb-1" />
                                                <span>{{$t('test-question-assembler.video')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-slot:image-btn>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex" style="flex-direction: column; align-items: center; justify-content: center;">
                                                <i class="fas fa-file-image font-28 mb-1" />
                                                <span>{{$t('test-question-assembler.image')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </generic-file-uploader>
                            <div class="col-12 mb-3" v-else>
                                <div>
                                <div v-if="question.newSubQuestion.multimedia_type == 'video' || question.newSubQuestion.multimedia_type == 'image' " style="color: var(--black); cursor:pointer; width: 100%; height: 100%" 
                                    :data-fancybox="`question.newSubQuestion`" 
                                    :href="question.newSubQuestion.multimedia_type != 'video' ? $store.getters.getStorageURL(`/download/${question.newSubQuestion.multimedia_type}/${question.newSubQuestion.multimedia_id}`) : '#question.newSubQuestion-'+question.newSubQuestion.multimedia_id" target="_blank"
                                    data-caption="Multimedia"
                                >
                                    <div v-if="question.newSubQuestion.multimedia_type == 'video'" style="max-width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: var(--lgray); border-radius: 5px; position: relative;">
                                        <video style="max-width: 100%; max-height: 100%; object-fit: cover; border-radius: 5px; pointer-events: none;" nocontrols>
                                            <source :src="$store.getters.getStorageURL(`/download/${question.newSubQuestion.multimedia_type}/${question.newSubQuestion.multimedia_id}`)">
                                        </video>
                                        <video :id="'question.newSubQuestion-'+question.newSubQuestion.multimedia_id" style="display: none;" controls controlsList="nodownload">
                                            <source :src="$store.getters.getStorageURL(`/download/${question.newSubQuestion.multimedia_type}/${question.newSubQuestion.multimedia_id}`)">
                                        </video>
                                        <i class="far fa-play-circle playbtn"></i>
                                    </div>
                                    <div v-if="question.newSubQuestion.multimedia_type == 'image'" style="max-width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: var(--lgray); border-radius: 5px;">
                                        <img :src="$store.getters.getStorageURL(`/download/${question.newSubQuestion.multimedia_type}/${question.newSubQuestion.multimedia_id}`)" style="max-width: 100%; max-height: 100%; object-fit: cover; border-radius: 5px">
                                    </div>
                                </div>
                                <button class="w-100 btn btn-danger mt-2" @click.prevent="deleteNewSubQuestionMultimedia(question)">{{$t('test-question-assembler.delete-multimedia')}}</button>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="">{{$t('test-question-assembler.correct-answer')}}</label>
                                    <select class="form-control"  v-model="question.newSubQuestion.correct_answer_id" required>
                                        <option :value="null" disabled>{{$t('test-groups.choose')}}</option>
                                        <option v-for="answer in question.test_question_answers" :key="answer.id" :value="answer.id">{{answer.answer}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-secondary w-100" :disabled="question.newSubQuestion.loading">{{ $t('test-question-assembler.save') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import GenericFileUploader from "../GenericFileUploader.vue"

export default 
{
    name: 'NewSubQuestionModal',
    components: { GenericFileUploader },
    props: {
        question: Object
    },
    methods: {
        submitNewSubQuestion(question) {
            question.newSubQuestion.loading = true
            this.axios.post('/questions/'+question.id+'/sub-questions', this.$store.getters.removeEmptyOrNull(question.newSubQuestion))
                .then(resp => {
                    resp.data.subQuestion.edit = JSON.parse(JSON.stringify(resp.data.subQuestion))
                    question.sub_questions.push(resp.data.subQuestion)
                    question.newSubQuestion = {
                        question: null,
                        multimedia_id: null,
                        correct_answer_id: null
                    }
                    $('#newSubQuestionModal-'+question.id).modal('hide')
                })
                .finally(() => question.newSubQuestion.loading = false)
        },
        attachMultimediaToNewSubQuestion(question, file) {
            question.newSubQuestion.multimedia_id = file.uuid
            question.newSubQuestion.multimedia_type = file.type
        },
        deleteNewSubQuestionMultimedia(question) {
            const url = this.$store.getters.getStorageURL(`/upload/${question.newSubQuestion.multimedia_type}/${question.newSubQuestion.multimedia_id}/abort`)
            question.newSubQuestion.multimedia_id = null
            question.newSubQuestion.multimedia_type = null
            this.axios.post(url)
            .then(resp => {})
            .catch(e => console.log(e.response))
        },
    }
}
</script>