<template>
        <div class="modal fade" id="delete-profile-modal" ref="delete-profile-modal" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ user ?  $t('delete-modal.title-admin') : $t('delete-modal.title') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-2">
                            <span v-if="user">{{ $t('delete-modal.line-1-admin', {user: user.name}) }}</span>
                            <span v-else>{{ $t('delete-modal.line-1') }}</span>
                            <strong>{{ $t('delete-modal.line-2') }}</strong>.
                        </div>
                        <div>{{ $t('delete-modal.line-3') }} <br><strong>{{confirmText}}</strong></div>
                        <input type="text" class="form-control mt-3" :placeholder="$t('delete-modal.placeholder')" v-model="userText">
                    </div>
                    <div class="modal-footer">
                      <PrimaryButton data-dismiss="modal" :text="$t('common.cancel')"/>
                      <TransparentButton :disabled="deleting || userText !== confirmText" :click="submit" :text="$t('common.delete')"/>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import PrimaryButton from "./settings/PrimaryButton.vue";
import TransparentButton from "./settings/TransparentButton.vue";

export default {
    name: 'DeleteProfileModal',
  components: {TransparentButton, PrimaryButton},
    emits: ['deleted'],
    props: {
        user: Object
    },
    data() {
        return {
            deleting: false,
            confirmText: 'I UNDERSTAND',
            userText: null
        }
    },
    methods: {
        submit() {
            this.deleting = true
            const path = this.user ? `users/${this.user.id}` : '/delete-profile'
            this.axios.delete(path)
                .then(resp => {
                    $(this.$refs['delete-profile-modal']).modal('hide')
                    if(!this.user || this.user.id === this.$store.state.user.id) {
                        window.Echo.leave('user.'+this.$store.state.user.id)
                        this.$store.commit('setUser', null)
                        this.$router.push({name: 'Login'})
                    }
                    this.$emit('deleted')
                })
                .catch(e => console.log(e))
                .finally(() => this.deleting = false)
        }
    }
}
</script>
